import fetcher from 'lib/fetcher';
import { AutomaticReadingPermissionsApi } from '../../domain/AutomaticReadingPermissionsApi';

export const httpAutomaticReadingPermissionsApi: AutomaticReadingPermissionsApi = {
    async getTracingDiExtractionAccess(params) {
        try {
            const response = await fetcher('api/tracing/extractions/access', { params });
            const data = response.data.content;

            return {
                canAccess: {
                    value: data.accessToDiExtractor
                }
            };
        } catch (error: any) {
            throw new Error(error.response?.data.description, { cause: error.response?.data });
        }
    }
};
