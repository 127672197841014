import { useTranslation } from 'react-i18next';

import { Onboarding } from 'features/onboarding';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import './Guides.scss';

const Guides = () => {
    const [t] = useTranslation('onboarding');

    return (
        <SimpleCard className="Guides">
            <header className="Guides__header">
                <h2 className="Guides__title">{t('guide.title')}</h2>
            </header>
            <Onboarding />
        </SimpleCard>
    );
};

export default Guides;
