import { IconAlertTriangle, IconFlag } from '@tabler/icons';

export const ALERT_STYLE = {
    High: { color: 'red', icon: <IconAlertTriangle />, levelDescription: 'Gravedad alta' },
    Medium: { color: 'orange', icon: <IconFlag />, levelDescription: 'Gravedad baja' }
};

export const ALERTS_LINK = ({ promotionId }): Array<{ code: string; link: { to: string; state?: any } }> => [
    {
        code: 'A005',
        link: {
            to: `/promotion/${promotionId}/activities/type-list`,
            state: {
                typeId: 360203,
                values: { estadoActividad: 'CERRADO' },
                alarm: true
            }
        }
    },
    {
        code: 'A010',
        // redirect to document type page is difficult so its not worth it. redirect to documents and thats it
        link: { to: '/documents' }
    }
];
