import { useEffect, useState } from 'react';
import './Tabs.scss';
import { useTabsIndicatorAnimation } from './hooks/useTabsIndicatorAnimation';

export interface TabsProps {
    className?: string;
    tabs?: React.ReactNode[];
    activeTab?: number;
    testid?: string;
    onClick?: (...args: any) => any;
}

const Tabs = (props: TabsProps) => {
    const [activeTab, setActiveTab] = useState<number | null>(props.activeTab ?? null);
    const { indicator, tabsRef } = useTabsIndicatorAnimation({ activeTab });

    // Syncs props.activeTab with local state
    useEffect(() => {
        if (props.activeTab === undefined) return;
        setActiveTab(props.activeTab);
    }, [props.activeTab]);

    return (
        <div className={`Tabs ${props.className || ''}`} data-testid={props.testid}>
            <ul ref={tabsRef} className="Tabs__list">
                {props.tabs?.map((tab, index) => (
                    <li
                        key={index}
                        className={`Tabs__item ${index === activeTab ? 'Tabs__item--active' : ''}`}
                        onClick={() => {
                            setActiveTab(index);
                            if (props.onClick) props.onClick(index);
                        }}
                    >
                        {tab}
                    </li>
                ))}
            </ul>
            <div
                className="Tabs__indicator"
                style={{
                    width: indicator.width,
                    transform: `translateX(${indicator.position}px)`
                }}
            />
        </div>
    );
};

export default Tabs;
