import uuid from 'react-uuid';
import { useContext, useEffect, useState } from 'react';
import { getPgclsFilterAsCarrier } from '../api/getPgclsFilterAsCarrier';
import { NewPpgclContext } from './NewPpgclStepper';

export const Option = ({ option, wasteManager }) => {
    const [isChecked, setIsChecked] = useState(false);
    const { newPpgcls, setNewPpgcls } = useContext(NewPpgclContext);

    const isOptionChecked = newPpgcls.some(
        (newPpgcl) =>
            newPpgcl.ppgcl.procesoGestora.gestora.id === option?.ppgcl.procesoGestora.gestora.id &&
            newPpgcl?.carrier?.procesoGestora.gestora.id === wasteManager.id &&
            newPpgcl?.carrier?.codLER.id === option.ppgcl.codLER.id
    );

    useEffect(() => {
        setIsChecked(isOptionChecked);
    }, [newPpgcls]);

    const deleteOption = () => {
        const indexOptionToDelete = newPpgcls.findIndex(
            (newPpgcl) =>
                newPpgcl.ppgcl.procesoGestora.gestora.id === option?.ppgcl.procesoGestora.gestora.id &&
                newPpgcl?.carrier?.procesoGestora.gestora.id === wasteManager.id &&
                newPpgcl?.carrier?.codLER.id === option.ppgcl.codLER.id
        );
        const howMany = newPpgcls.filter(
            (newPpgcl) => newPpgcl.wasteManagerProGesCodLerId === option.wasteManagerProGesCodLerId
        );
        if (howMany.length > 1) {
            newPpgcls.splice(indexOptionToDelete, 1);
            setNewPpgcls(newPpgcls);
        } else {
            delete newPpgcls[indexOptionToDelete].carrierProGesCodLerId;
            delete newPpgcls[indexOptionToDelete].carrier;
            setNewPpgcls(newPpgcls);
        }
    };

    const getProGesCodLerCarrier = async () => {
        const filter = `&gestoraId.equals=${wasteManager.id}&codLER.in=${option.ppgcl.codLER.codigoLER}`;
        const carrier = await getPgclsFilterAsCarrier(filter);
        return carrier.data.content.valorizationProcess[0];
    };
    const addOption = async () => {
        const copyNewPpgcls = [...newPpgcls];
        const carrier = await getProGesCodLerCarrier();
        const indexPpgclToEdit = copyNewPpgcls.findIndex(
            (newPpgcl) => newPpgcl.wasteManagerProGesCodLerId === option.wasteManagerProGesCodLerId
        );
        if (indexPpgclToEdit >= 0 && copyNewPpgcls[indexPpgclToEdit]?.carrierProGesCodLerId) {
            let copyPgcl = { ...copyNewPpgcls[indexPpgclToEdit] };
            copyPgcl = {
                ...copyPgcl,
                carrierProGesCodLerId: carrier.id,
                carrier: carrier,
                id: uuid()
            };
            copyNewPpgcls.push(copyPgcl);
            setNewPpgcls(copyNewPpgcls);
        } else if (indexPpgclToEdit >= 0 && !copyNewPpgcls[indexPpgclToEdit]?.carrierProGesCodLerId) {
            copyNewPpgcls[indexPpgclToEdit] = {
                ...copyNewPpgcls[indexPpgclToEdit],
                carrierProGesCodLerId: carrier.id,
                carrier: carrier
            };
            setNewPpgcls(copyNewPpgcls);
        }
    };

    const onChangeInputOption = (e) => {
        const { checked } = e.target;
        if (checked) {
            addOption();
            setIsChecked(true);
        } else {
            deleteOption();
            setIsChecked(false);
        }
    };
    return (
        <li key={option.ppgcl.id} className="wasteManagerResult carrierResult">
            <input
                type="checkbox"
                onChange={(e) => onChangeInputOption(e)}
                className="checkbox wasteManagerResult__checkbox"
                checked={isChecked}
            />
            <p>
                <span>{option.ppgcl.codLER.codigoLER} </span>
                {option.ppgcl.codLER.lerType.lerKind} - {option.ppgcl.procesoGestora.gestora.empresa}
                {option.ppgcl?.actualValorizationPercentage ? (
                    /** TODO: Unknown valorization percent should change to "null" */
                    option.ppgcl?.actualValorizationPercentage === 1 ? (
                        <span className="lerLine__error">- Falta el % valorización</span>
                    ) : (
                        <span className="lerLine__percent">- {option.ppgcl.actualValorizationPercentage}%</span>
                    )
                ) : (
                    ''
                )}
                - {option.ppgcl.procesoGestora.valorizacion.codigoValorizacion}{' '}
            </p>
        </li>
    );
};
