import { ONBOARDING_RESOURCES as resources } from './resources/OnboardingResources';
import { OnboardingTopic } from './topics/OnboardingTopic';

const genericTopic: OnboardingTopic = {
    id: 'generic',
    resources: [resources.intro, resources.documentation, resources.project]
};

export const ONBOARDING_PATHS = {
    directive: {
        topics: [genericTopic]
    },
    'facultative-direction': {
        topics: [genericTopic]
    },
    'quality-and-environment': {
        topics: [
            genericTopic,
            {
                id: 'execution',
                resources: [
                    resources.wasteFlows,
                    resources.diManagement,
                    resources.traceabilityValidation,
                    resources.traceability
                ]
            }
        ]
    },
    'project-manager': {
        topics: [
            genericTopic,
            {
                id: 'supervision',
                resources: [resources.wasteFlows, resources.traceability]
            }
        ]
    },
    construction: {
        topics: [
            genericTopic,
            {
                id: 'configuration',
                resources: [resources.wasteFlows, resources.diManagement]
            },
            {
                id: 'register',
                resources: [resources.wasteRegister]
            },
            {
                id: 'validation',
                resources: [resources.traceabilityValidation, resources.traceability]
            }
        ]
    },
    administrative: {
        topics: [
            genericTopic,
            {
                id: 'configuration',
                resources: [resources.wasteFlows, resources.diManagement]
            },
            {
                id: 'validation',
                resources: [resources.traceabilityValidation, resources.traceability]
            }
        ]
    }
};
