import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconEdit, IconEye, IconEyeOff, IconLink, IconTrash } from '@tabler/icons';
import { IconFileCheck } from '@tabler/icons';
import ReactTooltip from 'react-tooltip';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import { withAuth } from '../../../context/AuthProvider';
import IconButton from '../../../components/BasicComponents/Buttons/Small/IconButton';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import RoundedIcon from '../../../components/BasicComponents/Icon/RoundedIcon';
import SmallText from '../../../components/BasicComponents/Text/SmallText';
import ModalEliminar from '../../../components/BasicComponents/Modales/ModalEliminar';
import HasPermission from '../../../components/AccessCheckers/HasPermission';
import { openFileByURL } from '../../../utils/helpers/files.helpers.js';
import { cambiarOrdenFecha } from '../../../utils/helpers/general.helpers';
import { ASSOCIABLE_TYPES_IDS } from '../constants/documentation.constants';
import './Document.scss';

const Document = ({ document, documentType, values, onClickDelete, isCoCircular }) => {
    const [t] = useTranslation();
    const [deleteModal, setDeleteModal] = useState(false);

    const docDate = cambiarOrdenFecha(document.date);
    const docUpdateDate = cambiarOrdenFecha(document.dateUpdate);
    const dateInfoText = docDate === docUpdateDate ? docDate : `${docDate} - Actualizado el ${docUpdateDate}`;

    return (
        <SimpleCard className="document">
            <div className="document__infoContainer">
                <RoundedIcon big={true} icon={<IconFileCheck color={'#7F42F5'} />} />
                <div className="document__info">
                    <p className="document__date">{document.name}</p>
                    <SmallText text={dateInfoText} />
                </div>
            </div>
            <div className="document__containerButtons">
                {documentType?.visible === false && (
                    <div
                        data-for="docTooltip"
                        data-tip={t('notVisible', { ns: 'documentation' })}
                        className="activity__eye"
                    >
                        <IconEyeOff color={'#7F42F5'} />
                        <ReactTooltip id="docTooltip" delayHide={100} effect="solid" multiline={true} />
                    </div>
                )}

                <SmallButton
                    action={() => openFileByURL(document.url)}
                    titulo={t('show.show', { ns: 'common' })}
                    icon={<IconEye />}
                />

                <HasPermission ifRole="canCreate" section="traceability">
                    {ASSOCIABLE_TYPES_IDS.includes(documentType.id) && (
                        <Link
                            to={{
                                pathname: `/documents/${document.id}/link-traceabilities`,
                                state: { documento: { ...document, type: documentType } }
                            }}
                        >
                            <SmallButton titulo={t('associate.upperCase', { ns: 'common' })} icon={<IconLink />} />
                        </Link>
                    )}
                    {(documentType.uploadable || isCoCircular) && (
                        <>
                            <Link
                                to={{
                                    pathname: `/documents/${document.id}/edit`,
                                    state: {
                                        document: document,
                                        values: values
                                    }
                                }}
                            >
                                <SmallButton titulo={t('edit', { ns: 'common' })} icon={<IconEdit />} />
                            </Link>
                            <IconButton action={() => setDeleteModal(!deleteModal)} icon={<IconTrash />} />
                        </>
                    )}
                </HasPermission>
            </div>

            {deleteModal && (
                <ModalEliminar
                    openModal={deleteModal}
                    setOpenModal={setDeleteModal}
                    id={document.id}
                    deleteFunction={onClickDelete}
                    title={`${t('delete', { ns: 'documentation' })}`}
                    subtitle={`${t('areYouSure', { ns: 'common' })} ${documentType.name} - ${document.name}?`}
                />
            )}
        </SimpleCard>
    );
};
export default withAuth(Document);
