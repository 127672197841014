import Switch from 'react-switch';
import './Switch.scss';

const SwitchComponent = ({
    label,
    checked,
    onChange,
    /** @type {('default|'small')} - Variant */
    variant = 'default'
}) => {
    const modifiers = [`switch--${variant}`];
    return (
        <div className={`switch ${modifiers.join(' ')}`}>
            <Switch
                className="switch__component"
                checked={checked}
                onChange={onChange}
                offColor="#E8E8FF"
                onColor="#7F42F5"
                uncheckedIcon={true}
                checkedIcon={true}
                id={label}
            />
            <label htmlFor={label}>{label}</label>
        </div>
    );
};
export default SwitchComponent;
