import PrivateRoute from 'components/routes/PrivateRoute';
import FAQPage from './FAQPage';

const FAQ = () => {
    const FAQ_ROUTES = [
        {
            component: FAQPage,
            path: '/',
            exact: false
        }
    ];

    return (
        <>
            {FAQ_ROUTES.map((route: any) => (
                <PrivateRoute {...route} key={route.path} />
            ))}
        </>
    );
};

export default FAQ;
