import { actions } from './actions';
import { loadingState } from './state';

export const reducers = {
    [actions.pending]: (state) => {
        return {
            ...state,
            loading: loadingState.PENDING
        };
    },
    [actions.listFulfilled]: (state, payload) => {
        return {
            ...state,
            loading: loadingState.SUCCEEDED,
            treatmentContractList: payload.treatmentContractList
        };
    },
    [actions.detailFulfilled]: (state, payload) => {
        return {
            ...state,
            loading: loadingState.SUCCEEDED,
            treatmentContractDetail: payload.treatmentContractDetail
        };
    },
    [actions.rejected]: (state, payload) => {
        return {
            ...state,
            loading: loadingState.FAILED,
            error: payload.message
        };
    }
};
