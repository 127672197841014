import { createContext, useState } from 'react';
import { withAuth } from '../../../context/AuthProvider';
import VerticalStepper from '../../../components/BasicComponents/Steppers/VerticalStepper';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import WasteManagerStep from './WasteManagerStep';
import LersStep from './LersStep';
import TransferOperatorStep from './TransferOperatorStep';
import FinalStep from './FinalStep';

const steps = [
    { id: 0, order: 1, title: 'Gestoras', component: <WasteManagerStep /> },
    { id: 1, order: 2, title: 'Residuos', component: <LersStep /> },
    { id: 2, order: 3, title: 'Transportistas', component: <WasteManagerStep isCarrier /> },
    { id: 3, order: 4, title: 'Operador del traslado', component: <TransferOperatorStep /> },
    { id: 4, order: 5, title: 'Fin', component: <FinalStep /> }
];
export const NewPpgclContext = createContext({});
const { Provider } = NewPpgclContext;

const NewPpgclStepper = () => {
    const [activeStep, setActiveStep] = useState(1);
    const currentStep = steps.find((step) => step.order === activeStep);
    const [wasteManagersChecked, setWasteManagersChecked] = useState([]);
    const [carriersChecked, setCarriersChecked] = useState([]);
    const [newPpgcls, setNewPpgcls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [transferOperatorChecked, setTransferOperatorChecked] = useState([]);

    const finishForm = () => {
        setActiveStep(1);
        setWasteManagersChecked([]);
        setCarriersChecked([]);
        setNewPpgcls([]);
    };

    return (
        <VerticalStepper {...{ steps, activeStep, setActiveStep }}>
            <Provider
                value={{
                    wasteManagersChecked,
                    setWasteManagersChecked,
                    newPpgcls,
                    setNewPpgcls,
                    setLoading,
                    loading,
                    carriersChecked,
                    setCarriersChecked,
                    finishForm,
                    transferOperatorChecked,
                    setTransferOperatorChecked
                }}
            >
                {currentStep.component}
            </Provider>
            {loading && <Spinner />}
        </VerticalStepper>
    );
};
export default withAuth(NewPpgclStepper);
