import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../../context/AuthProvider';
import SubmitButton from '../../../BasicComponents/Buttons/SubmitButton';
import SimpleCard from '../../../BasicComponents/Cards/SimpleCard';
import { TituloH2 } from '../../../BasicComponents/Titulos/Titulos';
import { InfoDetailsForm } from './InfoDetailsForm';
import './PromotionInformation.scss';

const PromotionInformation = ({
    createPromotionForm,
    setPromotion,
    promotion,
    handleInputChange,
    validation,
    submitting,
    messageNotComplete,
    direction,
    setDirection
}) => {
    const [t] = useTranslation();

    return (
        <SimpleCard className="promotionInformation">
            <TituloH2 titulo={t('promotionInformation', { ns: 'newPromotion' })} />
            {/* TODO: Nos podemos cargar este componente ya y sacarlo todo a este nivel */}
            <form onSubmit={createPromotionForm}>
                <InfoDetailsForm
                    {...{
                        setPromotion,
                        promotion,
                        handleInputChange,
                        validation,
                        messageNotComplete,
                        direction,
                        setDirection
                    }}
                />
                <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={submitting} />
            </form>
        </SimpleCard>
    );
};
export default withAuth(PromotionInformation);
