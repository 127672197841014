import { PropsWithChildren } from 'react';

import IconLoaderPurple14 from 'assets/iconos/automatic-reading/icon-loader-purple-14.svg';
import './TraceabilitiesTabBadge.scss';

type TraceabilitiesTabBadgeProps = {
    state?: 'loading' | 'warning' | null;
};

const TraceabilitiesTabBadge = ({ children, state }: PropsWithChildren<TraceabilitiesTabBadgeProps>) => {
    if (children === null) return null;

    const modifiers = [
        children === null ? 'TraceabilitiesTabBadge--empty' : '',
        `TraceabilitiesTabBadge--${state || 'default'}`
    ];

    return (
        <div className={`TraceabilitiesTabBadge ${modifiers.join(' ')}`}>
            {state === 'loading' && <img src={IconLoaderPurple14} alt="" />}
            <div className="TraceabilitiesTabBadge__badge">
                <span title={`${children}`}>{children}</span>
            </div>
        </div>
    );
};

export default TraceabilitiesTabBadge;
