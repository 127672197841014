import { useTranslation } from 'react-i18next';
import './MandatoryFlowSelect.scss';

type MandatoryFlowSelectProps = {
    flows: { id: string; name: string }[];
    value: number | string;
    onChange?: (value: number) => void;
};

const MandatoryFlowSelect = (props: MandatoryFlowSelectProps) => {
    const [t] = useTranslation('kpis');

    const valueFlow = props.flows.find((flow) => flow.id === props.value);
    const modifiers = [props.value !== '' ? 'MandatoryFlowSelect--selected' : ''];
    const placeholder = t('mandatory-flows.selectedFlow');

    return (
        <>
            <select
                style={{ width: `calc(${(valueFlow?.name || placeholder).length || '6'}ch + 5.6rem)` }}
                className={`MandatoryFlowSelect ${modifiers.join(' ')}`}
                value={props.value}
                onChange={({ target }) => props.onChange?.(parseInt(target.value))}
            >
                <option value="" disabled>
                    {placeholder}
                </option>
                {props.flows.map((flow) => (
                    <option key={flow.id} value={flow.id}>
                        {flow.name}
                    </option>
                ))}
            </select>
        </>
    );
};

export default MandatoryFlowSelect;
