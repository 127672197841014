import { Reducers, createStore } from 'modules/shared/domain/store/createStore';

import { AutomaticReadingPermissionsStore } from '../domain/AutomaticReadingPermissionsStore';
import { loadAutomaticReadingPermissions } from './load/loadAutomaticReadingPermissions';

const reducers: Reducers<AutomaticReadingPermissionsStore> = {
    loadAutomaticReadingPermissionsPending(state, payload) {
        return { ...state, loading: 'pending' };
    },
    loadAutomaticReadingPermissionsFulfilled(state, payload) {
        return { ...state, loading: 'succeeded', permissions: payload.permissions };
    },
    loadAutomaticReadingPermissionsRejected(state, payload) {
        return { ...state, loading: 'failed', error: payload.error };
    }
};

export const automaticReadingPermissionsStore = createStore({
    reducers,
    initialState: {
        loading: 'idle',
        permissions: null,
        error: null
    },
    thunks: {
        loadAutomaticReadingPermissions
    }
});
