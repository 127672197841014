import axios from 'axios';
import { dispatchLogout } from '../../lib/LogoutHandler';
import { getToken } from '../../utils/helpers/api.helpers';

class WasteManagerServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    filterWasteManager = async (url) => {
        let noTerminatedWasteManagerFilter = '&estadoId.notEquals=5604';
        if (url === undefined || url === null || url === '') {
            noTerminatedWasteManagerFilter = '?estadoId.notEquals=5604';
        }
        try {
            const result = await this.services.get(`/api/waste-manager${url}${noTerminatedWasteManagerFilter}`, {
                headers: {
                    Authorization: getToken()
                }
            });

            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getWasteManagerById = async (id) => {
        try {
            const result = await this.services.get(`/api/waste-managers/${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });

            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getWasteManagers = async ({ promotionId }) => {
        try {
            const result = await this.services.get(`/api/waste-managers/project/${promotionId}?size=999&page=0`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
                return;
            }
            return error;
        }
    };

    getWasteManagerContact = async (filtro) => {
        try {
            const result = await this.services.get(`/api/waste-manager-contact${filtro}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    requestNewWasteManager = async (wasteManager) => {
        try {
            const result = await this.services.post(`/api/maintenance/request-new-wastemanager`, wasteManager, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    requestNewValorizationProcess = async (wasteManagerAndCodLer) => {
        try {
            const result = await this.services.post(
                `/api/maintenance/request-new-valorization-process`,
                wasteManagerAndCodLer,
                {
                    headers: {
                        Authorization: getToken()
                    }
                }
            );
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };
}

const wasteManagerServices = new WasteManagerServices();
export default wasteManagerServices;
