import { IconTrash } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import IconButton from '../Buttons/Small/IconButton';
import { openFileByURL, openFile } from '../../../utils/helpers/files.helpers';
import './File.scss';

const File = ({
    file,
    fileName,
    contentType,
    preSignedUrl,
    showImage = false,
    showDelete = false,
    deleteAction,
    fileIndex,
    className = ''
}) => {
    const showFile = () => {
        if (preSignedUrl !== undefined) {
            return openFileByURL(preSignedUrl);
        } else {
            return openFile(file, contentType);
        }
    };

    const removeFile = (event) => {
        // THIS!!! to avoid spread click event to parents (for example: drag and drop)
        event.stopPropagation();
        deleteAction(fileIndex);
    };

    return (
        <div className={`file ${className}`}>
            {preSignedUrl !== undefined && preSignedUrl !== null && showImage && (
                <img src={preSignedUrl} alt={fileName} />
            )}
            <div className="file__data">
                <p className="file__name" onClick={() => showFile()}>
                    {fileName}
                </p>
                {showDelete && <IconButton icon={<IconTrash size={24} />} action={(e) => removeFile(e)} />}
            </div>
        </div>
    );
};
export default withAuth(File);
