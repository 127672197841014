// eslint-disable-next-line import/named
import {
    IconFileAnalytics,
    IconFileInfo,
    IconMail,
    IconPennant,
    IconRecycle,
    IconSettings,
    // eslint-disable-next-line import/named
    TablerIconProps
} from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import SettingsLayout from 'components/Layouts/SettingsLayout';
import PrivateRoute from 'components/routes/PrivateRoute';
import KPIsGoalSettings from 'features/KPIsGoalSettings';
import notificationSettings from 'features/notification-settings';
import { promotionRouteRoot } from 'features/promotion/routes/PromotionRouter';
import { useAuth } from 'modules/auth';
import { usePromotion } from 'modules/promotion';
import Layout from 'pages/Layout/Layout';
import NewPromotion from 'pages/NewPromotion/NewPromotion';
import DetailPromotion from 'pages/PromotionSettings/DetailPromotion';
import { hasPermission } from 'utils/helpers/roles.helpers';
import './Settings.scss';
import EgrAndPgr from './pages/EgrAndPgr';
import PromotionData from './pages/PromotionData';

const Settings = () => {
    const [t] = useTranslation('promotion');
    const [{ promotion }] = usePromotion();
    const [account] = useAuth();
    const match = useRouteMatch();
    const location = useLocation();

    /**
     * As the route has route parameters, it is necessary to distinguish between the root
     * route against which it is typed (root) versus the current base route for the link (current).
     */
    const root = `${promotionRouteRoot}/settings`;
    const current = `${match.url}`;

    const iconProps: TablerIconProps = {
        size: 20,
        stroke: 1.75
    };

    enum Groups {
        cocircular = 'cocircular',
        public = 'public'
    }

    const routes = [
        // Cocircular admins only
        {
            name: t('settings.sections.general'),
            icon: <IconSettings {...iconProps} />,
            to: `/general`,
            detail: () => <NewPromotion layoutless match={{ params: { id: promotion?.id } }} />,
            requiredPermission: 'canCreate',
            section: 'promotion',
            group: t(`settings.groups.${Groups.cocircular}`)
        },
        {
            name: t('settings.sections.notifications'),
            icon: <IconMail {...iconProps} />,
            to: `/notifications`,
            detail: notificationSettings,
            requiredPermission: 'canView',
            section: 'notificationSettings',
            group: t(`settings.groups.${Groups.cocircular}`)
        },

        // Public settings
        {
            name: t('settings.sections.project'),
            icon: <IconFileInfo {...iconProps} />,
            to: `/project`,
            detail: () => <PromotionData />,
            section: 'projectSettings',
            requiredPermission: 'canView',
            group: t(`settings.groups.${Groups.public}`)
        },
        // Legacy flows, to be removed when https://app.clickup.com/t/86bwzuzap
        {
            name: t('settings.sections.flows'),
            icon: <IconRecycle {...iconProps} />,
            to: `/flows`,
            detail: DetailPromotion,
            requiredPermission: 'canCreate',
            section: 'configuration',
            group: t(`settings.groups.${Groups.public}`)
        },
        {
            name: t('settings.sections.egr-pgr'),
            icon: <IconFileAnalytics {...iconProps} />,
            to: `/egr-pgr`,
            detail: EgrAndPgr,
            requiredPermission: 'canView',
            section: 'egrandpgr',
            group: t(`settings.groups.${Groups.public}`)
        },
        {
            name: t('settings.sections.kpi-goals'),
            icon: <IconPennant {...iconProps} />,
            to: `/kpi-goals`,
            detail: KPIsGoalSettings,
            requiredPermission: 'canView',
            section: 'kpiGoals',
            group: t(`settings.groups.${Groups.public}`)
        }
    ]
        // Filter the routes based on the user's permissions
        .filter((route) => hasPermission(route.section, route.requiredPermission, account.user, promotion?.id));

    const active = routes.find(({ to }) => location.pathname.startsWith(current + to))?.name;

    return (
        <Layout
            navBar={false}
            promocion={null}
            breadcrumbs={[
                {
                    name: '360 Advisor',
                    to: `/promotion/${promotion?.id}`
                },
                {
                    name: `${t('settings.title')}`,
                    to: `/promotion/${promotion?.id}/settings`
                }
            ]}
        >
            <SettingsLayout currentUrl={current} links={routes} active={active}>
                <Switch>
                    {routes.map(({ to, detail }) => (
                        <PrivateRoute key={to} path={root + to} component={detail} />
                    ))}
                    <Redirect to={routes.length > 0 ? current + routes[0].to : promotionRouteRoot} />
                </Switch>
            </SettingsLayout>
        </Layout>
    );
};

export default Settings;
