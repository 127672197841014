import { IconAlertCircle, IconEye } from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { IconFileCheck } from '@tabler/icons';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import RoundedIcon from 'components/BasicComponents/Icon/RoundedIcon';
import SmallText from 'components/BasicComponents/Text/SmallText';
import { TituloH2 } from 'components/BasicComponents/Titulos/Titulos';
import { cambiarOrdenFecha, convertToYYYYMMDDFormat } from 'utils/helpers/general.helpers';

import TreatmentContractPrint from './TreatmentContractPrint';
import { loadingState, useTreatmentContractStore } from './store';

const TreatmentContractList = ({ promotion }) => {
    const [t] = useTranslation();
    const [state, actions] = useTreatmentContractStore();
    const [currentTreatmentContractToPrintIndex, setCurrentTreatmentContractToPrintIndex] = useState(null);
    const [printing, setPrinting] = useState(false);

    useEffect(async () => {
        if (promotion?.id !== undefined) {
            await actions.loadTreatmentContractList(promotion);
        }
    }, [promotion?.id]);

    const refs = useRef([]);

    useEffect(() => {
        if (printing) {
            handlePrint();
            setPrinting(false);
        }
    }, [printing]);

    const handlePrint = useReactToPrint({
        content: () => refs.current[currentTreatmentContractToPrintIndex],
        documentTitle: `${convertToYYYYMMDDFormat(new Date())}_Contrato de tratamiento`,
        pageStyle: () => `
                @media print {
                    @page {
                        size: 210mm 297mm ;
                        margin: 1.5cm;
                    }
                    html {
                        background-color: #FFFFFF;
                        -webkit-print-color-adjust: exact !important;
                        print-color-adjust: exact !important;
                    }
                }
            `
    });

    const getDocumentSubtitle = () => {
        return `Precontrato de tratamiento generado por CoCircular - ${cambiarOrdenFecha(new Date())}`;
    };

    const getDocumentTitle = (treatmentContract) => {
        return `Gestora: ${treatmentContract.wasteManager.empresa} - Operador del traslado: ${treatmentContract.transferOperator.name}`;
    };

    const printTreatmentContract = (index) => {
        setPrinting(true);
        setCurrentTreatmentContractToPrintIndex(index);
    };

    if (state.loading === loadingState.SUCCEEDED && state.treatmentContractList?.length === 0) return null;
    const skeletons = [...Array(4).keys()];

    return (
        <>
            <header className="TreatmentContractList__header">
                <TituloH2 titulo="Precontratos"></TituloH2>
                <p className="TreatmentContractList__headerTxt">
                    Precontratos generados a partir de los flujos de residuos configurados para el proyecto donde
                    CoCircular es Operador de Traslado.
                </p>
            </header>

            {state.loading === loadingState.FAILED && state.error && (
                <AlertCard className="card__alert-error TreatmentContractList__error" icon={<IconAlertCircle />}>
                    {t(`errors.${state.error}`, { ns: 'documentation' })}
                </AlertCard>
            )}

            {state.loading === loadingState.PENDING &&
                skeletons.map((_, i) => (
                    <SimpleCard className="document document--is-skeleton" key={i}>
                        <div className="document__infoContainer">
                            <div className="document__icon"></div>
                            <div className="document__info">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </SimpleCard>
                ))}

            {state.loading === loadingState.SUCCEEDED &&
                state.treatmentContractList?.map((treatmentContract, i) => (
                    <SimpleCard className="document" key={i}>
                        <div className="document__infoContainer">
                            <RoundedIcon big={true} icon={<IconFileCheck color={'#7F42F5'} />} />
                            <div className="document__info">
                                <p className="document__date">{getDocumentTitle(treatmentContract)}</p>
                                <SmallText text={getDocumentSubtitle()} />
                            </div>
                        </div>
                        <div className="document__containerButtons">
                            <SmallButton
                                action={() => printTreatmentContract(i)}
                                titulo={t('show.show', { ns: 'common' })}
                                icon={<IconEye />}
                            />
                        </div>
                        <TreatmentContractPrint
                            ref={(element) => (refs.current[i] = element)}
                            content={treatmentContract}
                        />
                    </SimpleCard>
                ))}
        </>
    );
};

export default TreatmentContractList;
