import { sha256 } from 'lib/crypto';
import { AUTH_TOKEN_KEY } from 'modules/auth/domain/AuthConfig';
import { Cache, CacheMetadata } from 'modules/shared';

const databaseName = '360Advisor';
const metaKey = '_meta';
const version = 1;

export class IndexedDbCache<Data extends Array<any>> implements Cache<Data> {
    private db: IDBDatabase | undefined;

    constructor(public readonly config: Cache<Data>['config']) {
        const result = indexedDB.open(databaseName, version);

        result.addEventListener('upgradeneeded', (event) => {
            /**
             * Generates database schema
             * ⚠️ IMPORTANT ⚠️ any change in the schema should be handled here an and
             * === NEEDS === upgrade the version of the database ☝️
             */

            const result = (event.target as IDBOpenDBRequest).result;
            this.db = result;

            result.createObjectStore(this.config.key, { autoIncrement: true });
            result.createObjectStore(metaKey);
        });

        result.addEventListener('success', (event) => {
            this.db = (event.target as IDBOpenDBRequest).result;
        });

        result.addEventListener('error', () => {
            console.error('Error opening the database');
        });
    }

    async cacheQuery(request: () => Promise<Data>) {
        // If the data is not stale, return the data
        const cached = await this.getAll();

        // Check if the data is stale
        const metadata = await this.meta();

        const shouldRevalidate = metadata.stale || !cached || cached.length === 0;

        // If the data is not stale, return cached
        if (!shouldRevalidate) {
            return { data: cached, ...metadata };
        }

        // Fetch data
        const data = await request();

        // Invalidate the cache
        await this.invalidate();

        // Store the data
        const store = await this._getStore(this.config.key);
        for await (const item of data) {
            const add = store.add(item);
            await this._waitFor(add);
        }

        await this.updateMeta();
        const newMetadata = await this.meta();

        return { data, ...newMetadata };
    }

    async invalidate() {
        const store = await this._getStore(this.config.key);
        const clearData = store.clear();
        const meta = await this._getStore(metaKey);
        const clearMeta = meta.delete(this.config.key);
        await this._waitFor(clearData);
        await this._waitFor(clearMeta);
    }

    async meta() {
        // Get the metadata from metadata store
        const now = this._now();
        const meta = await this._getStore(metaKey);
        const get = meta.get(this.config.key);
        const metadata = await this._waitFor<Omit<CacheMetadata, 'stale'> | undefined>(get);
        const signature = await this._signature();

        // If there is no metadata, return stale true and age Infinity
        if (!metadata) return { stale: true, age: Infinity, signature };

        // If the signature is different from stored metadata, the cache is stale
        if (metadata.signature !== signature) {
            return { stale: true, ...metadata };
        }

        // In case of configuration with expires
        if (this.config.expires) {
            const { every, offset } = this.config.expires;
            // Time (in seconds) when the cache should expire
            const expiresAt = Math.ceil(metadata.age / every) * every + offset;

            // Time left to expiration
            const timeToExpiration = expiresAt - now;

            // If the time to expiration is less than 0, the cache is stale
            const stale = timeToExpiration <= 0;

            return { stale, ...metadata };
        }

        // In case of configuration with maxAge
        if (this.config.maxAge !== undefined) {
            const timePast = now - metadata.age;
            const maxAge = this.config.maxAge || 0;

            // If the time past is greater than the maxAge, the cache is stale
            const stale = timePast > maxAge;

            return { stale, ...metadata };
        }

        return { stale: true, age: Infinity, signature };
    }

    /** PRIVATE METHODS */

    private async updateMeta() {
        const signature = await this._signature();
        const meta = await this._getStore(metaKey);
        const put = meta.put({ age: this._now(), signature }, this.config.key);
        await this._waitFor(put);
    }

    private async getAll(): Promise<Data | undefined> {
        const store = await this._getStore(this.config.key);

        const getAll = store.getAll();
        const data = await this._waitFor<Data>(getAll);

        return data;
    }

    private _now() {
        return Math.floor(new Date().getTime() / 1000);
    }

    private async _getStore(name: string): Promise<IDBObjectStore> {
        if (!this.db) {
            throw new Error(`Database ${databaseName} not initialized`);
        }

        return this.db.transaction(name, 'readwrite').objectStore(name);
    }

    private _waitFor<Result extends any>(operacion: IDBRequest): Promise<Result> {
        return new Promise((resolve, reject) => {
            operacion.addEventListener('success', (ev) => resolve((ev.target as IDBRequest<Result>).result));
            operacion.addEventListener('error', (ev) =>
                reject((ev.target as any)?.error || 'Database transaction failed')
            );
        });
    }

    private async _signature() {
        const accessToken = localStorage.getItem(AUTH_TOKEN_KEY);
        const hash = accessToken ? await sha256(accessToken) : 'no-signature';
        return hash;
    }
}
