import { IconAlertTriangle, IconCircleCheck, IconFlag } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface VisitRecordFieldResultProps {
    field: string;
    value: string | number;
    isDangerous: boolean;
}

const VisitRecordFieldResult = ({ field, value, isDangerous }: VisitRecordFieldResultProps) => {
    const [t] = useTranslation();
    const RESULT_TYPES = [
        { status: 'bad', className: 'VisitRecordFieldResult-bad', icon: <IconAlertTriangle /> },
        { status: 'moderate', className: 'VisitRecordFieldResult-moderate', icon: <IconFlag /> },
        { status: 'good', className: 'VisitRecordFieldResult-good', icon: <IconCircleCheck /> },
        { status: 'normal', className: '', icon: null }
    ];
    const [resultType, setResultType] = useState(RESULT_TYPES.at(-1));
    const [resultText, setResultText] = useState('');

    useEffect(() => {
        if (value === null) return;

        const { type, text } = calculateResult();
        const currentResultType = RESULT_TYPES.find((resultType) => resultType.status === type);
        setResultType(currentResultType);
        setResultText(text);
    }, []);

    const calculateResult = () => {
        if (field === 'purity') {
            const text = value + '%';

            if (value === 25 || value === 50) return { type: 'bad', text };
            if (value === 75) return { type: 'moderate', text };
            return { type: 'good', text };
        }

        if (field === 'containerFillPercentage') {
            const text = value + '%';

            if (value === 25 || value === 50) return { type: 'good', text };
            if (value === 75) return { type: 'moderate', text };
            return { type: 'bad', text };
        }

        if (field === 'containerSize') {
            if (value === 'SMALL') return { type: 'normal', text: isDangerous ? '< 150 L' : '< 5 m³' };
            if (value === 'MEDIUM') return { type: 'normal', text: isDangerous ? '150 - 500 L' : '5 - 12 m³' };
            return { type: 'normal', text: isDangerous ? '> 500 L' : '> 12 m³' };
        }

        // correct / wrong
        return value ? { type: 'good', text: t('correct.upperCase') } : { type: 'bad', text: t('incorrect') };
    };

    return (
        <>
            {value === null ? (
                <span>-</span>
            ) : (
                <div className={`VisitRecordFieldResult ${resultType?.className}`}>
                    {resultType?.icon}
                    <span>{resultText}</span>
                </div>
            )}
        </>
    );
};
export default VisitRecordFieldResult;
