import * as api from '../../../api';
import { actions } from '../actions';
import { createPosters } from '../domain/createPosters';

export const loadPosters =
    ({ dispatch }) =>
    async () => {
        try {
            dispatch({ type: actions.pending });

            const lerTypes = await api.getLerTypes();
            const posters = createPosters({ lerTypes });

            dispatch({ type: actions.fulfilled, payload: { posters } });
        } catch (error) {
            dispatch({ type: actions.rejected, payload: { message: error.message } });
        }
    };
