import DocumentationPage from '../components/DocumentationPage';
import NewDocumentPage from '../components/NewDocumentPage';
import DocumentationByTypePage from '../components/DocumentationByTypePage';
import DownloadListPage from '../components/DownloadListPage';
import NewDownloadRequestPage from '../components/NewDownloadRequestPage';

export const DOCUMENTATION_ROUTES = [
    {
        component: DocumentationPage,
        path: '/documents',
        requiredPermission: 'canView',
        section: 'documentation'
    },
    {
        component: NewDocumentPage,
        path: '/documents/create',
        requiredPermission: 'canCreate',
        section: 'documentation'
    },
    {
        component: NewDocumentPage,
        path: '/documents/:id/edit',
        requiredPermission: 'canCreate',
        section: 'documentation'
    },
    {
        component: DocumentationByTypePage,
        path: '/documents/type-list',
        requiredPermission: 'canView',
        section: 'documentation'
    },
    {
        component: NewDownloadRequestPage,
        path: '/documents/download/create',
        requiredPermission: 'canView',
        section: 'documentation'
    },
    {
        component: DownloadListPage,
        path: '/documents/download',
        requiredPermission: 'canView',
        section: 'documentation'
    }
];
