import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withAuth } from '../../context/AuthProvider';
import Layout from '../Layout/Layout';
import { TituloH1 } from '../../components/BasicComponents/Titulos/Titulos';
import PersonalInformationForm from '../../components/ComplexComponents/Profile/PersonalInformationForm';
import PasswordForm from '../../components/ComplexComponents/Profile/PasswordForm';
import GoBack from '../../components/BasicComponents/Buttons/GoBack';
import './EditProfile.scss';

const EditProfile = () => {
    const [t] = useTranslation();
    const history = useHistory();

    const goBack = () => {
        history.push('/profile');
    };

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <TituloH1 titulo={`${t('edit', { ns: 'common' })} ${t('profile.lowerCase', { ns: 'common' })}`} />
            <PersonalInformationForm />
            <PasswordForm />
        </Layout>
    );
};
export default withAuth(EditProfile);
