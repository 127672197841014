import fetcher from 'lib/fetcher';
import { LocationApi } from 'modules/location/domain/LocationApi';
import { Town } from 'modules/location/domain/Town';

export const httpLocationApi: LocationApi = {
    async getTowns(criteria) {
        const params = {
            ...(criteria.search ? { 'nombre.contains': criteria.search } : {}),
            page: criteria.page || 0,
            size: criteria.size || 999
        };

        const { data } = await fetcher.get(`/api/maintenance/towns`, { params });

        const towns: Town[] = data.map((entry) => ({
            id: entry.id,
            name: entry.nombre
        }));

        return towns;
    }
};
