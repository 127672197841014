export const getObjFilter = (formValues) => {
    return [
        {
            label: 'ASC_DATE',
            apiUrl: '&sort=fecha,asc&sort=id,desc',
            typeOf: 'SORT'
        },
        {
            label: 'DESC_DATE',
            apiUrl: '&sort=fecha,desc&sort=id,desc',
            typeOf: 'SORT'
        },
        {
            label: 'ASC_COST',
            apiUrl: '&sort=coste,asc&sort=id,desc',
            typeOf: 'SORT'
        },
        {
            label: 'DESC_COST',
            apiUrl: '&sort=coste,desc&sort=id,desc',
            typeOf: 'SORT'
        },
        {
            label: 'fechaDesde',
            apiUrl: `&fecha.greaterThanOrEqual=${formValues?.fechaDesde}`,
            typeOf: 'FILTER'
        },
        {
            label: 'fechaHasta',
            apiUrl: `&fecha.lessThanOrEqual=${formValues?.fechaHasta}`,
            typeOf: 'FILTER'
        },
        {
            label: 'codLer',
            apiUrl: `&codLerId.equals=${formValues?.codLer?.id}`,
            typeOf: 'FILTER'
        },
        {
            label: 'gestora',
            apiUrl: `&gestoraId.equals=${formValues?.gestora?.id}`,
            typeOf: 'FILTER'
        }
    ];
};
