import { GlobalSettings } from 'features/global-settings';
import NewPromotion from '../pages/NewPromotion/NewPromotion';
import NewPromotionRequest from '../pages/PromotionSettings/NewPromotionRequest';

export const SETTINGS_ROUTES = [
    {
        component: NewPromotion,
        path: '/settings/new-promotion',
        requiredPermission: 'canCreate',
        section: 'promotion'
    },
    {
        component: NewPromotion,
        path: '/promotion/:id/edit',
        requiredPermission: 'canCreate',
        section: 'promotion'
    },
    {
        component: NewPromotionRequest,
        path: '/settings/new-promotion-request',
        requiredPermission: 'canCreate',
        section: 'newPromotionRequest',
        withoutPromotion: true
    },

    // This route must be the last one
    {
        component: GlobalSettings,
        path: '/settings',
        requiredPermission: 'canCreate',
        section: 'globalSettings',
        exact: false
    }
];
