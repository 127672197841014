import { contextStoreAdapter } from 'modules/shared';
import { wasteRegisterStore } from 'modules/waste-register/application/wasteRegisterStore';

const WasteRegisterContext = contextStoreAdapter(wasteRegisterStore)({ name: 'WasteRegisterStore' });

export const WasteRegisterProvider = WasteRegisterContext.Provider;
export const useWasteRegisterStore = WasteRegisterContext.useConsumer;

export const useWasteRegisterApi = {
    findById: WasteRegisterContext.createQueryHook(({ api }) => api.findExtendedById),
    findDIByWasteRegisterId: WasteRegisterContext.createQueryHook(({ api }) => api.findDIByWasteRegisterId),
    findExtraSchemaByPromotionId: WasteRegisterContext.createQueryHook(({ api }) => api.findSaveSchemaByPromotionId),
    save: WasteRegisterContext.createMutationHook(({ api }) => api.save),
    remove: WasteRegisterContext.createMutationHook(({ api }) => api.remove),
    findComputedByCriteria: WasteRegisterContext.createQueryHook(({ api }) => api.findComputedByCriteria)
};
