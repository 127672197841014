import { useTranslation } from 'react-i18next';

export function useNumberFormatter() {
    const [t, { language }] = useTranslation();

    function abreviate(num: number) {
        if (num >= 1_000_000) return t('numbers.millions', { num: abreviateDecimals(num / 1_000_000) });
        if (num >= 1000) return t('numbers.thousands', { num: abreviateDecimals(num / 1000) });

        return abreviateDecimals(num).toString();
    }

    const abreviateDecimals = (value?: number | null) => {
        const num = value || 0;

        if (num >= 100) return format(num, { digits: 0 });
        if (num >= 10) return format(num, { digits: 1 });
        if (num >= 1) return format(num, { digits: 2 });
        if (num > 0) return format(num, { digits: 3 });
        return num.toString();
    };

    const format = (num: number, config = { digits: 2 }) => {
        return Intl.NumberFormat(language, {
            maximumFractionDigits: config.digits
        }).format(num);
    };

    return { abreviate, format };
}
