import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { PromotionPreviewCriteria } from 'modules/promotion/domain/promotion/PromotionPreviewCriteria';
import { PromotionStore } from '../../domain/PromotionStore';

type Config = {
    mode?: PromotionStore['actions']['loadPromotionPreviewsPending']['payload']['mode'];
};

export const loadPromotionPreviews =
    (criteria: PromotionPreviewCriteria, config?: Config): Thunk<PromotionStore> =>
    async (store, { api }) => {
        const { mode = 'replace' } = config || {};

        try {
            store.dispatch('loadPromotionPreviewsPending', { mode });

            const { promotionPreviews, total } = await api.getPromotionsPreview({ ...criteria }, mode);

            store.dispatch('loadPromotionPreviewsFulfilled', {
                promotionPreviews,
                total,
                mode,
                status: criteria.status
            });
        } catch (error: any) {
            store.dispatch('loadPromotionPreviewsRejected', { error });
        }
    };

export const loadPromotionPreviewsReducers: Pick<
    Reducers<PromotionStore>,
    'loadPromotionPreviewsPending' | 'loadPromotionPreviewsFulfilled' | 'loadPromotionPreviewsRejected'
> = {
    loadPromotionPreviewsFulfilled: (state, payload) => {
        return {
            ...state,
            error: null,
            loadingPromotionPreviews: 'succeeded',
            promotionPreviews:
                payload.mode === 'merge'
                    ? [...state.promotionPreviews, ...payload.promotionPreviews]
                    : payload.promotionPreviews,
            totalPromotionPreviews: payload.total !== undefined ? payload.total : state.totalPromotionPreviews,
            promotionPreviewCriteria: {
                ...state.promotionPreviewCriteria,
                page: payload.mode === 'merge' ? (state.promotionPreviewCriteria.page || 0) + 1 : 0
            }
        };
    },
    loadPromotionPreviewsPending(state) {
        return { ...state, loadingPromotionPreviews: 'pending' };
    },
    loadPromotionPreviewsRejected(state, payload) {
        return {
            ...state,
            loadingPromotionPreviews: 'failed',
            promotionPreviews: [],
            error: payload.error
        };
    }
};
