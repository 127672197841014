import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import wasteManagerServices from '../../../api/wasteManager/wasteManager.services';
import { withAuth } from '../../../context/AuthProvider';
import { customStyles, hasMore } from './helpers';

const WasteManagerAutocomplete = ({
    name = 'gestora',
    addToArray = false,
    showUserLinkedWasteManagers = false,
    label,
    optionLabel = 'empresa',
    ...props
}) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState();
    const { setValues, values, required, className, clereable, validacion, promocion } = props;

    const loadOptions = async (search, loadedOptions, { page }) => {
        let options;
        if (!search) {
            options = await wasteManagerServices.filterWasteManager('');
        } else {
            options = await wasteManagerServices.filterWasteManager(`?empresa.contains=${search}`);
        }
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const loadOptionsUserLinkedWasteManagers = async (search) => {
        let options = props?.user?.wasteManagers;

        if (search) {
            options = options.filter((wasteManager) => wasteManager.name.toLowerCase().includes(search.toLowerCase()));
        }

        return {
            options,
            hasMore: hasMore(options)
        };
    };

    const selectOption = (item) => {
        let newValue = item;
        if (item === null) {
            setValues({ ...values, [name]: null });
            return;
        }

        if (addToArray) {
            const newWasteManager = { id: item?.id, name: item?.empresa };
            newValue = [newWasteManager, ...values[name]];
        }

        setValues({ ...values, [name]: newValue });
    };

    const emptyInput = () => {
        if (validacion === true && required && !values[name]) {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    useEffect(() => {
        emptyInput();
    }, [promocion?.id, validacion]);

    const getLoadOptions = () => {
        if (showUserLinkedWasteManagers) {
            return loadOptionsUserLinkedWasteManagers;
        } else {
            return loadOptions;
        }
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {label ?? t('wasteManager', { ns: 'common' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={getLoadOptions()}
                onChange={selectOption}
                getOptionLabel={(option) => option[optionLabel]}
                getOptionValue={(option) => option}
                value={values[name]}
                styles={customStyles(notComplete)}
                placeholder=""
                additional={{
                    page: 0
                }}
                isClearable={clereable ? clereable : true}
                defaultInputValue={null}
            />
        </div>
    );
};
export default withAuth(WasteManagerAutocomplete);
