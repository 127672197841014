import { produce } from 'immer';
import { DiExtractionCriteria } from 'modules/di-extraction/domain/DiExtractionCriteria';
import { DiExtractionStore } from 'modules/di-extraction/domain/DiExtractionStore';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

export const fetchDiExtractionsCounter =
    (criteria?: DiExtractionCriteria): Thunk<DiExtractionStore> =>
    async (store, { api }) => {
        try {
            const counters = await api.getDiExtractionsCounters(criteria);

            store.dispatch('fetchCountersFulfilled', { ...counters });
        } catch (e) {
            store.dispatch('errorSetted', { error: e as Error });
        }
    };

export const fetchDiExtractionsCounterReducers: Pick<Reducers<DiExtractionStore>, 'fetchCountersFulfilled'> = {
    fetchCountersFulfilled(state, payload) {
        return produce(state, (draft) => {
            draft.counters = { ...draft.counters, ...payload };
            draft.total = payload.totalApplicable || 0;
        });
    }
};
