import { IconInfoCircle, IconReplace } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import IconButton from 'components/BasicComponents/Buttons/Small/IconButton';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import SuggestionCard from 'components/BasicComponents/Cards/SuggestionCard';
import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { wasteFlowHooks } from 'modules/waste-flow';
import { WasteHandling } from 'modules/waste-flow/domain/WasteHandling';
import './ModifyWasteHandling.scss';
import { useWasteHandlingOptions } from './hooks/useWasteHandlingOptions';

export interface ModifyWasteHandlingProps {
    wasteFlowId?: number;
    wasteHandling: WasteHandling;
    onSaved?: () => void;
}

const ModifyWasteHandling = (props: ModifyWasteHandlingProps) => {
    const saveWasteHandling = wasteFlowHooks.useSaveWasteHandling();

    const [t] = useTranslation('newPpgcl');
    const [open, setOpen] = useState(false);

    const options = useWasteHandlingOptions(props);

    const canSave = !!options.option && !!props.wasteFlowId;

    const handleSubmit = async () => {
        if (!options.option) return;
        if (!props.wasteFlowId) return;

        const { loading } = await saveWasteHandling.mutate({
            wasteHandlingId: options.option.value,
            wasteFlowId: props.wasteFlowId
        });

        if (loading === 'failed') return;

        props.onSaved?.();
        setOpen(false);
    };

    // Resets the option when the modal is closed
    useEffect(() => {
        if (open === false) options.setOption(undefined);
    }, [open]);

    return (
        <>
            <Tooltip msg={t('modifyWasteHandling.title')}>
                <IconButton action={() => setOpen(true)} icon={<IconReplace />} />
            </Tooltip>
            <SimplePopover
                openModal={open}
                className="ModifyWasteHandling__modal"
                setOpenModal={setOpen}
                title={t('modifyWasteHandling.title')}
            >
                <Trans
                    i18nKey="modifyWasteHandling.description"
                    ns="newPpgcl"
                    components={{ p: <p className="ModifyWasteHandling__modal-message" /> }}
                ></Trans>
                <SelectAutocomplete
                    className="ModifyWasteHandling__modal-select"
                    label={t('modifyWasteHandling.selectWasteHandling')}
                    value={options.option}
                    onChange={(o) => {
                        if (!o || Array.isArray(o)) return;
                        options.setOption(o);
                    }}
                    loadOptions={options.load}
                />
                <div className="ModifyWasteHandling__modal-alerts">
                    <AlertCard icon={<IconInfoCircle size={24} />}>
                        <div className="ModifyWasteHandling__modal-message">
                            {t('modifyWasteHandling.alertTraceabilitiesChanged')}
                        </div>
                    </AlertCard>
                    <SuggestionCard title="">
                        <div className="ModifyWasteHandling__modal-message">
                            {t('modifyWasteHandling.alertNotAvailable')}
                        </div>
                    </SuggestionCard>
                </div>
                <footer className="ModifyWasteHandling__modal-footer">
                    <SmallButton
                        action={() => setOpen(false)}
                        titulo={t('modifyWasteHandling.cancel')}
                        {...({} as any)} // Just to ignore optional fields
                    />
                    <SmallButton
                        disabled={!canSave}
                        color="purple"
                        action={handleSubmit}
                        titulo={t('modifyWasteHandling.accept')}
                        {...({} as any)} // Just to ignore optional fields
                    />
                </footer>
            </SimplePopover>
        </>
    );
};

export default ModifyWasteHandling;
