import { contextStoreAdapter } from 'modules/shared';
import { supportStore } from 'modules/support/application/supportStore';

const SupportContext = contextStoreAdapter(supportStore)({ name: 'SupportContext' });

export const SupportProvider = SupportContext.Provider;
export const useSupportStore = SupportContext.useConsumer;

export const useSupportTopics = SupportContext.createQueryHook(({ api: api }) => api.findTopics.bind(api));
export const useSupportSendTicket = SupportContext.createMutationHook(({ api: api }) => api.sendTicket.bind(api));
