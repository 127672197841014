import { TituloH3 } from '../Titulos/Titulos';
import SimpleCard from './SimpleCard';
import './Cards.scss';

const AlertCard = ({ children, title = '', icon, className = '', testId = '' }) => {
    return (
        <SimpleCard className={'card__alert ' + className}>
            {icon && icon}
            <div className="card__alert__content" data-testid={testId}>
                {title !== '' && <TituloH3 titulo={title} />}
                <div>{children}</div>
            </div>
        </SimpleCard>
    );
};

export default AlertCard;
