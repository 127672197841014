import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AutomaticReadingPage from './components/AutomaticReadingPage/AutomaticReadingPage';

const AutomaticReading = () => {
    const { url } = useRouteMatch();

    return (
        <Switch>
            <Route component={AutomaticReadingPage} path={[url, `${url}/:inbox`]} exact />
        </Switch>
    );
};

export default AutomaticReading;
