import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import { useWasteFlow } from 'modules/waste-flow';
import { WasteHandling } from 'modules/waste-flow/domain/WasteHandling';
import { useState } from 'react';

export function useWasteHandlingOptions(props: { wasteHandling: WasteHandling }) {
    const [, , { api }] = useWasteFlow();

    const [option, setOption] = useState<SelectOption | undefined>(undefined);

    const [load] = useAsyncOptions({
        request: async ({ search, page }) => {
            if ((page || 0) > 0) return [];
            const data = await api
                .getWasteHandling({
                    wasteManagerId: props.wasteHandling.manager.id || NaN,
                    lerId: props.wasteHandling.ler.id || NaN
                })
                // Exclude current wasteHandling
                .then((wasteHandling) => wasteHandling.filter((w) => w.id !== props.wasteHandling.id))
                // search by label
                .then((wasteHandling) =>
                    wasteHandling.filter(
                        (w) =>
                            w.ler.code.toLowerCase().includes(search?.toLowerCase() || '') ||
                            w.treatment.code.toLowerCase().includes(search?.toLowerCase() || '')
                    )
                );
            return data;
        },
        adapter: (wasteHandling) => wasteHandlingOptionAdapter(wasteHandling)
    });

    return { load, option, setOption };
}

export const wasteHandlingOptionAdapter = (wasteHandling: WasteHandling): SelectOption => ({
    value: wasteHandling.id,
    label: `${wasteHandling.treatment.code}`
});
