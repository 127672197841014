import { GridOptions } from 'ag-grid-community';

import { useDateFormatter } from 'hooks/useDateFormatter';
import { AG_GRID_LOCALE_ES } from 'i18n/agGrid_ES';
import { WasteRegisterComputed } from 'modules/waste-register/domain/WasteRegisterComputed';
import { useMemo } from 'react';
import { currencyFormatDE } from 'utils/helpers/general.helpers';

type Options = GridOptions<WasteRegisterComputed>;

/* Inspiration from: https://stackoverflow.com/a/51445435 */
const isFirstColumn = (cell) => {
    const { column } = cell;
    const { parent } = column;

    // maximum of 2 nested levels
    const firstColumnId =
        parent?.parent?.displayedChildren?.[0].displayedChildren?.[0].colId ?? parent?.displayedChildren?.[0].colId;
    return column.colId === firstColumnId;
};

const column = {
    sortable: true,
    filter: true,
    wrapHeaderText: true,
    cellStyle: (params) => {
        const cellType = params.colDef.cellDataType;
        let extraStyles = {};

        if (['number', 'boolean'].includes(cellType)) {
            extraStyles = { ...extraStyles, justifyContent: 'flex-end' };
        }

        if (isFirstColumn(params)) {
            extraStyles = { ...extraStyles, borderLeft: '1px solid rgb(205, 200, 218)' };
        }

        return extraStyles;
    }
};

export function useWasteRegisterGrid(schema) {
    const { format } = useDateFormatter();

    const getSchemaWithColumnInfo = () => {
        if (!schema) return [];

        // we only have 2 levels
        const updatedSchema = schema.map((entry) => ({
            ...column,
            ...entry,
            children: entry.children && entry.children.map((nestedEntry) => ({ ...column, ...nestedEntry }))
        }));

        return updatedSchema;
    };

    // By memoizing the options, we avoid re-creating the object every time the component re-renders to prevent Ag-Grid from re-rendering the entire grid
    const options: Options = useMemo(
        () => ({
            localeText: AG_GRID_LOCALE_ES,
            rowModelType: 'clientSide',
            dataTypeDefinitions: {
                number: {
                    extendsDataType: 'number',
                    baseDataType: 'number',
                    // replace -1 by empty cell. backend needs to send -1 when null due to json schema restriction
                    valueFormatter: ({ value }) => (value == null ? '' : `${currencyFormatDE(value, 4)}`)
                },
                dateType: {
                    extendsDataType: 'dateString',
                    baseDataType: 'dateString',
                    valueFormatter: ({ value }) => (value == null ? '' : format(value, 'DD/MM/YY'))
                }
            },
            headerHeight: 40,
            groupHeaderHeight: 36,
            tooltipShowDelay: 500,
            columnDefs: getSchemaWithColumnInfo()
        }),
        [schema]
    );

    return { options };
}
