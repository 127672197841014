import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import './ProgressBar.scss';

interface ProgressBarProps {
    percentage: number;
    displayPercentageText: boolean;
    style?: any;
}

const ProgressBar = ({ percentage, displayPercentageText, style }: ProgressBarProps) => {
    return (
        <div style={style}>
            <Tooltip className="ProgressBar" msg={`El avance del proyecto es del ${percentage}%`}>
                <div className="ProgressBar__wrapper">
                    <div style={{ width: percentage + '%' }} className="ProgressBar__progress">
                        {displayPercentageText ? percentage + ' %' : ''}
                    </div>
                </div>
            </Tooltip>
        </div>
    );
};

export default ProgressBar;
