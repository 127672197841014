import protocolPdf from 'assets/documentation/Documentación Obligatoria.pdf';
import bestPracticesPaisajismo from 'assets/documentation/Guía Práctica Gestión RCD - Paisajismo, urbanización.pdf';
import bestPracticesCivil from 'assets/documentation/Guía Práctica Gestión RCD - Civil.pdf';
import bestPracticesEdificacion from 'assets/documentation/Guía Práctica Gestión RCD - Edificación.pdf';
import bestPracticesRenovables from 'assets/documentation/Guía Práctica Gestión RCD - Renovables.pdf';

// protocolo, guia de buenas practicas -> documentos pre-loaded en los proyectos
const PROTOCOL_DOC_TYPE = 417751;
const BEST_PRACTICES_DOC_TYPE = 442551;
const defaultBestPracticesDoc = bestPracticesEdificacion;

export const createPermanentDocuments = (buildingDocs, promotionTypeId) => {
    const buildingDocsCopy = JSON.parse(JSON.stringify(buildingDocs));
    const protocol = buildingDocsCopy.find((docType) => docType.id === PROTOCOL_DOC_TYPE);
    const bestPractices = buildingDocsCopy.find((docType) => docType.id === BEST_PRACTICES_DOC_TYPE);

    protocol.documents = [getDocument(1111, 'Documentación obligatoria.pdf', protocolPdf)];
    protocol.size = 1;
    protocol.permanent = true;

    bestPractices.documents = [getBestPracticesDocument(promotionTypeId)];
    bestPractices.size = 1;
    bestPractices.permanent = true;

    return buildingDocsCopy;
};

const getBestPracticesDocument = (promotionTypeId) => {
    let doc = bestPracticesDocuments.find((document) => document.promotionTypeIds.includes(promotionTypeId));
    doc = doc.document || defaultBestPracticesDoc;

    const mappedDoc = getDocument(2222, 'Guía Práctica Gestión RCD.pdf', doc);
    return mappedDoc;
};

const getDocument = (id, docTitle, docContent) => {
    return {
        id,
        name: docTitle,
        url: docContent,
        remarks: '',
        date: '',
        type: null
    };
};

const bestPracticesDocuments = [
    {
        promotionTypeIds: [9], // paisaje
        document: bestPracticesPaisajismo
    },
    {
        promotionTypeIds: [3, 4], // civil comunicaciones, civil abastecimientos
        document: bestPracticesCivil
    },
    {
        promotionTypeIds: [1, 2, 5, 6, 10],
        document: bestPracticesEdificacion
    },
    {
        promotionTypeIds: [7, 8], // parque eolico, parque fotovoltaico
        document: bestPracticesRenovables
    }
];
