import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useUpdatePromotion } from 'modules/promotion';
import { promotionDataFormSchemaAdapter } from '../adapters/promotionDataFormSchemaAdapter';
import { promotionUpdaterAdapter } from '../adapters/promotionUpdaterAdapter';
import { PromotionDataFormProps } from '../PromotionDataForm';
import { PromotionDataFormSchema } from '../schemas/PromotionDataFormSchema';

export function usePromotionDataForm(props: PromotionDataFormProps) {
    const update = useUpdatePromotion();
    const [, analytics] = useAnalyticsStore();

    const form = useForm<PromotionDataFormSchema>({
        resolver: zodResolver(PromotionDataFormSchema, { async: true })
    });

    const save = async (e: React.BaseSyntheticEvent) => {
        await form.trigger(); // 1. Trigger form validation to update error state
        e.target.reportValidity(); // 2. Show native validation message if there are errors

        form.handleSubmit(async (data) => {
            analytics.event(AnalyticsEvents.PROMOSETTINGS_SAVE_CLICKED);
            const payload = promotionUpdaterAdapter(data);
            await update.mutate(payload);
        })(e);
    };

    const reset = () => {
        if (!props.promotion) return;
        form.reset(promotionDataFormSchemaAdapter(props.promotion));
    };

    useEffect(() => {
        reset();
    }, [props.promotion?.id]);

    return { form, save };
}
