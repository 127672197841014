import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import wasteManagerServices from '../../../api/wasteManager/wasteManager.services';
import { customStyles, hasMore } from './helpers';

const TransportistaSelectAutocomplete = (props) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState(false);

    const { transportista } = props.values;
    const { required, className, setValues, values, editarTrazabilidad } = props;

    const selectOptionTransportista = (item) => {
        if (item === null) {
            setValues({ ...values, transportista: '' });
        } else {
            setValues({ ...values, transportista: item });
        }
    };

    const loadOptionsTransportista = async (search, loadedOptions, { page }) => {
        let options;
        if (!search) {
            const filtro = `?page=${page}`;
            options = await wasteManagerServices.filterWasteManager(filtro);
        } else {
            const filtro = `?empresa.contains=${search}`;
            options = await wasteManagerServices.filterWasteManager(filtro);
        }
        if (props.newActivity && values?.gestora) {
            options = [];
        }
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };
    useEffect(() => {
        inputVacio();
    }, [props.validacion, transportista]);

    const inputVacio = () => {
        if (
            props.validacion === true &&
            required &&
            (transportista === '' || transportista === undefined || transportista === null)
        ) {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    const optionsMessage = () => {
        if (props.newActivity && values?.gestora) {
            return 'No puedes seleccionar un transportista con una gestora seleccionada';
        }
    };

    const key = () => {
        if (props.newActivity && values?.gestora) {
            return JSON.stringify(values.gestora);
        }
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {t('transporter', { ns: 'common' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                key={key()}
                loadOptions={loadOptionsTransportista}
                onChange={selectOptionTransportista}
                getOptionLabel={(option) => option.empresa}
                getOptionValue={(option) => option}
                value={transportista}
                styles={customStyles(notComplete)}
                placeholder=""
                additional={{
                    page: 0
                }}
                isClearable={editarTrazabilidad ? false : true}
                className="autocomplete"
                noOptionsMessage={() => optionsMessage()}
            />
        </div>
    );
};
export default TransportistaSelectAutocomplete;
