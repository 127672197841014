import { httpSupportApi } from 'modules/support/infrastructure/api/httpSupportApi';
import { SupportProvider } from 'modules/support/infrastructure/react/SupportContext';
import SupportWidget from './SupportWidget';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <SupportProvider api={httpSupportApi}>
        <SupportWidget />
    </SupportProvider>
);
