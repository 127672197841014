import { useTranslation } from 'react-i18next';

import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import InputFecha from 'components/BasicComponents/Inputs/InputFecha';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import TextArea from 'components/BasicComponents/Inputs/TextArea';
import DynamicForm from 'components/ComplexComponents/DynamicForm';
import WasteFlowCarrierSelector from 'components/ComplexComponents/WasteFlowCarrierSelector';
import TraceabilityForm from 'features/traceabilities/components/TraceabilityForm';
import { useRemoveTraceability } from 'modules/traceabilities';
import { TraceabilitiesRemoveOperations } from 'modules/traceabilities/domain/TraceabilitiesOperations';
import { useWasteRegisterApi, WasteRegisterExtended } from 'modules/waste-register';
import WasteRegisterStackeholdersNotification from './components/WasteRegisterStackeholdersNotification';
import { useTraceabilityForm } from './hooks/useTraceabilityForm';
import { useWasteRegisterForm } from './hooks/useWasteRegisterForm';
import './WasteRegisterForm.scss';
import { useWasteRegisterNotifications } from './hooks/useWasteRegisterNotifications';

export type WasteRegisterFormProps = {
    id?: string;
    promotionId: number;
    onSave?: (register: WasteRegisterExtended) => void;
    onRemove?: () => void;
};

const WasteRegisterForm = (props: WasteRegisterFormProps) => {
    const { promotionId } = props;
    const { t } = useTranslation('wasteRegister');
    const registerSchema = useWasteRegisterApi.findExtraSchemaByPromotionId({ promotionId });

    const registerForm = useWasteRegisterForm(props);
    const traceabilityForm = useTraceabilityForm(props);
    const traceabilityRemove = useRemoveTraceability();

    const notifications = useWasteRegisterNotifications();

    const save = async () => {
        const endSave = async (data: WasteRegisterExtended) => {
            props.onSave?.(data);
            notifications.created(data.id);
        };

        // 1. Save the waste register
        const register = await registerForm.save();
        if (register.error) return;

        // 2. Ask user if want to create a traceability (if not enabled or not linked yet)
        if (traceabilityForm.isEnabled === false && registerForm.mode === 'create') {
            const wantToCreateTraceability = window.confirm(t('form.traceability.wantToCreate'));
            traceabilityForm.enable(wantToCreateTraceability);

            if (!wantToCreateTraceability) {
                endSave(register.data);
                return;
            }
        }

        // 3. Save the traceability (if enabled)
        if (traceabilityForm.isEnabled === true) {
            const payload = { wasteRegisterId: register.data?.id };
            const traceability = await traceabilityForm.save(payload);
            if (traceability.error) return;
            endSave(register.data);
            return;
        }

        if (registerForm.mode === 'edit') endSave(register.data);
    };

    const remove = async () => {
        if (!window.confirm(t('form.remove.confirm'))) return;

        const { traceabilityId } = registerForm.data;

        if (traceabilityId) {
            const { error } = await traceabilityRemove.mutate({
                operation: TraceabilitiesRemoveOperations.REMOVE,
                traceability: { id: traceabilityId }
            });
            if (error) return;
        }

        if (registerForm.data.id) {
            const { error } = await registerForm.remove();
            if (error) return;
        }

        props.onRemove?.();
        notifications.removed();
    };

    const modifiers = [
        registerForm.error ? 'WasteRegisterForm--mutation-error' : '',
        registerForm.isLoading ? 'WasteRegisterForm--loading' : ''
    ];

    return (
        <div className={`WasteRegisterForm ${modifiers.join(' ')}`}>
            <header className="WasteRegisterForm__header">
                <div className="WasteRegisterForm__header-content">
                    <strong>{t('form.header.partA.title')}</strong>
                    <p>{t('form.header.partA.subtitle')}</p>
                </div>
            </header>

            <div data-testid="WasteRegisterForm">
                <form className={`WasteRegisterForm__form`}>
                    <ErrorCard
                        error={Array.isArray(registerForm.error) ? registerForm.error[0] : registerForm.error}
                    ></ErrorCard>

                    <div className="WasteRegisterForm__flow">
                        <WasteFlowCarrierSelector
                            key={registerForm.data?.flow?.id}
                            promotionId={promotionId}
                            flow={registerForm.data?.flow}
                            onFlowChange={(flow) => registerForm.update({ flow: flow || undefined })}
                        />

                        <WasteRegisterStackeholdersNotification wasteRegister={registerForm.data} id={props.id} />
                    </div>

                    <InputFecha
                        inputContainer="WasteRegisterForm__field --small"
                        label={t('form.movementDate.label')}
                        name="date"
                        value={registerForm.data?.movementDate}
                        onChange={({ target }) => {
                            registerForm.update({ movementDate: target.value });
                        }}
                        notLimit={true}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />

                    <InputNumber
                        inputContainer="WasteRegisterForm__field --small"
                        label={t('form.weight.label')}
                        name="weight"
                        value={registerForm.data?.weight}
                        onChange={({ target }) => {
                            registerForm.update({ weight: parseInt(target.value) });
                        }}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />

                    <InputNumber
                        inputContainer="WasteRegisterForm__field --small"
                        label={t('form.volume.label')}
                        name="volume"
                        value={registerForm.data?.volume}
                        onChange={({ target }) => {
                            registerForm.update({ volume: parseInt(target.value) });
                        }}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />

                    <div className="WasteRegisterForm__observation">
                        <TextArea
                            id="remarks"
                            label={t('form.observation.label')}
                            placeholder={t('form.observation.placeholder')}
                            name="remarks"
                            value={registerForm.data?.observation}
                            onChange={({ target }) => {
                                registerForm.update({ observation: target.value });
                            }}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                    </div>
                </form>

                <DynamicForm
                    ref={registerForm.$extra}
                    schema={registerSchema.data}
                    defaultValues={registerForm.data.extra}
                    onChanges={function (extra) {
                        registerForm.update({ extra });
                    }}
                />
            </div>

            {(registerForm.data.traceabilityId || traceabilityForm.isEnabled) && (
                <>
                    <header ref={traceabilityForm.headerRef} className="WasteRegisterForm__header">
                        <div className="WasteRegisterForm__header-content">
                            <strong>{t('form.header.partB.title')}</strong>
                            <p>{t('form.header.partB.subtitle')}</p>
                        </div>
                    </header>

                    <TraceabilityForm
                        ref={traceabilityForm.ref}
                        traceabilityId={registerForm.data.traceabilityId || undefined}
                        disableNotifyOnSave
                        traceability={
                            // Initilize traceability with waste register
                            !registerForm.data.traceabilityId
                                ? {
                                      ppgcl: registerForm.data.flow,
                                      volume: registerForm.data.volume,
                                      observations: registerForm.data.observation || ''
                                  }
                                : undefined
                        }
                        hideSubmitBtn
                    />
                </>
            )}

            <footer className="WasteRegisterForm__footer">
                {registerForm.mode === 'edit' && (
                    <BasicButton
                        testid="WasteRegisterForm__remove"
                        color="red"
                        type="button"
                        action={remove}
                        text={t('form.remove.button')}
                        iconPosition="left"
                        isLoading={registerForm.isLoading}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                )}

                <div style={{ marginRight: 'auto' }}></div>

                {registerForm.mode === 'edit' && !registerForm.data.traceabilityId && !traceabilityForm.isEnabled && (
                    <BasicButton
                        color="white"
                        type="button"
                        action={() => traceabilityForm.enable(true)}
                        text={t('form.createTraceability.button')}
                        iconPosition="left"
                        isLoading={registerForm.isLoading}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                )}

                <BasicButton
                    testid="WasteRegisterForm__submit"
                    color="purple"
                    type="button"
                    action={save}
                    text={t('form.submit.button')}
                    iconPosition="left"
                    isLoading={registerForm.isLoading}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </footer>
        </div>
    );
};

export default WasteRegisterForm;
