import { locationStore } from 'modules/location/application/locationStore';
import { contextStoreAdapter } from 'modules/shared';

const LocationContext = contextStoreAdapter(locationStore)({
    name: 'LocationContext'
});

export const LocationProvider = LocationContext.Provider;
export const useLocationStore = LocationContext.useConsumer;

export const useTowns = LocationContext.createQueryHook(({ api }) => api.getTowns);
