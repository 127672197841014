import { TituloH2 } from '../Titulos/Titulos';
import SimpleCard from '../Cards/SimpleCard';
import './Buscador.scss';

const Buscador = ({ children, titulo = 'Buscador de...', column = false }) => {
    return (
        <SimpleCard>
            <div className={`buscador ${column && 'buscador-vertical'}`}>
                {titulo !== null && titulo !== '' && <TituloH2 titulo={titulo} />}
                <div className="buscador__container">{children}</div>
            </div>
        </SimpleCard>
    );
};
export default Buscador;
