import fetcher from '../../../lib/fetcher';

export const traceabilityDocuments = async (id) => {
    const URL = `/api/tracing/get-documents-by-traceability?trazabilidadId=${id}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
