import { IconFile, IconHelp, IconVideo } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { SUPPORT_CONFIG } from 'features/support/config/support.config';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useResourcesState, useUpdateResourceState } from 'modules/onboarding';
import { OnboardingResourceStates } from 'modules/onboarding/domain/paths/resources/OnboardingResourceStates';
import './SupportResources.scss';

type SupportResourcesProps = {
    onResourceClick?: () => void;
};

const SupportResources = (props: SupportResourcesProps) => {
    const [t] = useTranslation('support');
    const location = useLocation();
    const [, analytics] = useAnalyticsStore();

    const { mutate: updateResourceState } = useUpdateResourceState();
    const resources = useResourcesState();

    const [{ faqs, videos }] = Object
        // get entries
        .entries(SUPPORT_CONFIG)
        // find resources that match the current path
        .filter(([path]) => matchPath(location.pathname, { path, exact: false, strict: false }))
        // Get resources
        ?.map(([, resources]) => resources)
        ?.flat();

    return (
        <>
            <div className="SupportResources">
                <header className="SupportResources__topicHeader">{t('resources')}</header>
                <div className="SupportResources__resources">
                    {videos.map(
                        ({ video, id }) =>
                            video && (
                                <a
                                    href={`https://www.loom.com/share/${video?.id}`}
                                    className="SupportResources__faqLink"
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={async () => {
                                        // sends an event to the analytics store
                                        const event = `${AnalyticsEvents.SUPPORT_VIDEO_CLICKED}_${video?.id}`;
                                        analytics.event(event as AnalyticsEvents);

                                        // updates the resource state to SEEN
                                        const { SEEN } = OnboardingResourceStates;
                                        await updateResourceState({ id: video.id, state: SEEN });

                                        // refetches the resources
                                        resources.refetch();
                                    }}
                                >
                                    <IconVideo size={16} />
                                    {t(`onboarding:resource.${id}.title`)}
                                </a>
                            )
                    )}
                    {faqs.map((faq) => (
                        <Link
                            onClick={() => {
                                const event = `${AnalyticsEvents.SUPPORT_FAQ_CLICKED}_${faq?.id}`;
                                analytics.event(event as AnalyticsEvents);

                                props.onResourceClick?.();
                            }}
                            to={`/help/faq/${faq.id}`}
                            className="SupportResources__faqLink"
                        >
                            <IconFile size={16} />
                            {faq.title}
                        </Link>
                    ))}
                    <Link onClick={props.onResourceClick} to={`/help`} className="SupportResources__faqLink">
                        <IconHelp size={16} />
                        {t('helpCenter')}
                    </Link>
                </div>
            </div>
        </>
    );
};

export default SupportResources;
