import { useAuth } from 'modules/auth';
import { usePromotion } from 'modules/promotion';

export function usePromotionDataCanEdit() {
    const [{ promotion }] = usePromotion();
    const [{ user }] = useAuth();

    const mainEnterprise = promotion?.relatedEnterprises.find((re) => re.mainEnterprise);

    const isAdmin = user?.authorities.includes('ROLE_ADMIN');
    const isOwner = user?.enterprise?.cif === mainEnterprise?.enterpriseCif;
    const canEdit = isAdmin || isOwner;

    return { canEdit, allowedEnterprise: mainEnterprise };
}
