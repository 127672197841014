import { Promotion } from 'modules/promotion';
import { PromotionDataFormSchema } from '../schemas/PromotionDataFormSchema';

export function promotionDataFormSchemaAdapter(promotion: Promotion): PromotionDataFormSchema {
    return {
        id: promotion?.id || 0,
        name: promotion?.nombre || '',
        numHouses: promotion?.promotionType?.additionalProperties?.numHouses || 0,
        power: promotion?.promotionType?.additionalProperties?.power || 0,
        nima: promotion?.nima,
        pem: promotion?.pem || 0,
        surface: promotion?.surface || 0,
        type: {
            id: promotion?.promotionType?.id || 0,
            name: promotion?.promotionType?.nombre || ''
        },
        town: {
            id: promotion?.direccion?.municipio?.id || 0,
            name: promotion?.direccion?.municipio?.nombre || ''
        },
        address: promotion?.direccion?.direccion || '',
        postalCode: promotion?.direccion?.postalCode || '',
        startDate: new Date(promotion?.fechaInicio),
        endDate: new Date(promotion?.fechaFin)
    };
}
