import { ReactElement } from 'react';
import { IconInfoCircle, IconReport, IconTruck } from '@tabler/icons';

export interface FaqSection {
    id: number;
    titleKey: string;
    icon: ReactElement;
    guides: Guide[];
}

interface Guide {
    id: string;
    title: string;
}

export const FAQ_BASE_URL = 'https://app.uphint.com/guide/';

export const FAQ_SECTIONS: FaqSection[] = [
    {
        id: 0,
        titleKey: 'general',
        icon: <IconInfoCircle />,
        guides: [
            {
                id: 'd0d02f86-7ea0-4920-94ac-6a0dac969ca4',
                title: '360º Advisor primeros pasos'
            },
            {
                id: 'daf58fed-4a0e-440f-b1d0-d0ae2403231a',
                title: '¿Cómo va mi proyecto?'
            },
            {
                id: '6d79eb11-90fb-4936-a303-492dbb829a59',
                title: '¿Qué es el "Impacto"?'
            },
            {
                id: '268b4c2a-cd83-465e-af7b-1dbee74cbd05',
                title: '¿Cómo creo mis flujos de residuos?'
            },
            {
                id: 'eb207c8b-c441-4a8b-85ac-50fad2980c06',
                title: '¿Cómo gestiono mis usuarios?'
            }
        ]
    },
    {
        id: 2,
        titleKey: 'traceability',
        icon: <IconTruck />,
        guides: [
            {
                id: 'da10ea37-2bd4-4b7d-8226-fc74d8e2b180',
                title: '¿Cómo crear traslados a partir de DIs?'
            },
            {
                id: 'ad616192-efaa-47a0-8c96-aac1a7f3a3a1',
                title: '¿Cómo editar o validar traslados (DIs)?'
            },
            {
                id: '37851613-d739-4cd0-8b21-eb6709b6aec1',
                title: '¿Cómo creo el registro de residuos de mi centro?'
            },
            {
                id: '91b0e401-83a7-4ddd-9130-48c9fbd57646',
                title: '¿Cómo interpretar trazabilidad?'
            }
        ]
    },
    {
        id: 3,
        titleKey: 'onSiteExecution',
        icon: <IconReport />,
        guides: [
            {
                id: '22c42ba0-9b5e-42bc-9188-e5e74b7df15d',
                title: 'Visita a obra: guía y actas'
            }
        ]
    }
];
