import { IconEye, IconEyeOff } from '@tabler/icons';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';

import oopsImg from 'assets/pictures/oops.gif';
import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import InputText from 'components/BasicComponents/Inputs/InputText';
import { useAuth } from 'modules/auth';
import './ChangePasswordForm.scss';
import { useChangePasswordForm } from './useChangePasswordForm';
import { useCheckKey } from './useCheckKey';

interface ChangePasswordFormProps {
    title: string;
    subtitle: string;
    sendBtnTxt: string;
}

const ChangePasswordForm = (props: ChangePasswordFormProps) => {
    const [t] = useTranslation('auth');
    const [{ error }] = useAuth();
    const [showPass, setShowPass] = useState(false);

    const form = useChangePasswordForm();
    const { password, repeatPassword, errorMsg, isLoading, dirty, setPassword, setRepeatPassword, send } = form;

    const { isCheckingKey, isKeyValid } = useCheckKey();

    const modifiers = [!dirty && errorMsg ? 'AuthLayout--has-error' : ''];

    const errorStatus = (error as AxiosError)?.response?.status;
    const showCriticalError = errorStatus && errorStatus >= 400;

    if (showCriticalError)
        return (
            <div className={`ChangePasswordForm ChangePasswordForm--has-error ${modifiers.join(' ')}`}>
                <img src={oopsImg} alt="" className="ChangePasswordForm__picture" />
                <header className="AuthLayout__header">
                    <h1 className="AuthLayout__title">{t('oopsTitle')}</h1>
                    <div className="AuthLayout__text">
                        <Trans i18nKey="oopsMsg" ns="auth" components={{ paragraph: <p /> }} />
                    </div>
                </header>
                <footer className="AuthLayout__footer">
                    <hr />
                    <Link to={`/account/help`} className="AuthLayout__link">
                        {t(`needHelpMsg`)}
                    </Link>
                    <Link to={`/account/login`} className="AuthLayout__link">
                        {t(`goBackLogin`)}
                    </Link>
                </footer>
                <div>
                    <ErrorCard className="AuthLayout__error-alert" error={error as Error} />
                </div>
            </div>
        );

    if (isCheckingKey)
        return (
            <div className={`ChangePasswordForm ${modifiers.join(' ')}`}>
                <header className="AuthLayout__header">
                    <p className="AuthLayout__text">{t('checkingLink')}</p>
                </header>
            </div>
        );

    if (!isKeyValid)
        return (
            <div className={`ChangePasswordForm ${modifiers.join(' ')}`}>
                <img src={oopsImg} alt="" className="ChangePasswordForm__picture" />
                <header className="AuthLayout__header">
                    <h1 className="AuthLayout__title">{t('linkExpiredTitle')}</h1>
                    <div className="AuthLayout__text">
                        <Trans i18nKey="linkExpired" ns="auth" components={{ paragraph: <p /> }} />
                    </div>
                </header>
                <footer className="AuthLayout__footer">
                    <BasicButton
                        to={`/account/reset-password/request`}
                        color="purple"
                        type="link"
                        isLoading={isLoading}
                        text={t(`requestNewLink`)}
                        extraClasses="AuthLayout__button"
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                </footer>
            </div>
        );

    return (
        <div className={`ChangePasswordForm ${modifiers.join(' ')}`}>
            <header className="AuthLayout__header">
                <h1 className="AuthLayout__title">{t(props.title)}</h1>
                <p className="AuthLayout__text">{t(props.subtitle)}</p>
            </header>
            <form className="AuthLayout__form" autoComplete="on" onSubmit={send}>
                <div className="AuthLayout__password">
                    <InputText
                        className="AuthLayout__input"
                        label={t('password')}
                        name="password"
                        type={showPass ? 'text' : 'password'}
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                    <button
                        type="button"
                        className="AuthLayout__password-eye-btn"
                        onClick={() => setShowPass(!showPass)}
                    >
                        {showPass ? <IconEyeOff /> : <IconEye />}
                    </button>
                </div>

                <div className="AuthLayout__password">
                    <InputText
                        className="AuthLayout__input"
                        label={t('repeatPassword')}
                        name="repeatPassword"
                        type={showPass ? 'text' : 'password'}
                        autoComplete="new-password"
                        value={repeatPassword}
                        onChange={(e) => {
                            setRepeatPassword(e.target.value);
                        }}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                    <button
                        type="button"
                        className="AuthLayout__password-eye-btn"
                        onClick={() => setShowPass(!showPass)}
                    >
                        {showPass ? <IconEyeOff /> : <IconEye />}
                    </button>
                </div>

                <div data-testid="auth__error" className="AuthLayout__error-msg">
                    {t(errorMsg)}
                </div>

                <footer className="AuthLayout__footer">
                    <BasicButton
                        color="purple"
                        type="submit"
                        isLoading={isLoading}
                        text={t(props.sendBtnTxt)}
                        extraClasses="AuthLayout__button"
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                    <hr />
                    <Link to={`/account/help`} className="AuthLayout__link">
                        {t(`needHelpMsg`)}
                    </Link>
                    <Link to={`/account/login`} className="AuthLayout__link">
                        {t(`goBackLogin`)}
                    </Link>
                </footer>
            </form>
        </div>
    );
};

export default ChangePasswordForm;
