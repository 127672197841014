import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import HasPermission from '../../../components/AccessCheckers/HasPermission';
import Buscador from '../../../components/BasicComponents/Buscador/Buscador';
import SearchButton from '../../../components/BasicComponents/Buttons/SearchButton';
import SubmitButton from '../../../components/BasicComponents/Buttons/SubmitButton';
import InputFecha from '../../../components/BasicComponents/Inputs/InputFecha';
import CodLerAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import PromoProGesCodLerAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/PromoProGesCodLerAutocomplete';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import SwitchComponent from '../../../components/BasicComponents/Switch/Switch';
import { withAuth } from '../../../context/AuthProvider';
import { differenceArrays } from '../../../utils/helpers/general.helpers';
import { associateTraceabilities } from '../api/associateTraceability';
import { createObjectDisassociate } from '../helpers/associateTraceabilities.helpers';
import { AssociateTracabilitiesContext } from './AssociateTraceabilitiesPage';
import ResultAssociateTraceability from './ResultAssociateTraceability';

const TraceabilitiesWithoutDocument = ({ getTraceabilitiesWithoutDocument }) => {
    const [t] = useTranslation();
    const INITIAL_PAGE = 0;
    const [traceabilitiesToAssociate, setTraceabilitiesToAssociate] = useState([]);
    const [associateAllTraceabilities, setAssociateAllTraceabilities] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [page, setPage] = useState(INITIAL_PAGE);
    const [loading, setLoading] = useState(false);

    const {
        traceabilitiesWithoutDocument,
        setTraceabilitiesWithoutDocument,
        traceabilitiesDocument,
        setTraceabilitiesDocument,
        associatedTraceabilities,
        setAssociatedTraceabilities,
        numberTraceabilitiesDocument,
        setNumberTraceabilitiesDocument,
        values,
        setValues,
        DOCUMENT
    } = useContext(AssociateTracabilitiesContext);

    const handleClickAsociateAll = () => {
        setAssociateAllTraceabilities(!associateAllTraceabilities);
    };

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    const handleSearchSubmit = async (e) => {
        e.preventDefault();
        setPage(INITIAL_PAGE);
        setAssociateAllTraceabilities(false);
        setTraceabilitiesToAssociate([]);
        handleLoadMore(0);
    };

    const handleClick = (traceability) => {
        const findIdx = traceabilitiesToAssociate.indexOf(traceability);
        if (findIdx > -1) {
            const filteredData = traceabilitiesToAssociate.filter((item) => item !== traceability);
            setTraceabilitiesToAssociate(filteredData);
        } else {
            setTraceabilitiesToAssociate([...traceabilitiesToAssociate, traceability]);
        }
    };

    const associateTraceabilitiesOnSubmit = async () => {
        setLoading(true);
        if (traceabilitiesToAssociate.length > 0) {
            setSubmitting(true);
            const documentToEdit = createObjectDisassociate(DOCUMENT, traceabilitiesToAssociate);
            const actionAssociateTraceabilities = await associateTraceabilities(documentToEdit);
            if (actionAssociateTraceabilities !== undefined) {
                setTraceabilitiesWithoutDocument(
                    differenceArrays(traceabilitiesWithoutDocument, traceabilitiesToAssociate)
                );
                setTraceabilitiesDocument(traceabilitiesDocument.concat(traceabilitiesToAssociate));
                setAssociatedTraceabilities(associatedTraceabilities.concat(traceabilitiesToAssociate));
                setNumberTraceabilitiesDocument(numberTraceabilitiesDocument + traceabilitiesToAssociate.length);
                setPage(INITIAL_PAGE);
                setTraceabilitiesToAssociate([]);
                setAssociateAllTraceabilities(false);
            }
        }
        setLoading(false);
        setSubmitting(false);
    };

    const handleLoadMore = async (pageAux) => {
        setLoading(true);
        const nextTraceabilities = await getTraceabilitiesWithoutDocument(pageAux);
        if (pageAux === 0) {
            setTraceabilitiesWithoutDocument(nextTraceabilities);
        } else {
            setTraceabilitiesWithoutDocument(traceabilitiesWithoutDocument.concat(nextTraceabilities));
        }
        setPage(pageAux + 1);
        setLoading(false);
    };

    useEffect(() => {
        handleLoadMore(0);
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        let mounted = true;
        if (mounted) {
            associateAllTraceabilities
                ? setTraceabilitiesToAssociate(traceabilitiesWithoutDocument)
                : setTraceabilitiesToAssociate([]);
        }
        return () => {
            mounted = false;
            abortController.abort();
        };
    }, [associateAllTraceabilities]);

    return (
        <HasPermission ifRole="canCreate" section="traceability">
            <Buscador titulo={`${t('unassociatedTracabilities', { ns: 'associateTraceabilities' })}`}>
                <form onSubmit={handleSearchSubmit}>
                    <PromoProGesCodLerAutocomplete {...{ values, setValues }} value={values?.gestora} />
                    <CodLerAutocomplete {...{ values, setValues }} value={values?.codLer} />
                    <InputFecha
                        label={t('date.dateFrom', { ns: 'common' })}
                        id="fechaDesde-trazabilidad"
                        name="fechaDesde"
                        value={values.fechaDesde}
                        onChange={handleInputChange}
                        values={values}
                        setValues={setValues}
                        aspaLimpiar
                    />
                    <InputFecha
                        label={t('date.dateTo', { ns: 'common' })}
                        id="fechaHasta-trazabilidad"
                        name="fechaHasta"
                        value={values.fechaHasta}
                        onChange={handleInputChange}
                        values={values}
                        setValues={setValues}
                        aspaLimpiar
                    />
                    <PromoProGesCodLerAutocomplete
                        className="input__autocomplete carrier__autocomplete"
                        {...{ values, setValues }}
                        value={values.transportista}
                        name="transportista"
                        typeOfWasteManager="CARRIER"
                        multiselect
                    />
                    <SearchButton text={t('search.search', { ns: 'common' })} />
                </form>
                {traceabilitiesWithoutDocument?.length > 0 && (
                    <>
                        <SwitchComponent
                            label={t('associateAll', { ns: 'associateTraceabilities' })}
                            checked={associateAllTraceabilities}
                            onChange={handleClickAsociateAll}
                        />
                        <p className="associateTraceabilities__selected">
                            {t('selectedTraceabilities', { ns: 'associateTraceabilities' })}:{' '}
                            <span>{traceabilitiesToAssociate.length}</span>
                        </p>
                    </>
                )}
                <div className="associateTraceabilities__containerNonAssociated" id="containerNonAssociated">
                    {traceabilitiesWithoutDocument?.length > 0 && (
                        <InfiniteScroll
                            dataLength={traceabilitiesWithoutDocument.length}
                            next={() => handleLoadMore(page)}
                            hasMore={traceabilitiesWithoutDocument.length % 20 === 0}
                            height={500}
                            scrollableTarget="containerNonAssociated"
                        >
                            {traceabilitiesWithoutDocument?.map((traceability) => (
                                <ResultAssociateTraceability
                                    traceability={traceability}
                                    selectedCheckboxes={traceabilitiesToAssociate}
                                    key={traceability.id}
                                    handleClick={handleClick}
                                />
                            ))}
                        </InfiniteScroll>
                    )}
                </div>
                {traceabilitiesWithoutDocument?.length > 0 && (
                    <SubmitButton
                        text={`${t('associateTraceabilitiesAction', { ns: 'associateTraceabilities' })}: ${
                            traceabilitiesToAssociate.length
                        }`}
                        onClickButton={associateTraceabilitiesOnSubmit}
                        buttonDisabled={submitting}
                    />
                )}
                {traceabilitiesWithoutDocument?.length === 0 && (
                    <p>{t('notTraceabilitiesNotAssociated', { ns: 'associateTraceabilities' })}</p>
                )}
            </Buscador>
            {loading && <Spinner />}
        </HasPermission>
    );
};
export default withAuth(TraceabilitiesWithoutDocument);
