import { useState, useEffect } from 'react';
import { IconPlus } from '@tabler/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import SmallButton from '../../../components/BasicComponents/Buttons/Small/SmallButton';
import { withAuth } from '../../../context/AuthProvider';
import { getFilterLer, getPgcls } from '../helpers/waste_manager.helpers';
import LerTypeAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/LerTypeAutocomplete';
import CodLerAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import SearchButton from '../../../components/BasicComponents/Buttons/SearchButton';
import LerLine from './LerLine';

const CodLerWasteManager = ({ wasteManager, checkboxHandleClick, newPpgcls, promocion }) => {
    const [search, setSearch] = useState({ lerType: '', codLER: '' });
    const [page, setPage] = useState(0);
    const [toggleAccordion, setToggleAccordion] = useState(false);
    const [ppgcls, setPpgcls] = useState([]);

    const getWasteManagerLers = async (filter = '', newSearch = true) => {
        const searchPpgcls = await getPgcls(filter + `&gestoraId.equals=${wasteManager.id}&sort=codLER.codigoLER,asc`);
        newSearch ? setPpgcls(searchPpgcls) && setPage(0) : setPpgcls(ppgcls.concat(searchPpgcls));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        getWasteManagerLers(getFilterLer(search));
    };

    const handleLoadMore = async () => {
        setPage((prevPage) => prevPage + 1);
        getWasteManagerLers(`&page=${page + 1}` + getFilterLer(search), false);
    };

    useEffect(() => {
        if (toggleAccordion) {
            getWasteManagerLers(`&page=0` + getFilterLer(search));
        }
    }, [toggleAccordion]);

    return (
        <li className="codlerWasteManager__container">
            <div className="codlerWasteManager__header">
                <p className="codlerWasteManager__p">{wasteManager.empresa}</p>
                {!toggleAccordion && (
                    <SmallButton titulo="Añadir flujo" icon={<IconPlus />} action={() => setToggleAccordion(true)} />
                )}
            </div>
            {toggleAccordion && (
                <div className="codlerWasteManager__form">
                    <form onSubmit={handleFormSubmit} className="wasteManagerStep__form">
                        <LerTypeAutocomplete
                            values={search}
                            setValues={setSearch}
                            propName="lerType"
                            isClearable={true}
                        />
                        <CodLerAutocomplete
                            values={search}
                            setValues={setSearch}
                            name="codLER"
                            searchFromPgcl={true}
                            {...{ promocion }}
                            pgclValorizationFilter="&valorizationCode.notIn=O01,T01,N00,Pos00,RU0,A00"
                        />
                        <SearchButton />
                    </form>
                    {toggleAccordion && ppgcls.length === 0 && (
                        <p className="verticalStepper__message">No existen resultados</p>
                    )}
                    <InfiniteScroll
                        dataLength={ppgcls.length}
                        next={() => handleLoadMore()}
                        hasMore={true}
                        height={450}
                    >
                        {ppgcls.map((ppgcl) => (
                            <LerLine key={ppgcl.id} {...{ ppgcl, checkboxHandleClick, newPpgcls }} />
                        ))}
                    </InfiniteScroll>
                </div>
            )}
            <div className="codlerWasteManager__buttonClose">
                {toggleAccordion && <SmallButton titulo="Cerrar" action={() => setToggleAccordion(false)} />}
            </div>
        </li>
    );
};
export default withAuth(CodLerWasteManager);
