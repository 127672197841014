import {
    WASTE_MANAGERS,
    WASTE_MANAGERS_VALUATION_CODES
} from '../../../shared/domain/constants/WasteManagersConstants';
import { WasteManagersCriteria } from '../WasteManagers/WasteManagersCriteria';

/** creates a criteria to retrive recomended transfer operators */
export function createRecomendedTransferOperatorsCriteria(filters: { lers: string }): WasteManagersCriteria {
    if (!filters || !filters.lers) {
        throw new Error('atLeastOneLerRequired');
    }

    const recomendedTransferOperators = [WASTE_MANAGERS.COCIRCULAR];

    return {
        'valuationCode.equals': WASTE_MANAGERS_VALUATION_CODES.TRANSFER_OPERATOR,
        'nIMA.contains': recomendedTransferOperators.map((transferOperator) => transferOperator.nima).join(),
        'codLer.in': filters.lers
    };
}
