import { AuthStore } from 'modules/auth/domain/AuthStore';
import { validatePassword } from 'modules/auth/domain/validations/validatePassword';
import { Thunk } from 'modules/shared/domain/store/createStore';

export const changePassword =
    (params: { newPassword: string; key: string; repeatPassword: string }): Thunk<AuthStore, { success: boolean }> =>
    async (store, { api }) => {
        try {
            validatePassword(params.newPassword, params.repeatPassword);
            await api.changePassword(params);

            return { success: true };
        } catch (error) {
            store.set((draft) => {
                draft.error = error as Error;
            });
            return { success: false };
        }
    };
