import { Tag, TagId, TagProyectId } from './Tag';
import { TagColors } from './TagColors';

export interface TagCreator {
    id: TagId | null;
    enterpriseId: TagEnterpriseId | null;
    name: string | null;
    color: TagColors | null;
    projectIds: TagProyectId[] | null;
}

export type TagEnterpriseId = number;

export const tagCreatorAdapter = (tag: Tag, enterpriseId: TagEnterpriseId): TagCreator => {
    return {
        id: tag.id,
        enterpriseId: enterpriseId,
        name: tag.name,
        color: tag.color,
        projectIds: tag.projects.map((project) => project.id) || []
    };
};
