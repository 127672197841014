import { contextStoreAdapter } from 'modules/shared';
import { alertsStore } from 'modules/alerts/application/alertsStore';

const AlertsContext = contextStoreAdapter(alertsStore)({
    name: 'AlertsContext'
});

export const AlertsProvider = AlertsContext.Provider;
export const useAlerts = AlertsContext.createQueryHook(({ api }) => api.getAlerts);
export const useAlertsCause = AlertsContext.createQueryHook(({ api }) => api.getAlertCause);
