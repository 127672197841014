import axios from 'axios';
import { getToken } from '../../utils/helpers/api.helpers';
import { dispatchLogout } from '../../lib/LogoutHandler';

class VisitRecordServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    getVisitRecordsByActivityId = async (activityId) => {
        try {
            const visitRecords = await this.services.get(`/api/audit/visit-records?promotionActivityId=${activityId}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return visitRecords?.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    createConstructionSiteActivity = async (actividad) => {
        try {
            const response = await this.services.post(`/api/audit/visit-records`, actividad, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response?.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    updateConstructionSiteActivity = async (actividad) => {
        try {
            const response = await this.services.put(`/api/audit/visit-records`, actividad, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response?.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    addImageVisitRecord = async (visitRecordId, image) => {
        try {
            const response = await this.services.post(`/api/audit/visit-records/${visitRecordId}/add-image`, image, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response?.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    deleteImageVisitRecord = async (visitRecordId, imageId) => {
        try {
            const response = await this.services.delete(`/api/audit/visit-records/${visitRecordId}/images/${imageId}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };
}

const visitRecordServices = new VisitRecordServices();
export default visitRecordServices;
