import './RoundedIcon.scss';

const RoundedIcon = ({ icon = <></>, documentStyles = '', color = 'blue', big = false, action = () => {} }) => {
    return (
        <div
            onClick={action && action}
            className={`icon__circle icon__circle-${color} ${big ? 'icon__big' : ''} ${
                documentStyles && 'documentStyles__infoContainer--icon'
            }`}
        >
            {icon}
        </div>
    );
};
export default RoundedIcon;
