import fetcher from '../../../lib/fetcher';

//valorizationCode.notIn=O01,T01,VMNE,N00,Pos00

export const getPgclsFilterAsCarrier = async (filter = '') => {
    const URL = `/api/pro-ges-cod-lers?valorizationCode.equals=T01${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
