import { IconFiles } from '@tabler/icons';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import File from 'components/BasicComponents/File/File';
import RoundedIcon from 'components/BasicComponents/Icon/RoundedIcon';
import { TituloH3 } from 'components/BasicComponents/Titulos/Titulos';
import { openFileByURL } from 'utils/helpers/files.helpers';

interface ActivityFilesProps {
    files: any;
}

const ActivityFiles = ({ files }: ActivityFilesProps) => {
    const IMG_TYPES = ['.jpg', '.jpeg', '.png', '.apng', '.avif', '.gif', '.svg', '.webp'];

    const checkIfImage = (fileName) => {
        const isImage = IMG_TYPES.some((mimeType) => fileName.includes(mimeType));
        return isImage;
    };

    return (
        <SimpleCard>
            <section className="ActivityDetail__filesSection">
                <header className="ActivityDetail__cardTitle">
                    <RoundedIcon icon={<IconFiles />} />
                    <TituloH3 titulo={'Documentos adjuntos'} />
                </header>
                <div className="ActivityDetail__files">
                    {files.map((doc) => {
                        return checkIfImage(doc?.nombreFichero) ? (
                            <img
                                key={doc?.id}
                                src={doc?.preSignedUrl}
                                alt={doc?.nombreFichero}
                                onClick={() => openFileByURL(doc?.preSignedUrl)}
                                className="ActivityDetail__attachedImage"
                            />
                        ) : (
                            <File
                                key={doc?.id}
                                document={doc}
                                fileName={doc?.nombreFichero}
                                preSignedUrl={doc?.preSignedUrl}
                            />
                        );
                    })}
                </div>
            </section>
        </SimpleCard>
    );
};

export default ActivityFiles;
