import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconDownload, IconFileCheck } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import Layout from '../../../pages/Layout/Layout';
import { TituloH1, TituloH2 } from '../../../components/BasicComponents/Titulos/Titulos';
import CreateButton from '../../../components/BasicComponents/Buttons/CreateButton';
import Folder from '../../../components/BasicComponents/Folder/Folder';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import HasPermission from '../../../components/AccessCheckers/HasPermission';
import SiteDocument from './SiteDocument';
import Signage from './Signage/Signage';
import { useDocumentsStore } from './store';
import './Documentation.scss';

const DocumentationPage = (props) => {
    const [t] = useTranslation();
    const [state, actions] = useDocumentsStore();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.promocion?.id) {
            getAllDocuments();
        }
    }, [props.promocion?.id]);

    const getAllDocuments = async () => {
        setLoading(true);
        await actions.loadDocumentTypes(props?.promocion);
        setLoading(false);
    };

    const deleteDocumentFunction = async (id) => {
        await actions.deleteDocument(id);
    };

    const shouldDisplayCustomSignage = (doc) => doc.name === 'Cartelería' && doc.documents.length === 0;

    return (
        <Layout>
            <div className="documentation">
                <div className="create-button">
                    <TituloH1 titulo={t('title', { ns: 'documentation' })} />
                    <div className="create-button__container">
                        <CreateButton
                            icon={<IconDownload stroke={2.1} size={20} color="white" />}
                            text={t('downloadDocs', { ns: 'documentation' })}
                            to="/documents/download"
                        />
                        <HasPermission ifRole="canCreate" section="documentation">
                            <CreateButton text={t('new', { ns: 'documentation' })} to="/documents/create" />
                        </HasPermission>
                    </div>
                </div>
                {loading ? (
                    <Spinner />
                ) : (
                    <>
                        {Object.entries(state.documentTypesBySection)?.map(([section, { documentTypes }], i) => (
                            <>
                                <TituloH2 titulo={t(`section.${section}`, { ns: 'documentation' })} />
                                <div key={i} className="documentation__folderContainer">
                                    {documentTypes?.map((documentType) => {
                                        // TODO: refactor to best approach when https://app.clickup.com/t/860qjtkvj
                                        if (shouldDisplayCustomSignage(documentType)) {
                                            return <Signage key={documentType.id} />;
                                        }

                                        if (documentType.unique) {
                                            return (
                                                <SiteDocument
                                                    siteDocument={documentType}
                                                    key={documentType.id}
                                                    deleteDocument={deleteDocumentFunction}
                                                />
                                            );
                                        } else {
                                            return (
                                                <Link
                                                    key={documentType.id}
                                                    to={{
                                                        pathname: `documents/type-list`,
                                                        state: {
                                                            selectedDocType: { ...documentType, section: section }
                                                        }
                                                    }}
                                                >
                                                    <Folder>
                                                        <p className="documentation__numberFiles">
                                                            <IconFileCheck size={20} color={'#7F42F5'} />
                                                            {documentType.size}
                                                        </p>
                                                        <p className="documentation__descriptionFolder">
                                                            {documentType.name}
                                                        </p>
                                                    </Folder>
                                                </Link>
                                            );
                                        }
                                    })}
                                </div>
                            </>
                        ))}
                    </>
                )}
            </div>
        </Layout>
    );
};
export default withAuth(DocumentationPage);
