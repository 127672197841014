import { GoalMet } from '../goals/PromotionKPIsGoal';
import { MandatoryFlowsGoal } from './MandatoryFlowsGoal';

type Flow = Partial<Pick<GoalMet<MandatoryFlowsGoal>, 'severity' | 'met'>> & { name: string };

export const calcMandatoryFlowsSort = (a: Flow, b: Flow) => {
    // 1. Not applicable last
    if (a.met === null) return 1;
    if (b.met === null) return -1;
    // 2 - Sort by met
    if (a.met !== b.met) return a.met ? 1 : -1;
    // 2 - Sort by severity
    if (a.severity !== b.severity) return (a.severity || 0) - (b.severity || 0);
    // 3 - Sort by name
    if (a.name && b.name) return a.name.localeCompare(b.name);
    return 1;
};
