import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLoadDeepLink } from 'modules/auth/infrastructure/react/useDeepLink';
import { withAuth } from '../../context/AuthProvider';
import { hasPermission } from '../../utils/helpers/roles.helpers';

const PrivateRoute = ({
    component: Component,
    requiredPermission,
    section,
    showContent,
    withoutPromotion,
    ...rest
}) => {
    const { user, promocion, isLoggedin } = rest;
    const { i18n } = useTranslation();

    const getLang = () => {
        localStorage.setItem('lang', user?.langKey.toLowerCase());
        i18n.changeLanguage(user?.langKey);
    };

    const isAuthorizated = () => {
        const hasRoleAccess = hasPermission(section, requiredPermission, user, promocion);
        return hasRoleAccess;
    };

    const choosePromotion = () => {
        const promoSessionStorage = sessionStorage.getItem('promocion');
        // promoSessionStorage may be undefined, but that undefined value is a string!!!! ('undefined')
        if (
            promocion === null &&
            promoSessionStorage !== undefined &&
            promoSessionStorage !== null &&
            promoSessionStorage !== 'undefined'
        ) {
            rest.changePromotion(JSON.parse(promoSessionStorage));
        }
    };

    useEffect(() => {
        let mounted = true;

        if (!mounted) return;

        getLang();

        if (!isLoggedin || !isAuthorizated()) return;

        if (withoutPromotion) {
            rest.removePromotion();
            return;
        }

        choosePromotion();

        return () => {
            mounted = false;
        };
    }, [rest.path]);

    useLoadDeepLink();

    if (isLoggedin && isAuthorizated()) {
        return <Route {...rest} component={Component} />;
    }

    return <Redirect to={'/'} />;
};

export default withAuth(PrivateRoute);
