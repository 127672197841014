import { useTranslation } from 'react-i18next';
import { IconAdjustmentsHorizontal, IconX } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import './SortMenu.scss';

const SortMenu = ({ children, openMenu, setOpenMenu, selectedOption, deleteFilter }) => {
    const [t] = useTranslation();

    return (
        <div className="sort">
            <div className="sort__menu">
                <button className="button sort__openButton" onClick={() => setOpenMenu(!openMenu)}>
                    <IconAdjustmentsHorizontal stroke={1.2} />
                    {t('sort', { ns: 'common' })}
                </button>
                {!openMenu && selectedOption && (
                    <div className="sort__button" key={selectedOption?.label}>
                        {' '}
                        {t(selectedOption?.label, { ns: 'sort' })}{' '}
                        <IconX size={15} onClick={() => deleteFilter(selectedOption)} />
                    </div>
                )}
            </div>
            {openMenu && <div className="sort__container">{children}</div>}
        </div>
    );
};
export default withAuth(SortMenu);
