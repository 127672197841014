interface IColumn {
    id: number;
    name:
        | 'metadata'
        | 'lerInfo'
        | 'wasteManagerAndCarrier'
        | 'valorizationInfo'
        | 'weight'
        | 'volume'
        | 'cost'
        | 'di'
        | 'actions';
    fields: {
        name: string;
        show: boolean;
    }[];
}

export const COLUMNS: IColumn[] = [
    {
        id: 0,
        name: 'metadata',
        fields: [
            {
                name: 'date',
                show: true
            },
            {
                name: 'id',
                show: true
            }
        ]
    },
    {
        id: 1,
        name: 'lerInfo',
        fields: [
            {
                name: 'lerCode',
                show: true
            },
            {
                name: 'lerKind',
                show: true
            }
        ]
    },
    {
        id: 2,
        name: 'wasteManagerAndCarrier',
        fields: [
            {
                name: 'wasteManager',
                show: true
            },
            {
                name: 'carrier',
                show: true
            }
        ]
    },
    {
        id: 3,
        name: 'valorizationInfo',
        fields: [
            {
                name: 'process',
                show: true
            },
            {
                name: 'valorizationPercent',
                show: true
            }
        ]
    },
    {
        id: 4,
        name: 'weight',
        fields: [
            {
                name: 'weight',
                show: true
            }
        ]
    },
    {
        id: 5,
        name: 'volume',
        fields: [
            {
                name: 'volume',
                show: true
            }
        ]
    },
    {
        id: 6,
        name: 'cost',
        fields: [
            {
                name: 'cost',
                show: true
            }
        ]
    },
    {
        id: 7,
        name: 'di',
        fields: [
            {
                name: 'di',
                show: true
            }
        ]
    },
    {
        id: 7,
        name: 'actions',
        fields: [
            {
                name: 'actions',
                show: true
            }
        ]
    }
];
