import { WasteManagementProvider } from 'modules/waste-management';
import { httpWasteManagementApi } from 'modules/waste-management/infrastructure/api/httpWasteManagementApi';
import WasteManagementChart, { WasteManagementChartProps } from './WasteManagementChart';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: WasteManagementChartProps) => (
    <WasteManagementProvider api={httpWasteManagementApi}>
        <WasteManagementChart {...props} />
    </WasteManagementProvider>
);
