import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { legacyEnterpriseAdapter } from 'modules/enterprise/related-enterprise/domain/legacyEnterpriseAdapter';
import activitiesServices from '../../api/activities/activities.services';
import promoProGesCodLersServices from '../../api/promoProGesCodLers/promoProGesCodLers.services';
import Buscador from '../../components/BasicComponents/Buscador/Buscador';
import '../../components/BasicComponents/Buscador/Buscador.scss';
import GoBack from '../../components/BasicComponents/Buttons/GoBack';
import SubmitButton from '../../components/BasicComponents/Buttons/SubmitButton';
import DragNDrop from '../../components/BasicComponents/DragAndDrop/DragNDrop';
import InputFecha from '../../components/BasicComponents/Inputs/InputFecha';
import InputText from '../../components/BasicComponents/Inputs/InputText';
import MessageNotComplete from '../../components/BasicComponents/Messages/MessageNotComplete';
import ResultadoCarga from '../../components/BasicComponents/Messages/ResultadoCarga';
import RichText from '../../components/BasicComponents/RichText';
import ActivityStatusSelectAutocomplete from '../../components/BasicComponents/Select-Autocomplete/ActivityStatusSelectAutocomplete';
import PromoProGesCodLerAutocomplete from '../../components/BasicComponents/Select-Autocomplete/PromoProGesCodLerAutocomplete';
import TipoActividadSelectAutocomplete from '../../components/BasicComponents/Select-Autocomplete/TipoActividadSelectAutocomplete';
import TransportistaSelectAutocomplete from '../../components/BasicComponents/Select-Autocomplete/TransportistaSelectAutocomplete';
import { TituloH1 } from '../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../context/AuthProvider';
import { searchUserClient } from '../../utils/helpers/general.helpers';
import Layout from '../Layout/Layout';

const NuevaActividad = (props) => {
    const [t] = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const INITIAL_STATE = {
        tipoActividad: '',
        fechaActividad: '',
        fechaPrevistaActividad: '',
        observaciones: '',
        asunto: '',
        estadoActividad: 'ABIERTO',
        visitRecords: []
    };

    const [values, setValues] = useState(INITIAL_STATE);
    const [files, setFiles] = useState([]);
    const [newFiles, setNewFiles] = useState([]);

    const [creating, setCreating] = useState(false);

    const [modoEditar, setModoEditar] = useState(false);
    const [actividadData, setActividadData] = useState();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [resetObservaciones, setResetObservaciones] = useState(false);
    const [message, setMessage] = useState({});
    // eslint-disable-next-line
    const [previousState, setPreviousState] = useState({});
    const [validacion, setValidacion] = useState(false);
    const [messageNotComplete, setMessageNotComplete] = useState('');

    useEffect(() => {
        setDataLoaded(false);

        if (location.state !== undefined) {
            setPreviousState(location.state);
        }

        if (props.match.params.activityId !== undefined && props.promocion?.id) {
            setModoEditar(true);
            getDataActividad(props.match.params.activityId);
        } else {
            setModoEditar(false);
            setActividadData();
            setValues(INITIAL_STATE);
            setDataLoaded(true);
        }
    }, [location, props.promocion]);

    const checkIfIsWasteManager = async (wasteManagerId) => {
        const ppgcl = await promoProGesCodLersServices.filterPromoProGesCodLers(
            `?promocionId.equals=${props.promocion?.id}&gestoraId.equals=${wasteManagerId}`
        );
        const isWasteManager = ppgcl.length > 0 ? true : false;
        return isWasteManager;
    };

    const getDataActividad = async (id) => {
        const actividad = await activitiesServices.getActivityById(id);
        comprobarPromocion(actividad.promocion.id);
        setActividadData(actividad);

        const mainEnterprise = legacyEnterpriseAdapter(actividad?.promocion?.relatedEnterprises);

        if (actividad.gestora?.id) {
            const isWasteManager = await checkIfIsWasteManager(actividad.gestora?.id);
            if (!isWasteManager) {
                setValues({
                    ...actividad,
                    empresa: mainEnterprise,
                    visitRecords: [],
                    gestora: undefined,
                    transportista: actividad.gestora
                });
            } else {
                setValues({
                    ...actividad,
                    empresa: mainEnterprise,
                    visitRecords: []
                });
            }
        } else {
            setValues({
                ...actividad,
                empresa: mainEnterprise,
                visitRecords: []
            });
        }
        getFiles(id);
        setDataLoaded(true);
    };

    const comprobarPromocion = (id) => {
        if (id !== props.promocion?.id) {
            history.goBack();
        }
    };

    const getFiles = async (activityId) => {
        const filesAux = (await activitiesServices.getActivityFile(`?actividadId.equals=${activityId}`)) || [];
        setFiles(filesAux);
        setNewFiles(filesAux);
    };

    const goBack = () => {
        history.goBack();
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setCreating(true);
        const { promocion } = props;
        const {
            tipoActividad,
            fechaActividad,
            fechaPrevistaActividad,
            observaciones,
            asunto,
            codLer,
            estadoActividad,
            gestora
        } = values;
        if (modoEditar) {
            let actividadDataCopy = JSON.parse(JSON.stringify(actividadData));
            actividadDataCopy = {
                ...actividadDataCopy,
                clienteUser: await searchUserClient(props.user.id),
                promocion,
                tipoActividad,
                fechaActividad,
                fechaPrevistaActividad,
                observaciones,
                asunto,
                codLer,
                estadoActividad,
                gestora: values?.transportista ? values.transportista : gestora,
                visitRecords: []
            };
            setActividadData(actividadDataCopy);
            const nuevaActividad = await activitiesServices.updateActivity(actividadDataCopy);
            if (nuevaActividad !== undefined) {
                persistFiles(nuevaActividad);
                setMessage({ success: true, text: t('success.edit', { ns: 'activities' }) });
            } else {
                setMessage({
                    success: false,
                    text: t('activity.edit', { ns: 'errors' })
                });
            }
        } else {
            // creamos la actividad

            const validarForm = validationForm();
            if (validarForm) {
                let actividadAux = {
                    clienteUser: await searchUserClient(props.user.id),
                    promocion,
                    tipoActividad,
                    fechaActividad,
                    fechaPrevistaActividad,
                    observaciones,
                    asunto,
                    codLer,
                    estadoActividad,
                    gestora: values?.transportista ? values.transportista : gestora,
                    visitRecords: []
                };
                const nuevaActividad = await activitiesServices.createActivity(actividadAux);
                if (nuevaActividad?.status === 200 || nuevaActividad?.status === 201) {
                    persistFiles(nuevaActividad?.data);

                    setValues({
                        ...values,
                        tipoActividad: '',
                        fechaActividad: '',
                        fechaPrevistaActividad: '',
                        observaciones: '',
                        asunto: '',
                        codLer: null,
                        // estadoActividad: { value: 'ABIERTO', descripcion: 'Abierto' },
                        gestora: null
                    });
                    setResetObservaciones(true);
                    setResetObservaciones(false);

                    setMessage({ success: true, text: t('success.create', { ns: 'activities' }) });
                } else {
                    setMessage({
                        success: false,
                        text: t('activity.create', { ns: 'errors' })
                    });
                }
            }
        }
        setCreating(false);
    };

    const persistFiles = async (updatedActivity) => {
        let updatedFiles = [];

        // Check if the user removed previously saved files and deleting them from BBDD
        for (let i = 0; i < files?.length; i++) {
            const file = files[i];

            const fileFound = newFiles?.some((f) => f?.id === file?.id);

            if (!fileFound) {
                // Couldn't find the file in the new array (bc it was deleted by the user)
                const deletedFile = await activitiesServices.deleteActivityFile(file?.id);

                if (deletedFile === undefined) {
                    setMessage({
                        text: t('activity.images.deleting', { ns: 'errors' }),
                        success: false
                    });
                }
            }
        }

        // Posting all new files
        for (let i = 0; i < newFiles?.length; i++) {
            let newFile = newFiles[i];

            if (!newFile?.id) {
                // Its a new file so it doesn't have ID yet
                newFile = await activitiesServices.createActivityFile({
                    ...newFile,
                    actividad: updatedActivity
                });

                if (newFile === undefined) {
                    setMessage({
                        text: t('activity.images.uploading', { ns: 'errors' }),
                        success: false
                    });
                }
            }

            updatedFiles.push(newFile);
        }
        setFiles(modoEditar ? updatedFiles : []);
        setNewFiles(modoEditar ? updatedFiles : []);
    };

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    const validationForm = () => {
        if (
            !creating &&
            values.tipoActividad !== '' &&
            values.fechaActividad !== '' &&
            values.asunto !== undefined &&
            values.asunto !== null &&
            values.asunto !== '' &&
            values.estadoActividad !== ''
        ) {
            setMessageNotComplete('');
            setValidacion(false);
            return true;
        }
        setMessageNotComplete(t('complete', { ns: 'common' }));
        setValidacion(true);
        return false;
    };

    const { fechaActividad, fechaPrevistaActividad, asunto } = values;

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <TituloH1
                titulo={modoEditar ? t('edit', { ns: 'activities' }) : t('new.newActivity', { ns: 'activities' })}
            />
            <Buscador titulo="" column={true}>
                <form onSubmit={handleFormSubmit}>
                    <div className="activities__form">
                        <div>
                            <InputText
                                label={t('subject', { ns: 'common' })}
                                id="asunto-nueva-actividad"
                                name="asunto"
                                value={asunto}
                                onChange={handleInputChange}
                                className="input__all-width"
                                validacion={validacion}
                                required
                            />
                            <div className="documentacion__input__container textareaObservaciones mt-1">
                                <label htmlFor="observacionesInput" className="documentacion__label">
                                    {t('comments', { ns: 'common' })} *
                                </label>
                                <RichText
                                    id="observacionesInput"
                                    values={values}
                                    dataLoaded={dataLoaded}
                                    setValues={setValues}
                                    field="observaciones"
                                    validacion={validacion}
                                    required
                                    reset={resetObservaciones}
                                />
                            </div>
                        </div>
                        <div>
                            <PromoProGesCodLerAutocomplete
                                {...{ values, setValues }}
                                value={values.gestora}
                                className="input__large mt-1"
                                INITIAL_STATE={null}
                                newActivity
                            />
                            <TransportistaSelectAutocomplete {...{ values, setValues }} newActivity />

                            <InputFecha
                                label={t('date.date', { ns: 'common' })}
                                id="fecha-actividad"
                                name="fechaActividad"
                                value={fechaActividad}
                                onChange={handleInputChange}
                                className="input__large"
                                inputContainer="input__large mt-1"
                                validacion={validacion}
                                required
                            />
                            <InputFecha
                                label={t('date.expectedDate', { ns: 'common' })}
                                id="fecha-prevista-actividad"
                                name="fechaPrevistaActividad"
                                value={fechaPrevistaActividad}
                                onChange={handleInputChange}
                                className="input__large"
                                inputContainer="input__large mt-1 mb-1"
                            />
                            <TipoActividadSelectAutocomplete
                                values={values}
                                setValues={setValues}
                                className="input__large mt-1"
                                validacion={validacion}
                                required
                            />
                            <ActivityStatusSelectAutocomplete setValues={setValues} values={values} required />
                            <DragNDrop files={newFiles} setFiles={setNewFiles} />
                        </div>
                    </div>
                    <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={creating} />
                    {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
                    {message.text !== undefined && (
                        <ResultadoCarga text={message.text} success={message.success} setMessage={setMessage} />
                    )}
                </form>
            </Buscador>
        </Layout>
    );
};

export default withAuth(NuevaActividad);
