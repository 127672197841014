import { Status } from 'modules/comparative';

export const getLastUpdateDate = () => {
    const lastUpdateDate = new Date(); // Current time

    lastUpdateDate.setMinutes(0);
    lastUpdateDate.setSeconds(0);

    return lastUpdateDate;
};

export const getNextUpdateDate = () => {
    const nextUpdateDate = new Date(); // Current time

    nextUpdateDate.setHours(nextUpdateDate.getHours() + 1);
    nextUpdateDate.setMinutes(0);
    nextUpdateDate.setSeconds(0);

    return nextUpdateDate;
};

export const getGoalsSummaryText = (status: Status | undefined | null) => {
    const text =
        status === 'OK'
            ? 'Cumple objetivos'
            : status === 'WARNING'
            ? 'No cumple recomendados'
            : status === 'FAILED'
            ? 'No cumple'
            : 'N/A';

    return text;
};
