import hormigon from 'assets/imagenes/wastes-bg/hormigon.jpg';
import escombrolimpio from 'assets/imagenes/wastes-bg/escombrolimpio.jpg';
import madera from 'assets/imagenes/wastes-bg/madera.jpg';
import metales from 'assets/imagenes/wastes-bg/metales.jpg';
import carton from 'assets/imagenes/wastes-bg/carton.jpg';
import plastico from 'assets/imagenes/wastes-bg/plastico.jpg';
import yeso from 'assets/imagenes/wastes-bg/yeso.jpg';
import aislamientos from 'assets/imagenes/wastes-bg/aislamientos.jpg';
import mezclas from 'assets/imagenes/wastes-bg/mezclas.jpg';
import absorbentes from 'assets/imagenes/wastes-bg/absorbentes.jpg';
import tierras from 'assets/imagenes/wastes-bg/tierras.jpg';
import asfalto from 'assets/imagenes/wastes-bg/asfalto.jpg';
import envasescontaminados from 'assets/imagenes/wastes-bg/envasescontaminados.jpg';
import aerosoles from 'assets/imagenes/wastes-bg/aerosoles.jpg';
import pinturaybarniz from 'assets/imagenes/wastes-bg/pinturaybarniz.jpg';
import vidrio from 'assets/imagenes/wastes-bg/vidrio.jpg';
import tierrascontaminadas from 'assets/imagenes/wastes-bg/tierrascontaminadas.jpg';
import rsuorgánico from 'assets/imagenes/wastes-bg/rsuorganico.jpg';
import rsuvidrio from 'assets/imagenes/wastes-bg/rsuvidrio.jpg';
import rsupapel from 'assets/imagenes/wastes-bg/rsupapel.jpg';
import rsuenvases from 'assets/imagenes/wastes-bg/rsuenvases.jpg';
import rsumezclas from 'assets/imagenes/wastes-bg/rsumezclas.jpg';
import aceites from 'assets/imagenes/wastes-bg/aceites.jpg';
import desbroceypoda from 'assets/imagenes/wastes-bg/desbroceypoda.jpg';
import tubos_fluorescentes from 'assets/imagenes/wastes-bg/tubos_fluorescentes.jpg';
import equipos_electricos_electronicos from 'assets/imagenes/wastes-bg/equipos_electricos_electronicos.jpg';
import toner_impresion from 'assets/imagenes/wastes-bg/toner_impresion.jpg';
import paneles_solares from 'assets/imagenes/wastes-bg/paneles_solares.jpg';
import raees from 'assets/imagenes/wastes-bg/raees.jpg';
import fibrocemento from 'assets/imagenes/wastes-bg/fibrocemento.png';

export const imagesByLerType = {
    1: hormigon,
    2: escombrolimpio,
    3: madera,
    4: metales,
    5: carton,
    6: plastico,
    // 7: ceramicos, // NOW EMPTY
    8: yeso,
    9: aislamientos,
    10: mezclas,
    11: absorbentes,
    12: tierras,
    13: asfalto,
    15: envasescontaminados,
    16: aerosoles,
    17: pinturaybarniz,
    18: vidrio,
    19: tierrascontaminadas,
    457801: rsuorgánico,
    457802: rsuvidrio,
    457803: rsupapel,
    457804: rsuenvases,
    623301: rsumezclas,
    635451: aceites,
    637251: desbroceypoda,
    822804: tubos_fluorescentes, // Tubos Fluorescentes
    822802: equipos_electricos_electronicos, // Equipos eléctricos y electrónicos peligrosos
    822801: toner_impresion, // Toners de impresión
    825801: paneles_solares, // Paneles Solares Fotovoltaicos
    869301: fibrocemento,
    869302: raees
};
