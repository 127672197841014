import fromEntities from 'lib/fromEntities';

import { Billeable, usePromotionBilleables } from 'modules/billeables';

import BilleableBox from '../BilleableBox/BilleableBox';
import SelloValorizaBilleableForm from '../SelloValorizaBilleableForm/SelloValorizaBilleableForm';
import PromotionBilleablesListSkeletons from './PromotionBilleablesListSkeletons';

interface PromotionBilleablesListProps {
    purchased?: boolean;
    scope: Billeable['scope'];
}

const PromotionBilleablesList = ({ purchased = false, scope }: PromotionBilleablesListProps) => {
    const [{ entities, loading }] = usePromotionBilleables();

    const billeables = fromEntities(entities).filter(
        (billeables) => billeables.purchased === purchased && billeables.scope === scope
    );

    if (loading === 'pending') {
        return <PromotionBilleablesListSkeletons count={purchased ? 2 : 1} />;
    }

    return (
        <div
            className="PromotionBilleables__sectionList"
            data-testid={`PromotionBilleablesList--${purchased ? 'purchased' : 'notPurchased'}--${scope}`}
        >
            {billeables.map((billeable) => (
                <BilleableBox key={billeable.name} billeable={billeable}>
                    {purchased && billeable.name === 'SELLO_VALORIZA' && <SelloValorizaBilleableForm />}
                </BilleableBox>
            ))}
        </div>
    );
};

export default PromotionBilleablesList;
