import { AxiosError } from 'axios';

import fetcher from 'lib/fetcher';
import { fixWasteFlowVolumeAndCost } from 'modules/waste-flow';
import { omitFalsyValues } from 'utils/helpers/object.helpers';
import { DiExtractionCriteria } from '../domain/DiExtractionCriteria';
import { InvalidApproveDataException } from '../domain/DiExtractionErrors';
import { Counters, IDiExtraction } from '../domain/IDiExtraction';
import { IDiExtractionApi } from '../domain/IDiExtractionApi';
import { applicableStatusList } from '../domain/statusLists/applicableStatusList';

let getDiExtractrionsController: AbortController | null = null;

export const httpDiExtractionApi: IDiExtractionApi = {
    async getDiExtractions(criteria) {
        // Aborts previous request
        getDiExtractrionsController?.abort();
        getDiExtractrionsController = new AbortController();

        const apiCriteria = criteriaToQueryAdapter(criteria);

        const diExtractionsRequest = await fetcher(`/api/tracing/extractions`, {
            params: omitFalsyValues(apiCriteria),
            signal: getDiExtractrionsController?.signal
        });

        const withWarnings = diExtractionsRequest.data.content.withWarnings;

        const results: IDiExtraction[] = diExtractionsRequest.data.content.extractions.map(
            (diExtraction: IDiExtraction) => {
                const [, fileType] = diExtraction.mimeType?.split('/');

                const adaptedDiExtraction: IDiExtraction = {
                    ...diExtraction,
                    mimeType: fileType,
                    state: diExtraction.state,
                    result: {
                        ...diExtraction.result,
                        ppgcl:
                            /* Convert volume and cost to NULL if they are '0' */
                            diExtraction.result.ppgcl ? fixWasteFlowVolumeAndCost(diExtraction.result.ppgcl) : null
                    }
                };

                return adaptedDiExtraction;
            }
        );

        return { results, withWarnings };
    },
    async approveDiExtraction(diExtraction) {
        const { id, ...data } = diExtraction;

        await fetcher.patch(`/api/tracing/extractions/${id}/approve`, data).catch((e) => {
            const error = e as AxiosError;

            if (error.response?.status === 400) {
                throw new InvalidApproveDataException(error.response.data);
            }

            throw error;
        });
    },
    async rejectDiExtraction(extractionId) {
        const message = {
            success: { text: 'rejectRequest.success', ns: 'diExtraction' },
            error: { text: 'rejectRequest.error', ns: 'diExtraction' }
        };

        await fetcher.patch(`/api/tracing/extractions/${extractionId}/reject`, {}, { message } as any);
        return {};
    },
    async createDiExtractions(promotionId, newDiExtractions, extra = {}) {
        const body = new FormData();

        body.append('promotionId', promotionId.toString());

        newDiExtractions.forEach((newDiExtraction) => {
            body.append('file', newDiExtraction);
        });

        Object.entries(extra).forEach(([key, value]) => {
            if (value === undefined || value === null) return;
            body.append(key, value.toString());
        });

        await fetcher.put('/api/tracing/extractions/request/multipart', body);
    },
    async getDiExtractionsCounters(criteria) {
        const apiCriteria = criteriaToQueryAdapter(criteria);

        const request = await fetcher(`/api/tracing/extractions/count`, { params: omitFalsyValues(apiCriteria) });
        const apiCounters = request?.data?.content;

        const totalApplicable = applicableStatusList.reduce((acc, key) => {
            return acc + apiCounters[key];
        }, 0);

        const counters: Counters = {
            totalApplicable: (totalApplicable as number) || 0,
            ...apiCounters
        };

        return counters;
    },
    approveManyDiExtractions: async (criteria) => {
        const body = criteriaToBodyAdapter(criteria);
        await fetcher.patch(`/api/tracing/extractions/approve`, body);
    },
    rejectManyDiExtractions: async (criteria) => {
        const body = criteriaToBodyAdapter(criteria);
        await fetcher.patch(`/api/tracing/extractions/reject`, body);
    },
    retryManyDiExtractions: async (criteria) => {
        const body = criteriaToBodyAdapter(criteria);
        await fetcher.patch(`/api/tracing/extractions/retry`, body);
    }
};

const criteriaToQueryAdapter = (criteria?: DiExtractionCriteria) => {
    const apiCriteria = {
        page: criteria?.page,
        size: criteria?.size,
        // T00:00:00 is required by back
        'creationDate.greaterThanOrEqual': criteria?.requestDateFrom
            ? criteria?.requestDateFrom + 'T00:00:00'
            : undefined,
        'creationDate.lessThanOrEqual': criteria?.requestDateTo ? criteria?.requestDateTo + 'T00:00:00' : undefined,
        'promotionId.equals': criteria?.promotionId,
        'channel.contains': criteria?.origin,
        sort: criteria?.sort || 'requestTime,desc',
        'state.in': criteria?.state?.join(','),
        'id.in': criteria?.ids?.join(','),
        'creatorEmail.contains': criteria?.email,
        'documentName.contains': criteria?.fileName,
        withWarnings: criteria?.withWarnings
    };

    return apiCriteria;
};

const criteriaToBodyAdapter = (criteria?: DiExtractionCriteria) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
        ids,
        origin,
        promotionId,
        requestDateFrom,
        requestDateTo,
        state,
        email,
        fileName,
        withWarnings,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        sort,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        page
    } = criteria || {};
    const draft = {};

    if (requestDateFrom || requestDateTo) draft['creationDate'] = {};
    if (requestDateFrom) draft['creationDate']['greaterThanOrEqual'] = requestDateFrom + 'T00:00:00';
    if (requestDateTo) draft['creationDate']['lessThanOrEqual'] = requestDateTo + 'T00:00:00';

    if (promotionId) draft['promotionId'] = { equals: promotionId };
    if (origin) draft['channel'] = { contains: origin };
    if (state) draft['state'] = { in: state };
    if (ids) draft['id'] = { in: ids };
    if (email) draft['creatorEmail'] = { contains: email };
    if (fileName) draft['documentName'] = { contains: fileName };
    if (typeof withWarnings === 'boolean') draft['withWarnings'] = withWarnings;

    return draft;
};
