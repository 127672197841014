import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import { PromotionKPIsGoalScope, usePromotionKPIs } from 'modules/promotion-kpis';
import { KPIGoalProps } from '../KPIGoal';

export const useGoalScopeFlowOptions = (props: KPIGoalProps) => {
    const [, , { api }] = usePromotionKPIs();

    const [loadScopeFlowOptions] = useAsyncOptions({
        request: ({ search, page }) => api.getGoalScopes({ kpi: props.kpi, search, page }),
        adapter: (scope) => scopeFlowOptionAdapter(scope)
    });

    return { loadScopeFlowOptions };
};

export function scopeFlowOptionAdapter(scope: PromotionKPIsGoalScope): SelectOption {
    return { value: scope, label: scope.name };
}
