import { IconAlertOctagon, IconDownload, IconInfoCircle } from '@tabler/icons';
import { AgGridReact } from 'ag-grid-react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

// from https://www.ag-grid.com/theme-builder/
// import './styles/ag-grid-theme-builder.css';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import DropdownList from 'components/BasicComponents/DropdownList';
import EmptyState from 'components/BasicComponents/EmptyState';
import Spinner from 'components/BasicComponents/Spinner/Spinner';
import SwitchComponent from 'components/BasicComponents/Switch/Switch';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import TableFilters from 'lib/ag-grid/components/TableFilters';
import { FilterModel } from 'lib/ag-grid/types/agGridTypes';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useComparative } from 'modules/comparative';
import './Comparative.scss';
import ComparativeExplanation from './components/ComparativeExplanation/ComparativeExplanation';
import ComparativeObservationsModal from './components/ComparativeObservationsModal/ComparativeObservationsModal';
import ComparativeUpdater from './components/ComparativeUpdater';
import { useComparativeExport } from './hooks/useComparativeExport';
import { useComparativeGrid } from './hooks/useComparativeGrid';
import { useComparativeHeight } from './hooks/useComparativeHeight';
import { useComparativeState } from './hooks/useComparativeState';

const Comparative = () => {
    const [t] = useTranslation('comparative');
    const [, analytics] = useAnalyticsStore();

    const comparative = useComparative();
    const container = useComparativeHeight();
    const { options, openObservationsModal, setOpenObservationsModal, selectedObservationsInfo } = useComparativeGrid();
    const { save, state, shouldPersist, setShouldPersist, initialState } = useComparativeState();

    const [openExplanationModal, setOpenExplanationModal] = useState(false);

    const gridRef = useRef<AgGridReact>(null);
    const { exportComparativeAsCsv, exportComparativeAsExcel } = useComparativeExport({ gridRef });

    const refetch = async () => {
        if (comparative.loading === 'pending') return;
        comparative.refetch();
    };

    return (
        <div className="Comparative">
            <div
                ref={container.ref}
                className="ag-theme-quartz" // applying the Data Grid theme
            >
                {comparative.error ? (
                    <>
                        <ErrorCard error={comparative.error} />
                        <EmptyState icon={IconAlertOctagon} title={t('error.title')} subtitle={t('error.subtitle')} />
                    </>
                ) : (
                    <>
                        {/* Header ID is being used at useComparativeHeight */}
                        <header className="Comparative__header" id="Comparative__header">
                            <TableFilters
                                i18nNamespace="comparative"
                                filtersObject={(state.filter?.filterModel as FilterModel) || undefined}
                                updateFilters={(updatedFilters) => gridRef.current?.api?.setFilterModel(updatedFilters)}
                            />

                            <div className="Comparative__shouldPersist">
                                <SwitchComponent
                                    variant="small"
                                    label={t('shouldPersist.label')}
                                    checked={shouldPersist}
                                    onChange={setShouldPersist}
                                />
                                <Tooltip msg={t('shouldPersist.info')}>
                                    <IconInfoCircle size={16} stroke={1.75} />
                                </Tooltip>
                            </div>
                            <div className="Comparative__actions">
                                <ComparativeUpdater onRefresh={refetch} />
                                <DropdownList
                                    items={[
                                        <li
                                            role="button"
                                            onClick={() => {
                                                analytics.event(AnalyticsEvents.COMPARATIVE_EXPORT_EXCEL);
                                                exportComparativeAsExcel();
                                            }}
                                        >
                                            {t('excelFormat', { ns: 'common' })}
                                        </li>,
                                        <li
                                            role="button"
                                            onClick={() => {
                                                analytics.event(AnalyticsEvents.COMPARATIVE_EXPORT_CSV);
                                                exportComparativeAsCsv();
                                            }}
                                        >
                                            {t('csvFormat', { ns: 'common' })}
                                        </li>
                                    ]}
                                >
                                    <SmallButton
                                        color="blue"
                                        titulo={t('exportData', { ns: 'common' })}
                                        icon={<IconDownload />}
                                    />
                                </DropdownList>

                                <SmallButton
                                    color="blue"
                                    titulo={t('howItsCalculated')}
                                    icon={<IconInfoCircle />}
                                    action={() => setOpenExplanationModal(true)}
                                />
                            </div>
                        </header>
                        <AgGridReact
                            ref={gridRef}
                            initialState={initialState}
                            rowData={comparative.data || []}
                            onStateUpdated={(p) => save(p.state)}
                            noRowsOverlayComponent={() => <Spinner />}
                            getRowId={(row) => row.data.promotion.id.toString()}
                            {...options}
                        />
                        {openExplanationModal && (
                            <ComparativeExplanation
                                openModal={openExplanationModal}
                                setOpenModal={setOpenExplanationModal}
                            />
                        )}
                        {openObservationsModal && (
                            <ComparativeObservationsModal
                                openModal={openObservationsModal}
                                setOpenModal={setOpenObservationsModal}
                                observationsInfo={selectedObservationsInfo}
                                onRefresh={comparative.refetch}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Comparative;
