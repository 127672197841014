import fetcher from 'lib/fetcher';
import { Promotion } from 'modules/promotion/domain/promotion/Promotion';
import { PromotionType } from 'modules/promotion/domain/promotion/PromotionType';
import { PromotionApi } from 'modules/promotion/domain/PromotionApi';
import { deepMerge } from 'utils/helpers/object.helpers';

const getPromotionById: PromotionApi['getPromotionById'] = async (id) => {
    const params = { 'id.equals': id };
    const { data } = await fetcher.get('api/maintenance/promotions', { params });
    let [promotion] = data;

    return promotion;
};

const getPromotionsPreview: PromotionApi['getPromotionsPreview'] = async (criteria, mode) => {
    const params = {
        'id.equals': criteria.id,
        page: criteria.page,
        status: criteria.status === 'inProgress' ? 'inprogress' : criteria.status,
        ...(criteria.search !== '' && { 'search.contains': criteria.search }),
        // always search by, at least, name and tagName
        ...(criteria.search !== '' && { searchFor: ['name', 'tagName', ...(criteria.searchFor || [])].join(',') })
    };

    let arrayPinned = [];
    let arrayNotPinned = [];
    let totalPinned = { inProgress: 0, finished: 0 };
    let totalNotPinned = { inProgress: 0, finished: 0 };

    const arePinnedPreviews = criteria.pinnedPreviewIds !== undefined && criteria.pinnedPreviewIds.length > 0;

    const loadPinnedPreviews = arePinnedPreviews && mode === 'replace'; // Get pinned previews only on replace

    const pinnedIds = arePinnedPreviews ? criteria.pinnedPreviewIds!.join(',') : undefined;

    // GET PINNED
    if (loadPinnedPreviews) {
        const pinnedRequest = await fetcher.get('api/project-preview', {
            params: { ...params, 'id.in': pinnedIds, size: criteria.pinnedPreviewIds?.length || 20 }
        });
        arrayPinned = pinnedRequest?.data?.content?.projectPreviews || [];
        totalPinned = {
            inProgress: pinnedRequest?.data?.content?.total?.inProgress,
            finished: pinnedRequest?.data?.content?.total?.finished
        };
    }

    // ALWAYS GET NOT PINNED PREVIEWS
    const notPinnedRequest = await fetcher.get('api/project-preview', {
        params: { ...params, 'id.notIn': pinnedIds }
    });

    arrayNotPinned = notPinnedRequest.data?.content?.projectPreviews || [];
    totalNotPinned = {
        inProgress: notPinnedRequest?.data?.content?.total?.inProgress,
        finished: notPinnedRequest?.data?.content?.total?.finished
    };

    return {
        promotionPreviews: [...arrayPinned, ...arrayNotPinned],
        // RETURN TOTALS ONLY WHEN
        // -> There are no pinned previews (total = totalNotPinned)
        // -> There are pinned but we just loaded them (total = totalNotPinned + totalPinned)
        // ELSE: RETURN UNDEFINED
        // -> This will happen when retrieving the rest of the promotions (= loadMore())
        // At application layer, skip the update of totals if totals = undefined
        total:
            !arePinnedPreviews || loadPinnedPreviews
                ? {
                      inProgress: totalPinned.inProgress + totalNotPinned.inProgress,
                      finished: totalPinned.finished + totalNotPinned.finished
                  }
                : undefined
    };
};

const getTypes: PromotionApi['getTypes'] = async (criteria) => {
    const params = {
        ...(criteria.search ? { 'nombre.contains': criteria.search } : {}),
        page: criteria.page || 0,
        size: criteria.size || 999
    };

    const { data } = await fetcher.get(`/api/maintenance/promotion-type`, { params });

    const types: PromotionType[] = data.map((entry) => ({
        id: entry.id,
        name: entry.nombre
    }));

    return types;
};

const updatePromotion: PromotionApi['updatePromotion'] = async (payload) => {
    // Retrives the current promotion fields
    const promotion = await getPromotionById(payload.id);

    // Merge the payload with the already existing promotion fields
    const body: Promotion = deepMerge(structuredClone(promotion), structuredClone(payload));

    // If the address has changed, we need to create a new one
    if (JSON.stringify(body.direccion) !== JSON.stringify(promotion.direccion)) {
        const { id: _, ...newAddressBody } = body.direccion;
        const { data: newAddress } = await fetcher.post(`/api/maintenance/address`, newAddressBody);
        body.direccion = newAddress;
    }

    // Updates promotion
    await fetcher.put(`/api/maintenance/promotions`, body);

    // Reloads if nima was undefined before the update
    if (!promotion.nima) window.location.reload();
};

export const httpPromotionApi: PromotionApi = {
    getPromotionById,
    getPromotionsPreview,
    getTypes,
    updatePromotion
};
