import { useTranslation } from 'react-i18next';

import SubmitButton from 'components/BasicComponents/Buttons/SubmitButton';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { TituloH2 } from 'components/BasicComponents/Titulos/Titulos';
import MessageNotComplete from 'components/BasicComponents/Messages/MessageNotComplete';
import { usePromotionBilleables } from 'modules/billeables';

import BadgesForm from './components/BadgesForm';
import PromotionBilleables from './components/PromotionBilleables';

import './ProductsAndServices.scss';
import OthersForm from './components/OthersForm';

const ProductsAndServices = (props: any) => {
    const [billeables] = usePromotionBilleables();
    const [t] = useTranslation();
    const { submitting } = props;

    const buttonDisabled = submitting || billeables.loading === 'saving';

    return (
        <SimpleCard>
            <TituloH2 titulo={t('productsAndServices.name', { ns: 'newPromotion' })} />
            <form onSubmit={props.createPromotionForm}>
                <div className="ProductsAndServices__formContainer">
                    <div className="ProductsAndServices__column ProductsAndServices__column--first">
                        <PromotionBilleables />
                    </div>
                    <div className="ProductsAndServices__column ProductsAndServices__column--second">
                        <section>
                            <BadgesForm {...props} />
                        </section>
                        <section>
                            <OthersForm {...props} />
                        </section>
                    </div>
                </div>
                <footer className="ProductsAndServices__actions">
                    <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={buttonDisabled} />
                </footer>
                {props.messageNotComplete && <MessageNotComplete message={props.messageNotComplete} />}
            </form>
        </SimpleCard>
    );
};

export default ProductsAndServices;
