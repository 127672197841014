import { useTranslation } from 'react-i18next';

/**
 * Because this is an infinite scroll list item to ensure performance,
 * we import icons as images instead of React components
 */
import IconAlertTriangleRed14 from 'assets/iconos/automatic-reading/icon-alert-triangle-red-14.svg';
import IconLinkPurple14 from 'assets/iconos/automatic-reading/icon-link-purple-14.svg';
import IconLoaderPurple14 from 'assets/iconos/automatic-reading/icon-loader-purple-14.svg';
import IconTruckDeliveryGrey18 from 'assets/iconos/automatic-reading/icon-truck-delivery-grey-18.svg';
import IconRecycleGrey18 from 'assets/iconos/automatic-reading/icon-recycle-grey-18.svg';
import IconTrashGrey14 from 'assets/iconos/automatic-reading/icon-trash-grey-14.svg';
import IconUnlinkGrey14 from 'assets/iconos/automatic-reading/icon-unlink-grey-14.svg';
import IconWeightGrey18 from 'assets/iconos/automatic-reading/icon-weight-grey-18.svg';

import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { DiExtrationStatus, IDiExtraction } from 'modules/di-extraction';
import { usePromotion } from 'modules/promotion';
import { Link } from 'react-router-dom';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import './AutomaticReadingResult.scss';

interface AutomaticReadingResultProps {
    diExtraction: IDiExtraction;
}

const AutomaticReadingResult = ({ diExtraction }: AutomaticReadingResultProps) => {
    const [t] = useTranslation('automaticReading');
    const [{ promotion }] = usePromotion();
    const { format } = useDateFormatter();
    const { ppgcl, date, weight } = diExtraction.result;

    const getDataStyles = (value: any) =>
        `AutomaticReadingResult__extractionData ${!value ? 'AutomaticReadingResult__extractionData--error' : ''} `;

    switch (diExtraction.state) {
        case DiExtrationStatus.REJECTED:
            return (
                <a
                    className="AutomaticReadingResult__text--link"
                    href={diExtraction.fileUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={IconTrashGrey14} alt="" />
                    <span>{t(`resultsInfo.${DiExtrationStatus.REJECTED}`)}</span>
                </a>
            );
        case DiExtrationStatus.PENDING:
            return (
                <div className="AutomaticReadingResult__text AutomaticReadingResult__text--loading">
                    <img src={IconLoaderPurple14} alt="" />
                    <span>{t(`resultsInfo.${DiExtrationStatus.PENDING}`)}</span>
                </div>
            );

        case DiExtrationStatus.NOT_VALID:
            return (
                <div className="AutomaticReadingResult__text AutomaticReadingResult__text--error">
                    <img src={IconAlertTriangleRed14} alt="" />
                    <span>{t(`resultsInfo.${DiExtrationStatus.NOT_VALID}`)}</span>
                </div>
            );

        case DiExtrationStatus.DOCUMENT_UPLOAD_ERROR:
            return (
                <div className="AutomaticReadingResult__text AutomaticReadingResult__text--error">
                    <img src={IconAlertTriangleRed14} alt="" />
                    <span>{t(`resultsInfo.${DiExtrationStatus.DOCUMENT_UPLOAD_ERROR}`)}</span>
                </div>
            );

        case DiExtrationStatus.APPROVED:
        case DiExtrationStatus.ALREADY_PROCESSED:
            return diExtraction.traceabilityId ? (
                <Link
                    className="AutomaticReadingResult__text AutomaticReadingResult__text--link"
                    to={`/promotion/${promotion?.id}/traceabilities/traceability/${diExtraction?.traceabilityId}`}
                >
                    <img src={IconLinkPurple14} alt="" />
                    <span>
                        {t(`resultsInfo.${DiExtrationStatus.APPROVED}`, {
                            id: diExtraction.traceabilityId
                        })}
                    </span>
                </Link>
            ) : (
                <p className="AutomaticReadingResult__text">
                    <img src={IconUnlinkGrey14} alt="" />
                    <span>{t('noLinkedDi')}</span>
                </p>
            );

        case DiExtrationStatus.VALIDATION_ERROR:
        case DiExtrationStatus.PENDING_REVIEW:
        default:
            return (
                <div className="AutomaticReadingResult__extractionDataRow">
                    <div className={getDataStyles(date)}>
                        <img src={IconTruckDeliveryGrey18} alt="" />
                        <span>{date ? format(date, 'DD/MM/YY') : t('noDate')}</span>
                    </div>
                    <div className={getDataStyles(weight)}>
                        <img src={IconWeightGrey18} alt="" />
                        <span>{weight ? `${currencyFormatDE(weight)} Tn` : t('noWeight')}</span>
                    </div>
                    <div className={getDataStyles(ppgcl)}>
                        <img src={IconRecycleGrey18} alt="" />
                        <Tooltip
                            delayShow={500}
                            msg={
                                ppgcl &&
                                `Gestora: ${ppgcl.manager.name} <br /> Transportista: ${ppgcl.carrier?.name || '-'}`
                            }
                        >
                            <span>{ppgcl ? `${ppgcl.ler.code} - ${ppgcl.manager.name}` : t('noFlow')}</span>
                        </Tooltip>
                    </div>
                </div>
            );
    }
};

export default AutomaticReadingResult;
