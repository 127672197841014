import WasteByValorization from 'features/promotion/pages/PromotionDetail/sections/WasteByValorization';
import './WasteByValorizationChartPrintable.scss';

const WasteByValorizationChartPrintable = () => {
    return (
        <>
            <div className="WasteByValorizationChartPrintable">
                <WasteByValorization chart={{ variant: 'horizontal' }} />
            </div>
        </>
    );
};

export default WasteByValorizationChartPrintable;
