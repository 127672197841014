import axios from 'axios';
import { dispatchLogout } from '../../lib/LogoutHandler';
import { getToken } from '../../utils/helpers/api.helpers';

class UserServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    getUserById = async (id) => {
        try {
            const response = await this.services.get(`/api/users?id.equals=${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response?.data?.content?.[0];
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getUsersByPromoId = async (promoId) => {
        try {
            // OJO es "promocion" aquí
            const response = await this.services.get(`/api/promotion/${promoId}/users`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response?.data?.content;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getUsersByEntepriseId = async (enterpriseId, filters) => {
        try {
            // OJO es "promocion" aquí
            const response = await this.services.get(`/api/enterprise/${enterpriseId}/users${filters || ''}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response?.data?.content;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    editUser = async (user) => {
        const body = this.userToUserCreatorAdapter(user);
        try {
            const response = await this.services.put(`/api/users`, body, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    removeUserFromPromotion = async (user, promotionId) => {
        const editedUser = { ...user, promotionIds: [...user.promotionIds].filter((id) => id !== promotionId) };

        const body = this.userToUserCreatorAdapter(editedUser);
        try {
            const response = await this.services.put(`/api/users`, body, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            } else {
                throw error;
            }
        }
    };

    addUserToPromotion = async (user, promotionId) => {
        const editedUser = { ...user, promotionIds: [...user.promotionIds, promotionId], activated: true };

        const body = this.userToUserCreatorAdapter(editedUser);
        try {
            const response = await this.services.put(`/api/users`, body, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            } else {
                throw error;
            }
        }
    };

    getRoleTypes = async (url) => {
        try {
            const response = await this.services.get(`/api/role-types${url}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    createUser = async (user) => {
        try {
            const body = this.userToUserCreatorAdapter(user);
            const response = await this.services.post(`/api/users`, body, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getUser = async (filter) => {
        try {
            const response = await this.services.get(`/api/users${filter}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response.data?.content;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    deleteUser = async (id) => {
        try {
            const response = await this.services.delete(`/api/users/${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response.data?.content;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    userToUserCreatorAdapter = (user) => {
        const { enterprise, ...data } = user;
        const userCreator = {
            ...data,
            enterpriseId: enterprise?.id
        };
        return userCreator;
    };
}

const userServices = new UserServices();
export default userServices;
