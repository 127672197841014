import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import userServices from 'api/user/user.services';
import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import NewUserForm from 'components/ComplexComponents/NewPromotion/Step3/NewUserForm';
import SearchUsers from 'components/ComplexComponents/NewPromotion/Step3/SearchUsers';
import Usuarios from 'components/ComplexComponents/Promotion/Usuarios';
import { useUsers } from 'hooks/useUsers';
import { usePromotion } from 'modules/promotion';
import './Users.scss';
import HasPermission from 'components/AccessCheckers/HasPermission';

const Users = () => {
    const [{ promotion }] = usePromotion();
    const [t] = useTranslation('users');

    const [users, setUsers] = useUsers(promotion?.id);

    const [userToEditOrCreate, setUserToEditOrCreate] = useState(null as any);
    const [userToAdd, setUserToAdd] = useState(null as any);
    const [showEditUserModal, setShowEditUserModal] = useState(false);
    const [isAddingUser, setIsAddingUser] = useState(false);

    const openEditPopover = async (user) => {
        const getUser = await userServices.getUser(`?id.equals=${user.id}`);
        setUserToEditOrCreate(getUser[0]);
        setShowEditUserModal(!showEditUserModal);
    };

    const removeUserFromPromotion = async (user) => {
        try {
            await userServices.removeUserFromPromotion(user, promotion?.id);

            const newUsers = users.filter((u) => u.id !== user.id);
            setUsers(newUsers);
        } catch (error) {}
    };

    const addUserToPromotion = async () => {
        setIsAddingUser(true);
        try {
            await userServices.addUserToPromotion(userToAdd, promotion?.id);
            const newUsers = [userToAdd, ...users];
            setUsers(newUsers);
        } catch (error) {}

        setIsAddingUser(false);
        setUserToAdd(null);
    };

    return (
        <div className="Users">
            <header className="Users__header">
                <TituloH1 titulo={t('users')} />
                <HasPermission ifRole="canCreate" section="users">
                    <CreateButton action={() => setShowEditUserModal(true)} text={t('newUser')} type="button" />
                </HasPermission>
            </header>

            <HasPermission ifRole="canCreate" section="users">
                <SimpleCard className="Users__add" title={t('addUsers')} subtitle={t('addUsersExplanation')}>
                    <div className="Users__add-content">
                        <SearchUsers className="Users__search" required value={userToAdd} onChange={setUserToAdd} />
                        <CreateButton
                            disabled={!userToAdd}
                            text={t('addUser')}
                            type="button"
                            action={addUserToPromotion}
                            isLoading={isAddingUser}
                        />
                    </div>
                </SimpleCard>
            </HasPermission>

            <Usuarios {...{ users, openEditPopover, removeUserFromPromotion }} />

            {showEditUserModal && (
                <SimplePopover
                    openModal={showEditUserModal}
                    setOpenModal={setShowEditUserModal}
                    title={userToEditOrCreate?.id ? t('editUser') : t('newUser')}
                    className="simplePopover newPromotion__popover"
                    addFunctionOnClose={() => setUserToEditOrCreate(null)}
                >
                    <NewUserForm
                        setToggleFormNewUser={setShowEditUserModal}
                        {...{ userToEditOrCreate, setUsers, users }}
                        promotion={promotion}
                    />
                </SimplePopover>
            )}
        </div>
    );
};

export default Users;
