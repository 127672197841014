import { ResponsiveLine } from '@nivo/line';
import { TituloH2 } from '../../../../components/BasicComponents/Titulos/Titulos';
import options, { labelOptions } from './options';
import '../Impact.scss';

const TemporalLineGraph = ({ graphData, graphTitle }) => {
    return (
        <div className="Impact__graph">
            <TituloH2 titulo={graphTitle} />
            <div className="Impact__lineGraph">
                <ResponsiveLine
                    colors={options.colorsLineChart}
                    margin={options.margin}
                    pointSize={9}
                    pointBorderWidth={3}
                    data={graphData}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={options.axisBottom}
                />
            </div>
            <div className="Impact__containerLegends">
                <p>
                    <span className={`impact__legend impact__legend--${labelOptions['real'].id}`}></span>
                    {labelOptions['real'].label}
                </p>
                <p>
                    <span className={`impact__legend impact__legend--${labelOptions['potential'].id}`}></span>
                    {labelOptions['potential'].label}
                </p>
                <p>
                    <span className={`impact__legend impact__legend--${labelOptions['standard'].id}`}></span>
                    {labelOptions['standard'].label}
                </p>
            </div>
        </div>
    );
};

export default TemporalLineGraph;
