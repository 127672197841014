import { ApiRelatedEnterprises } from 'modules/shared/domain/ApiPromotion';

export const createNewRelatedEnterprise = (
    relatedEnterprise: ApiRelatedEnterprises,
    currentRelatedEnterprises: ApiRelatedEnterprises[]
) => {
    if (currentRelatedEnterprises.length === 0) {
        relatedEnterprise.mainEnterprise = true;
    }

    return relatedEnterprise;
};
