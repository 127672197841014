import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import DateFormControl from 'components/FormControls/DateFormControl';
import InputFormControl from 'components/FormControls/InputFormControl';
import SelectAutocompleteFormControl from 'components/FormControls/SelectAutocompleteFormControl';
import { usePromotion } from 'modules/promotion';
import { ADDITIONAL_FIELDS_BY_PROMO_TYPE } from 'utils/constants/promotion.constants';
import { PromotionDataFormSchema } from '../schemas/PromotionDataFormSchema';

const conditionalFields = ADDITIONAL_FIELDS_BY_PROMO_TYPE;
const typeOptionAdapter = (v: PromotionDataFormSchema['type']) => ({ label: v.name, value: v.id });

const PromotionDataInfoForm = () => {
    const [t] = useTranslation('promotion');
    const form = useFormContext<PromotionDataFormSchema>();
    const [, , { api }] = usePromotion();

    const [loadTypesOptions] = useAsyncOptions({
        request: api.getTypes,
        adapter: typeOptionAdapter
    });

    const typeId = form.watch('type')?.id;
    const enabledFields = conditionalFields.find((t) => t.id === typeId)?.fields.map((f) => f.name) || [];

    return (
        <>
            <div className="PromotionDataForm__row">
                <SelectAutocompleteFormControl
                    name="type"
                    control={form.control}
                    label={t('type')}
                    valueAdapter={typeOptionAdapter}
                    onChangeAdapter={(v) => {
                        if (Array.isArray(v) || !v) return undefined;
                        return { id: v.value, name: v.label };
                    }}
                    loadOptions={loadTypesOptions}
                    className="PromotionDataForm__control --full --medium-large"
                />
                {enabledFields.includes('numHouses') && (
                    <InputFormControl
                        name="numHouses"
                        type="number"
                        control={form.control}
                        label={t('numHouses')}
                        className="PromotionDataForm__control --small"
                    />
                )}
                {enabledFields.includes('power') && (
                    <InputFormControl
                        name="power"
                        type="number"
                        control={form.control}
                        label={t('power')}
                        className="PromotionDataForm__control --small"
                    />
                )}
                <InputFormControl
                    name="pem"
                    type="number"
                    control={form.control}
                    label={t('pem')}
                    className="PromotionDataForm__control --medium"
                />
                <InputFormControl
                    name="surface"
                    type="number"
                    control={form.control}
                    label={t('surface')}
                    className="PromotionDataForm__control --small"
                />
            </div>
            <div className="PromotionDataForm__row">
                <DateFormControl
                    name="startDate"
                    control={form.control}
                    label={t('startDate')}
                    className="PromotionDataForm__control --medium"
                />
                <DateFormControl
                    name="endDate"
                    type="text"
                    control={form.control}
                    label={t('endDate')}
                    className="PromotionDataForm__control --medium"
                />
            </div>
        </>
    );
};

export default PromotionDataInfoForm;
