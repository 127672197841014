import { IconCurrencyEuro, IconStar, IconTrash } from '@tabler/icons';
import ReactTooltip from 'react-tooltip';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import { getEnterpriseRole } from 'modules/enterprise/related-enterprise/domain/constants/enterpriseRoles';
import { useRelatedEnterprisesData } from 'modules/enterprise/related-enterprise/infraestructure/store/relatedEnterpriseStore';
import { ApiRelatedEnterprises } from 'modules/shared/domain/ApiPromotion';
import './RelatedEnterprise.scss';

const RelatedEnterprise = (props) => {
    const relatedEnterprise: ApiRelatedEnterprises = props.relatedEnterprise;
    const { classNames } = props;

    const [, { changeRelatedEnterprise, removeRelatedEnterprise }] = useRelatedEnterprisesData();

    const enterpriseRole = getEnterpriseRole(relatedEnterprise.enterpriseRole);

    return (
        <div
            data-testid={`relatedEnterpriseItem__${relatedEnterprise.id}`}
            className={`relatedEnterprise ${classNames || ''}`}
        >
            <div className="relatedEnterprise__data">
                <p className="relatedEnterprise__role">{enterpriseRole}</p>
                {relatedEnterprise.client && (
                    <p
                        data-for={'clientTooltip' + relatedEnterprise.id}
                        data-tip="Es cliente"
                        data-offset="{'top': -10}"
                        className="relatedEnterprise__price"
                    >
                        <IconCurrencyEuro />
                        <ReactTooltip
                            id={'clientTooltip' + relatedEnterprise.id}
                            delayHide={100}
                            effect="solid"
                            multiline={true}
                        />
                    </p>
                )}
                {relatedEnterprise.mainEnterprise && (
                    <p
                        data-testid="mainEnterpriseBadge"
                        data-for={'mainEnterpriseTooltip' + relatedEnterprise.id}
                        data-tip="Empresa principal de este proyecto"
                        data-offset="{'top': -10}"
                        className="relatedEnterprise__mainEnterprise"
                    >
                        <IconStar />
                        <ReactTooltip
                            id={'mainEnterpriseTooltip' + relatedEnterprise.id}
                            delayHide={100}
                            effect="solid"
                            multiline={true}
                        />
                    </p>
                )}
                <p>
                    <span>{relatedEnterprise.enterpriseName}</span>
                </p>
            </div>
            <div className="relatedEnterprise__buttons">
                {!relatedEnterprise.mainEnterprise && (
                    <>
                        <SmallButton
                            icon={<IconStar />}
                            titulo="Empresa principal"
                            action={() => changeRelatedEnterprise(relatedEnterprise.id)}
                        />
                        <SmallButton
                            icon={<IconTrash />}
                            titulo={'Eliminar relación'}
                            action={() => removeRelatedEnterprise(relatedEnterprise.id)}
                        />
                    </>
                )}
            </div>
        </div>
    );
};

export default RelatedEnterprise;
