import { useState, useEffect } from 'react';

const InputEmail = ({
    label = '',
    id = '',
    placeholder = '',
    name,
    value,
    onChange,
    inputContainer,
    className,
    onlyRead,
    required,
    validacion
}) => {
    const [notComplete, setNotComplete] = useState(false);

    useEffect(() => {
        inputVacio();
    }, [notComplete, validacion]);

    const inputVacio = () => {
        if (validacion === true && required && value === undefined) {
            setNotComplete(true);
        } else if (validacion === true && required && value === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    const classInput = () => {
        let inputClass = 'input__input';
        if (notComplete) {
            inputClass = `${inputClass} input__input--notComplete`;
        }
        if (className) {
            inputClass = `${inputClass} ${className}`;
        }
        return inputClass;
    };

    return (
        <div className={inputContainer ? `${inputContainer} input__container` : `input__container`}>
            <label className="input__label" htmlFor={id}>
                {label} {required ? '*' : ''}
            </label>
            <input
                id={id}
                type="email"
                name={name}
                value={value}
                onChange={onChange}
                className={classInput()}
                placeholder={placeholder}
                readOnly={onlyRead ? true : false}
            />
        </div>
    );
};
export default InputEmail;
