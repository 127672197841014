import { AsyncPaginate } from 'react-select-async-paginate';
import { customStyles } from './helpers';

const SelloVerdeAutocomplete = (props) => {
    const optionStates = [
        { description: 'BREEAM' },
        { description: 'LEED' },
        { description: 'GBCe' },
        { description: 'DGNB' }
    ];

    const loadOptions = async (search, loadedOptions, { page }) => {
        let optionsStatesDi = optionStates;
        if (search) {
            optionsStatesDi = optionsStatesDi.filter((opcion) => opcion.toLowerCase().includes(search.toLowerCase()));
        }
        return {
            options: optionsStatesDi,
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        if (item === null) {
            setValues({ ...values, selloVerde: '' });
        } else {
            setValues({ ...values, selloVerde: item.description });
        }
    };

    const { estado } = props.values;
    const { setValues, values, required, className } = props;

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">Sello verde{required ? '*' : ''}</label>
            <AsyncPaginate
                loadOptions={loadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.description}
                placeholder=""
                value={estado}
                additional={{
                    page: 0
                }}
                filterOption={false}
                styles={customStyles()}
                isClearable={true}
            />
        </div>
    );
};

export default SelloVerdeAutocomplete;
