export const AUTH_TOKEN_KEY = 'jhi-authenticationToken';

export const AUTH_INITIAL_STATE = {
    isLoggedin: false,
    user: null,
    isLoading: true,
    message: null,
    authorities: [],
    isCoCircular: false,
    pathRedirect: '',
    originLink: null
};
