import { PromotionPreview as IPromotionPreview } from 'modules/promotion/domain/promotion/PromotionPreview';
import { useAdditionalData } from 'features/home/hooks/useAdditionalData';
import './PromotionItem.scss';

interface PromotionItemProps {
    propName: keyof IPromotionPreview['additionalData'];
    children: string;
}

const PromotionItem = ({ propName, children }: PromotionItemProps) => {
    const { additionalData } = useAdditionalData();
    const currentPropData = additionalData.find((element) => element.propName === propName);

    if (!children) return <></>;

    return (
        <p className="PromotionItem">
            {currentPropData?.icon}
            <span>{currentPropData?.transformFunction ? currentPropData?.transformFunction(children) : children}</span>
            {currentPropData?.extraText && <span>{currentPropData?.extraText}</span>}
        </p>
    );
};

export default PromotionItem;
