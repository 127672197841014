import { Reducers, createStore } from 'modules/shared/domain/store/createStore';
import { SupportStore } from '../domain/SupportStore';
import { sendTicket } from './send/sendTicket';

export const supportStore = createStore({
    reducers: {} as Reducers<SupportStore>,
    initialState: {},
    thunks: {
        sendTicket
    }
});
