import logo from '../../../assets/iconos/CoCircular/icono_Mesa de trabajo 1_Mesa de trabajo 1.svg';
import './Spinner.scss';

const Spinner = ({ positionRelative = false }) => {
    return (
        <div className={positionRelative ? 'spinner spinner__relative' : 'spinner spinner__absolute'}>
            <img src={logo} alt="CoCircular logo" />
        </div>
    );
};
export default Spinner;
