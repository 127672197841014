import { AgGridReact } from 'ag-grid-react';
import ExcelJS from 'exceljs';
import { RefObject } from 'react';

type Column = { key: string; header: string; level: number; columnNumber: number; hasChildren: boolean };

interface ExportAsExcelProps {
    gridRef: RefObject<AgGridReact>;
    fileName: string;
    worksheetName: string;
    prependString: string;
    customizeValuesFn?: (row: Record<string, any>) => Record<string, any>;
}

export function useExcelExport() {
    const exportAsExcel = async (props: ExportAsExcelProps) => {
        if (!props.gridRef?.current?.api) return;

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet(props.worksheetName);
        const ROWS_MARGIN = 3; // "prependString" row + 2 margin rows

        // Calculate and define columns
        const { columns, totalColumns, totalHeaderRows } = calculateHeaders(props.gridRef.current.api.getColumnDefs());
        const rootColumns = columns.filter((col) => !col.hasChildren) || [];
        sheet.columns = rootColumns;

        // Add necessary rows for superior headers + all data
        const emptyRows = Array.from({ length: totalHeaderRows + ROWS_MARGIN - 1 }, () => []);
        const rows = calculateRows(props.gridRef, rootColumns, props.customizeValuesFn);
        sheet.addRows([...emptyRows, ...rows]);

        // Add extra headers
        for (let i = totalHeaderRows; i >= 1; i--) {
            const currentHeaders = columns.filter((col) => col.hasChildren && col.level === i) || [];
            const extraHeaders = calculateExtraHeaders(currentHeaders, totalColumns);
            sheet.getRow(i + ROWS_MARGIN).values = extraHeaders;
        }

        // Move column definitions closer to data rows + Display metadata on 1st row
        sheet.getRow(totalHeaderRows + ROWS_MARGIN).values = sheet.getRow(1).values;
        sheet.getRow(1).values = [props.prependString];

        // Styles (fit columsn width + headers with purple background)
        autoFitColumnsWidth(sheet);
        for (let i = 1; i <= totalHeaderRows; i++)
            sheet.getRow(ROWS_MARGIN + i).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF6F2FF' } };

        // Create buffer and finally download excel
        const file = await workbook.xlsx.writeBuffer();
        downloadFile(props.fileName, file);
    };

    return { exportAsExcel };
}

const calculateHeaders = (cols): { columns: Column[]; totalHeaderRows: number; totalColumns: number } => {
    const columns: Column[] = [];
    let columnNumber: number = 0;
    let totalHeaderRows: number = 1; // At least there will be one row of headers

    const calculateDepth = (col, level: number) => {
        columns.push({ key: col.field, header: col.headerName, level, columnNumber, hasChildren: !!col.children });

        if (!col.children) columnNumber++;
        if (col.children) col.children.forEach((col) => calculateDepth(col, level + 1));

        if (level > totalHeaderRows) totalHeaderRows = level;
    };

    cols.forEach((col) => calculateDepth(col, 1));

    return { columns, totalHeaderRows, totalColumns: columnNumber };
};

const calculateRows = (
    gridRef: RefObject<AgGridReact>,
    rootColumns: Column[],
    customizeValuesFn?: (value: Record<string, any>) => Record<string, any>
) => {
    const rows: (string | number | null)[][] = [];

    gridRef?.current?.api?.forEachNode((node) => {
        if (!node.displayed) return; // "false" when row doesn't match applied filters (and "true" even if row is not rendered/visible)

        const flattenedData = flattenNodeRow(node.data);
        const modifiedFlattenedData = customizeValuesFn ? customizeValuesFn(flattenedData) : flattenedData;

        const orderedRow = rootColumns.map((col) => modifiedFlattenedData[col.key]); // Making sure row data order always matches columns/headers order

        rows.push(orderedRow);
    });

    return rows;
};

const flattenNodeRow = (obj: any, prefix = ''): Record<string, any> => {
    const flattened: Record<string, string | number | null> = {};

    for (const key in obj) {
        // Exclude sensitive data (id)
        if (key === 'id' || !Object.prototype.hasOwnProperty.call(obj, key)) continue; // == break equivalent for forin

        const value = obj[key];
        const newKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            Object.assign(flattened, flattenNodeRow(value, newKey)); // Nested objects -> recursivity
        } else {
            flattened[newKey] = value; // Save primitive value / array
        }
    }

    return flattened;
};

const calculateExtraHeaders = (currentHeaders: Column[], totalColumns: number) => {
    const extraHeaders: string[] = [];

    // Add empty strings columns between headers if necessary
    for (let i = 0; i <= totalColumns; i++) {
        let text = '';
        for (const header of currentHeaders) {
            if (header.columnNumber === i) {
                text = header.header;
                break;
            }
        }

        extraHeaders.push(text);
    }

    return extraHeaders;
};

const autoFitColumnsWidth = (worksheet: ExcelJS.Worksheet) => {
    const minColumnWidth = 12,
        maxColumnWidth = 24;

    worksheet.columns.forEach((column) => {
        let largestWidth = 0;
        column?.eachCell?.({ includeEmpty: true }, (cell) => {
            largestWidth = Math.max(largestWidth, minColumnWidth, cell.value ? cell.value.toString().length : 0);
        });
        column.width = largestWidth > maxColumnWidth ? maxColumnWidth : largestWidth + 2;
    });
};

const downloadFile = (fileName: string, file: ExcelJS.Buffer) => {
    // HACK to avoid using more libraries: create a hidden link to the file, add it to DOM, click it and remove it
    // (https://github.com/exceljs/exceljs/issues/460#issuecomment-2097008058)
    const downloadLink = document.createElement('a');
    downloadLink.download = `${fileName}.xlsx`;
    downloadLink.href = window.URL.createObjectURL(
        new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    );
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    downloadLink.remove();
};
