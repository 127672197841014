import { useEffect } from 'react';

export function useKey(key: string, callback: () => void, deps: any[] = [], elementId?: string) {
    // listener que escucha cambios cuando presiona Enter
    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.key === key) callback();
        };

        // I had to use this instead a forwardRef... I couldn't make it work this way
        const htmlElement =
            elementId !== undefined && elementId !== null ? document.getElementById(elementId) : document;

        if (!htmlElement) return;

        (htmlElement as HTMLElement).addEventListener('keydown', listener);
        return () => (htmlElement as HTMLElement).removeEventListener('keydown', listener);
    }, [callback, ...deps]);
}
