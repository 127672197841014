import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './AuthHelp.scss';

const AuthHelp = () => {
    const [t] = useTranslation('auth');

    const supportEmail = 'admin@cocircular.es';

    return (
        <div className={`AuthHelp`}>
            <header className="AuthLayout__header AuthLayout__header--align-left">
                <h1 className="AuthLayout__title">{t('recoveryAuthHelpTitle')}</h1>
                <ul>
                    <Trans
                        i18nKey="helpTips"
                        ns="auth"
                        components={{ li: <li className="AuthLayout__text" />, p: <p className="AuthLayout__text" /> }}
                    ></Trans>
                </ul>
                <p className="AuthLayout__text">
                    <Trans
                        i18nKey="helpMsg"
                        ns="auth"
                        values={{ email: supportEmail }}
                        components={[
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <a className="AuthLayout__link" href={`mailto:${supportEmail}`} />,
                            <p />
                        ]}
                    ></Trans>
                </p>
            </header>
            <footer className="AuthLayout__footer">
                <hr />
                <Link to={`/account/login`} className="AuthLayout__link">
                    {t(`goBackLogin`)}
                </Link>
            </footer>
        </div>
    );
};

export default AuthHelp;
