import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { Traceability } from 'modules/traceabilities';

import { TraceabilitiesRemoveOperations } from '../../domain/TraceabilitiesOperations';
import { TraceabilitiesStore } from '../../domain/TraceabilitiesStore';

export const removeOneTraceability =
    (traceability: Traceability, operation: TraceabilitiesRemoveOperations): Thunk<TraceabilitiesStore> =>
    async (store, { api, logger }) => {
        try {
            store.dispatch('removeOneTraceabilityPending', { traceability });
            await api.removeTraceability({ traceability, operation });
            store.dispatch('removeOneTraceabilityFulfilled', { traceability });
        } catch (e) {
            const error = e as Error;

            logger.send(error);
            store.dispatch('removeOneTraceabilityRejected', { error, traceability });
        }
    };

export const removeOneTraceabilityReducers: Pick<
    Reducers<TraceabilitiesStore>,
    'removeOneTraceabilityPending' | 'removeOneTraceabilityFulfilled' | 'removeOneTraceabilityRejected'
> = {
    removeOneTraceabilityPending: (state, { traceability }) =>
        produce(state, (draft) => {
            draft.removing.push(traceability.id);
            draft.loading.list = 'deleteing';
        }),
    removeOneTraceabilityFulfilled: (state, { traceability }) =>
        produce(state, (draft) => {
            draft.removing = draft.removing.filter((id) => id !== traceability.id);
            draft.data.allIds = draft.data.allIds.filter((id) => parseInt(id) !== traceability.id);
            draft.removed.push(traceability.id);
            draft.total -= 1;
            draft.loading.list = 'succeeded';
        }),
    removeOneTraceabilityRejected: (state, { error, traceability }) =>
        produce(state, (draft) => {
            draft.removing = draft.removing.filter((id) => id !== traceability.id);
            draft.loading.list = 'failed';
            draft.error = error;
        })
};
