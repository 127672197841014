import { WASTE_MANAGERS } from 'modules/shared/domain/constants/WasteManagersConstants';
import { removeDuplicates } from 'utils/helpers/general.helpers';

import { createWasteManagerTransferOperator } from './createTransferOperator';
import { createTreatmentContractMapper } from './createTreatmentContractMapper';

// more complex scenario because it can have N different transfer operators
export const createAnotherWasteManagerContract = (ppgcls, promotion, wasteManager) => {
    const anotherWasteManagerPpgcls = ppgcls.filter(isCoCircularTransferOperator);

    let wasteManagers = anotherWasteManagerPpgcls
        .map((ppgcl) => ppgcl.transferOperatorProGesCodLer?.procesoGestora?.gestora || null)
        .filter((wasteManager) => wasteManager !== null);

    wasteManagers = removeDuplicates(wasteManagers);

    const anotherWasteManagerContracts = [];

    wasteManagers.forEach((wasteManagerAux) => {
        const ppgclsAux = _getPpgclsByWasteManager(anotherWasteManagerPpgcls, wasteManagerAux.id);
        const transferOperator = createWasteManagerTransferOperator({ wasteManager: wasteManagerAux });

        const newContract = createTreatmentContractMapper({
            wasteManager,
            promotion,
            transferOperator,
            ppgcls: ppgclsAux
        });

        anotherWasteManagerContracts.push(newContract);
    });

    return anotherWasteManagerContracts;
};

/** @deprecated - https://app.clickup.com/t/865cbpmgd?block=block-861921be-3c8c-44ef-ae42-b1e5e0962ab3 */
// const anotherWasteManagerAsTransferOperator = (ppgcl) =>
//     ppgcl.transferOperatorType.id === 4 && ppgcl.transferOperatorProGesCodLer !== null;

const isCoCircularTransferOperator = (ppgcl) => {
    return ppgcl?.transferOperatorProGesCodLer?.gestora?.nIMA === WASTE_MANAGERS.COCIRCULAR.nima;
};

const _getPpgclsByWasteManager = (ppgcls, wasteManagerId) => {
    return ppgcls.filter((ppgcl) => ppgcl.transferOperatorProGesCodLer.procesoGestora.gestora.id === wasteManagerId);
};
