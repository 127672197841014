import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

import { TraceabilitiesStore } from '../../domain/TraceabilitiesStore';

export const loadOneTraceability =
    (promotionId: number, id: string): Thunk<TraceabilitiesStore> =>
    async (store, { api, logger }) => {
        try {
            store.dispatch('loadOneTraceabilityPending', null);
            const traceability = await api.getTraceabilityDetail({ id, promotionId });

            store.dispatch('loadOneTraceabilityFulfilled', { traceability });
        } catch (e) {
            const error = e as Error;

            logger.send(error);
            store.dispatch('loadOneTraceabilityError', { error });
        }
    };

export const loadOneTraceabilityReducers: Pick<
    Reducers<TraceabilitiesStore>,
    'loadOneTraceabilityPending' | 'loadOneTraceabilityFulfilled' | 'loadOneTraceabilityError'
> = {
    loadOneTraceabilityPending: (state) =>
        produce(state, (draft) => {
            draft.loading.detail = 'pending';
        }),
    loadOneTraceabilityFulfilled: (state, { traceability }) =>
        produce(state, (draft) => {
            draft.data.byId[traceability.id] = traceability;
            draft.loading.detail = 'succeeded';
        }),
    loadOneTraceabilityError: (state, { error }) =>
        produce(state, (draft) => {
            draft.error = error;
            draft.loading.detail = 'failed';
        })
};
