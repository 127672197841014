import { toast } from 'react-toastify';

const _defaultPosition = toast.POSITION.BOTTOM_CENTER;

export const notify = ({ isSuccess, msg, autoClose = 2000, position = _defaultPosition, ...rest }) => {
    if (isSuccess) {
        toast.success(msg, {
            autoClose,
            position,
            ...rest
        });
    } else {
        toast.error(msg, {
            autoClose,
            position: position,
            ...rest
        });
    }
};
