import fetcher from '../../../lib/fetcher';

const _userMessage = {
    success: {
        text: 'success.delete',
        ns: 'documentation'
    },
    error: {
        text: 'document.delete',
        ns: 'errors'
    }
};

export const deleteDocument = async (id) => {
    const URL = `/api/tracing/documents/${id}`;
    try {
        const response = await fetcher.delete(URL, { message: _userMessage });
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
