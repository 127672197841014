import PromotionInfo from 'features/promotion/pages/PromotionDetail/sections/PromotionInfo/PromotionInfo';
import './PromotionInfoPrintable.scss';

const PromotionInfoPrintable = () => {
    return (
        <div className="PromotionInfoPrintable">
            <PromotionInfo />
        </div>
    );
};

export default PromotionInfoPrintable;
