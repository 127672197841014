import { IconChevronDown, IconChevronRight, IconChevronUp, IconEdit, IconUnlink, IconUser } from '@tabler/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import { useAuth } from 'modules/auth';
import IconButton from '../../../components/BasicComponents/Buttons/Small/IconButton';
import SmallButton from '../../../components/BasicComponents/Buttons/Small/SmallButton';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import RoundedIcon from '../../../components/BasicComponents/Icon/RoundedIcon';
import UsuarioPopover from '../../../components/BasicComponents/Popovers/UsuarioPopover';
import { withAuth } from '../../../context/AuthProvider';
import './Usuarios.scss';

const Usuarios = ({ users, openEditPopover, isCoCircular, width, breakpoint, removeUserFromPromotion }) => {
    const [{ user: account }] = useAuth();
    const [t] = useTranslation();
    const [showUserDetail, setShowUserDetail] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [closedDropdown, setClosedDropdown] = useState(false);

    const togglePopover = (user) => {
        setSelectedUser(user);
        setShowUserDetail(!showUserDetail);
    };

    const isMaintenance = account?.authorities?.includes('ROLE_MAINTENANCE');

    const sortByAccountEnterpriseName = ([a], [b]) => {
        if (account?.enterprise?.name === b) return 1;
        return a.localeCompare(b);
    };

    return (
        <SimpleCard
            title={t('users', { ns: 'newPromotion' })}
            allowScroll={true}
            tooltip={t('tooltip', { ns: 'users' })}
        >
            {width <= breakpoint && (
                <SmallButton
                    action={() => setClosedDropdown(!closedDropdown)}
                    titulo={closedDropdown ? 'Mostrar mis usuarios' : 'Ocultar mis usuarios'}
                    icon={
                        closedDropdown ? (
                            <IconChevronDown size={18} stroke={1.4} />
                        ) : (
                            <IconChevronUp size={18} stroke={1.4} />
                        )
                    }
                    iconPosition="right"
                    className="usuario__button"
                />
            )}
            {!closedDropdown && (
                <div className={width <= breakpoint && 'usuario__container'}>
                    {groupUsersByEnterprise(users)
                        .sort(sortByAccountEnterpriseName)
                        .map(([enterpriseName, users], i) => (
                            <>
                                <div className="usuario__enterprise-group-name">{enterpriseName}</div>
                                <div className="usuario__enterprise-group-list">
                                    {users.map((user, i) => {
                                        const canRemoveFromPromotion =
                                            isCoCircular ||
                                            (isMaintenance && user?.enterprise?.id === account?.enterprise?.id);

                                        return (
                                            <div key={i} className="usuario__row">
                                                <div>
                                                    <RoundedIcon icon={<IconUser size={24} stroke={1.1} />} />
                                                    <div className="usuario__data">
                                                        <span className="usuario__name">
                                                            {user?.firstName} {user?.lastName}
                                                        </span>
                                                        <span className="usuario__desc">
                                                            {user?.roleType?.descripcion}
                                                            {user.enterprise ? ` - ${user.enterprise.name}` : ''}
                                                        </span>
                                                    </div>
                                                </div>

                                                {canRemoveFromPromotion && (
                                                    <ActionConfirm
                                                        title={t(`removeUserFromPromotion.title`, { ns: 'users' })}
                                                        message={t(`removeUserFromPromotion.message`, { ns: 'users' })}
                                                        confirmText={t(`removeUserFromPromotion.confirmText`, {
                                                            ns: 'users'
                                                        })}
                                                        onConfirm={() => removeUserFromPromotion(user)}
                                                    >
                                                        <IconButton titulo="" icon={<IconUnlink />} />
                                                    </ActionConfirm>
                                                )}

                                                {isCoCircular && (
                                                    <IconButton
                                                        titulo=""
                                                        icon={<IconEdit />}
                                                        action={() => openEditPopover(user)}
                                                    />
                                                )}

                                                <SmallButton
                                                    titulo={'Ver'}
                                                    icon={<IconChevronRight size={18} stroke={1.4} />}
                                                    iconPosition="right"
                                                    action={() => togglePopover(user)}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ))}
                    {users?.length === 0 && <p>{t('noUsers', { ns: 'users' })}</p>}

                    {showUserDetail && (
                        <UsuarioPopover
                            openModal={showUserDetail}
                            setOpenModal={setShowUserDetail}
                            usuario={selectedUser}
                        />
                    )}
                </div>
            )}
        </SimpleCard>
    );
};

export default withAuth(Usuarios);

const groupUsersByEnterprise = (users = []) => {
    const usersByEnterpriseName = users.reduce((acc, user) => {
        const enterpriseName = user.enterprise?.name || 'Sin empresa';
        acc[enterpriseName] = [...(acc[enterpriseName] || []), user];
        return acc;
    }, {});

    return Object.entries(usersByEnterpriseName);
};
