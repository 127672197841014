import { AuthApi } from 'modules/auth/domain/AuthApi';
import { AuthStore } from 'modules/auth/domain/AuthStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

export const checkKeyValidity =
    (params: Parameters<AuthApi['checkKeyValidity']>[0]): Thunk<AuthStore, { valid: boolean }> =>
    async (store, { api }) => {
        try {
            const checkKeyValidityResult = await api.checkKeyValidity(params);
            return checkKeyValidityResult;
        } catch (error) {
            store.set((draft) => {
                draft.error = error as Error;
            });
            return { valid: false };
        }
    };
