import { JsonSchema } from 'modules/shared';
import './DynamicFormSection.scss';
import DynamicFormControl from '../DynamicFormControl';

interface DynamicFormSectionProps {
    name: string;
    node: JsonSchema;
    parent?: JsonSchema;
    path?: string;
}

const DynamicFormSection = (props: DynamicFormSectionProps) => {
    if (!props.node) return null;

    if (props.node.properties) {
        return (
            <div className="DynamicFormSection">
                <p className="DynamicFormSection__title">{props.node.title}</p>
                <div className="DynamicFormSection__children">
                    {Object.entries(props.node.properties).map(([key, value]) => {
                        const path = props.path ? `${props.path}.${key}` : key;
                        return <DynamicFormSection key={key} name={key} node={value} path={path} parent={props.node} />;
                    })}
                </div>
            </div>
        );
    }

    const isRequired = !!props.parent?.required?.includes(props.name);

    return <DynamicFormControl node={props.node} path={props.path || ''} isRequired={isRequired} />;
};

export default DynamicFormSection;
