import { toggleModal } from './ToastHandler';

export const methodsToast = ['post', 'put', 'patch', 'delete'];

const _successStatusFirstNumber = 2;
const _errorStatusFirstNumber = [4, 5];

const _getStatusFirstNumber = (response) => {
    const firstDigitStr = String(response.status)[0];
    const firstDigitNum = Number(firstDigitStr);
    return firstDigitNum;
};

const _isSuccessStatus = (number) => {
    if (number === _successStatusFirstNumber) {
        return true;
    } else if (_errorStatusFirstNumber.includes(number)) {
        return false;
    }
};

export const toastHandler = (response) => {
    const isSuccess = _isSuccessStatus(_getStatusFirstNumber(response));
    const { message } = response.config;
    if (isSuccess) {
        if (message !== undefined) {
            toggleModal(message.success, isSuccess);
        }
    } else {
        const serverErrorMsg = response?.data?.description;
        const frontErrorMgs = message?.error;

        // If server sends default error message but frontend specifies another message, show the frontend msg
        const text = serverErrorMsg === 'Unknown error' && frontErrorMgs ? frontErrorMgs : serverErrorMsg;
        if (text !== undefined) {
            toggleModal(text, isSuccess);
        }
    }
};
