import { ToastContainer } from 'react-toastify';
import './Toast.scss';
/* className: applied to the container
toastClassName: applied on the toast wrapper
bodyClassName: applied on the toast body
progressClassName: applied on the progress bar */

const Toast = () => {
    return (
        <ToastContainer
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            bodyClassName="toast__container"
            autoClose={10000}
        />
    );
};
export default Toast;
