import { AsyncPaginate } from 'react-select-async-paginate';
import { withAuth } from '../../../context/AuthProvider';
import { getDocumentsByUsage } from '../../../features/documentation/api/getDocumentsByUsage';
import { iconSelect } from './helpers';
import './DocumentType.scss';

const DocumentType = (props) => {
    const { docType, changeDocType, className } = props;

    const loadOptions = async () => {
        let options = [];

        let trackingDocs = await getDocumentsByType('tracking');
        let wasteManagerDocs = await getDocumentsByType('waste-management');

        trackingDocs = trackingDocs.map((trackingDocType) => ({
            ...trackingDocType,
            section: 'traceabilityDocuments'
        }));
        wasteManagerDocs = wasteManagerDocs.map((wasteManagerDocType) => ({
            ...wasteManagerDocType,
            section: 'wasteManagerDocuments'
        }));

        options = [...trackingDocs, ...wasteManagerDocs];
        options = options.filter((docType) => docType.visible);

        return {
            options
        };
    };

    const getDocumentsByType = async (usage) => {
        let docs = [];
        const filter = `?usage.equals=${usage}`;
        const docsRequest = await getDocumentsByUsage(props.promocion?.id, filter);

        if (docsRequest?.data?.code == 0) {
            docs = docsRequest?.data?.content;
        }
        return docs;
    };

    const changeSelectedDocType = (option) => {
        changeDocType(option);
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <AsyncPaginate
                loadOptions={loadOptions}
                onChange={changeSelectedDocType}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option}
                value={docType}
                styles={iconSelect()}
                placeholder=""
                additional={{
                    page: 0
                }}
                isClearable={false}
                className="documentType"
                isSearchable={false}
            />
        </div>
    );
};

export default withAuth(DocumentType);
