export const ENTERPRISE_ROLES = [
    {
        id: 0,
        label: 'Constructora',
        value: 'OWNER'
    },
    {
        id: 1,
        label: 'Promotora',
        value: 'PRODUCER'
    },
    {
        id: 2,
        label: 'Consultoría Green',
        value: 'GREEN_CONSULTING'
    },
    {
        id: 3,
        label: 'Ingeniería',
        value: 'ENGINEERING'
    },
    {
        id: 4,
        label: 'Consultoría proyectos',
        value: 'PROJECT_MANAGEMENT'
    }
];

export const getEnterpriseRole = (value) => {
    const role = ENTERPRISE_ROLES.find((role) => role.value === value);
    const label = role?.label || '';

    return label;
};
