import { useState, useEffect, useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchButton from '../../../components/BasicComponents/Buttons/SearchButton';
import InputNumber from '../../../components/BasicComponents/Inputs/InputNumber';
import InputText from '../../../components/BasicComponents/Inputs/InputText';
import { getPgclAsTransferOperator } from '../api/getPgclAsTransferOperator';
import {
    codeTypeTransferOperator,
    getFilterTransferOperator,
    getTransferOperatorByPgcl
} from '../helpers/waste_manager.helpers';
import { NewPpgclContext } from './NewPpgclStepper';
import WasteManagerResult from './WasteManagerResult';

export const SearcherTransferOperator = ({ completePpgcl }) => {
    const { newPpgcls, setNewPpgcls, setTransferOperatorChecked, transferOperatorChecked } =
        useContext(NewPpgclContext);
    const [search, setSearch] = useState({ name: '', nima: '', cif: '' });
    const [page, setPage] = useState(0);
    const [transferOperators, setTransferOperators] = useState([]);
    const handleInputChange = ({ target }) => {
        setSearch({
            ...search,
            [target.name]: target.value
        });
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setStatusTransferOperators(
            getFilterTransferOperator(search) + `&codLer.equals=${completePpgcl.ppgcl.codLER.codigoLER}`
        );
    };

    const getIdTransferOperator = async (wasteManager) => {
        const filter = `&gestoraId.equals=${wasteManager.id}&codLER.equals=${completePpgcl.ppgcl.codLER.codigoLER}`;
        const pgclTransferOperator = await getPgclAsTransferOperator(filter);
        return pgclTransferOperator.data?.content?.valorizationProcess[0];
    };

    const findIndex = () => {
        return newPpgcls.findIndex((newPpgcl) => newPpgcl.id === completePpgcl.id);
    };

    const addTransferOperator = (wasteManager) => {
        if (!transferOperatorChecked.some((transferOperator) => transferOperator.id === completePpgcl.id)) {
            setTransferOperatorChecked(
                transferOperatorChecked.concat({
                    id: completePpgcl.id,
                    transferOperatorWasteManagerId: wasteManager.id
                })
            );
        } else {
            const copy = transferOperatorChecked.filter((transfer) => transfer.id !== completePpgcl.id);
            setTransferOperatorChecked(
                copy.concat({ id: completePpgcl.id, transferOperatorWasteManagerId: wasteManager.id })
            );
        }
    };

    const deleteTransferOperator = () => {
        setTransferOperatorChecked(
            transferOperatorChecked.filter((transferOperatorChecked) => transferOperatorChecked.id !== completePpgcl.id)
        );
    };
    const checkboxHandleClick = async (wasteManager) => {
        const index = findIndex();
        if (wasteManager.id === newPpgcls[index].transferOperator?.procesoGestora?.gestora?.id) {
            delete newPpgcls[index].transferOperatorProGesCodLerId;
            delete newPpgcls[index].transferOperator;
            setNewPpgcls(newPpgcls);
            deleteTransferOperator();
        } else {
            let transferOperator = await getIdTransferOperator(wasteManager);
            newPpgcls[index] = {
                ...newPpgcls[index],
                transferOperatorProGesCodLerId: transferOperator.id,
                transferOperator: transferOperator,
                transferOperatorTypeId: codeTypeTransferOperator['ANOTHER_MANAGER']
            };
            setNewPpgcls(newPpgcls);
            addTransferOperator(wasteManager);
        }
    };

    const setStatusTransferOperators = async (filter, newSearch = true) => {
        const getTransferOperators = await getTransferOperatorByPgcl(filter);
        newSearch
            ? setTransferOperators(getTransferOperators)
            : setTransferOperators(transferOperators.concat(getTransferOperators));
    };

    useEffect(() => {
        setStatusTransferOperators(
            getFilterTransferOperator(search) + `&codLer.equals=${completePpgcl.ppgcl.codLER.codigoLER}`
        );
    }, []);

    const handleLoadMore = async () => {
        setPage((prevPage) => prevPage + 1);
        const filter = `&page=${page + 1}` + getFilterTransferOperator(search);
        await setStatusTransferOperators(filter, false);
    };

    return (
        <div>
            <form onSubmit={handleFormSubmit} className="wasteManagerStep__form">
                <InputText
                    label="Nombre"
                    name="name"
                    value={search?.name}
                    setValues={setSearch}
                    onChange={handleInputChange}
                />
                <InputNumber
                    label="NIMA"
                    name="nima"
                    value={search?.nima}
                    setValues={setSearch}
                    onChange={handleInputChange}
                />
                <InputText
                    label="CIF"
                    name="cif"
                    value={search?.cif}
                    setValues={setSearch}
                    onChange={handleInputChange}
                />
                <SearchButton />
            </form>
            <InfiniteScroll
                dataLength={transferOperators.length}
                next={() => handleLoadMore()}
                hasMore={true}
                height={450}
            >
                <ul>
                    {transferOperators.map((transferOperator) => (
                        <WasteManagerResult
                            key={transferOperator.id}
                            wasteManager={transferOperator}
                            checkboxHandleClick={checkboxHandleClick}
                            transferOperator={true}
                            wasteManagersChecked={transferOperatorChecked}
                            {...{ completePpgcl }}
                        />
                    ))}
                </ul>
            </InfiniteScroll>
        </div>
    );
};
