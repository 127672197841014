import { IconArrowLeft } from '@tabler/icons';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import SupportTicketFeedbackError from 'assets/imagenes/support/support-ticket-feedback-error.gif';
import SupportTicketFeedbackSuccess from 'assets/imagenes/support/support-ticket-feedback-success.gif';
import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import InputFormControl from 'components/FormControls/InputFormControl';
import SelectAutocompleteFormControl from 'components/FormControls/SelectAutocompleteFormControl';
import TextareaFormControl from 'components/FormControls/TextareaFormControl';
import { TicketTopic } from 'modules/support/domain/ticket/TicketTopic';
import { useSupportStore } from 'modules/support/infrastructure/react/SupportContext';
import './SupportForm.scss';
import { useSupportForm } from './hooks/useSupporForm';

export type SupportFormProps = {
    onGoBack?: () => void;
    onSendedSuccess?: () => void;
};

const SupportForm = (props: SupportFormProps) => {
    const [t] = useTranslation('support');

    const { form, sent, ticket, success, error, isSubmitting, submit } = useSupportForm(props);
    const [, , { api }] = useSupportStore();

    const topicOptionAdapter = (topic: TicketTopic) => ({ label: t(`topics.${topic}`), value: topic });

    const [loadOptions] = useAsyncOptions({
        request: api.findTopics,
        adapter: topicOptionAdapter,
        removePagination: true
    });

    if (sent && success && ticket) {
        return (
            <div className="SupportForm" data-testid="SupportForm">
                <img className="SupportForm__sentImg" src={SupportTicketFeedbackSuccess} />
                <div className="SupportForm__sentTitle" data-testid="SupportForm__sentTitle">
                    {t('form.feedback.success.title')}
                </div>
                <div className="SupportForm__sentMsg" data-testid="SupportForm__sentMsg">
                    <Trans
                        i18nKey="support:form.feedback.success.msg"
                        values={{ email: ticket.creator.email, title: ticket.title, id: ticket.id }}
                        components={{ b: <strong />, p: <p /> }}
                    />
                </div>
            </div>
        );
    }

    if (sent && error) {
        const email = 'admin@cocircular.es';
        return (
            <div className="SupportForm" data-testid="SupportForm">
                <img className="SupportForm__sentImg" src={SupportTicketFeedbackError} />
                <div className="SupportForm__sentTitle" data-testid="SupportForm__sentTitle">
                    {t('form.feedback.error.title')}
                </div>
                <div className="SupportForm__sentMsg" data-testid="SupportForm__sentMsg">
                    <Trans
                        i18nKey="support:form.feedback.error.msg"
                        values={{ email }}
                        components={{
                            b: <strong />,
                            p: <p />,
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            a: <a href={`mailto:${email}`} />
                        }}
                    />
                </div>
                <ErrorCard error={error as any} />
            </div>
        );
    }

    return (
        <FormProvider {...form}>
            <form className="SupportForm" data-testid="SupportForm" onSubmit={submit}>
                <SelectAutocompleteFormControl
                    name="topic"
                    control={form.control}
                    label={t('form.topic.label')}
                    placeholder={t('form.topic.placeholder')}
                    loadOptions={loadOptions}
                    valueAdapter={topicOptionAdapter}
                    onChangeAdapter={(v) => {
                        if (Array.isArray(v) || !v) return undefined;
                        return v.value;
                    }}
                />
                <InputFormControl
                    name="title"
                    control={form.control}
                    label={t('form.title.label')}
                    placeholder={t('form.title.placeholder')}
                />

                <TextareaFormControl
                    name="description"
                    control={form.control}
                    label={t('form.description.label')}
                    placeholder={t('form.description.placeholder')}
                    rows={5}
                />

                <label className="SupportForm__check">
                    <input type="checkbox" {...form.register('allowSendData')} />
                    {t('form.allowSendData.label')}
                </label>

                <footer className="SupportForm__footer">
                    <BasicButton
                        extraClasses="button__white"
                        type="button"
                        action={props.onGoBack}
                        text={t('form.back')}
                        ghost
                        icon={<IconArrowLeft />}
                    />
                    <BasicButton
                        isLoading={isSubmitting}
                        extraClasses="button__purple"
                        type="submit"
                        text={t('form.send')}
                        icon={<></>}
                    />
                </footer>
            </form>
        </FormProvider>
    );
};

export default SupportForm;
