import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import './TagManagerEnterpriseAlert.scss';
import { Trans, useTranslation } from 'react-i18next';
import { IconAlertCircle } from '@tabler/icons';

const TagManagerEnterpriseAlert = () => {
    const [t] = useTranslation('tags');

    return (
        <AlertCard title={t('manager.noUserEnterpriseAlert.title')} icon={<IconAlertCircle />}>
            <Trans
                ns="tags"
                i18nKey="manager.noUserEnterpriseAlert.explanation"
                components={[
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                        className="TagManager__link"
                        href={`mailto:admin@cocircular.es`}
                        target="_blank"
                        rel="noreferrer"
                    />
                ]}
            />
        </AlertCard>
    );
};

export default TagManagerEnterpriseAlert;
