import { WasteByValorizationEntry } from 'modules/waste-by-valorization';
import './WasteByValorizationTable.scss';
import { currencyFormatDE, roundNumber } from 'utils/helpers/general.helpers';

const WasteByValorizationTable = ({ data }: { data: WasteByValorizationEntry[] }) => {
    const formatNumber = (num, replaceZeroWithHyphen = true) => {
        if (num === 0 && replaceZeroWithHyphen) return '-';

        const formatedNum = currencyFormatDE(roundNumber(num));
        return formatedNum;
    };

    return (
        <div className="WasteByValorizationTable" data-testid="WasteByValorizationTable">
            <div className="WasteByValorizationTable__scroll-container">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Total</th>
                            <th className="WasteByValorizationTable--green">Reutil.</th>
                            <th className="WasteByValorizationTable--green">Finalista</th>
                            <th className="WasteByValorizationTable--green">Material</th>
                            <th className="WasteByValorizationTable--yellow">Energét.</th>
                            <th className="WasteByValorizationTable--yellow">Almacen.</th>
                            <th className="WasteByValorizationTable--red">Verted.</th>
                            <th className="WasteByValorizationTable--red">Sin valoriz.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((entry) => (
                            <tr key={entry.lerType.id}>
                                <th role="rowheader">
                                    <span
                                        className={
                                            entry.lerType.name === 'Mezclas' ? 'WasteByValorizationTable__mixed' : ''
                                        }
                                    >
                                        {entry.lerType.name}
                                    </span>
                                    <br />
                                    <span className="WasteByValorizationTable__smallText">
                                        {formatNumber(entry.valorizationPercentage, false)}% val.
                                    </span>
                                </th>
                                <td>
                                    <span>{formatNumber(entry.totalTons)}</span>
                                </td>
                                <td>
                                    <span>{formatNumber(entry.reuseTons)}</span>
                                </td>
                                <td>
                                    <span>{formatNumber(entry.finalMaterialTons)}</span>
                                </td>
                                <td>
                                    <span>{formatNumber(entry.materialTons)}</span>
                                </td>
                                <td>
                                    <span>{formatNumber(entry.energyTons)}</span>
                                </td>
                                <td>
                                    <span>{formatNumber(entry.storageTons)}</span>
                                </td>
                                <td>
                                    <span>{formatNumber(entry.dumpTons)}</span>
                                </td>
                                <td>
                                    <span>{formatNumber(entry.nonValorizedTons)}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default WasteByValorizationTable;
