import { useTranslation } from 'react-i18next';
import SimplePopover from '../Popovers/SimplePopover';
import './ModalEliminar.scss';

const ModalEliminar = ({
    title = '¿Eliminar objeto?',
    subtitle = '',
    deleteText,
    cancelText,
    openModal,
    setOpenModal,
    deleteFunction,
    modalDelay = 1500,
    id
}) => {
    const [t] = useTranslation();

    deleteText = deleteText || t('delete', { ns: 'common' });
    cancelText = cancelText || t('cancel', { ns: 'common' });

    const closeModal = (delayTime = 0) => {
        setTimeout(() => {
            setOpenModal(false);
        }, delayTime);
    };

    return (
        <SimplePopover openModal={openModal} setOpenModal={setOpenModal} title={title} className="simplePopover modal">
            {subtitle !== '' && <p>{subtitle}</p>}
            <div className="modal__buttonsContainer">
                <button
                    className="button button__refuse"
                    onClick={() => {
                        deleteFunction(id);
                        closeModal(modalDelay);
                    }}
                >
                    {deleteText}
                </button>
                <button className="button button__blue" onClick={closeModal}>
                    {cancelText}
                </button>
            </div>
        </SimplePopover>
    );
};
export default ModalEliminar;
