import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import authenticationServices from '../../../api/authentication/authentication.services';
import { withAuth } from '../../../context/AuthProvider';
import { TituloH3 } from '../../BasicComponents/Titulos/Titulos';
import SimpleCard from '../../BasicComponents/Cards/SimpleCard';
import InputPassword from '../../BasicComponents/Inputs/InputPassword';
import SubmitButton from '../../BasicComponents/Buttons/SubmitButton';
import ResultadoCarga from '../../BasicComponents/Messages/ResultadoCarga';
import Spinner from '../../BasicComponents/Spinner/Spinner';
import MessageNotComplete from '../../BasicComponents/Messages/MessageNotComplete';

const PasswordForm = () => {
    const [t] = useTranslation();
    const [passwords, setPasswords] = useState({ currentPassword: '', newPassword: '', newPassword2: '' });
    const [message, setMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [isSubmmitingPass, setIsSubmittingPass] = useState(false);
    const [loading, setLoading] = useState(false);

    const handlePasswordChange = ({ target }) => {
        setPasswords({
            ...passwords,
            [target.name]: target.value
        });
    };

    const handlePasswordSubmit = async (e) => {
        setIsSubmittingPass(true);
        setLoading(true);
        e.preventDefault();
        if (arePasswordsEquals()) {
            const { currentPassword, newPassword } = passwords;
            const editPassword = await authenticationServices.changePassword({ currentPassword, newPassword });
            if (editPassword !== undefined && editPassword.status === 400) {
                setErrorMessage(editPassword.data.title);
            } else if (editPassword !== undefined && editPassword.status === 200) {
                setMessage({ text: t('message.success', { ns: 'profile' }), success: true });
                setPasswords({});
            }
        }
        setLoading(false);
        setIsSubmittingPass(false);
    };

    const arePasswordsEquals = () => {
        if (isFormCompleted()) {
            setErrorMessage();
            let isValid;
            passwords?.newPassword === passwords?.newPassword2 && passwords?.newPassword?.length > 0
                ? (isValid = true)
                : (isValid = false);
            if (isValid && passwords?.newPassword2?.length >= 4) {
                isValid = true;
            } else {
                isValid = false;
                setErrorMessage(t('message.longPassword', { ns: 'profile' }));
            }
            return isValid;
        }
    };

    const isFormCompleted = () => {
        if (
            passwords?.newPassword?.length === 0 ||
            passwords.currentPassword?.length === 0 ||
            passwords.newPassword2?.length === 0
        ) {
            setErrorMessage(t('message.complete', { ns: 'profile' }));
            return false;
        } else {
            return true;
        }
    };
    return (
        <SimpleCard className="editProfile__card">
            <TituloH3 titulo={t('security', { ns: 'profile' })} />
            <form onSubmit={handlePasswordSubmit}>
                <InputPassword
                    label={t('password', { ns: 'profile' })}
                    onChange={handlePasswordChange}
                    name="currentPassword"
                    value={passwords?.currentPassword}
                    id="currentPassword"
                    required
                />
                <InputPassword
                    label={t('newPassword', { ns: 'profile' })}
                    onChange={handlePasswordChange}
                    name="newPassword"
                    id="newPassword"
                    value={passwords?.newPassword}
                    required
                />
                <InputPassword
                    label={t('confirmNewPassword', { ns: 'profile' })}
                    onChange={handlePasswordChange}
                    name="newPassword2"
                    id="newPassword2"
                    value={passwords?.newPassword2}
                    required
                />
                <SubmitButton text={t('changePassword', { ns: 'profile' })} buttonDisabled={isSubmmitingPass} />
            </form>
            {message?.text && (
                <ResultadoCarga text={message?.text} setMessage={setMessage} success={message?.success} />
            )}
            {loading && <Spinner />}
            {errorMessage && <MessageNotComplete message={errorMessage} />}
        </SimpleCard>
    );
};

export default withAuth(PasswordForm);
