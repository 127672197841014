import { Auth } from 'modules/auth/domain/Auth';
import { AuthStore } from 'modules/auth/domain/AuthStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Store = AuthStore;

export const login =
    (params: { username: string; password: string }): Thunk<Store> =>
    async (store, { api, tokenStorage, deepLinkStorage }) => {
        try {
            const { accessToken } = await api.authenticate(params);
            tokenStorage.save(accessToken);

            const auth = (await api.getAuth()) as Auth;

            const goToAfter = deepLinkStorage.getDeepLink();
            deepLinkStorage.removeDeepLink();

            store.set({ ...auth, pathRedirect: goToAfter || auth.pathRedirect });
        } catch (e) {
            const error = e as Error;
            store.set((draft) => {
                draft.isLoggedin = false;
                draft.error = error;
            });
        }
    };
