import { DateImpactScenario, ImpactApi, LerImpact } from '../ImpactApi';
import { Impact } from './Impact';

const POTENTIAL_UNSEPARATED_WASTES_TYPELER = 'Otros';

interface Sources {
    avoided: Awaited<ReturnType<ImpactApi['getAvoidedImpact']>>;
    generated: Awaited<ReturnType<ImpactApi['getGeneratedImpact']>>;
}

export function createImpact(sources: Sources): Impact {
    return {
        impactCo2: {
            // Generated
            co2GeneratedbyLerGraphData:
                sources.generated.co2ByLer
                    ?.filter((ler) => ler.real !== 0)
                    ?.sort(sortByRealScenario)
                    ?.map((ler) => ({
                        Real: ler.real,
                        typeLer: ler.typeLer
                    })) || [],
            co2GeneratedTemporalGraphData: createDatesGraph(sources.generated.co2ByDate || []),
            co2generated: {
                real: createKPIFromLers('real', sources.generated.co2ByLer),
                potential: createKPIFromLers('potential', sources.generated.co2ByLer),
                standard: createKPIFromLers('standard', sources.generated.co2ByLer)
            },

            // Avoided?
            co2ByLerGraphData:
                sources.avoided.co2ByLer
                    ?.filter(excludePotentialUnseparatedWastes)
                    ?.sort(sortByAllScenarioSummatory)
                    ?.map((ler) => {
                        return {
                            typeLer: ler.typeLer,
                            Estándar: ler.standard.toString(),
                            Real: ler.real.toString(),
                            Potential: (ler.potentialHandledLer + ler.potentialAssignedMix).toString(),
                            PotentialDetail: {
                                ler: ler.potentialHandledLer.toString(),
                                mix: ler.potentialAssignedMix.toString()
                            }
                        };
                    }) || [],
            co2TemporalGraphData: createDatesGraph(sources.avoided.co2ByDate || []),
            co2KPI: {
                potential: sources.avoided.co2Total?.potential.co2.toString() || '0',
                real: sources.avoided.co2Total?.real.co2.toString() || '0',
                standard: sources.avoided.co2Total?.standard.co2.toString() || '0',
                potentialUnseparatedWastes: createPotentialUnseparatedWastesKPI(sources.avoided.co2ByLer)
            }
        },
        impactLandUse: {
            landUseByLerGraphData:
                sources.avoided.landByLer
                    ?.filter(excludePotentialUnseparatedWastes)
                    ?.sort(sortByAllScenarioSummatory)
                    ?.map((ler) => ({
                        typeLer: ler.typeLer,
                        Estándar: ler.standard.toString(),
                        Real: ler.real.toString(),
                        Potential: (ler.potentialHandledLer + ler.potentialAssignedMix).toString(),
                        PotentialDetail: {
                            ler: ler.potentialHandledLer.toString(),
                            mix: ler.potentialAssignedMix.toString()
                        }
                    })) || [],
            landUseTemporalGraphData: createDatesGraph(sources.avoided.landByDate || []),
            landUseKPI: {
                potential: sources.avoided.landTotal?.potential.landTons.toString() || '0',
                real: sources.avoided.landTotal?.real.landTons.toString() || '0',
                standard: sources.avoided.landTotal?.standard.landTons.toString() || '0',
                potentialUnseparatedWastes: createPotentialUnseparatedWastesKPI(sources.avoided.landByLer)
            }
        },
        equivalencies: {
            trees: sources.avoided.co2Total?.real?.trees || 0,
            meters: sources.avoided.landTotal?.real?.landAreaM2 || 0,
            trips: {
                numTripsMAD: sources.avoided.co2Total?.real.trips || 0
            }
        }
    };
}

const sortByRealScenario = (a: LerImpact, b: LerImpact) => (a.real > b.real ? -1 : 1);

const sortByAllScenarioSummatory = (a: LerImpact, b: LerImpact) =>
    a.real + a.standard + a.potentialHandledLer > b.real + b.standard + b.potentialHandledLer ? -1 : 1;

const createDatesGraph = (scenarios: DateImpactScenario[]): DateImpactScenario[] => {
    return scenarios.map((scenario) => ({
        ...scenario,
        data: scenario.data.map((entry) => ({
            y: entry.y,
            // Formats date from "2020-01-01" to "Ene 20"
            x: new Date(entry.x).toLocaleString('es-ES', { year: '2-digit', month: 'short' })
        }))
    }));
};

const createKPIFromLers = (scenario: string, lers?: LerImpact[]) => {
    if (!lers) return 'Error';

    const result = lers
        .reduce((sum, ler) => {
            const value = ler[scenario];
            return sum + value;
        }, 0)
        .toString();

    return result;
};

const excludePotentialUnseparatedWastes = (ler: LerImpact) => {
    return ler.typeLer !== POTENTIAL_UNSEPARATED_WASTES_TYPELER;
};

const createPotentialUnseparatedWastesKPI = (lers?: LerImpact[]) => {
    const others = lers?.find((ler) => ler.typeLer === POTENTIAL_UNSEPARATED_WASTES_TYPELER);

    return others?.potentialHandledLer?.toString() || '0';
};
