import { useTranslation } from 'react-i18next';
import { TituloH1 } from '../../components/BasicComponents/Titulos/Titulos';
import CardPpgclSettings from '../../components/ComplexComponents/WasteManagerDashboard/CardPpgclSettings';
import { withAuth } from '../../context/AuthProvider';
import Layout from '../Layout/Layout';
import '../../components/ComplexComponents/WasteManagerDashboard/SettingsPpgcl.scss';

const WasteManagerDashboard = (props) => {
    const [t] = useTranslation();
    return (
        <Layout>
            <TituloH1 titulo={t('dashboardTitle', { ns: 'newWasteManager' })} />
            <CardPpgclSettings />
        </Layout>
    );
};

export default withAuth(WasteManagerDashboard);
