import { IconRotateClockwise } from '@tabler/icons';
import { Trans, useTranslation } from 'react-i18next';

import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import SuggestionCard from 'components/BasicComponents/Cards/SuggestionCard';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import AutomaticReadingStatus from 'features/automatic-reading/components/AutomaticReadingStatus';
import { sumCountersByKey } from 'features/automatic-reading/utils/sumCountersByKey';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useDiExtraction } from 'modules/di-extraction';
import { retryableStatusList } from 'modules/di-extraction/domain/statusLists/retryableStatusList';

interface AutomaticReadingMassiveRetryProps {}

const AutomaticReadingMassiveRetry = (props: AutomaticReadingMassiveRetryProps) => {
    const [t] = useTranslation('automaticReading');
    const [, analytics] = useAnalyticsStore();

    const [{ criteria, counters }, diExtractionsActions] = useDiExtraction();
    const { retryManyDiExtractions, reloadDiExtractions } = diExtractionsActions;

    // Intersection between retryableStatusList and the selected status (filters)
    // -> for example, retry ONLY 'to be completed' DIs if user applied that filter
    // -> for example, you cannot aprove DIs if at filters there is selected 'to be completed' only
    const thereAreStatusFilter = criteria.state && criteria.state.length > 0;
    const visibleRetryableStatus = thereAreStatusFilter
        ? retryableStatusList.filter((status) => criteria.state?.flat()?.includes(status))
        : retryableStatusList;

    const retryablesCount = sumCountersByKey(counters, visibleRetryableStatus);

    if (counters.totalApplicable === 0) return null;

    const text = t('actions.retryAll.name', { count: retryablesCount });

    const disableText: string | null =
        (!!counters.PENDING && t('actions.disabledMsg.pending')) ||
        (retryablesCount === 0 && t('actions.disabledMsg.noRetryableDis')) ||
        null;

    const triggerRetry = async () => {
        const payload = { ...criteria, page: undefined, size: undefined };

        await retryManyDiExtractions({ ...payload, state: visibleRetryableStatus });
        analytics.event(AnalyticsEvents.AUTOMATIC_READING_MASSIVE_RETRIED);

        await reloadDiExtractions();
    };

    return (
        <Tooltip msg={disableText} delayShow={0}>
            <ActionConfirm
                title={text}
                onConfirm={triggerRetry}
                confirmStyle="primary"
                confirmText={text}
                message={
                    <div className="AutomaticReadingHeader__actions-dialog">
                        <SuggestionCard className="AutomaticReadingHeader__actions-tip" title="">
                            <div>{t('actions.tip')}</div>
                        </SuggestionCard>
                        <Trans
                            i18nKey="actions.retryAll.confirmation"
                            ns="automaticReading"
                            components={{
                                pending: <AutomaticReadingStatus state="PENDING" />,
                                b: <strong />
                            }}
                            values={{ count: retryablesCount }}
                        />
                    </div>
                }
            >
                <SmallButton
                    type="button"
                    titulo={text}
                    icon={<IconRotateClockwise />}
                    iconPosition="left"
                    disabled={retryablesCount === 0 || counters.PENDING}
                    testid="AutomaticReadingHeader__actions-massive-retry"
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </ActionConfirm>
        </Tooltip>
    );
};

export default AutomaticReadingMassiveRetry;
