import { useEffect } from 'react';

import { useDiExtraction } from 'modules/di-extraction';
import { usePromotion } from 'modules/promotion';

export function useLoadExtractionCounters() {
    const [{ promotion }] = usePromotion();
    const [{ counters }, { fetchDiExtractionsCounter }] = useDiExtraction();

    const promotionId = promotion?.id;
    const shouldPolling = (counters.PENDING || 0) > 0;

    // Load counters when promotion changes
    useEffect(() => {
        if (!promotionId) return;

        fetchDiExtractionsCounter({ promotionId });
    }, [promotionId]);

    // Reload counters when criteria changes
    useEffect(() => {
        if (!promotionId) return;
        if (!shouldPolling) return;

        const polling = setInterval(() => {
            if (document.hidden) return;
            fetchDiExtractionsCounter({ promotionId });
        }, 10_000);

        if (!shouldPolling) clearInterval(polling);
        return () => clearInterval(polling);
    }, [promotionId, shouldPolling]);
}
