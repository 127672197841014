import fetcher from '../../../lib/fetcher';

export const getTypeDocuments = async (promotionId, filter) => {
    // 'promocionId' is required
    const URL = `api/documents/promotion/${promotionId}/type/list${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
