import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import SmallText from 'components/BasicComponents/Text/SmallText';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { Alert, useAlertsCause } from 'modules/alerts';
import { Link } from 'react-router-dom';
import './AlertDetailPopover.scss';
import { ALERTS_LINK, ALERT_STYLE } from './constants/alerts.constants';

interface AlertDetailPopoverProps {
    promotionId: number;
    alert: Alert;
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
}

const AlertDetailPopover = ({ promotionId, alert, openModal, setOpenModal }: AlertDetailPopoverProps) => {
    const { data: causes } = useAlertsCause({ promotionId, alertCode: alert.code });
    const { format } = useDateFormatter();

    const linkInfo = ALERTS_LINK({ promotionId }).find((alertExtraInfo) => alertExtraInfo.code === alert.code);

    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={alert.title}
            className="AlertDetailPopover"
        >
            <div className="AlertDetailPopover__detail">
                <p className={`AlertDetailPopover__level AlertDetailPopover__level--${ALERT_STYLE[alert.level].color}`}>
                    {ALERT_STYLE[alert.level].icon} {ALERT_STYLE[alert.level].levelDescription}
                </p>
                <SmallText text={format(alert.date, 'DD MMM YYYY')} className="" />
            </div>
            <div className="AlertDetailPopover__info">
                <p>{alert.help}</p>
                {causes?.lerTypes && (
                    <ul>
                        {causes?.lerTypes.map((lerType) => (
                            <li key={lerType}>{lerType}</li>
                        ))}
                    </ul>
                )}
            </div>
            {linkInfo && (
                <Link
                    to={{ pathname: linkInfo.link.to, state: linkInfo.link.state }}
                    className="AlertDetailPopover__link"
                >
                    Ver incidencia
                </Link>
            )}
        </SimplePopover>
    );
};

export default AlertDetailPopover;
