import { PromotionUpdater } from 'modules/promotion/domain/promotion/PromotionUpdater';
import { PromotionDataFormSchema } from '../schemas/PromotionDataFormSchema';

export function promotionUpdaterAdapter(form: PromotionDataFormSchema): PromotionUpdater {
    const {
        id,
        name,
        nima,

        address,
        postalCode,
        town,

        type,
        numHouses,
        power,

        pem,
        surface,

        startDate,
        endDate,
        ...rest
    } = form;

    // Just for ensure that all fields are used
    const nonDestructuredKeys = Object.keys(rest).length;
    if (nonDestructuredKeys > 0)
        throw new Error(`promotionUpdaterAdapter: Not used fields for this form ${Object.keys(rest).join(', ')}`);

    const updater: PromotionUpdater = {
        id: id,
        nombre: name,
        nima: nima,

        direccion: {
            direccion: address,
            postalCode: postalCode,
            municipio: {
                id: town.id,
                nombre: town.name
            }
        },

        promotionType: {
            id: type.id,
            nombre: type.name,
            additionalProperties: {
                numHouses: numHouses,
                power: power
            }
        },

        pem: pem,
        surface: surface,

        // to YYYY-MM-DD
        fechaFin: endDate.toISOString().split('T')[0],
        // to YYYY-MM-DD
        fechaInicio: startDate.toISOString().split('T')[0]
    };

    return updater;
}
