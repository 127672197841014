import * as RadixTooltip from '@radix-ui/react-tooltip';
import { PropsWithChildren } from 'react';

import TooltipContent, { TooltipContentLabel } from './TooltipContent';
import './Tooltips.scss';

interface TooltipProps {
    msg?: React.ReactNode | null;
    delayShow?: number;
    className?: string;
    tag?: string;
    offset?: {
        top?: number;
        bottom?: number;
        left?: number;
        right?: number;
    };
    hideArrow?: boolean;
}

const Tooltip = ({
    msg,
    tag,
    className,
    children,
    offset,
    delayShow = 500,
    hideArrow
}: PropsWithChildren<TooltipProps>) => {
    if (!msg) return <>{children}</>;

    const Tag = (tag || `span`) as keyof JSX.IntrinsicElements;

    return (
        <>
            <RadixTooltip.Provider delayDuration={delayShow}>
                <RadixTooltip.Root>
                    <RadixTooltip.Trigger asChild>
                        <Tag className={`Tooltip ${className || ''}`}>{children}</Tag>
                    </RadixTooltip.Trigger>
                    <RadixTooltip.Portal>
                        <RadixTooltip.Content
                            alignOffset={offset?.top || 0}
                            sideOffset={offset?.left || 0}
                            className="Tooltip__content"
                        >
                            <TooltipContent>
                                {typeof msg === 'string' ? <TooltipContentLabel>{msg}</TooltipContentLabel> : msg}
                            </TooltipContent>
                            {!hideArrow && <RadixTooltip.Arrow className="Tooltip__arrow" />}
                        </RadixTooltip.Content>
                    </RadixTooltip.Portal>
                </RadixTooltip.Root>
            </RadixTooltip.Provider>
        </>
    );
};

export default Tooltip;
