import MultiProvider from 'lib/MultiProvider';
import { logger } from 'logger';
import { TraceabilitiesProvider, httpTraceabilitiesApi } from 'modules/traceabilities';
import { WasteManagementProvider } from 'modules/waste-management';
import { httpWasteManagementApi } from 'modules/waste-management/infrastructure/api/httpWasteManagementApi';
import WasteManagementPlanner, { WasteManagementPlannerProps } from './WasteManagementPlanner';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: WasteManagementPlannerProps) => (
    <MultiProvider
        providers={[
            <WasteManagementProvider api={httpWasteManagementApi} />,
            // Just for access to api.getLers ATM
            <TraceabilitiesProvider api={httpTraceabilitiesApi} logger={logger} />
        ]}
    >
        <WasteManagementPlanner {...props} />
    </MultiProvider>
);
