import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePromotionBilleables } from 'modules/billeables';
import { useRelatedEnterprisesData } from 'modules/enterprise/related-enterprise/infraestructure/store/relatedEnterpriseStore';
import RelatedEnterprisesStep from 'components/ComplexComponents/NewPromotion/RelatedEnterprises/RelatedEnterprisesStep';
import ProductsAndServices from 'components/ComplexComponents/NewPromotion/ProductsAndServices';
import { withAuth } from '../../context/AuthProvider';
import PromotionInformation from '../../components/ComplexComponents/NewPromotion/Step2/PromotionInformation';
import Users from '../../components/ComplexComponents/NewPromotion/Step3/Users';
import './NewPromotion.scss';

const StepperContent = (props) => {
    const [t] = useTranslation();
    const params = useParams();
    const isEditMode = params.promotionId !== undefined;

    // Uses "promotion" instead of "promocion" because needs parent "NewPromotion" state
    const promotionId = props?.promotion?.id;

    const [{ relatedEnterprises, loading: loadingEnterprises }, { fetchRelatedEnterprises }] =
        useRelatedEnterprisesData();
    const [, { fetchBilleables, saveBilleables }] = usePromotionBilleables();

    const createPromotionForm = async (e) => {
        e.preventDefault();
        const newPromotion = await props.createPromotion(relatedEnterprises);

        if (newPromotion) {
            saveBilleables({ promotionId: promotionId || newPromotion.id });
        }
    };

    useEffect(() => {
        if (isEditMode && !promotionId) return;
        // Refetches billeables if "isDemo" changes
        fetchBilleables({ promotionId });
    }, [promotionId, isEditMode, props.promotion.isDemo]);

    useEffect(() => {
        // Fetch only one time and only if edit mode
        if (promotionId === undefined || loadingEnterprises !== 'idle') return;

        fetchRelatedEnterprises({ promotionId });
    }, [promotionId, loadingEnterprises]);

    return (
        <div className="newPromotion">
            {(() => {
                switch (props.currentStep) {
                    case 0:
                        return <PromotionInformation {...{ ...props, createPromotionForm }} />;
                    case 1:
                        return <RelatedEnterprisesStep {...{ ...props, createPromotionForm }} />;
                    case 2:
                        return <ProductsAndServices {...{ ...props, createPromotionForm }} />;
                    case 3:
                        return <Users {...{ ...props, createPromotionForm }} />;
                    default:
                        return <div>{t('error', { ns: 'common' })}</div>;
                }
            })()}
        </div>
    );
};
export default withAuth(StepperContent);
