import './RoundedCard.scss';

const RoundedCard = ({ text, unit }) => {
    return (
        <div className="card__rounded">
            <span>{text}</span>
            {unit}
        </div>
    );
};
export default RoundedCard;
