import { useState, useEffect } from 'react';
// TODO: Creo que se pueden usar ambas desde react-router-dom
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import activitiesServices from '../../api/activities/activities.services';
import { withAuth } from '../../context/AuthProvider';
import Layout from '../Layout/Layout';
import Buscador from '../../components/BasicComponents/Buscador/Buscador';
import SearchButton from '../../components/BasicComponents/Buttons/SearchButton';
import InputFecha from '../../components/BasicComponents/Inputs/InputFecha';
import ActivityStatusSelectAutocomplete from '../../components/BasicComponents/Select-Autocomplete/ActivityStatusSelectAutocomplete';
import PromoProGesCodLerAutocomplete from '../../components/BasicComponents/Select-Autocomplete/PromoProGesCodLerAutocomplete';
import Spinner from '../../components/BasicComponents/Spinner/Spinner';
import { TituloH1, TituloPromocion } from '../../components/BasicComponents/Titulos/Titulos';
import ActivityTypeSelectAutocomplete from '../../components/BasicComponents/Select-Autocomplete/ActivityTypeSelectAutocomplete';
import ResultadoCarga from '../../components/BasicComponents/Messages/ResultadoCarga';
import SortMenu from '../../components/BasicComponents/Sort/SortMenu';
import GoBack from '../../components/BasicComponents/Buttons/GoBack';
import SubmitButton from '../../components/BasicComponents/Buttons/SubmitButton';
import SmallButton from '../../components/BasicComponents/Buttons/Small/SmallButton';
import TransportistaSelectAutocomplete from '../../components/BasicComponents/Select-Autocomplete/TransportistaSelectAutocomplete';
import Activity from '../../components/ComplexComponents/Activities/Activity';
import { createFilterWithObject, INCIDENCE_ID, getObjFilter } from '../../utils/constants/activities.constants';
import { INITIAL_PAGE } from '../../utils/constants/pagination.constants';
import HasPermission from '../../components/AccessCheckers/HasPermission';
import CreateButton from '../../components/BasicComponents/Buttons/CreateButton';

import './Activities.scss';

const ActivityType = (props) => {
    const [t] = useTranslation();
    const { state } = useLocation();
    const [{ promotion }] = usePromotion();

    const history = useHistory();

    const initialStateValues = {
        fechaDesde: '',
        fechaHasta: '',
        estadoActividad: '',
        gestora: '',
        transportista: ''
    };

    const [values, setValues] = useState(initialStateValues);
    const [typeId, setTypeId] = useState();
    const [descriptionType, setDescriptionType] = useState('');
    const [activities, setActivities] = useState([]);
    const [numberOfActivities, setNumberOfActivities] = useState();
    const [pagination, setPagination] = useState(INITIAL_PAGE);
    const [loading, setLoading] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [openMenu, setOpenMenu] = useState(false);

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setPagination(INITIAL_PAGE);
        searchTypeActivity(INITIAL_PAGE, values, true);
        setOpenMenu(false);
        setSelectedOption(values?.sortOption);
        getCounter(values);
        setLoading(false);
    };
    const typeActivity = async () => {
        const filter = `?id.equals=${typeId}`;
        const searchTypeActivity = await activitiesServices.getTypesActivities(filter);
        setDescriptionType(searchTypeActivity[0].descripcionCorta);
    };

    const handleLoadMore = () => {
        setPagination(pagination + 1);
        searchTypeActivity(pagination + 1, values, false);
    };

    const getCounter = async (filteredValues) => {
        const filter = getFilter(0, filteredValues, true);
        const getNumberOfActivities = await activitiesServices.getActivities(filter);
        setNumberOfActivities(getNumberOfActivities);
    };

    const getFilter = (page, filterValues, counter = false) => {
        let filter;
        filter = values.sortOption
            ? `?page=${page}&promocionId.equals=${props.promocion.id}&tipoActividadId.equals=${typeId}`
            : `?page=${page}&promocionId.equals=${props.promocion.id}&sort=fechaActividad,desc&sort=id,desc&tipoActividadId.equals=${typeId}`;
        if (counter) {
            filter = `/count?promocionId.equals=${props.promocion?.id}&tipoActividadId.equals=${typeId}`;
        }
        let valuesFilter;
        if (filterValues?.transportista) {
            valuesFilter = JSON.parse(JSON.stringify(filterValues));
            valuesFilter = { ...valuesFilter, transportista: '', gestora: valuesFilter.transportista };
        } else {
            valuesFilter = filterValues;
        }
        //esto se hace para cuando viene el link de alarmas
        if (state?.alarm) {
            filter += '&sort=fechaActividad,desc&size=1';
        }
        const objFilter = getObjFilter(valuesFilter);
        const addFilter = createFilterWithObject(valuesFilter, objFilter);
        return (filter += addFilter);
    };

    const searchTypeActivity = async (page, filterValues, newSearch = true) => {
        setLoading(true);
        const filter = getFilter(page, filterValues);
        const getActivities = await activitiesServices.getActivities(filter);
        if (newSearch) {
            setActivities(getActivities);
        } else {
            setActivities(activities.concat(getActivities));
        }
        setLoading(false);
    };

    useEffect(() => {
        if (props.promocion?.id && typeId !== undefined) {
            typeActivity();
            setPagination(0);
            if (state.values) {
                setValues(state.values);
                searchTypeActivity(INITIAL_PAGE, state.values, true);
                getCounter(state.values);
            } else {
                searchTypeActivity(INITIAL_PAGE, values, true);
                getCounter(values);
            }
        }
    }, [typeId, props.promocion?.id]);

    useEffect(() => {
        setTypeId(state.typeId);
    }, [props.promocion?.id]);

    const deleteActivity = async (id) => {
        setLoading(true);
        const deleteActivity = await activitiesServices.deleteActivity(id);
        if (deleteActivity?.status === 204) {
            const newResults = activities.filter((res) => {
                return res.id !== id;
            });
            setActivities(newResults);
            setNumberOfActivities(numberOfActivities - 1);
            setMessageSuccess({ success: true, text: t('success.delete', { ns: 'activities' }) });
        } else {
            setMessageSuccess({ success: false, text: t('activity.delete', { ns: 'errors' }) });
        }
        setLoading(false);
    };

    const goBack = () => {
        history.goBack();
    };

    const updateActivity = (updatedActivity, index) => {
        let activitiesAux = [...activities];
        activitiesAux[index] = updatedActivity;
        setActivities(activitiesAux);
    };

    const deleteFilter = async () => {
        setSelectedOption();
        setValues({ ...values, sortOption: '' });
        setLoading(true);
        setPagination(INITIAL_PAGE);
        const filter = getFilter(INITIAL_PAGE, { ...values, sortOption: '' });
        const getActivities = await activitiesServices.getActivities(filter);
        setActivities(getActivities);
        setLoading(false);
    };

    const onChangeRadio = (option) => {
        setValues({ ...values, sortOption: option });
    };

    const isChecked = (option) => {
        if (values?.sortOption?.label === option.label) {
            return true;
        } else {
            return false;
        }
    };

    // esto se hace para recuperar todas las observaciones para q Jeza pueda pegarlas en un email
    const getAllObservationsToCopy = async () => {
        const numberOfPages = Math.floor(numberOfActivities) / 20;
        const allActivities = await loopActivities(numberOfPages);
        let observations = allActivities.map((activity) => activity.observaciones);
        observations.forEach(function (item, i) {
            observations[i] = item.concat(' \n \n');
        });
        const joinObservations = observations.join('');
        let cleanText = joinObservations.replace(/<\/?[^>]+(>|$)/g, '');
        cleanText = cleanText.replace(/&nbsp;/g, ' ');
        return cleanText;
    };

    const onClickCopy = async () => {
        const text = await getAllObservationsToCopy();
        await navigator.clipboard.writeText(text);
    };

    const loopActivities = async (numberOfPages) => {
        let activitiesArray = [];
        for (let i = 0; i <= numberOfPages; i++) {
            let filter = getFilter(i, values);
            const activities = await activitiesServices.getActivities(filter);
            activitiesArray = activitiesArray.concat(activities);
        }
        return activitiesArray;
    };

    const { fechaDesde, fechaHasta } = values;
    const { isCoCircular } = props;

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <div className="create-button__container">
                <TituloH1 titulo={t('title', { ns: 'activities' })} />
                <HasPermission ifRole="canCreate" section="activities">
                    <CreateButton
                        text={t('new.newActivity', { ns: 'activities' })}
                        to={`/promotion/${promotion.id}/activities/create`}
                        state={{ values, typeId }}
                    />
                </HasPermission>
            </div>
            <Buscador titulo={t('find', { ns: 'activities' })}>
                <form className="documentation__form" onSubmit={handleFormSubmit}>
                    {isCoCircular && (
                        <>
                            <PromoProGesCodLerAutocomplete
                                {...{ values, setValues }}
                                value={values.gestora}
                                newActivity
                            />
                            <TransportistaSelectAutocomplete {...{ values, setValues }} newActivity />
                        </>
                    )}
                    <InputFecha
                        label={t('date.dateFrom', { ns: 'common' })}
                        id="fechaDesde-trazabilidad"
                        name="fechaDesde"
                        value={fechaDesde}
                        onChange={handleInputChange}
                        aspaLimpiar
                        values={values}
                        setValues={setValues}
                    />
                    <InputFecha
                        label={t('date.dateTo', { ns: 'common' })}
                        id="fechaHasta-trazabilidad"
                        name="fechaHasta"
                        value={fechaHasta}
                        onChange={handleInputChange}
                        aspaLimpiar
                        values={values}
                        setValues={setValues}
                    />
                    <ActivityStatusSelectAutocomplete setValues={setValues} values={values} />
                    <SearchButton text={t('search.search', { ns: 'common' })} />
                </form>
            </Buscador>

            {typeId && (
                <>
                    <TituloPromocion titulo={t('search.results', { ns: 'common' })} />
                    <SortMenu
                        openMenu={openMenu}
                        setOpenMenu={setOpenMenu}
                        selectedOption={selectedOption}
                        deleteFilter={deleteFilter}
                    >
                        <form onSubmit={handleFormSubmit} className="sort__form">
                            {getObjFilter()
                                .filter((oneFilter) => oneFilter.typeOf === 'SORT')
                                .map((input) => (
                                    <div key={input.label} className="sort__inputContainer">
                                        <input
                                            className="sort__input"
                                            type="radio"
                                            value={input.label}
                                            name={input.name}
                                            onChange={() => onChangeRadio(input)}
                                            checked={isChecked(input)}
                                            id={input.label}
                                        />
                                        <label className="sort__label" htmlFor={input.label}>
                                            {t(input.label, { ns: 'sort' })}
                                        </label>
                                    </div>
                                ))}
                            <SubmitButton text={t('results', { ns: 'common' })} />
                        </form>
                    </SortMenu>
                    <div className="activities__containerButtons">
                        <div className="documentation__select">
                            <HasPermission ifRole="canView" section="activities">
                                <ActivityTypeSelectAutocomplete setValues={setTypeId} values={typeId} />
                                <h4>
                                    {descriptionType} ({numberOfActivities})
                                </h4>
                            </HasPermission>
                            {typeId === INCIDENCE_ID && (
                                <SmallButton
                                    type="button"
                                    titulo={'Copiar observaciones'}
                                    action={() => onClickCopy()}
                                />
                            )}
                        </div>
                    </div>

                    {activities.length > 0 && (
                        <InfiniteScroll
                            dataLength={activities.length}
                            next={() => handleLoadMore()}
                            hasMore={true}
                            scrollableTarget="main-layout"
                        >
                            {activities.map((activity, i) => (
                                <Activity
                                    key={activity.id}
                                    index={i}
                                    {...{ activity, values, descriptionType, updateActivity, deleteActivity }}
                                />
                            ))}
                        </InfiniteScroll>
                    )}
                    {activities.length === 0 && (
                        <p className="documentation__message">{t('noResults', { ns: 'common' })}</p>
                    )}
                </>
            )}
            {messageSuccess?.text && (
                <ResultadoCarga
                    text={messageSuccess?.text}
                    success={messageSuccess.success}
                    setMessage={setMessageSuccess}
                />
            )}
            {loading && <Spinner />}
        </Layout>
    );
};
export default withAuth(ActivityType);
