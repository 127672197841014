import { Reducers, createStore } from 'modules/shared/domain/store/createStore';
import { DiExtractionStore } from '../domain/DiExtractionStore';
import { createDiExtractions, createReducers } from './create/createDiExtractions';
import { fetchDiExtractions, fetchReducers } from './fetch/fetchDiExtractions';
import { fetchDiExtractionsCounter, fetchDiExtractionsCounterReducers } from './fetch/fetchDiExtractionsCounter';
import { fetchNextDiExtraction, fetchNextReducers } from './fetch/fetchNextDiExtraction';
import { reloadDiExtractions } from './load/reloadDiExtractions';
import { rejectDiExtraction } from './update/rejectDiExtraction';
import { approveDiExtraction } from './update/approveDiExtraction';
import { approveManyDiExtractions } from './update/approveManyDiExtractions';
import { rejectManyDiExtractions } from './update/rejectManyDiExtractions';
import { retryManyDiExtractions } from './update/retryManyDiExtractions';

const reducers: Reducers<DiExtractionStore> = {
    // Common reducers
    errorSetted: (state, payload) => ({ ...state, error: payload.error, loading: 'failed' }),

    ...fetchReducers,
    ...fetchNextReducers,
    ...fetchDiExtractionsCounterReducers,
    ...createReducers
};

export const diExtractionStore = createStore({
    initialState: {
        diExtractions: [],
        loading: 'idle',
        error: null,
        withWarnings: 0,
        criteria: {},
        updating: [],
        updated: [],
        invalid: {},
        total: 0, // FOR TAB
        counters: {
            totalApplicable: null,
            PENDING: null,
            ALREADY_PROCESSED: null,
            VALIDATION_ERROR: null,
            APPROVED: null,
            REJECTED: null,
            NOT_VALID: null,
            PENDING_REVIEW: null,
            DOCUMENT_UPLOAD_ERROR: null
        }
    },
    reducers,
    thunks: {
        fetchDiExtractions,
        fetchNextDiExtraction,
        approveDiExtraction,
        rejectDiExtraction,
        createDiExtractions,
        fetchDiExtractionsCounter,
        approveManyDiExtractions,
        rejectManyDiExtractions,
        retryManyDiExtractions,
        reloadDiExtractions
    }
});
