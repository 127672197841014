import { useState, useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { customStyles } from '../../../components/BasicComponents/Select-Autocomplete/helpers';
import { withAuth } from '../../../context/AuthProvider';

const PromotionsUserAutocomplete = (props) => {
    const [options, setOptions] = useState([]);
    const [notComplete, setNotComplete] = useState(false);
    const { setValues, values, required, className, setPromotionsToAdd } = props;

    const loadOptions = async (search, loadedOptions, { page }) => {
        return {
            options: options,
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        setValues({ ...values, promotionIds: [...values.promotionIds, item.id] });
        setPromotionsToAdd([...props.promotionsToAdd, item]);
    };

    const key = () => {
        return JSON.stringify(props.values.promotionIds);
    };

    const removeSelectedPromotions = () => {
        let options = props.myPromotions;
        for (let i = 0; i < props.myPromotions.length; i++) {
            for (let j = 0; j < values.promotionIds.length; j++) {
                if (props.myPromotions[i].id === values.promotionIds[j]) {
                    options = options.filter((option) => option.id !== values.promotionIds[j]);
                }
            }
        }
        return options;
    };

    const noOptions = () => {
        return 'Todos los proyectos ya han sido seleccionadas';
    };

    const emptyInput = () => {
        if (props.validacion === true && required && props.values.promotionIds.length === 0) {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    useEffect(() => {
        emptyInput();
    }, [props.validacion]);

    useEffect(() => {
        setOptions(removeSelectedPromotions());
    }, [props.validacion, props.promocion, props.myPromotions, props.values.promotionIds]);

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">Asignar al proyecto / proyectos {required ? '*' : ''}</label>

            <AsyncPaginate
                key={key()}
                loadOptions={loadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option?.nombre}
                getOptionValue={(option) => option}
                value={props.values?.promotions}
                styles={customStyles(notComplete)}
                placeholder=""
                additional={{
                    page: 0
                }}
                isClearable={true}
                className="autocomplete"
                noOptionsMessage={() => noOptions()}
            />
        </div>
    );
};
export default withAuth(PromotionsUserAutocomplete);
