import { IconCopy } from '@tabler/icons';
import { usePromotion } from 'modules/promotion';
import { useTranslation } from 'react-i18next';
import { notify } from 'utils/helpers/notifications';
import './WalleMailAddress.scss';

const WalleMailAddress = () => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();

    const walle = `walle-${promotion?.walleId || promotion?.id}@cocircular.es`;

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(value);
        notify({ msg: t('copyToClipboard'), isSuccess: true });
    };
    return (
        <button className="WalleMailAddress" onClick={() => copyToClipboard(walle)}>
            {walle}
            <IconCopy size={18} />
        </button>
    );
};

export default WalleMailAddress;
