export const PROMO_TYPE_EDIFICATION_ID = 1;
export const PROMO_TYPE_PHOTOVOLTAIC_ID = 8;

export const ADDITIONAL_FIELDS_BY_PROMO_TYPE = [
    {
        id: PROMO_TYPE_EDIFICATION_ID,
        fields: [
            {
                id: 0,
                name: 'numHouses',
                label: 'numHouses',
                type: Number
            }
        ]
    },
    {
        id: PROMO_TYPE_PHOTOVOLTAIC_ID,
        fields: [
            {
                id: 0,
                name: 'power',
                label: 'power',
                type: Number
            }
        ]
    }
];
