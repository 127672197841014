import { DiExtractionCriteria } from 'modules/di-extraction/domain/DiExtractionCriteria';
import { DiExtractionStore } from 'modules/di-extraction/domain/DiExtractionStore';
import { validateMassiveActionCriteria } from 'modules/di-extraction/domain/validations/validateMassiveActionCriteria';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Config = {
    silent?: boolean;
};

export const reloadDiExtractions =
    (newCriteria?: DiExtractionCriteria, config?: Config): Thunk<DiExtractionStore> =>
    async (store, { api }) => {
        const { silent } = config || {};

        try {
            const currentPage = store.state.criteria.page || 0;

            const criteria: DiExtractionCriteria = {
                ...store.state.criteria,
                size: (currentPage + 1) * 20,
                page: 0,
                ...(newCriteria || {})
            };

            validateMassiveActionCriteria(criteria);

            store.set((draft) => {
                if (!silent) draft.loading = 'updating';
            });

            const [extractions, counters, countersWithoutFilters] = await Promise.all([
                await api.getDiExtractions(criteria),
                await api.getDiExtractionsCounters(criteria),
                await api.getDiExtractionsCounters({ promotionId: criteria.promotionId })
            ]);

            store.set((draft) => {
                draft.loading = 'succeeded';
                draft.error = null;
                draft.diExtractions = extractions.results;
                draft.withWarnings = extractions.withWarnings;
                draft.criteria = criteria;
                draft.counters = { ...draft.counters, ...counters };
                draft.total = countersWithoutFilters.totalApplicable || 0;
            });
        } catch (e) {
            const error = e as Error;

            store.set((draft) => {
                draft.error = error;
                draft.loading = 'failed';
            });
        }
    };
