import { useRef } from 'react';

import { DynamicFormRef } from 'components/ComplexComponents/DynamicForm';
import { usePromotion } from 'modules/promotion';
import { useTraceabilitySaveExtraSchema } from 'modules/traceabilities';
import { TraceabilityFormProps } from '../TraceabilityForm';

export function useTraceabilityExtra(props: TraceabilityFormProps) {
    const [{ promotion }] = usePromotion();
    const schema = useTraceabilitySaveExtraSchema({ promotionId: promotion?.id });
    const $ref = useRef<DynamicFormRef>(null);

    const submit = async () => {
        const result = await $ref.current?.submit?.();
        return result;
    };

    return {
        schema,
        $ref,
        submit
    };
}
