import fetcher from 'lib/fetcher';
import { OrphanDocumentsApi } from '../domain/OrphanDocumentsApi';
import { EmailWithOrphanDocuments } from '../domain/EmailWithOrphanDocuments';

export const httpOrphanDocuments: OrphanDocumentsApi = {
    async getEmailsWithOrphanDocuments({ page }) {
        const emailsRequest = await fetcher(`/api/tracing/extractions/orphan-documents`, { params: { page } });

        const results = emailsRequest.data.content.map((email: EmailWithOrphanDocuments) => ({
            ...email,
            userMail: _extractEmail(email.userMail),
            to: _extractEmail(email.to)
        }));

        return {
            emails: results,
            total: results.length // THIS ENDPOINT HAS NO PAGINATION
        };
    },
    async assignOrphanDocuments({ selectedDocumentsIds, promotionId }) {
        const message = {
            success: { text: 'assignRequest.success', ns: 'walle' },
            error: { text: 'assignRequest.error', ns: 'walle' }
        };

        const data = {
            extractions: selectedDocumentsIds,
            promotionId
        };

        await fetcher.put(`/api/tracing/extractions/orphan-documents`, data, { message } as any);
    },
    async discardOrphanDocuments({ selectedDocumentsIds }) {
        const message = {
            success: { text: 'rejectRequest.success', ns: 'walle' },
            error: { text: 'rejectRequest.error', ns: 'walle' }
        };

        const data = { id: { in: selectedDocumentsIds } };

        await fetcher.patch(`/api/tracing/extractions/reject`, data, { message } as any);
    }
};

const _extractEmail = (fullMailData) => {
    return fullMailData.split('<').pop().split('>')[0] || '';
};
