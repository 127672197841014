import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { IconMinus, IconPlus } from '@tabler/icons';

import { Billeable, usePromotionBilleables } from 'modules/billeables';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';

import './BilleableBox.scss';

type BilleableBoxProps = PropsWithChildren<{
    billeable?: Billeable;
    added?: boolean;
}>;

const BilleableBox = ({ billeable, children }: BilleableBoxProps) => {
    const [, { purchaseBilleable, removeBilleable }] = usePromotionBilleables();
    const [t] = useTranslation();

    const showActions = billeable?.editable;

    const withActionsMod = showActions ? 'BilleableBox--with-actions' : '';
    const isSkeletonMod = !billeable ? 'BilleableBox--is-skeleton' : '';
    const purchasedMod = billeable?.purchased ? 'BilleableBox--purchased' : '';
    const withDetails = children ? 'BilleableBox--with-details' : '';

    return (
        <article
            data-testid={`BilleableBox__${billeable?.name}`}
            className={`BilleableBox ${isSkeletonMod} ${purchasedMod} ${withActionsMod} ${withDetails}`}
        >
            <header className="BilleableBox__summary">
                <p className={`BilleableBox__title`}>
                    {billeable?.name &&
                        t(`productsAndServices.billeables.${billeable?.name}.name`, { ns: 'newPromotion' })}
                </p>
                <p className={`BilleableBox__text`}>
                    {billeable?.name &&
                        t(`productsAndServices.billeables.${billeable?.name}.description`, { ns: 'newPromotion' })}
                </p>
            </header>

            {children && <div className="BilleableBox__details">{children}</div>}

            {showActions && (
                <footer className="BilleableBox__actions">
                    {billeable.purchased ? (
                        <SmallButton
                            titulo={t('delete')}
                            color="refuse"
                            icon={<IconMinus size={20} />}
                            action={() => removeBilleable({ billeable })}
                        />
                    ) : (
                        <SmallButton
                            titulo={t('add')}
                            color="purple"
                            icon={<IconPlus size={20} />}
                            action={() => purchaseBilleable({ billeable })}
                        />
                    )}
                </footer>
            )}
        </article>
    );
};

export default BilleableBox;
