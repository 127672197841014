import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import DateRangeFilter from 'components/BasicComponents/Filter/DateRangeFilter';
import FilterBar from 'components/BasicComponents/Filter/FilterBar/FilterBar';
import SelectFilter from 'components/BasicComponents/Filter/SelectFilter';
import TextFilter from 'components/BasicComponents/Filter/TextFilter';
import { useQuery } from 'hooks/useQuery';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { DiExtractionCriteria, DiExtrationOrigin, DiExtrationStatus, useDiExtraction } from 'modules/di-extraction';
import './AutomaticReadingFilters.scss';

const AutomaticReadingFilters = () => {
    const [t] = useTranslation('automaticReading');
    const [, analytics] = useAnalyticsStore();
    const query = useQuery();
    const [showWithWarnings, setShowWithWarnings] = useState(false);
    const [{ criteria }] = useDiExtraction();

    const { state } = criteria;
    const filteredOnlyByPendingReview = state?.length === 1 && state?.[0]?.[0] === DiExtrationStatus.PENDING_REVIEW;

    const sortOptions: Array<{ label: string; value: DiExtractionCriteria['sort'] }> = [
        { label: 'Fecha más reciente', value: 'requestTime,desc' },
        { label: 'Fecha más antigua', value: 'requestTime,asc' },
        { label: 'Usuario', value: 'userMail,asc' },
        { label: 'Tipo de fichero', value: 'mimeType,asc' }
    ];

    return (
        <div className="AutomaticReadingFilters">
            <FilterBar
                onFilterChange={(name, value) => {
                    analytics.event(
                        AnalyticsEvents['AUTOMATIC_READING_FILTEREDBY_' + name?.toUpperCase()] as AnalyticsEvents
                    );

                    if (name === 'status') {
                        const filteringByPendingReview =
                            value.length > 0 &&
                            value.every((o) => o?.value?.includes(DiExtrationStatus.PENDING_REVIEW));

                        setShowWithWarnings(filteringByPendingReview);

                        if (filteringByPendingReview) return;
                        query.remove(['withWarnings']);
                    }
                }}
            >
                <SelectFilter label={t('sort', { ns: 'common' })} name="sort" options={sortOptions} />
                <DateRangeFilter name="date" label={'Fecha de subida'} />

                <TextFilter name="fileName" label={'Nombre del archivo contiene'} isClearable={true} />

                <TextFilter name="email" label={'Email de usuario contiene'} isClearable={true} />

                <SelectFilter
                    label={'Origen'}
                    name="origin"
                    options={[
                        { label: '360º Advisor', value: DiExtrationOrigin.WEB },
                        { label: 'Email', value: DiExtrationOrigin.EMAIL }
                    ]}
                />

                <SelectFilter
                    label={'Estado del DI'}
                    name="status"
                    options={[
                        { label: 'Por completar', value: [DiExtrationStatus.VALIDATION_ERROR] },
                        { label: 'Por validar', value: [DiExtrationStatus.PENDING_REVIEW] },
                        { label: 'Ya existente', value: [DiExtrationStatus.ALREADY_PROCESSED] },
                        {
                            label: 'Error',
                            value: [DiExtrationStatus.DOCUMENT_UPLOAD_ERROR, DiExtrationStatus.NOT_VALID]
                        }
                    ]}
                    isMultiple
                />

                <SelectFilter
                    disabled={!showWithWarnings && !filteredOnlyByPendingReview}
                    label={t('filters.warnings.label')}
                    name="withWarnings"
                    options={[
                        { label: t('filters.warnings.with'), value: true },
                        { label: t('filters.warnings.without'), value: false }
                    ]}
                />
            </FilterBar>
        </div>
    );
};

export default AutomaticReadingFilters;
