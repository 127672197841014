import axios from 'axios';
import { getToken } from '../../utils/helpers/api.helpers';

class AuthenticationServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    changePassword = async (password) => {
        try {
            const response = await this.services
                .post(`/api/account/change-password`, password, {
                    headers: {
                        Authorization: getToken()
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        return error.response;
                    }
                });
            return response;
        } catch (error) {
            console.log(error);
        }
    };
}

const authenticationServices = new AuthenticationServices();
export default authenticationServices;
