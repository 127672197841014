import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import HasPermission from 'components/AccessCheckers/HasPermission';
import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import TraceabilityRequestDIAction from 'features/traceabilities/components/TraceabilityRequestDIAction/TraceabilityRequestDIAction';
import { useAutomaticReadingPermissions } from 'modules/automatic-reading';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import './TraceabilitiesPageHeader.scss';
import TraceabilityUpload from '../TraceabilityUpload/TraceabilityUpload';

const TraceabilitiesPageHeader = ({
    showRegisterAction = true,
    showTraceabilityAction = true,
    title
}: {
    showTraceabilityAction?: boolean;
    showRegisterAction?: boolean;
    title: string;
}) => {
    const [{ promotion }] = usePromotion();
    const promotionId = promotion?.id;

    const [{ permissions }, { loadAutomaticReadingPermissions }] = useAutomaticReadingPermissions();
    const canAccessAutomaticReading = permissions?.canAccess.value && !permissions.isDisabled.value;
    const [uploadModalOpened, setUploadModalOpened] = useState(false);

    const [t] = useTranslation();

    // Update extractions counters
    useEffect(() => {
        if (!promotion?.id) return;

        loadAutomaticReadingPermissions();
    }, [promotionId]);

    return (
        <>
            <header className="TraceabilitiesPageHeader">
                <TituloH1 titulo={title} />

                <div className="TraceabilitiesPageHeader__actions">
                    {showRegisterAction && <TraceabilityRequestDIAction color="white" />}

                    {showTraceabilityAction && (
                        <HasPermission
                            ifRole="canCreate"
                            section="traceability"
                            disabledMsg={t('cannotCreateMsg', { ns: 'traceabilities' })}
                        >
                            <Tooltip
                                msg={
                                    !canAccessAutomaticReading
                                        ? t('disableReasons.promotionWithoutNima', { ns: 'automaticReading' })
                                        : ''
                                }
                            >
                                <CreateButton
                                    type="button"
                                    text={t('uploadDi', { ns: 'traceabilities' })}
                                    icon={null}
                                    action={() => setUploadModalOpened(true)}
                                    disabled={!canAccessAutomaticReading}
                                />
                            </Tooltip>
                        </HasPermission>
                    )}
                </div>
            </header>

            <TraceabilityUpload isOpen={uploadModalOpened} setIsOpen={setUploadModalOpened} />
        </>
    );
};

export default TraceabilitiesPageHeader;
