import { useTranslation } from 'react-i18next';
import { IconCertificate } from '@tabler/icons';
import { withAuth } from 'context/AuthProvider';
import RoundedIcon from 'components/BasicComponents/Icon/RoundedIcon';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import InputText from 'components/BasicComponents/Inputs/InputText';
import { TituloH3 } from 'components/BasicComponents/Titulos/Titulos';

const BadgesForm = ({ promotion, handleInputChange, validation }) => {
    const [t] = useTranslation();

    return (
        <div>
            <div className="ProductsAndServices__form-title">
                <RoundedIcon icon={<IconCertificate />} />
                <TituloH3 titulo={t('labels.greenBadge', { ns: 'newPromotion' })} />
            </div>
            <div className="ProductsAndServices__container">
                <InputText
                    onChange={handleInputChange}
                    name="selloVerde"
                    value={promotion.selloVerde}
                    label={t('labels.greenBadgeNames', { ns: 'newPromotion' })}
                />
                <InputNumber
                    onChange={handleInputChange}
                    name="porcentajeSelloVerde"
                    value={promotion.porcentajeSelloVerde}
                    label={t('labels.percentGreenBadge', { ns: 'newPromotion' })}
                    formatNumber={false}
                    min={0}
                    max={100}
                    required={promotion.selloVerde ? true : false}
                    validacion={validation}
                />
            </div>
        </div>
    );
};
export default withAuth(BadgesForm);
