import { IconFileDownload } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import { useWasteRegisterStore } from 'modules/waste-register/infrastructure/react/wasteRegisterContext';
import './WasteRegisterExportCsv.scss';

const WasteRegisterExportCsv = () => {
    const [t] = useTranslation('wasteRegister');
    const [, { exportCsv }] = useWasteRegisterStore();

    return (
        <div className="WasteRegisterExportCsv">
            <SmallButton
                className={`button--link`}
                iconPosition="left"
                action={exportCsv}
                titulo={t('list.actions.exportToCsv.label')}
                icon={<IconFileDownload />}
                // Just to ignore optional fields
                {...({} as any)}
            />
        </div>
    );
};

export default WasteRegisterExportCsv;
