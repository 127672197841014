import { AsyncPaginate } from 'react-select-async-paginate';
import lerServices from '../../../api/ler/ler.services';
import { withAuth } from '../../../context/AuthProvider';
import { customStyles, hasMore } from './helpers';

const LerTypeAutocomplete = ({
    propName = 'typeLer',
    required,
    className,
    values,
    setValues,
    isClearable = false,
    INITIAL_STATE = ''
}) => {
    const getLoadOptions = async (search, loadedOptions, { page }) => {
        let filter = search && `?lerKind.contains=${search}`;
        let options = await lerServices.getLerTypes(filter);
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        if (item) {
            setValues({ ...values, [propName]: item });
        } else {
            setValues({ ...values, [propName]: INITIAL_STATE });
        }
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">Tipo de LER {required ? '*' : ''}</label>
            <AsyncPaginate
                loadOptions={getLoadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.lerKind}
                placeholder=""
                value={values?.[propName]}
                additional={{
                    page: 0
                }}
                filterOption={false}
                styles={customStyles()}
                isClearable={isClearable}
            />
        </div>
    );
};
export default withAuth(LerTypeAutocomplete);
