import { useState } from 'react';

import { useAuth } from 'modules/auth';

export const useEnterprise = () => {
    const [account] = useAuth();
    const initialEnterprise = account.user?.enterprise;

    const [enterprise, setEnterprise] = useState(initialEnterprise);

    return {
        enterprise,
        setEnterprise,
        hasEnterprise: !account.isLoading && enterprise
    };
};
