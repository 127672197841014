import { logger } from 'logger';
import { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import beaver from '../../assets/imagenes/castor.gif';
import BasicButton from '../../components/BasicComponents/Buttons/Base/BasicButton';
import { withAuth } from '../../context/AuthProvider';
import './ErrorBoundary.scss';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined,
            info: undefined
        };
    }

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            error,
            info
        });

        console.error(error);
        logger.send(error, { level: 'fatal', type: 'errorBoundary' });
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen(() => {
            if (this.state.hasError) {
                this.setState({ hasError: false });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    onClick = () => {
        this.setState({
            ...this.state,
            hasError: false
        });
    };

    render() {
        const supportEmail = 'admin@cocircular.es';
        const { hasError, error } = this.state;
        const { t } = this.props;

        if (hasError) {
            return (
                <div className="ErrorBoundary">
                    <img src={beaver} alt="beaver" className="ErrorBoundary__beaver" />
                    <div className="ErrorBoundary__container">
                        <h1 className="ErrorBoundary__title">{t('errorBoundary', { ns: 'errors' })}</h1>
                        <p className="ErrorBoundary__msg">
                            <Trans
                                i18nKey="errorBoundaryMsg"
                                ns="errors"
                                values={{ email: supportEmail }}
                                components={[
                                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                                    <a className="ErrorBoundary__link" href={`mailto:${supportEmail}`} />,
                                    <p />
                                ]}
                            ></Trans>
                        </p>
                        <details>
                            <ErrorCard className="ErrorBoundary__stack" error={error} />
                        </details>
                        <BasicButton
                            extraClasses="ErrorBoundary__goHomeButton"
                            to={`/account/reset-password/request`}
                            color="purple"
                            type="link"
                            text={t(`goHomeButton`, { ns: 'errors' })}
                        />
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default withTranslation()(withAuth(withRouter(ErrorBoundary)));
