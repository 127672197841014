import { useEffect, useState } from 'react';

import { EmptyWasteRegister, WasteRegister } from 'modules/waste-register/domain/WasteRegister';
import { useNotificationSettings } from 'modules/notification-settings/infrastructure/react/notificationSettingsContext';
import { usePromotion } from 'modules/promotion';

export function useCheckStakeholdersNotification(props: { wasteRegister?: WasteRegister | EmptyWasteRegister }) {
    const [{ promotion }] = usePromotion();
    const [, , { api }] = useNotificationSettings();
    const [notificationTo, setNotificationTo] = useState<string[]>([]);

    const checkIfStakeholdersAreNotifiable = async () => {
        const notificationSettings = await api.findByCriteria({
            promotionIds: [promotion?.id || NaN],
            type: 'waste-register'
        });

        const stakeholders = notificationSettings.map((entry) => entry.data);

        const notifiableWasteManagers = stakeholders
            .filter((s) => s.stakeholderType === 'waste-manager')
            .map((s) => s.stakeholderName);
        const notifiableCarriers = stakeholders
            .filter((s) => s.stakeholderType === 'carrier')
            .map((s) => s.stakeholderName);

        const managerName = props.wasteRegister?.flow?.manager?.name || '';
        const carrierName = props.wasteRegister?.flow?.carrier?.name || '';

        const notificationToAux: string[] = [];
        if (notifiableWasteManagers.includes(managerName)) notificationToAux.push(managerName);
        if (notifiableCarriers.includes(carrierName)) notificationToAux.push(carrierName);

        // prevent notificationToAux duplicates
        const uniqueNotificationToAux = [...new Set(notificationToAux)];

        setNotificationTo(uniqueNotificationToAux);
    };

    useEffect(() => {
        if (!promotion || !props.wasteRegister || !props.wasteRegister.flow) return;

        checkIfStakeholdersAreNotifiable();
    }, [promotion, props.wasteRegister?.flow?.id]);

    return { notificationTo };
}
