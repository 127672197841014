import { useEffect } from 'react';

import { withAuth } from 'context/AuthProvider';
import HomePage from './HomePage';

/**
 * Remove promotion fron sessionstorage
 * @todo - remove this when all promotion pages moved under promotion/:id path
 */
export default withAuth((props) => {
    useEffect(() => {
        props.removePromotion();
    }, []);

    return <HomePage />;
});
