import fetcher from '../../../lib/fetcher';

export const getDownloads = async ({ promotionId }, filter) => {
    const URL = `/api/tracing/documents/compression?promotionId=${promotionId}${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
