import { IconPlus } from '@tabler/icons';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import EmptyState from 'components/BasicComponents/EmptyState';
import { useAuth } from 'modules/auth';
import { tagsHooks } from 'modules/tags';
import { TagColors } from 'modules/tags/domain/TagColors';
import './TagManager.scss';
import EnterpriseSelectAutocomplete from './components/EnterpriseSelectAutocomplete';
import TagManagerEnterpriseAlert from './components/TagManagerEnterpriseAlert';
import TagManagerItem from './components/TagManagerItem';
import { useEnterprise } from './hooks/useEnterprise';

const TagManager = () => {
    const [t] = useTranslation('tags');
    const [account] = useAuth();
    const { enterprise, setEnterprise } = useEnterprise();

    const [isCreating, setIsCreating] = useState(false);

    const tags = tagsHooks.useQuery({ enterpriseId: enterprise?.id || NaN });

    const colors = Object.values(TagColors);
    const nextColor = colors[(tags.data?.length || 0) % colors.length];

    const modifiers = [tags.loading === 'pending' ? 'TagManager--updating' : ''];

    return (
        <>
            <SimpleCard
                dataTestid="TagManager"
                className={`TagManager ${modifiers.join(' ')}`}
                title={t('manager.title')}
                subtitle={t('manager.subtitle')}
            >
                {tags.error && <ErrorCard error={tags.error} />}

                {!enterprise && <TagManagerEnterpriseAlert />}

                {account.isCoCircular && (
                    <EnterpriseSelectAutocomplete
                        className="TagManager__enterprise"
                        value={enterprise}
                        onChange={(value) => setEnterprise(value)}
                    />
                )}

                {tags.data && enterprise && (
                    <div className="TagManager__list">
                        <header className="TagManager__listHeader">
                            <Trans
                                i18nKey="tags:manager.tagsFor"
                                components={{ strong: <strong /> }}
                                values={{ enterprise: enterprise?.name }}
                            />
                        </header>

                        {tags.data.map((tag) => (
                            <TagManagerItem
                                key={tag.id}
                                tag={tag}
                                enterpriseId={enterprise.id}
                                onSaved={() => tags.refetch()}
                                onRemoved={() => tags.refetch()}
                                onClose={() => tags.refetch()}
                            />
                        ))}

                        {tags.loading == 'succeeded' && !isCreating && tags.data.length === 0 && (
                            <EmptyState mode="emptyResults" />
                        )}

                        {isCreating && (
                            <TagManagerItem
                                enterpriseId={enterprise.id}
                                defaults={{ color: nextColor }}
                                onClose={() => setIsCreating(false)}
                                onSaved={() => {
                                    tags.refetch();
                                    setIsCreating(false);
                                }}
                            />
                        )}

                        {!isCreating && (
                            <footer className="TagManager__listFooter">
                                <SmallButton
                                    action={() => setIsCreating(true)}
                                    titulo={t('manager.newTag')}
                                    icon={<IconPlus />}
                                    iconPosition="left"
                                    // Just to ignore optional fields
                                    {...({} as any)}
                                />
                            </footer>
                        )}
                    </div>
                )}
            </SimpleCard>
        </>
    );
};

export default TagManager;
