import { useReducer } from 'react';

import createReducer from 'lib/createReducer';

import { reducers } from './reducers';
import { initialState } from './state';
import { loadDocumentTypes } from './thunks/loadDocumentTypes';
import { deleteDocument } from './thunks/deleteDocument';

export * from './reducers';

export function useDocumentsStore() {
    const [state, dispatch] = useReducer(createReducer(reducers), initialState);

    const store = {
        loadDocumentTypes: loadDocumentTypes({ dispatch }),
        deleteDocument: deleteDocument({ state, dispatch })
    };

    return [state, store];
}
