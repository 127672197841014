import { FieldValues, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControlProps } from './FormControlProps';

export function useErrorMessage<Values extends FieldValues>(props: FormControlProps<Values>) {
    const [t] = useTranslation('validations');

    // To properly subscribe to errors, destructuring the errors object is necessary
    // https://react-hook-form.com/docs/useformstate
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { errors: _ } = useFormState({ control: props.control });

    const { error } = props.control.getFieldState(props.name);

    const errorMsg =
        t(error?.message || '', {
            minimum: (error as any)?.minimum
        }) || '';

    return { errorMsg };
}
