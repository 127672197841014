export const calcTitleFontSize = (text) => {
    const base = '(75vw - 4.5rem)';

    if (text.length > 28) {
        return `calc(${base} / ${text.length / 2.5})`;
    }

    const longestWord = text
        .split(' ')
        .reduce((longest, current) => (current.length > longest.length ? current : longest), '');

    const letterCount = longestWord.length;
    const factor = letterCount * 0.5;
    const factorWithThresholds = (factor < 9 && 9) || factor;

    return `calc(${base} / ${factorWithThresholds})`;
};
