export const WASTE_MANAGERS = {
    COCIRCULAR: {
        id: 1,
        nima: '1200010719'
    }
};

export const WASTE_MANAGERS_VALUATION_CODES = {
    TRANSFER_OPERATOR: 'O01'
};
