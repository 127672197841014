import PromotionInfo from 'features/promotion/pages/PromotionDetail/sections/PromotionInfo/PromotionInfo';
import { useTranslation } from 'react-i18next';
import HasPermission from '../../AccessCheckers/HasPermission';
import SimpleCard from '../../BasicComponents/Cards/SimpleCard';
import InputFecha from '../../BasicComponents/Inputs/InputFecha';
import ActivityStatusSelectAutocomplete from '../../BasicComponents/Select-Autocomplete/ActivityStatusSelectAutocomplete';
import EstadosPromocionSelectAutocomplete from '../../BasicComponents/Select-Autocomplete/EstadosPromocionSelectAutocomplete';

const DatosIniciales = (props) => {
    const [t] = useTranslation();
    const { user, actividad } = props;

    const handleInputChange = ({ target }) => {
        props.setActividad({
            ...props.actividad,
            [target.name]: target.value
        });
    };

    return (
        <div className="visita__data">
            <SimpleCard title={props?.title} allowScroll={false}>
                <span>
                    {t('author', { ns: 'common' })}: {user?.firstName + ' ' + user?.lastName}
                </span>
                <div className="visita__dataInputs">
                    <InputFecha
                        label={t('date.visitDate', { ns: 'common' })}
                        name="fechaActividad"
                        value={props.actividad?.fechaActividad}
                        values={props.actividad}
                        setValues={props.setActividad}
                        onChange={handleInputChange}
                        notLimit={true}
                    />
                    <HasPermission ifRole="canComplete" section="siteChecking">
                        <ActivityStatusSelectAutocomplete
                            isConstructionSite={true}
                            values={props.actividad}
                            setValues={props.setActividad}
                        />
                    </HasPermission>
                    <EstadosPromocionSelectAutocomplete values={props.promoState} setValues={props.setPromoState} />
                </div>
                <label className="visita__onSite">
                    <input
                        type="checkbox"
                        checked={props.actividad?.onSite === false}
                        onChange={() => props.setActividad({ ...actividad, onSite: !actividad.onSite })}
                    />
                    <span>Es online</span>
                </label>
            </SimpleCard>
            <PromotionInfo />
        </div>
    );
};

export default DatosIniciales;
