import axios, { AxiosError } from 'axios';
import { logger } from 'logger';
import { toastHandler, methodsToast } from './toastHandler.helper';
import { getToken } from './getToken.helper';
import { dispatchLogout } from './LogoutHandler';

type FetcherStatusRequest = { url?: string; method?: string; status: 'pending' | 'fulfilled' | 'rejected' };

export const FetcherStatus = {
    request: new Map<string, FetcherStatusRequest>(),
    target: new EventTarget(),
    update: (request: FetcherStatusRequest) => {
        const key = `${request.method} ${request.url}`;
        const maxHistory = 1000;
        FetcherStatus.request.set(key, request);
        if (FetcherStatus.request.size > maxHistory) {
            const keys = Array.from(FetcherStatus.request.keys());
            FetcherStatus.request.delete(keys[0]);
        }

        FetcherStatus.target.dispatchEvent(
            new CustomEvent('fetcher:request', { detail: { requests: FetcherStatus.request } })
        );
    },
    subscribe: (callback: (detail: { requests: Map<string, FetcherStatusRequest> }) => void) => {
        const listener = (e: any) => {
            const event = e as CustomEvent<{ requests: Map<string, FetcherStatusRequest> }>;
            callback(event.detail);
        };
        FetcherStatus.target.addEventListener('fetcher:request', listener);
        return {
            unsubscribe: () => {
                FetcherStatus.target.removeEventListener('fetcher:request', listener);
            }
        };
    }
};

function authRequestInterceptor(config) {
    const token = getToken();
    if (token) {
        config.headers.Authorization = token;
    }
    config.headers.Accept = 'application/json';
    return config;
}

const fetcher = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

fetcher.interceptors.request.use((config) => {
    FetcherStatus.update({ url: config.url, method: config.method, status: 'pending' });
    return authRequestInterceptor(config);
});
fetcher.interceptors.response.use(
    (response) => {
        if (response && response.config.method && methodsToast.includes(response.config.method)) {
            toastHandler(response);
        }
        FetcherStatus.update({ url: response.config.url, method: response.config.method, status: 'fulfilled' });
        return response;
    },
    (axiosError: AxiosError) => {
        if (
            axiosError?.response &&
            axiosError.response.config.method &&
            methodsToast.includes(axiosError.response.config.method)
        ) {
            toastHandler(axiosError.response);
        }
        if (axiosError.response?.status === 401) {
            dispatchLogout();
        }

        const message = axiosError.message;
        const stack = axiosError.response?.data || axiosError.stack;

        console.error(message, stack);

        const shouldSendLog =
            !axiosError.response || axiosError.response.status >= 400 || axiosError.response.status < 200;

        if (shouldSendLog) {
            const extra = {
                message,
                data: axiosError.response?.data,
                status: axiosError.response?.status,
                headers: axiosError.response?.headers,
                url: axiosError.config.url,
                params: axiosError.config.params,
                request: axiosError.config
            };
            logger.send(axiosError, extra);
        }

        FetcherStatus.update({ url: axiosError.config.url, method: axiosError.config.method, status: 'rejected' });
        return Promise.reject(axiosError);
    }
);

export default fetcher;
