import usePrevious from 'hooks/usePrevious';
import { useLayoutEffect, useRef, useState } from 'react';

interface Props {
    activeTab: number | null;
}

export function useTabsIndicatorAnimation({ activeTab }: Props) {
    const [indicator, setIndicator] = useState({ width: 0, position: 0 });
    const [layoutLoaded, setLayoutLoaded] = useState(false);
    const tabsRef = useRef<HTMLUListElement>(null);

    const prevActiveTab = usePrevious(activeTab);

    const updateIndicatorPosition = () => {
        if (activeTab === null) return;
        const tabs = tabsRef.current;
        const activeElement = tabs?.children[activeTab] as HTMLElement | undefined;

        const width = activeElement?.offsetWidth || 0;
        const position = (activeElement?.offsetLeft || 0) - (tabs?.offsetLeft || 0);

        setIndicator({ width, position });

        const tabsContainer = tabs?.parentElement;
        tabsContainer?.scrollTo({ left: position - 50 });
    };

    useLayoutEffect(() => {
        if (!layoutLoaded) {
            /**
             * Workaround:
             * All CSS is required to be loaded before reading the tab width.
             * Since there is no event that provides this information, a timeout is used instead.
             */
            setTimeout(() => setLayoutLoaded(true), 1000);
            return;
        }

        updateIndicatorPosition();

        // Keep position when resize
        window.addEventListener('resize', updateIndicatorPosition);
        return () => window.removeEventListener('resize', updateIndicatorPosition);
    }, [activeTab, layoutLoaded]);

    // Update indicator position when tabs DOM elements changes
    useLayoutEffect(() => {
        const observer = new MutationObserver(() => {
            if (activeTab !== prevActiveTab) return;
            updateIndicatorPosition();
        });

        const tabs = tabsRef.current;

        if (tabs) observer.observe(tabs, { attributes: true, childList: true, subtree: true });
        return () => observer.disconnect();
    }, [activeTab, prevActiveTab]);

    return { indicator, tabsRef };
}
