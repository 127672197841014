import { Thunk } from 'modules/shared/domain/store/createStore';
import { WasteRegisterStore } from 'modules/waste-register/domain/WasteRegisterStore';

type Store = WasteRegisterStore; // Define Store here

export const exportCsv =
    (): Thunk<Store> =>
    async (store, { api }) => {
        try {
            await api.downloadCsv(store.state.criteria);
        } catch (e) {
            const error = e as Error;
            console.error(error);
        }
    };
