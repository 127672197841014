let timer: ReturnType<typeof setTimeout> | undefined = undefined;

export function debounce<DebouncedFn extends (...params: any[]) => Promise<any>>(fn: DebouncedFn, delay: number = 500) {
    type Data = Awaited<ReturnType<DebouncedFn>>;
    type Params = Parameters<DebouncedFn>;

    const debounced = (...args: Params) => {
        clearTimeout(timer);

        const promise = new Promise<Data>((resolve) => {
            timer = setTimeout(async () => {
                const result = await fn(...args);
                resolve(result);
            }, delay);
        });
        return promise;
    };

    return debounced;
}

/** Retries a function until it succeeds or the attemps limit is reached */
export function retry<RetriedFn extends (...params: any[]) => Promise<any>>(
    fn: RetriedFn,
    config?: { attemps?: number; onFailedAttemp?: (attemp: number) => void }
) {
    const { attemps = 3, onFailedAttemp } = config || {};
    type Data = Awaited<ReturnType<RetriedFn>>;
    type Params = Parameters<RetriedFn>;

    return (...args: Params) =>
        new Promise<Data>((resolve, reject) => {
            const tryRequest = async (attemp: number) => {
                try {
                    const response = await fn(...args);
                    resolve(response);
                } catch (error) {
                    if (attemp > attemps) {
                        reject(error);
                    } else {
                        onFailedAttemp?.(attemp);
                        console.log(`Failed attemp ${attemp} of ${attemps}`);
                        tryRequest(attemp + 1);
                    }
                }
            };

            tryRequest(1);
        });
}
