import { KPIStatus } from 'modules/promotion-kpis';
import './KPIThermometer.scss';

interface KPIThermometerProps {
    value?: number | null;
    maxValue?: number;
    status?: KPIStatus | Array<KPIStatus | null> | null;
    thresholds?: Array<{
        value: number;
        type: 'MIN' | 'MAX';
        goal?: number;
    }>;
    type?: 'discrete' | 'continuous';
}

const KPIThermometer = (props: KPIThermometerProps) => {
    const value = Math.round(props?.value || 0);
    const maxValue = props?.maxValue || value || 1;
    const type = props.type || 'continuous';
    const valuePercent = value <= maxValue ? `${(value / maxValue) * 100}%` : '100%';

    const modifiers = [
        value ? 'KPIThermometer--has-value' : '',
        props.status && !Array.isArray(props.status) ? `KPIThermometer--${props.status}` : '',
        `KPIThermometer--${type}`
    ];

    const segments = [...new Array(maxValue || 1)];

    return (
        <div className={`KPIThermometer ${modifiers.join(' ')}`} data-testid="KPIThermometer">
            {type === 'discrete' && (
                <div className="KPIThermometer__bar">
                    {segments.map((_, index) => {
                        const status = Array.isArray(props.status) ? props.status[index] : 'INDETERMINATE';
                        return <div className={`KPIThermometer__segment KPIThermometer--${status}`} />;
                    })}
                </div>
            )}

            {type === 'continuous' && (
                <>
                    <div className="KPIThermometer__bar" />
                    <div className="KPIThermometer__progress" style={{ width: valuePercent }}></div>
                    {props.thresholds?.map((threshold, index) => {
                        // determines if the threshold is the best goal among all thresholds
                        const isBest =
                            (threshold.type === 'MIN' && props.thresholds?.every((t) => threshold.value >= t.value)) ||
                            (threshold.type === 'MAX' && props.thresholds?.every((t) => threshold.value <= t.value));

                        const pinModifiers = [
                            `KPIThermometer__threshold-pin--${threshold?.type}`,
                            isBest ? 'KPIThermometer__threshold-pin--best-goal' : ''
                        ];

                        const value = (threshold?.value / maxValue) * 100;

                        return (
                            <>
                                <div
                                    className="KPIThermometer__threshold"
                                    data-testid={`KPIThermometer__threshold-${value}`}
                                    style={{ left: `${value}%` }}
                                    key={index}
                                >
                                    <svg
                                        className={`KPIThermometer__threshold-pin ${pinModifiers.join(' ')}`}
                                        data-testid="KPIThermometer__threshold-pin"
                                        width="5"
                                        height="18"
                                        viewBox="0 0 5 15"
                                        fill="none"
                                    >
                                        <path d="M5 3L1 5.40002V15H0V0L5 3Z" fill="#3D5671" />
                                    </svg>
                                </div>
                            </>
                        );
                    })}

                    <div
                        className="KPIThermometer__point"
                        data-testid="KPIThermometer__point"
                        style={{ left: valuePercent || 0 }}
                    />
                </>
            )}
        </div>
    );
};

export default KPIThermometer;
