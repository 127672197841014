import { IconChevronDown, IconMessageDots, IconX } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import SaveButton from 'components/BasicComponents/Buttons/SaveButton';
import SupportForm from 'features/support/components/SupportForm';
import { useKey } from 'hooks/useKey';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import SupportResources from '../../components/SupportResources';
import './SupportWidget.scss';

const SupportWidget = () => {
    const location = useLocation();
    const [, analytics] = useAnalyticsStore();
    const [open, setOpen] = useState(false);
    const [view, setView] = useState<'resources' | 'form'>('resources');
    const [t] = useTranslation('support');

    const modifiers = [open ? 'SupportWidget--open' : ''];

    // Close modal on Escape key
    useKey(
        'Escape',
        () => {
            if (view === 'form') {
                const confirmed = window.confirm(t('areYouSureYouWantToClose'));
                if (!confirmed) return;
            }
            setOpen(false);
        },
        [open, view]
    );

    // On Close, reset view to resources
    useEffect(() => {
        if (!open) setView('resources');
    }, [open]);

    // On Open, send analytics event
    useEffect(() => {
        if (open) {
            analytics.event(AnalyticsEvents.SUPPORT_OPENED);
        }
    }, [open]);

    const excludePaths = ['/account'];
    if (excludePaths.some((path) => matchPath(location.pathname, { path, exact: false }))) return null;

    return createPortal(
        <div className={`SupportWidget ${modifiers.join(' ')}`}>
            <div className="SupportWidget__modal">
                <header className="SupportWidget__modalHeader">
                    {t('doYouNeedHelp')}
                    <IconX
                        className="SupportWidget__modalHeaderClose"
                        role="button"
                        size={28}
                        stroke={2}
                        onClick={() => setOpen(false)}
                    />
                </header>

                {view === 'resources' && (
                    <section className="SupportWidget__modalContent">
                        <SupportResources onResourceClick={() => setOpen(false)} />
                        <div className="SupportWidget__cta">
                            <span className="SupportWidget__ctaText">{t('wannaMakeARequest')}</span>
                            <SaveButton
                                testid="SupportWidget__ctaButton"
                                extraClasses="SupportWidget__ctaButton button__purple"
                                text={t('contactWithUs')}
                                action={() => setView('form')}
                                icon={<></>}
                            />
                        </div>
                    </section>
                )}

                {view === 'form' && (
                    <section className="SupportWidget__modalContent">
                        <SupportForm onGoBack={() => setView('resources')} />
                    </section>
                )}
            </div>

            <button
                className="SupportWidget__button"
                onClick={() => setOpen(!open)}
                data-testid="SupportWidget__button"
            >
                <div className="SupportWidget__buttonContent --open">
                    <IconMessageDots size={20} stroke={2} />
                    <span>{t('help')}</span>
                </div>
                <div className="SupportWidget__buttonContent --closed">
                    <IconChevronDown size={28} stroke={2} />
                </div>
            </button>
        </div>,
        document.body
    );
};

export default SupportWidget;
