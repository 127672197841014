import Modal from 'react-modal';
import './SidePanel.scss';
import { PropsWithChildren } from 'react';
import { IconX } from '@tabler/icons';

interface SidePanelProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    title?: string;
    testid?: string;
    variant?: 'default' | 'full';
    shouldCloseOnEsc?: boolean;
}

const SidePanel = (props: PropsWithChildren<SidePanelProps>) => {
    const modifiers = [`SidePanel__sheet--${props.variant || 'default'}`];

    return (
        <Modal
            className={`SidePanel__sheet ${modifiers.join(' ')}`}
            overlayClassName="SidePanel__overlay"
            isOpen={props.openModal}
            shouldCloseOnEsc={props.shouldCloseOnEsc}
            onRequestClose={() => props.setOpenModal(false)}
            closeTimeoutMS={250}
            ariaHideApp={false}
        >
            <header className="SidePanel__header">
                <span>{props.title}</span>
                <button
                    data-testid={props.testid + '--close'}
                    onClick={() => props.setOpenModal(false)}
                    className="SidePanel__closeBtn"
                >
                    <IconX />
                </button>
            </header>
            <div className="SidePanel__content" data-testid={props.testid}>
                {props.children}
            </div>
        </Modal>
    );
};

export default SidePanel;
