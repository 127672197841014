import { useTranslation } from 'react-i18next';

import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { Traceability } from 'modules/traceabilities';

import {
    TooltipContentDate,
    TooltipContentLabel,
    TooltipContentValue
} from 'components/BasicComponents/Tooltips/TooltipContent';
import { COLUMNS } from './constants/columns';

interface MetadataProps {
    traceability: Traceability;
}

const Metadata = ({ traceability }: MetadataProps) => {
    const { format } = useDateFormatter();
    const [t] = useTranslation();

    const columnData = COLUMNS.find((column) => column.name === 'metadata') || null;
    if (!columnData) return null;

    const Status = () => (
        <span className={`TraceabilitiesTable__status TraceabilitiesTable__status--${traceability.status}`}></span>
    );

    return (
        <td className="TraceabilitiesTable__metadata">
            <Tooltip
                offset={{ bottom: 8 }}
                msg={
                    <>
                        <TooltipContentDate>
                            {t('latestUpdate', { ns: 'promotion' })} {format(traceability.updateDate, 'DD/MM/YYYY')}
                            <p>{traceability.updatedBy}</p>
                        </TooltipContentDate>
                        <TooltipContentValue>#{traceability.id}</TooltipContentValue>
                        <TooltipContentLabel className="TraceabilitiesTable__metadata-tooltip-label">
                            {t(`status.${traceability?.status}`, { ns: 'traceabilities', defaultValue: null })}{' '}
                            <Status />
                        </TooltipContentLabel>
                    </>
                }
            >
                <p className="TraceabilitiesTable__metadata-Date">
                    <span data-testid="Traceability__date">{format(traceability.date, 'DD/MM/YYYY')}</span>
                    {traceability.status !== 'COMPLETED' && <Status />}
                </p>
                <p
                    className="TraceabilitiesTable__metadata-displayed-value"
                    data-testid="Traceability__displayed-value"
                >
                    {traceability.updatedBy || (
                        <span className="TraceabilitiesTable__metadata-displayed-value--nd">N/D</span>
                    )}
                </p>
            </Tooltip>
        </td>
    );
};

export default Metadata;
