import { useEffect, useRef } from 'react';

export function useComparativeHeight() {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateHeight = (currentHeaderHeight?: number) => {
            if (!ref.current) return;

            const topbarHeight = 55;
            let headerHeight = currentHeaderHeight;

            if (!headerHeight) {
                const header = document.getElementById('Comparative__header');
                headerHeight = currentHeaderHeight || header?.clientHeight || 64;
            }

            ref.current.style.height = `${window.innerHeight - topbarHeight - headerHeight}px`;
        };

        updateHeight();
        window.addEventListener('resize', () => updateHeight());

        const header = document.getElementById('Comparative__header');
        if (!header) return;

        const resizeObserver = new ResizeObserver((entries) => {
            // since we are observing only a single element, so we access the first element in entries array
            const headerHeight = entries[0].borderBoxSize[0].blockSize || 64;
            updateHeight(headerHeight);
        });

        resizeObserver.observe(header);

        return () => {
            window.removeEventListener('resize', () => updateHeight());
            resizeObserver.unobserve(header);
        };
    }, []);

    return { ref };
}
