/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from 'zod';

export const SupportFormSchema = z.object({
    topic: z.string().min(1, { message: 'validations:required' }),
    title: z.string().min(1, { message: 'validations:required' }).max(50),
    description: z.string().min(1, { message: 'validations:required' }).max(255),
    allowSendData: z.boolean().optional()
});

export type SupportFormSchema = z.infer<typeof SupportFormSchema>;
