import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import enterpriseServices from '../../../../../../api/enterprise/enterprise.services';
import { withAuth } from '../../../../../../context/AuthProvider';
import InputText from '../../../../../BasicComponents/Inputs/InputText';
import SubmitButton from '../../../../../BasicComponents/Buttons/SubmitButton';
import ResultadoCarga from '../../../../../BasicComponents/Messages/ResultadoCarga';
import Spinner from '../../../../../BasicComponents/Spinner/Spinner';
import MessageNotComplete from '../../../../../BasicComponents/Messages/MessageNotComplete';
import { allFieldsComplete } from '../../../../../../utils/helpers/general.helpers';

const NewEnterpriseForm = ({ setToggleFormEnterprise }) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [message, setMessage] = useState('');
    const [messageNotComplete, setMessageNotComplete] = useState('');
    const [validation, setValidation] = useState(false);

    const INITIAL_STATE = {
        fiscalName: '',
        comercialName: '',
        CIF: ''
    };

    const [enterprise, setEnterprise] = useState(INITIAL_STATE);

    const handleInputChange = ({ target }) => {
        setEnterprise({
            ...enterprise,
            [target.name]: target.value
        });
    };

    const createMessage = (newEnterprise) => {
        if (newEnterprise?.status === 201) {
            setMessage({ success: true, text: t('successNewEnterprise', { ns: 'newPromotion' }) });
            setEnterprise(INITIAL_STATE);

            setTimeout(() => {
                setToggleFormEnterprise(false);
            }, 1500);
        } else {
            const errorMessage = newEnterprise?.data?.description || t('errorNewEnterprise', { ns: 'newPromotion' });
            setMessage({ success: false, text: errorMessage });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        setLoading(true);
        if (validationForm() && validationCif()) {
            const objEnterprise = {
                cif: CIF,
                nombreComercial: comercialName,
                nombreFiscal: fiscalName
            };
            const newEnterprise = await enterpriseServices.createEnterprise(objEnterprise);
            createMessage(newEnterprise);
        }
        setLoading(false);
        setSubmitting(false);
    };

    const validationForm = () => {
        if (allFieldsComplete(enterprise)) {
            setValidation(false);
            setMessageNotComplete('');
            return true;
        } else {
            setValidation(true);
            setMessageNotComplete(t('notComplete', { ns: 'errors' }));
            return false;
        }
    };

    const validationCif = () => {
        const regexCif = '^[a-zA-Z]{1}\\d{7}[a-zA-Z0-9]{1}$';
        const isCIFValid = CIF.match(regexCif);
        if (isCIFValid === null) {
            setMessageNotComplete(t('cifNotValid', { ns: 'errors' }));
            return false;
        } else {
            setMessageNotComplete('');
            return true;
        }
    };

    const { fiscalName, comercialName, CIF } = enterprise;

    return (
        <form onSubmit={handleFormSubmit}>
            <InputText
                label={t('fiscalName', { ns: 'newPromotion' })}
                id="fiscalName--enterprise"
                name="fiscalName"
                value={fiscalName}
                onChange={handleInputChange}
                validacion={validation}
                required
            />

            <InputText
                label={t('comercialName', { ns: 'newPromotion' })}
                id="comercialName--enterprise"
                name="comercialName"
                value={comercialName}
                onChange={handleInputChange}
                validacion={validation}
                required
            />

            <InputText
                label={t('CIF', { ns: 'newPromotion' })}
                id="CIF--enterprise"
                name="CIF"
                value={CIF}
                onChange={handleInputChange}
                validacion={validation}
                required
            />
            {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
            <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={submitting} />
            {loading && <Spinner />}

            {message?.text && <ResultadoCarga text={message.text} success={message.success} setMessage={setMessage} />}
        </form>
    );
};
export default withAuth(NewEnterpriseForm);
