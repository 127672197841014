import { Redirect, Switch } from 'react-router-dom';

import AnonRoute from 'components/routes/AnonRoute';
import AuthLayout from './components/AuthLayout';
import AuthHelp from './pages/AuthHelp/AuthHelp';
import Login from './pages/Login';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import ResetPasswordChange from './pages/ResetPasswordChange';
import Signup from './pages/Signup';

const Auth = () => {
    return (
        <AuthLayout>
            <Switch>
                <AnonRoute component={Login} path="/account/login" exact />
                <AnonRoute component={ResetPasswordRequest} path="/account/reset-password/request" exact />
                <AnonRoute component={ResetPasswordChange} path="/account/reset-password/change/:key" exact />
                <AnonRoute component={Signup} path="/account/signup/:key" exact />
                <AnonRoute component={AuthHelp} path="/account/help" exact />
                <Redirect to="/account/login" />
            </Switch>
        </AuthLayout>
    );
};

export default Auth;
