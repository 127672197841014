import { NotificationSetting } from 'modules/notification-settings/domain/NotificationSetting';
import { NotificationSettingsStore } from 'modules/notification-settings/domain/NotificationSettingsStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Store = NotificationSettingsStore; // Define Store here

export const saveNotificationSetting =
    (notificationSetting: NotificationSetting, promotionId: number): Thunk<Store> =>
    async (store, { api }) => {
        try {
            store.set((draft) => {
                draft.loading = 'updating';
                draft.updatingId = notificationSetting.id;

                draft.invalid = {};
                draft.error = null;
            });

            await api.save(notificationSetting, promotionId);

            store.set((draft) => {
                draft.updatingId = null;
                draft.loading = 'succeeded';
            });
        } catch (e) {
            const error = e as Error;

            store.set((draft) => {
                draft.updatingId = null;
                draft.loading = 'failed';

                draft.invalid[notificationSetting.id] = error;
            });

            throw error;
        }
    };
