import { Reducers, createStore } from 'modules/shared/domain/store/createStore';
import { WasteFlowStore } from '../domain/WasteFlowStore';

export const wasteFlowStore = createStore({
    reducers: {} as Reducers<WasteFlowStore>,
    initialState: {
        wasteFlows: []
    },
    thunks: {}
});
