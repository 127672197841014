import fetcher from '../../../lib/fetcher';

export const getMultiplesDocuments = async (promotionId, ids) => {
    const URL = `/api/documents/promotion/${promotionId}?id.in=${ids}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
