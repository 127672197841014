import { JsonSchema } from 'modules/shared';

export type AGGridColumn = {
    headerName: string;
    field: string;
    cellDataType?: string;
    children?: AGGridColumn[];
};

type JsonSchemaType = 'string' | 'number' | 'integer' | 'object' | 'array' | 'boolean' | null;

export function convertJSONSchemaToAGGrid(jsonSchema: JsonSchema): AGGridColumn[] {
    const getCellDataType = (type, format) => {
        if (format !== undefined && format === 'date') return 'dateType';
        if (type !== undefined && (type as JsonSchemaType) === 'string') return 'text';

        return type || 'text'; // text as default
    };

    const processProperties = (properties: any, parentKey: string = ''): AGGridColumn[] => {
        return Object.entries(properties).map(([key, value]: [string, any]) => {
            const fieldName = parentKey ? `${parentKey}.${key}` : key;
            const column: AGGridColumn = {
                field: fieldName,
                headerName: value.title || key,
                cellDataType: getCellDataType(value.type, value.format)
            };

            // If the property has nested properties, process them as children
            if (value.properties) {
                column.children = processProperties(value.properties, fieldName);
            }

            return column;
        });
    };

    if (jsonSchema.properties) {
        return processProperties(jsonSchema.properties);
    }

    throw new Error('Invalid JSON Schema format');
}
