import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { TooltipContentLabel } from 'components/BasicComponents/Tooltips/TooltipContent';
import { IconSparkles } from 'icons/IconSparkles';
import { Trans } from 'react-i18next';
import './AutomaticReadingAiBadge.scss';

type AutomaticReadingAiBadgeProps = {
    className?: string;
};

const AutomaticReadingAiBadge = (props: AutomaticReadingAiBadgeProps) => {
    return (
        <>
            <Tooltip
                msg={
                    <TooltipContentLabel>
                        <Trans i18nKey="automaticReading:iaBadgeTooltip" components={{ br: <br /> }} />
                    </TooltipContentLabel>
                }
            >
                <p className={`AutomaticReadingAiBadge ${props.className || ''}`}>
                    <IconSparkles />
                    <p>WALLE&nbsp;&nbsp;AI</p>
                </p>
            </Tooltip>
        </>
    );
};

export default AutomaticReadingAiBadge;
