import MultiProvider from 'lib/MultiProvider';
import { httpNotificationSettingsApi } from 'modules/notification-settings/infrastructure/api/httpNotificationSettingsApi';
import { NotificationSettingsProvider } from 'modules/notification-settings/infrastructure/react/notificationSettingsContext';
import { WasteFlowProvider, httpWasteFlowApi } from 'modules/waste-flow';
import WasteRegisterForm, { WasteRegisterFormProps } from './WasteRegisterForm';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: WasteRegisterFormProps) => (
    <MultiProvider
        providers={[
            <WasteFlowProvider api={httpWasteFlowApi} />,
            <NotificationSettingsProvider api={httpNotificationSettingsApi} />
        ]}
    >
        <WasteRegisterForm {...props} />
    </MultiProvider>
);
