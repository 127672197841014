import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import './LerAlert.scss';
import { TooltipContentLabel } from 'components/BasicComponents/Tooltips/TooltipContent';
import { Trans, useTranslation } from 'react-i18next';
import { IconAlertTriangle } from '@tabler/icons';

type LerAlertProps = {
    code?: string;
};

const LerAlert = (props: LerAlertProps) => {
    const { code = '' } = props;
    const [t] = useTranslation();

    const twoFirstDigits = code.slice(0, 2);

    const firstDigis = `lerAlert.${twoFirstDigits}`;
    const hasMsg = !!t(firstDigis, { defaultValue: null });
    if (!hasMsg) return null;

    return (
        <Tooltip
            className="LerAlert__tooltip"
            msg={
                <TooltipContentLabel className="LerAlert__tooltip-msg">
                    <Trans i18nKey={firstDigis} components={{ bold: <strong />, br: <br />, em: <em /> }} />
                </TooltipContentLabel>
            }
        >
            <IconAlertTriangle size={22} strokeWidth={1.5} />
        </Tooltip>
    );
};

export default LerAlert;
