import { useState, useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../../context/AuthProvider';
import { customStyles } from '../../../BasicComponents/Select-Autocomplete/helpers';
import userServices from '../../../../api/user/user.services';

const RoleAutocomplete = ({ required, values, setValues, validacion, isCoCircular }) => {
    const [notComplete, setNotComplete] = useState(false);
    const [t] = useTranslation();
    const hasMore = (options) => {
        return options.length === 20 ? true : false;
    };

    const loadOptions = async (search, loadedOptions, { page }) => {
        let filter = search ? `?descripcion.contains=${search}` : `?page=${page}`;
        let options = await userServices.getRoleTypes(filter);

        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const emptyInput = () => {
        if (validacion === true && required && roleType === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    useEffect(() => {
        emptyInput();
    }, [validacion]);

    const selectOption = (item) => {
        setValues({
            ...values,
            roleType: item
        });
    };

    const { roleType } = values;

    return (
        <div className="input__container">
            <label className="input__label">
                {t('rol', { ns: 'users' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={loadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.descripcion}
                getOptionValue={(option) => option}
                placeholder=""
                value={roleType}
                additional={{
                    page: 0
                }}
                styles={customStyles(notComplete)}
            />
        </div>
    );
};
export default withAuth(RoleAutocomplete);
