import { withAuth } from 'context/AuthProvider';
import ActionMenuDocumentShow from '../ActionMenuDocumentShow';
import SiteDocument from '../SiteDocument';

import './Signage.scss';
import WasteSignage from './components/WasteSignage/WasteSignage';
import { useSignageDocInfo } from './hooks/useSignageDocInfo';
import { useWasteSignagePrint } from './hooks/useWasteSignagePrint';
import { useWastePostersStore } from './store/wastePosters';

const Signage = (props) => {
    const [state, actions] = useWastePostersStore();
    const docInfo = useSignageDocInfo();
    const { printTargetRef, handlePrint } = useWasteSignagePrint({ docInfo });

    const showWasteSignagePostersPrintView = async () => {
        await actions.loadPosters();
        handlePrint();
    };

    return (
        <>
            <SiteDocument
                dataTestid="signageDocuments"
                siteDocument={docInfo}
                customActions={
                    <>
                        <ActionMenuDocumentShow onClick={showWasteSignagePostersPrintView} />
                    </>
                }
            />
            <div className="Signage__printView">
                <WasteSignage ref={printTargetRef} posters={state.posters} promocion={props.promocion} />
            </div>
        </>
    );
};

export default withAuth(Signage);
