import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconLogout, IconUser } from '@tabler/icons';
import { Menu, MenuItem } from '@szhsin/react-menu';

import { useAuth } from 'modules/auth';
import { withAuth } from '../../../context/AuthProvider';
import '@szhsin/react-menu/dist/core.css';
import '@szhsin/react-menu/dist/index.css';

const MenuDetailTopBar = ({ user }) => {
    const [, auth] = useAuth();
    const [t] = useTranslation();

    return (
        <div>
            <Menu
                menuClassName="topbar__menu"
                direction="bottom"
                align="center"
                position="anchor"
                arrow={true}
                menuButton={
                    <div className="topbar__user">
                        {user?.firstName !== null && user?.lastName !== null ? (
                            <>
                                <p className="topbar__userName">
                                    {user?.firstName} {user?.lastName}
                                </p>
                                <p className="iconUser">
                                    {user?.firstName?.charAt(0)?.toUpperCase()}
                                    {user?.lastName?.charAt(0)?.toUpperCase()}
                                </p>
                            </>
                        ) : (
                            <>
                                <p>{user?.login}</p>
                                <p className="iconUser">
                                    <IconUser size={24} stroke={1.5} />
                                </p>
                            </>
                        )}
                    </div>
                }
            >
                <Link to="/profile">
                    <MenuItem className="topbar__item">
                        <IconUser size={18} stroke={1.5} />
                        <span>
                            {t('my.upperCase', { ns: 'common' })} {t('profile.lowerCase', { ns: 'common' })}
                        </span>
                    </MenuItem>
                </Link>
                <MenuItem className="topbar__item" onClick={auth.logout}>
                    <IconLogout size={18} stroke={1.5} />
                    <span>{t('logOut', { ns: 'common' })}</span>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default withAuth(MenuDetailTopBar);
