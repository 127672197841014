import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { renderElementAsFile } from 'lib/renderElementAsFile';
import { logger } from 'logger';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useAuth } from 'modules/auth';
import { Ticket } from 'modules/support/domain/ticket/Ticket';
import { useSupportStore } from 'modules/support/infrastructure/react/SupportContext';
import { SupportFormProps } from '../SupportForm';
import { SupportFormSchema } from '../SupportFormSchema';

export const useSupportForm = (props: SupportFormProps) => {
    const [, { sendTicket }] = useSupportStore();
    const [{ user }] = useAuth();
    const location = useLocation();
    const [, analytics] = useAnalyticsStore();

    const [sent, setSent] = useState(false);
    const [ticket, setTicket] = useState<Ticket>();
    const [error, setError] = useState<Error>();

    const form = useForm<SupportFormSchema>({
        resolver: zodResolver(SupportFormSchema, { async: true }),
        defaultValues: { allowSendData: true }
    });

    const submit = form.handleSubmit(async (data) => {
        setSent(false);
        setError(undefined);
        analytics.event(AnalyticsEvents.SUPPORT_REQUEST_CLICKED);

        if (!user) throw new Error('User not found');

        const screen = data.allowSendData
            ? await renderElementAsFile(document.getElementById('root') || document.body, 'screenshot.png')
            : undefined;

        const log =
            logger.log.length > 0
                ? new File([JSON.stringify(logger.log)], 'log.json', { type: 'application/json' })
                : undefined;

        const [, promotionId] = location.pathname.match(/\/promotion\/(\d+)/) || [];

        const [ticket, error] = await sendTicket({
            title: data.title,
            description: data.description,
            topic: data.topic,
            extra: {
                url: window.location.href,
                attachments: [
                    // if screen, add to attachments
                    ...(screen ? [screen] : []),
                    ...(log ? [log] : [])
                ]
            },
            user,
            promotion: {
                id: promotionId
            }
        });

        setSent(true);

        if (error) {
            setError(error);
            setTicket(undefined);
            return;
        }

        if (ticket) {
            setTicket(ticket);
            setError(undefined);
            analytics.event(AnalyticsEvents.SUPPORT_REQUEST_SENT);
            props.onSendedSuccess?.();
            return;
        }
    });

    const success = form.formState.isSubmitSuccessful;
    const isSubmitting = form.formState.isSubmitting;

    return { form, sent, ticket, success, error, isSubmitting, submit };
};
