import { AGGridColumn, convertJSONSchemaToAGGrid } from 'lib/json-schema/helpers/convertJSONSchemaToAGGrid';
import { convertJSONSchemaToTranslationObject } from 'lib/json-schema/helpers/convertJSONSchemaToTranslationObject';
import { Thunk } from 'modules/shared/domain/store/createStore';
import { WasteRegisterStore } from '../../domain/WasteRegisterStore';

type Store = WasteRegisterStore;

export const loadWasteRegisterTableInfo =
    (
        promotionId: number
    ): Thunk<Store, { headers: AGGridColumn[]; translationObject: Record<string, string> } | undefined> =>
    async (store, { api }) => {
        try {
            const schema = await api.findComputedSchemaByPromotionId({ promotionId });

            if (!schema) throw new Error('Error loading schema');

            const mappedHeaders = convertJSONSchemaToAGGrid(schema);
            const translationObject = convertJSONSchemaToTranslationObject(schema);

            return { headers: mappedHeaders, translationObject };
        } catch (e) {
            const error = e as Error;
            console.error(error);

            store.set((state) => {
                state.error = error;
                state.loading = 'failed';
            });
        }
    };
