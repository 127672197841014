export const createFilteredAndSortedDocumentTypes = (documents) => {
    // order doc types alfabethically asc
    let docs = documents?.sort((doc1, doc2) => doc1.name.localeCompare(doc2.name));

    // order documents by date desc
    docs.map((doc) => {
        const docsSortedByDate = doc?.documents?.sort((doc1, doc2) => doc2.date.localeCompare(doc1.date));

        return {
            ...docs,
            documents: docsSortedByDate
        };
    });

    // hide non visible type docs (even for cocircular)
    docs = docs?.filter(isVisible);

    return docs;
};

const isVisible = (document) => document.visible;
