import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { IconCheck, IconPencil, IconTag, IconTrash, IconX } from '@tabler/icons';
import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import IconButton from 'components/BasicComponents/Buttons/Small/IconButton';
import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import InputText from 'components/BasicComponents/Inputs/InputText';
import ColorSelectorOption from 'components/BasicComponents/Select/CustomOptions/ColorSelectorOption';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { Tag, TagCreator, createTag, tagsHooks } from 'modules/tags';
import { TagColors } from 'modules/tags/domain/TagColors';
import TagBadge from '../../../../components/TagBadge';
import './TagManagerItem.scss';
import PromotionSelectAutocomplete from './components/PromotionSelectAutocomplete';

interface TagManagerItemProps {
    tag?: Tag;
    enterpriseId: TagCreator['enterpriseId'];
    defaults?: Partial<Tag>;
    onSaved?: (tag: Tag) => void;
    onClose?: () => void;
    onRemoved?: (tag: Tag) => void;
}

const TagManagerItem = (props: TagManagerItemProps) => {
    const mode = props.tag?.id ? 'edit' : 'create';

    const [t] = useTranslation('tags');
    const save = tagsHooks.useSave();
    const remove = tagsHooks.useRemove();

    const [tag, setTag] = useState<Tag>(props.tag || createTag({ ...(props.defaults || {}), id: uuid() }));
    const [canEdit, setCanEdit] = useState(mode === 'create');

    const updateTag = (values: Partial<Tag>) => {
        const updatedTagData = { ...(tag || {}), ...values } as Tag;
        setTag(updatedTagData);
    };

    const saveTag = async (e) => {
        e.preventDefault();
        if (!tag) return;

        await save.mutate({
            id: tag.id || null,
            color: tag.color || null,
            name: tag.name || null,
            enterpriseId: props.enterpriseId || null,
            projectIds: tag.projects?.map((project) => project.id) || []
        });

        props.onSaved?.(tag);
        if (mode === 'edit') setCanEdit(false);
        if (mode === 'create') setTag(createTag({ ...(props.defaults || {}), id: uuid() }));
    };

    // Refresh projects when enterpriseId changes
    useEffect(() => {
        updateTag({ projects: [] });
    }, [props.enterpriseId]);

    // Uptaes tag when props.tag changes
    useEffect(() => {
        if (props.tag) setTag(props.tag);
    }, [props.tag]);

    const modifiers = [
        `TagManagerItem--${mode}`,
        canEdit ? 'TagManagerItem--expanded' : '',
        tag.projects?.length ? 'TagManagerItem--hasProjects' : ''
    ];

    return (
        <>
            <form
                className={`TagManagerItem ${modifiers.join(' ')}`}
                onSubmit={saveTag}
                data-testid={`tag-${props.tag?.name || 'new'}`}
            >
                <header className="TagManagerItem__header">
                    <div className="TagManagerItem__info">
                        {!canEdit && <TagBadge name={tag.name} color={tag.color} />}
                        {canEdit && (
                            <>
                                <InputText
                                    onlyRead={!canEdit}
                                    label={t('manager.tag.name')}
                                    placeholder={t('manager.tag.name')}
                                    value={tag?.name || ''}
                                    onChange={({ target }) => updateTag({ name: target.value })}
                                    inputContainer="--label-hidden"
                                    required
                                    // Just to ignore optional fields
                                    {...({} as any)}
                                />
                                <SelectAutocomplete
                                    name="color"
                                    className="--label-hidden"
                                    label={t('manager.tag.color')}
                                    value={{ value: tag.color, label: <ColorSelectorOption color={tag.color} /> }}
                                    onChange={(option) => {
                                        if (!option) return;
                                        updateTag({ color: (option as SelectOption).value });
                                    }}
                                    loadOptions={async (search) => ({
                                        hasMore: false,
                                        options: Object.values(TagColors)
                                            .filter((color) => color.includes(search))
                                            .map((color) => ({
                                                value: color,
                                                label: <ColorSelectorOption color={color} />
                                            }))
                                    })}
                                />
                            </>
                        )}
                    </div>
                    <div className="TagManagerItem__projectCounter">
                        <IconTag size={20} stroke={1.5} />
                        <span>{t(`manager.tag.projectsCount`, { count: tag?.projects?.length || 0 })}</span>
                    </div>
                    <div className="TagManagerItem__actions">
                        {mode === 'edit' && !canEdit && (
                            <>
                                <IconButton
                                    ariaLabel={t('manager.operations.START_EDIT.name')}
                                    icon={<IconPencil size={16} />}
                                    action={() => setCanEdit(true)}
                                    title="edit"
                                    // Just to ignore optional fields
                                    {...({} as any)}
                                />
                                <ActionConfirm
                                    title={t(`manager.operations.REMOVE.name`)}
                                    message={t(`manager.operations.REMOVE.message`, { tag: tag?.name })}
                                    onConfirm={async () => {
                                        await remove.mutate({ id: (props.tag as Tag).id });
                                        props.onRemoved?.(props.tag as Tag);
                                    }}
                                >
                                    <IconButton
                                        ariaLabel={t('manager.operations.REMOVE.name')}
                                        icon={<IconTrash size={16} />}
                                        title="delete"
                                        // Just to ignore optional fields
                                        {...({} as any)}
                                    />
                                </ActionConfirm>
                            </>
                        )}
                        {canEdit && (
                            <>
                                <IconButton
                                    ariaLabel={t('manager.operations.CANCEL_EDIT.name')}
                                    icon={<IconX size={16} />}
                                    title="close"
                                    action={() => {
                                        setCanEdit(false);
                                        props.onClose?.();
                                    }}
                                    // Just to ignore optional fields
                                    {...({} as any)}
                                />
                                <IconButton
                                    type="submit"
                                    ariaLabel={t('manager.operations.SAVE.name')}
                                    icon={<IconCheck size={16} />}
                                    title="save"
                                    // Just to ignore optional fields
                                    {...({} as any)}
                                />
                            </>
                        )}
                    </div>
                </header>

                {canEdit && (
                    <div className="TagManagerItem__detail">
                        <PromotionSelectAutocomplete
                            key={props.enterpriseId}
                            label={t('manager.tag.promotionTags')}
                            value={tag?.projects || []}
                            onChange={(projects) => updateTag({ projects: projects as Tag['projects'] })}
                        />
                    </div>
                )}
            </form>
        </>
    );
};

export default TagManagerItem;
