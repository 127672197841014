import { InvalidCriteriaException } from 'modules/di-extraction/domain/exceptions/InvalidCriteriaException';
import { Thunk } from 'modules/shared/domain/store/createStore';
import { DiExtractionCriteria } from '../../domain/DiExtractionCriteria';
import { DiExtractionStore } from '../../domain/DiExtractionStore';
import { validateMassiveActionCriteria } from '../../domain/validations/validateMassiveActionCriteria';
import { DiExtractionUpdates, fulfillUpdates, rejectUpdates, startUpdates } from './update.utils';

export const rejectManyDiExtractions =
    (criteria: DiExtractionCriteria): Thunk<DiExtractionStore> =>
    async (store, { api }) => {
        try {
            validateMassiveActionCriteria(criteria);

            store.set(startUpdates(criteria.ids));

            await api.rejectManyDiExtractions(criteria);
            const { results } = await api.getDiExtractions(criteria);

            const updates = criteria.ids?.reduce(
                (updates, id) => ({
                    ...updates,
                    [id]: results.find((result) => result.id === id) || {}
                }),
                {} as DiExtractionUpdates
            );

            store.set(fulfillUpdates(updates));
        } catch (e) {
            if (e instanceof InvalidCriteriaException) throw e;
            store.set(rejectUpdates(criteria.ids, e as Error));
        }
    };
