import fetcher from 'lib/fetcher';
import { Tag } from 'modules/tags/domain/Tag';
import { TagsApi } from '../../domain/TagsApi';

export const httpTagsApi: TagsApi = {
    async getTags(params) {
        const { data } = await fetcher.get('api/tags', { params });

        const tags = (data.content as Tag[]).sort((a, b) => a.name.localeCompare(b.name));

        return tags;
    },

    async saveTag(source) {
        await fetcher.put('api/tags', source);
    },

    async removeTag(source) {
        await fetcher.delete(`api/tags/${source.id}`);
    },

    async attachTagToProject(payload) {
        await fetcher.patch(`api/tags/attach/${payload.projectId}`, { id: payload.tagId });
    },

    async detachTagFromProject(payload) {
        await fetcher.patch(`api/tags/detach/${payload.projectId}`, { id: payload.tagId });
    }
};
