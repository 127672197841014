import { useEffect } from 'react';

import { AnalyticsEvents } from 'modules/analytics/domain/AnalyticsEvents';
import { useAnalyticsStore } from '../analyticsContext';

/** Hits analytics event on component load */
export function useAnalyticsEvent(event: AnalyticsEvents) {
    const [{ identification }, analytics] = useAnalyticsStore();

    useEffect(() => {
        if (!identification) return;
        analytics.event(event);
    }, [identification]);

    return analytics;
}
