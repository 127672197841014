import fetcher from '../../../lib/fetcher';

const _userMessage = {
    success: {
        text: 'message.associate.success',
        ns: 'associateTraceabilities'
    },
    error: {
        text: 'message.associate.error',
        ns: 'associateTraceabilities'
    }
};

export const associateTraceabilities = async (document) => {
    const URL = '/api/tracing/associate-traceability';
    try {
        const response = await fetcher.put(URL, document, { message: _userMessage });
        return response;
    } catch (error) {
        console.log(error);
    }
};
