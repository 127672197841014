import axios from 'axios';
import { dispatchLogout } from '../../lib/LogoutHandler';
import { getToken } from '../../utils/helpers/api.helpers';

class LerServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    getCodLers = async (filter) => {
        try {
            const lers = await this.services.get(`/api/lers${filter}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return lers.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getLerTypes = async (filter = '') => {
        try {
            const lerTypes = await this.services.get(`/api/ler-type${filter}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return lerTypes.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };
}

const lerServices = new LerServices();
export default lerServices;
