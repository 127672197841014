// eslint-disable-next-line import/named
import { CartesianMarkerProps } from '@nivo/core';
// eslint-disable-next-line import/named
import { Scale } from '@nivo/scales';
import { ResponsiveLine } from '@nivo/line';
import { useTranslation } from 'react-i18next';

import TooltipContent, {
    TooltipContentDate,
    TooltipContentLabel,
    TooltipContentValue
} from 'components/BasicComponents/Tooltips/TooltipContent';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { usePromotion } from 'modules/promotion';
import { KPIStatus, PromotionKPIsGoalMet } from 'modules/promotion-kpis';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import { capitalizeFirstLetter } from 'utils/helpers/text.helpers';
import './KPIHistoricalChart.scss';
import { KPIHistoricalChartStyles } from './KPIHistoricalChart.styles';
import { historicalKPItoSerieAdapter } from './historicalKPItoSerieAdapter';

export interface KPIHistoricalChartProps {
    label?: string;
    data?: Array<{
        month: string;
        value: number;
    }>;
    goals?: Array<PromotionKPIsGoalMet & { type: 'MIN' | 'MAX' }>;
    status?: KPIStatus | null;
    unit?: string;
    addtionalMarkers?: CartesianMarkerProps[];
    yScale?: Scale;
}

const KPIHistoricalChart = (props: KPIHistoricalChartProps) => {
    const { data = [], goals = [] } = props;
    const statusColor = KPIHistoricalChartStyles.colors[props.status || 'indeterminate'];
    const [lastDataEntry] = data?.slice(-1) || [];

    const [t] = useTranslation('kpis');
    const { format } = useDateFormatter();
    const [{ promotion }] = usePromotion();
    if (!promotion) return null;

    const seriesData = historicalKPItoSerieAdapter({ data, goals, color: statusColor, promotion });

    const highestValue = Math.max(
        ...(props.addtionalMarkers?.map((marker) => marker.value as number) || []),
        ...data.map((entry) => entry.value),
        ...goals.map((goal) => goal.value.value)
    );

    const promotionInProgress = promotion?.progress < 100;

    const startDate = new Date(`${promotion?.fechaInicio.slice(0, 7)}-01`);
    const endDate = new Date(`${promotion?.fechaFin.slice(0, 7)}-01`);
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    return (
        <div className="KPIHistoricalChart" role="application">
            <div className="KPIHistoricalChart__chart">
                <ResponsiveLine
                    data={seriesData}
                    isInteractive={true}
                    sliceTooltip={(point) => {
                        const month = point.slice.points[0].data.x as string;
                        const value = point.slice.points.find((p) => p.serieId === 'data')?.data.y;

                        const goalPoints = point.slice.points.filter(
                            (p) => `${p.serieId}`.includes('MIN') || `${p.serieId}`.includes('MAX')
                        );

                        return (
                            <TooltipContent>
                                <TooltipContentDate>
                                    {capitalizeFirstLetter(format(month, 'MMMM YYYY'))}
                                </TooltipContentDate>
                                <TooltipContentValue>
                                    {value !== undefined ? (
                                        `${currencyFormatDE(value)}${props.unit || ''}`
                                    ) : (
                                        <span style={{ opacity: 0.35 }}>{t('not-available')}</span>
                                    )}
                                </TooltipContentValue>
                                <TooltipContentLabel>
                                    {goalPoints.map((goalPoint) => {
                                        const [type, severity] = `${goalPoint.serieId}`.split('-');

                                        return (
                                            <div className="KPIHistoricalChart__tooltipGoal">
                                                <svg
                                                    className="KPIHistoricalChart__legend-goal-icon"
                                                    viewBox="0 0 18 18"
                                                >
                                                    <KPIHistoricalChartStyles.GoalPoint
                                                        theme="dark"
                                                        type={type as any}
                                                    />
                                                </svg>
                                                <span> {t(`goalType.${type as any}.${severity}`)}</span>
                                                {goalPoint.data.y} {props.unit || ''}
                                            </div>
                                        );
                                    })}
                                </TooltipContentLabel>
                            </TooltipContent>
                        );
                    }}
                    useMesh={false}
                    margin={{ top: 32, right: 40, bottom: 40, left: 40 }}
                    enableArea={true}
                    areaOpacity={1}
                    enableGridX={false}
                    xScale={{
                        format: '%Y-%m-%d',
                        type: 'time'
                    }}
                    axisBottom={null}
                    axisLeft={{
                        tickValues: 5,
                        tickSize: 0,
                        tickPadding: 12,
                        format: (value) => `${value}${props.unit || ''}`
                    }}
                    enablePoints={true}
                    animate={true}
                    enableSlices="x"
                    pointSize={16}
                    pointBorderWidth={1}
                    pointBorderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.3]]
                    }}
                    pointSymbol={(params) => {
                        const serie = seriesData.find((serie) => serie.color === params.color);

                        if (!serie) return null;
                        if (serie.id === 'timeline') return null;
                        if (serie.id === 'data') return <KPIHistoricalChartStyles.DataPoint status={props.status} />;

                        return (
                            <KPIHistoricalChartStyles.GoalPoint
                                color={serie.color}
                                pointColor={serie.pointColor}
                                type={serie.goal?.type}
                            />
                        );
                    }}
                    defs={[
                        {
                            id: `gradient`,
                            type: 'linearGradient',
                            colors: [
                                {
                                    offset: 0,
                                    color: statusColor + '44'
                                },
                                {
                                    offset: 70,
                                    color: statusColor + '00'
                                }
                            ]
                        },
                        {
                            id: `no-gradient`,
                            type: 'linearGradient',
                            colors: [
                                {
                                    offset: 0,
                                    color: 'transparent'
                                }
                            ]
                        }
                    ]}
                    fill={[
                        { match: { id: 'data' }, id: `gradient` },
                        { match: '*', id: `no-gradient` }
                    ]}
                    yScale={
                        props.yScale || {
                            type: 'linear',
                            min: data.length > 0 ? 'auto' : 0,
                            max: highestValue > 0 ? highestValue * 1.1 : 100
                        }
                    }
                    colors={{ datum: 'color' }}
                    lineWidth={2}
                    markers={[
                        ...(props.addtionalMarkers || []),
                        // Marker for promotion start
                        KPIHistoricalChartStyles.createMarker({
                            axis: 'x',
                            lineStyle: { stroke: '#D5DFEB', strokeWidth: 1, opacity: 0.75 },
                            value: startDate,
                            legendPosition: 'top',
                            legend: t('promotionStart')
                        }),
                        // Marker for last data entry
                        KPIHistoricalChartStyles.createMarker({
                            axis: 'x',
                            value: endDate,
                            legendPosition: 'bottom',
                            legend: capitalizeFirstLetter(format(lastDataEntry?.month, 'MMM YY'))
                        }),
                        // Marker for today if promotion is in progress
                        ...(promotionInProgress && currentDate < endDate && currentDate > startDate
                            ? [
                                  KPIHistoricalChartStyles.createMarker({
                                      axis: 'x',
                                      lineStyle: { stroke: '#D5DFEB', strokeWidth: 1, opacity: 0.75 },
                                      // day 01 of today's month
                                      value: currentDate,
                                      legendPosition: 'top',
                                      legend: t('currentMonth')
                                  })
                              ]
                            : []),
                        // Marker for promotion end
                        KPIHistoricalChartStyles.createMarker({
                            axis: 'x',
                            lineStyle: { stroke: '#D5DFEB', strokeWidth: 1, opacity: 0.75 },
                            value: new Date(`${promotion?.fechaFin.slice(0, 7)}-01`),
                            legendPosition: 'top',
                            legend: t('promotionEnd')
                        })
                    ]}
                    theme={{
                        grid: {
                            line: {
                                stroke: 'rgba(209, 216, 225, 0.50)',
                                strokeWidth: 2,
                                strokeDasharray: '4 4'
                            }
                        },
                        axis: {
                            ticks: {
                                line: {
                                    stroke: 'rgba(209, 216, 225, 0.50)',
                                    strokeWidth: 2
                                },
                                text: KPIHistoricalChartStyles.labelStyles
                            }
                        }
                    }}
                />
            </div>
            <div className="KPIHistoricalChart__legend" data-testid="KPIHistoricalChart__legend">
                {seriesData
                    .filter((serie) => !!serie.goal)
                    .sort((a, b) => a.color.localeCompare(b.color))
                    .map((serie) => {
                        const goal: PromotionKPIsGoalMet & { type: 'MIN' | 'MAX' } = serie.goal;

                        if (!goal) return null;
                        return (
                            <div className="KPIHistoricalChart__legend-goal">
                                <div
                                    className="KPIHistoricalChart__legend-goal-line --short"
                                    style={{ background: serie.color }}
                                />
                                <svg className="KPIHistoricalChart__legend-goal-icon" viewBox="0 0 18 18">
                                    <KPIHistoricalChartStyles.GoalPoint
                                        pointColor={serie.pointColor}
                                        color={serie.color}
                                        type={goal.type as any}
                                    />
                                </svg>
                                <div
                                    className="KPIHistoricalChart__legend-goal-line"
                                    style={{ background: serie.color }}
                                />
                                <span
                                    className="KPIHistoricalChart__legend-goal-text"
                                    data-testid="KPIHistoricalChart__legend-goal-text"
                                >
                                    {t(`goalType.${goal.type as any}.${goal.severity?.toString()}`)}
                                    <span>
                                        &nbsp;
                                        {goal.value.value}
                                        {props.unit}
                                    </span>
                                </span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default KPIHistoricalChart;
