export interface WasteRegisterCriteria {
    promotionIds?: number[];
    page?: number;
    size?: number;
}

export const WASTE_REGISTER_INITIAL_CRITERIA: WasteRegisterCriteria = {
    promotionIds: [],
    page: 0,
    size: 20
};
