import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useOrphanDocuments } from 'modules/orphan-documents';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import EmptyState from 'components/BasicComponents/EmptyState';
import WalleEmail from './WalleEmail';

const WalleList = () => {
    const [t] = useTranslation('walle');
    const [{ data, total, criteria, loading }, { loadEmailsWithOrphanDocuments, unselectOrphanDocuments }] =
        useOrphanDocuments();

    const loadNextPage = () => {
        loadEmailsWithOrphanDocuments({ page: (criteria?.page || 0) + 1 }, { mode: 'merge' });
    };

    useEffect(() => {
        loadEmailsWithOrphanDocuments({ page: 0 }, { mode: 'replace' });
        unselectOrphanDocuments({ unselectAll: true });
    }, []);

    const modifiers = [loading === 'assigning' ? 'WalleList--loading' : ''];

    return (
        <div className={`WalleList ${modifiers.join(' ')}`}>
            <header className="WalleList__header">
                <TituloH1 titulo={`${t('title')} (${total})`} />
            </header>
            <div className="WalleList__results" id="WalleList__results">
                {data.length > 0 ? (
                    <InfiniteScroll
                        dataLength={data.length}
                        next={loadNextPage}
                        hasMore={false} // THIS ENDPOINT HAS NO PAGINATION
                        loader={null}
                        scrollableTarget="WalleList__results"
                    >
                        {data.map((email, i) => (
                            <WalleEmail key={i} email={email} fakeId={i} />
                        ))}
                    </InfiniteScroll>
                ) : (
                    <EmptyState mode="happyEmptyState" subject={t('walleEmails')} />
                )}
            </div>
        </div>
    );
};

export default WalleList;
