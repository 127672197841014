import { ONBOARDING_PATHS } from './OnboardingPaths';

export const ROLETYPE_TO_PATH: {
    [key: string]: {
        desc: string;
        path: keyof typeof ONBOARDING_PATHS;
    };
} = {
    '80251': {
        desc: 'Jefe de Producción',
        path: 'construction'
    },
    '80253': {
        desc: 'Técnico de Campo CoCircular',
        path: 'construction'
    },
    '80256': {
        desc: 'Responsable de Calidad y Medioambiente',
        path: 'quality-and-environment'
    },
    '80257': {
        desc: 'Marketing',
        path: 'directive'
    },
    '80258': {
        desc: 'Dirección General',
        path: 'directive'
    },
    '434201': {
        desc: 'Jefe de Obra',
        path: 'construction'
    },
    '434651': {
        desc: 'Responsable de Administración',
        path: 'administrative'
    },
    '434652': {
        desc: 'Director de Construcción',
        path: 'construction'
    },
    '434653': {
        desc: 'Dirección Facultativa',
        path: 'facultative-direction'
    },
    '435651': {
        desc: 'Project Manager',
        path: 'project-manager'
    },
    '435652': {
        desc: 'ESG Manager',
        path: 'project-manager'
    },
    '441801': {
        desc: 'Responsable de Prevención',
        path: 'quality-and-environment'
    },
    '674301': {
        desc: 'Manager CoCircular',
        path: 'construction'
    },
    '676051': {
        desc: 'Design Manager',
        path: 'directive'
    },
    '676052': {
        desc: 'Construction Manager',
        path: 'construction'
    },
    '840701': {
        desc: 'Encargado de Obra',
        path: 'construction'
    },
    '840702': {
        desc: 'Coordinador de Construcción',
        path: 'construction'
    },
    '840703': {
        desc: 'Jefe de Grupo',
        path: 'directive'
    },
    '840704': {
        desc: 'Director Territorial',
        path: 'directive'
    },
    '912851': {
        desc: 'Asesor Verde',
        path: 'quality-and-environment'
    },
    '926301': {
        desc: 'Dirección de Innovación',
        path: 'directive'
    }
};
