import { individualDocMapper } from '../mappers/individualDocMapper';

export const prepareDocsArray = (files) => {
    const mappedDocsArray = [];

    for (let i = 0; i < files?.length; i++) {
        const file = files[i];
        const mappedDoc = individualDocMapper({
            mimeType: file?.type,
            name: file?.name,
            bytes: file?.bytes
        });
        mappedDocsArray.push(mappedDoc);
    }

    return mappedDocsArray;
};
