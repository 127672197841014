import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import { NotificationSetting } from 'modules/notification-settings/domain/NotificationSetting';
import { useNotificationSettings } from 'modules/notification-settings/infrastructure/react/notificationSettingsContext';
import { usePromotion } from 'modules/promotion';

export const useLoadStakeholders = (stakeholderType: NotificationSetting['data']['stakeholderType']) => {
    const [{ promotion }] = usePromotion();
    const [, , { api }] = useNotificationSettings();

    const [loadStakeholders] = useAsyncOptions({
        request: async ({ search, page }) => {
            return api
                .getStakeholders({ promotionIds: [promotion?.id || NaN], type: stakeholderType, name: search, page })
                .then((stakeholders) => stakeholders);
        },
        adapter: (stakeholder) => ({ value: stakeholder.id, label: stakeholder.name })
    });

    return { loadStakeholders };
};
