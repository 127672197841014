import { NavLink } from 'react-router-dom';

import { useOrphanDocuments } from 'modules/orphan-documents';

import HasPermission from '../../AccessCheckers/HasPermission';

const LinkNavBar = ({
    link,
    externalLink = false,
    Icon,
    size,
    stroke = 1.5,
    title,
    click,
    target,
    hideActiveClassName = false,
    ifRole,
    section,
    authority = true,
    action
}) => {
    const counters = {
        '/admin/walle': useOrphanDocuments()[0].total
    };

    const isActive = (match, location) => {
        const linkLastSegment = link
            .replace('/promotion')
            .split('/')
            .map((segment) => `/${segment}`)
            .reverse()[0];

        const isSubpath = match?.url?.includes(linkLastSegment);

        return isSubpath ? true : match?.isExact;
    };

    const notifications = counters[link] || 0;

    return (
        <li className="navbar__link" onClick={click}>
            {externalLink && (
                <a href={link} className={hideActiveClassName ? '' : 'navbar__link-active'} target={target}>
                    <Icon size={size} stroke={stroke} />
                    <span>{title}</span>
                </a>
            )}
            {ifRole && section && authority && (
                <HasPermission ifRole={ifRole} section={section}>
                    <NavLink
                        to={link}
                        activeClassName={hideActiveClassName ? '' : 'navbar__link-active'}
                        target={target}
                        isActive={isActive}
                    >
                        <Icon size={size} stroke={stroke} />
                        <span>{title}</span>
                        {notifications > 0 && <div className="navbar__notification-badge">{notifications}</div>}
                    </NavLink>
                </HasPermission>
            )}

            {!ifRole && !section && !externalLink && (
                <NavLink
                    to={link ? link : null}
                    activeClassName={hideActiveClassName ? '' : 'navbar__link-active'}
                    target={target}
                    onClick={action}
                >
                    <Icon size={size} stroke={stroke} />
                    <span>{title}</span>
                </NavLink>
            )}
        </li>
    );
};
export default LinkNavBar;
