import { PromotionKPIdGoalExportPayload } from 'modules/promotion-kpis/domain/goals/PromotionKPIdGoalExportPayload';
import { PromotionKPIsStore } from 'modules/promotion-kpis/domain/PromotionKPIsStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Store = PromotionKPIsStore; // Define Store here

export const downloadGoals =
    (criteria: { promotionId: number }): Thunk<Store, PromotionKPIdGoalExportPayload[] | null> =>
    async (store, { api }) => {
        try {
            const data: PromotionKPIdGoalExportPayload[] = await api
                .getAllGoals({ promotionId: criteria.promotionId })
                .then((goals) => goals.map(({ uuid, ...goal }) => goal));

            return data;
        } catch (e) {
            const error = e as Error;
            console.error(error);

            return null;
        }
    };
