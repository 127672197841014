import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InputText from '../../../BasicComponents/Inputs/InputText';
import InputNumber from '../../../BasicComponents/Inputs/InputNumber';
import InputDate from '../../../BasicComponents/Inputs/InputFecha';
import MessageNotComplete from '../../../BasicComponents/Messages/MessageNotComplete';
import { getAdditionalFields } from '../../../../utils/helpers/promotion.helpers';
import TownAutocomplete from './TownAutocomplete';
import PromotionTypeAutocomplete from './PromotionTypeAutocomplete';
import PromotionStateAutocomplete from './PromotionStateAutocomplete';
import './PromotionInformation.scss';

export const InfoDetailsForm = ({
    setPromotion,
    promotion,
    direction,
    setDirection,
    handleInputChange,
    validation,
    messageNotComplete
}) => {
    const [t] = useTranslation();

    const { nombre, num, nima, fechaInicio, fechaFin, endDateCocircular, startDateCocircular, surface, pem, isDemo } =
        promotion;

    const { direccion, postalCode } = direction;

    const handleDirectionChange = ({ target }) => {
        setDirection({
            ...direction,
            [target.name]: target.value
        });
    };

    const crearMaxDate = () => {
        let endDate = new Date(fechaFin);
        let numberOfDaysToAdd = 31;
        endDate.setDate(endDate.getDate() + numberOfDaysToAdd);
        let dd = endDate.getDate();
        let mm = endDate.getMonth() + 1;
        let yyyy = endDate.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }

        endDate = yyyy + '-' + mm + '-' + dd;
        setPromotion({ ...promotion, endDateCocircular: endDate });
    };

    const handleAdditionalFieldsInputChange = ({ target }) => {
        const additionalPropsAux = {
            ...promotion.promotionType.additionalProperties,
            [target.name]: target.value
        };

        setPromotion({
            ...promotion,
            promotionType: {
                ...promotion.promotionType,
                additionalProperties: additionalPropsAux
            }
        });
    };

    useEffect(() => {
        if (fechaFin && endDateCocircular === '') {
            crearMaxDate();
        }
    }, [fechaFin]);

    return (
        <div>
            <div className="promotionInformation__row">
                <InputText
                    className="promotionInformation__input promotionInformation__input-extraLarge"
                    value={nombre}
                    name="nombre"
                    onChange={handleInputChange}
                    label={t('labels.promotionName', { ns: 'newPromotion' })}
                    validacion={validation}
                    required
                />
                <InputNumber
                    className="promotionInformation__input promotionInformation__input-small"
                    value={num}
                    name="num"
                    onChange={handleInputChange}
                    label={t('labels.num', { ns: 'newPromotion' })}
                    validacion={validation}
                    required
                />
                <InputNumber
                    className="promotionInformation__input promotionInformation__input-medium"
                    value={nima}
                    name="nima"
                    onChange={handleInputChange}
                    label={t('labels.nima', { ns: 'newPromotion' })}
                    validacion={validation}
                    formatNumber={false}
                />
            </div>

            <div className="promotionInformation__row">
                <InputText
                    className="promotionInformation__input promotionInformation__input-extraLarge"
                    label={t('labels.direction', { ns: 'newPromotion' })}
                    name="direccion"
                    value={direccion}
                    setValues={setDirection}
                    onChange={handleDirectionChange}
                    validacion={validation}
                    inputContainer="previousInformation__input"
                    required
                />
                <InputText
                    className="promotionInformation__input promotionInformation__input-small"
                    label={t('postalCode', { ns: 'promotion' })}
                    name="postalCode"
                    value={postalCode}
                    setValues={setDirection}
                    onChange={handleDirectionChange}
                    validacion={validation}
                    inputContainer="previousInformation__input"
                    required
                />
                <TownAutocomplete
                    className="promotionInformation__input promotionInformation__input-medium"
                    values={direction}
                    setValues={setDirection}
                    validacion={validation}
                    required
                />
            </div>

            <div className="promotionInformation__row">
                <PromotionStateAutocomplete
                    className="promotionInformation__input promotionInformation__input-medium"
                    values={promotion}
                    setValues={setPromotion}
                    validacion={validation}
                    required
                />

                <PromotionTypeAutocomplete
                    className="promotionInformation__input promotionInformation__input-medium"
                    values={promotion}
                    setValues={setPromotion}
                    validacion={validation}
                    required
                />

                {getAdditionalFields(promotion?.promotionType?.id)?.map((field) => (
                    <InputNumber
                        className="promotionInformation__input promotionInformation__input-small"
                        key={field?.i}
                        value={promotion?.promotionType?.additionalProperties?.[field?.name] || ''}
                        name={field?.name}
                        onChange={handleAdditionalFieldsInputChange}
                        label={t(field?.label, { ns: 'promotion' })}
                        min={0}
                    />
                ))}

                <InputNumber
                    className="promotionInformation__input promotionInformation__input-small"
                    value={surface}
                    name="surface"
                    onChange={handleInputChange}
                    label={t('labels.surface', { ns: 'newPromotion' })}
                    validacion={validation}
                    required
                    min={0}
                />

                <InputNumber
                    className="promotionInformation__input promotionInformation__input-small"
                    value={pem}
                    name="pem"
                    onChange={handleInputChange}
                    label={t('labels.pem', { ns: 'newPromotion' })}
                    validacion={validation}
                />
            </div>

            <div className="promotionInformation__row">
                <InputDate
                    value={fechaInicio}
                    name="fechaInicio"
                    onChange={handleInputChange}
                    label={t('labels.startDate', { ns: 'newPromotion' })}
                    validacion={validation}
                    notLimit={true}
                    required
                />
                <InputDate
                    value={fechaFin}
                    name="fechaFin"
                    onChange={handleInputChange}
                    label={t('labels.endDate', { ns: 'newPromotion' })}
                    validacion={validation}
                    notLimit={true}
                    minDate={fechaInicio ? fechaInicio : ''}
                    required
                />
                <InputDate
                    value={startDateCocircular}
                    name="startDateCocircular"
                    onChange={handleInputChange}
                    label={t('labels.startDateCC', { ns: 'newPromotion' })}
                    validacion={validation}
                    notLimit={true}
                    required
                />
                <InputDate
                    value={endDateCocircular}
                    name="endDateCocircular"
                    onChange={handleInputChange}
                    label={t('labels.endDateCC', { ns: 'newPromotion' })}
                    validacion={validation}
                    notLimit={true}
                    minDate={startDateCocircular ? startDateCocircular : ''}
                    required
                />
            </div>

            <div className="promotionInformation__row">
                <div className="ProductsAndServices__option">
                    <input
                        id="isDemo"
                        name="isDemo"
                        type="checkbox"
                        className="checkbox"
                        checked={isDemo}
                        onChange={() => setPromotion({ ...promotion, isDemo: !isDemo })}
                    />
                    <label htmlFor="isDemo">{t('labels.isDemo', { ns: 'newPromotion' })}</label>
                </div>
            </div>
            {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
        </div>
    );
};
