export const getBarsPerWidthWasteByValorization = (): Array<{ maxWidth: number; bars: number }> => {
    const barsPerWidth = [
        { maxWidth: 250, bars: 1 },
        { maxWidth: 350, bars: 2 },
        { maxWidth: 450, bars: 3 },
        { maxWidth: 550, bars: 4 },
        { maxWidth: 650, bars: 5 },
        { maxWidth: 750, bars: 6 },
        { maxWidth: 900, bars: 7 },
        { maxWidth: 3000, bars: 8 } // high number just in case
    ];
    return barsPerWidth;
};
