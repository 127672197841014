import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { MandatoryFlowsGoalValue, PromotionKPIsGoal } from 'modules/promotion-kpis';
import { KPIGoalValueProps } from './KPIGoalValueProps';

type Type = MandatoryFlowsGoalValue['type'];

const defaultValue: MandatoryFlowsGoalValue = { type: 'COUNT', value: 1, unit: 'TRACEABILITY' };

export const MandatoryFlowsKPIGoalValue = (props: KPIGoalValueProps) => {
    const [t] = useTranslation('KPIsGoalSettings');
    const [value, setValue] = useState<MandatoryFlowsGoalValue>(validateValue(props.value) || defaultValue);

    const options: Array<{ label: string; value: Type }> = [
        { label: 'Con traslados acreditados', value: 'COUNT' },
        { label: 'EGR', value: 'EGR' },
        { label: 'PGR', value: 'PGR' }
    ];

    const updateValue = (v: number) => setValue({ ...value, value: v });

    const updateType = (t: Type) =>
        setValue({
            value: (t === 'COUNT' && 1) || (t === 'EGR' && 90) || (t === 'PGR' && 90) || 0,
            type: t as any,
            unit: t === 'COUNT' ? 'TRACEABILITY' : 'PERCENT'
        });

    useEffect(() => {
        const newVal = validateValue(props.value);
        setValue(newVal || defaultValue);
    }, [props.value]);

    useEffect(() => {
        props.update({ value });
    }, [JSON.stringify(value)]);

    return (
        <div className="KPIGoal__value">
            {(value.type === 'EGR' || value.type === 'PGR') && (
                <InputNumber
                    extraElement={
                        <>
                            <span className="KPIGoal__input-unit">%</span>
                            <span className="KPIGoal__input-prefix">≥</span>
                        </>
                    }
                    name="value"
                    placeholderText={t(`kpi.${props.kpi}.value.value`)}
                    value={value.value || 0}
                    onChange={({ target }) => updateValue(Number(target.value))}
                    {...({} as any)} // Just to ignore optional fields
                />
            )}

            <SelectAutocomplete
                className="KPIGoal__selector"
                placeholder={t(`kpi.${props.kpi}.value.type`)}
                isClearable={false}
                menuPosition="fixed"
                value={options.find((o) => o.value === value.type)}
                onChange={(option) => {
                    if (!option) return;
                    if (Array.isArray(option)) return;
                    updateType(option.value as Type);
                }}
                loadOptions={async () => ({ options })}
                // Just to ignore optional fields
                {...({} as any)}
            />
        </div>
    );
};

const validateValue = (value?: PromotionKPIsGoal['value'] | null): MandatoryFlowsGoalValue | null => {
    if (!value) return null;
    if (value.type === null) return null;
    if (value.unit === null) return null;

    return value as MandatoryFlowsGoalValue;
};
