import fetcher from '../../../lib/fetcher';

export const getTransferOperators = async (filter = '') => {
    const URL = `/api/waste-manager?hasPgcl.equals=true&valuationCode.equals=O01&estadoId.notEquals=5604&sort=empresa,asc${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
