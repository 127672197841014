import { StakeholderInfo } from 'modules/traceabilities/domain/Traceability/Traceability';

export const wasteManagerAdapter = (ppgcl): StakeholderInfo => ({
    id: ppgcl.wasteManagerProGesCodLer.gestora.id.toString(),
    name: ppgcl.wasteManagerProGesCodLer.gestora.empresa,
    nif: ppgcl.wasteManagerProGesCodLer.gestora.identificacionFiscal,
    email: ppgcl.wasteManagerProGesCodLer.gestora.mail,
    nima: ppgcl.wasteManagerProGesCodLer.gestora.nIMA,
    phone: ppgcl.wasteManagerProGesCodLer.gestora.telefono,
    addresses: ppgcl.wasteManagerProGesCodLer.gestora.direccions.map((dir) => ({
        address: dir.direccion,
        postalCode: dir.postalCode,
        city: {
            code: dir.municipio.codigo,
            name: dir.municipio.nombre
        },
        province: {
            code: dir.municipio.provincia.codigo,
            name: dir.municipio.provincia.nombre
        }
    }))
});
