import axios from 'axios';
import { getToken } from '../../utils/helpers/api.helpers';
import { dispatchLogout } from '../../lib/LogoutHandler';

class EnterpriseServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }
    filterEnterpriseName = async (name) => {
        try {
            const response = await this.services.get(`/api/maintenance/enterprise?nombreComercial.contains=${name}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    filterEnterprise = async (url) => {
        try {
            const response = await this.services.get(`/api/maintenance/enterprise${url}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getEnterpriseById = async (id) => {
        try {
            const response = await this.services.get(`/api/maintenance/enterprise?id.equals=${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response?.data?.[0];
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    createEnterprise = async (enterprise) => {
        try {
            const result = await this.services.post(`/api/maintenance/enterprise`, enterprise, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
            return error.response;
        }
    };

    recoveryMetrics = async (filter, { source }) => {
        try {
            const result = await this.services.get(
                `/api/impact/metrics/extended-metrics${filter}`,
                {
                    headers: {
                        Authorization: getToken()
                    }
                },
                {
                    cancelToken: source.token
                }
            );
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };
}

const enterpriseServices = new EnterpriseServices();
export default enterpriseServices;
