import { useTranslation } from 'react-i18next';

import DateRangeFilter from 'components/BasicComponents/Filter/DateRangeFilter/DateRangeFilter';
import FilterBar from 'components/BasicComponents/Filter/FilterBar/FilterBar';
import SelectFilter from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import TextFilter from 'components/BasicComponents/Filter/TextFilter';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import { TraceabilitiesCriteria, useTraceabilities } from 'modules/traceabilities';

import './TraceabilitiesFilters.scss';

const TraceabilitiesFilters = () => {
    const [t] = useTranslation('traceabilities');
    const [{ promotion }] = usePromotion();
    const [, , { api }] = useTraceabilities();

    const promotionId = promotion?.id;

    const [loadLerOptions] = useAsyncOptions({
        request: ({ search }) => api.getLers({ promotionId, search }),
        adapter: (ler) => ({ value: ler.code, label: `${ler.code} - ${ler.kind}` }),
        removePagination: true
    });

    const [loadLerTypeOptions] = useAsyncOptions({
        request: ({ search }) => api.getLerTypes({ promotionId, search }),
        adapter: (lerType) => ({ value: lerType.id, label: lerType.name }),
        removePagination: true
    });

    const [loadWasteManagerOptions] = useAsyncOptions({
        request: ({ search, page }) => api.getWasteManagers({ promotionId, search, page }),
        adapter: (wasteManager) => ({ value: wasteManager.id, label: wasteManager.name })
    });

    const [loadCarrierOptions] = useAsyncOptions({
        request: ({ search, page }) => api.getCarriers({ promotionId, search, page }),
        adapter: (wasteManager) => ({ value: wasteManager.id, label: wasteManager.name })
    });

    const sortOptions: Array<{ label: string; value: TraceabilitiesCriteria['sort'] }> = [
        { label: 'Fecha más reciente', value: 'movementDate,desc' },
        { label: 'Fecha más antigua', value: 'movementDate,asc' },
        { label: 'Tipo de residuo', value: 'lerTypeName,desc' },
        { label: 'Código LER', value: 'lerCode,desc' },
        { label: 'Tratamiento', value: 'valorizationCode,desc' },
        { label: 'Peso más alto', value: 'weight,desc' },
        { label: 'Peso más bajo', value: 'weight,asc' },
        { label: 'Volumen más alto', value: 'volume,desc' },
        { label: 'Volumen más bajo', value: 'volume,asc' },
        { label: 'Coste más alto', value: 'cost,desc' },
        { label: 'Coste más bajo', value: 'cost,asc' }
    ];

    return (
        <FilterBar className="TraceabilitiesFilters">
            <SelectFilter label={t('sort', { ns: 'common' })} name="sort" options={sortOptions} />

            <DateRangeFilter name="date" label={t('date.date', { ns: 'common' })} />
            <SelectFilter
                name="lerCodes"
                label={t('ler.code', { ns: 'common' })}
                loadOptions={loadLerOptions}
                isMultiple
            />
            <SelectFilter
                name="lerTypes"
                label={t('ler.type', { ns: 'common' })}
                loadOptions={loadLerTypeOptions}
                isMultiple
            />
            <SelectFilter
                name="wasteManagers"
                label={t('manager.pl', { ns: 'common' })}
                loadOptions={loadWasteManagerOptions}
                isMultiple
            />
            <SelectFilter
                name="carriers"
                label={t('transporter', { ns: 'common' })}
                loadOptions={loadCarrierOptions}
                isMultiple
            />
            <TextFilter name="updatedBy" label={t('filters.updatedBy')} isClearable={true} />
            <SelectFilter
                label="Traslado acreditado (con DI)"
                name="di"
                options={[
                    { label: 'Acreditado', value: 'certified' },
                    { label: 'No acreditado', value: 'not-certified' }
                ]}
            />
            <SelectFilter
                label={t('valorization', { ns: 'common' })}
                name="valorization"
                options={[
                    { label: 'Con porcentaje conocido', value: 'with' },
                    { label: 'Con porcentaje desconocido', value: 'without' }
                ]}
            />

            <SelectFilter
                label={t('filters.excludeLands', { ns: 'traceabilities' })}
                name="excludeLands"
                options={[{ label: 'Sí', value: true }]}
            />
        </FilterBar>
    );
};

export default TraceabilitiesFilters;
