import { AnalyticsApi } from 'modules/analytics/domain/AnalyticsApi';
import { googleAnalyticsApi } from './googleAnalyticsApi';
import { hotjarAnalyticsApi } from './hotjarAnalyticsApi';

export const multipleAnalyticsApi: AnalyticsApi = {
    init: async () => {
        hotjarAnalyticsApi.init();
        googleAnalyticsApi.init();
    },
    event: async (...args) => {
        hotjarAnalyticsApi.event(...args);
        googleAnalyticsApi.event(...args);
    },
    pageView: async (...args) => {
        hotjarAnalyticsApi.pageView(...args);
        googleAnalyticsApi.pageView(...args);
    },
    identify: async (...args) => {
        hotjarAnalyticsApi.identify(...args);
        googleAnalyticsApi.identify(...args);
    }
};
