import axios from 'axios';
import fetcher from 'lib/fetcher';
import { dispatchLogout } from '../../lib/LogoutHandler';
import { getToken } from '../../utils/helpers/api.helpers';

class DirectionServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    getTown = async (filter) => {
        try {
            const result = await this.services.get(`/api/maintenance/towns${filter}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    createDirection = async (direction) => {
        try {
            const result = await fetcher.post(`/api/maintenance/address`, direction, {
                headers: {
                    Authorization: getToken()
                },
                message: {
                    error: 'Error al crear la dirección. Revisa que no haya espacios delante o detrás',
                    success: 'Dirección creada con éxito'
                }
            });
            return result;
        } catch (error) {
            console.log(error);
            if (error?.response?.status === 401) {
                dispatchLogout();
            }
        }
    };

    getTypeDirection = async (filter) => {
        try {
            const result = await this.services.get(`/api/address-type${filter}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getProvinces = async (filter) => {
        try {
            const result = await this.services.get(`/api/maintenance/county${filter}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };
}

const directionServices = new DirectionServices();
export default directionServices;
