import { IconX } from '@tabler/icons';
import BasicButton from './Base/BasicButton';

const RefuseButton = ({ type = 'button', noIcon = false, text = 'Refuse', disabled = false, action, to, state }) => {
    return (
        <BasicButton
            color="red"
            type={type}
            text={text}
            action={action}
            to={to}
            state={state}
            disabled={disabled}
            icon={noIcon ? null : <IconX stroke={2.1} size={18} color="white" />}
        />
    );
};
export default RefuseButton;
