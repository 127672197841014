import { contextStoreAdapter } from 'modules/shared';
import { tagsStore } from '../../application/tagsStore';

const TagsContext = contextStoreAdapter(tagsStore)({ name: 'TagsContext' });

export const TagsProvider = TagsContext.Provider;
export const useTagsStore = TagsContext.useConsumer;

export const tagsHooks = {
    useQuery: TagsContext.createQueryHook(({ api }) => api.getTags),
    useSave: TagsContext.createMutationHook(({ api }) => api.saveTag),
    useRemove: TagsContext.createMutationHook(({ api }) => api.removeTag),
    useAttachToProject: TagsContext.createMutationHook(({ api }) => api.attachTagToProject),
    useDetachFromProject: TagsContext.createMutationHook(({ api }) => api.detachTagFromProject)
};
