import { deleteDocument as apiDeleteDocument } from 'features/documentation/api/deleteDocument';
import { actions } from '../actions';

export const deleteDocument =
    ({ state, dispatch }) =>
    async (id) => {
        try {
            dispatch({ type: actions.pending });

            await apiDeleteDocument(id);

            // remove document from state (its really nested so = ugly code)
            const documentTypesBySectionCopy = JSON.parse(JSON.stringify(state.documentTypesBySection));

            for (const section in documentTypesBySectionCopy) {
                const documentTypes = documentTypesBySectionCopy[section].documentTypes;

                for (let i = 0; i < documentTypes.length; i++) {
                    const documentType = documentTypes[i];

                    let documents = documentType.documents;
                    const documentIndex = documents.findIndex((document) => document.id === id);

                    if (documentIndex > -1) {
                        documentTypesBySectionCopy[section].documentTypes[i].size = 0;
                        documentType.documents = [];
                    }
                }
            }

            dispatch({ type: actions.fulfilled, payload: { documentTypesBySection: documentTypesBySectionCopy } });
        } catch (error) {
            console.log(error);
            dispatch({ type: actions.rejected, payload: { message: error.message } });
        }
    };
