import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { Billeable } from 'modules/billeables/domain/Billeable/Billeable';

import { BilleablesStore } from '../../domain/BilleablesStore';

export const removeBilleable =
    (criteria: { billeable: Billeable }): Thunk<BilleablesStore> =>
    async (store) => {
        store.dispatch('stagedRemove', criteria);
    };

export const removeReducers: Pick<Reducers<BilleablesStore>, 'stagedRemove'> = {
    stagedRemove: (state, payload) => {
        return produce(state, (draft) => {
            const billeable = draft.entities.byId[payload.billeable.name];

            // Resets billeable config keeping only addon attached
            billeable.config = { addon: billeable.config.addon };

            billeable.purchased = false;
        });
    }
};
