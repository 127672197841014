import { useCallback, useEffect, useState } from 'react';

interface RectProps {
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    x: number;
    y: number;
}

export function useNodeDimensions() {
    const [rect, setRect] = useState<RectProps | null>(null);
    const [isResizing, setIsResizing] = useState(true); // to get initial dimensions

    useEffect(() => {
        window.addEventListener('resize', activateResizing);
        return () => window.removeEventListener('resize', deactivateResizing);
    }, []);

    const activateResizing = () => {
        setIsResizing(true);
    };

    const deactivateResizing = () => {
        setIsResizing(false);
    };

    const ref = useCallback(
        (node) => {
            if (node !== null && isResizing) {
                setRect(node.getBoundingClientRect());
                deactivateResizing();
            }
        },
        [isResizing]
    );

    return { ref, rect };
}
