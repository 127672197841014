import { createAnotherWasteManagerContract } from './createAnotherWasteManagerContract';

export const createContractsGroupedByTransferOperator = ({ ppgcls, promotion, wasteManager }) => {
    const contractsAnotherWasteManager = createAnotherWasteManagerContract(ppgcls, promotion, wasteManager);

    let allContracts = [...contractsAnotherWasteManager];
    allContracts = allContracts.filter((treatmentContract) => treatmentContract !== null);

    return allContracts;
};
