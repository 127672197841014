import { httpDocumentApi } from 'modules/document/infrastructure/api/httpDocumentApi';
import { DocumentProvider } from 'modules/document/infrastructure/react/DocumentContext';
import { forwardRef } from 'react';
import DocumentPreview, { DocumentPreviewProps } from './DocumentPreview';

export default forwardRef<HTMLDivElement, DocumentPreviewProps>((props, ref) => (
    <DocumentProvider api={httpDocumentApi}>
        <DocumentPreview {...props} ref={ref} />
    </DocumentProvider>
));
