import { useContext } from 'react';
import SuggestionCard from '../../../components/BasicComponents/Cards/SuggestionCard';
import { withAuth } from '../../../context/AuthProvider';
import { NewPpgclContext } from './NewPpgclStepper';
import './TransferOperator.scss';
import TransferOperatorOption from './TransferOperatorOption';
const TransferOperatorStep = () => {
    const { newPpgcls } = useContext(NewPpgclContext);
    const filterCompletePpglcs = newPpgcls.filter((newPpgcl) => newPpgcl.carrierProGesCodLerId);

    return (
        <>
            <SuggestionCard>
                El Operador de Traslado es la figura que se responsabiliza del control de la trazabilidad del residuo y
                la emisión documental que la certifica (DI o Documentos de Identificación de traslado).
            </SuggestionCard>
            {filterCompletePpglcs.length > 0 && (
                <ul className="transferOperator__list">
                    <p className="verticalStepper__message">
                        Selecciona el rol de operador de traslado para cada flujo de residuos creado previamente. Para
                        una gestión más ágil, indica también el coste y volumen por defecto de cada contenedor.
                    </p>
                    {filterCompletePpglcs.map((completePpgcl) => (
                        <TransferOperatorOption key={completePpgcl.ppgcl.id} {...{ completePpgcl }} />
                    ))}
                </ul>
            )}
            {filterCompletePpglcs.length === 0 && (
                <p className="verticalStepper__message">
                    Para poder seleccionar los operadores del traslado complete los pasos anteriores
                </p>
            )}
        </>
    );
};

export default withAuth(TransferOperatorStep);
