import React, { PropsWithChildren } from 'react';
import './Headline.scss';

interface HeadlineProps {
    children: React.ReactNode;
    tag?: string;
    className?: string;
}

const Headline = ({ children, tag, className }: PropsWithChildren<HeadlineProps>) => {
    const Tag = (tag || `h2`) as keyof JSX.IntrinsicElements;

    return <Tag className={`Headline ${className ? className : ''}`}>{children}</Tag>;
};

export default Headline;
