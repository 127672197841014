import { useTranslation } from 'react-i18next';
import '../../../components/BasicComponents/Popovers/Popovers.scss';
import ImpactTableInfoCalculation from './ImpactTableInfoCalculation';

const PopoverCalculation = () => {
    const [t] = useTranslation();

    const distribution = [
        { type: 'cleanRubble', percent: 30 },
        { type: 'concrete', percent: 20 },
        { type: 'gypsum', percent: 20 },
        { type: 'wood', percent: 10 },
        { type: 'insulation', percent: 10 },
        { type: 'plastic', percent: 5 },
        { type: 'paper&Cardboard', percent: 5 }
    ];

    return (
        <div className="popover__container popover__impact-calculations">
            <div className="popover__info popover__innerContainer">
                <p className="popover__paragraph">{t('explanation.softwareUsed', { ns: 'impact' })}</p>
                <ul className="popover__list">
                    <li>Cambio climático, en términos de CO2 (t CO2 eq.)</li>
                    <li>Uso de suelo (m2 de vertedero evitado), en términos de toneladas de Carbono (t C déficit).</li>
                </ul>
                <p className="popover__paragraph">
                    Nos basamos en el software OpenLCA v1.11 y la base de datos Ecoinvent 3.6., según los métodos de
                    evaluación:
                </p>
                <ul className="popover__list">
                    <li>
                        {t('explanation.climateChange', { ns: 'impact' })} →{' '}
                        {t('explanation.methodCo2eq', { ns: 'impact' })}
                    </li>
                    <li>
                        {t('explanation.landUse', { ns: 'impact' })} →{' '}
                        {t('explanation.methodCarbonDeficit', { ns: 'impact' })}
                    </li>
                </ul>
                <p className="popover__paragraph">{t('explanation.frequentLER', { ns: 'impact' })}</p>
                <h3 className="popover__sectionTitle">{t('explanation.scenarios', { ns: 'impact' })}</h3>
                <p className="popover__paragraph">{t('explanation.scenariosExplanation', { ns: 'impact' })}</p>
                <ul className="popover__list">
                    <li>
                        <strong>{t('explanation.lineal', { ns: 'impact' })}.</strong>{' '}
                        {t('explanation.linealExplanation', { ns: 'impact' })} 🙁
                    </li>
                    <li>
                        <strong>{t('explanation.potential', { ns: 'impact' })}.</strong>{' '}
                        {t('explanation.potentialExplanation', { ns: 'impact' })} 🙂
                    </li>
                    <li>
                        <strong>{t('explanation.standard', { ns: 'impact' })}.</strong>{' '}
                        {t('explanation.standardExplanation', { ns: 'impact' })}
                        😐
                    </li>
                </ul>
                <ImpactTableInfoCalculation />
                <p className="popover__paragraph">{t('explanation.calculatedData', { ns: 'impact' })}</p>
                <h3 className="popover__sectionTitle">{t('explanation.criteria', { ns: 'impact' })}</h3>
                <ul className="popover__list">
                    <li>
                        <strong>{t('explanation.transport', { ns: 'impact' })}</strong>:
                        <ul className="popover__list__secondary">
                            <li>{t('explanation.originToWastemanager', { ns: 'impact' })}</li>
                            <li>{t('explanation.wastemanagerToLandfill', { ns: 'impact' })}</li>
                            <li>{t('explanation.wastemanagerToTransformer', { ns: 'impact' })}</li>
                        </ul>
                    </li>

                    <li>
                        <strong>{t('explanation.metalsTreatment', { ns: 'impact' })}</strong>:
                        <ul className="popover__list__secondary">
                            <li>{t('explanation.iron', { ns: 'impact' })}</li>
                            <li>{t('explanation.aluminium', { ns: 'impact' })}</li>
                        </ul>
                    </li>

                    <li>
                        <strong>{t('explanation.cartonAndPaperTreatment', { ns: 'impact' })}</strong>:
                        <ul className="popover__list__secondary">
                            <li>{t('explanation.paper', { ns: 'impact' })}</li>
                            <li>{t('explanation.carton', { ns: 'impact' })}</li>
                        </ul>
                    </li>

                    <li>
                        <strong>{t('explanation.plasticComposition', { ns: 'impact' })}</strong>:
                        <ul className="popover__list__secondary">
                            <li>{t('explanation.composition1', { ns: 'impact' })}</li>
                            <li>{t('explanation.composition2', { ns: 'impact' })}</li>
                            <li>{t('explanation.composition3', { ns: 'impact' })}</li>
                            <li>{t('explanation.composition4', { ns: 'impact' })}</li>
                        </ul>
                    </li>

                    <li>
                        <strong>{t('explanation.mixDistribution', { ns: 'impact' })}:</strong>
                        <ul className="popover__list__secondary">
                            {distribution.map(({ type, percent }, index) => (
                                <li key={index}>
                                    {percent}% {t(`explanation.${type}`, { ns: 'impact' })}
                                </li>
                            ))}
                        </ul>
                    </li>
                </ul>
                <p className="popover__paragraph">
                    <small>(*) {t('explanation.contactUs', { ns: 'impact' })}</small>
                </p>
            </div>
        </div>
    );
};

export default PopoverCalculation;
