import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconInfoCircle } from '@tabler/icons';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import SimplePopover from '../../../components/BasicComponents/Popovers/SimplePopover';
import { ReactComponent as Co2 } from '../../../assets/iconos/valorizadas/co2.svg';
import { isGraphData } from '../mappers/isGraphData';
import SuggestionCard from '../../../components/BasicComponents/Cards/SuggestionCard';
import { currencyFormatDE } from '../../../utils/helpers/general.helpers';
import PopoverCalculation from './PopoverInfoCalculation';
import TemporalLineGraph from './graphs/GraphTemporalLine';
import ByLerBarGraph from './graphs/GraphByLerBar';
import Kpi from './ImpactKpi';
import './Impact.scss';

const ImpactCo2Avoided = ({
    co2TemporalGraphData,
    co2ByLerGraphData,
    co2KPI,
    equivalencies,
    isCoCircular,
    showAccumulatedGraph = true
}) => {
    const [t] = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const handleToggleModal = () => {
        setOpenModal(!openModal);
    };

    const getButtonIconAndTitle = (title) => {
        return (
            <>
                <IconInfoCircle size={18} stroke={1.5} />
                <span>{title}</span>
            </>
        );
    };

    const emptyData = Object.values(co2KPI).every((kpi) => kpi === '0');
    if (emptyData) return null;

    return (
        <SimpleCard
            title="CO₂ evitado (t CO₂ eq)"
            button
            buttonTitle={getButtonIconAndTitle(t('howIsItCalculated', { ns: 'impact' }))}
            buttonAction={() => handleToggleModal()}
        >
            <div className="impact__card">
                <div className="Impact__containerGraphs">
                    <div>
                        <div className="impact__kpisContainer">
                            <Kpi
                                kpiValue={currencyFormatDE(co2KPI?.real)}
                                IconKPI={Co2}
                                type="real"
                                testid="kpiCo2AvoidedReal"
                            />
                            <Kpi
                                kpiValue={currencyFormatDE(co2KPI?.potential)}
                                IconKPI={Co2}
                                type="potential"
                                testid="kpiCo2AvoidedPotential"
                            />
                            <Kpi
                                kpiValue={currencyFormatDE(co2KPI?.standard)}
                                IconKPI={Co2}
                                type="standard"
                                testid="kpiCo2AvoidedStandard"
                            />
                        </div>
                        {isGraphData(co2TemporalGraphData) && showAccumulatedGraph ? (
                            <TemporalLineGraph
                                graphTitle={t('co2.avoided.byDate', { ns: 'impact' })}
                                graphData={co2TemporalGraphData}
                                legendTitle="T CO₂ Eq."
                            />
                        ) : null}
                    </div>

                    <div>
                        {/** @deprecated - Hide KPI until we decide what to do with it in a bigger refactor */
                        /* <div className="impact__kpisContainer">
                            <Kpi
                                kpiValue={currencyFormatDE(co2KPI?.potentialUnseparatedWastes)}
                                IconKPI={Co2}
                                type="potentialUnseparatedWastes"
                                testid="kpiCo2AvoidedPotentialUnseparatedWastes"
                            >
                                {t('potentialForUnusedWastesMessage', { ns: 'impact' })}
                            </Kpi>
                        </div> */}
                        {co2ByLerGraphData?.length > 0 ? (
                            <ByLerBarGraph
                                graphTitle={t('co2.avoided.byLer', { ns: 'impact' })}
                                graphTitleSuffix={`(${t('separatedIntoProject', { ns: 'impact' })})`}
                                graphData={co2ByLerGraphData}
                                legendTitle="T CO₂ Eq."
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            {co2KPI?.real && (
                <div className="impact__equivalenciesContainer">
                    <SuggestionCard title="El CO₂ evitado equivale al consumo de...">
                        <p data-testid="kpiCo2AvoidedTrees" className="impact__equivalencia">
                            <span>{equivalencies?.trees} </span> árboles
                        </p>
                        {isCoCircular && (
                            <>
                                <p data-testid="kpiCo2AvoidedTrips" className="impact__equivalencia">
                                    <span>{currencyFormatDE(equivalencies?.trips?.numTripsMAD)} </span> viajes de
                                    Valencia a Madrid
                                </p>
                            </>
                        )}
                        <div className="impact__small">
                            <small>** CO₂ absorbido por árboles adultos</small>
                            {isCoCircular && <small>** Los viajes son en coche asumiendo un único ocupante</small>}
                        </div>
                    </SuggestionCard>
                </div>
            )}
            <SimplePopover
                openModal={openModal}
                setOpenModal={setOpenModal}
                noClose={false}
                title={t('popoverTitle', { ns: 'impact' })}
                addFunctionOnClose={handleToggleModal}
            >
                <PopoverCalculation />
            </SimplePopover>
        </SimpleCard>
    );
};

export default ImpactCo2Avoided;
