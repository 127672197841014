import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import SaveButton from 'components/BasicComponents/Buttons/SaveButton';
import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import { TituloH3 } from 'components/BasicComponents/Titulos/Titulos';
import { useRelatedEnterprisesData } from 'modules/enterprise/related-enterprise/infraestructure/store/relatedEnterpriseStore';
import EnterpriseAutocomplete from './components/autocompletes/EnterpriseAutocomplete';
import EnterpriseRoleAutocomplete from './components/autocompletes/EnterpriseRoleAutocomplete';
import NewEnterpriseForm from './components/popovers/NewEnterpriseForm';

const NewRelatedEnterpriseForm = () => {
    const [t] = useTranslation();

    const [toggleFormEnterprise, setToggleFormEnterprise] = useState(false);
    const [isFormValid, setIsFormValid] = useState(true);

    const INITIAL_STATE = {
        enterprise: '',
        enterpriseRole: '',
        isClient: false,
        isMainEnterprise: false
    };

    const [newRelatedEnterprise, setNewRelatedEnterprise] = useState(INITIAL_STATE as any);

    const [, { addRelatedEnterprise }] = useRelatedEnterprisesData();

    const handleAddRelatedEnterprise = (e) => {
        e.preventDefault();

        const { enterprise, enterpriseRole, isClient, isMainEnterprise } = newRelatedEnterprise;

        if (!enterprise || !enterpriseRole) {
            setIsFormValid(false);
            return;
        }

        setIsFormValid(true);

        addRelatedEnterprise({
            id: uuid(),
            enterpriseId: enterprise.id,
            enterpriseName: enterprise.nombreComercial,
            enterpriseCif: enterprise.cif,
            client: isClient,
            enterpriseRole: enterpriseRole.value,
            mainEnterprise: isMainEnterprise
        });

        setNewRelatedEnterprise(INITIAL_STATE);
    };

    const handleIsClientChange = (event) => {
        const { checked } = event.target;
        setNewRelatedEnterprise({
            ...newRelatedEnterprise,
            isClient: checked
        });
    };

    return (
        <div className="newRelatedEnterprise">
            <div className="newRelatedEnterprise__header">
                <TituloH3 titulo="Añadir una nueva empresa relacionada" />
                <div className="newRelatedEnterprise__buttons">
                    <CreateButton
                        type="button"
                        extraClasses="button__small"
                        text={t('createEnterprise', { ns: 'newPromotion' })}
                        action={() => setToggleFormEnterprise(!toggleFormEnterprise)}
                    />
                </div>
            </div>
            <form onSubmit={handleAddRelatedEnterprise}>
                <EnterpriseRoleAutocomplete
                    values={newRelatedEnterprise}
                    setValues={setNewRelatedEnterprise}
                    propName="enterpriseRole"
                    className="newRelatedEnterprise__input-medium"
                    isFormValid={isFormValid}
                    required={true}
                />
                <EnterpriseAutocomplete
                    values={newRelatedEnterprise}
                    setValues={setNewRelatedEnterprise}
                    propName="enterprise"
                    className="newRelatedEnterprise__input-large"
                    isFormValid={isFormValid}
                    required={true}
                />
                <div className="newRelatedEnterprise__checkbox">
                    <label>
                        <span>Es cliente</span>
                        <input
                            type="checkbox"
                            checked={newRelatedEnterprise.isClient}
                            onChange={(event) => handleIsClientChange(event)}
                        />
                    </label>
                </div>
                <SaveButton text="Añadir empresa" />
            </form>
            {toggleFormEnterprise && (
                <SimplePopover
                    title={t('createEnterprise', { ns: 'newPromotion' })}
                    openModal={toggleFormEnterprise}
                    setOpenModal={setToggleFormEnterprise}
                    className="simplePopover newPromotion__popover"
                >
                    <NewEnterpriseForm setToggleFormEnterprise={setToggleFormEnterprise} />
                </SimplePopover>
            )}
        </div>
    );
};

export default NewRelatedEnterpriseForm;
