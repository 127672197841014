import { Trans, useTranslation } from 'react-i18next';

import oopsImg from 'assets/pictures/oops.gif';
import './WasteRegisterDIFallback.scss';

const WasteRegisterDIFallback = () => {
    const [t] = useTranslation('wasteRegister');

    const supportEmail = 'admin@cocircular.es';

    return (
        <div className={`WasteRegisterDIFallback`}>
            <img src={oopsImg} alt="" className="WasteRegisterDIFallback__picture" />

            <header className="WasteRegisterDIFallback__header">
                <h1 className="WasteRegisterDIFallback__title">{t('di.errors.lost.title')}</h1>
                <div className="WasteRegisterDIFallback__text">
                    <Trans
                        i18nKey="di.errors.lost.message"
                        ns="wasteRegister"
                        components={{
                            a: <a className="AuthLayout__link" href={`mailto:${supportEmail}`} />,
                            paragraph: <p />
                        }}
                    />
                </div>
            </header>
        </div>
    );
};

export default WasteRegisterDIFallback;
