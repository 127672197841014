import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import wasteManagerServices from '../../../api/wasteManager/wasteManager.services';
import { withAuth } from '../../../context/AuthProvider';
import SubmitButton from '../../BasicComponents/Buttons/SubmitButton';
import InputEmail from '../../BasicComponents/Inputs/InputEmail';
import InputNumber from '../../BasicComponents/Inputs/InputNumber';
import InputText from '../../BasicComponents/Inputs/InputText';
import TextArea from '../../BasicComponents/Inputs/TextArea';
import MessageNotComplete from '../../BasicComponents/Messages/MessageNotComplete';
import ResultadoCarga from '../../BasicComponents/Messages/ResultadoCarga';
import SimplePopover from '../../BasicComponents/Popovers/SimplePopover';
import Spinner from '../../BasicComponents/Spinner/Spinner';
import { allFieldsComplete } from '../../../utils/helpers/general.helpers';
import './NewWasteManagerRequest.scss';
import { TituloH4 } from '../../BasicComponents/Titulos/Titulos';
import CodLerAutocomplete from '../../BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import SmallText from '../../BasicComponents/Text/SmallText';
import BasicChip from '../../BasicComponents/Chips/BasicChip';

const NewWasteManagerRequest = ({ openModal, setOpenModal, promotionName }) => {
    const [t] = useTranslation();

    const INITIAL_STATE_WASTEMANAGER = {
        empresa: '',
        nIMA: '',
        identificacionFiscal: '',
        telefono: '',
        mail: '',
        observation: '',
        lers: [],
        promotionName
    };
    const [newWasteManager, setNewWasteManager] = useState(INITIAL_STATE_WASTEMANAGER);
    const [selectedCodLer, setSelectedCodLer] = useState({ codLer: '' });
    const [validation, setValidation] = useState(false);
    const [message, setMessage] = useState();
    const [messageNotComplete, setMessageNotComplete] = useState();
    const [loading, setLoading] = useState(false);

    const requiredFields = {
        empresa: newWasteManager?.empresa,
        identificacionFiscal: newWasteManager?.identificacionFiscal,
        telefono: newWasteManager?.telefono,
        mail: newWasteManager?.mail,
        nima: newWasteManager?.nIMA
    };

    const validateFormWasteManager = () => {
        if (newWasteManager?.nIMA?.length > 0 && newWasteManager?.nIMA?.length !== 10) {
            setMessageNotComplete(t('longNIMA', { ns: 'newWasteManager' }));
            return false;
        }
        if (allFieldsComplete(requiredFields)) {
            setMessageNotComplete('');
            setValidation(false);
            return true;
        } else {
            setMessageNotComplete(t('notComplete', { ns: 'errors' }));
            setValidation(true);
            return false;
        }
    };

    const resetWasteManagerData = () => {
        setNewWasteManager(INITIAL_STATE_WASTEMANAGER);
    };

    const createMessage = (wasteManagerRequest) => {
        if (
            wasteManagerRequest !== undefined &&
            (wasteManagerRequest?.status === 200 || wasteManagerRequest?.status === 204)
        ) {
            setMessage({ text: t('message.success', { ns: 'newWasteManager' }), success: true });
            setTimeout(() => {
                resetWasteManagerData();
                setOpenModal(false);
            }, 3000);
        } else {
            setMessage({ text: t('message.error', { ns: 'newWasteManager' }), success: false });
        }
    };

    const handleInputChange = ({ target }) => {
        setNewWasteManager({
            ...newWasteManager,
            [target.name]: target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (validateFormWasteManager()) {
            const newWasteManagerRequest = await wasteManagerServices.requestNewWasteManager(newWasteManager);
            createMessage(newWasteManagerRequest);
        }

        setLoading(false);
    };

    const removeCodLer = (removedCodLer) => {
        const updatedCodLers = newWasteManager?.lers?.filter((ler) => ler !== removedCodLer);
        setNewWasteManager({ ...newWasteManager, lers: updatedCodLers });
    };

    const addSelectedCodLer = () => {
        if (selectedCodLer?.codLer !== '') {
            const newCodLer = selectedCodLer?.codLer?.codigoLER;
            if (!newWasteManager?.lers.includes(newCodLer)) {
                setNewWasteManager({ ...newWasteManager, lers: [...newWasteManager.lers, newCodLer] });
            }
        }
    };

    useEffect(() => {
        addSelectedCodLer();
    }, [selectedCodLer]);

    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={t('title', { ns: 'newWasteManager' })}
            className="simplePopover popover__big"
            allowScroll={false}
            addFunctionOnClose={() => resetWasteManagerData()}
        >
            <p>
                {t('note', { ns: 'newWasteManager' })}
                <a className="newWasteManagerRequest__mail" href="mailto: operaciones@cocircular.es">
                    operaciones@cocircular.es
                </a>
            </p>
            <form onSubmit={handleSubmit} className="promotionSettings__formEditPromotion">
                <InputText
                    label={t('enterpriseName', { ns: 'newWasteManager' })}
                    value={newWasteManager?.empresa}
                    name="empresa"
                    onChange={handleInputChange}
                    validacion={validation}
                    required
                />

                <div className="newWasteManagerRequest__flex">
                    <InputNumber
                        label="NIMA"
                        value={newWasteManager?.nIMA}
                        name="nIMA"
                        onChange={handleInputChange}
                        validacion={validation}
                        formatNumber={false}
                        required
                    />

                    <InputText
                        label={t('cif', { ns: 'newWasteManager' })}
                        value={newWasteManager?.identificacionFiscal}
                        name="identificacionFiscal"
                        onChange={handleInputChange}
                        validacion={validation}
                        required
                    />
                </div>

                <div className="newWasteManagerRequest__flex">
                    <InputNumber
                        label={t('contactTelf', { ns: 'newWasteManager' })}
                        value={newWasteManager?.telefono}
                        name="telefono"
                        onChange={handleInputChange}
                        validacion={validation}
                        required
                        formatNumber={false}
                    />

                    <InputEmail
                        label={t('contactEmail', { ns: 'newWasteManager' })}
                        value={newWasteManager?.mail}
                        name="mail"
                        onChange={handleInputChange}
                        validacion={validation}
                        required
                    />
                </div>
                <div className="newWasteManagerRequest__codLers">
                    <TituloH4 titulo={t('titleLers', { ns: 'newWasteManager' })} />

                    <CodLerAutocomplete
                        searchFromPPGCL={false}
                        values={selectedCodLer}
                        setValues={setSelectedCodLer}
                        label={t('codLerSearcher', { ns: 'newWasteManager' })}
                    />

                    <SmallText text={t('selectedCodLers', { ns: 'newWasteManager' })} />

                    <div className="newWasteManagerRequest__selectedCodLers">
                        {newWasteManager.lers?.map((codLer, i) => (
                            <BasicChip key={i} id={codLer} text={codLer} removeChipFunction={removeCodLer} />
                        ))}
                    </div>
                </div>
                <TextArea
                    label={t('observations', { ns: 'newWasteManager' })}
                    name="observation"
                    value={newWasteManager?.observation}
                    onChange={handleInputChange}
                    formatNumber={false}
                    inputContainer="newWasteManagerRequest__textArea"
                />

                {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
                <SubmitButton text={t('sendRequest', { ns: 'newWasteManager' })} />
                {message?.text && (
                    <ResultadoCarga text={message?.text} success={message?.success} setMessage={setMessage} />
                )}
            </form>
            {loading && <Spinner />}
        </SimplePopover>
    );
};
export default withAuth(NewWasteManagerRequest);
