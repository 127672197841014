import { Reducers, createStore } from 'modules/shared';
import { WASTE_REGISTER_INITIAL_CRITERIA } from '../domain/WasteRegisterCriteria';
import { WasteRegisterStore } from '../domain/WasteRegisterStore';
import { loadMoreWasteRegisters } from './load/loadMoreWasteRegisters';
import { loadWasteRegisters } from './load/loadWasteRegisters';
import { saveWasteRegister } from './save/saveWasteRegister';
import { exportCsv } from './download/exportCsv';
import { loadWasteRegisterTableInfo } from './load/loadWasteRegisterTableInfo';
import { removeWasteRegister } from './save/removeWasteRegister';

export const wasteRegisterStore = createStore({
    reducers: {} as Reducers<WasteRegisterStore>,
    initialState: {
        data: {
            allIds: [],
            byId: {}
        },
        total: 0,
        updating: {},
        updated: {},
        invalid: {},
        loading: 'idle',
        error: null,
        criteria: {
            ...WASTE_REGISTER_INITIAL_CRITERIA
        }
    },
    thunks: {
        loadWasteRegisters,
        loadMoreWasteRegisters,
        saveWasteRegister,
        exportCsv,
        loadWasteRegisterTableInfo,
        removeWasteRegister
    }
});
