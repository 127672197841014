import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import './DiExtractionWarning.scss';
import { TooltipContentLabel } from 'components/BasicComponents/Tooltips/TooltipContent';

interface DiExtractionWarningProps {
    type: 'LOW' | 'HIGH';
    total?: number;
    messages: string[];
}

const DiExtractionWarning = ({ type, total, messages }: DiExtractionWarningProps) => {
    const modifiers = {
        type: type === 'LOW' ? 'DiExtractionWarning__icon--yellowCircle' : 'DiExtractionWarning__icon--redDiamond'
    };

    const icon = type === 'LOW' ? '?' : '!';

    return (
        <span className="DiExtractionWarning" data-testid={`DiExtractionWarning__${type}`}>
            <Tooltip
                className="DiExtractionWarning__tooltip"
                msg={
                    <TooltipContentLabel>
                        {messages.map((message, index) => (
                            <p className="DiExtractionWarning__message" key={index}>
                                {message}
                            </p>
                        ))}
                    </TooltipContentLabel>
                }
                delayShow={500}
                offset={{ top: -15 }}
                hideArrow
            >
                <div className={`DiExtractionWarning__icon ${Object.values(modifiers).join(' ')}`}>
                    <label>{total || icon}</label>
                </div>
            </Tooltip>
        </span>
    );
};

export default DiExtractionWarning;
