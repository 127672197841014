import { IconBackhoe, IconFlag, IconX } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import { currencyFormatDE } from '../../../../utils/helpers/general.helpers';
import RoundedIcon from '../../Icon/RoundedIcon';
import SmallText from '../../Text/SmallText';
import { TituloH4 } from '../../Titulos/Titulos';
import './FinalTraceabilityPopover.scss';

const FinalTraceabilityPopover = ({ finalTraceability }) => {
    const [t] = useTranslation();
    const subsequent = (traz) => {
        return (
            <div className="subsequent" key={traz.id}>
                <li className="c-stepper__item">
                    <RoundedIcon
                        icon={
                            !traz.isfinalValorizator ? (
                                <IconBackhoe size={25} stroke={1.5} />
                            ) : (
                                <IconFlag size={25} stroke={1.8} />
                            )
                        }
                    />
                </li>
                <div className="subsequent__info">
                    <p className="subsequent__wasteManager">{traz.wasteManagerName}</p>
                    {/* TODO: Unknown valorization percent should change to "null" */}
                    {traz.valorizationPercentage === null || traz.valorizationPercentage === 1 ? (
                        <SmallText text="% de valorización no disponible" />
                    ) : (
                        <SmallText
                            text={`${traz.valorizationPercentage}% t ${t('valued.lowerCase', { ns: 'traceability' })}`}
                        />
                    )}
                    <SmallText text={traz.treatment} />
                </div>
            </div>
        );
    };

    const final = (traz, i) => {
        const lengthNext = finalTraceability.finalTraceability.length;
        if (i + 1 === lengthNext && !traz.isfinalValorizator) {
            return getFinalWaste(traz.isfinalValorizator);
        }
    };

    const getFinalWaste = (isFinal) => {
        return (
            <div className="subsequent">
                <li className="c-stepper__item">
                    <RoundedIcon
                        icon={isFinal ? <IconFlag size={25} stroke={1.8} /> : <IconX size={25} stroke={1.8} />}
                    />
                </li>
            </div>
        );
    };

    return (
        <div className="finalTraceability">
            <TituloH4 titulo={t('valuation.percentage.cocircular', { ns: 'traceability' })} tooltip="" />
            <SmallText className="finalTraceability__text" text={t('finalTraceability.text', { ns: 'traceability' })} />
            {finalTraceability?.valorizationPercentCocircular !== undefined &&
            finalTraceability?.valorizationPercentCocircular !== null ? (
                <p className="subsequent__percent">
                    <span>{currencyFormatDE(finalTraceability?.valorizationPercentCocircular)} %</span>
                    {t('valuation.cocircular.lowerCase', { ns: 'traceability' })}
                </p>
            ) : (
                <p className="subsequent__message">- {t('finalTraceability.notPossible', { ns: 'traceability' })}.</p>
            )}

            <TituloH4 titulo={t('finalTraceability.wasteTracking', { ns: 'traceability' })} />
            <div className="subsequent__container">
                {finalTraceability?.finalTraceability &&
                    finalTraceability?.finalTraceability.map((traceability, i) => subsequent(traceability, i))}
                {finalTraceability?.finalTraceability &&
                    finalTraceability?.finalTraceability.map((traceability, i) => final(traceability, i))}
            </div>
        </div>
    );
};
export default FinalTraceabilityPopover;
