import NewEditTraceabilitPage from '../components/NewEditTraceabilitPage';

export const NEW_EDIT_TRACEABILITY_ROUTE = [
    {
        component: NewEditTraceabilitPage,
        path: '/traceabilities/create',
        requiredPermission: 'canCreate',
        section: 'traceability'
    },
    {
        component: NewEditTraceabilitPage,
        path: '/traceabilities/traceability/:id/old-edit',
        requiredPermission: 'canCreate',
        section: 'traceability'
    }
];
