import FAQPage from '../components/FAQPage';

export const faqRoutesRoot = 'help/faq';

export const FAQ_ROUTES = [
    {
        component: FAQPage,
        path: faqRoutesRoot + '/:faqId',
        exact: false
    },
    {
        component: FAQPage,
        path: faqRoutesRoot,
        exact: false
    }
];
