import fetcher from '../../../lib/fetcher';

const _userMessage = {
    success: {
        text: 'success.edit',
        ns: 'traceability'
    },
    error: {
        text: 'traceability.edit',
        ns: 'errors'
    }
};

export const editTraceability = async (traceability) => {
    const URL = '/api/tracing/container-traceability';
    try {
        const response = await fetcher.put(URL, traceability, { message: _userMessage });
        return response;
    } catch (error) {
        console.log(error);
    }
};
