import { WasteHandling } from 'modules/waste-flow/domain/WasteHandling';

export const pgclWasteHandlingAdapter = (pgcl: any): WasteHandling => ({
    id: pgcl.id,
    ler: {
        id: pgcl.codLER.id,
        code: pgcl.codLER.codigoLER,
        description: pgcl.codLER.descripcion
    },
    treatment: {
        id: pgcl.procesoGestora.valorizacion.id,
        code: pgcl.procesoGestora.valorizacion.codigoValorizacion,
        description: pgcl.procesoGestora.valorizacion.descripcion
    },
    manager: {
        id: pgcl.procesoGestora.gestora.id
    }
});
