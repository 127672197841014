import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconClock, IconDownload, IconFileZip, IconX } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import ModalEliminar from '../../../components/BasicComponents/Modales/ModalEliminar';
import SmallMetricCard from '../../../components/BasicComponents/Cards/SmallMetricCard';
import './DocDownloadRequest.scss';
import { TituloH3 } from '../../../components/BasicComponents/Titulos/Titulos';
import SmallText from '../../../components/BasicComponents/Text/SmallText';
import Status from '../../../components/BasicComponents/Status/Status';
import SmallButton from '../../../components/BasicComponents/Buttons/Small/SmallButton';
import { cambiarOrdenFecha } from '../../../utils/helpers/general.helpers';
import { openFileByURL } from '../../../utils/helpers/files.helpers';

const DocDownloadRequest = ({ download, deleteFunction }) => {
    const [t] = useTranslation();
    const [deleteModal, setDeleteModal] = useState(false);

    const status = {
        // null -> pick default icon
        PENDING: { description: t('status.pending', { ns: 'documentation' }), color: 'blue', icon: <IconClock /> },
        COMPLETED: { description: t('status.done', { ns: 'documentation' }), color: 'green', icon: null },
        SKIPPED: { description: t('status.noDocuments', { ns: 'documentation' }), color: 'red', icon: null },
        default: { description: t('status.pending', { ns: 'documentation' }), color: 'blue', icon: null }
    };

    const getStatus = (downloadStatus) => {
        return status[downloadStatus] || status['default'];
    };

    const downloadFile = () => {
        if (download?.url !== undefined && download?.url !== null) {
            openFileByURL(download?.url);
        }
    };

    return (
        <SimpleCard className="docDownloadRequest">
            <div className="docDownloadRequest__container">
                <section className="docDownloadRequest__content">
                    <SmallMetricCard
                        icon={<IconFileZip size={26} stroke={1.8} />}
                        title={download?.weight !== null ? download?.weight + ' MB' : ''}
                        className={download?.weight === null && 'smallMetricCard-center'}
                    />
                    <div>
                        <TituloH3
                            titulo={`${t('downloadOf', { ns: 'documentation' })}: ${
                                download?.type?.name
                            } (${cambiarOrdenFecha(download.date)})`}
                        />
                        <p className="docDownloadRequest__data">
                            <Status
                                text={getStatus(download.status)['description']}
                                status={getStatus(download.status)['color']}
                                icon={getStatus(download.status)['icon']}
                            />
                            {download.docNum !== null && (
                                <SmallText text={`${download.docNum} ${t('documents', { ns: 'documentation' })}`} />
                            )}
                        </p>
                    </div>
                </section>
                <div className="docDownloadRequest__buttons">
                    {download.status === 'PENDING' && (
                        <SmallButton
                            color="white"
                            iconPosition="left"
                            titulo={t('deleteDownload.title', { ns: 'documentation' })}
                            icon={<IconX />}
                            action={() => setDeleteModal(!deleteModal)}
                        />
                    )}
                    {download.status === 'COMPLETED' && (
                        <SmallButton
                            color="blue"
                            iconPosition="left"
                            titulo={t('downloadFile', { ns: 'documentation' })}
                            icon={<IconDownload />}
                            action={() => downloadFile()}
                        />
                    )}
                </div>
            </div>
            {deleteModal && (
                <ModalEliminar
                    openModal={deleteModal}
                    setOpenModal={setDeleteModal}
                    id={download.id}
                    deleteFunction={deleteFunction}
                    title={`${t('deleteDownload.title', { ns: 'documentation' })}`}
                    subtitle={t('deleteDownload.areYouSure', { ns: 'documentation' })}
                    cancelText={t('deleteDownload.buttonCancel', { ns: 'documentation' })}
                    deleteText={t('deleteDownload.buttonDelete', { ns: 'documentation' })}
                />
            )}
        </SimpleCard>
    );
};
export default withAuth(DocDownloadRequest);
