import { useTranslation } from 'react-i18next';

import { EmptyWasteRegister, WasteRegister } from 'modules/waste-register';
import { useCheckStakeholdersNotification } from '../../hooks/useCheckStakeholdersNotification';
import './WasteRegisterStackeholdersNotification.scss';

interface WasteRegisterStackeholdersNotificationProps {
    wasteRegister?: WasteRegister | EmptyWasteRegister;
    id?: string;
}

const WasteRegisterStackeholdersNotification = (props: WasteRegisterStackeholdersNotificationProps) => {
    const { wasteRegister, id } = props;

    const { t } = useTranslation('wasteRegister');
    const isEditMode = !!id;
    const { notificationTo } = useCheckStakeholdersNotification({ wasteRegister });

    return (
        <>
            {/* Display only when new register */}
            {notificationTo.length > 0 && !isEditMode && (
                <p className="WasteRegisterForm__notificationTo">
                    {t('notificationTo')} {notificationTo.join(' y ')}.
                </p>
            )}
        </>
    );
};

export default WasteRegisterStackeholdersNotification;
