import { useTranslation } from 'react-i18next';
import '../../../components/BasicComponents/Popovers/Popovers.scss';

const ImpactTableInfoCalculation = () => {
    const [t] = useTranslation();

    return (
        <table className="popover__table">
            <thead>
                <tr>
                    <th>{t('explanation.stage', { ns: 'impact' })}</th>
                    <th>{t('explanation.processes', { ns: 'impact' })}</th>
                    <th>{t('explanation.lineal', { ns: 'impact' })}</th>
                    <th>{t('explanation.potential', { ns: 'impact' })}</th>
                    <th>{t('explanation.standard', { ns: 'impact' })}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowSpan="4">{t('explanation.endMaterial', { ns: 'impact' })}</td>
                    <td>{t('explanation.wasteSeparation', { ns: 'impact' })}</td>
                    <td>❌</td>
                    <td>✅</td>
                    <td>❌</td>
                </tr>
                <tr>
                    <td>{t('explanation.wasteTreatment', { ns: 'impact' })}</td>
                    <td>✅</td>
                    <td>✅</td>
                    <td>✅</td>
                </tr>
                <tr>
                    <td>{t('explanation.landfill', { ns: 'impact' })}</td>
                    <td>✅</td>
                    <td>❌</td>
                    <td rowSpan="2">
                        {t('explanation.endTreatment', { ns: 'impact' })}
                        <em>{t('explanation.mix', { ns: 'impact' })}</em>
                    </td>
                </tr>
                <tr>
                    <td>{t('explanation.wasteManagerTreatment', { ns: 'impact' })}</td>
                    <td>❌</td>
                    <td>✅</td>
                </tr>
                <tr>
                    <td>{t('explanation.newProduct', { ns: 'impact' })}</td>
                    <td>{t('explanation.manufacturing', { ns: 'impact' })}</td>
                    <td>{t('explanation.virginMaterial', { ns: 'impact' })}</td>
                    <td>{t('explanation.recycledMaterial', { ns: 'impact' })}</td>
                    <td>
                        {t('explanation.normalConditions', { ns: 'impact' })}{' '}
                        <em>{t('explanation.normalConditionsExample', { ns: 'impact' })}</em>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default ImpactTableInfoCalculation;
