import fetcher from 'lib/fetcher';

export const deleteLinkedTreatmentContract = async ({
    projectWasteFlowId,
    contractId
}: {
    projectWasteFlowId: number;
    contractId: number;
}) => {
    const message = {
        success: 'Se ha desvinculado del contrato de tratamiento',
        error: 'Error al desvincular el contrato'
    };

    const URL = `/api/project-waste-flow/${projectWasteFlowId}/treatment-contract/${contractId}`;
    try {
        const response = await fetcher.delete(URL, { message } as any);
        return response;
    } catch (error) {
        console.log(error);
    }
};
