import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DOMPurify from 'dompurify';

const RichText = (props) => {
    const MAX_LENGTH = 13000; // 4096;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [notComplete, setNotComplete] = useState(false);

    /* Comentario para el futuro: por cada cambio en el texto, se hace setValues. El useEffect de abajo se triggerea
    una vez la data esté cargada. Se podría decir que se triggeree según props.values, pero si se hace eso el cursor 
    del componente este te lleva al principio del texto todo el rato (vamos que no puedes escribir). 
    Así que por eso se ha hecho el workaround de abajo. */

    useEffect(() => {
        // if you pass null to this components, it brokes
        let initialValue = props.values[props.field] !== null ? props.values[props.field] : '';

        if (props.dataLoaded && isFirstTime) {
            setEditorState(
                EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(initialValue)))
            );
            setIsFirstTime(false);
        }
    }, [props.dataLoaded]);

    useEffect(() => {
        if (props.reset) {
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(''))));
        }
    }, [props.reset]);

    useEffect(() => {
        inputVacio();
    }, [props.validacion]);

    const inputVacio = () => {
        if (props.validacion === true && props.required && props.values.observaciones === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    const handleEditorStateChange = (e) => {
        setEditorState(e);
        convertContentToHTML(e.getCurrentContent());
    };

    const convertContentToHTML = (content) => {
        let toHTML = convertToHTML(content);
        let cleanedHTML = DOMPurify.sanitize(toHTML);
        cleanedHTML = cleanedHTML.replaceAll('<p></p>', '<br>'); // hago esto super cutre porque no queda otra, de verdad, i tried

        props.setValues({ ...props.values, [props.field]: cleanedHTML });
    };

    const handleBeforeInput = (input) => {
        const inputLength = editorState.getCurrentContent().getPlainText().length;

        if (input && inputLength >= MAX_LENGTH) {
            return 'handled';
        }
    };

    return (
        <>
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName={notComplete ? 'editorClassName editorClassName--notComplete' : 'editorClassName'}
                handleBeforeInput={handleBeforeInput}
                onEditorStateChange={handleEditorStateChange}
                toolbar={{
                    options: ['inline', 'blockType', 'list', 'emoji', 'history']
                }}
                localization={{
                    locale: 'es'
                }}
            />
        </>
    );
};

export default RichText;
