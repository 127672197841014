import { DeepLinkStorage } from 'modules/auth/domain/DeepLinkStorage';

const DEEP_LINK_KEY = 'deepLink';
const storage = sessionStorage;

export const sessionDeepLinkStorage: DeepLinkStorage = {
    hasDeepLink: () => {
        return Boolean(storage.getItem(DEEP_LINK_KEY));
    },

    saveDeepLink: (deepLinkUrl) => {
        if (deepLinkUrl === '/') return; // In order to prevent infinite loops

        storage.setItem(DEEP_LINK_KEY, deepLinkUrl);
    },

    removeDeepLink: () => {
        if (Boolean(storage.getItem(DEEP_LINK_KEY))) {
            storage.removeItem(DEEP_LINK_KEY);
        }
    },

    getDeepLink: () => {
        const deepLink = storage.getItem(DEEP_LINK_KEY);
        return deepLink;
    }
};
