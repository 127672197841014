import { contextStoreAdapter } from 'modules/shared';
import { wasteByValorizationStore } from 'modules/waste-by-valorization/application/wasteByValorizationStore';

const WasteByValorizationContext = contextStoreAdapter(wasteByValorizationStore)({
    name: 'WasteByValorizationContext'
});

export const WasteByValorizationProvider = WasteByValorizationContext.Provider;
export const useWasteByValorization = WasteByValorizationContext.createQueryHook(
    ({ api }) => api.getWasteByValorization
);
