import Auth from 'features/auth';

export const RUTAS_ANON = [
    {
        component: Auth,
        path: '/account',
        exact: false
    }
];
// adf
