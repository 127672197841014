import { Link } from 'react-router-dom';
import './Buttons.scss';

const SecondaryButton = ({
    type = 'link',
    titulo = 'Secondary button',
    icon,
    iconPosition = 'left',
    widthFull = false,
    action,
    to
}) => {
    return (
        <>
            {type === 'button' ? (
                <button
                    type="button"
                    className={`buttonAux button__white ${widthFull ? 'button-full' : ''}`}
                    onClick={action}
                >
                    {iconPosition === 'left' && icon}
                    <span className="button__label">{titulo}</span>
                    {iconPosition === 'right' && icon}
                </button>
            ) : (
                <Link className={`buttonAux button__white ${widthFull ? 'button-full' : ''}`} to={to}>
                    {iconPosition === 'left' && icon}
                    <span className="button__label">{titulo}</span>
                    {iconPosition === 'right' && icon}
                </Link>
            )}
        </>
    );
};
export default SecondaryButton;
