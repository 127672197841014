import { IconChevronLeft } from '@tabler/icons';
import { Link } from 'react-router-dom';

import { legacyEnterpriseAdapter } from 'modules/enterprise/related-enterprise/domain/legacyEnterpriseAdapter';
import logoImg from '../../../assets/logo/logo360-isotype.png';
import { withAuth } from '../../../context/AuthProvider';
import HasPermission from '../../AccessCheckers/HasPermission';
import MenuDetailTopBar from '../../ComplexComponents/TopBar/MenuDetailTopBar';
import FastAccessButton from '../Buttons/FastAccessButton';
import './TopBar.scss';

const TopBar = (props) => {
    const { isCoCircular, breadcrumbs = [], showLogo = false, showOnMobile = false } = props;

    const modifiers = [showOnMobile ? '--showOnMobile' : ''];

    return (
        <>
            <div className={`topbar ${modifiers.join(' ')}`}>
                {showLogo && (
                    <Link to="/" className="topbar__logo">
                        <img src={logoImg} alt="360 Advisor" />
                    </Link>
                )}
                <div className="topbar__promoData">
                    {props.promocion?.id && breadcrumbs.length === 0 && (
                        <>
                            <p className="topbar__item --highlighted">
                                {legacyEnterpriseAdapter(props.promocion?.relatedEnterprises)?.nombreComercial}
                            </p>
                            <span className="topbar__separator" />
                            <p className="topbar__item --highlighted">
                                {isCoCircular && `${props.promocion?.num} -`} {props.promocion?.nombre}
                            </p>
                            <HasPermission ifRole="canView" section="wasteConfiguration">
                                <Link to="/waste-manager/dashboard">
                                    <FastAccessButton type="recycle" className="fastAccessButtons" />
                                </Link>
                            </HasPermission>
                        </>
                    )}
                    {breadcrumbs.map((bc, index) => {
                        const isLast = breadcrumbs.length - 1 === index;
                        const isFirst = index === 0;
                        const modifiers = [isFirst ? '--first --highlighted' : '', isLast ? '--last' : ''];
                        return (
                            <>
                                <Link className={`topbar__item ${modifiers.join(' ')}`} to={bc.to}>
                                    {isFirst && <IconChevronLeft size="20" />}
                                    <span>{bc.name}</span>
                                </Link>
                                {!isLast && <span className="topbar__separator --lean" />}
                            </>
                        );
                    })}
                </div>
                <MenuDetailTopBar />
            </div>
        </>
    );
};
export default withAuth(TopBar);
