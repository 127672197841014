import { useTranslation } from 'react-i18next';
import { PropsWithChildren, ReactElement, cloneElement, useState } from 'react';

import BasicModal from '../Modales/BasicModal';

export interface ActionConfirmProps {
    onConfirm?: (...args: any) => any;
    title?: string;
    message?: React.ReactNode;
    actions?: React.ReactNode;
    confirmText?: string;
    confirmStyle?: 'danger' | 'primary';
}

const ActionConfirm = ({
    title,
    onConfirm,
    confirmText,
    confirmStyle = 'danger',
    children,
    message,
    actions = null
}: PropsWithChildren<ActionConfirmProps>) => {
    const [isOpen, setIsOpen] = useState(false);
    const [t] = useTranslation();

    return (
        <>
            {cloneElement(children as ReactElement<any>, {
                onClick: () => setIsOpen(true),
                action: () => setIsOpen(true)
            })}
            <BasicModal
                testid="ActionConfirm"
                actionText={confirmText || t('delete')}
                refuse={confirmStyle === 'danger'}
                cancelText={t('cancel')}
                title={title || t('delete')}
                openModal={isOpen}
                setOpenModal={setIsOpen}
                textCenter
                actionFunction={onConfirm}
                actions={actions}
                // Just to ignore optional fields
                {...({} as any)}
            >
                {message || t('areYouSure')}
            </BasicModal>
        </>
    );
};

export default ActionConfirm;
