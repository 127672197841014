import { TraceabilitiesSummaryByDate } from 'modules/traceabilities/domain/TraceabilitiesSummary';

export const summaryMock: TraceabilitiesSummaryByDate = [
    {
        date: '2021-08-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 5
            }
        ]
    },
    {
        date: '2021-09-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 1
            }
        ]
    },
    {
        date: '2021-11-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 4
            }
        ]
    },
    {
        date: '2022-01-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 3
            }
        ]
    },
    {
        date: '2022-02-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 1
            }
        ]
    },
    {
        date: '2022-04-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 2
            }
        ]
    },
    {
        date: '2022-05-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 2
            }
        ]
    },
    {
        date: '2022-06-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 1
            }
        ]
    },
    {
        date: '2022-07-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 2
            }
        ]
    },
    {
        date: '2022-08-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 1
            }
        ]
    },
    {
        date: '2022-09-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 2
            }
        ]
    },
    {
        date: '2022-10-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 3
            }
        ]
    },
    {
        date: '2022-11-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 5
            }
        ]
    },
    {
        date: '2022-12-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 4
            }
        ]
    },
    {
        date: '2023-01-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 2
            }
        ]
    },
    {
        date: '2023-02-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 3
            }
        ]
    },
    {
        date: '2023-03-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 4
            }
        ]
    },
    {
        date: '2023-04-01',
        data: [
            {
                id: 9999999,
                label: 'Fracciones minerales',
                value: 2
            }
        ]
    }
];
