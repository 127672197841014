import { TraceabilitiesSummaryByDate } from 'modules/traceabilities/domain/TraceabilitiesSummary';
import { summaryMock } from '../mocks/summaryMock';

export const transformSummaryToGraphData = (
    summary: TraceabilitiesSummaryByDate
): { data: Array<any>; series: string[] } => {
    const data = summary.map(({ date, data }) => ({
        date,
        ...data.reduce((series, serie) => ({ ...series, [`${serie.id}:${serie.label}`]: serie.value }), {})
    }));

    const series = [...new Set(data.flatMap(({ date, ...entries }) => Object.keys(entries)))] as string[];

    return { data, series };
};

export const getSummaryMock = (): { data: Array<any>; series: string[] } => {
    const { data, series } = transformSummaryToGraphData(summaryMock);
    return { data, series };
};

export const getBarsPerWidthTraceability = (): Array<{ maxWidth: number; bars: number }> => {
    const barsPerWidth = [
        { maxWidth: 200, bars: 1 },
        { maxWidth: 300, bars: 3 },
        { maxWidth: 400, bars: 5 },
        { maxWidth: 500, bars: 7 },
        { maxWidth: 600, bars: 9 },
        { maxWidth: 700, bars: 11 },
        { maxWidth: 800, bars: 13 },
        { maxWidth: 900, bars: 15 },
        { maxWidth: 1000, bars: 17 },
        { maxWidth: 1200, bars: 19 },
        { maxWidth: 3000, bars: 34 } // high number just in case
    ];
    return barsPerWidth;
};
