import { useEffect } from 'react';

import { useDiExtraction } from 'modules/di-extraction';

export const useReloadAfterUpdate = () => {
    const [{ criteria, updated }, { reloadDiExtractions }] = useDiExtraction();

    useEffect(() => {
        if (!criteria.promotionId || updated.length === 0) return;
        reloadDiExtractions();
    }, [criteria.promotionId, JSON.stringify(updated)]);
};
