import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { TraceabilitiesCriteria } from 'modules/traceabilities/domain/TraceabilitiesCriteria';

import { TraceabilitiesStore } from '../../domain/TraceabilitiesStore';

export const loadTraceabilitiesCounters =
    ({ promotionId }: Pick<TraceabilitiesCriteria, 'promotionId'>): Thunk<TraceabilitiesStore> =>
    async (store, { api, logger }) => {
        try {
            store.dispatch('loadCountersPending', null);
            const counters = await api.getCounters({ promotionId });

            store.dispatch('loadCountersFulfilled', { counters });
        } catch (e) {
            const error = e as Error;

            logger.send(error);
            store.dispatch('loadCountersRejected', { error });
        }
    };

export const loadTraceabilitiesCountersReducers: Pick<
    Reducers<TraceabilitiesStore>,
    'loadCountersPending' | 'loadCountersFulfilled' | 'loadCountersRejected'
> = {
    loadCountersPending: (state) =>
        produce(state, (draft) => {
            draft.loading.counters = 'pending';
        }),
    loadCountersFulfilled: (state, { counters }) =>
        produce(state, (draft) => {
            draft.counters = counters;
            draft.loading.counters = 'succeeded';
        }),
    loadCountersRejected: (state, { error }) =>
        produce(state, (draft) => {
            draft.counters = {
                completedAndWarning: 0
            };
            draft.error = error;
            draft.loading.counters = 'failed';
        })
};
