import axios from 'axios';
import { getToken } from '../../utils/helpers/api.helpers';
import { dispatchLogout } from '../../lib/LogoutHandler';

class PromoProGesCodLersServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    filterPromoProGesCodLers = async (url) => {
        try {
            const result = await this.services.get(`/api/maintenance/promo-pro-ges-cod-lers${url}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    countPromoProGesCodLers = async (url) => {
        try {
            const result = await this.services.get(`/api/maintenance/promo-pro-ges-cod-lers/count${url}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    filterProGesCodLers = async (url) => {
        try {
            const result = await this.services.get(`/api/pro-ges-cod-lers${url}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    finalTraceability = async (id) => {
        try {
            const result = await this.services.get(`/api/pro-ges-cod-lers/final-traceability/${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result?.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    createPromoProGesCodLer = async (promoProGesCodLer) => {
        try {
            const result = await this.services.post(`/api/maintenance/promo-pro-ges-cod-lers`, promoProGesCodLer, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    editPromoProGesCodLer = async (promoProGesCodLer) => {
        try {
            const result = await this.services.put(`/api/maintenance/promo-pro-ges-cod-lers`, promoProGesCodLer, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    deletePromoProGesCodLer = async (promoProGesCodLer) => {
        try {
            const result = await this.services.delete(
                `/api/maintenance/promo-pro-ges-cod-lers/${promoProGesCodLer.id}`,
                {
                    headers: {
                        Authorization: getToken()
                    }
                }
            );
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };
}

const promoProGesCodLersServices = new PromoProGesCodLersServices();
export default promoProGesCodLersServices;
