import { useTranslation } from 'react-i18next';
import { Link, Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import Tabs from 'components/BasicComponents/Tabs';
import PrivateRoute from 'components/routes/PrivateRoute';
import { promotionRouteRoot } from 'features/promotion/routes/PromotionRouter';
import { ReportPage } from 'features/reports';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import Layout from 'pages/Layout/Layout';
import PromotionDetail from '../PromotionDetail/PromotionDetail';
import Users from '../Users';
import WasteManagers from '../WasteManagers';
import './Expedient.scss';

const Expedient = () => {
    const match = useRouteMatch();
    const { pathname } = useLocation();
    const [t] = useTranslation('promotion');
    const [, analytics] = useAnalyticsStore();

    /**
     * As the route has route parameters, it is necessary to distinguish between the root
     * route against which it is typed (root) versus the current base route for the link (current).
     */
    const root = `${promotionRouteRoot}/expedient`;
    const current = `${match.url}/expedient`;

    const routes = [
        {
            page: 'summary',
            path: `/summary`,
            component: PromotionDetail
        },
        {
            page: 'waste-managers',
            path: `/waste-managers`,
            component: WasteManagers,
            event: AnalyticsEvents.PROMO_WASTE_MANAGER_TAB_CLICKED
        },
        { page: 'users', path: `/users`, component: Users, event: AnalyticsEvents.PROMO_USERS_TAB_CLICKED },
        { page: 'report', path: `/report`, component: ReportPage, event: AnalyticsEvents.PROMO_REPORT_TAB_CLICKED }
    ];

    const triggerAnalyticsEvent = (tabIndex) => {
        const route = routes[tabIndex];
        if (!route || !route.event) return;

        analytics.event(route.event);
    };

    return (
        <Layout>
            <div className="Expedient">
                <Tabs
                    className="Expedient__tabs"
                    activeTab={routes.findIndex((route) => pathname.includes(route.path))}
                    onClick={(tabIndex) => triggerAnalyticsEvent(tabIndex)}
                    tabs={routes.map((route) => (
                        <Link to={current + route.path}>{t(`tabs.${route.page}`)}</Link>
                    ))}
                />
                <Switch>
                    {routes
                        .filter((route) => route.component)
                        .map(({ path, ...route }) => (
                            <PrivateRoute path={root + path} {...route} />
                        ))}

                    {/* // Just for keep redirecting old links */}
                    <Redirect from={`${root}/settings`} to={`${promotionRouteRoot}/settings`} />

                    <Redirect to={current + routes[0].path} />
                </Switch>
            </div>
        </Layout>
    );
};

export default Expedient;
