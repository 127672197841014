import { Trans, useTranslation } from 'react-i18next';

import EmptyState from 'components/BasicComponents/EmptyState';
import { useDiExtraction } from 'modules/di-extraction';
import AutomaticReadingAiBadge from '../AutomaticReadingAiBadge';
import './AutomaticReadingPendingState.scss';

const AutomaticReadingPendingState = () => {
    const [{ counters }] = useDiExtraction();
    const [t] = useTranslation('automaticReading');

    return (
        <>
            {!!counters.PENDING && (
                <EmptyState
                    className="AutomaticReadingPendingState"
                    title={
                        <Trans
                            i18nKey="automaticReading:pendingMsg.title"
                            count={counters.PENDING || 0}
                            values={{ count: counters.PENDING || 0 }}
                            components={{
                                brand: <AutomaticReadingAiBadge className="AutomaticReadingPendingState__aiBadge" />
                            }}
                        />
                    }
                    subtitle={t('pendingMsg.subtitle')}
                />
            )}
        </>
    );
};

export default AutomaticReadingPendingState;
