import fetcher from 'lib/fetcher';

import { Carrier } from '../../domain/Carrier';
import { CarrierApi } from '../../domain/CarrierApi';

type ApiWasteFlow = {
    carrier: {
        name: string;
    };
};

export const httpCarrierApi: CarrierApi = {
    async findCarrierByCriteria(criteria) {
        const { promotionId } = criteria || {};

        /** From PPGCL */
        if (promotionId !== undefined) {
            const params = {
                'promocionId.equals': promotionId,
                'codLer.contains': criteria?.ler?.code,
                'gestoraName.contains': criteria?.manager?.name,
                size: criteria?.size || 999,
                page: criteria?.page || 0,
                ...(criteria?.search !== '' && { 'search.contains': criteria?.search })
            };

            const { data } = await fetcher.get('/api/project-waste-flow', {
                params
            });

            const wasteFlows: ApiWasteFlow[] = data?.content || [];

            const groupedWasteFlowsByCarrier = Object.groupBy(
                wasteFlows.filter((wf) => !!wf.carrier?.name),
                (wf) => wf.carrier.name
            );

            const carriers = Object.values(groupedWasteFlowsByCarrier)
                .filter((group) => group && group.length > 0)
                .map((group) => {
                    const g = group as ApiWasteFlow[];
                    const source = g[0].carrier;

                    const carrier: Carrier = {
                        name: source.name
                    };

                    return carrier;
                });

            return carriers;
        }

        return [];
    }
};
