import { IconBan } from '@tabler/icons';
import { Trans, useTranslation } from 'react-i18next';

import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import SuggestionCard from 'components/BasicComponents/Cards/SuggestionCard';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { sumCountersByKey } from 'features/automatic-reading/utils/sumCountersByKey';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { DiExtractionCriteria, rejectableStatusList, useDiExtraction } from 'modules/di-extraction';

interface AutomaticReadingMassiveRejectProps {}

const AutomaticReadingMassiveReject = (props: AutomaticReadingMassiveRejectProps) => {
    const [t] = useTranslation('automaticReading');
    const [, analytics] = useAnalyticsStore();

    const [{ criteria, counters }, diExtractionsActions] = useDiExtraction();
    const { rejectManyDiExtractions, reloadDiExtractions } = diExtractionsActions;

    const triggerMassiveAction = async (action: 'approve-all' | 'approve-withoutWarnings' | 'reject') => {
        const allCriteriaButPage = { ...criteria, page: undefined, size: undefined };

        const payload: DiExtractionCriteria = {
            promotionId: criteria.promotionId,
            ...allCriteriaButPage
        };

        if (action === 'reject') {
            await rejectManyDiExtractions({ ...payload, state: criteria.state?.flat() || rejectableStatusList });
            analytics.event(AnalyticsEvents.AUTOMATIC_READING_MASSIVE_REJECTED);
        }

        await reloadDiExtractions();
    };

    // Intersection between rejectableStatusList/approvableStatusList and the selected status (filters)
    // -> for example, reject ONLY 'to be completed' DIs if user applied that filter
    // -> for example, you cannot aprove DIs if at filters there is selected 'to be completed' only
    const thereAreStatusFilter = criteria.state && criteria.state.length > 0;
    const visibleRejectableStatus = thereAreStatusFilter
        ? rejectableStatusList.filter((status) => criteria.state?.flat()?.includes(status))
        : rejectableStatusList;

    const rejectablesCount = sumCountersByKey(counters, visibleRejectableStatus);

    if (counters.totalApplicable === 0) return null;

    const rejectTxt = t('actions.rejectAll.name', { count: rejectablesCount });

    const rejectDisabledText: string | null =
        (!!counters.PENDING && t('actions.disabledMsg.pending')) ||
        (rejectablesCount === 0 && t('actions.disabledMsg.noRejectableDis')) ||
        null;

    return (
        <Tooltip msg={rejectDisabledText} delayShow={0}>
            <ActionConfirm
                title={rejectTxt}
                onConfirm={() => triggerMassiveAction('reject')}
                confirmStyle="danger"
                confirmText={rejectTxt}
                message={
                    <div className="AutomaticReadingHeader__actions-dialog">
                        <SuggestionCard className="AutomaticReadingHeader__actions-tip" title="">
                            <div>{t('actions.tip')}</div>
                        </SuggestionCard>
                        <Trans
                            i18nKey="actions.rejectAll.confirmation"
                            ns="automaticReading"
                            values={{ count: rejectablesCount }}
                        />
                    </div>
                }
            >
                <SmallButton
                    type="button"
                    titulo={rejectTxt}
                    icon={<IconBan />}
                    iconPosition="left"
                    disabled={rejectablesCount === 0 || counters.PENDING}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </ActionConfirm>
        </Tooltip>
    );
};

export default AutomaticReadingMassiveReject;
