// eslint-disable-next-line import/named
import { Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { IconLayoutList, IconTable } from '@tabler/icons';
import SegmentedControl from 'components/BasicComponents/SegmentedControl/SegmentedControl';
import PrivateRoute from 'components/routes/PrivateRoute';
import WasteRegisterEditPage from 'features/waste-register/components/WasteRegisterEditPage';
import WasteRegisterList from 'features/waste-register/components/WasteRegisterList/WasteRegisterList';
import WasteRegisterTable from 'features/waste-register/components/WasteRegisterTable/WasteRegisterTable';
import WASTE_REGISTER_CONFIG from 'features/waste-register/waste-register.config';
import useStateFromStorage from 'hooks/useStateFromStorage';
import './PromotionWasteRegisterPage.scss';

const { PAGE_MODE_STORAGE_KEY } = WASTE_REGISTER_CONFIG;

interface WasteRegisterPageRoute {
    promotionId: string;
}

enum WasteRegisterPageRoutes {
    LIST = 'list',
    TABLE = 'table'
}

const PromotionWasteRegisterPage = () => {
    const { path, params } = useRouteMatch<WasteRegisterPageRoute>();
    const history = useHistory();
    const promotionId = parseInt(params.promotionId);

    const [mode, setMode] = useStateFromStorage({
        key: PAGE_MODE_STORAGE_KEY,
        initialState: WasteRegisterPageRoutes.LIST
    });

    if (!promotionId) return null;

    return (
        <div className="PromotionWasteRegisterPage">
            <SegmentedControl
                className="PromotionWasteRegisterPage__modeSwitch"
                value={mode}
                onChange={(newMode) => {
                    setMode(newMode as typeof mode);
                    history.push(`./${newMode}`);
                }}
                options={[
                    {
                        id: WasteRegisterPageRoutes.LIST,
                        label: 'Lista simplificada',
                        content: <IconLayoutList size={20} stroke={1.6} />
                    },
                    {
                        id: WasteRegisterPageRoutes.TABLE,
                        label: 'Tabla',
                        content: <IconTable size={20} stroke={1.6} />
                    }
                ]}
            />
            <Switch>
                <PrivateRoute path={`${path}/${WasteRegisterPageRoutes.LIST}`}>
                    <WasteRegisterList promotionIds={[promotionId]} />
                    <PrivateRoute path={`${path}/${WasteRegisterPageRoutes.LIST}/:registerId`}>
                        <WasteRegisterEditPage
                            promotionId={promotionId}
                            goBackTo={`/${WasteRegisterPageRoutes.LIST}`}
                        />
                    </PrivateRoute>
                </PrivateRoute>

                <PrivateRoute path={`${path}/${WasteRegisterPageRoutes.TABLE}`}>
                    <WasteRegisterTable />
                    <PrivateRoute path={`${path}/${WasteRegisterPageRoutes.TABLE}/:registerId`}>
                        <WasteRegisterEditPage
                            promotionId={promotionId}
                            goBackTo={`/${WasteRegisterPageRoutes.TABLE}`}
                        />
                    </PrivateRoute>
                </PrivateRoute>

                <Redirect to={`${path}/${mode}`} />
            </Switch>

            <WasteRegisterEditPage promotionId={promotionId} goBackTo={`${path}/${mode}`} />
        </div>
    );
};

export default PromotionWasteRegisterPage;
