import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconFileCheck, IconAlertTriangle } from '@tabler/icons';
import RoundedIcon from '../../../components/BasicComponents/Icon/RoundedIcon';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import SmallText from '../../../components/BasicComponents/Text/SmallText';
import ModalEliminar from '../../../components/BasicComponents/Modales/ModalEliminar';
import { cambiarOrdenFecha } from '../../../utils/helpers/general.helpers';
import ActionMenuDocument from './ActionMenuDocument';
import './SiteDocument.scss';

const SiteDocument = ({ siteDocument, deleteDocument, customActions, dataTestid }) => {
    const [t] = useTranslation();
    const [openMenu, setOpenMenu] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const wrapperRef = useRef();

    const { size, permanent } = siteDocument;

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu, then close the menu
            if (openMenu && wrapperRef.current && !wrapperRef.current.contains(e.target)) {
                setOpenMenu(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [openMenu]);

    const getSubtitle = () => {
        let subtitle = siteDocument.documents[0].date
            ? `${cambiarOrdenFecha(siteDocument.documents[0].date)} - ${siteDocument.documents[0].name}`
            : siteDocument.documents[0].name;
        return subtitle;
    };

    return (
        <SimpleCard dataTestid={dataTestid} className="siteDocument">
            <div className="siteDocument__info">
                {size > 0 ? (
                    <RoundedIcon big={true} icon={<IconFileCheck />} />
                ) : (
                    <RoundedIcon big={true} color="orange" icon={<IconAlertTriangle color="#e3b100" />} />
                )}
                <div>
                    <p className="siteDocument__description"> {siteDocument.name}</p>
                    {size > 0 ? (
                        <SmallText className="siteDocument__descriptionFile" text={getSubtitle()} />
                    ) : (
                        <p className="siteDocument__noDocument">{t('noDocumentUploaded', { ns: 'documentation' })}</p>
                    )}
                </div>
            </div>
            {size > 0 && (
                <div ref={wrapperRef}>
                    <ActionMenuDocument
                        customActions={customActions}
                        onlyShowAction={permanent}
                        setOpenMenu={setOpenMenu}
                        openMenu={openMenu}
                        hasDocument={size > 0}
                        id={siteDocument.documents[0].id}
                        file={siteDocument.documents[0]}
                        setOpenModalDelete={setOpenModalDelete}
                    />
                </div>
            )}
            {openModalDelete && (
                <ModalEliminar
                    openModal={openModalDelete}
                    setOpenModal={setOpenModalDelete}
                    id={siteDocument?.documents[0]?.id}
                    modalDelay={0}
                    deleteFunction={deleteDocument}
                    title={`${t('delete', { ns: 'documentation' })}`}
                    subtitle={`${t('areYouSure', { ns: 'common' })} ${siteDocument?.name} - ${
                        siteDocument?.documents[0]?.name
                    }?`}
                />
            )}
        </SimpleCard>
    );
};
export default SiteDocument;
