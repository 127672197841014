import { ResponsiveBar } from '@nivo/bar';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import TooltipContent, {
    TooltipContentDate,
    TooltipContentLabel,
    TooltipContentValue
} from 'components/BasicComponents/Tooltips/TooltipContent';
import { useBarChart } from 'hooks/charts/useBarChart';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useTraceabilities } from 'modules/traceabilities';
import {
    TraceabilitiesSummaryCriteria,
    TraceabilitiesSummaryUnits
} from 'modules/traceabilities/domain/TraceabilitiesSummary';
import { getBarColor, getLerCodeColor, getTreatmentBarColor } from 'utils/helpers/colors.helpers';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import './TraceabilitiesSummaryChart.scss';
import {
    getBarsPerWidthTraceability,
    getSummaryMock,
    transformSummaryToGraphData
} from './utils/traceabilities.helpers';

type TraceabilitiesSummaryChartProps = TraceabilitiesSummaryCriteria;

const TraceabilitiesSummaryChart = ({ filters, measure, unit }: TraceabilitiesSummaryChartProps) => {
    const [t] = useTranslation();
    const { format } = useDateFormatter();
    const [{ loading, removed }, { loadTraceabilitiesSummaryByDate }] = useTraceabilities();

    const [{ summaryByDate }] = useTraceabilities();
    const { data: allData, series } = transformSummaryToGraphData(summaryByDate);

    const { slicedData, ref, showLeftButton, showRightButton, scrollLeft, scrollRight } = useBarChart({
        allData,
        idProp: 'date',
        resetObjects: [summaryByDate],
        barsPerWidth: getBarsPerWidthTraceability(),
        mode: 'sliceFromRightToLeft'
    });

    const [delayedDataLoaded, setDelayedDataLoaded] = useState(false);
    const dataLoaded = loading.summary === 'succeeded';

    const unitSymbols: { [key in TraceabilitiesSummaryUnits]: string } = {
        costs: '€',
        movements: 'traslados',
        tons: 'toneladas'
    };

    // In order to prevent innecesary updates,
    // extracts page and sort from criteria to only update when filters change

    useEffect(() => {
        if (!filters.promotionId) return;
        loadTraceabilitiesSummaryByDate({ measure, unit });
    }, [measure, unit, JSON.stringify(filters), JSON.stringify(removed)]);

    useEffect(() => {
        /* Animation hack to improve transition from skeleton to the real bar chart. More info: https://app.clickup.com/t/865d0t37f */
        if (dataLoaded) {
            setTimeout(() => {
                setDelayedDataLoaded(true);
            }, 1500);
        } else {
            setDelayedDataLoaded(false);
        }
    }, [dataLoaded]);

    const getColors = (label) => {
        if (!delayedDataLoaded) return getBarColor(label);
        if (measure === 'cod-ler') return getLerCodeColor(label);
        if (measure === 'treatment') return getTreatmentBarColor(label);

        return getBarColor(label);
    };

    const modifiers = [
        [delayedDataLoaded ? '' : 'TraceabilitiesSummaryChart--skeleton'],
        [series.length === 0 ? 'TraceabilitiesSummaryChart--noResults' : '']
    ];

    return (
        <div ref={ref} className={`TraceabilitiesSummaryChart ${modifiers.join(' ')}`}>
            <div>
                {series.length === 0 && delayedDataLoaded && (
                    <p className="TraceabilitiesSummaryChart__noResultsMessage">
                        {t('noResultsMsg', { ns: 'traceabilities' })}
                    </p>
                )}
                <div className="TraceabilitiesSummaryChart__loadingContent">
                    <div className="TraceabilitiesSummaryChart__chart">
                        {showLeftButton && delayedDataLoaded && (
                            <button
                                onClick={scrollLeft}
                                className="TraceabilitiesSummaryChart__scrollButton TraceabilitiesSummaryChart__scrollButton-left"
                            >
                                <IconChevronLeft />
                            </button>
                        )}
                        {showRightButton && delayedDataLoaded && (
                            <button
                                onClick={scrollRight}
                                className="TraceabilitiesSummaryChart__scrollButton TraceabilitiesSummaryChart__scrollButton-right"
                            >
                                <IconChevronRight />
                            </button>
                        )}
                        <ResponsiveBar
                            data={delayedDataLoaded && series.length > 0 ? slicedData : getSummaryMock().data}
                            keys={delayedDataLoaded && series.length > 0 ? series : getSummaryMock().series}
                            /*  animation activates AFTER skeleton loaded and BEFORE delayed data. The goals is to display
                        an inanimated skeleton (the result is weird) but an animated graph with the real data */
                            animate={dataLoaded}
                            indexBy="date"
                            margin={{
                                top: 10,
                                right: showRightButton ? 40 : 7.5,
                                bottom: 30,
                                left: showLeftButton ? 70 : 45
                            }}
                            padding={0.3}
                            valueScale={{ type: 'linear' }}
                            indexScale={{ type: 'band', round: true }}
                            colors={(bar) => {
                                const label = bar.id.split(':')[1];
                                return getColors(label);
                            }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize: 0,
                                tickPadding: 5,
                                tickRotation: slicedData.length > 24 ? -30 : 0,
                                legendPosition: 'middle',
                                format: (v) => format(v, 'MMM YY')
                            }}
                            axisLeft={{
                                tickSize: 1,
                                tickPadding: 5,
                                tickRotation: 0,
                                format: (v) => currencyFormatDE(v)
                            }}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            role="application"
                            theme={{
                                fontFamily: 'Be Vietnam Pro',
                                grid: {
                                    line: {
                                        strokeWidth: 1,
                                        strokeDasharray: '6 6'
                                    }
                                }
                            }}
                            onClick={(data) => {
                                const [value, label] = (data.id as string).split(':');
                                console.log({
                                    option: { value, label },
                                    filter: measure
                                });
                            }}
                            tooltip={(point) => {
                                const [, label] = (point.id as string).split(':');
                                const value = point.data[point.id];
                                return (
                                    <TooltipContent>
                                        <TooltipContentValue>
                                            {currencyFormatDE(value)} {unitSymbols[unit]}
                                        </TooltipContentValue>
                                        <TooltipContentLabel>{label}</TooltipContentLabel>
                                        <TooltipContentDate>
                                            {format(point.indexValue as string, 'MMMM YY')}
                                        </TooltipContentDate>
                                    </TooltipContent>
                                );
                            }}
                        />
                    </div>
                    <div className="TraceabilitiesSummaryChart__legend">
                        {series.map((legend, i) => {
                            const legendName = legend.split(':')[1];

                            return (
                                <div key={i} className="TraceabilitiesSummaryChart__legendElement">
                                    <div
                                        style={{
                                            backgroundColor: getColors(legendName)
                                        }}
                                    />
                                    <p title={legendName}>{legendName}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TraceabilitiesSummaryChart;
