import RolloutComparative from 'assets/rollout-comparative.png';
import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import './PromotionAnalysis.scss';

/** @deprecated - provisional message */
const PromotionAnalysis = () => {
    return (
        <>
            <div className="PromotionAnalysis">
                <TituloH1 titulo={'Nueva vista comparativa'} />
                <p>Nueva vista de comparativa de proyectos en el menú lateral</p>

                <img src={RolloutComparative} alt="Rollout Comparative" />

                <CreateButton to="/comparative" text="Ir a la nueva comparativa" icon={null} />
            </div>
        </>
    );
};

export default PromotionAnalysis;
