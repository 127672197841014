import { IconCheck, IconX } from '@tabler/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import IconButton from 'components/BasicComponents/Buttons/Small/IconButton';
import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { usePromotion } from 'modules/promotion';
import { PromotionKPIs, useGoalRemove } from 'modules/promotion-kpis';
import { PromotionKPIsGoal } from 'modules/promotion-kpis/domain/goals/PromotionKPIsGoal';
import { Loading } from 'modules/shared/domain/loading';
import { KPIGoalValueProps } from '../KPIGoalValue';
import './KPIGoal.scss';
import { useGoalForm } from './hooks/useGoalForm';
import { scopeFlowOptionAdapter, useGoalScopeFlowOptions } from './hooks/useGoalScopeFlowOptions';
import { severityOptionAdapter, useGoalSeverityOptions } from './hooks/useGoalSeverityOptions';

export interface KPIGoalProps {
    kpi: PromotionKPIs;
    goal?: PromotionKPIsGoal;
    KPIGoalValue: FC<KPIGoalValueProps>;
    className?: string;
    onSave?: (result: Loading) => void;
    onRemove?: (result: Loading) => void;
}

const KPIGoal = (props: KPIGoalProps) => {
    const [t] = useTranslation('KPIsGoalSettings');
    const [{ promotion }] = usePromotion();

    const { mutate: remove, loading: removing } = useGoalRemove();

    const { goal, mode, saving, update, save } = useGoalForm(props);

    const { loadScopeFlowOptions } = useGoalScopeFlowOptions(props);
    const { loadSeverityOptions } = useGoalSeverityOptions(props);

    const modifiers = [props.className || '', saving === 'failed' ? 'WMPlannerItem--mutation-error' : ''];

    return (
        <tr className={`KPIGoal ${modifiers.join(' ')}`} data-testid={`KPIGoal__${props.goal ? goal.uuid : 'empty'}`}>
            <td>
                <SelectAutocomplete
                    name="scope"
                    className="KPIGoal__selector"
                    placeholder={t(`placeholder.scope`)}
                    isClearable={false}
                    menuPosition="fixed"
                    value={goal.scope ? scopeFlowOptionAdapter(goal.scope) : undefined}
                    onChange={(option) =>
                        update({
                            scope: (option as SelectOption).value
                        })
                    }
                    loadOptions={loadScopeFlowOptions}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </td>
            <td>
                <SelectAutocomplete
                    name="severity"
                    className="KPIGoal__selector"
                    placeholder={t(`placeholder.severity`)}
                    isClearable={false}
                    menuPosition="fixed"
                    value={
                        goal.severity ? severityOptionAdapter({ severity: goal.severity, kpi: props.kpi }) : undefined
                    }
                    onChange={(option) => update({ severity: (option as SelectOption).value })}
                    loadOptions={loadSeverityOptions}
                />
            </td>
            <td>
                <InputNumber
                    name="progress"
                    extraElement={<span className="KPIGoal__input-unit">%</span>}
                    placeholderText={t(`placeholder.progress`)}
                    value={goal.progress !== null ? goal.progress : ''}
                    onChange={({ target }) => update({ progress: parseInt(target.value) })}
                    min="1"
                    max="100"
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </td>
            <td>
                <props.KPIGoalValue
                    key={goal.uuid} // To force re-render when goal changes
                    kpi={props.kpi}
                    value={goal.value}
                    update={update}
                />
            </td>
            <td>
                {mode === 'edit' && (
                    <ActionConfirm
                        onConfirm={async () => {
                            const { loading } = await remove({
                                goalId: goal.uuid,
                                kpi: props.kpi,
                                promotionId: promotion?.id || NaN
                            });
                            props.onRemove?.(loading);
                        }}
                    >
                        <IconButton
                            ariaLabel={t('delete')}
                            tabIndex={-1}
                            titulo="delete"
                            isLoading={saving === 'pending' || removing === 'pending'}
                            icon={<IconX size={16} />}
                            disabled={saving === 'pending' || removing === 'pending'}
                        />
                    </ActionConfirm>
                )}
                {mode === 'create' && (
                    <IconButton
                        ariaLabel={t('add')}
                        titulo="add"
                        action={() => save(goal as PromotionKPIsGoal)}
                        isLoading={saving === 'pending'}
                        icon={<IconCheck size={16} />}
                        disabled={saving === 'pending'}
                    />
                )}
            </td>
        </tr>
    );
};

export default KPIGoal;
