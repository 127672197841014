import { useEffect, useRef, useState } from 'react';
import uuid from 'react-uuid';

import { DynamicFormRef } from 'components/ComplexComponents/DynamicForm';
import {
    WasteRegisterExtended,
    createEmptyWasteRegister,
    useWasteRegisterApi,
    useWasteRegisterStore
} from 'modules/waste-register';
import { WasteRegisterFormProps } from '../WasteRegisterForm';

type EmptyWasteRegister = Partial<WasteRegisterExtended> & Pick<WasteRegisterExtended, 'id'>;

export function useWasteRegisterForm({ promotionId, id }: WasteRegisterFormProps) {
    const mode = !!id ? 'edit' : 'create';

    const create = () =>
        createEmptyWasteRegister({
            id: uuid(),
            promotionId,
            movementDate: new Date().toISOString().split('T')[0]
        });

    const { data: foundWr, loading, error } = useWasteRegisterApi.findById({ id: id || '' });
    const [store, actions] = useWasteRegisterStore();
    const [data, setWasteRegister] = useState<WasteRegisterExtended | EmptyWasteRegister>(create());
    const $extra = useRef<DynamicFormRef>(null);

    const update = (wr: Partial<typeof data>) => {
        if (!data) return;
        setWasteRegister({ ...data, ...wr });
    };

    const save = async () => {
        if (!data) return { data: null, error: new Error('No data to save') };
        const payload = data as WasteRegisterExtended;

        const extraResult = await $extra.current?.submit?.();
        if (extraResult?.errors) return { data: null, error: extraResult.errors };

        const result = await actions.saveWasteRegister(payload);
        return result;
    };

    const remove = async () => {
        if (!data) return { data: null, error: new Error('No data to remove') };
        return actions.removeWasteRegister(data as WasteRegisterExtended);
    };

    // Loads waste register data or creates a new one
    useEffect(() => {
        if (loading !== 'succeeded') return;
        if (mode === 'edit' && foundWr?.id) {
            setWasteRegister(foundWr);
        }
    }, [foundWr?.id, loading, mode]);

    // Updates volume with flow volume when flow changes
    useEffect(() => {
        if (data?.flow?.id === undefined) return;
        if (data.volume) return;

        update({ volume: data.flow.volume });
    }, [data?.flow?.id]);

    return {
        data,
        mode,
        isLoading: loading === 'pending' || store.updating[data.id],
        error: error || store.invalid[data.id],
        $extra,

        // actions
        update,
        save,
        remove
    };
}
