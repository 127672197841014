import { httpImpactApi } from 'modules/impact/infraestructure/api/httpImpactApi';
import { withAuth } from 'context/AuthProvider';
import ReportImpact from './ReportImpact';
import { ReportImpactDataProvider } from './store/reportImpactStore';

export default withAuth((props: any) => (
    <ReportImpactDataProvider api={httpImpactApi}>
        <ReportImpact {...props}></ReportImpact>
    </ReportImpactDataProvider>
));
