import { CSSProperties, PropsWithChildren } from 'react';

import { Tag } from 'modules/tags';
import './TagBadge.scss';
import { IconX } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

interface TagBadgeProps {
    name: Tag['name'];
    color: Tag['color'];
    onRemove?: () => void;
}

const TagBadge = (props: PropsWithChildren<TagBadgeProps>) => {
    const [t] = useTranslation('tags');
    const style = { '--_color': props.color } as CSSProperties;

    const modifiers = [props.onRemove ? 'TagBadge--removable' : 0];

    return (
        <div
            style={style}
            data-testid={`TagBadge-${props.name}`}
            className={`TagBadge ${modifiers.join(' ')}`}
            role="status"
        >
            <div className="TagBadge__text">{props.name}</div>
            {/* {props.children} */}

            {props.onRemove && (
                <button className="TagBadge__remove" aria-label={t('tags.removeTags')} onClick={props.onRemove}>
                    <IconX size={14} />
                </button>
            )}
        </div>
    );
};

export default TagBadge;
