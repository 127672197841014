import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import promotionServices from '../../../../api/promotion/promotion.services';
import { withAuth } from '../../../../context/AuthProvider';
import { customStyles } from '../../../BasicComponents/Select-Autocomplete/helpers';

const PromotionStateAutocomplete = ({ required, values, setValues, validacion, className }) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState(false);

    const { estadoPromocion } = values;

    const hasMore = (options) => {
        return options.length === 20 ? true : false;
    };

    const loadOptions = async (search, loadedOptions, { page }) => {
        let filter = search ? `?estado.contains=${search}` : `?page=${page}`;
        let options = await promotionServices.promotionState(filter);
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const emptyInput = () => {
        if (validacion === true && required && estadoPromocion === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    useEffect(() => {
        emptyInput();
    }, [validacion, estadoPromocion]);

    const selectOption = (item) => {
        setValues({
            ...values,
            estadoPromocion: item
        });
    };

    return (
        <div className={`input__container ${className || ''}`}>
            <label className="input__label">
                {t('labels.statePromotion', { ns: 'newPromotion' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={loadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.estado}
                getOptionValue={(option) => option}
                placeholder=""
                value={estadoPromocion}
                additional={{
                    page: 0
                }}
                styles={customStyles(notComplete)}
            />
        </div>
    );
};
export default withAuth(PromotionStateAutocomplete);
