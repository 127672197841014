import WasteRegisterDI from 'features/waste-register/components/WasteRegisterDI';
import { useRouteMatch } from 'react-router-dom';

const WasteRegisterDiPage = () => {
    const { params } = useRouteMatch<{ wasteRegisterDiUuid: string }>();

    return <WasteRegisterDI id={params.wasteRegisterDiUuid} />;
};

export default WasteRegisterDiPage;
