import { Thunk } from 'modules/shared/domain/store/createStore';
import { SupportStore } from 'modules/support/domain/SupportStore';
import { Ticket } from 'modules/support/domain/ticket/Ticket';

type Params = Pick<Ticket, 'topic' | 'title' | 'description' | 'extra'> & {
    user: { createdBy: string; email: string };
    promotion?: { id: number } | null;
};

export const sendTicket =
    (params: Params): Thunk<SupportStore, [Ticket | null, Error | null]> =>
    async (store, { api }) => {
        try {
            // If the ticket is related to a promotion, we will assign it to the promotion's admins
            let asignees = await api.findAsigneesByPromotionId(params.promotion?.id);

            // As fallback, we will assign the ticket to the creator
            if (asignees.length === 0) {
                const createdBy = params.user.createdBy;
                const isValidEmail = /.+@.+\..+/.test(createdBy);
                if (isValidEmail) asignees.push({ email: createdBy });
            }

            // Send the ticket
            const ticket = await api.sendTicket({
                title: params.title,
                description: params.description,
                creator: { email: params.user.email },
                asignees,
                topic: params.topic,
                extra: params.extra
            });

            return [ticket, null];
        } catch (e) {
            const error = e as Error;
            return [null, error];
        }
    };
