import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

import { RelatedEnterprisesStore } from '../../domain/RelatedEnterprisesStore';
import { createRelatedEnterprisesList } from '../../domain/createRelatedEnterprisesList';

export const fetchRelatedEnterprises =
    (criteria: { promotionId?: number }): Thunk<RelatedEnterprisesStore> =>
    async (store, { api }) => {
        store.dispatch('loadingSetted', { loading: store.state.loading === 'idle' ? 'pending' : 'updating' });

        try {
            const relatedEnterprises = await api.getRelatedEnterprisesByPromotionId(criteria.promotionId);
            const mappedRelatedEnterprises = createRelatedEnterprisesList(relatedEnterprises);

            store.dispatch('fetchFulfilled', { relatedEnterprises: mappedRelatedEnterprises });
        } catch (e) {
            console.error(e);
            store.dispatch('errorSetted', { error: e as Error });
        }
    };

export const fetchRelatedEnterprisesReducers: Pick<Reducers<RelatedEnterprisesStore>, 'fetchFulfilled'> = {
    fetchFulfilled(state, payload) {
        return { ...state, loading: 'succeeded', relatedEnterprises: payload.relatedEnterprises };
    }
};
