import { getYearMonthDay } from '../../utils/helpers/general.helpers';

export const INITIAL_STATE_PROMOTION = {
    direccion: '',
    nombre: '',
    num: '',
    nima: '',
    estadoPromocion: '',
    promotionType: '',
    recicla: '',
    surface: '',
    fechaFin: '',
    fechaInicio: '',
    endDateCocircular: '',
    startDateCocircular: '',
    selloValoriza: true,
    puntuacionSelloValoriza: '',
    selloVerde: '',
    porcentajeSelloVerde: '',
    usuarios: [],
    siteVisitNum: null,
    pem: null,
    startDateVisits: '',
    valorizacion: '',
    isDemo: false
};

export const INITIAL_STATE_DIRECTION = {
    tipo: '',
    direccion: '',
    municipio: '',
    postalCode: null
};

export const INITIAL_STATE_PPGCL = {
    carrierProGesCodLer: '',
    transferOperatorProGesCodLer: '',
    wasteManagerProGesCodLer: '',
    codLer: '',
    cost: '',
    fechaPresupuesto: getYearMonthDay(),
    volumenUnitario: ''
};

export const INITIAL_STATE_INFO = {
    gestora: '',
    codLer: '',
    treatment: ''
};

export const INITIAL_STATE_SEEKER = {
    gestora: '',
    codLer: ''
};
