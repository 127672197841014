import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import costsServices from '../../api/costs/costs.services';
import { withAuth } from '../../context/AuthProvider';
import Layout from '../Layout/Layout';
import GoBack from '../../components/BasicComponents/Buttons/GoBack';
import ResultadoCarga from '../../components/BasicComponents/Messages/ResultadoCarga';
import { TituloH1 } from '../../components/BasicComponents/Titulos/Titulos';
import Buscador from '../../components/BasicComponents/Buscador/Buscador';
import InputFecha from '../../components/BasicComponents/Inputs/InputFecha';
import SubmitButton from '../../components/BasicComponents/Buttons/SubmitButton';
import InputNumber from '../../components/BasicComponents/Inputs/InputNumber';
import TextArea from '../../components/BasicComponents/Inputs/TextArea';
import MessageNotComplete from '../../components/BasicComponents/Messages/MessageNotComplete';
import CodLerAutocomplete from '../../components/BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import PromoProGesCodLerAutocomplete from '../../components/BasicComponents/Select-Autocomplete/PromoProGesCodLerAutocomplete';
import { keyValueObjNotEmpty } from '../../utils/helpers/general.helpers.js';

const NuevoCoste = (props) => {
    const [t] = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const INITIAL_STATE = {
        coste: '',
        observaciones: '',
        codLer: null,
        fecha: '',
        gestora: null
    };
    const [values, setValues] = useState(INITIAL_STATE);
    const [modoEditar, setModoEditar] = useState(false);
    const [costeData, setCosteData] = useState();
    const [message, setMessage] = useState({});
    const [messageNotComplete, setMessageNotComplete] = useState('');
    const [creating, setCreating] = useState(false);
    // eslint-disable-next-line
    const [previousState, setPreviousState] = useState({});
    const [validacion, setValidacion] = useState(false);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (location.state !== undefined) {
                setPreviousState(location.state);
            }

            if (props.match.params.id !== undefined) {
                getDataCoste(props.match.params.id);
            } else {
                setModoEditar(false);
                setCosteData();
                setValues(INITIAL_STATE);
            }
        }
        return () => {
            mounted = false;
        };
    }, [location, props.promocion]);

    const getDataCoste = async (id) => {
        const coste = await costsServices.getCostById(props.promocion.id, id);
        comprobarPromocion(coste.promocion.id);
        setCosteData(coste);
        setValues({
            coste: coste.coste,
            observaciones: coste.observaciones,
            codLer: coste.codLer,
            fecha: coste.fecha,
            gestora: coste.gestora
        });
        setModoEditar(true);
    };

    /* 	const goBack = () => {
			history.push({
				pathname: '/costs',
				state: previousState
			});
		}; */

    const goBack = () => {
        history.push({
            pathname: `/costs`,
            state: keyValueObjNotEmpty(location.state?.values) ? { values: location.state.values } : null
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setCreating(true);
        let { codLer, coste, observaciones, fecha, gestora } = values;
        const { promocion } = props;
        if (modoEditar) {
            let costeDataCopy = JSON.parse(JSON.stringify(costeData));
            costeDataCopy = {
                ...costeDataCopy,
                promocion: props.promocion,
                coste,
                observaciones,
                codLer,
                fecha,
                gestora
            };
            setCosteData(costeDataCopy);
            const nuevoCoste = await costsServices.updateCost(costeDataCopy);

            if (nuevoCoste !== undefined) {
                setMessage({ success: true, text: t('success.edit', { ns: 'costs' }) });
            } else {
                setMessage({ success: false, text: t('cost.edit', { ns: 'errors' }) });
            }
        } else {
            const validarFormulario = validarForm();
            if (validarFormulario) {
                // creamos el documento
                if (codLer === '') {
                    codLer = null;
                }
                const nuevoCoste = await costsServices.createCost({
                    codLer,
                    coste,
                    observaciones,
                    promocion,
                    fecha,
                    gestora
                });
                setValues({
                    ...values,
                    coste: '',
                    observaciones: '',
                    codLer: null,
                    fecha: '',
                    gestora: null
                });

                if (nuevoCoste.status === 200 || nuevoCoste.status === 201) {
                    setMessage({ success: true, text: t('success.create', { ns: 'costs' }) });
                } else {
                    setMessage({ success: false, text: t('cost.create', { ns: 'errors' }) });
                }
            }
        }
        setCreating(false);
    };

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    const comprobarPromocion = (id) => {
        if (id !== props.promocion?.id) {
            history.push({
                pathname: '/costs',
                state: null
            });
        }
    };

    const validarForm = () => {
        if (!creating && values.coste !== '' && values.observaciones !== '') {
            setMessageNotComplete('');
            setValidacion(false);
            return true;
        } else {
            setMessageNotComplete(t('complete', { ns: 'common' }));
            setValidacion(true);
            return false;
        }
    };

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <TituloH1 titulo={t('new', { ns: 'costs' })} />
            <Buscador titulo="" column={true}>
                <form onSubmit={handleFormSubmit}>
                    <PromoProGesCodLerAutocomplete
                        {...{ values, setValues }}
                        value={values?.gestora}
                        INITIAL_STATE={null}
                        className="input__large"
                    />
                    <CodLerAutocomplete
                        {...{ values, setValues }}
                        value={values?.codLer}
                        INITIAL_STATE={null}
                        className="input__large"
                    />
                    <InputFecha
                        label={t('date.date', { ns: 'common' })}
                        id="fechaDesde-trazabilidad"
                        name="fecha"
                        value={values.fecha}
                        onChange={handleInputChange}
                    />
                    <InputNumber
                        label={t('amount', { ns: 'costs' })}
                        id="nuevocoste-importe"
                        name="coste"
                        value={values.coste}
                        onChange={handleInputChange}
                        validacion={validacion}
                        required
                    />
                    <TextArea
                        label={t('concept', { ns: 'common' })}
                        id="observaciones-nuevo-documento"
                        name="observaciones"
                        value={values.observaciones}
                        onChange={handleInputChange}
                        inputContainer="input__textarea"
                        validacion={validacion}
                        required
                    />
                    {message.text !== undefined && (
                        <ResultadoCarga text={message.text} success={message.success} setMessage={setMessage} />
                    )}
                    <SubmitButton text={t('add', { ns: 'costs' })} buttonDisabled={creating ? true : false} />
                    {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
                </form>
            </Buscador>
        </Layout>
    );
};

export default withAuth(NuevoCoste);
