import { ReactElement } from 'react';
import { IconAlertCircle, IconCircleCheck, IconClock } from '@tabler/icons';
import './Status.scss';

interface StatusProps {
    text: string;
    status: string;
    icon?: ReactElement | null;
}

const Status = ({ text, status, icon = null }: StatusProps) => {
    const statusIcon = () => {
        if (icon !== null) return icon;

        if (status === 'green') return <IconCircleCheck />;
        if (status === 'blue') return <IconClock />;
        if (status === 'red' || status === 'orange') return <IconAlertCircle />;

        return null;
    };

    return (
        <div className={`status status-${status || 'red'}`}>
            {statusIcon()}
            {text}
        </div>
    );
};

export default Status;
