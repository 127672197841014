import { IconArrowBarToDown, IconArrowBarUp } from '@tabler/icons';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import DragNDrop from 'components/BasicComponents/DragAndDrop/DragNDrop';
import BasicModal from 'components/BasicComponents/Modales/BasicModal';
import './SettingsExporter.scss';

interface SettingsExporterProps {
    onDownload: () => Promise<any>;
    onUpload: (payload: any) => Promise<void>;
    uploadMsg: ReactNode;
    defaultFileName?: string;
}

const SettingsExporter = (props: SettingsExporterProps) => {
    const [t] = useTranslation();

    const [loading, setLoading] = useState<'import' | 'export' | null>(null);

    const [importOpen, setImportOpen] = useState(false);
    const [files, setFiles] = useState<File[]>([]);

    const [downloadHref, setDownloadHref] = useState<string | null>(null);
    const [downloadFileName, setDownloadFileName] = useState<string | null>(null);
    const downloadRef = useRef<HTMLAnchorElement | null>(null);

    const download = async () => {
        setLoading('export');
        setDownloadHref(null);
        setDownloadFileName(null);

        const payload = await props.onDownload();

        const data = JSON.stringify(payload);
        const blob = new Blob([data], { type: 'application/json' });

        const url = URL.createObjectURL(blob);
        const filename = window.prompt(t('settings.exportMsg'), props.defaultFileName || '');

        if (!filename) {
            setLoading(null);
            return;
        }
        setDownloadHref(url);
        setDownloadFileName(filename);
    };

    const upload = async () => {
        setLoading('import');
        const file = files[0];
        // reads json from file
        const reader = new FileReader();
        reader.readAsText(file);

        reader.onload = async (e) => {
            const text = e.target?.result as string;
            const payload = JSON.parse(text);

            await props.onUpload(payload);
            setLoading(null);
        };
    };

    useEffect(() => {
        if (!downloadHref || !downloadFileName) return;
        downloadRef.current?.click();
        setLoading(null);
    }, [downloadHref, downloadFileName]);

    return (
        <>
            <div className="SettingsExporter">
                <SmallButton
                    type="button"
                    action={() => setImportOpen(true)}
                    titulo={t('settings.import')}
                    icon={<IconArrowBarUp />}
                    isLoading={loading === 'import'}
                    iconPosition="left"
                    // Just to ignore optional fields
                    {...({} as any)}
                />
                <BasicModal
                    testid="ImportModal"
                    actionText={t('settings.import')}
                    cancelText={t('cancel')}
                    refuse={false}
                    title={t('settings.import')}
                    openModal={importOpen}
                    disabledButton={files.length === 0}
                    setOpenModal={setImportOpen}
                    textCenter
                    actionFunction={upload}
                    onCloseModal={() => setFiles([])}
                    // Just to ignore optional fields
                    {...({} as any)}
                >
                    <div className="SettingsExporter__uploadMsg">{props.uploadMsg}</div>

                    <DragNDrop
                        label={' '}
                        files={files}
                        setFiles={setFiles}
                        format="file"
                        maxFiles={1}
                        accept={['application/json']}
                        // Just to ignore optional fields
                        {...({} as any)}
                    ></DragNDrop>
                </BasicModal>

                <SmallButton
                    type="button"
                    titulo={t('settings.export')}
                    action={download}
                    icon={<IconArrowBarToDown />}
                    isLoading={loading === 'export'}
                    iconPosition="left"
                    // Just to ignore optional fields
                    {...({} as any)}
                />
                {/* // eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a
                    style={{ display: 'none' }}
                    data-testid="SettingsExporter__link"
                    ref={downloadRef}
                    href={downloadHref || undefined}
                    download={`${downloadFileName}.json`}
                />
            </div>
        </>
    );
};

export default SettingsExporter;
