import { Reducers, createStore } from 'modules/shared/domain/store/createStore';

import { RelatedEnterprisesStore } from '../domain/RelatedEnterprisesStore';

import { fetchRelatedEnterprises, fetchRelatedEnterprisesReducers } from './fetch/fetchRelatedEnterprises';
import { addRelatedEnterprise, addRelatedEnterpriseReducers } from './add/addRelatedEnterprise';
import { changeRelatedEnterprise, changeRelatedEnterpriseReducers } from './update/changeRelatedEnterprise';
import { removeRelatedEnterprise, removeRelatedEnterpriseReducers } from './remove/removeRelatedEnterprise';

const reducers: Reducers<RelatedEnterprisesStore> = {
    // common
    errorSetted: (state, payload) => ({ ...state, error: payload.error, loading: 'failed' }),
    loadingSetted: (state, payload) => ({ ...state, loading: payload.loading }),

    ...fetchRelatedEnterprisesReducers,
    ...addRelatedEnterpriseReducers,
    ...changeRelatedEnterpriseReducers,
    ...removeRelatedEnterpriseReducers
};

export const relatedEnterprisesStore = createStore({
    initialState: {
        relatedEnterprises: [],
        loading: 'idle',
        error: null,
        criteria: null
    },
    reducers,
    thunks: {
        fetchRelatedEnterprises,
        addRelatedEnterprise,
        changeRelatedEnterprise,
        removeRelatedEnterprise
    }
});
