import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { usePromotion } from 'modules/promotion';
import './PromotionSelectAutocomplete.scss';

type PromotionOption = {
    id: number;
    name: string;
};

interface PromotionSelectAutocompleteProps {
    className?: string;
    label?: string;
    value?: PromotionOption | PromotionOption[];
    onChange?: (option: PromotionOption | PromotionOption[]) => void;
}

const PromotionSelectAutocomplete = (props: PromotionSelectAutocompleteProps) => {
    const [, , { api }] = usePromotion();

    const [loadOptions] = useAsyncOptions({
        request: async ({ search, page }) => {
            const { promotionPreviews } = await api.getPromotionsPreview({
                search,
                page: page || 0
            });
            return promotionPreviews;
        },
        adapter: (promo) => ({ value: promo.id, label: promo.name })
    });

    const multivalue: SelectOption[] | undefined = Array.isArray(props.value)
        ? props.value.map((v) => ({ value: v.id, label: v.name }))
        : [];

    const singlevalue: SelectOption | undefined =
        props.value && !Array.isArray(props.value) ? { value: props.value?.id, label: props.value?.name } : undefined;

    return (
        <div className="PromotionSelectAutocomplete">
            <SelectAutocomplete
                name="projects"
                dataTestId="PromotionSelectAutocomplete"
                className={props.className}
                value={multivalue || singlevalue}
                onChange={(option) => {
                    if (!option) return;

                    if (Array.isArray(option)) {
                        const promotions: PromotionOption[] = option.map((o) => ({
                            id: o.value,
                            name: o.label as string
                        }));

                        props.onChange?.(promotions);
                        return;
                    }

                    props.onChange?.({ id: option.value, name: option.label as string });
                }}
                label={props.label}
                loadOptions={loadOptions}
                isClearable={true}
            />
        </div>
    );
};

export default PromotionSelectAutocomplete;
