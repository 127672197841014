import { InvalidApproveDataException } from 'modules/di-extraction/domain/DiExtractionErrors';
import { DiExtractionStore } from 'modules/di-extraction/domain/DiExtractionStore';
import { IApproveDiExtractionData } from 'modules/di-extraction/domain/IApproveDiExtractionData';
import { IDiExtraction } from 'modules/di-extraction/domain/IDiExtraction';
import { Thunk } from 'modules/shared/domain/store/createStore';
import { fulfillUpdates, startUpdates } from './update.utils';

export const approveDiExtraction =
    (diExtraction: IApproveDiExtractionData): Thunk<DiExtractionStore, IDiExtraction | null> =>
    async (store, { api }) => {
        const extractionId = diExtraction.id;
        try {
            store.set(startUpdates([extractionId]));

            await api.approveDiExtraction(diExtraction);

            if (!store.state.criteria.promotionId) throw new Error('PromotionId not found');

            const { results } = await api.getDiExtractions({
                ids: [extractionId],
                promotionId: store.state.criteria.promotionId
            });

            const newDiExtraction = results.find((result) => result.id === extractionId);

            if (!newDiExtraction) throw new Error('DiExtraction not found');

            store.set(fulfillUpdates({ [extractionId]: newDiExtraction }));

            return newDiExtraction;
        } catch (e) {
            const error = e as Error;
            const isDomainError = error instanceof InvalidApproveDataException;

            store.set((draft) => {
                draft.error = isDomainError ? null : error;
                draft.updating = draft.updating.filter((id) => id !== diExtraction.id);

                if (isDomainError) {
                    draft.invalid = { ...draft.invalid, [diExtraction.id]: error as InvalidApproveDataException };
                }
            });

            return null;
        }
    };
