import { httpImpactApi } from 'modules/impact/infraestructure/api/httpImpactApi';
import { withAuth } from 'context/AuthProvider';

import { ImpactDataProvider } from './store/impactDataStore';
import ImpactData from './ImpactData';

export default withAuth((props: any) => (
    <ImpactDataProvider api={httpImpactApi}>
        <ImpactData {...props}></ImpactData>
    </ImpactDataProvider>
));
