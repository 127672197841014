import fetcher from '../../../lib/fetcher';

const _message = {
    success: {
        text: 'edit.success',
        ns: 'reuse'
    },
    error: {
        text: 'edit.error',
        ns: 'reuse'
    }
};

export const updateReuse = async (reuse) => {
    const URL = '/api/tracing/self-reuse-traceability';
    try {
        const response = await fetcher.put(URL, reuse, { message: _message });
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
