import { Reducers, createStore } from 'modules/shared/domain/store/createStore';

import { TraceabilitiesStore } from '../domain/TraceabilitiesStore';
import { downloadTraceabilities, downloadTraceabilitiesReducers } from './download/downloadTraceabilities';
import { loadOneTraceability, loadOneTraceabilityReducers } from './load/loadOneTraceability';
import { loadTraceabilities, loadTraceabilitiesReducers } from './load/loadTraceabilities';
import { loadTraceabilitiesCounters, loadTraceabilitiesCountersReducers } from './load/loadTraceabilitiesCounters';
import { loadTraceabilitiesStats, loadTraceabilitiesStatsReducers } from './load/loadTraceabilitiesStats';
import {
    loadTraceabilitiesSummaryByDate,
    loadTraceabilitiesSummaryByDateReducers
} from './load/loadTraceabilitiesSummaryByDate';
import {
    loadTraceabilitiesSummaryTotals,
    loadTraceabilitiesSummaryTotalsReducers
} from './load/loadTraceabilitiesSummaryTotals';
import { removeOneTraceability, removeOneTraceabilityReducers } from './remove/removeOneTraceability';

const reducers: Reducers<TraceabilitiesStore> = {
    ...loadTraceabilitiesReducers,
    ...loadTraceabilitiesStatsReducers,
    ...loadTraceabilitiesSummaryByDateReducers,
    ...loadTraceabilitiesCountersReducers,
    ...loadOneTraceabilityReducers,
    ...removeOneTraceabilityReducers,
    ...downloadTraceabilitiesReducers,
    ...loadTraceabilitiesSummaryTotalsReducers
};

export const traceabilitiesStore = createStore({
    reducers,
    initialState: {
        data: { allIds: [], byId: {} },
        total: 0,
        error: null,
        loading: {
            list: 'idle',
            stats: 'idle',
            summary: 'idle',
            detail: 'idle',
            counters: 'idle',
            download: 'idle'
        },
        criteria: null,
        removing: [],
        removed: [],
        stats: [],
        summaryByDate: [],
        summaryTotals: [],
        counters: {
            completedAndWarning: null
        }
    },
    thunks: {
        loadTraceabilities,
        loadTraceabilitiesStats,
        loadTraceabilitiesSummaryByDate,
        loadTraceabilitiesCounters,
        loadOneTraceability,
        removeOneTraceability,
        downloadTraceabilities,
        loadTraceabilitiesSummaryTotals
    }
});
