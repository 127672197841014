import PromotionInfoModal from 'features/promotion/pages/PromotionDetail/sections/PromotionInfo/PromotionInfoModal';
import './PromotionInfoModalPrintable.scss';

const PromotionInfoModalPrintable = () => {
    return (
        <>
            <div className="PromotionInfoModalPrintable">
                <PromotionInfoModal />
            </div>
        </>
    );
};

export default PromotionInfoModalPrintable;
