import { IconCheck, IconChevronDown, IconX } from '@tabler/icons';
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GoalFlagFill from 'assets/iconos/kpis/goal-flag-fill.svg';
import GoalFlagLine from 'assets/iconos/kpis/goal-flag-line.svg';
import EmptyState from 'components/BasicComponents/EmptyState';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import KPIStatusIcon from 'features/KPIs/components/KPIStatusIcon';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useNumberFormatter } from 'hooks/useNumberFormatter';
import { usePromotion } from 'modules/promotion';
import { FlowLerTypeDetail, MandatoryFlowsDetails, calcKPIStatusFromGoal } from 'modules/promotion-kpis';
import FlowTooltip from './FlowTooltip';
import './MandatoryFlowsOverview.scss';
import { calcGrValue } from './utils/calcGrValue';

type MandatoryFlowsOverviewProps = {
    mandatoryFlows?: MandatoryFlowsDetails | null;
};

const today = new Date();

const MandatoryFlowsOverview = (props: MandatoryFlowsOverviewProps) => {
    const hasGoalFlows = !!props.mandatoryFlows?.lerTypes.length;
    const hasOtherFlows = !!props.mandatoryFlows?.otherLerTypes.length;
    const isEmpty = !hasGoalFlows && !hasOtherFlows;
    const [t] = useTranslation('kpis');
    const date = useDateFormatter();
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [showOther, setShowOther] = useState(!hasGoalFlows);
    const [{ promotion }] = usePromotion();

    // Scrolls to the current month
    useLayoutEffect(() => {
        const calcScrollMovement = () => {
            const percentage = (promotion?.progress || 0) / 100;
            const container = scrollContainerRef.current;

            if (!container) return;
            const width = container.scrollWidth - container.clientWidth;
            const left = width * percentage;
            container.scrollTo({ left, behavior: 'smooth' });
        };
        calcScrollMovement();

        // On resize window, recalculate the scroll position
        window.addEventListener('resize', calcScrollMovement);
        return () => window.removeEventListener('resize', calcScrollMovement);
    }, [props.mandatoryFlows, promotion?.progress]);

    if (isEmpty)
        return (
            <div className="MandatoryFlowsOverview MandatoryFlowsOverview--empty">
                <EmptyState
                    mode="emptyResults"
                    title={t('mandatory-flows.empty.title')}
                    subtitle={t('mandatory-flows.empty.subtitle')}
                />
            </div>
        );

    const firstFlow = props.mandatoryFlows?.lerTypes[0] || props.mandatoryFlows?.otherLerTypes[0];

    return (
        <div className="MandatoryFlowsOverview">
            <div className="MandatoryFlowsOverview__container" ref={scrollContainerRef}>
                <table cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            {firstFlow?.history?.map((history, index) => {
                                const monthNth = 3;
                                return (
                                    <td key={history.month} className="MandatoryFlowsOverview__months">
                                        <span>{index % monthNth === 0 && date.format(history.month, 'MMM YY')}</span>
                                    </td>
                                );
                            })}
                            <td /> {/* Empty cell */}
                            <th>EGR (t)</th>
                            <th>PGR (t)</th>
                            <th>Real (t)</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <Row flows={props.mandatoryFlows?.lerTypes} />
                        {showOther && (
                            <>
                                <Row header flows={props.mandatoryFlows?.otherLerTypes} />
                                <Row flows={props.mandatoryFlows?.otherLerTypes} />
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            {hasOtherFlows && (
                <div
                    className="MandatoryFlowsOverview__showOther"
                    role="button"
                    onClick={() => setShowOther(!showOther)}
                >
                    <div className={`MandatoryFlowsOverview__showOtherButton ${showOther ? '--show' : '--hide'}`}>
                        <IconChevronDown size={20} onClick={() => setShowOther(!showOther)} />
                        {t('mandatory-flows.showOther')}
                    </div>
                </div>
            )}
            <div className="MandatoryFlowsOverview__legend">
                <div className="MandatoryFlowsOverview__legend-item">
                    <div className="MandatoryFlowsOverview__entry"></div>
                    {t('mandatory-flows.legend.withoutTraceabilities')}
                </div>
                <div className="MandatoryFlowsOverview__legend-item">
                    <div className="MandatoryFlowsOverview__entry MandatoryFlowsOverview__entry--filled"></div>
                    {t('mandatory-flows.legend.withTraceabilities')}
                </div>
                <div className="MandatoryFlowsOverview__legend-item">
                    <div className="MandatoryFlowsOverview__entry">
                        <img className="MandatoryFlowsOverview__flag" src={GoalFlagFill} />
                    </div>
                    {t('mandatory-flows.legend.mandatoryGoal')}
                </div>
                <div className="MandatoryFlowsOverview__legend-item">
                    <div className="MandatoryFlowsOverview__entry">
                        <img className="MandatoryFlowsOverview__flag" src={GoalFlagLine} />
                    </div>
                    {t('mandatory-flows.legend.recomendedGoal')}
                </div>
                <div className="MandatoryFlowsOverview__legend-item">*{t('mandatory-flows.legend.dangerous')}</div>
            </div>
        </div>
    );
};

type RowProps = {
    flows?: FlowLerTypeDetail[];
    currentColRef?: React.RefObject<HTMLTableDataCellElement>;
    header?: boolean;
};

const Row = (props: RowProps) => {
    const date = useDateFormatter();
    const numbers = useNumberFormatter();
    const currentMonth = date.format(today, 'YYYY-MM-01');
    const [t] = useTranslation('kpis');
    const [{ promotion }] = usePromotion();

    const endMonth = `${promotion?.fechaFin.slice(0, 7)}-01`;
    const startMonth = `${promotion?.fechaInicio.slice(0, 7)}-01`;

    if (props.header) {
        const firstFlow = props.flows?.[0];
        if (!firstFlow) return null;
        return (
            <tr key={'header'} className="MandatoryFlowsOverview__header">
                {firstFlow?.history?.map((entry) => {
                    const isCurrent = entry.month === currentMonth;
                    const isStart = entry.month === startMonth;
                    const isEnd = entry.month === endMonth;

                    const cellMods = [
                        isCurrent ? 'MandatoryFlowsOverview__cell--current MandatoryFlowsOverview__cell--marker' : '',
                        isStart ? 'MandatoryFlowsOverview__cell--marker' : '',
                        isEnd ? 'MandatoryFlowsOverview__cell--marker' : ''
                    ];

                    return <td key={entry.month} className={`MandatoryFlowsOverview__cell ${cellMods.join(' ')}`}></td>;
                })}
                <td /> {/* Empty cell */}
                <th colSpan={4}>{t('no-goals-flows')}</th>
            </tr>
        );
    }

    return (
        <>
            {props.flows?.map((flow, flowIndex) => {
                const { egrTons, pgrTons, severity = null, met } = flow;
                const status = calcKPIStatusFromGoal({ goal: { met, severity } });
                const hasGoals = met !== undefined;

                return (
                    <tr key={flow.lerTypeId}>
                        {flow.history?.map((entry, index) => {
                            const { accumulatedRealTons, totalTraceabilities } = entry;
                            const prevEntry = flow.history ? flow.history[index - 1] : undefined;

                            const egr = entry.goals?.find((goal) => goal.value.type === 'EGR');
                            const pgr = entry.goals?.find((goal) => goal.value.type === 'PGR');
                            const count = entry.goals?.find((goal) => goal.value.type === 'COUNT');
                            const isCritical = entry.met === false && entry.severity === 1;
                            const isWarning = entry.met === false && entry.severity === 2;

                            const isFuture = today < new Date(entry.month);
                            const isCurrent = entry.month === currentMonth;
                            const isStart = entry.month === startMonth;
                            const isEnd = entry.month === endMonth;

                            const value =
                                (isFuture && ' ') ||
                                (!!pgr && `${calcGrValue(accumulatedRealTons, pgrTons, { truncate: true })}%`) ||
                                (!!egr && `${calcGrValue(accumulatedRealTons, egrTons, { truncate: true })}%`) ||
                                (!!count && entry.goals?.some((g) => g.met === false) && (
                                    <IconX size={12} stroke={3} />
                                )) ||
                                (!!count && entry.goals?.every((g) => g.met === true) && (
                                    <IconCheck size={12} stroke={3} />
                                ));

                            const cellMods = [
                                isFuture ? 'MandatoryFlowsOverview__cell--future' : '',
                                isCurrent
                                    ? 'MandatoryFlowsOverview__cell--current MandatoryFlowsOverview__cell--marker'
                                    : '',
                                isStart ? 'MandatoryFlowsOverview__cell--marker' : '',
                                isEnd ? 'MandatoryFlowsOverview__cell--marker' : ''
                            ].join(' ');

                            const entryMods = [
                                totalTraceabilities > 0 ? 'MandatoryFlowsOverview__entry--filled' : '',
                                isCritical ? 'MandatoryFlowsOverview__entry--critical' : '',
                                isWarning ? 'MandatoryFlowsOverview__entry--warning' : '',
                                entry.met === true ? 'MandatoryFlowsOverview__entry--success' : ''
                            ].join(' ');

                            const fontSize =
                                (typeof value === 'string' && value.length < 4 && '1.1rem') ||
                                (typeof value === 'string' && value.length < 5 && '1rem') ||
                                '0.9rem';

                            return (
                                <td
                                    key={entry.month}
                                    className={`MandatoryFlowsOverview__cell ${cellMods}`}
                                    data-current={isCurrent}
                                >
                                    {entry.goals?.map((goal) =>
                                        prevEntry?.goals?.some((g) => g.uuid === goal.uuid) ? null : (
                                            <img
                                                className="MandatoryFlowsOverview__flag"
                                                src={
                                                    (goal.severity === 2 && GoalFlagLine) ||
                                                    (goal.severity === 1 && GoalFlagFill) ||
                                                    ''
                                                }
                                            />
                                        )
                                    )}
                                    <Tooltip msg={<FlowTooltip flow={flow} entry={entry} />}>
                                        <div className={`MandatoryFlowsOverview__entry ${entryMods}`} role="button">
                                            <div className="MandatoryFlowsOverview__entry-value" style={{ fontSize }}>
                                                {value}
                                            </div>
                                        </div>
                                    </Tooltip>
                                    {hasGoals && flowIndex === 0 && (
                                        <>
                                            {isCurrent && (
                                                <div className="MandatoryFlowsOverview__hint">{t('today')}</div>
                                            )}
                                            {isStart && (
                                                <div className="MandatoryFlowsOverview__hint">{t('start')}</div>
                                            )}
                                            {isEnd && <div className="MandatoryFlowsOverview__hint">{t('end')}</div>}
                                        </>
                                    )}
                                </td>
                            );
                        })}
                        <td /> {/* Empty cell */}
                        <th>{numbers.abreviate(flow.egrTons)}</th>
                        <th>{numbers.abreviate(flow.pgrTons)}</th>
                        <th>{numbers.abreviate(flow.realTons)}</th>
                        <th>
                            <div
                                className={`MandatoryFlowsOverview__entry-name MandatoryFlowsOverview__entry-name--${status}`}
                            >
                                {hasGoals && <KPIStatusIcon status={status} />}
                                <span>
                                    {flow.dangerous ? '*' : ''}
                                    {flow.name || `${flow.goalScope}-${flow.lerTypeId || 'default'}`}
                                </span>
                            </div>
                        </th>
                    </tr>
                );
            })}
        </>
    );
};

export default MandatoryFlowsOverview;
