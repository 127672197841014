import Admin from './Admin';
import Walle from './walle/Walle';

export const ADMIN_ROUTES = [
    {
        component: Admin,
        path: '/admin',
        section: 'admin',
        exact: false,
        requiredPermission: 'canView',
        children: {
            walle: {
                component: Walle,
                path: '/admin/walle'
            }
        }
    }
];
