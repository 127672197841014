import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import visitRecordServices from '../../../api/siteChecking/visitRecord.services';
import SaveButton from '../Buttons/SaveButton';
import InputFecha from '../Inputs/InputFecha';
import ResultadoCarga from '../Messages/ResultadoCarga';
import { cambiarOrdenFecha } from '../../../utils/helpers/general.helpers.js';
import SimplePopover from './SimplePopover';

const ConstructionSiteDatePopover = ({ activity, openModal, setOpenModal, updateActivity, index }) => {
    const [t] = useTranslation();
    const [values, setValues] = useState(activity);
    const [message, setMessage] = useState(null);

    const editDateActivity = async () => {
        const visitRecords = await visitRecordServices.getVisitRecordsByActivityId(activity?.id);

        if (visitRecords?.content?.response === undefined) {
            setMessage({ success: false, text: t('error.edit', { ns: 'activities' }) });
            return;
        }

        const updatedActivity = await visitRecordServices.updateConstructionSiteActivity({
            ...values,
            visitRecords: visitRecords?.content?.response
        });

        if (updatedActivity) {
            updateActivity(updatedActivity, index); // update activity in the list
            setMessage({ success: true, text: t('success.edit', { ns: 'activities' }) });
            setTimeout(() => {
                setOpenModal(false);
            }, 3000);
        } else {
            setMessage({ success: false, text: t('error.edit', { ns: 'activities' }) });
        }
    };

    const handleInputChange = ({ target }) => {
        if (target?.name === 'fechaActividad') {
            const activitySubject = `Visita a obra (${cambiarOrdenFecha(target?.value)})`;
            setValues({
                ...values,
                [target.name]: target.value,
                asunto: activitySubject
            });
        }
    };

    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={t('replan.label', { ns: 'visit' })}
            refuse={false}
            actionText={t('save', { ns: 'common' })}
            id={activity.id}
        >
            <InputFecha
                label={t('date.visitDate', { ns: 'common' })}
                name="fechaActividad"
                value={values?.fechaActividad}
                values={values}
                setValues={setValues}
                onChange={handleInputChange}
                notLimit={true}
            />
            <div className="constructionSiteDatePopover__saveButton">
                <SaveButton type="button" text={t('save', { ns: 'common' })} action={() => editDateActivity()} />
            </div>
            {message?.text && <ResultadoCarga text={message.text} setMessage={setMessage} success={message.success} />}
        </SimplePopover>
    );
};

export default ConstructionSiteDatePopover;
