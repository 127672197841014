import fetcher from '../../../lib/fetcher';

const _userMessage = {
    success: {
        text: 'messages.success.create',
        ns: 'newPpgcls'
    },
    error: {
        text: 'messages.error.create',
        ns: 'newPpgcls'
    }
};

export const createMassivePpgcls = async (arrPpgcls) => {
    const URL = `/api/maintenance/promo-pro-ges-cod-lers-massive`;
    try {
        const response = await fetcher.post(URL, arrPpgcls, { message: _userMessage });
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
