import Layout from 'pages/Layout/Layout';
import './ComparativePage.scss';
import Comparative from 'features/comparative/components/Comparative';

const ComparativePage = () => {
    return (
        <Layout
            navBar={false}
            padding={0}
            promocion={null}
            breadcrumbs={[
                {
                    name: '360 Advisor',
                    to: '/'
                },
                {
                    name: 'Comparativa de proyectos',
                    to: '/comparative'
                }
            ]}
        >
            <Comparative />
        </Layout>
    );
};

export default ComparativePage;
