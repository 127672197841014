import { JsonSchema } from 'modules/shared';
import { capitalize } from 'utils/helpers/general.helpers';

/*  Expected result example:
    {
        'item': 'Item',
        'project.locationName': 'Ubicación - nombre (Proyecto)',
        'project.country': 'Pais (Proyecto)',
        'storage.initialStorageDate': 'Fecha inicial almacen. (Almacenamiento)'
    }
*/
export const convertJSONSchemaToTranslationObject = (
    schema: JsonSchema,
    parentKey: string = '',
    parentTitle: string = ''
): Record<string, string> => {
    const titleMap: Record<string, string> = {};

    if (schema.properties) {
        for (const [key, value] of Object.entries(schema.properties)) {
            const fullKey = parentKey ? `${parentKey}.${key}` : key;
            const title = value.title ? (parentTitle ? `${value.title} (${parentTitle})` : value.title) : undefined;

            if (title) {
                titleMap[fullKey] = capitalize(title);
            }

            if (value.properties) {
                Object.assign(titleMap, convertJSONSchemaToTranslationObject(value, key, value.title || key));
            }
        }
    }

    return titleMap;
};
