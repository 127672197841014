import { IconAlertCircle } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import EnterpiseAutocomplete from 'components/BasicComponents/Select-Autocomplete/EnterpiseAutocomplete';
import Spinner from 'components/BasicComponents/Spinner/Spinner';

import promotionServices from 'api/promotion/promotion.services';
import { useAuth } from 'modules/auth';
import PromotionMultiSelect from '../../../dashboard/components/PromotionMultiSelect';
import '../Impact.scss';
import ImpactCo2 from '../ImpactCo2';
import ImpactLandUse from '../ImpactLandUse';
import { useImpactData } from './store/impactDataStore';

export default function ImpactData({ user, promocion, isCoCircular }) {
    const [t] = useTranslation();

    const [state, { fetchImpact }] = useImpactData();
    const [account] = useAuth();
    const { impact, loading } = state;

    const { equivalencies, impactCo2, impactLandUse } = impact;

    const [values, setValues] = useState({ enterprise: '', promotions: [] as Array<{ id: string }> });
    const promotions = values.promotions.map((promo) => promo.id).join() || promocion?.id;

    useEffect(() => {
        if (!promotions) return;

        fetchImpact({
            common: { 'promocion.in': promotions }
        });
    }, [promotions]);

    const searchUserPromotions = async (promotionIds) => {
        const promotions = await promotionServices.filterPromotion(`?id.in=${promotionIds}&size=999`);
        setValues({ ...values, promotions });
    };

    useEffect(() => {
        // Admin roles should filter by enterprise first
        if (account.user?.authorities.includes('ROLE_ADMIN')) return;

        const promotionIds = account?.user?.promotionIds;
        if (!promotionIds) return;
        searchUserPromotions(account?.user?.promotionIds);
    }, [account.user?.id]);

    return (
        <div className="Impact">
            {!promocion?.id && (
                <SimpleCard>
                    <div className="dashboard__filterContainer">
                        {isCoCircular && (
                            <EnterpiseAutocomplete
                                {...{ values, setValues }}
                                isClearable={true}
                                propName="enterprise"
                                promotion={values.promotions}
                            />
                        )}
                        <PromotionMultiSelect
                            values={values}
                            setValues={setValues}
                            isClearable={true}
                            propName="promotions"
                            enterprise={values.enterprise}
                            className="dashboard__select"
                        />
                    </div>
                </SimpleCard>
            )}

            {loading == 'pending' && <Spinner />}

            {(loading === 'warning' || loading == 'failed') && (
                <AlertCard className="card__alert-error" icon={<IconAlertCircle />}>
                    {loading === 'warning' && t('errors.someFailed', { ns: 'impact' })}
                    {loading === 'failed' && t('errors.failed', { ns: 'impact' })}
                </AlertCard>
            )}

            {loading !== 'pending' && loading !== 'failed' && (
                <>
                    <div>
                        <ImpactCo2 {...impactCo2} {...{ equivalencies, isCoCircular }} />
                    </div>
                    <div>
                        <ImpactLandUse {...impactLandUse} {...{ equivalencies }} />
                    </div>
                </>
            )}
        </div>
    );
}
