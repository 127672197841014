import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { hasPermission } from '../../utils/helpers/roles.helpers';
import { withAuth } from '../../context/AuthProvider';
import './HasPermissions.scss';

const HasPermission = ({ ifRole, section, children, has = false, disabledMsg = '', ...rest }) => {
    const { user, promocion } = rest;
    children = children || <></>; // if children is undefined

    const canShow = hasPermission(section, ifRole, user, promocion) || has;

    if (!canShow && disabledMsg) {
        return (
            <Tooltip className="HasPermissions__tooltip--disabled" msg={disabledMsg}>
                {children}
            </Tooltip>
        );
    }

    return canShow && children;
};

export default withAuth(HasPermission);
