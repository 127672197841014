import { useEffect, useState } from 'react';
import DragNDrop from '../../BasicComponents/DragAndDrop/DragNDrop';

const DragNDropContainer = ({
    label = 'Mostrar más',
    imageType = 'external',
    className = '',
    files,
    updateFiles,
    visitRecordIndex,
    section
}) => {
    const [newFiles, setNewFiles] = useState([]);

    useEffect(() => {
        // initial state
        setNewFiles(files);
    }, []);

    useEffect(() => {
        processImages();
    }, [newFiles]);

    const processImages = () => {
        const filesAux = [];

        newFiles?.forEach((file) => {
            filesAux.push({
                ...file,
                file: file?.file,
                title: file?.title || file?.name,
                name: addTypeBeforeName(file?.name),
                contentType: file?.contentType,
                type: imageType,
                preSignedUrl: file?.preSignedUrl
            });
        });

        updateFiles(filesAux, imageType, visitRecordIndex, section);
    };

    const addTypeBeforeName = (string) => {
        if (string !== 'deleted image') {
            const character = imageType === 'external' ? 'e' : 'i';
            string = character + '_' + string;
        }
        return string;
    };

    const deleteFile = (index) => {
        let filesAux = JSON.parse(JSON.stringify(files));
        let deletedFile = filesAux[index];

        if (deletedFile.id !== undefined) {
            // it already exists
            deletedFile = { ...deletedFile, file: 'deleted image' };
            filesAux[index] = deletedFile;
        } else {
            // the image was added right now, but its not saved in bbdd, so its better to remove it and thats it
            filesAux.splice(index, 1);
        }

        setNewFiles(filesAux);
    };

    return (
        <DragNDrop
            className={className}
            files={newFiles}
            setFiles={setNewFiles}
            label={label}
            fileProp="file"
            fileNameProp="name"
            contentTypeProp="contentType"
            fileTitleProp="title"
            removeFunction={deleteFile}
        />
    );
};

export default DragNDropContainer;
