import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../context/AuthProvider';
import { getValorizationType } from '../../impact/helpers/valorization.helpers';
import './LerLine.scss';
const LerLine = ({ ppgcl, checkboxHandleClick, newPpgcls }) => {
    const [t] = useTranslation();
    return (
        <div className="lerLine">
            <input
                type="checkbox"
                value={ppgcl}
                name={ppgcl.id}
                onChange={() => checkboxHandleClick(ppgcl)}
                checked={newPpgcls.find((newPpgcl) => (newPpgcl.ppgcl.id === ppgcl.id ? true : false))}
                selected={newPpgcls.find((newPpgcl) => (newPpgcl.ppgcl.id === ppgcl.id ? true : false))}
                className="checkbox wasteManagerResult__checkbox"
            />
            <p className="lerLine__info">
                {ppgcl.codLER.codigoLER}
                <span>{ppgcl.codLER.lerType.lerKind}</span> - {ppgcl.procesoGestora.valorizacion.codigoValorizacion} -{' '}
                <span>
                    {t(
                        `valorizationTypes.${getValorizationType({
                            valorizationCode: ppgcl.procesoGestora.valorizacion.codigoValorizacion,
                            isFinalValorizator: ppgcl.finalValorizator
                        })}`,
                        { ns: 'impact' }
                    )}
                </span>
                {ppgcl?.actualValorizationPercentage ? (
                    /** TODO: Unknown valorization percent should change to "null" */
                    ppgcl?.actualValorizationPercentage === 1 ? (
                        <span className="lerLine__error">- Falta el % valorización</span>
                    ) : (
                        <span className="lerLine__percent">- {ppgcl.actualValorizationPercentage}%</span>
                    )
                ) : (
                    ''
                )}
            </p>
        </div>
    );
};
export default withAuth(LerLine);
