import { Route, Redirect } from 'react-router-dom';
import { useAuth } from 'modules/auth';
import { withAuth } from '../../context/AuthProvider';

const AnonRoute = ({ component: Component, isLoggedin, pathRedirect, ...rest }) => {
    const [auth] = useAuth();

    if (auth.isLoggedin && auth.pathRedirect) {
        return <Redirect to={pathRedirect} />;
    }

    return <Route {...rest} component={Component} />;
};

export default withAuth(AnonRoute);
