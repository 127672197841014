import { AUTH_TOKEN_KEY } from 'modules/auth/domain/AuthConfig';
import { TokenStorage } from 'modules/auth/domain/TokenStorage';

export const localTokenStorage: TokenStorage = {
    async save(token) {
        localStorage.setItem(AUTH_TOKEN_KEY, token);
        return;
    },
    async get() {
        return localStorage.getItem(AUTH_TOKEN_KEY);
    },
    async delete() {
        localStorage.removeItem(AUTH_TOKEN_KEY);
    }
};
