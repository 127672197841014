import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import HasPermission from '../../../components/AccessCheckers/HasPermission';
import GoBack from '../../../components/BasicComponents/Buttons/GoBack';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import { withAuth } from '../../../context/AuthProvider';
import Layout from '../../../pages/Layout/Layout';
import { keyValueObjNotEmpty } from '../../../utils/helpers/general.helpers';
import {
    countTraceabilitiesDocument,
    filter,
    searchTraceabilitiesDocument,
    searchTraceabilitiesWithoutDocument
} from '../helpers/associateTraceabilities.helpers';
import './AssociateTraceabilities.scss';
import TraceabilitiesWithDocument from './TraceabilitiesWithDocument';
import TraceabilitiesWithoutDocument from './TraceabilitiesWithoutDocument';

export const AssociateTracabilitiesContext = createContext({});
const { Provider } = AssociateTracabilitiesContext;

const AssociateTraceabilitiesPage = (props) => {
    const history = useHistory();
    const DOCUMENT = history.location.state.documento;
    const PROMOTION = props.promocion;
    const INITIAL_PAGE = 0;
    const VALUES = history.location.state?.values;

    const [values, setValues] = useState({
        promocion: '',
        gestora: '',
        fechaDesde: '',
        fechaHasta: '',
        codLer: '',
        transportista: []
    });
    const [traceabilitiesDocument, setTraceabilitiesDocument] = useState([]);
    const [numberTraceabilitiesDocument, setNumberTraceabilitiesDocument] = useState([]);
    const [traceabilitiesWithoutDocument, setTraceabilitiesWithoutDocument] = useState([]);
    const [associatedTraceabilities, setAssociatedTraceabilities] = useState([]);
    const [loading, setLoading] = useState(false);

    const getTraceabilitiesWithoutDocument = async (page = INITIAL_PAGE) => {
        const getFilter = filter(page, values, DOCUMENT, PROMOTION);
        const traceabilities = await searchTraceabilitiesWithoutDocument(getFilter);
        return traceabilities;
    };

    const getData = async () => {
        setLoading(true);
        let traceabilitiesWithoutDocument = [];
        const [traceabilities, numberTraceabilitiesDocument] = await Promise.all([
            searchTraceabilitiesDocument(DOCUMENT, INITIAL_PAGE),
            countTraceabilitiesDocument(DOCUMENT)
        ]);
        setLoading(false);
        return { traceabilities, numberTraceabilitiesDocument, traceabilitiesWithoutDocument };
    };

    useEffect(() => {
        const abortController = new AbortController();
        if (PROMOTION?.id) {
            getData().then(({ traceabilities, numberTraceabilitiesDocument, traceabilitiesWithoutDocument }) => {
                setTraceabilitiesDocument(traceabilities);
                setAssociatedTraceabilities(traceabilities);
                setNumberTraceabilitiesDocument(numberTraceabilitiesDocument);
                setTraceabilitiesWithoutDocument(traceabilitiesWithoutDocument);
            });
        }

        return () => abortController.abort();
    }, [PROMOTION]);

    const goBack = () => {
        history.push({
            pathname: `/documents`,
            state: keyValueObjNotEmpty(VALUES) ? { values: VALUES } : null
        });
    };

    return (
        <Layout>
            <Provider
                value={{
                    traceabilitiesDocument,
                    setTraceabilitiesDocument,
                    traceabilitiesWithoutDocument,
                    setTraceabilitiesWithoutDocument,
                    getTraceabilitiesWithoutDocument,
                    numberTraceabilitiesDocument,
                    setNumberTraceabilitiesDocument,
                    associatedTraceabilities,
                    setAssociatedTraceabilities,
                    values,
                    setValues,
                    DOCUMENT
                }}
            >
                <div className="associateTraceabilities">
                    <GoBack goBack={goBack} />
                    <TraceabilitiesWithDocument />
                    <HasPermission ifRole="canCreate" section="traceability">
                        <TraceabilitiesWithoutDocument {...{ getTraceabilitiesWithoutDocument }} />
                    </HasPermission>
                </div>
                {loading && <Spinner />}
            </Provider>
        </Layout>
    );
};

export default withAuth(AssociateTraceabilitiesPage);
