import { TituloH2 } from '../Titulos/Titulos';
import './Cards.scss';

const SimpleCard = (
    {
        children,
        title = '',
        subtitle = '',
        tooltip = '',
        topRight = null,
        className = '',
        allowScroll = false,
        id = null,
        action = null,
        button = null,
        buttonTitle = null,
        buttonAction = null,
        dataTestid = '',
        ref = undefined,
        role = ''
    },
    column = false
) => {
    return (
        <div
            role={role}
            className={`card ${column ? 'card-col' : ''} ${allowScroll ? 'card-scroll' : ''} ${className || ''}`}
            ref={ref}
            id={id ? id : null}
            onClick={action && action}
            data-testid={dataTestid}
        >
            {(!!title || !!button) && (
                <div className="card__header">
                    <div className="card__header-title-container">
                        {title && (
                            <div className={button ? 'card__title card__title-button' : 'card__title'}>
                                {title !== '' && <TituloH2 titulo={title} tooltip={tooltip} />}
                                {topRight}
                            </div>
                        )}

                        {button && (
                            <button className="button button__small card__button" onClick={buttonAction}>
                                {buttonTitle}
                            </button>
                        )}
                    </div>

                    {subtitle && <div className="card__subtitle">{subtitle}</div>}
                </div>
            )}

            {children}
        </div>
    );
};

export default SimpleCard;
