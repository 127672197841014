import { useTranslation } from 'react-i18next';

import { DiExtrationStatus } from 'modules/di-extraction';
import './AutomaticReadingStatus.scss';

type AutomaticReadingStatusProps = {
    state: keyof typeof DiExtrationStatus;
};

const AutomaticReadingStatus = ({ state }: AutomaticReadingStatusProps) => {
    const [t] = useTranslation('automaticReading');

    return (
        <>
            <div className={`AutomaticReadingStatus AutomaticReadingStatus--${state}`}>
                <span>{t(`statusName.${state}`, { ns: 'automaticReading' })}</span>
            </div>
        </>
    );
};

export default AutomaticReadingStatus;
