import { useTraceabilities } from 'modules/traceabilities';
import { useTranslation } from 'react-i18next';

const TraceabilitiesSummaryTableSkeleton = () => {
    const [t] = useTranslation('traceabilities');
    const [{ loading, summaryTotals }] = useTraceabilities();
    const isEmpty = loading.summary === 'succeeded' && summaryTotals.length === 0;
    const isLoading = loading.summary === 'pending' || loading.summary === 'updating';
    const isError = loading.summary === 'failed';

    const modifiers = [
        'TraceabilitiesSummaryTable--skeleton',
        isLoading ? 'TraceabilitiesSummaryTable--loading' : '',
        isError ? 'TraceabilitiesSummaryTable--error' : '',
        isEmpty ? 'TraceabilitiesSummaryTable--empty' : ''
    ];

    return (
        <div className={`TraceabilitiesSummaryTable ${modifiers.join(' ')}`}>
            {isEmpty && <p className="TraceabilitiesSummaryTable__no-results-msg">{t('noResultsMsg')}</p>}
            <table>
                <thead>
                    <tr>
                        {[...new Array(4)].map((skeleton) => (
                            <th />
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {[...new Array(6)].map(() => (
                        <tr>
                            {[...new Array(4)].map((skeleton) => (
                                <td />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TraceabilitiesSummaryTableSkeleton;
