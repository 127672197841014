import { promotionStore } from 'modules/promotion/application/promotionStore';
import { contextStoreAdapter } from 'modules/shared/infraestructure/adapters/react/contextStoreAdapter';

const promotionContext = contextStoreAdapter(promotionStore)({ name: 'PromotionStore' });

export const usePromotion = promotionContext.useConsumer;
export const PromotionProvider = promotionContext.Provider;

export const useUpdatePromotion = promotionContext.createMutationHook(function ({ api }) {
    return api.updatePromotion;
});
