import { Traceability } from 'modules/traceabilities';
import { COLUMNS } from './constants/columns';

interface LerInfoProps {
    traceability: Traceability;
}

const LerInfo = ({ traceability }: LerInfoProps) => {
    const columnData = COLUMNS.find((column) => column.name === 'lerInfo') || null;
    if (!columnData) return null;

    return (
        <td data-testid="Traceability__lerInfo" className="TraceabilitiesTable__lerInfo">
            <p data-testid="Traceability__lerCode">{traceability.ler.code}</p>
            <p data-testid="Traceability__lerKind" title={traceability.ler.kind}>
                {traceability.ler.kind}
            </p>
        </td>
    );
};

export default LerInfo;
