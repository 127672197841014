import ComparativePage from '../pages/ComparativePage';

export const COMPARATIVE_ROUTES = [
    {
        component: ComparativePage,
        path: '/comparative',
        requiredPermission: 'canView',
        section: 'comparative',
        exact: false
    }
];
