import { IconBuildingFactory, IconTruck } from '@tabler/icons';
import { Traceability } from 'modules/traceabilities';
import GenericDataField from '../GenericDataField';
import { COLUMNS } from './constants/columns';

interface WasteManagerAndCarrierProps {
    traceability: Traceability;
}

const WasteManagerAndCarrier = ({ traceability }: WasteManagerAndCarrierProps) => {
    const columnData = COLUMNS.find((column) => column.name === 'wasteManagerAndCarrier') || null;
    if (!columnData) return null;

    const { carrier, wasteManager } = traceability;

    const format = (name?: string) => {
        if (!name) return '-';
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    return (
        <td className="TraceabilitiesTable__wasteManagerAndCarrier">
            <GenericDataField
                text={format(wasteManager.name)}
                icon={<IconBuildingFactory />}
                title={format(wasteManager.name)}
                testid="Traceability__wasteManager"
            />
            <GenericDataField
                text={format(carrier.name)}
                icon={<IconTruck />}
                title={format(carrier.name)}
                testid="Traceability__carrier"
            />
        </td>
    );
};

export default WasteManagerAndCarrier;
