import { AgGridReact } from 'ag-grid-react';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useExcelExport } from 'lib/ag-grid/hooks/useExcelExport';
import { useFiltersTranslation } from 'lib/ag-grid/hooks/useFiltersTranslation';
import { RefObject } from 'react';
import { getGoalsSummaryText, getLastUpdateDate } from '../helpers/comparative.helpers';

interface UseComparativeExport {
    gridRef: RefObject<AgGridReact>;
}

export function useComparativeExport({ gridRef }: UseComparativeExport) {
    const { format } = useDateFormatter();
    const { exportAsExcel } = useExcelExport();
    const { getFiltersString } = useFiltersTranslation({ i18nNamespace: 'comparative' });

    if (!gridRef?.current?.api) return { exportComparativeAsCsv: () => {}, exportComparativeAsExcel: () => {} };

    const currentTime = format(new Date(), 'YYYY/MM/DD_HH:mm');
    const lastUpdate = format(getLastUpdateDate(), 'HH:mm');
    const fileName = 'Comparativa--' + currentTime;

    // Filters
    const filtersString = getFiltersString(gridRef.current.api.getFilterModel());
    const filtersInOneLine = filtersString.replaceAll('\n', ', '); // Avoid rows overlap

    const prependString = `Fecha y hora de descarga: ${currentTime} (última actualización de datos a las ${lastUpdate}). ${filtersInOneLine}`;

    const exportComparativeAsCsv = () => {
        gridRef.current?.api.exportDataAsCsv({
            allColumns: true,
            fileName,
            prependContent: prependString,
            processCellCallback: (params) => {
                const columnId = params.column.getId();

                if (typeof params.value === 'boolean' && params.value) return 'Sí';
                if (typeof params.value === 'boolean' && !params.value) return 'No';

                // UPDATE IF DTO/TABLE CHANGES
                switch (columnId) {
                    case 'promotion.managers':
                    case 'promotion.tags':
                        return params.value?.map((entry) => entry?.name).join(', ');
                    case 'promotion.users':
                        return params.value?.map((entry) => entry?.email).join(', ');
                    default:
                        return params.value;
                }
            }
        });
    };

    const exportComparativeAsExcel = () => {
        exportAsExcel({
            fileName,
            gridRef,
            prependString,
            worksheetName: 'Comparativa',
            customizeValuesFn: (rowData) => {
                const result: Record<string, any> = {};

                for (const key in rowData) {
                    if (Object.prototype.hasOwnProperty.call(rowData, key)) {
                        const value = rowData[key];
                        let newValue = value; // Current value as default

                        // Booleans
                        if (typeof value === 'boolean' && value) newValue = 'Sí';
                        if (typeof value === 'boolean' && !value) newValue = 'No';

                        // Goals summary
                        if (key === 'goals.summary') newValue = getGoalsSummaryText(value);

                        // Arrays
                        if (key === 'promotion.users') newValue = value?.map((entry) => entry?.email).join(', ');
                        if (key === 'promotion.managers' || key === 'promotion.tags')
                            newValue = value?.map((entry) => entry?.name).join(', ');

                        result[key] = newValue;
                    }
                }

                // Manually modifying goals. Ugly code
                result['goals.valorization'] = rowData['goals.valorization.value'];
                result['goals.mix'] = rowData['goals.mix.value'];
                result['goals.traceabilityUpdate'] = rowData['goals.traceabilityUpdate.daysPassed'];
                result[
                    'goals.mandatoryFlows'
                ] = `${rowData['goals.mandatoryFlows.segregated']}/${rowData['goals.mandatoryFlows.total']}`;
                result[
                    'goals.treatmentContracts'
                ] = `${rowData['goals.treatmentContracts.flowsWithContract']}/${rowData['goals.treatmentContracts.totalFlows']}`;

                return result;
            }
        });
    };

    return { exportComparativeAsCsv, exportComparativeAsExcel };
}
