import { useTranslation } from 'react-i18next';

import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { Ler } from 'modules/ler';
import { useWasteManagerStore, WasteManager } from 'modules/waste-manager';
import './WasteManagerSelector.scss';
import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';

export type WasteManagerSelectorProps = {
    onChange?: (ler: WasteManager) => void;
    value?: WasteManager;
    isClearable?: boolean;
    disabled?: boolean;
    filters?: {
        promotionId?: number;
        ler?: Ler;
    };
};

const wasteManagerOptionAdapter = (manager): SelectOption => ({
    value: manager.name,
    label: manager.name,
    manager
});

const WasteManagerSelector = (props: WasteManagerSelectorProps) => {
    const { filters } = props;
    const [t] = useTranslation();
    const [, , { api }] = useWasteManagerStore();

    const [loadWasteManagerOptions] = useAsyncOptions({
        removePagination: true,
        adapter: wasteManagerOptionAdapter,
        request: ({ search }) =>
            api.findWasteManagerByCriteria({
                promotionId: filters?.promotionId,
                search,
                size: 999, // Because we need to group by manager in the frontend
                ler: { code: filters?.ler?.code }
            })
    });

    return (
        <SelectAutocomplete
            // Forces re-render when the filters change to reload the options with the new filters
            key={JSON.stringify(filters)}
            dataTestId="manager-selector"
            name="manager"
            isClearable={props.isClearable}
            label={t('wasteManager')}
            loadOptions={loadWasteManagerOptions}
            defaultOptions={false}
            disabled={props.disabled}
            value={props.value ? wasteManagerOptionAdapter(props.value) : undefined}
            onChange={(option) => {
                if (Array.isArray(option)) return;
                props.onChange?.(option?.manager);
            }}
        />
    );
};

export default WasteManagerSelector;
