import fetcher from 'lib/fetcher';
import { SupportApi } from 'modules/support/domain/SupportApi';

const supportBaseUrl = process.env.REACT_APP_SUPPORT_API_URL;

export const httpSupportApi: SupportApi = {
    async sendTicket(payload) {
        // send as FormData
        const body = new FormData();
        body.append('title', payload.title);
        body.append('description', payload.description);
        body.append('topic', payload.topic);
        body.append('creator', payload.creator.email);
        payload.asignees.forEach((asignee) => body.append('asignees', asignee.email));

        if (payload.extra?.url) body.append('url', payload.extra.url);
        payload.extra?.attachments?.forEach((file) => body.append('attachments', file));

        const ticket = await fetcher.post(`${supportBaseUrl}/api/ticket/send`, body).then((r) => r.data);
        return ticket;
    },
    async findTopics() {
        const { data } = await fetcher.get(`${supportBaseUrl}/api/ticket/topics`);
        return data;
    },
    async findAsigneesByPromotionId(promotionId) {
        if (!promotionId) return [];

        const users = await fetcher.get(`/api/promotion/${promotionId}/users`).then((r) => r.data.content);
        const admins = users.filter((u) => u.authorities.includes('ROLE_ADMIN'));
        return admins.map((u) => ({ email: u.email }));
    }
};
