import { IconSettings } from '@tabler/icons';
import SecondaryButton from 'components/BasicComponents/Buttons/SecondaryButton';
import DropdownList from 'components/BasicComponents/DropdownList';
import { useAdditionalData } from 'features/home/hooks/useAdditionalData';
import useStateFromStorage from 'hooks/useStateFromStorage';
import { PromotionPreview as IPromotionPreview } from 'modules/promotion/domain/promotion/PromotionPreview';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './VisibleDataDropdown.scss';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
interface VisibleDataDropdownProps {
    changeVisibleData: (newData) => void;
}

const VisibleDataDropdown = ({ changeVisibleData }: VisibleDataDropdownProps) => {
    const [t] = useTranslation('home');
    const [, analytics] = useAnalyticsStore();
    const { additionalData } = useAdditionalData();
    const [visibleItems, setVisibleItems] = useState<Array<string>>([]);

    const initialVisibleData: Array<keyof IPromotionPreview['additionalData']> = [
        'latestUpdate',
        'mainEnterpriseName',
        'province',
        'progressPercentage',
        'endDate',
        'surface',
        'type'
    ];
    const [localStorageVisibleData, setLocalStorageVisibleData] = useStateFromStorage({
        key: 'VisiblePromotionData',
        initialState: JSON.stringify(initialVisibleData)
    });

    useEffect(() => {
        const parsedVisibleData = JSON.parse(localStorageVisibleData as string);

        setVisibleItems(parsedVisibleData); // update state (needed for checkboxes)
        changeVisibleData(parsedVisibleData); // update parent
    }, [localStorageVisibleData]);

    const changeSelectedItems = (event, prop) => {
        const { checked } = event.target;

        let newData: Array<string>;
        if (checked) {
            newData = [...visibleItems, prop];
        } else {
            newData = visibleItems.filter((item) => item !== prop);
        }

        analytics.event(AnalyticsEvents.HOME_SETTINGS_CHANGED);
        setLocalStorageVisibleData(JSON.stringify(newData)); // update localstorage
    };

    return (
        <div className="VisibleDataDropdown">
            <DropdownList
                closeOnItemClick={false}
                items={additionalData.map((item, i) => (
                    <p className="VisibleDataDropdown__item" key={i}>
                        <input
                            id={item.propName}
                            name={item.propName}
                            type="checkbox"
                            onChange={(event) => changeSelectedItems(event, item.propName)}
                            checked={visibleItems.includes(item.propName)}
                        />
                        <label htmlFor={item.propName}>
                            <span>{t(item.translationKey)}</span>
                        </label>
                    </p>
                ))}
            >
                <SecondaryButton titulo="Ajustes" icon={<IconSettings />} type="button" {...({} as any)} />
            </DropdownList>
        </div>
    );
};

export default VisibleDataDropdown;
