import fetcher from 'lib/fetcher';
import { RelatedEnterprisesApi } from '../../domain/RelatedEnterprisesApi';

export const httpRelatedEnterprisesApi: RelatedEnterprisesApi = {
    async getRelatedEnterprisesByPromotionId(promotionId: number) {
        const { data } = await fetcher(`/api/maintenance/promotions?id.equals=${promotionId}`);
        const project = data[0];
        return project.relatedEnterprises;
    }
};
