export function groupHeadersAdapter<E extends { [key: string]: any }>(data: E[], sortKey: keyof E) {
    return data.reduce((acc, item, index) => {
        const prevItem = data[index - 1];

        const prevSortKey = prevItem?.[sortKey];
        const currentSortKey = item[sortKey];

        if (!prevSortKey || prevSortKey !== currentSortKey) {
            return [...acc, { type: 'header' as const, group: currentSortKey, sortKey }, item];
        }

        return [...acc, item];
    }, [] as (E | { type: 'header'; group: any })[]);
}
