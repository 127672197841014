import { IconPinned } from '@tabler/icons';
import { useHistory } from 'react-router-dom';

import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { withAuth } from 'context/AuthProvider';
import { useAdditionalData } from 'features/home/hooks/useAdditionalData';
import { Tags } from 'features/tags';
import { TagButProjects } from 'features/tags/components/Tags/Tags';
import { useAuth } from 'modules/auth';
import {
    PromotionPreview as IPromotionPreview,
    fakePromotionPreview
} from 'modules/promotion/domain/promotion/PromotionPreview';
import PromotionItem from './PromotionItem';
import './PromotionPreview.scss';

interface PromotionPreviewProps {
    promotionPreview?: IPromotionPreview;
    changePromotion: (promotionData) => {};
    visibleData: Array<keyof IPromotionPreview['additionalData']>;
    skeleton?: boolean;
    changePinnedPreviews?: (id: number) => void;
    pinned?: boolean;
}

const PromotionPreview = ({
    promotionPreview = fakePromotionPreview,
    changePromotion,
    visibleData,
    skeleton,
    changePinnedPreviews,
    pinned
}: PromotionPreviewProps) => {
    const history = useHistory();
    const { additionalData } = useAdditionalData();
    const [account] = useAuth();

    const goToPromotion = () => {
        changePromotion(promotionPreview);
        history.push(`/promotion/${promotionPreview.id}`);
    };

    const handlePinClick = (event) => {
        event.stopPropagation();
        if (!changePinnedPreviews) return;
        changePinnedPreviews(promotionPreview.id);
    };

    const modifiers = {
        isSkeleton: skeleton ? 'PromotionPreview--is-skeleton' : ''
    };

    return (
        <SimpleCard
            role="listitem"
            className={`PromotionPreview ${Object.values(modifiers).join(' ')}`}
            action={(() => goToPromotion()) as any}
        >
            <header className={`PromotionPreview__title ${pinned && 'PromotionPreview__title--pinned'}`}>
                <p title={promotionPreview.name}>{promotionPreview.name}</p>
                <IconPinned onClick={(event) => handlePinClick(event)} />
            </header>
            <div className="PromotionPreview__additionalData">
                {additionalData?.map((data, i) =>
                    visibleData.includes(data.propName) ? (
                        <PromotionItem key={i} propName={data.propName}>
                            {promotionPreview.additionalData[data.propName]?.toString()}
                        </PromotionItem>
                    ) : (
                        <></>
                    )
                )}
            </div>
            <footer className="PromotionPreview__footer">
                <Tags
                    tags={promotionPreview.tags as TagButProjects[]}
                    enterpriseId={account.user?.enterprise.id || NaN}
                    promotionId={promotionPreview.id}
                />
            </footer>
        </SimpleCard>
    );
};

export default withAuth(PromotionPreview);
