import { withAuth } from '../../../context/AuthProvider';
import IconButton from '../Buttons/Small/IconButton';
import './VerticalStepper.scss';

const VerticalStepper = ({ steps, activeStep, setActiveStep, children }) => {
    const isCurrent = (order) => {
        return activeStep === order ? true : false;
    };
    return (
        <div className="verticalStepper">
            <div className="verticalStepper__steps">
                {steps.map((step) => (
                    <div className="verticalStepper__step" key={step.id} onClick={() => setActiveStep(step.order)}>
                        <span
                            className={
                                isCurrent(step.order)
                                    ? `verticalStepper__circle verticalStepper__circle--current`
                                    : `verticalStepper__circle`
                            }
                        >
                            {step.order}
                        </span>
                        <p>{step.title}</p>
                    </div>
                ))}
            </div>
            <div className="verticalStepper__children">
                {children}
                <div className="verticalStepper__containerButtons">
                    {activeStep !== 1 && <IconButton text="Anterior" action={() => setActiveStep(activeStep - 1)} />}
                    {activeStep !== 5 && <IconButton text="Siguiente" action={() => setActiveStep(activeStep + 1)} />}
                </div>
            </div>
        </div>
    );
};

export default withAuth(VerticalStepper);
