import MultiProvider from 'lib/MultiProvider';
import { PromotionProvider } from 'modules/promotion';
import { httpPromotionApi } from 'modules/promotion/infrastructure/api/httpPromotionApi';
import { TagsProvider, httpTagsApi } from 'modules/tags';
import PromotionList from './PromotionList';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <MultiProvider
        providers={[
            // For preview cards and search
            <PromotionProvider api={httpPromotionApi} />,
            // To add/remove tags
            <TagsProvider api={httpTagsApi} />
        ]}
    >
        <PromotionList />
    </MultiProvider>
);
