const SubmitButton = ({ icon = <></>, text = '', buttonDisabled = false, onClickButton = () => {} }) => {
    return (
        <button
            type="submit"
            onClick={onClickButton ? onClickButton : null}
            disabled={buttonDisabled}
            className={buttonDisabled ? 'buttonAux button__blue button__disabled' : 'buttonAux button__blue'}
        >
            <span>
                {icon && icon}
                {text}
            </span>
        </button>
    );
};
export default SubmitButton;
