import { IconChevronUp } from '@tabler/icons';
import { Dispatch, PropsWithChildren, SetStateAction, useEffect } from 'react';
import Headline from '../Headline/Headline';
import './Accordion.scss';

interface AccordionProps {
    text: string;
    count?: number | string;
    children: React.ReactNode;
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const Accordion = ({ text, isOpen, setIsOpen, count = '...', children }: PropsWithChildren<AccordionProps>) => {
    const toggleAccordion = () => {
        setIsOpen(!isOpen);

        const contentHtml = document.getElementById(`content-${text}`);
        if (!contentHtml) return;

        // isOpen is not the new value, is the current one
        contentHtml.style.height = isOpen ? '0px' : contentHtml.scrollHeight + 'px';
    };

    const initializeContentHeight = () => {
        const contentHtml = document.getElementById(`content-${text}`);
        if (!contentHtml) return;

        // We change height two times to skip the first open animation when content loads:
        // First we set 100% to see all content opened without animation
        contentHtml.style.height = '100%';
        // And then we set the trick ready for the interaction when user clicks the accordion
        contentHtml.style.height = contentHtml.scrollHeight + 'px';
    };

    useEffect(() => {
        if (isOpen) {
            initializeContentHeight();
        }
    }, [children]);

    const modifiers = [isOpen ? 'Accordion--is-open' : ''];

    return (
        <div className={`Accordion ${modifiers.join(' ')}`}>
            <button className="Accordion__header" type="button" onClick={() => toggleAccordion()}>
                <Headline>
                    <span>{text}</span>
                    {count !== undefined && <span> ({count})</span>}
                </Headline>
                <IconChevronUp id={text + '__arrow'} />
            </button>
            <div id={`content-${text}`} className="Accordion__content">
                {children}
            </div>
        </div>
    );
};

export default Accordion;
