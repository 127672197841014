import Accordion from 'components/BasicComponents/Accordion';
import PromotionPreview from 'features/home/components/Promotion/PromotionPreview';
import PromotionSearchBar from 'features/home/components/SearchBar/PromotionSearchBar';
import VisibleDataDropdown from 'features/home/components/VisibleDataDropdown/VisibleDataDropdown';
import { usePromotion } from 'modules/promotion';
import { PromotionStatus } from 'modules/promotion/domain/promotion/PromotionPreview';
import { arrayOptionalSearchFor } from 'modules/promotion/domain/promotion/PromotionPreviewCriteria';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import './PromotionList.scss';
import useStateFromStorage from 'hooks/useStateFromStorage';
import { AnalyticsEvents, useAnalyticsEvent } from 'modules/analytics';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';

const PromotionList = () => {
    const [t] = useTranslation('home');
    const analytics = useAnalyticsEvent(AnalyticsEvents.HOME_LIST_REACHED);

    const [
        { error, promotionPreviews, loadingPromotionPreviews, totalPromotionPreviews, promotionPreviewCriteria },
        { loadPromotionPreviews }
    ] = usePromotion();

    const [localStoragePinnedPreviews, setLocalStoragePinnedPreviews] = useStateFromStorage({
        key: 'PinnedPromotionPreviews'
    });

    const [visibleData, setVisibleData] = useState([]);

    const [search, setSearch] = useState<string>('');
    const [openInProgressAccordion, setOpenInProgressAccordion] = useState(true);
    const [openFinishedAccordion, setOpenFinishedAccordion] = useState(true);

    const VISIBLE_PROMOTION_PREVIEWS = 12;
    const statusArray: Array<PromotionStatus> = ['inProgress', 'finished'];
    const promotionsPreviewGrouped = {
        inProgress: promotionPreviews.filter((preview) => preview.status === 'inProgress'),
        finished: promotionPreviews.filter((preview) => preview.status === 'finished')
    };

    const changeVisibleData = (newVisibleData) => {
        setVisibleData(newVisibleData);
    };

    const changePinnedPreviews = (previewId) => {
        const isPreviewPinned = checkIfPinned(previewId);
        const parsedPinnedPreviews = JSON.parse(localStoragePinnedPreviews as string) || [];
        let newDataPinned: Array<number>;

        if (isPreviewPinned) {
            newDataPinned = parsedPinnedPreviews.filter((id) => id !== previewId);
            analytics.event(AnalyticsEvents.HOME_PROJECT_UNPINNED);
        } else {
            newDataPinned = [...parsedPinnedPreviews, previewId];
            analytics.event(AnalyticsEvents.HOME_PROJECT_PINNED);
        }

        setLocalStoragePinnedPreviews(JSON.stringify(newDataPinned)); // update localstorage
    };

    const checkIfPinned = (previewId: number) => {
        const parsedPinnedPreviews = JSON.parse(localStoragePinnedPreviews as string) || [];
        const isChecked = parsedPinnedPreviews.includes(previewId);
        return isChecked;
    };

    const handleSearchChange = (string) => {
        setSearch(string);
    };

    const calculateStatus = (): PromotionStatus | undefined => {
        if (openInProgressAccordion && !openFinishedAccordion) return 'inProgress';
        if (!openInProgressAccordion && openFinishedAccordion) return 'finished';

        return undefined; // no status
    };

    const getPromotionPreviews = async ({ page, mode }: { page: number; mode: 'merge' | 'replace' }) => {
        const searchFor = visibleData.filter((value) => arrayOptionalSearchFor.includes(value));
        const status = calculateStatus();
        const parsedPinnedPreviews = JSON.parse(localStoragePinnedPreviews as string) || [];

        await loadPromotionPreviews(
            { search, status, searchFor, page, pinnedPreviewIds: parsedPinnedPreviews },
            { mode }
        );
    };

    const checkIfLoadSkeleton = (status: PromotionStatus) => {
        if (loadingPromotionPreviews === 'failed') return true;

        if (loadingPromotionPreviews !== 'pending') return false;

        // Load skeleton when pending but stop it when it has all data loaded
        const loadSkeleton =
            totalPromotionPreviews[status] === null ||
            (totalPromotionPreviews[status] !== null &&
                // @ts-ignore: Object is possibly 'null'.
                promotionsPreviewGrouped[status]?.length < totalPromotionPreviews[status]);

        return loadSkeleton;
    };

    const checkIfHasMore = () => {
        if (totalPromotionPreviews?.finished === null || totalPromotionPreviews?.inProgress === null) return true;

        const hasMore = promotionPreviews.length < totalPromotionPreviews.finished + totalPromotionPreviews.inProgress;
        return hasMore;
    };

    const loadNextPage = () => {
        getPromotionPreviews({ page: (promotionPreviewCriteria?.page || 0) + 1, mode: 'merge' });
    };

    useEffect(() => {
        // When component loads / accordion, user pins / unpins or search changes -> Reset page and content
        getPromotionPreviews({ page: 0, mode: 'replace' });
    }, [localStoragePinnedPreviews, search, openInProgressAccordion, openFinishedAccordion]);

    const modifiers = [
        ['updating', 'pending'].includes(loadingPromotionPreviews) ? 'PromotionList--loading' : '',
        loadingPromotionPreviews === 'failed' ? 'PromotionList--failed' : ''
    ];

    return (
        <div className={`PromotionList ${modifiers.join(' ')}`}>
            <header className="PromotionList__searchAndFilters">
                <PromotionSearchBar searchText={search} handleSearchChange={handleSearchChange} />
                <VisibleDataDropdown changeVisibleData={changeVisibleData} />
            </header>

            {error && <ErrorCard error={error as Error} />}

            <InfiniteScroll
                dataLength={promotionPreviews.length}
                next={loadNextPage}
                hasMore={checkIfHasMore()}
                scrollableTarget={`main-layout`}
                className="PromotionList__infiniteScroll"
                loader={null}
            >
                {statusArray.map((status, i) => (
                    <section key={i} id={`section__${status}`} className="PromotionList__section">
                        <Accordion
                            text={t(status)}
                            count={
                                totalPromotionPreviews[status] === null
                                    ? undefined
                                    : totalPromotionPreviews[status]?.toString()
                            }
                            isOpen={status === 'inProgress' ? openInProgressAccordion : openFinishedAccordion}
                            setIsOpen={status === 'inProgress' ? setOpenInProgressAccordion : setOpenFinishedAccordion}
                        >
                            <div className="PromotionList__grid">
                                {promotionsPreviewGrouped[status]?.length > 0 &&
                                    promotionsPreviewGrouped[status]?.map((promotionPreview) => (
                                        <PromotionPreview
                                            key={promotionPreview.id}
                                            promotionPreview={promotionPreview}
                                            visibleData={visibleData}
                                            changePinnedPreviews={changePinnedPreviews}
                                            pinned={checkIfPinned(promotionPreview.id)}
                                        />
                                    ))}
                                {checkIfLoadSkeleton(status) &&
                                    [...Array(VISIBLE_PROMOTION_PREVIEWS)].map((e, j) => (
                                        <PromotionPreview
                                            key={j}
                                            promotionPreview={undefined}
                                            visibleData={visibleData}
                                            skeleton={true}
                                            changePinnedPreviews={changePinnedPreviews}
                                        />
                                    ))}
                                {loadingPromotionPreviews === 'succeeded' &&
                                    promotionsPreviewGrouped[status]?.length === 0 &&
                                    // hide element when accordion is closed so the css animation (appear with delay)
                                    // re-triggers when opening again the accordion
                                    (status === 'inProgress' ? openInProgressAccordion : openFinishedAccordion) && (
                                        <i className="PromotionList__noResults">No se han encontrado proyectos</i>
                                    )}
                            </div>
                        </Accordion>
                    </section>
                ))}
            </InfiniteScroll>
        </div>
    );
};

export default PromotionList;
