import { IconBuildingCommunity, IconVideo } from '@tabler/icons';
import './SiteCheckingModeChip.scss';

interface ActivityBasicDataProps {
    onSite: boolean | null;
}

const SiteCheckingModeChip = ({ onSite }: ActivityBasicDataProps) => {
    if (onSite === null) return <></>;

    const modifiers = [onSite ? 'SiteCheckingModeChip--purple' : 'SiteCheckingModeChip--blue'];

    return (
        <div className={`SiteCheckingModeChip ${modifiers.join(' ')}`}>
            {onSite ? <IconBuildingCommunity /> : <IconVideo />}
            <span>{onSite ? 'Presencial' : 'Online'}</span>
        </div>
    );
};

export default SiteCheckingModeChip;
