import IconTooltip from '../Tooltips/IconTooltip';
import './Titulos.scss';

export const TituloH1 = ({ titulo }) => {
    return <h1 className="titulo titulo__h1">{titulo}</h1>;
};

export const TituloH2 = ({ titulo, tooltip = '' }) => {
    return (
        <h2 className="titulo titulo__h2">
            {titulo}
            {tooltip && <IconTooltip msg={tooltip} />}
        </h2>
    );
};

export const TituloH3 = ({ titulo, tooltip = '' }) => {
    return (
        <h3 className="titulo titulo__h3">
            {titulo}
            {tooltip && <IconTooltip msg={tooltip} />}
        </h3>
    );
};

export const TituloH4 = ({ titulo, tooltip = '' }) => {
    return (
        <h4 className="titulo titulo__h4">
            {titulo}
            {tooltip && <IconTooltip msg={tooltip} />}
        </h4>
    );
};

export const TituloH5 = ({ titulo, tooltip = '' }) => {
    return (
        <h5 className="titulo titulo__h5">
            {titulo}
            {tooltip && <IconTooltip msg={tooltip} />}
        </h5>
    );
};

export const TituloH6 = ({ titulo, tooltip = '' }) => {
    return (
        <h6 className="titulo titulo__h6">
            {titulo}
            {tooltip && <IconTooltip msg={tooltip} />}
        </h6>
    );
};
export const TituloFraction = ({ titulo, unit }) => {
    const [firstElem, secondElem] = titulo.split('/');

    return (
        <h4>
            <span className={firstElem > 0 ? 'titulo__number titulo__danger' : 'titulo__number'}>{firstElem}</span>
            <span className="titulo__number">/{secondElem}</span>
            <span className="titulo__unit">{unit}</span>
        </h4>
    );
};

export const TituloMetric = ({ titulo, unit }) => {
    return (
        <h4>
            <span className="titulo__number">{titulo}</span>
            <span className="titulo__unit">{unit}</span>
        </h4>
    );
};

export const TituloDate = ({ titulo }) => {
    return (
        <h4>
            <span className="titulo__date">{titulo}</span>
        </h4>
    );
};

export const TituloResultadoBusqueda = ({ titulo }) => {
    return <h4 className="titulo__resultado-busqueda">{titulo}</h4>;
};

export const TituloPromocion = ({ titulo }) => {
    return <h2 className="titulo__promocion">{titulo}</h2>;
};
