import { useEffect } from 'react';
import { IconPlus, IconTrash } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import InputFecha from '../../BasicComponents/Inputs/InputFecha';
import SimpleCard from '../../BasicComponents/Cards/SimpleCard';
import { TituloH3, TituloH4 } from '../../BasicComponents/Titulos/Titulos';
import SmallButton from '../../BasicComponents/Buttons/Small/SmallButton';
import BasicButton from '../../BasicComponents/Buttons/Base/BasicButton';
import {
    notDangerousContainerData,
    dangerousContainerData,
    rsuContainerData
} from '../../../utils/constants/siteCheking.constants';
import '../../../pages/Visitas/Visitas.scss';
import './Residuos.scss';
import DragNDropContainer from './DragNDropContainer';

const Residuos = ({ dangerously = false, sectionTypes, setSectionTypes, isRSU, lerTypes, setActividad, ...props }) => {
    const [t] = useTranslation();
    const visitRecordStructure = {
        id: null,
        lerType: null,
        promotionActivity: null,
        existContainer: null,
        containerSize: null,
        purity: null,
        rainProtection: null,
        waterproofTray: null,
        watertightDrum: null,
        containerFillPercentage: null,
        signage: null,
        orderAndAccessibility: null,
        documents: [],
        leakProof: null,
        collectionDate: null
    };

    useEffect(() => {
        fillData(sectionTypes);
    }, [dangerously, isRSU]);

    const fillData = (sectionTypesArray) => {
        sectionTypesArray?.forEach((sectionType) => {
            if (sectionType?.dangerous === dangerously && sectionType?.rsu === isRSU) {
                const option = sectionType?.existLerType ? 'si' : 'no';
                const element = document.getElementById(sectionType?.lerType?.id + option);
                const defaultElement = document.getElementById(sectionType?.lerType?.id + 'no_aplica');
                if (element !== null) {
                    element.checked = true;
                    defaultElement.checked = false;
                }

                if (sectionType?.existLerType) {
                    sectionType.containers?.forEach((container, i) => {
                        for (const field in container) {
                            const value = container[field];

                            const allOptions = document.getElementsByName(sectionType?.sectionName + i + field);
                            allOptions?.forEach((option) => {
                                option.checked = false;
                            });

                            const element = document.getElementById(sectionType?.sectionName + i + field + value);
                            if (element !== null) {
                                element.checked = true;
                            }
                        }
                    });
                }
            }
        });
    };

    const handleOptionChange = (lerType) => {
        const optionValue = document.querySelector(`input[name="${lerType?.lerType?.id}"]:checked`)?.value;

        switch (optionValue) {
            case 'si':
                changeSectionType(lerType?.lerType, true);
                break;
            case 'no':
                changeSectionType(lerType?.lerType, false);
                break;
            case 'no_aplica':
                removeSectionType(lerType?.lerType);
                break;
            default:
                break;
        }
    };

    const addNewSectionType = (sectionTypesAux, lerType, existsType) => {
        const newSection = {
            sectionName: lerType.lerKind,
            lerType: lerType,
            existLerType: existsType,
            dangerous: dangerously,
            rsu: lerType?.rsu,
            containers: [createVisitRecord(lerType, existsType)]
        };

        sectionTypesAux.push(newSection);
        setSectionTypes(sectionTypesAux);
        updateVisitRecords(sectionTypesAux);
    };

    const changeSectionType = (lerType, existType) => {
        const sectionTypesAux = removeSectionTypeFromArray(lerType);
        addNewSectionType(sectionTypesAux, lerType, existType);
    };

    const createVisitRecord = (lerType, existContainer) => {
        const newVisitRecord = { ...visitRecordStructure };
        newVisitRecord['existContainer'] = existContainer;
        newVisitRecord['lerType'] = lerType;

        return newVisitRecord;
    };

    const addVisitRecord = (section) => {
        const newVisitRecord = createVisitRecord(section?.lerType, true);

        let sectionAux = JSON.parse(JSON.stringify(section));
        let sectionTypesAux = JSON.parse(JSON.stringify(sectionTypes));
        const index = sectionTypes.findIndex((e) => e.lerType?.id === section?.lerType?.id);
        sectionAux.containers.push(newVisitRecord);
        sectionTypesAux[index] = sectionAux;

        setSectionTypes(sectionTypesAux);
        updateVisitRecords(sectionTypesAux);
    };

    const removeVisitRecord = (section, i) => {
        let sectionAux = JSON.parse(JSON.stringify(section));
        let sectionTypesAux = JSON.parse(JSON.stringify(sectionTypes));
        const index = sectionTypes.findIndex((e) => e.lerType?.id === section?.lerType?.id);

        sectionAux.containers.splice(i, 1);
        sectionTypesAux[index] = sectionAux;

        setSectionTypes(sectionTypesAux);
        updateVisitRecords(sectionTypesAux);
        fillData(sectionTypesAux);
    };

    const removeSectionType = (lerType) => {
        const sectionTypesAux = removeSectionTypeFromArray(lerType);
        setSectionTypes(sectionTypesAux);
        updateVisitRecords(sectionTypesAux);
    };

    const removeSectionTypeFromArray = (lerType) => {
        let sectionTypesAux = JSON.parse(JSON.stringify(sectionTypes));
        const index = sectionTypesAux.findIndex((e) => e.lerType?.id === lerType?.id);

        if (index >= 0) {
            // si ya existía, lo borramos
            sectionTypesAux = sectionTypesAux.filter((obj) => obj.lerType?.id !== lerType?.id);
        }

        return sectionTypesAux;
    };

    const getContainerData = () => {
        let data = notDangerousContainerData;
        if (dangerously) {
            data = dangerousContainerData;
        }
        if (isRSU) {
            data = rsuContainerData;
        }
        return data;
    };

    const handleDataChange = (section, containerIndex, field, value, sectionIndex) => {
        let containerAux = { ...section.containers[containerIndex] };
        const containersAux = [...section.containers];
        const sectionAux = JSON.parse(JSON.stringify(section));
        const sectionTypesAux = JSON.parse(JSON.stringify(sectionTypes));

        containerAux = { ...containerAux, [field]: value };
        containersAux[containerIndex] = containerAux;
        sectionAux.containers = containersAux;
        sectionTypesAux[sectionIndex] = sectionAux;

        setSectionTypes(sectionTypesAux);
        updateVisitRecords(sectionTypesAux);
    };

    const updateVisitRecords = (sections) => {
        const visitRecordsAux = [];

        sections?.forEach((section) => {
            section?.containers?.forEach((container) => {
                visitRecordsAux.push(container);
            });
        });

        setActividad((val) => ({ ...val, visitRecords: visitRecordsAux }));
    };

    const showLerTypeByDangerous = (lerType) => {
        return lerType?.lerType?.dangerous === dangerously;
    };

    const showLerTypeByRSU = (lerType) => {
        return lerType?.lerType?.rsu === isRSU;
    };

    const showSectionByDangerousAndRSU = (section) => {
        return section?.dangerous === dangerously && section?.rsu === isRSU;
    };

    const updateFiles = (arrayImages, type = 'external', visitRecordIndex, section) => {
        const sectionTypesAux = JSON.parse(JSON.stringify(sectionTypes));
        const sectionAux = JSON.parse(JSON.stringify(section));
        const sectionIndex = sectionTypes.findIndex((e) => e.lerType?.id === section?.lerType?.id);

        const containersAux = [...section.containers];
        let containerAux = { ...section.containers[visitRecordIndex] };

        const updatedDocuments = updateDocumentsByType(arrayImages, type, containerAux);
        containerAux = { ...containerAux, documents: updatedDocuments };

        containersAux[visitRecordIndex] = containerAux;
        sectionAux.containers = containersAux;
        sectionTypesAux[sectionIndex] = sectionAux;

        setSectionTypes(sectionTypesAux);
        updateVisitRecords(sectionTypesAux);
    };

    const updateDocumentsByType = (arrayImages, type, containerData) => {
        const filteredDocsByType = containerData?.documents?.filter((doc) => doc.type !== type) || [];
        const arrayDocs = [...filteredDocsByType, ...arrayImages];

        return arrayDocs;
    };

    const filterFilesByType = (filesArray, type = 'external') => {
        return filesArray?.filter((file) => file?.type === type);
    };

    const getInputs = (field) => {
        let inputs = [];
        switch (field) {
            case 'purity':
            case 'containerFillPercentage':
                inputs = [
                    { label: '< 25%', value: 25 },
                    { label: '50%', value: 50 },
                    { label: '75%', value: 75 },
                    { label: '100%', value: 100 }
                ];
                break;
            case 'containerSize':
                if (dangerously) {
                    inputs = [
                        { label: '< 150 L', value: 'SMALL' },
                        { label: '150 - 500 L', value: 'MEDIUM' },
                        { label: '> 500 L', value: 'LARGE' }
                    ];
                } else {
                    inputs = [
                        { label: '< 5 m³', value: 'SMALL' },
                        { label: '5 - 12 m³', value: 'MEDIUM' },
                        { label: '> 12 m³', value: 'LARGE' }
                    ];
                }
                break;
            case 'leakProof':
                inputs = [
                    { label: t('yes', { ns: 'common' }), value: true },
                    { label: t('no', { ns: 'common' }), value: false }
                ];
                break;
            default:
                inputs = [
                    { label: t('correct.upperCase', { ns: 'common' }), value: true },
                    { label: t('incorrect', { ns: 'common' }), value: false }
                ];
                break;
        }

        return inputs;
    };

    return (
        <SimpleCard title={props?.title} allowScroll={true}>
            <TituloH4 titulo={t('isRSU.check', { ns: 'visit' })} />
            <div className="visita__lerTypes">
                {lerTypes?.map(
                    (lerType) =>
                        showLerTypeByDangerous(lerType) &&
                        showLerTypeByRSU(lerType) && (
                            <div className="lerTypes__type" key={lerType?.id}>
                                <span>{lerType?.lerType?.lerKind}</span>
                                <div className="lerTypes__options">
                                    <div className="lerTypes__option" onChange={() => handleOptionChange(lerType)}>
                                        <input
                                            type="radio"
                                            id={lerType?.lerType?.id + 'si'}
                                            name={lerType?.lerType?.id}
                                            value="si"
                                        />
                                        <label htmlFor={lerType?.lerType?.id + 'si'}>
                                            {t('yes', { ns: 'common' })}
                                        </label>
                                    </div>
                                    <div className="lerTypes__option" onChange={() => handleOptionChange(lerType)}>
                                        <input
                                            type="radio"
                                            id={lerType?.lerType?.id + 'no'}
                                            name={lerType?.lerType?.id}
                                            value="no"
                                        />
                                        <label htmlFor={lerType?.lerType?.id + 'no'}>{t('no', { ns: 'common' })}</label>
                                    </div>
                                    <div className="lerTypes__option" onChange={() => handleOptionChange(lerType)}>
                                        <input
                                            type="radio"
                                            id={lerType?.lerType?.id + 'no_aplica'}
                                            name={lerType?.lerType?.id}
                                            value="no_aplica"
                                            defaultChecked
                                        />
                                        <label htmlFor={lerType?.lerType?.id + 'no_aplica'}>
                                            {t('notApplicable', { ns: 'common' })}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                )}
            </div>
            <TituloH4 titulo={t('isRSU.evaluate', { ns: 'visit' })} />
            {sectionTypes?.map(
                (section, j) =>
                    section?.existLerType &&
                    showSectionByDangerousAndRSU(section) && (
                        <div key={section?.sectionName} className="visita__lerSection">
                            <TituloH3 titulo={section?.sectionName} />

                            {section?.containers?.map((container, i) => (
                                <div key={i} className="visita__containerSection">
                                    <div>
                                        <TituloH4
                                            titulo={`${section?.sectionName} - ${
                                                dangerously
                                                    ? t('canister.upperCase', { ns: 'visit' })
                                                    : t('container.upperCase', { ns: 'visit' })
                                            } ${i + 1}`}
                                        />
                                        {section?.containers?.length > 1 && (
                                            <SmallButton
                                                titulo={`${t('delete', { ns: 'common' })} ${
                                                    dangerously
                                                        ? t('canister.lowerCase', { ns: 'visit' })
                                                        : t('container.lowerCase', { ns: 'visit' })
                                                }`}
                                                type="button"
                                                icon={<IconTrash stroke={1.25} size={18} />}
                                                action={() => removeVisitRecord(section, i)}
                                            />
                                        )}
                                    </div>
                                    <div className="visita__containerData">
                                        {getContainerData()?.map((data) => (
                                            <div key={data.field + i} className="visita__option">
                                                <div>{t(data.label, { ns: 'visit' })}</div>

                                                {/* Date inputs */}
                                                {data.type === 'date' && (
                                                    <InputFecha
                                                        value={container[data.field] || null}
                                                        onChange={(e) =>
                                                            handleDataChange(section, i, data.field, e.target.value, j)
                                                        }
                                                        notLimit
                                                    />
                                                )}

                                                {/* Radio inputs */}
                                                {(data.type === 'radio' || !data.type) && (
                                                    <div className="lerTypes__options">
                                                        {getInputs(data.field)?.map((input) => (
                                                            <div
                                                                key={input.label + i}
                                                                className="lerTypes__option"
                                                                onChange={() =>
                                                                    handleDataChange(
                                                                        section,
                                                                        i,
                                                                        data.field,
                                                                        input.value,
                                                                        j
                                                                    )
                                                                }
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    id={
                                                                        section?.sectionName +
                                                                        i +
                                                                        data.field +
                                                                        input.value
                                                                    }
                                                                    name={section?.sectionName + i + data.field}
                                                                    value={input.value}
                                                                />
                                                                <label
                                                                    htmlFor={
                                                                        section?.sectionName +
                                                                        i +
                                                                        data.field +
                                                                        input.value
                                                                    }
                                                                >
                                                                    {input.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="waste__uploadContainer">
                                        <DragNDropContainer
                                            className="waste__dragAndDrop waste__dragAndDrop-green"
                                            files={filterFilesByType(container?.documents, 'internal')}
                                            updateFiles={updateFiles}
                                            visitRecordIndex={i}
                                            section={section}
                                            imageType="internal"
                                            label={t('attach.internal', { ns: 'visit' })}
                                        />

                                        <DragNDropContainer
                                            className="waste__dragAndDrop waste__dragAndDrop-blue"
                                            files={filterFilesByType(container?.documents, 'external')}
                                            updateFiles={updateFiles}
                                            visitRecordIndex={i}
                                            section={section}
                                            imageType="external"
                                            label={t('attach.external', { ns: 'visit' })}
                                        />
                                    </div>
                                </div>
                            ))}

                            <BasicButton
                                color="blue"
                                text={`${t('add', { ns: 'common' })} ${
                                    dangerously
                                        ? t('canister.lowerCase', { ns: 'visit' })
                                        : t('container.lowerCase', { ns: 'visit' })
                                } ${t('of.simple', { ns: 'common' })} ${section?.sectionName?.toLowerCase()}`}
                                type="button"
                                icon={<IconPlus stroke={1.25} size={24} />}
                                action={() => addVisitRecord(section)}
                            />
                        </div>
                    )
            )}
        </SimpleCard>
    );
};

export default Residuos;
