import fetcher from 'lib/fetcher';
import { Alert } from 'modules/alerts/domain/Alert';
import { AlertsApi } from '../../domain/AlertsApi';

export const httpAlertsApi: AlertsApi = {
    getAlerts: async ({ promotionId }) => {
        const { data } = await fetcher.get(`/api/promotions/${promotionId}/alarms`);
        const alerts = data.content.alarms.map((alarm) => {
            const alert: Alert = {
                code: alarm.code,
                title: alarm.title,
                description: alarm.desc,
                help: alarm.help,
                level: alarm.level,
                date: alarm.creation
            };
            return alert;
        });

        return alerts;
    },
    getAlertCause: async ({ promotionId, alertCode }) => {
        const { data } = await fetcher.get(`/api/promotions/${promotionId}/alarms/${alertCode}/cause`);

        if (data.code != 0 || !data.content.lerTypes || data.content.lerTypes.length === 0) return null;

        const lerTypes = data.content.lerTypes.map((lerType) => lerType.lerKind);
        return { lerTypes } as any;
    }
};
