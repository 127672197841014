import { IconCheck } from '@tabler/icons';
import { Trans, useTranslation } from 'react-i18next';

import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import SaveButton from 'components/BasicComponents/Buttons/SaveButton';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import SuggestionCard from 'components/BasicComponents/Cards/SuggestionCard';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import AutomaticReadingStatus from 'features/automatic-reading/components/AutomaticReadingStatus';
import { sumCountersByKey } from 'features/automatic-reading/utils/sumCountersByKey';
import DiExtractionWarning from 'features/di-extraction/components/DiExtractionWarnings/DiExtractionWarning';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { DiExtractionCriteria, approvableStatusList, useDiExtraction } from 'modules/di-extraction';

interface AutomaticReadingMassiveApproveProps {}

const AutomaticReadingMassiveApprove = (props: AutomaticReadingMassiveApproveProps) => {
    const [t] = useTranslation('automaticReading');
    const [, analytics] = useAnalyticsStore();

    const [{ criteria, counters, withWarnings }, diExtractionsActions] = useDiExtraction();
    const { approveManyDiExtractions, reloadDiExtractions } = diExtractionsActions;

    const triggerMassiveAction = async (action: 'approve-all' | 'approve-withoutWarnings' | 'reject') => {
        const allCriteriaButPage = { ...criteria, page: undefined, size: undefined };

        const payload: DiExtractionCriteria = {
            promotionId: criteria.promotionId,
            ...allCriteriaButPage
        };

        if (action === 'approve-all') {
            await approveManyDiExtractions({ ...payload, state: approvableStatusList });
            analytics.event(AnalyticsEvents.AUTOMATIC_READING_MASSIVE_APPROVED);
        }

        if (action === 'approve-withoutWarnings') {
            await approveManyDiExtractions({ ...payload, state: approvableStatusList, withWarnings: false });
            analytics.event(AnalyticsEvents.AUTOMATIC_READING_MASSIVE_APPROVED);
        }

        await reloadDiExtractions();
    };

    // Intersection between approvableStatusList and the selected status (filters)
    // -> for example, reject ONLY 'to be completed' DIs if user applied that filter
    // -> for example, you cannot aprove DIs if at filters there is selected 'to be completed' only
    const thereAreStatusFilter = criteria.state && criteria.state.length > 0;

    const visibleAprovablesStatus = thereAreStatusFilter
        ? approvableStatusList.filter((status) => criteria.state?.flat()?.includes(status))
        : approvableStatusList;

    const approvablesCount = sumCountersByKey(counters, visibleAprovablesStatus);

    if (counters.totalApplicable === 0) return null;

    const approveTxtAll = t('actions.approveAll.name', { count: approvablesCount });

    const approveDisabledText: string | null =
        (!!counters.PENDING && t('actions.disabledMsg.pending')) ||
        (approvablesCount === 0 && t('actions.disabledMsg.noApprovableDis')) ||
        null;

    const canValidateWithoutWarnings = withWarnings > 0 && approvablesCount !== withWarnings;

    return (
        <Tooltip msg={approveDisabledText} delayShow={0}>
            <ActionConfirm
                title={approveTxtAll}
                actions={
                    <div className="AutomaticReadingHeader__actions-validate-confirm">
                        {canValidateWithoutWarnings && (
                            <SaveButton
                                icon={<></>}
                                text={t('actions.approveAll.withoutWarnings')}
                                testid="AutomaticReadingHeader__actions-massive-validation-without-warnings"
                                action={() => triggerMassiveAction('approve-withoutWarnings')}
                            />
                        )}
                        <SaveButton
                            extraClasses="button__purple"
                            text={t('actions.approveAll.all', { count: approvablesCount })}
                            testid="AutomaticReadingHeader__actions-massive-validation-all"
                            action={() => triggerMassiveAction('approve-all')}
                        />
                    </div>
                }
                message={
                    <div className="AutomaticReadingHeader__actions-dialog">
                        <SuggestionCard className="AutomaticReadingHeader__actions-tip" title="">
                            <div>{t('actions.tip')}</div>
                        </SuggestionCard>
                        <Trans
                            i18nKey="actions.approveAll.confirmation"
                            ns="automaticReading"
                            values={{ count: approvablesCount }}
                            components={{
                                badge: <AutomaticReadingStatus state="PENDING_REVIEW" />,
                                b: <strong />
                            }}
                        />
                        {withWarnings > 0 && (
                            <p>
                                <Trans
                                    i18nKey="actions.approveAll.confirmationWithWarnings"
                                    ns="automaticReading"
                                    components={{
                                        warnings: (
                                            <DiExtractionWarning
                                                type={'LOW'}
                                                messages={[t('actions.approveAll.warningsDescription')]}
                                            />
                                        ),
                                        b: <strong />,
                                        em: <em className="AutomaticReadingHeader__actions-validate-all" />
                                    }}
                                />
                            </p>
                        )}
                    </div>
                }
            >
                <SmallButton
                    type="button"
                    titulo={approveTxtAll}
                    icon={<IconCheck />}
                    iconPosition="left"
                    disabled={approvablesCount === 0 || counters.PENDING}
                    testid="AutomaticReadingHeader__actions-massive-validation"
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </ActionConfirm>
        </Tooltip>
    );
};

export default AutomaticReadingMassiveApprove;
