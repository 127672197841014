import { PropsWithChildren } from 'react';
import './TooltipContent.scss';

const TooltipContent = (props: PropsWithChildren<{}>) => {
    return <div className="TooltipContent__tooltip">{props.children}</div>;
};

export const TooltipContentLabel = (props: PropsWithChildren<{ className?: string }>) => {
    return <div className={`TooltipContent__tooltipLabel ${props.className || ''}`}>{props.children}</div>;
};

export const TooltipContentValue = (props: PropsWithChildren<{ className?: string }>) => {
    return <div className={`TooltipContent__tooltipValue ${props.className || ''}`}>{props.children}</div>;
};

export const TooltipContentDate = (props: PropsWithChildren<{ className?: string }>) => {
    return <div className={`TooltipContent__tooltipDate ${props.className || ''}`}>{props.children}</div>;
};

export default TooltipContent;
