import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { Billeable } from 'modules/billeables/domain/Billeable/Billeable';

import { SelloValorizaBilleable } from '../../domain/Billeable/SelloValorizaBilleable/SelloValorizaBilleable';
import { BilleablesStore } from '../../domain/BilleablesStore';

export const updateBilleable =
    (criteria: Billeable): Thunk<BilleablesStore> =>
    async (store, { promotion }) => {
        store.dispatch('stagedUpdateConfig', criteria);

        // For update all billeable fields stored inside promotion
        if (promotion) {
            const config = criteria.config as SelloValorizaBilleable['config'];

            promotion?.actions.updatePromotion({
                selloValorizaScore: config.score,
                selloValorizaValorizationTarget: config.valorizationTarget
            });
        }
    };

export const updateReducers: Pick<Reducers<BilleablesStore>, 'stagedUpdateConfig'> = {
    stagedUpdateConfig: (state, payload) => {
        return produce(state, (draft) => {
            let billeable = draft.entities.byId[payload.name];
            if (!billeable) return;

            billeable.config = { ...billeable.config, ...payload.config };
        });
    }
};
