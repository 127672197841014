import { IconChevronLeft } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

const GoBack = ({ goBack }) => {
    const [t] = useTranslation();

    return (
        <button onClick={() => goBack()} className="button button__back">
            <IconChevronLeft />
            <span>{t('back', { ns: 'common' })}</span>
        </button>
    );
};

export default GoBack;
