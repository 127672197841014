import { Controller, FieldValues } from 'react-hook-form';

import SelectAutocomplete, { SelectAutocompleteProps } from 'components/BasicComponents/Select/SelectAutocomplete';
import { FormControlProps } from './shared/FormControlProps';
import { useErrorMessage } from './shared/useErrorMessage';

type AdaptersProps = {
    valueAdapter?: (entity: any) => SelectAutocompleteProps['value'];
    onChangeAdapter?: (option?: SelectAutocompleteProps['value']) => any;
};

type SelectAutocompleteFormControlProps<Values extends FieldValues> =
    // Concatenate props
    FormControlProps<Values> & AdaptersProps & SelectAutocompleteProps;

const SelectAutocompleteFormControl = <Values extends FieldValues>(
    props: SelectAutocompleteFormControlProps<Values>
) => {
    const { control, name, valueAdapter = (v) => v, onChangeAdapter = (v) => v, ...selectAutocompleteProps } = props;

    const { errorMsg } = useErrorMessage(props);

    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field }) => (
                <SelectAutocomplete
                    {...selectAutocompleteProps}
                    value={field.value ? valueAdapter(field.value) : undefined}
                    onChange={(value) => {
                        if (value === null || value === undefined) field.onChange(undefined);
                        const adaptedValue = onChangeAdapter(value);
                        field.onChange(adaptedValue);
                    }}
                    onBlur={field.onBlur}
                    errorMsg={errorMsg}
                    disabled={field.disabled}
                />
            )}
        />
    );
};

export default SelectAutocompleteFormControl;
