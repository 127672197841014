import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { IconAlertCircle } from '@tabler/icons';
import promoProGesCodLersServices from '../../../../api/promoProGesCodLers/promoProGesCodLers.services';
import { withAuth } from '../../../../context/AuthProvider';
import { customStyles } from '../../../BasicComponents/Select-Autocomplete/helpers';
import { createFilterWithObject } from '../../../../utils/helpers/general.helpers';

//PROGESCODLER
const ProGesCodLerAutocomplete = ({
    codLer = 'codLer',
    values,
    setValues,
    required,
    className,
    promocion,
    validacion,
    name,
    typeOfWasteManager = 'WASTE_MANAGER',
    value,
    INITIAL_STATE = '',
    codLerPath = values[codLer]?.codigoLER
}) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState(false);

    const hasMoreWasteManager = (options) => {
        return options.length === 20 ? true : false;
    };

    const filterValues = {
        [codLer]: `&codLER.equals=${codLerPath}`
    };

    const getWasteManagerWithValorization = () => {
        return {
            CARRIER: `&valorizationCode.equals=T01`,
            TRANSFER_OPERATOR: `&valorizationCode.equals=O01`,
            WASTE_MANAGER: `&valorizationCode.notIn=O01,T01`
        };
    };

    const getFilter = (page, search) => {
        let filter = `?page=${page}`;
        filter += getWasteManagerWithValorization()[typeOfWasteManager];
        filter += createFilterWithObject(values, filterValues);
        if (search) {
            filter += `&gestoraName.contains=${search}`;
        }
        return filter;
    };

    const removeWasteManagerDuplicates = (arrPpgcl) => {
        let options = [];
        for (let i = 0; i < arrPpgcl.length; i++) {
            const wasteManagerExists = options.some(
                (ppgcl) => ppgcl?.procesoGestora.gestora.id === arrPpgcl[i].procesoGestora.gestora.id
            );
            if (!wasteManagerExists) {
                options.push(arrPpgcl[i]);
            }
        }
        return options;
    };

    const loadOptionsGestora = async (search, loadedOptions, { page }) => {
        let options = [];
        let pgcl;
        if (values[codLer]) {
            const filter = getFilter(page, search);
            const pgclRequest = await promoProGesCodLersServices.filterProGesCodLers(filter);

            if (pgclRequest !== undefined && pgclRequest?.data?.code == 0) {
                pgcl = pgclRequest?.data?.content?.valorizationProcess;
                options = removeWasteManagerDuplicates([...pgcl, ...loadedOptions]);
            }
        }
        return {
            options,
            hasMore: hasMoreWasteManager(pgcl),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        return item === null ? setValues({ ...values, [name]: INITIAL_STATE }) : setValues({ ...values, [name]: item });
    };

    useEffect(() => {
        emptyField();
    }, [validacion, promocion]);

    const emptyField = () => {
        if (required && !value && validacion) {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };
    const key = () => {
        return JSON.stringify(values[codLer]);
    };

    const labelName = {
        CARRIER: t('transporter', { ns: 'common' }),
        TRANSFER_OPERATOR: t('transferOperator', { ns: 'common' }),
        WASTE_MANAGER: t('manager.sg', { ns: 'common' })
    };

    const noAccreditedFlowTranslation = {
        CARRIER: t('noAccreditedFlow.carrier', { ns: 'ppgcl' }),
        TRANSFER_OPERATOR: t('noAccreditedFlow.transferOperator', { ns: 'ppgcl' }),
        WASTE_MANAGER: t('noAccreditedFlow.wasteManager', { ns: 'ppgcl' })
    };

    const notOptionsMessage = {
        CARRIER: 'No hay opciones para gestoras transportistas',
        TRANSFER_OPERATOR: 'No hay opciones para gestoras operadoras del traslado',
        WASTE_MANAGER: 'No hay optiones de gestoras'
    };

    const optionsMessage = () => {
        if (!values[codLer]) {
            return 'Selecciona primero un código ler';
        } else {
            return notOptionsMessage[typeOfWasteManager];
        }
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {' '}
                {labelName[typeOfWasteManager]} {required ? '*' : ''}
            </label>

            <AsyncPaginate
                key={key()}
                loadOptions={loadOptionsGestora}
                onChange={selectOption}
                getOptionLabel={(option) => option.procesoGestora.gestora.empresa}
                getOptionValue={(option) => option}
                value={value}
                styles={customStyles(notComplete)}
                placeholder=""
                additional={{
                    page: 0
                }}
                isClearable={true}
                className="autocomplete"
                noOptionsMessage={optionsMessage}
            />

            {value !== null && value !== '' && !value.accreditedFlow && (
                <p className="ppgcl__notAccredited">
                    <IconAlertCircle stroke={2} size={16} />
                    <span>{noAccreditedFlowTranslation[typeOfWasteManager]}</span>
                </p>
            )}
        </div>
    );
};
export default withAuth(ProGesCodLerAutocomplete);
