import { AUTH_INITIAL_STATE } from 'modules/auth/domain/AuthConfig';
import { AuthStore } from 'modules/auth/domain/AuthStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

export const logout =
    (): Thunk<AuthStore> =>
    async (store, { tokenStorage, deepLinkStorage }) => {
        try {
            tokenStorage.delete();

            // Si le pasamos un objeto, debemos pasarle todo el nuevo estado al completo:
            store.set({
                ...AUTH_INITIAL_STATE,
                isLoading: false,
                isLoggedin: false,
                pathRedirect: '/auth/login',
                error: null
            });

            /**
             * Workaround
             * To remove after state changes, we must wait until call stack finish all calls.
             * an approach to do this is with setTimeout without time
             */
            setTimeout(() => {
                deepLinkStorage.removeDeepLink();
            });
        } catch (error) {
            // Si le pasamos una función, podemos cambiar sólo una propiedad
            store.set((draft) => {
                draft.error = error as Error;
            });
        }
    };
