import { useTranslation } from 'react-i18next';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import KPIHistoricalChart from 'features/KPIs/components/KPIHistoricalChart';
import { calcKPIStatusFromGoal, useValorizationByScope } from 'modules/promotion-kpis';
import './ValorizationHistory.scss';

interface ValorizationHistoryProps {
    valorization: ReturnType<typeof useValorizationByScope>;
    promotionId: string;
}

const ValorizationHistory = (props: ValorizationHistoryProps) => {
    const [t] = useTranslation('kpis');
    const { valorization } = props;

    const goal = valorization.data?.current;
    const status = calcKPIStatusFromGoal({ goal });

    return (
        <>
            <ErrorCard error={valorization.error} />

            <div className="ValorizationHistory">
                <KPIHistoricalChart
                    data={valorization.data?.data}
                    goals={valorization.data?.goals.map((goal) => ({ ...goal, type: 'MIN' }))}
                    status={status}
                    unit={t(`valorization.unit`)}
                    yScale={{ type: 'linear', min: 0, max: 105 }}
                />
            </div>
        </>
    );
};

export default ValorizationHistory;
