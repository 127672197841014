import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../context/AuthProvider';
import { TituloH3 } from '../Titulos/Titulos';
import { getRoles, getTheMostImportantRole } from '../../../utils/constants/user.constants';
import { checkIfCoCircularResponsability } from '../../../utils/helpers/user.helpers';
import SimplePopover from './SimplePopover';

const UsuarioPopover = ({ openModal, setOpenModal, noClose, usuario, isCoCircular }) => {
    const [t] = useTranslation();

    const checkResponsability = () => {
        return checkIfCoCircularResponsability(usuario?.roleType?.id);
    };

    const keyValues = [
        { label: t('firstName', { ns: 'users' }), value: usuario?.firstName + ' ' + usuario?.lastName, show: true },
        { label: t('position', { ns: 'users' }), value: null, prop: 'roleType.descripcion', show: true },
        { label: t('enterprise', { ns: 'users' }), value: null, prop: 'enterprise.name', show: true },
        { label: 'E-mail', value: null, prop: 'email', show: true },
        { label: t('phoneOne', { ns: 'users' }), value: null, prop: 'phoneOne', show: true },
        { label: t('phoneTwo', { ns: 'users' }), value: null, prop: 'phoneTwo', show: true },
        { label: t('notifications', { ns: 'users' }), value: null, prop: 'notifiable', show: checkResponsability() }
    ];

    const getDataValue = (item) => {
        if (item.value !== null) {
            return item.value;
        }

        return getNestedData(item.prop);
    };

    const getNestedData = (prop) => {
        let finalData = '';
        if (prop.includes('.')) {
            const nestedProps = prop.split('.');
            let dataAux = { ...usuario };

            nestedProps.forEach((nestedProp) => {
                dataAux = dataAux[nestedProp];
            });
            finalData = dataAux;
        } else {
            finalData = usuario[prop];
        }

        if (finalData === true) {
            finalData = 'Sí';
        }

        if (finalData === false) {
            finalData = 'No';
        }

        return finalData || '-';
    };

    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            noClose={noClose}
            title={t('detail', { ns: 'users' })}
        >
            <div className="popover__container">
                <TituloH3 titulo={usuario.nombreWeb} />
                <div className="popover__data">
                    <div>
                        {keyValues.map((item, j) => (
                            <>
                                {item.show && (
                                    <div key={j} className="data__row">
                                        <label className="data__label">{item.label}</label>
                                        <p className="data__value">{getDataValue(item)}</p>
                                    </div>
                                )}
                            </>
                        ))}
                        {isCoCircular && (
                            <div className="data__row">
                                <label className="data__label">Rol</label>

                                {getTheMostImportantRole(getRoles(usuario.authorities)).map((role) => (
                                    <p className="data__value" key={role}>
                                        {t(role.description, { ns: 'users' })}
                                    </p>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </SimplePopover>
    );
};
export default withAuth(UsuarioPopover);
