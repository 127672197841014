import { httpWasteRegisterApi } from 'modules/waste-register/infrastructure/api/httpWasteRegisterApi';
import { WasteRegisterProvider } from 'modules/waste-register/infrastructure/react/wasteRegisterContext';
import PromotionWasteRegisterPage from './PromotionWasteRegisterPage';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return (
        <WasteRegisterProvider api={httpWasteRegisterApi}>
            <PromotionWasteRegisterPage />
        </WasteRegisterProvider>
    );
};
