import { Redirect, Switch } from 'react-router-dom';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';

import PrivateRoute from 'components/routes/PrivateRoute';
import { INTERNAL_ACTIVITIES_ROUTES, activitiesRouteRoot } from '../routes/ActivitiesRoutes';

/** Activities container component to make  */
const ActivitiesRoot = () => {
    const [{ promotion }] = usePromotion();
    if (!promotion?.id) return;

    return (
        <Switch>
            {INTERNAL_ACTIVITIES_ROUTES.map((route: any) => (
                <PrivateRoute {...route} key={route.path} />
            ))}
            <Redirect to={activitiesRouteRoot} />
        </Switch>
    );
};

export default ActivitiesRoot;
