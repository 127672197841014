import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import EnterpriseAutocomplete from 'components/ComplexComponents/NewPromotion/RelatedEnterprises/components/autocompletes/EnterpriseAutocomplete';
import promotionServices from '../../api/promotion/promotion.services';
import GoBack from '../../components/BasicComponents/Buttons/GoBack';
import SubmitButton from '../../components/BasicComponents/Buttons/SubmitButton';
import SimpleCard from '../../components/BasicComponents/Cards/SimpleCard';
import InputFecha from '../../components/BasicComponents/Inputs/InputFecha';
import InputNumber from '../../components/BasicComponents/Inputs/InputNumber';
import InputText from '../../components/BasicComponents/Inputs/InputText';
import TextArea from '../../components/BasicComponents/Inputs/TextArea';
import MessageNotComplete from '../../components/BasicComponents/Messages/MessageNotComplete';
import ResultadoCarga from '../../components/BasicComponents/Messages/ResultadoCarga';
import SelloVerdeAutocomplete from '../../components/BasicComponents/Select-Autocomplete/SelloVerdeAutocomplete';
import Spinner from '../../components/BasicComponents/Spinner/Spinner';
import { TituloH1 } from '../../components/BasicComponents/Titulos/Titulos';
import PromotionTypeAutocomplete from '../../components/ComplexComponents/NewPromotion/Step2/PromotionTypeAutocomplete';
import TownAutocomplete from '../../components/ComplexComponents/NewPromotion/Step2/TownAutocomplete';
import { withAuth } from '../../context/AuthProvider';
import { allFieldsComplete } from '../../utils/helpers/general.helpers';
import Layout from '../Layout/Layout';
import './NewPromotion.scss';

const NewPromotionRequest = (props) => {
    const INITIAL_STATE_VALUES = {
        municipio: '',
        promotionName: '',
        address: '',
        promotionType: '',
        surface: '',
        initialDate: '',
        endDate: '',
        enterprise: ''
    };

    const [values, setValues] = useState(INITIAL_STATE_VALUES);
    const [validation, setValidation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageNotComplete, setMessageNotComplete] = useState('');
    const [message, setMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();

    const createMessage = (requestNewPromotion) => {
        if (requestNewPromotion !== undefined && requestNewPromotion.status === 204) {
            setMessage({ text: 'Proyecto solicitado con éxito', success: true });
            setTimeout(() => {
                goBack();
            }, 1500);
        } else {
            setMessage({ text: 'Error al solicitar el proyecto', success: false });
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSubmitting(true);
        if (validationForm()) {
            // Remove unused fields
            const { delegation, municipio, ...body } = {
                ...values,
                user: props.user,
                town: values.municipio
            };
            const requestNewPromotion = await promotionServices.requestNewPromotion(body);
            createMessage(requestNewPromotion);
        }
        setLoading(false);
        setSubmitting(false);
    };

    const objRequired = {
        municipio: values.municipio,
        promotionName: values.promotionName,
        address: values.address,
        promotionType: values.promotionType,
        surface: values.surface,
        initialDate: values.initialDate,
        endDate: values.endDate,
        porcentajeSelloVerde: values?.porcentajeSelloVerde
    };

    const validationForm = () => {
        if (values?.nima?.length > 0 && values?.nima?.length !== 10) {
            setMessageNotComplete('El nima tiene que contener 10 carácteres');
            return false;
        }
        if (allFieldsComplete(objRequired)) {
            setMessageNotComplete('');
            setValidation(false);
            return true;
        } else {
            setMessageNotComplete('Complete los campos requeridos');
            setValidation(true);
            return false;
        }
    };
    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };
    const goBack = () => {
        history.push({
            pathname: '/'
        });
    };

    return (
        <Layout>
            <GoBack goBack={() => goBack()} />
            <SimpleCard>
                <TituloH1 titulo="Solicitud de nuevo proyecto" />
                <form onSubmit={handleFormSubmit} className="newPromotionRequest__form">
                    <EnterpriseAutocomplete values={values} setValues={setValues} propName="enterprise" />

                    <div className="newPromotionRequest__containerInputs">
                        <InputText
                            label="Nombre del proyecto"
                            name="promotionName"
                            value={values?.promotionName}
                            onChange={handleInputChange}
                            validacion={validation}
                            required
                        />
                        <InputNumber
                            label="Nima"
                            name="nima"
                            value={values.nima}
                            onChange={handleInputChange}
                            formatNumber={false}
                        />
                    </div>
                    <div className="newPromotionRequest__containerInputs">
                        <InputText
                            label="Dirección"
                            name="address"
                            value={values?.address}
                            onChange={handleInputChange}
                            validacion={validation}
                            required
                        />
                        <TownAutocomplete values={values} setValues={setValues} validacion={validation} required />
                    </div>
                    <div className="newPromotionRequest__containerInputs">
                        <PromotionTypeAutocomplete
                            values={values}
                            setValues={setValues}
                            validacion={validation}
                            required
                        />
                        <InputNumber
                            label="Superfície construida (m²)"
                            name="surface"
                            value={values.surface}
                            onChange={handleInputChange}
                            formatNumber={false}
                            required
                            validacion={validation}
                        />
                    </div>
                    <div className="newPromotionRequest__containerInputs">
                        <InputFecha
                            label="Fecha inicio proyecto"
                            name="initialDate"
                            value={values.initialDate}
                            onChange={handleInputChange}
                            validacion={validation}
                            required
                        />
                        <InputFecha
                            label="Fecha fin proyecto"
                            name="endDate"
                            value={values.endDate}
                            onChange={handleInputChange}
                            validacion={validation}
                            required
                            notLimit
                        />
                    </div>
                    <div className="newPromotionRequest__containerInputs">
                        <SelloVerdeAutocomplete {...{ values, setValues }} />
                        <InputNumber
                            label="Objetivo de valorización (%)"
                            name="porcentajeSelloVerde"
                            value={values.porcentajeSelloVerde}
                            onChange={handleInputChange}
                            formatNumber={false}
                            validacion={validation}
                            required
                            min={70}
                            max={100}
                        />
                    </div>
                    <TextArea
                        label="Observaciones"
                        name="observations"
                        value={values.observations}
                        onChange={handleInputChange}
                        formatNumber={false}
                        inputContainer="newPromotionRequest__textArea"
                    />

                    {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
                    <SubmitButton buttonDisabled={submitting} text="Solicitar nuevo proyecto " />
                </form>
            </SimpleCard>
            {message?.text && <ResultadoCarga text={message.text} success={message.success} setMessage={setMessage} />}
            {loading && <Spinner />}
        </Layout>
    );
};
export default withAuth(NewPromotionRequest);
