export const truncateString = (text = null, maxTextLength) => {
    if (text?.length > maxTextLength) {
        return text.slice(0, maxTextLength) + '...';
    }
    return text;
};

export const capitalizeFirstLetter = (text = '') => {
    if (text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
    return text;
};
