import MultiProvider from 'lib/MultiProvider';
import { CarrierProvider } from 'modules/carrier';
import { httpCarrierApi } from 'modules/carrier/infrastructure/api/httpCarrierApi';
import { LerProvider } from 'modules/ler';
import { httpLerApi } from 'modules/ler/infrastructure/api/httpLerApi';
import { httpWasteFlowApi, WasteFlowProvider } from 'modules/waste-flow';
import { WasteManagerProvider } from 'modules/waste-manager';
import { httpWasteManagerApi } from 'modules/waste-manager/infrastructure/api/httpWasteManagerApi';
import WasteFlowCarrierSelector, { WasteFlowCarrierSelectorProps } from './WasteFlowCarrierSelector';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: WasteFlowCarrierSelectorProps) => (
    <MultiProvider
        providers={[
            <WasteFlowProvider api={httpWasteFlowApi} />,
            <LerProvider api={httpLerApi} />,
            <CarrierProvider api={httpCarrierApi} />,
            <WasteManagerProvider api={httpWasteManagerApi} />
        ]}
    >
        <WasteFlowCarrierSelector {...props} />
    </MultiProvider>
);
