import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import WasteFlowSelectorOption from 'components/BasicComponents/Select/CustomOptions/WasteFlowSelectorOption';
import { WasteFlow } from 'modules/waste-flow';

export function wasteFlowOptionAdapter(wasteFlow: WasteFlow, search?: string): SelectOption {
    return {
        value: wasteFlow,
        label: (
            <WasteFlowSelectorOption
                lerData={wasteFlow.ler.code + ' ' + wasteFlow.ler.type}
                carrierName={wasteFlow.carrier?.name}
                wasteManagerName={wasteFlow.manager.name}
                search={search}
            />
        )
    };
}
