import { INCIDENCES_LIST } from '../constants/siteCheking.constants';

export const getIncidences = (sections) => {
    const individualIncidences = getIndividualContainerIncidences(sections);
    const noContainerIncidences = getNoContainerIncidences(sections);

    return individualIncidences + noContainerIncidences;
};

const getIndividualContainerIncidences = (sections) => {
    let incidences = '';

    sections?.forEach((section) => {
        section?.containers?.forEach((container, i) => {
            const lerKind = container?.lerType?.lerKind;
            const containerName = lerKind + ' ' + (i + 1);
            let incidenceAdded = false;

            if (container?.existContainer) {
                for (const field in container) {
                    const value = container[field];

                    const newIncidenceText = INCIDENCES_LIST.find(
                        (incidence) => incidence.id === field + '-' + value
                    )?.text;

                    if (newIncidenceText !== undefined) {
                        incidences += (incidenceAdded ? ', ' : containerName + ': ') + newIncidenceText;
                        incidenceAdded = true;
                    }
                }

                if (incidenceAdded) {
                    incidences += '.';
                    incidenceAdded = false;
                }
            }
        });
    });

    return incidences;
};

const getNoContainerIncidences = (sections) => {
    let incidences = '';
    let noContainersType = [];

    sections?.forEach((section) => {
        let noContainersAux = section?.containers?.filter((container) => !container?.existContainer) || [];
        noContainersAux = noContainersAux?.map((container) => container?.lerType?.lerKind?.toLowerCase());

        noContainersType.push(...noContainersAux);
    });

    if (noContainersType?.length > 0) {
        incidences = 'Se debe separar: ';
        noContainersType?.forEach((type) => {
            incidences += type + ', ';
        });

        incidences = incidences.slice(0, -2);
        incidences += '.';
    }

    return incidences;
};
