import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { useAuth } from 'modules/auth';
import { usePromotion } from 'modules/promotion';
import { WasteManagementEntryType, wasteManagementApi } from 'modules/waste-management';
import { hasPermission } from 'utils/helpers/roles.helpers';
import './WasteManagementPlanner.scss';
import WMPlannerItem from './components/WMPlannerItem';
import { useExternalCheck } from './hooks/useExternalCheck';
import { useLerOrientativeCheck } from './hooks/useLerOrientativeCheck';

export interface WasteManagementPlannerProps {
    type: WasteManagementEntryType;
}

const WasteManagementPlanner = (props: WasteManagementPlannerProps) => {
    const [t] = useTranslation('wasteManagement');
    const [{ promotion }] = usePromotion();
    const [account] = useAuth();
    const canEdit = hasPermission('egrandpgr', 'canCreate', account.user, promotion?.id);

    const parameters = { promotionId: promotion?.id || NaN, type: props.type };
    const { data, refetch, error } = wasteManagementApi.useQuery(parameters);
    const { mutate: copy } = wasteManagementApi.useCopyWasteManagement();

    const { external, updateAndSaveExternal } = useExternalCheck({ data });
    const { lerOrientative, updateAndSaveLerOrientative } = useLerOrientativeCheck({ data });

    const reload = useCallback(() => refetch(), [parameters]);

    const copyEgrToPgr = async () => {
        await copy({
            from: { promotionId: promotion?.id || NaN, type: WasteManagementEntryType.EGR },
            to: { promotionId: promotion?.id || NaN, type: WasteManagementEntryType.PGR }
        });

        await refetch();
    };

    return (
        <>
            <SimpleCard
                className="WasteManagementPlanner"
                title={t(`${props.type}_large`)}
                subtitle={t(`explanation`, { type: props.type })}
            >
                {error && <ErrorCard error={error} />}

                {props.type === 'PGR' && (
                    <ActionConfirm
                        title={t('copyEgrToPgr')}
                        message={t('copyEgrToPgrWarning')}
                        onConfirm={() => copyEgrToPgr()}
                        confirmText={t('copyData')}
                        confirmStyle="primary"
                    >
                        <SmallButton titulo={t('copyEgrToPgr')} type="button" color="blue" {...({} as any)} />
                    </ActionConfirm>
                )}

                {!error && (
                    <>
                        <table className="WasteManagementPlanner__table">
                            <thead>
                                <tr>
                                    <th style={{ width: '40%' }}>{t('ler.code', { ns: 'common' })}*</th>
                                    <th style={{ width: '20%' }}>{t('weight.upperCase', { ns: 'common' })}* (t)</th>
                                    <th style={{ width: '20%' }}>{t('volume.upperCase', { ns: 'common' })} (m³)</th>
                                    <th style={{ width: '20%' }}>{t('cost', { ns: 'common' })} (€)</th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {canEdit && (
                                    <WMPlannerItem
                                        entry={{
                                            lerOrientative,
                                            external,
                                            promotionId: parameters.promotionId,
                                            type: props.type
                                        }}
                                        onCreated={reload}
                                    />
                                )}
                                {data
                                    ?.sort((a, b) => b.id - a.id)
                                    ?.map((entry) => (
                                        <WMPlannerItem key={entry.id} entry={entry} onDeleted={reload} />
                                    ))}
                            </tbody>
                        </table>
                        <div className="WasteManagementPlanner__additionalOptions">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={external}
                                    disabled={!canEdit}
                                    onChange={({ target }) => updateAndSaveExternal(target.checked)}
                                />
                                <span>{t('external')}</span>
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={lerOrientative}
                                    disabled={!canEdit}
                                    onChange={({ target }) => updateAndSaveLerOrientative(target.checked)}
                                />
                                <span>{t('lerOrientative')}</span>
                            </label>
                        </div>
                    </>
                )}
            </SimpleCard>
        </>
    );
};

export default WasteManagementPlanner;
