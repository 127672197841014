import { useEffect } from 'react';
import { useAnalyticsStore } from 'modules/analytics';
import { useAuth } from 'modules/auth';
import { useLocation } from 'react-router-dom';
import { ROLES } from 'utils/constants/roles.constants';

export function useAnalyticsBootstrap() {
    const [{ identification }, analytics] = useAnalyticsStore();
    const [auth] = useAuth();
    const { user } = auth;
    const location = useLocation();

    useEffect(() => {
        analytics.init();
    }, []);

    useEffect(() => {
        if (!identification) return;

        analytics.pageView({ page: location.pathname + location.search });
    }, [location]);

    useEffect(() => {
        if (!user) return;

        analytics.indentify({
            id: user.id,
            type: user.authorities.includes(ROLES.admin) ? 'internal' : 'external',
            authorities: user.authorities.join(',') || '',
            email: user.email || '',
            enterpriseId: user.enterprise.id || null,
            enterpriseName: user.enterprise.name || '',
            enterpriseCif: user.enterprise.cif || '',
            name: user.firstName || '',
            role: user.roleType.descripcion || ''
        });
    }, [user?.id]);

    return { analytics };
}
