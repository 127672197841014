import { IndexedDbCache } from 'lib/cache';
import { ComparativePromotion } from '../../domain/ComparativePromotion';

export const HourlyComparativeCache = new IndexedDbCache<ComparativePromotion[]>({
    key: 'Comparative',
    expires: {
        every: 60 * 60, // every hour
        offset: 0 // o'clock
    }
});
