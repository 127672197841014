import { TraceabilitiesCriteria, TraceabilitiesSort } from 'modules/traceabilities/domain/TraceabilitiesCriteria';

export const traceabilitiesCriteriaAdapter = (criteria: TraceabilitiesCriteria) => {
    const sort: { [O in TraceabilitiesSort]: string } = {
        'cost,asc': 'costeLER,asc',
        'cost,desc': 'costeLER,desc',
        'lerCode,desc': 'promoProGesCodeLer.procesoGestoraCodLer.codLER.codigoLER,asc',
        'lerTypeName,desc': 'promoProGesCodeLer.procesoGestoraCodLer.codLER.lerType.lerKind,asc',
        'movementDate,asc': 'fechaMovimiento,asc',
        'movementDate,desc': 'fechaMovimiento,desc',
        'valorizationCode,desc':
            'promoProGesCodeLer.procesoGestoraCodLer.procesoGestora.valorizacion.codigoValorizacion,desc',
        'volume,asc': 'volumenGestionado,asc',
        'volume,desc': 'volumenGestionado,desc',
        'weight,asc': 'pesoGestionado,asc',
        'weight,desc': 'pesoGestionado,desc'
    };

    const params = {
        'id.equals': criteria.id,
        ...(criteria.promotionId ? { 'promocionId.equals': criteria.promotionId } : {}),
        'fechaMovimiento.greaterThanOrEqual': criteria.dateFrom || null,
        'fechaMovimiento.lessThanOrEqual': criteria.dateTo || null,
        'codLer.in': criteria.lerCodes?.join(),
        'lerType.in': criteria.lerTypes?.join(),
        'gestoraId.in': criteria.wasteManagers?.join(),
        'carrierId.in': criteria.carriers?.join(),
        ...(criteria.excludeLands === true
            ? {
                  'land.equals': false
              }
            : {}),
        'certified.specified': criteria.certified,
        ...(criteria.certified === true
            ? {
                  'certified.specified': true,
                  'valorizationCode.notEquals': 'RU0'
              }
            : {}),
        ...(criteria.certified === false
            ? {
                  'certified.specified': false,
                  'valorizationCode.notEquals': 'RU0'
              }
            : {}),
        'traceabilityStatus.in': criteria.status?.join(),
        'porcentajeValorizacion.greaterThan': criteria.valorization === 'with' ? '1' : null,
        'porcentajeValorizacion.equals': criteria.valorization === 'without' ? '1' : null,
        page: criteria.page,
        size: criteria.size,
        sort: criteria.sort ? sort[criteria.sort] : sort['movementDate,desc'],
        'updatedBy.contains': criteria.updatedBy?.toLowerCase(),
        ...(criteria.excludeLands ? { 'land.equals': 'false' } : {})
    };
    return params;
};
