import { useEffect, useState } from 'react';

import { WasteManagement, wasteManagementApi } from 'modules/waste-management';

interface UseLerOrientativeCheckProps {
    data?: WasteManagement | null;
}

export const useLerOrientativeCheck = (props: UseLerOrientativeCheckProps) => {
    const [lerOrientative, setLerOrientative] = useState<boolean | undefined>();
    const { mutate: save } = wasteManagementApi.useSave();

    useEffect(() => {
        const allLerOrientative = (props.data?.length || 0) > 0 && props.data?.every((entry) => entry.lerOrientative);
        setLerOrientative(allLerOrientative);
    }, [props.data]);

    const updateAndSaveLerOrientative = (value: boolean) => {
        if (!props.data) return;

        setLerOrientative(value);
        save(
            props.data.map((entry) => ({
                ...entry,
                lerOrientative: value
            }))
        );
    };

    return { lerOrientative, updateAndSaveLerOrientative };
};
