import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import promoProGesCodLersServices from '../../../../api/promoProGesCodLers/promoProGesCodLers.services';
import { withAuth } from '../../../../context/AuthProvider';
import { codeTypeTransferOperator } from '../../../../features/new_ppgcl/helpers/waste_manager.helpers';
import { allFieldsComplete } from '../../../../utils/helpers/general.helpers';
import SmallButton from '../../../BasicComponents/Buttons/Small/SmallButton';
import SubmitButton from '../../../BasicComponents/Buttons/SubmitButton';
import InputNumber from '../../../BasicComponents/Inputs/InputNumber';
import MessageNotComplete from '../../../BasicComponents/Messages/MessageNotComplete';
import ResultadoCarga from '../../../BasicComponents/Messages/ResultadoCarga';
import Spinner from '../../../BasicComponents/Spinner/Spinner';
import ProGesCodLerAutocomplete from '../NewPpgcl/ProGesCodLerAutocomplete';
import { modifyLinkedTreatmentContract } from './api/modifyLinkedTreatmentContract';

const FormEditPpgcl = ({ ppgcl, allPpgcl, setAllPpgcl, openEditPopover, setOpenEditPopover, promocion }) => {
    const [ppgclToEdit, setPpgclToEdit] = useState(ppgcl);
    const [validation, setValidation] = useState(false);
    const [submitting, setSumitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageNotComplete, setMessageNotComplete] = useState('');
    const [costData, setCostData] = useState({ unit: '', ton: '' });
    const [message, setMessage] = useState();
    const [transferOperatorSelected, setTransferOperatorSelected] = useState();
    const [t] = useTranslation();
    const promotion = promocion;

    const selectTransferOperator = (type) => {
        if (!transferOperatorSelected || transferOperatorSelected !== type) {
            setTransferOperatorSelected(type);
            setPpgclToEdit({ ...ppgclToEdit, transferOperatorTypeId: codeTypeTransferOperator[type] });
        } else {
            setTransferOperatorSelected();
            delete ppgclToEdit.transferOperatorTypeId;
        }
    };

    const hasTransferOperator =
        ppgcl.transferOperatorType.id !== 4 ||
        (ppgcl.transferOperatorType.id === 4 && ppgcl.transferOperatorProGesCodLer)
            ? true
            : false;
    const createMessage = (request, ppgcl) => {
        if (request !== undefined && request.status === 200) {
            setMessage({ text: t('messageEdit.success', { ns: 'newPpgcl' }), success: true });
            updatePpgclEditedInArr(ppgcl);
        } else {
            setMessage({ text: t('messageEdit.error', { ns: 'newPpgcl' }), success: false });
        }
    };

    const updatePpgclEditedInArr = (ppgclEdited) => {
        const objIndex = allPpgcl.findIndex((ppgcl) => ppgcl.id === ppgclEdited.id);
        allPpgcl[objIndex] = ppgclEdited;
        setAllPpgcl(allPpgcl);
        setTimeout(() => {
            setOpenEditPopover(!openEditPopover);
        }, 2500);
    };

    const createEditObj = () => {
        let copyEditObj = Object.assign(ppgclToEdit);
        return (copyEditObj = {
            ...copyEditObj,
            carrierProGesCodLerId: copyEditObj.carrierProGesCodLer?.id ? copyEditObj.carrierProGesCodLer?.id : null,
            transferOperatorProGesCodLerId: copyEditObj.transferOperatorProGesCodLer?.id
                ? copyEditObj.transferOperatorProGesCodLer?.id
                : null,
            promocionId: copyEditObj.promocion?.id,
            wasteManagerProGesCodLerId: copyEditObj.wasteManagerProGesCodLer?.id
        });
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSumitting(true);
        if (validationForm()) {
            const editPpgclObj = createEditObj();
            const ppgclRequest = await promoProGesCodLersServices.editPromoProGesCodLer(editPpgclObj);

            // The edit legacy endpoint unlinks the CT. Link it again via PATCH
            if (editPpgclObj?.contractId !== null) {
                await modifyLinkedTreatmentContract({
                    projectWasteFlowId: editPpgclObj?.id,
                    contractId: editPpgclObj?.contractId
                });
            }

            createMessage(ppgclRequest, editPpgclObj);
        }
        setLoading(false);
        setSumitting(false);
    };

    const handleInputChange = ({ target }) => {
        setPpgclToEdit({
            ...ppgclToEdit,
            [target.name]: target.value
        });
    };

    const onCostChange = ({ target }) => {
        const updatedCost = { ...costData, [target.name]: target.value };
        setCostData(updatedCost);
        setPpgclToEdit({
            ...ppgclToEdit,
            cost: {
                unit: updatedCost?.unit,
                ton: updatedCost?.ton
            }
        });
    };

    const requiredFields = {
        volumenUnitario: ppgclToEdit.volumenUnitario,
        carrierProGesCodLer: ppgclToEdit.carrierProGesCodLer
    };

    const validationForm = () => {
        if (allFieldsComplete(requiredFields)) {
            return true;
        } else {
            setValidation(true);
            setMessageNotComplete(t('notComplete', { ns: 'errors' }));
            return false;
        }
    };

    useEffect(() => {
        if (ppgclToEdit.transferOperatorProGesCodLer) {
            setTransferOperatorSelected();
            setPpgclToEdit({ ...ppgclToEdit, transferOperatorTypeId: codeTypeTransferOperator['ANOTHER_MANAGER'] });
        } else if (ppgclToEdit?.transferOperatorType) {
            setPpgclToEdit({
                ...ppgclToEdit,
                transferOperatorTypeId: ppgclToEdit?.transferOperatorType?.id
            });
        }
        setCostData({
            unit: ppgclToEdit?.cost?.unit ?? '',
            ton: ppgclToEdit?.cost?.ton ?? ''
        });
    }, [ppgclToEdit.transferOperatorProGesCodLer]);

    return (
        <div className="seekerPpgcl____editPpgcl">
            <p>
                <span>{t('wasteManager', { ns: 'newPpgcl' })}:</span>{' '}
                {ppgcl.wasteManagerProGesCodLer.procesoGestora.gestora.empresa}
            </p>
            <p>
                <span>{t('ler', { ns: 'newPpgcl' })}:</span> {ppgcl.codLer}
            </p>
            {ppgcl?.carrierProGesCodLer && (
                <p>
                    <span>{t('carrier', { ns: 'newPpgcl' })}: </span>
                    {ppgcl.carrierProGesCodLer.procesoGestora.gestora.empresa}
                </p>
            )}
            {ppgcl?.transferOperatorProGesCodLer && (
                <p>
                    <span>{t('transferOperator', { ns: 'newPpgcl' })}: </span>
                    {ppgcl.transferOperatorProGesCodLer.procesoGestora.gestora.empresa}
                </p>
            )}

            <form onSubmit={handleFormSubmit}>
                {!ppgcl?.carrierProGesCodLer && (
                    <ProGesCodLerAutocomplete
                        values={ppgclToEdit}
                        setValues={setPpgclToEdit}
                        validacion={validation}
                        typeOfWasteManager="CARRIER"
                        value={ppgclToEdit?.carrierProGesCodLer}
                        name="carrierProGesCodLer"
                        codLerPath={ppgclToEdit.codLer}
                        required
                    />
                )}
                {!hasTransferOperator && (
                    <>
                        <p className="editPpgcl__p">Seleccionar operador del traslado</p>
                        <div className="editPpgcl__container">
                            <SmallButton
                                action={() => selectTransferOperator('PROMOTION')}
                                titulo={promocion.nombre}
                                className={transferOperatorSelected === 'PROMOTION' ? 'transferOperator__active' : ''}
                            />
                            <SmallButton
                                action={() => selectTransferOperator('SAME_MANAGER')}
                                titulo={ppgcl.wasteManagerProGesCodLer.procesoGestora.gestora.empresa}
                                className={
                                    transferOperatorSelected === 'SAME_MANAGER' ? 'transferOperator__active' : ''
                                }
                            />
                            <ProGesCodLerAutocomplete
                                values={ppgclToEdit}
                                setValues={setPpgclToEdit}
                                typeOfWasteManager="TRANSFER_OPERATOR"
                                value={ppgclToEdit.transferOperatorProGesCodLer}
                                name="transferOperatorProGesCodLer"
                                codLerPath={ppgclToEdit.codLer}
                            />
                        </div>
                    </>
                )}
                {promotion?.permissions?.costs && (
                    <>
                        <InputNumber
                            label="Coste unitario"
                            name="unit"
                            value={Number(ppgclToEdit?.cost?.unit)}
                            onChange={onCostChange}
                            placeholder={true}
                            required
                            validacion={validation}
                        />
                        <InputNumber
                            label="Coste por tonelada"
                            name="ton"
                            value={Number(ppgclToEdit?.cost?.ton)}
                            onChange={onCostChange}
                            placeholder={true}
                        />
                    </>
                )}
                <InputNumber
                    label={t('volumHired', { ns: 'newPpgcl' })}
                    name="volumenUnitario"
                    value={
                        ppgclToEdit?.volumenUnitario
                            ? Number(ppgclToEdit?.volumenUnitario)
                            : ppgclToEdit.volumenUnitario
                    }
                    onChange={handleInputChange}
                    placeholder={true}
                    required
                    validacion={validation}
                />

                <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={submitting} />
                {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
            </form>
            {loading && <Spinner />}
            {message?.text && <ResultadoCarga text={message.text} setMessage={setMessage} success={message.success} />}
        </div>
    );
};

export default withAuth(FormEditPpgcl);
