import { useFormContext } from 'react-hook-form';

import InputFormControl from 'components/FormControls/InputFormControl';
import SelectAutocompleteFormControl from 'components/FormControls/SelectAutocompleteFormControl';
import { JsonSchema } from 'modules/shared';
import './DynamicFormControl.scss';
import DateFormControl from 'components/FormControls/DateFormControl';

interface DynamicFormControlProps {
    node: JsonSchema;
    path: string;
    isRequired?: boolean;
}

const DynamicFormControl = ({ node, path, isRequired }: DynamicFormControlProps) => {
    const form = useFormContext();

    if (!node.type) return null;

    const label = `${node.title || path}${isRequired ? '*' : ''}`;

    if (node.oneOf) {
        return (
            <SelectAutocompleteFormControl
                key={path}
                name={path}
                label={label}
                control={form.control}
                dataTestId={label}
                className="DynamicFormControl"
                loadOptions={async (search) => ({
                    hasMore: false,
                    options:
                        node.oneOf
                            ?.filter((v) => v.title.toLowerCase().includes(search.toLowerCase()))
                            ?.map((v) => ({ label: v.title, value: v.const })) || []
                })}
                valueAdapter={(v) => ({ label: v, value: v })}
                onChangeAdapter={(o) => {
                    if (Array.isArray(o) || !o) return undefined;
                    return o.value;
                }}
            />
        );
    }

    if (node.type === 'string' && ['date', 'date-time'].includes(node.format || '')) {
        return (
            <DateFormControl
                key={path}
                name={path}
                label={label}
                format={
                    (node.format === 'date' && 'YYYY-MM-DD') || //
                    (node.format === 'date-time' && 'ISO') || //
                    'ISO'
                }
                control={form.control}
                className="DynamicFormControl"
                type="date"
            />
        );
    }

    if (node.type === 'number' || node.type === 'string') {
        return (
            <InputFormControl
                key={path}
                name={path}
                label={label}
                control={form.control}
                className="DynamicFormControl"
                type={node.type === 'number' ? 'number' : 'text'}
            />
        );
    }

    return <code>{node.type} NOT IMPLEMENTED</code>;
};

export default DynamicFormControl;
