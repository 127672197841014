import { PropsWithChildren } from 'react';
import './EllipsisText.scss';

interface EllipsisTextProps {
    children: React.ReactNode;
}

const EllipsisText = ({ children }: PropsWithChildren<EllipsisTextProps>) => {
    return (
        <span title={children?.toString()} className="EllipsisText">
            {children}
        </span>
    );
};

export default EllipsisText;
