import { Reducers, createStore } from 'modules/shared';
import { AnalyticsStore } from '../domain/AnalyticsStore';
import { init } from './actions/init';
import { event } from './actions/event';
import { indentify } from './actions/indentify';
import { pageView } from './actions/pageView';

export const analyticsStore = createStore({
    reducers: {} as Reducers<AnalyticsStore>,
    initialState: {
        initialized: null,
        identification: null,
        error: null
    },
    thunks: {
        init,
        event,
        indentify,
        pageView
    }
});
