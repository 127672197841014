import ListadoActividades from 'pages/Activities/Activities';
import ActivityType from 'pages/Activities/ActivityType';
import NuevaActividad from 'pages/Activities/NuevaActividad';
import NuevaVisita from 'pages/Visitas/NuevaVisita';

import ActivityDetail from '../components/ActivityDetail';
import ActivitiesRoot from '../components/ActivitiesRoot';

export const activitiesRouteRoot = '/promotion/:promotionId/activities';

export const ACTIVITIES_ROUTES = [
    {
        component: ActivitiesRoot,
        path: activitiesRouteRoot,
        requiredPermission: 'canView',
        section: ['activities', 'siteChecking'],
        exact: false
    }
];

export const INTERNAL_ACTIVITIES_ROUTES = [
    {
        component: ActivityType,
        path: activitiesRouteRoot + '/type-list',
        requiredPermission: 'canView',
        section: ['activities', 'siteChecking']
    },
    {
        component: NuevaActividad,
        path: activitiesRouteRoot + '/create',
        requiredPermission: 'canCreate',
        section: 'activities'
    },
    {
        component: NuevaVisita,
        path: activitiesRouteRoot + '/create-visit',
        requiredPermission: 'canComplete',
        section: 'siteChecking'
    },
    {
        component: NuevaVisita,
        path: activitiesRouteRoot + '/:activityId/edit-visit',
        requiredPermission: 'canComplete',
        section: 'siteChecking'
    },
    {
        component: NuevaActividad,
        path: activitiesRouteRoot + '/:activityId/edit',
        requiredPermission: 'canCreate',
        section: 'activities'
    },
    {
        component: ActivityDetail,
        path: activitiesRouteRoot + '/:activityId',
        requiredPermission: 'canView',
        section: ['activities', 'siteChecking']
    },
    {
        component: ListadoActividades,
        path: activitiesRouteRoot,
        requiredPermission: 'canView',
        section: ['activities', 'siteChecking']
    }
];
