import './EnvLabel.scss';

const EnvLabel = () => {
    const ENV = process.env.REACT_APP_ENV || 'development';

    if (ENV === 'development') {
        return <div className="label-environment label__dev">Desarrollo</div>;
    }
    if (ENV === 'staging') {
        return <div className="label-environment">Demo</div>;
    }

    return <></>;
};

export default EnvLabel;
