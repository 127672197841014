import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import directionServices from '../../../api/directions/directions.services';
import { withAuth } from '../../../context/AuthProvider';
import { customStyles, hasMore } from './helpers';

const ProvinceAutocomplete = ({
    propName = 'province',
    required,
    className,
    values,
    setValues,
    isClearable = false,
    INITIAL_STATE = ''
}) => {
    const [t] = useTranslation();

    const getLoadOptions = async (search, loadedOptions, { page }) => {
        let filter = search ? `?nombre.contains=${search}&sort=nombre,asc` : `?page=${page}&sort=nombre,asc`;
        let options;
        options = await directionServices.getProvinces(filter);
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        if (item) {
            setValues({ ...values, [propName]: item });
        } else {
            setValues({ ...values, [propName]: INITIAL_STATE });
        }
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {t('province', { ns: 'common' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={getLoadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.nombre}
                placeholder=""
                value={values[propName]}
                additional={{
                    page: 0
                }}
                filterOption={false}
                styles={customStyles()}
                isClearable={isClearable}
            />
        </div>
    );
};
export default withAuth(ProvinceAutocomplete);
