import valorizaBadgeCriteria from 'assets/Criterios Sello Valoriza - CoCircular.pdf';
import valorizaObjective0 from 'assets/imagenes/objetivo-valoriza-0.png';
import valorizaObjective1 from 'assets/imagenes/objetivo-valoriza-1.png';
import valorizaObjective2 from 'assets/imagenes/objetivo-valoriza-2.png';
import valorizaObjective3 from 'assets/imagenes/objetivo-valoriza-3.png';
import valorizaObjective4 from 'assets/imagenes/objetivo-valoriza-4.png';
import valorizaObjective5 from 'assets/imagenes/objetivo-valoriza-5.png';

import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import { usePromotion } from 'modules/promotion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './ValorizaBadge.scss';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';

const ValorizaBadge = () => {
    const [t] = useTranslation('promotion');
    const [{ promotion }] = usePromotion();
    const [, analytics] = useAnalyticsStore();
    const [openModal, setOpenModal] = useState(false);
    const [puntuacionSelloVal] = useState(promotion?.puntuacionSelloValoriza || 0);

    const IMGS = [
        valorizaObjective0,
        valorizaObjective1,
        valorizaObjective2,
        valorizaObjective3,
        valorizaObjective4,
        valorizaObjective5
    ];

    return (
        <>
            <img
                src={IMGS[puntuacionSelloVal]}
                className="ValorizaBadge"
                alt="Sello Valoriza"
                onClick={() => {
                    setOpenModal(!openModal);
                    analytics.event(AnalyticsEvents.PROMO_VALORIZA_BADGE_CLICKED);
                }}
            />

            {openModal && (
                <SimplePopover
                    className="ValorizaBadge__explanation"
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    title={t('seal.title')}
                >
                    <p>{t('seal.popover.pt1')}</p>
                    <br />
                    <p>
                        <span>{t('seal.popover.pt2')} </span>
                        <a href={valorizaBadgeCriteria} target="_blank" rel="noreferrer">
                            {t('seal.popover.pt3')}
                        </a>
                        .
                    </p>
                </SimplePopover>
            )}
        </>
    );
};

export default ValorizaBadge;
