import { useTranslation } from 'react-i18next';
import { Link, Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import Tabs from 'components/BasicComponents/Tabs';
import PrivateRoute from 'components/routes/PrivateRoute';
import Layout from 'pages/Layout/Layout';
import PromotionAnalysis from '../PromotionAnalysis';
import PromotionList from '../PromotionList';
import './HomePage.scss';

const HomePage = () => {
    const [t] = useTranslation('home');
    const { url } = useRouteMatch();
    const location = useLocation();

    const routes = [
        {
            name: 'projectList',
            to: `${url}/list`,
            component: PromotionList
        },
        {
            name: 'projectComparative',
            to: `${url}/analysis`,
            component: PromotionAnalysis
        }
    ];

    const active = routes.findIndex((route) => location.pathname.startsWith(route.to));

    return (
        <Layout>
            <div className="HomePage">
                <section className="HomePage__tabs">
                    <Tabs
                        activeTab={active}
                        tabs={routes.map((route) => (
                            <Link className="HomePage__tab" to={route.to}>
                                {t(route.name)}
                            </Link>
                        ))}
                    />
                </section>
                <section className="HomePage__content">
                    <Switch>
                        {routes.map(({ to, component, ...rest }) => (
                            <PrivateRoute path={to} key={to} component={component} {...rest} />
                        ))}
                        <Redirect to={routes[0].to} />
                    </Switch>
                </section>
            </div>
        </Layout>
    );
};

export default HomePage;
