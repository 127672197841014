import { IconSearch } from '@tabler/icons';
import BasicButton from './Base/BasicButton';

const SearchButton = ({ type = 'submit', text = 'Buscar', disabled = false, action, to, state }) => {
    return (
        <BasicButton
            color="blue"
            type={type}
            text={text}
            action={action}
            to={to}
            state={state}
            disabled={disabled}
            icon={<IconSearch stroke={2} size={18} color="white" />}
        />
    );
};
export default SearchButton;
