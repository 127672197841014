import { logger } from 'logger';

import { httpTraceabilitiesApi, TraceabilitiesProvider } from 'modules/traceabilities';
import TraceabilitiesSummaryPrintable, { TraceabilitiesSummaryPrintableProps } from './TraceabilitiesSummaryPrintable';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: TraceabilitiesSummaryPrintableProps) => {
    return (
        <TraceabilitiesProvider api={httpTraceabilitiesApi} logger={logger}>
            <TraceabilitiesSummaryPrintable {...props} />
        </TraceabilitiesProvider>
    );
};
