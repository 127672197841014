import { Reducers, createStore } from 'modules/shared/domain/store/createStore';
import { BilleablesStore } from '../domain/BilleablesStore';
import { fetchBilleables, fetchReducers } from './fetch/fetchBilleables';
import { purchaseReducers, purchaseBilleable } from './purchase/purchaseBilleable';
import { removeReducers, removeBilleable } from './remove/removeBilleable';
import { saveReducers, saveBilleables } from './save/saveBilleables';
import { updateReducers, updateBilleable } from './update/updateBIlleable';

const reducers: Reducers<BilleablesStore> = {
    // Common reducers
    errorSetted: (state, payload) => ({ ...state, error: payload.error, loading: 'failed' }),
    loadingSetted: (state, payload) => ({ ...state, loading: payload.loading }),

    ...fetchReducers,
    ...purchaseReducers,
    ...removeReducers,
    ...saveReducers,
    ...updateReducers
};

export const billeablesStore = createStore({
    initialState: {
        entities: {
            allIds: [],
            byId: {}
        },
        loading: 'idle',
        error: null
    },
    reducers,
    thunks: {
        fetchBilleables,
        purchaseBilleable,
        removeBilleable,
        saveBilleables,
        updateBilleable
    }
});
