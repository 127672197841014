import { useDateFormatter } from 'hooks/useDateFormatter';
import { GoalMet, MandatoryFlowsGoal } from 'modules/promotion-kpis';
import { MandatoryFlowAlarmsProps } from '../MandatoryFlowAlarms';

const today = new Date();

export function useMandatoryFlowsAlarms(props: MandatoryFlowAlarmsProps) {
    const date = useDateFormatter();

    const currentMonth = date.format(today, 'YYYY-MM-01');

    // Get the alarms for the current month
    const onlyCurrentGoalsFlows =
        props.mandatoryFlows?.lerTypes.map(({ history, ...flow }) => {
            const currentMonthEntry = history?.find((entry) => entry.month === currentMonth);
            const lastEntry = history ? history[history.length - 1] : undefined;

            const applicableEntry = currentMonthEntry || lastEntry;

            return { ...flow, ...applicableEntry };
        }) || [];

    const countAlarms = onlyCurrentGoalsFlows
        .map(({ goals, ...flow }) => ({
            ...flow,
            goal: goals?.find((goal) => goal.value.type === 'COUNT' && goal.met === false)
        }))
        .filter((flow) => flow.goal);

    const getEgrAndPgrAlarms = (props: { severity: GoalMet<MandatoryFlowsGoal>['severity'] }) =>
        onlyCurrentGoalsFlows
            .map(({ goals, ...flow }) => ({
                ...flow,
                goal: goals?.find(
                    (goal) => goal.value.type.endsWith('GR') && goal.met === false && goal.severity === props.severity
                )
            }))
            .filter((flow) => flow.goal);

    return {
        notApplicable:
            (props.mandatoryFlows?.lerTypes.length || 0) > 0 &&
            onlyCurrentGoalsFlows.some((flow) => flow.goals?.length === 0),
        allMet: onlyCurrentGoalsFlows.every((goal) => goal.met === true),
        count: {
            Critical: countAlarms.filter((alarm) => alarm.severity === 1),
            Warning: countAlarms.filter((alarm) => alarm.severity === 2)
        },
        egrAndPgr: {
            Critical: getEgrAndPgrAlarms({ severity: 1 }),
            Warning: getEgrAndPgrAlarms({ severity: 2 })
        }
    };
}

export type MandatoryFlowAlarms = ReturnType<typeof useMandatoryFlowsAlarms>['count']['Warning'];
