import FinalTraceabilityPopover from 'components/BasicComponents/Popovers/Traceability/FinalTraceabilityPopover';
import { useTraceabilities } from 'modules/traceabilities';

interface TraceabilityDetailFinalProps {
    traceabilityId: string;
}

const TraceabilityDetailFinal = (props: TraceabilityDetailFinalProps) => {
    const [traceabilities] = useTraceabilities();

    const traceability = traceabilities.data.byId[props.traceabilityId];

    return (
        <>
            <FinalTraceabilityPopover finalTraceability={traceability?.finalTraceability || {}} />
        </>
    );
};

export default TraceabilityDetailFinal;
