import { Reducers, createStore } from 'modules/shared/domain/store/createStore';

import { PromotionStore } from '../domain/PromotionStore';
import { loadPromotion, loadPromotionReducers } from './load/loadPromotion';
import { loadPromotionPreviews, loadPromotionPreviewsReducers } from './load/loadPromotionPreviews';

const reducers: Reducers<PromotionStore> = {
    loadPending(state) {
        return { ...state, loading: 'pending' };
    },
    loadRejected(state, payload) {
        return { ...state, loading: 'failed', promotion: null, error: payload.error };
    },
    ...loadPromotionReducers,
    ...loadPromotionPreviewsReducers
};

export const promotionStore = createStore({
    reducers,
    initialState: {
        promotion: null,
        error: null,
        loading: 'idle',
        promotionPreviews: [],
        totalPromotionPreviews: {
            inProgress: null,
            finished: null
        },
        promotionPreviewCriteria: {
            page: 0
        },
        loadingPromotionPreviews: 'idle',
        errorPromotionPreviews: null
    },
    thunks: {
        loadPromotion,
        loadPromotionPreviews
    }
});
