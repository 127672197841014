import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import directionServices from '../../../../api/directions/directions.services';
import { withAuth } from '../../../../context/AuthProvider';
import { customStyles } from '../../../BasicComponents/Select-Autocomplete/helpers';

const TownAutocomplete = (props) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState(false);
    const { municipio } = props.values;
    const { setValues, values, required, className } = props;

    const hasMore = (options) => {
        return options.length === 20 ? true : false;
    };

    const loadOptions = async (search, loadedOptions, { page }) => {
        let towns;
        let filter = search ? `?nombre.contains=${search}` : `?page=${page}`;
        towns = await directionServices.getTown(filter);
        return {
            options: towns,
            hasMore: hasMore(towns),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOptionMunicipio = (item) => {
        setValues({ ...values, municipio: item });
    };

    const emptyInput = () => {
        if (props.validacion === true && required && municipio === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    useEffect(() => {
        emptyInput();
    }, [props.validacion, municipio]);

    return (
        <div className={`input__container ${className || ''}`}>
            <label className="input__label">
                {t('town', { ns: 'common' })} {required ? '*' : ''}
            </label>

            <AsyncPaginate
                loadOptions={loadOptions}
                onChange={selectOptionMunicipio}
                getOptionLabel={(option) => option.nombre}
                getOptionValue={(option) => option}
                placeholder=""
                value={municipio}
                additional={{
                    page: 0
                }}
                styles={customStyles(notComplete)}
            />
        </div>
    );
};
export default withAuth(TownAutocomplete);
