import { IconBuildingFactory, IconTruck } from '@tabler/icons';
import { removeAccentedCharacters } from 'utils/helpers/general.helpers';
import './WasteFlowSelectorOption.scss';

interface WasteFlowSelectorOptionProps {
    lerData: string;
    wasteManagerName: string;
    carrierName: string | undefined;
    search?: string;
}

const WasteFlowSelectorOption = ({ lerData, wasteManagerName, carrierName, search }: WasteFlowSelectorOptionProps) => {
    const highlightSearch = (string: string) => {
        if (!search || search === '') return string;

        const cleanedString = removeAccentedCharacters(string);
        const cleanedSearch = removeAccentedCharacters(search);

        var match = new RegExp(cleanedSearch, 'i').exec(cleanedString);

        if (!match) return string;

        const i = match.index;

        const firstPart = string.substring(0, i);
        const middlePart = string.substring(i, i + search.length);
        const lastPart = string.substring(firstPart.length + middlePart.length);

        const highlightedText = firstPart + `<mark>${middlePart}</mark>` + lastPart;

        return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
    };

    return (
        <div className="WasteFlowSelectorOption" data-testid="WasteFlowSelectorOption">
            <p className="WasteFlowSelectorOption__ler">{highlightSearch(lerData)}</p>
            <p className="WasteFlowSelectorOption__agents">
                <p className="WasteFlowSelectorOption__agent">
                    <IconBuildingFactory />
                    <span title={'Gestora: ' + wasteManagerName}>{highlightSearch(wasteManagerName)}</span>
                </p>
                <p className="WasteFlowSelectorOption__agent">
                    <IconTruck />
                    <span title={'Transportista: ' + carrierName || '-'}>
                        {carrierName ? highlightSearch(carrierName) : '-'}
                    </span>
                </p>
            </p>
        </div>
    );
};

export default WasteFlowSelectorOption;
