import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconInfoCircle } from '@tabler/icons';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import SimplePopover from '../../../components/BasicComponents/Popovers/SimplePopover';
import { ReactComponent as LandUse } from '../../../assets/iconos/valorizadas/landUse.svg';
import { isGraphData } from '../mappers/isGraphData';
import SuggestionCard from '../../../components/BasicComponents/Cards/SuggestionCard';
import { currencyFormatDE } from '../../../utils/helpers/general.helpers';
import PopoverCalculation from './PopoverInfoCalculation';
import TemporalLineGraph from './graphs/GraphTemporalLine';
import ByLerBarGraph from './graphs/GraphByLerBar';
import Kpi from './ImpactKpi';
import './Impact.scss';

const ImpactLandUse = ({
    landUseKPI,
    landUseTemporalGraphData,
    landUseByLerGraphData,
    equivalencies,
    showAccumulatedGraph = true
}) => {
    const [t] = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const handleToggleModal = () => {
        setOpenModal(!openModal);
    };

    const getButtonIconAndTitle = (title) => {
        return (
            <>
                <IconInfoCircle size={18} stroke={1.5} />
                <span>{title}</span>
            </>
        );
    };

    const emptyData = Object.values(landUseKPI).every((kpi) => kpi === '0');
    if (emptyData) return null;

    return (
        <SimpleCard
            title={t('landUse.title', { ns: 'impact' })}
            button
            buttonTitle={getButtonIconAndTitle(t('howIsItCalculated', { ns: 'impact' }))}
            buttonAction={() => handleToggleModal()}
        >
            <div className="impact__card">
                <div className="Impact__containerGraphs">
                    <div>
                        <div className="impact__kpisContainer">
                            <Kpi
                                kpiValue={currencyFormatDE(landUseKPI?.real)}
                                IconKPI={LandUse}
                                type="real"
                                testid="kpiLandAvoidedReal"
                            />
                            <Kpi
                                kpiValue={currencyFormatDE(landUseKPI?.potential)}
                                IconKPI={LandUse}
                                type="potential"
                                testid="kpiLandAvoidedPotential"
                            />
                            <Kpi
                                kpiValue={currencyFormatDE(landUseKPI?.standard)}
                                IconKPI={LandUse}
                                type="standard"
                                testid="kpiLandAvoidedStandard"
                            />
                        </div>
                        {isGraphData(landUseTemporalGraphData) && showAccumulatedGraph ? (
                            <TemporalLineGraph
                                graphTitle={t('landUse.byDate', { ns: 'impact' })}
                                graphData={landUseTemporalGraphData}
                                legendTitle="t C déficit"
                            />
                        ) : null}
                    </div>

                    <div>
                        {/** @deprecated - Hide KPI until we decide what to do with it in a bigger refactor */
                        /* <div className="impact__kpisContainer">
                            <Kpi
                                kpiValue={currencyFormatDE(landUseKPI?.potentialUnseparatedWastes)}
                                IconKPI={LandUse}
                                type="potentialUnseparatedWastes"
                                testid="kpiLandAvoidedPotentialUnseparatedWastes"
                            >
                                {t('potentialForUnusedWastesMessage', { ns: 'impact' })}
                            </Kpi>
                        </div> */}
                        {landUseByLerGraphData?.length > 0 ? (
                            <ByLerBarGraph
                                graphTitle={t('landUse.byLer', { ns: 'impact' })}
                                graphTitleSuffix={`(${t('separatedIntoProject', { ns: 'impact' })})`}
                                graphData={landUseByLerGraphData}
                                legendTitle="t C déficit"
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            {landUseKPI?.real && (
                <div className="impact__equivalenciesContainer">
                    <SuggestionCard title="El uso de suelo evitado equivale al consumo de...">
                        <p data-testid="kpiLandAvoidedArea" className="impact__equivalencia">
                            <span>{currencyFormatDE(equivalencies?.meters)} </span> m²,
                            <span> ({currencyFormatDE(Number((equivalencies?.meters / 10000).toFixed(2)))}</span>{' '}
                            hectáreas) de naturaleza intacta
                        </p>
                    </SuggestionCard>
                </div>
            )}
            <SimplePopover
                openModal={openModal}
                setOpenModal={setOpenModal}
                noClose={false}
                title={t('popoverTitle', { ns: 'impact' })}
                addFunctionOnClose={handleToggleModal}
            >
                <PopoverCalculation />
            </SimplePopover>
        </SimpleCard>
    );
};

export default ImpactLandUse;
