import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import InputFormControl from 'components/FormControls/InputFormControl';
import SelectAutocompleteFormControl from 'components/FormControls/SelectAutocompleteFormControl';
import { useLocationStore } from 'modules/location/infrastructure/react/LocationContext';
import { PromotionDataFormSchema } from '../schemas/PromotionDataFormSchema';

const townOptionAdapter = (v: PromotionDataFormSchema['town']) => ({ label: v.name, value: v.id });

const PromotionDataAddressForm = () => {
    const [t] = useTranslation('promotion');
    const form = useFormContext<PromotionDataFormSchema>();
    const [, , { api }] = useLocationStore();

    const [loadTownsOptions] = useAsyncOptions({
        request: api.getTowns,
        adapter: townOptionAdapter
    });

    return (
        <div className="PromotionDataForm__row">
            <InputFormControl
                name="address"
                control={form.control}
                label={t('address')}
                className="PromotionDataForm__control --full --large"
            />
            <SelectAutocompleteFormControl
                name="town"
                control={form.control}
                label={t('town')}
                valueAdapter={townOptionAdapter}
                onChangeAdapter={(v) => {
                    if (Array.isArray(v) || !v) return undefined;
                    return { id: v.value, name: v.label };
                }}
                loadOptions={loadTownsOptions}
                className="PromotionDataForm__control --medium-large"
            />
            <InputFormControl
                name="postalCode"
                control={form.control}
                label={t('postalCode')}
                className="PromotionDataForm__control --small"
            />
        </div>
    );
};

export default PromotionDataAddressForm;
