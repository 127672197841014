import BasicButton from '../Base/BasicButton';
import '../Buttons.scss';

const SmallButton = ({
    className = '',
    titulo = 'Mostrar más',
    type = 'button',
    color = 'white',
    icon = <></>,
    iconPosition = 'left',
    disabled = false,
    action = () => {},
    to = null,
    state = null,
    testid = null,
    isLoading = false
}) => {
    return (
        <BasicButton
            testid={testid}
            type={type}
            text={titulo}
            color={color}
            action={action}
            to={to}
            state={state}
            disabled={disabled}
            icon={icon}
            iconPosition={iconPosition}
            extraClasses={className + ' button__small'}
            isLoading={isLoading}
        />
    );
};
export default SmallButton;
