import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

import { TraceabilitiesStore } from '../../domain/TraceabilitiesStore';

export const downloadTraceabilities =
    (): Thunk<TraceabilitiesStore> =>
    async (store, { api, logger }) => {
        try {
            store.dispatch('downloadPending', {});
            const criteria = store.state.criteria || {};
            await api.downloadTraceabilities(criteria);

            store.dispatch('downloadFulfilled', {});
        } catch (e) {
            const error = e as Error;

            logger.send(error);
            store.dispatch('downloadRejected', { error });
        }
    };

export const downloadTraceabilitiesReducers: Pick<
    Reducers<TraceabilitiesStore>,
    'downloadPending' | 'downloadFulfilled' | 'downloadRejected'
> = {
    downloadPending: (state) =>
        produce(state, (draft) => {
            draft.loading.download = 'pending';
        }),
    downloadFulfilled: (state) =>
        produce(state, (draft) => {
            draft.loading.download = 'succeeded';
        }),
    downloadRejected: (state, payload) =>
        produce(state, (draft) => {
            draft.loading.download = 'failed';
            draft.error = payload.error;
        })
};
