import { useTranslation } from 'react-i18next';

import KPIThermometer from 'features/KPIs/components/KPIThermometer';
import KPITile from 'features/KPIs/components/KPITile';
import { Widget } from 'features/KPIs/types/Widget';
import { KPIStatus, PromotionKPIs, useTraceabilityUpdateSummary } from 'modules/promotion-kpis';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import KPIAlarm from 'features/KPIs/components/KPIAlarm';

const key = PromotionKPIs.traceabilityUpdate;

const useDaysFromLastTraceabilities: Widget = (props) => {
    const { promotionProgress } = props;
    const [t] = useTranslation('kpis');
    const { data, error } = useTraceabilityUpdateSummary(props);

    const value = data?.daysPassed;
    const status = data?.status ? KPIStatus[data?.status] : KPIStatus.NOT_APPLICABLE;

    return {
        status: status,
        Tile: () => {
            return (
                <KPITile
                    label={t(`${key}.label`)}
                    unit={t(`${key}.unit`)}
                    value={(value || 0) > 9999 ? '9999+' : value}
                    hasError={!!error}
                    status={status}
                >
                    <KPIThermometer
                        maxValue={100}
                        value={value}
                        status={status}
                        thresholds={
                            (status === KPIStatus.NOT_APPLICABLE && []) ||
                            (promotionProgress >= 100 && []) ||
                            (promotionProgress >= 75 && [
                                // { value: 30, type: 'MAX' },
                                { value: 60, type: 'MAX' }
                            ]) ||
                            (promotionProgress >= 20 && [
                                { value: 90, type: 'MAX' },
                                { value: 60, type: 'MAX' }
                            ]) ||
                            []
                        }
                    />
                </KPITile>
            );
        },
        Detail: () => {
            return (
                <>
                    <ErrorCard error={error} />
                    {!error && (
                        <KPIAlarm
                            status={status}
                            title={t(`${key}.alarm.${status}.title`, { count: value })}
                            text={t(`${key}.alarm.${status}.subtitle`, { count: value })}
                            link={{
                                text: t(`${key}.alarm.${status}.link`, { defaultValue: null }),
                                to: `/promotion/${props.promotionId}/traceabilities`
                            }}
                        />
                    )}
                </>
            );
        }
    };
};

export default useDaysFromLastTraceabilities;
