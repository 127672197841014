import { IconPlus } from '@tabler/icons';
import BasicButton from './Base/BasicButton';
import './Buttons.scss';

const CreateButton = ({ type = 'link', text = 'Create', disabled = false, to = '', color = 'purple', ...props }) => {
    const defaultIcon = <IconPlus stroke={2.1} size={20} color="white" />;
    return (
        <BasicButton
            color={color}
            type={type}
            text={text}
            action={props.action}
            to={to}
            state={props.state}
            disabled={disabled}
            icon={props.icon === undefined ? defaultIcon : props.icon}
            extraClasses={props.extraClasses}
            {...props}
        />
    );
};
export default CreateButton;
