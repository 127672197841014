import { useTranslation } from 'react-i18next';

import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import { SelloValorizaBilleable, usePromotionBilleables } from 'modules/billeables';

import { useSelloValorizaBilleableForm } from './hooks/useSelloValorizaBilleableForm';
import './SelloValorizaBilleableForm.scss';

const SelloValorizaBilleableForm = () => {
    const [t] = useTranslation();

    const [{ entities }] = usePromotionBilleables();
    const selloValoriza = entities.byId['SELLO_VALORIZA'] as SelloValorizaBilleable;

    const form = useSelloValorizaBilleableForm({ selloValoriza });

    const options = selloValoriza.options.addon.filter((option) => option.label !== 'NO_BADGE');

    return (
        <div className="SelloValorizaBilleableForm">
            {/* TODO: extract this css to a global part */}
            <div className="SelloValorizaBilleableForm__type lerTypes__options">
                {options.map((option) => (
                    <div key={option.value} className="lerTypes__option">
                        <input
                            id={option.value.toString()}
                            type="radio"
                            defaultChecked={form.values.addonId === option.value.toString()}
                            name="addonId"
                            onChange={({ target }) => form.update(target.name, target.id)}
                        />
                        <label htmlFor={option.value.toString()}>
                            {t(`productsAndServices.addons.${option.label}.name`, { ns: 'newPromotion' })}
                        </label>
                    </div>
                ))}
            </div>

            <InputNumber
                onChange={({ target }) => form.update(target.name, parseInt(target.value))}
                name="score"
                id="score"
                value={form.values.score !== null ? form.values.score : ''}
                label={t('labels.puntuationValorizaBadge', { ns: 'newPromotion' })}
                formatNumber={false}
                required={true}
                htmlRequired={true}
                min={selloValoriza.validations.score?.min}
                max={selloValoriza.validations.score?.max}
                // Just to ignore optional fields
                {...({} as any)}
            />

            <InputNumber
                onChange={({ target }) => form.update(target.name, parseInt(target.value))}
                name="valorizationTarget"
                id="valorizationTarget"
                value={form.values.valorizationTarget !== null ? form.values.valorizationTarget : ''}
                label={t('labels.valorizationTargetSelloValoriza', { ns: 'newPromotion' })}
                formatNumber={false}
                required={true}
                htmlRequired={true}
                min={selloValoriza.validations.valorizationTarget?.min}
                max={selloValoriza.validations.valorizationTarget?.max}
                // Just to ignore optional fields
                {...({} as any)}
            />
        </div>
    );
};

export default SelloValorizaBilleableForm;
