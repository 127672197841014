import { IconSearch } from '@tabler/icons';
import './PromotionSearchBar.scss';
import { debounce } from 'utils/helpers/async.helpers';
import { useEffect, useState } from 'react';
import { usePromotion } from 'modules/promotion';
import SpinnerTiny from 'components/BasicComponents/SpinnerTiny';

interface PromotionSearchBarProps {
    searchText: string;
    handleSearchChange: (str: string) => any;
}

const PromotionSearchBar = ({ searchText, handleSearchChange }: PromotionSearchBarProps) => {
    const [search, setSearch] = useState(searchText);
    const [dirty, setDirty] = useState(false);
    const [{ loadingPromotionPreviews }] = usePromotion();

    const changeValue = async (event) => {
        setSearch(event.target.value || '');
        setDirty(true);
    };

    const triggerSearchWithDebounce = async () => {
        await debounce(handleSearchChange, 500)(search.trim());
    };

    useEffect(() => {
        triggerSearchWithDebounce();
    }, [search]);

    const showSpinner = dirty && loadingPromotionPreviews === 'pending';

    return (
        <div className="PromotionSearchBar">
            <IconSearch className="PromotionSearchBar__search" />
            <input type="text" placeholder="Buscar por nombre, etiqueta..." value={search} onChange={changeValue} />
            {showSpinner && <SpinnerTiny className="PromotionSearchBar__spinner" />}
        </div>
    );
};

export default PromotionSearchBar;
