import { DomainException } from 'modules/shared';

export class InvalidCriteriaException extends DomainException {
    constructor() {
        super({
            code: 'invalid-criteria',
            description: 'Invalid criteria for massive action. You must provide either promotionId or ids.',
            content: null
        });
    }
}
