import { useTranslation } from 'react-i18next';

import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import { KPIGoalValueProps } from './KPIGoalValueProps';

export const SimpleKPIGoalValue = (props: KPIGoalValueProps) => {
    const { value, update, kpi } = props;

    const [t] = useTranslation('KPIsGoalSettings');

    return (
        <InputNumber
            name="value"
            extraElement={
                <>
                    <span className="KPIGoal__input-unit">%</span>
                </>
            }
            placeholderText={t(`kpi.${kpi}.value`)}
            value={value?.value || ''}
            onChange={({ target }) =>
                update({
                    value: {
                        type: null,
                        unit: null,
                        value: parseFloat(target.value)
                    }
                })
            }
            {...({} as any)} // Just to ignore optional fields
        />
    );
};
