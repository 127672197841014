import { OnboardingApi } from 'modules/onboarding/domain/OnboardingApi';

const key = 'Onboarding--resources';

export const localstorageOnboardingApi: OnboardingApi = {
    getResourcesState: async () => {
        const resources = JSON.parse(localStorage.getItem(key) || '{}');
        return resources;
    },
    updateResourceState: async (resource) => {
        const resources = JSON.parse(localStorage.getItem(key) || '{}');
        resources[resource.id] = resource;

        localStorage.setItem(key, JSON.stringify(resources));
    }
};
