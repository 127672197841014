import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { differenceArrays, removeDuplicates } from 'utils/helpers/general.helpers';
import { notify } from 'utils/helpers/notifications';
import { SelectAsyncOptions, SelectOption } from '../SelectFilter';

export function useAsyncOptions<
    Entity extends any,
    Request extends (params: { search?: string; page?: number }) => Promise<Entity[]>
>(props: {
    request: Request;
    removePagination?: boolean;
    adapter: (entity: Awaited<ReturnType<Request>>[0], search?: string) => SelectOption;
}) {
    const [t] = useTranslation();

    const loadOptions: SelectAsyncOptions = useCallback(
        async (search, loadedOptions, additional) => {
            try {
                const results = await props.request({ search, page: additional?.page });
                const newOptions = results.map((result) => props.adapter(result, search));

                const optionsWithoutDuplicates = removeDuplicates(newOptions, 'value');
                const optionsOnlyNew = differenceArrays(optionsWithoutDuplicates, loadedOptions, 'value');

                return {
                    options: optionsOnlyNew,
                    hasMore: props.removePagination ? false : results.length > 0,
                    additional: { page: (additional?.page || 0) + 1 }
                };
            } catch (e) {
                const error = e as AxiosError;
                console.error(error);
                if (error.response?.status === 403) {
                    notify({ msg: t('forbidden.title'), isSuccess: false });
                } else {
                    notify({ msg: error.message, isSuccess: false });
                }
                return {
                    options: [],
                    hasMore: false,
                    additional: { page: 0 }
                };
            }
        },
        [props.request]
    );

    return [loadOptions] as [typeof loadOptions];
}
