import { Cache } from 'lib/cache';
import fetcher from 'lib/fetcher';
import { Comparative } from '../../domain/Comparative';
import { ComparativeApi } from '../../domain/ComparativeApi';

export class HttpComparativeApi implements ComparativeApi {
    constructor(private cache: Cache<Comparative>) {}

    async findAll() {
        async function getComparativeData(): Promise<Comparative> {
            const { data } = await fetcher.get('/api/comparative');
            return data;
        }

        const comparative = await this.cache.cacheQuery(getComparativeData);
        return comparative?.data;
    }

    async updateObservations({ promotionId, text }) {
        const request = await fetcher.put(`/api/comparative/${promotionId}/observations`, { text });

        return { success: request.status === 204 };
    }

    async PROVISIONAL_getProtocolAndExecutionByPromoId(promotionId: string | number) {
        const request = async () => fetcher(`/api/comparative`).then((r) => r.data);
        const response = await (this.cache ? this.cache.cacheQuery(request) : request());
        const comparative: Comparative = response.data;

        const promotion = comparative.find((row) => row.promotion.id.toString() === promotionId.toString());
        if (!promotion) throw new Error(`Promotion ${promotionId} not found in comparative`);

        const { protocol, execution } = promotion;
        return { protocol, execution };
    }
}
