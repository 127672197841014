import { FAQ_SECTIONS } from 'features/faq/constans/faqSections';
import { ONBOARDING_RESOURCES as videos, OnboardingResource } from 'modules/onboarding';

const faqs = FAQ_SECTIONS.reduce((acc, section) => {
    section.guides.forEach((guide) => {
        acc[guide.title] = { id: guide.id, title: guide.title };
    });
    return acc;
}, {} as { [title: string]: { id: string; title: string } });

export const SUPPORT_CONFIG: {
    [path: string]: {
        faqs: Array<typeof faqs[0]>;
        videos: Array<OnboardingResource>;
    };
} = {
    '/promotion/:promotionId/impact': {
        faqs: [faqs['¿Qué es el "Impacto"?']],
        videos: [videos.intro]
    },
    '/promotion/:promotionId/settings': {
        faqs: [faqs['¿Cómo gestiono mis usuarios?']],
        videos: [videos.intro, videos.wasteFlows]
    },
    '/promotion/:promotionId/traceabilities': {
        faqs: [],
        videos: [videos.traceability, videos.traceabilityValidation, videos.wasteFlows, videos.wasteRegister]
    },
    '/promotion/:promotionId/activities': {
        faqs: [faqs['Visita a obra: guía y actas']],
        videos: [videos.intro, videos.project]
    },
    '/documents': {
        faqs: [],
        videos: [videos.intro, videos.documentation, videos.wasteFlows]
    },
    '/promotion/:promotionId': {
        faqs: [faqs['¿Cómo gestiono mis usuarios?']],
        videos: [videos.intro, videos.project]
    },
    '/': {
        faqs: [],
        videos: [videos.intro, videos.project]
    }
};
