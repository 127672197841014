import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TooltipContent, {
    TooltipContentDate,
    TooltipContentLabel,
    TooltipContentValue
} from 'components/BasicComponents/Tooltips/TooltipContent';
import KPIBarChart from 'features/KPIs/components/KPIBarChart';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { wasteManagementApi } from 'modules/waste-management';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import './WasteManagementChart.scss';
import { mandatoryFlowsToChartAdapter } from './adapters/mandatoryFlowsToChartAdapter';
import FlowHistoryChart from './components/FlowHistoryChart';

export interface WasteManagementChartProps {
    mode: 'EGR' | 'PGR' | number;
    promotionId: string;
    onBarClick?: (lerTypeId: number | null) => void;
}

// un componente llamado WasteManagementChart con el código JSX entre las líneas 86 y 144
const WasteManagementChart = (props: WasteManagementChartProps) => {
    const { mode, promotionId } = props;
    const [, analytics] = useAnalyticsStore();
    const [t] = useTranslation('kpis');

    const [lands, setLands] = useState<boolean>(false);
    const { data } = wasteManagementApi.useMetrics({ promotionId, lands });
    const mandatoryFlows = data?.data || [];

    const toggleDetail = (value: any) => {
        const flow = mandatoryFlows?.find((flow) => flow.lerType.name === value);
        if (!flow) return;
        props.onBarClick?.(flow.lerType.id);
        analytics.event(AnalyticsEvents.KPIS_MANDATORYFLOWS_DETAIL_BAR_CLICKED);
    };

    return (
        <>
            {(mode === 'EGR' || mode === 'PGR') && (
                <KPIBarChart
                    indexBy="name"
                    onClick={(data) => toggleDetail(data.indexValue)}
                    legend={[
                        {
                            label: `${t('mandatory-flows.unit')} ${t('mandatory-flows.managed')}`,
                            icon: (
                                <svg width="24" height="24">
                                    <rect width="24" height="24" fill="rgb(200, 200, 200)"></rect>
                                </svg>
                            )
                        },
                        {
                            label: `${t('mandatory-flows.unit')} ${t('mandatory-flows.estimated')} ${mode}`,
                            icon: (
                                <svg width="24" height="24">
                                    <rect width="24" height="24" fill="url(#lines-filled)"></rect>
                                </svg>
                            )
                        }
                    ]}
                    data={mandatoryFlows.length > 0 ? mandatoryFlowsToChartAdapter(mandatoryFlows, mode) : undefined}
                    fill={[
                        { match: { id: 'filled' }, id: 'lines' },
                        { match: { id: 'deficit' }, id: 'lines-filled' }
                    ]}
                    axisBottom={{
                        renderTick: ({ opacity, textAnchor, value, x, y }) => {
                            const segregated = mandatoryFlows.find((flow) => flow.lerType.name === value)?.segregated;

                            const count = 25 - mandatoryFlows.length;
                            const valueSliced = value?.slice(0, count);
                            const name = valueSliced?.length < value?.length ? `${valueSliced}.` : value;

                            return (
                                <g transform={`translate(${x},${y})`} style={{ opacity }}>
                                    <text
                                        className="KPIBarChart__label"
                                        transform="translate(0,15)"
                                        fontFamily="Be Vietnam Pro"
                                        fill={segregated ? '#273749' : 'red'}
                                        fontWeight={segregated ? 'normal' : 'bold'}
                                        textAnchor={textAnchor}
                                        fontSize="10px"
                                        onClick={() => toggleDetail(value)}
                                    >
                                        {segregated === false ? '⚠' : ''} {name}
                                    </text>
                                </g>
                            );
                        }
                    }}
                    tooltip={(params) => {
                        const overflow = (params.data as any).overflow || 0;
                        const filled = (params.data as any).filled || 0;

                        const deficit = (params.data as any).deficit || 0;
                        const real = filled + overflow;
                        const estimated = filled + deficit;

                        return (
                            <TooltipContent>
                                <TooltipContentLabel>{params.indexValue}</TooltipContentLabel>
                                <TooltipContentValue>
                                    {currencyFormatDE(real)}
                                    {t('mandatory-flows.unit')}
                                </TooltipContentValue>
                                <TooltipContentDate>
                                    {currencyFormatDE(estimated)}
                                    {t('mandatory-flows.unit')} {t('mandatory-flows.estimated')} {mode}
                                </TooltipContentDate>
                            </TooltipContent>
                        );
                    }}
                />
            )}

            {typeof mode === 'number' && (
                <FlowHistoryChart
                    lerTypeId={mode as number}
                    promotionId={promotionId}
                    mandatoryFlows={mandatoryFlows || []}
                />
            )}

            <div className="WasteManagementChart__checks">
                <label>
                    <input
                        type="checkbox"
                        checked={lands}
                        onChange={({ target }) => {
                            setLands((target as HTMLInputElement).checked);
                        }}
                    />
                    <span>{t('mandatory-flows.showLands')}</span>
                </label>
            </div>
        </>
    );
};

export default WasteManagementChart;
