import { DiExtractionStore } from 'modules/di-extraction/domain/DiExtractionStore';
import { DiExtrationStatus } from 'modules/di-extraction/domain/IDiExtraction';
import { Thunk } from 'modules/shared/domain/store/createStore';
import { startUpdates, fulfillUpdates } from './update.utils';

/** @deprecated in favor of rejectManyDiExtractions */
export const rejectDiExtraction =
    (extractionId: string): Thunk<DiExtractionStore> =>
    async (store, { api }) => {
        try {
            store.set(startUpdates([extractionId]));

            await api.rejectDiExtraction(extractionId);

            store.set(fulfillUpdates({ [extractionId]: { state: DiExtrationStatus.REJECTED } }));
        } catch (e) {
            store.set((draft) => {
                draft.updating = draft.updating.filter((id) => id !== extractionId);
                draft.error = e as Error;
            });
        }
    };
