import { actions } from './actions';
import { loadingState } from './state';

export const reducers = {
    [actions.pending]: (state) => {
        return {
            loading: loadingState.IDLE,
            ...state
        };
    },
    [actions.fulfilled]: (state, payload) => {
        return {
            ...state,
            loading: loadingState.SUCCEEDED,
            documentTypesBySection: payload.documentTypesBySection
        };
    },
    [actions.rejected]: (state, payload) => {
        return {
            ...state,
            loading: loadingState.FAILED,
            error: payload.message
        };
    }
};
