import { useState, useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../../context/AuthProvider';
import { customStyles } from '../../../BasicComponents/Select-Autocomplete/helpers';

const LanguageSelect = ({ required, values, setValues, validacion }) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState(false);
    const [optionLanguage, setOption] = useState();
    const { langKey } = values;

    const hasMore = (options) => {
        return options.length === 20 ? true : false;
    };

    const options = [
        {
            language: 'English',
            langKey: 'en'
        },
        {
            language: 'Spanish',
            langKey: 'es'
        }
    ];

    const loadOptions = async (search, loadedOptions, { page }) => {
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const emptyInput = () => {
        if (validacion === true && required && langKey === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    useEffect(() => {
        emptyInput();
    }, [validacion]);

    useEffect(() => {
        getOption();
    }, [langKey]);

    const selectOption = (item) => {
        setValues({
            ...values,
            langKey: item.langKey
        });
        setOption(item);
    };

    const getOption = () => {
        setOption(options.find((option) => option.langKey === langKey));
    };

    return (
        <div className="input__container">
            <label className="input__label">
                {t('lang', { ns: 'users' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={loadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.language}
                getOptionValue={(option) => option}
                placeholder=""
                value={optionLanguage}
                additional={{
                    page: 0
                }}
                styles={customStyles(notComplete)}
            />
        </div>
    );
};
export default withAuth(LanguageSelect);
