import { useQuery } from 'hooks/useQuery';
import { useAuth } from 'modules/auth';
import { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export function useChangePasswordForm() {
    const query = useQuery();
    const route = useParams<{ key: string }>();
    const [{ error }, { changePassword, login }] = useAuth();
    const [dirty, setDirty] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    const send = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);

        const changePasswordResult = await changePassword({ key: route.key, newPassword: password, repeatPassword });

        const canLogin = changePasswordResult.success && query.params.email;
        if (canLogin) await login({ username: query.params.email, password });

        setIsLoading(false);
        setDirty(false);
    };

    useEffect(() => {
        setDirty(true);
    }, [password, repeatPassword]);

    const errorMsg = (error as Error)?.message;

    return { errorMsg, isLoading, dirty, repeatPassword, password, setPassword, setRepeatPassword, send };
}
