export const createWastePoster = (lerType) => {
    const isDangerous = lerType.dangerous;
    const isRsu = lerType.rsu;
    const isRestrictedUsage = lerType.lerKind === 'Mezclas';

    return {
        id: lerType.id,
        title: lerType.lerKind,
        isDangerous,
        isRestrictedUsage,
        isRsu,
        lers: lerType.codLers.map((codLer) => codLer.codigoLER).sort(),
        order: (isDangerous && 1) || (isRsu && 2) || 0
    };
};
