import axios from 'axios';
import { dispatchLogout } from 'lib/LogoutHandler';
import { AuthApi } from 'modules/auth/domain/AuthApi';
import { AUTH_TOKEN_KEY } from 'modules/auth/domain/AuthConfig';
import { AuthAdapter } from './AuthAdapter';

// Requires its own fetcher in order to avoid circular dependencies with the AuthProvider through the toasthandler.
const fetcher = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const httpAuthApi: AuthApi = {
    async authenticate(params) {
        const { headers } = await fetcher.post('/api/authenticate', {
            ...params,
            rememberMe: false
        });

        const bearerToken = headers.authorization;
        const isBearer = bearerToken.slice(0, 7) === 'Bearer ';
        if (!isBearer) throw new Error('httpAuthApi: not a bearer token');

        const accessToken = bearerToken.slice(7, bearerToken.length);
        if (!accessToken) throw new Error('httpAuthApi: not an access token');

        return { accessToken };
    },
    async getAuth() {
        const token = localStorage.getItem(AUTH_TOKEN_KEY);
        if (!token) throw new Error('No token provided');

        const res = await fetcher.get('/api/account', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY)}`
            }
        });

        if (res.status === 401) {
            dispatchLogout();
            throw new Error('Invalid token');
        }

        const auth = await AuthAdapter.adaptFromAccount(res.data.content);

        return auth;
    },
    async requestPassReset({ email }) {
        return fetcher.post(`/api/account/reset-password/init`, email, {
            headers: { ['Content-Type']: 'text/plain' }
        });
    },
    async checkKeyValidity(params) {
        const { data } = await fetcher.get(`/api/account/check-reset-token/${params.key}`);
        return data;
    },
    async changePassword(params) {
        return fetcher.post(`/api/account/reset-password/finish`, params);
    }
};
