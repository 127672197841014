import { AuthStore } from 'modules/auth/domain/AuthStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Store = AuthStore;

export const loadAuth =
    (): Thunk<Store> =>
    async (store, { api }) => {
        try {
            store.set((draft) => {
                draft.isLoading = true;
            });
            const auth = await api.getAuth();
            store.set(auth);
        } catch (e) {
            store.set((draft) => {
                draft.isLoggedin = false;
                draft.isLoading = false;
            });
        }
    };
