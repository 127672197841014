import fetcher from '../../../lib/fetcher';

const message = {
    success: {
        text: 'downloadRequest.delete.success',
        ns: 'documentation'
    },
    error: {
        text: 'downloadRequest.delete.error',
        ns: 'documentation'
    }
};

export const deleteDownload = async (id) => {
    const URL = `/api/tracing/documents/compression/${id}`;
    try {
        const response = await fetcher.delete(URL, { message });
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
