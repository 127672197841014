import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconDots, IconEdit, IconFileUpload, IconTrash } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import IconButton from '../../../components/BasicComponents/Buttons/Small/IconButton';
import HasPermission from '../../../components/AccessCheckers/HasPermission';
import { openFileByURL } from '../../../utils/helpers/files.helpers';
import ActionMenuDocumentShow from './ActionMenuDocumentShow';
import './ActionMenuDocument.scss';

const ActionMenuDocument = ({
    hasDocument,
    setOpenMenu,
    openMenu,
    file,
    setOpenModalDelete,
    openModalDelete,
    customActions,
    onlyShowAction
}) => {
    const [t] = useTranslation();

    const listWithDocument = () => {
        return (
            <ul className="menu__dropdown">
                {customActions || (
                    <>
                        <ActionMenuDocumentShow onClick={() => openFileByURL(file.url)} />
                        <HasPermission ifRole="canCreate" section="documentation">
                            {!onlyShowAction && (
                                <>
                                    <Link
                                        to={{
                                            pathname: `/documents/${file.id}/edit`,
                                            state: {
                                                documento: file
                                            }
                                        }}
                                    >
                                        <li>
                                            <IconEdit stroke={1.25} size={20} />
                                            {t('edit', { ns: 'common' })}
                                        </li>
                                    </Link>
                                    <li onClick={() => setOpenModalDelete(!openModalDelete)}>
                                        <IconTrash stroke={1.25} size={20} />
                                        Eliminar
                                    </li>
                                </>
                            )}
                        </HasPermission>
                    </>
                )}
            </ul>
        );
    };

    const listWithoutDocument = () => {
        return (
            <ul className="menu__dropdown">
                <li>
                    <IconFileUpload stroke={1} size={20} />
                    {t('upload.upperCase', { ns: 'common' })}
                </li>
            </ul>
        );
    };

    return (
        <div className="menu">
            <IconButton icon={<IconDots />} action={() => setOpenMenu(!openMenu)} ariaLabel="menu" />
            {openMenu && hasDocument && listWithDocument()}
            {openMenu && !hasDocument && listWithoutDocument()}
        </div>
    );
};

export default withAuth(ActionMenuDocument);
