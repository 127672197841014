import { StakeholderInfo } from '../../../domain/Traceability/Traceability';

export const carrierAdapter = (ppgcl): StakeholderInfo => ({
    id: ppgcl.carrierProGesCodLer?.gestora?.id?.toString(),
    name: ppgcl.carrierProGesCodLer?.gestora?.empresa,
    nif: ppgcl.carrierProGesCodLer?.gestora?.identificacionFiscal,
    email: ppgcl.carrierProGesCodLer?.gestora?.mail,
    nima: ppgcl.carrierProGesCodLer?.gestora?.nIMA,
    phone: ppgcl.carrierProGesCodLer?.gestora?.telefono,
    addresses:
        ppgcl.carrierProGesCodLer?.gestora?.direccions?.map((dir) => ({
            address: dir.direccion,
            postalCode: dir.postalCode,
            city: {
                code: dir.municipio.codigo,
                name: dir.municipio.nombre
            },
            province: {
                code: dir.municipio.provincia.codigo,
                name: dir.municipio.provincia.nombre
            }
        })) || []
});
