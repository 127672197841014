import { DiExtractionCriteria } from '../DiExtractionCriteria';
import { InvalidCriteriaException } from '../exceptions/InvalidCriteriaException';

export const validateMassiveActionCriteria = (criteria?: DiExtractionCriteria) => {
    const { promotionId, ids } = criteria || {};
    const invalidPromotionId = !promotionId || isNaN(promotionId);
    const invalidIds = !ids || !ids.length;

    if (invalidPromotionId && invalidIds) throw new InvalidCriteriaException();
};
