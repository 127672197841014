import { CantUploadFilesException } from 'modules/di-extraction/domain/DiExtractionErrors';
import { DiExtractionStore } from 'modules/di-extraction/domain/DiExtractionStore';
import { IDiExtractionApi } from 'modules/di-extraction/domain/IDiExtractionApi';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

type createDiExtractionsProps = Parameters<IDiExtractionApi['createDiExtractions']>;

export const createDiExtractions =
    (...params: createDiExtractionsProps): Thunk<DiExtractionStore, { succeeded: boolean }> =>
    async (store, { api }) => {
        const [promotionId, newDiExtractions, extra] = params;

        try {
            await api.createDiExtractions(promotionId, newDiExtractions, extra);

            store.dispatch('createExtractionsFulfilled', {});
            return { succeeded: true };
        } catch (e) {
            throw new CantUploadFilesException();
        }
    };

export const createReducers: Pick<
    Reducers<DiExtractionStore>,
    'createExtractionsPending' | 'createExtractionsFulfilled'
> = {
    createExtractionsPending(state, payload) {
        return {
            ...state,
            loading: 'creating'
        };
    },
    createExtractionsFulfilled(state, payload) {
        return {
            ...state,
            loading: 'created'
        };
    }
};
