import { useContext } from 'react';
import uuid from 'react-uuid';
import SuggestionCard from '../../../components/BasicComponents/Cards/SuggestionCard';
import { withAuth } from '../../../context/AuthProvider';
import CodLerWasteManager from './CodLerWasteManager';
import { NewPpgclContext } from './NewPpgclStepper';
import './CodLerWasteManager.scss';

const LersStep = () => {
    const { wasteManagersChecked, newPpgcls, setNewPpgcls } = useContext(NewPpgclContext);

    const checkboxHandleClick = (ppgcl) => {
        if (newPpgcls.some((newPpgcl) => newPpgcl.wasteManagerProGesCodLerId === ppgcl.id)) {
            setNewPpgcls(newPpgcls.filter((newPpgcl) => newPpgcl.wasteManagerProGesCodLerId !== ppgcl.id));
        } else {
            setNewPpgcls([...newPpgcls, { id: uuid(), ppgcl, wasteManagerProGesCodLerId: ppgcl.id }]);
        }
    };

    return (
        <>
            <SuggestionCard>
                Selecciona los códigos LER y valorización que aplican las gestoras seleccionadas en el paso anterior.
            </SuggestionCard>
            {wasteManagersChecked.length === 0 && (
                <p className="verticalStepper__message">
                    Para poder seleccionar los residuos primero configura las gestoras en el paso anterior
                </p>
            )}
            {wasteManagersChecked.length > 0 && (
                <ul className="codlerWasteManager__ul">
                    {wasteManagersChecked.map((wasteManager) => (
                        <CodLerWasteManager
                            key={wasteManager.id}
                            {...{ checkboxHandleClick, newPpgcls, setNewPpgcls, wasteManager }}
                        />
                    ))}
                </ul>
            )}
        </>
    );
};
export default withAuth(LersStep);
