import { useEffect } from 'react';
import { useAuth } from 'modules/auth';

/** In order to ensure that the first route is saved, this part remains outside the hook because inside the "useEffect" the route has already changed.. */
let deepLinkUrl = null as null | string;

/** Loads deepLink to storage in order to keep it until login completed */
export const useLoadDeepLink = () => {
    const [, , { deepLinkStorage }] = useAuth();
    if (!deepLinkUrl) {
        deepLinkUrl = window.location.pathname + window.location.search;
    }

    const [{ isLoggedin }] = useAuth();

    useEffect(() => {
        if (isLoggedin || deepLinkStorage.hasDeepLink()) {
            deepLinkStorage.removeDeepLink();
            return;
        }

        if (!deepLinkUrl) return;
        deepLinkStorage.saveDeepLink(deepLinkUrl);
    }, [isLoggedin]);
};
