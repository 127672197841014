import KPIHistoricalChart from 'features/KPIs/components/KPIHistoricalChart';
import { KPIHistoricalChartStyles } from 'features/KPIs/components/KPIHistoricalChart/KPIHistoricalChart.styles';
import { useTranslation } from 'react-i18next';
import './FlowHistoryChart.scss';
import { wasteManagementApi } from 'modules/waste-management';
import { WasteManagementMetrics } from 'modules/waste-management';

interface FlowHistoryChartProps {
    mandatoryFlows: WasteManagementMetrics;
    promotionId: string;
    lerTypeId: number;
}

const FlowHistoryChart = (props: FlowHistoryChartProps) => {
    const { lerTypeId, promotionId, mandatoryFlows } = props;

    const [t] = useTranslation('kpis');
    const { data } = wasteManagementApi.useFlowHistory({ lerTypeId, promotionId: promotionId });

    const mandatoryFlow = mandatoryFlows?.find((flow) => flow.lerType.id === lerTypeId);
    const pgr = mandatoryFlow?.pgrTons;
    const egr = mandatoryFlow?.egrTons;

    return (
        <div className="FlowHistoryChart MandatoryFlowsWidget__chart">
            <section>
                <KPIHistoricalChart
                    label={t('mandatory-flows.label')}
                    data={data?.data}
                    unit={t('mandatory-flows.unit')}
                    addtionalMarkers={[
                        ...(egr !== undefined
                            ? [
                                  KPIHistoricalChartStyles.createMarker({
                                      axis: 'y',
                                      lineStyle: { stroke: '#344960', strokeWidth: 2 },
                                      value: egr,
                                      legendPosition: egr === pgr ? 'bottom-right' : 'right',
                                      legend: egr === pgr ? 'EGR/PGR' : 'EGR'
                                  })
                              ]
                            : []),
                        ...(pgr !== undefined && pgr !== egr
                            ? [
                                  KPIHistoricalChartStyles.createMarker({
                                      axis: 'y',
                                      lineStyle: { stroke: '#344960', strokeWidth: 2 },
                                      value: pgr,
                                      legendPosition: 'right',
                                      legend: 'PGR'
                                  })
                              ]
                            : [])
                    ]}
                />
            </section>
        </div>
    );
};

export default FlowHistoryChart;
