import fetcher from '../../../lib/fetcher';

const _userMessage = {
    success: {
        text: 'success.edit',
        ns: 'documentation'
    },
    error: {
        text: 'document.edit',
        ns: 'errors'
    }
};

export const patchDocument = async (document) => {
    const URL = '/api/tracing/documents';
    try {
        const response = await fetcher.patch(URL, document, { message: _userMessage });
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
