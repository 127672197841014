import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';

import enterpriseServices from '../../../../../../api/enterprise/enterprise.services';
import { withAuth } from '../../../../../../context/AuthProvider';
import { customStyles } from '../../../../../BasicComponents/Select-Autocomplete/helpers';

const EnterpriseAutocomplete = ({ values, disabled = false, setValues, propName, tooltip = null, ...props }) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState(false);

    const loadOptionsEnterprise = async (search, loadedOptions, { page }) => {
        let options;
        let filter = `?page=${page}`;
        if (!search) {
            options = await enterpriseServices.filterEnterprise(filter);
        } else {
            options = await enterpriseServices.filterEnterpriseName(search);
        }
        return options;
    };

    const hasMore = (options) => {
        return options.length === 20 ? true : false;
    };

    const loadOptions = async (search, loadedOptions, { page }) => {
        let opcionesEmpresa;
        opcionesEmpresa = await loadOptionsEnterprise(search, loadedOptions, { page });
        return {
            options: opcionesEmpresa,
            hasMore: hasMore(opcionesEmpresa),
            additional: {
                page: page + 1
            }
        };
    };
    const selectOptionEmpresa = (item) => {
        setValues({ ...values, [propName]: item });
    };

    const emptyInput = () => {
        const fieldEmpty = !values[propName];
        if (props.required && props.isFormValid === false && fieldEmpty) {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    const key = () => {
        return JSON.stringify(values);
    };

    useEffect(() => {
        emptyInput();
    }, [props.isFormValid, values]);

    const modifiers = [[disabled ? 'EnterpriseAutocomplete--disabled' : '']];

    return (
        <div className="EnterpriseAutocomplete__container">
            <div className={`EnterpriseAutocomplete ${modifiers.join(' ')} ${props.className} `}>
                <label className="EnterpriseAutocomplete__label input__label" id="enterpriseAutocomplete">
                    {t('enterprise', { ns: 'common' })} {props.required ? '*' : ''}
                </label>
                <AsyncPaginate
                    isDisabled={disabled}
                    key={key()}
                    id="enterpriseAutocompleteInput"
                    aria-labelledby="enterpriseAutocomplete"
                    loadOptions={loadOptions}
                    onChange={selectOptionEmpresa}
                    getOptionLabel={(option) => option.nombreComercial}
                    getOptionValue={(option) => option}
                    value={values[propName]}
                    placeholder=""
                    additional={{
                        page: 0
                    }}
                    styles={customStyles(notComplete)}
                />
            </div>
            {tooltip && <div className="EnterpriseAutocomplete__tooltip">{tooltip}</div>}
        </div>
    );
};
export default withAuth(EnterpriseAutocomplete);
