import { useTranslation } from 'react-i18next';

import KPIThermometer from 'features/KPIs/components/KPIThermometer';
import KPITile from 'features/KPIs/components/KPITile';
import { Widget } from 'features/KPIs/types/Widget';
import { KPIStatus, useValorizationSummary } from 'modules/promotion-kpis';
import ValorizationDetail from './components/ValorizationDetail';

const useValorizationWidget: Widget = (props) => {
    const [t] = useTranslation('kpis');
    const { data, error } = useValorizationSummary(props);

    const recoverable = data?.recoverable;
    const status = KPIStatus[recoverable?.status || 'INDETERMINATE'];

    return {
        status,
        Tile: () => {
            return (
                <KPITile
                    label={t('valorization.label')}
                    unit={t('valorization.unit')}
                    value={recoverable?.value}
                    hasError={!!error}
                    status={status}
                >
                    <KPIThermometer
                        maxValue={100}
                        value={recoverable?.value}
                        status={status}
                        thresholds={recoverable?.goals.map((goal) => ({
                            ...goal,
                            type: 'MIN' as const,
                            value: goal.value.value
                        }))}
                    />
                </KPITile>
            );
        },
        Detail: () => {
            return (
                <ValorizationDetail
                    promotionId={props.promotionId}
                    summary={data}
                    promotionProgress={props.promotionProgress}
                />
            );
        }
    };
};

export default useValorizationWidget;
