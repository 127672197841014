import { useRef, useState } from 'react';

import { TraceabilityFormRef } from 'features/traceabilities/components/TraceabilityForm/TraceabilityForm';
import { WasteRegisterFormProps } from '../WasteRegisterForm';

export function useTraceabilityForm(props: WasteRegisterFormProps) {
    const ref = useRef<TraceabilityFormRef>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const [show, setShow] = useState(false);

    const isEnabled = show || ref.current !== null;

    const save: TraceabilityFormRef['save'] = async (overrides) => {
        if (!ref.current) return { data: null, error: new Error(`Traceabilities form not enabled yet`) };

        const result = await ref.current?.save(overrides);
        return result;
    };

    const enable = (enabled: boolean) => {
        setShow(enabled);
        setTimeout(() => {
            headerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 0);
    };

    return {
        ref,
        headerRef,
        /** Whether the traceability form is enabled */
        isEnabled,
        enable,
        save
    };
}
