import fetcher from 'lib/fetcher';

export const modifyLinkedTreatmentContract = async ({
    projectWasteFlowId,
    contractId
}: {
    projectWasteFlowId: number;
    contractId: number;
}) => {
    const message = {
        success: 'Se ha asociado el contrato de tratamiento',
        error: 'Error al asociar el contrato'
    };

    const URL = `/api/project-waste-flow/${projectWasteFlowId}/treatment-contract`;
    try {
        const response = await fetcher.patch(URL, { contractId }, { message } as any);
        return response;
    } catch (error) {
        console.log(error);
    }
};
