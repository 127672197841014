import { useEffect } from 'react';

import TraceabilitiesListKPIs from 'features/traceabilities/components/TraceabilitiesList/components/TraceabilitiesListKPIs/TraceabilitiesListKPIs';
import { usePromotion } from 'modules/promotion';
import { useTraceabilities } from 'modules/traceabilities';
import './TraceabilitiesListKPIsPrintable.scss';

const TraceabilitiesListKPIsPrintable = () => {
    const [{ promotion }] = usePromotion();
    const [, { loadTraceabilities }] = useTraceabilities();

    useEffect(() => {
        loadTraceabilities({ promotionId: promotion?.id });
    }, []);

    return (
        <div className="TraceabilitiesListKPIsPrintable">
            <TraceabilitiesListKPIs hideCost />
        </div>
    );
};

export default TraceabilitiesListKPIsPrintable;
