import {
    IconAlertTriangle,
    IconDeviceAnalytics,
    IconFiles,
    IconListCheck,
    IconReport,
    IconTrendingUp,
    IconTruck,
    IconWriting
} from '@tabler/icons';

export const DEFAULT_ICON = <IconReport />;

export const ICONS_BY_ACTIVITY_TYPE = [
    // visita obra
    { typeId: 360203, icon: <IconListCheck /> },
    // analisis traz interno
    { typeId: 365251, icon: <IconTruck /> },
    // balance mensual
    { typeId: 385751, icon: <IconDeviceAnalytics /> },
    // incidencia
    { typeId: 389651, icon: <IconAlertTriangle /> },
    // documentacion mensual
    { typeId: 418401, icon: <IconFiles /> },
    // nota interna de visita
    { typeId: 436151, icon: <IconWriting /> },
    // reunion de seguimiento
    { typeId: 468051, icon: <IconTrendingUp /> }
];
