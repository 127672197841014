import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { useNotificationSettings } from 'modules/notification-settings/infrastructure/react/notificationSettingsContext';
import { usePromotion } from 'modules/promotion';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import StakeholderEmailRow from './StakeholderEmailRow';
import './WasteRegisterNotifications.scss';

const WasteRegisterNotifications = () => {
    const [t] = useTranslation('NotificationSettings');
    const [{ promotion }] = usePromotion();
    const [{ data }, { loadNotificationSettings }] = useNotificationSettings();

    const load = () => {
        if (!promotion) return;
        loadNotificationSettings([promotion.id]);
    };

    useEffect(() => {
        load();
    }, [promotion?.id]);

    return (
        <div className="WasteRegisterNotifications">
            <SimpleCard title={t('wasteManagers.title')} subtitle={t('wasteManagers.subtitle')}>
                <table className="WasteRegisterNotifications__table" data-testid={`hola`}>
                    <thead>
                        <tr>
                            <th>{t('wasteManagers.label')}</th>
                            <th>{t('associatedEmails')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <StakeholderEmailRow stakeholderType="waste-manager" onSave={() => load()} />
                        {data?.wasteManagers?.map((notificationSetting) => (
                            <StakeholderEmailRow
                                key={notificationSetting.id}
                                notificationSetting={notificationSetting}
                                stakeholderType="waste-manager"
                                onRemove={() => load()}
                            />
                        ))}
                    </tbody>
                </table>
                {/* {loading === 'failed' && <ErrorCard className="KPIGoals__error" error={error} />} */}
            </SimpleCard>
            <SimpleCard title={t('carriers.title')} subtitle={t('carriers.subtitle')}>
                <table className="WasteRegisterNotifications__table" data-testid={`hola2`}>
                    <thead>
                        <tr>
                            <th>{t('carriers.label')}</th>
                            <th>{t('associatedEmails')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <StakeholderEmailRow stakeholderType="carrier" onSave={() => load()} />
                        {data?.carriers?.map((notificationSetting) => (
                            <StakeholderEmailRow
                                key={notificationSetting.id}
                                notificationSetting={notificationSetting}
                                stakeholderType="carrier"
                                onRemove={() => load()}
                            />
                        ))}
                    </tbody>
                </table>
                {/* {loading === 'failed' && <ErrorCard className="KPIGoals__error" error={error} />} */}
            </SimpleCard>
        </div>
    );
};

export default WasteRegisterNotifications;
