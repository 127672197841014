export const entitiesAdapter = (list, keyId = 'id') => {
    const emptyEntities = {
        byId: {},
        allIds: []
    };

    const result = list.reduce((entities, item) => {
        const id = item[keyId];
        return {
            byId: { ...entities.byId, [id]: item },
            allIds: [...entities.allIds, id]
        };
    }, emptyEntities);

    return result;
};
