import { produce } from 'immer';

import { OrphanDocumentsStore } from 'modules/orphan-documents/domain/OrphanDocumentsStore';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { removeDuplicates } from 'utils/helpers/general.helpers';

export const selectOrphanDocuments =
    ({ selectedDocuments = [] as OrphanDocumentsStore['state']['selectedDocuments'] }): Thunk<OrphanDocumentsStore> =>
    async (store) => {
        store.dispatch('selectMany', { selectedDocuments });
    };

export const selectOrphanDocumentsReducers: Pick<Reducers<OrphanDocumentsStore>, 'selectMany'> = {
    selectMany: (state, { selectedDocuments }) =>
        produce(state, (draft) => {
            const selectedDocsWithDuplicates = [...state.selectedDocuments, ...selectedDocuments];
            draft.selectedDocuments = removeDuplicates(selectedDocsWithDuplicates, 'extractionId');
        })
};
