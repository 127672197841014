import fetcher from 'lib/fetcher';

import { BilleablesApi } from '../domain/BilleablesApi/BilleablesApi';
import { ApiAddon } from '../domain/BilleablesApi/ApiBilleable';
import { isSelloValorizaAddon } from '../domain/Billeable/SelloValorizaBilleable/isSelloValorizaAddon';
import { SELLO_VALORIZA_ADDONS } from '../domain/Billeable/SelloValorizaBilleable/SelloValorizaAddons';

export const httpBilleablesApi: BilleablesApi = {
    async getAddons(params) {
        const [promotionAddons, promotion] = await Promise.all([
            fetcher.get('api/maintenance/promotion-addons').then((r) => r.data),
            fetcher.get('api/maintenance/promotions', { params }).then((r) => r.data[0])
        ]);

        const purchasedEnterpriseAddons = promotion.accessTo.filter((addon) => addon.scope === 'ENTERPRISE');
        const addons = [...promotionAddons, ...purchasedEnterpriseAddons];

        // Dado que estos addons son funcionalidades por defecto que se ofrecen desde el SaaS, no se tienen en cuenta
        const excludedAddons = ['PREMIUM_BI', 'ALERTS', 'FINAL_TRACEABILITY', 'WASTE_MANAGEMENT_ADVISORY'];

        const addonsWithPurchase: ApiAddon[] = addons
            .map((addon) => {
                return {
                    ...addon,
                    purchased: promotion.accessTo.some((purchasedAddon) => addon.id === purchasedAddon.id)
                };
            })
            .filter((addon) => !excludedAddons.includes(addon.name));

        return addonsWithPurchase;
    },
    async savePromotionAddons({ billeables, promotionId }) {
        const prevAddons = (await this.getAddons({ 'id.equals': promotionId })).filter(
            (addon) => addon.scope === 'PROMOTION'
        );

        const prevAddonsWithoutNoBadge = prevAddons.filter((addon) => addon.name !== 'NO_BADGE');

        const newAddons = billeables
            .filter((billeable) => billeable.config.addon && billeable.scope === 'PROMOTION')
            .map((billeable) => ({ ...billeable.config.addon, purchased: billeable.purchased })) as ApiAddon[];

        const addons = { prevAddons: prevAddonsWithoutNoBadge, newAddons };

        let toAdd = getAddonsChanged(addons, (prevAddon, newAddon) => newAddon?.purchased && !prevAddon.purchased);
        let toRemove = getAddonsChanged(addons, (prevAddon, newAddon) => !newAddon?.purchased && prevAddon.purchased);

        /**
         * TODO REMOVE
         * At this moment, promotions requires to have at least one "Sello Valoriza" addon.
         * If not, we need to send  NO_BADGE addon as fallback.
         */

        const addingSelloValorizaAddon = toAdd.some(isSelloValorizaAddon);
        const removingSelloValorizaAddon = toRemove.some(isSelloValorizaAddon);

        const removingAllSelloValorizaAddons = !addingSelloValorizaAddon && removingSelloValorizaAddon;
        const prevHasSelloValoriza = prevAddons.some((addon) => isSelloValorizaAddon(addon) && addon.purchased);
        const prevHasNoBadgeAddon = prevAddons.some((addon) => addon.name === 'NO_BADGE' && addon.purchased);

        const shouldAddNoBadge = removingAllSelloValorizaAddons || (!prevHasSelloValoriza && !prevHasNoBadgeAddon);
        const shouldRemoveNoBadge = addingSelloValorizaAddon && prevHasNoBadgeAddon;

        if (shouldAddNoBadge) toAdd.push(SELLO_VALORIZA_ADDONS.NO_BADGE);
        if (shouldRemoveNoBadge) toRemove.push(SELLO_VALORIZA_ADDONS.NO_BADGE);

        //** end TODO REMOVE */

        await Promise.all(
            toRemove.map((addon) =>
                fetcher.put('api/maintenance/remove-addon-from-promotion', { promotionId, addonId: addon.id })
            )
        );

        await Promise.all(
            toAdd.map((addon) =>
                fetcher.put('api/maintenance/add-addon-to-promotion', { promotionId, addonId: addon.id })
            )
        );
    }
};

function getAddonsChanged(
    addons: { prevAddons: ApiAddon[]; newAddons: ApiAddon[] },
    callback: (prevAddon: ApiAddon, newAddon?: ApiAddon) => boolean | undefined
) {
    return addons.prevAddons.reduce((addonsAcc, prevAddon) => {
        const newAddon = addons.newAddons.find((addon) => addon.id === prevAddon.id);
        if (callback(prevAddon, newAddon)) {
            return [...addonsAcc, prevAddon];
        }
        return addonsAcc;
    }, [] as Array<ApiAddon>);
}
