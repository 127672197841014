import { useAuth } from 'modules/auth';

let dispatchLogout = () => {};
const LogoutHandler = () => {
    const [, { logout }] = useAuth();

    dispatchLogout = () => {
        logout();
    };

    return <></>;
};
export { dispatchLogout };
export default LogoutHandler;
