export const ONBOARDING_RESOURCES = {
    intro: {
        id: 'intro',
        title: 'Introducción 360ºAdvisor',
        video: {
            id: 'fbf89a3cd57b4437b95a503bdc15ee59?sid=dc84d864-9aac-444a-a864-a4b2c8879fdd',
            thumbnailId: 'fbf89a3cd57b4437b95a503bdc15ee59-with-play',
            minutes: 3
        },
        guide: {
            id: 'd0d02f86-7ea0-4920-94ac-6a0dac969ca4',
            heightInPixels: 300
        }
    },
    documentation: {
        id: 'documentation',
        title: 'Configuración del proyecto - documentación',
        video: {
            id: 'e38646fbaec24e73ba720c5e22679883?sid=e8b1d286-89f2-4d76-8fc9-6ea78e87db6e',
            thumbnailId: 'e38646fbaec24e73ba720c5e22679883-with-play',
            minutes: 3
        },
        guide: null
    },
    project: {
        id: 'project',
        title: 'Seguimiento de proyecto',
        video: {
            id: '4a3de2986c2d44748512211173f5fb8d?sid=d56fa2bf-5d2e-4318-81d2-18daa9ab9c8f',
            thumbnailId: '4a3de2986c2d44748512211173f5fb8d-1720183394544-with-play',
            minutes: 5
        },
        guide: {
            id: 'daf58fed-4a0e-440f-b1d0-d0ae2403231a',
            heightInPixels: 300
        }
    },
    wasteFlows: {
        id: 'wasteFlows',
        title: 'Configuración - flujos de residuos. CT y gestión de gestoras',
        video: {
            id: 'e6fd0c7b595b49fa8ad70fc5b3ef5072?sid=5c1d74b1-167c-4afc-b6ab-f1fa384ddb54',
            thumbnailId: 'e6fd0c7b595b49fa8ad70fc5b3ef5072-1087baa4ab0a2c23-full-play',
            minutes: 5
        },
        guide: {
            id: '268b4c2a-cd83-465e-af7b-1dbee74cbd05',
            heightInPixels: 300
        }
    },
    users: {
        id: 'users',
        title: '¿Cómo gestiono mis usuarios?',
        video: null,
        guide: {
            id: 'eb207c8b-c441-4a8b-85ac-50fad2980c06',
            thumbnailId: 'eb207c8b-with-play',
            heightInPixels: 300
        }
    },
    diManagement: {
        id: 'di-management',
        title: 'Gestionar los DIs - buzón WallE',
        video: {
            id: 'a349c04fe91a466ea8079db202ba3421?sid=1d175f5b-7155-45b9-89ee-e62ec9e88aed',
            thumbnailId: 'a349c04fe91a466ea8079db202ba3421-with-play',
            minutes: 3
        },
        guide: {
            id: 'da10ea37-2bd4-4b7d-8226-fc74d8e2b180',
            heightInPixels: 300
        }
    },
    wasteRegister: {
        id: 'waste-register',
        title: 'Registrar residuos',
        video: {
            id: '91c4f96f674f4358b986aadd381ac732?sid=6fab1f17-ee64-4a83-86d4-0cd3031930bd',
            thumbnailId: 'e6fd0c7b595b49fa8ad70fc5b3ef5072-1087baa4ab0a2c23-full-play',
            minutes: 4
        },
        guide: {
            id: '37851613-d739-4cd0-8b21-eb6709b6aec1',
            heightInPixels: 300
        }
    },
    traceabilityValidation: {
        id: 'traceability-validation',
        title: 'Validación de traslados',
        video: {
            id: '5d8822dc4df3414eb76dae05c1356e53?sid=c155fd30-b18f-49fd-a635-1416fbde4eb9',
            thumbnailId: '5d8822dc4df3414eb76dae05c1356e53-with-play',
            minutes: 4
        },
        guide: {
            id: 'ad616192-efaa-47a0-8c96-aac1a7f3a3a1',
            heightInPixels: 300
        }
    },
    traceability: {
        id: 'traceability',
        title: 'Seguimiento de trazabilidad',
        video: {
            id: 'cdc32fa064b54ffba5ce68003fecdc51?sid=1f3f9b6d-cf59-4711-ad32-33077e4c3ed4',
            thumbnailId: 'cdc32fa064b54ffba5ce68003fecdc51-09902fcdfe8a46e1-full-play',
            minutes: 5
        },
        guide: {
            id: '91b0e401-83a7-4ddd-9130-48c9fbd57646',
            heightInPixels: 300
        }
    },

    impact: {
        id: 'impact',
        title: '¿Qué es el "Impacto"?',
        video: null,
        guide: {
            id: '6d79eb11-90fb-4936-a303-492dbb829a59',
            heightInPixels: 300
        }
    },
    siteChecking: {
        id: 'site-checking',
        title: 'Visita a obra: guía y actas',
        video: null,
        guide: {
            id: '22c42ba0-9b5e-42bc-9188-e5e74b7df15d',
            heightInPixels: 300
        }
    }
};
