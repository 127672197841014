import * as api from '../../api/index.js';
import { actions } from '../actions';
import { createValuablePpgcls } from '../domain/createValuablePpgcls';
import { createContractsGroupedByTransferOperator } from '../domain/createContractsGroupedByTransferOperator';
import { ensurePromotionFeaturesEnabled } from '../domain/ensurePromotionFeaturesEnabled';

export const loadTreatmentContractList =
    ({ dispatch }) =>
    async (promotion) => {
        try {
            dispatch({ type: actions.pending });
            ensurePromotionFeaturesEnabled(promotion);

            const treatmentContractList = [];
            const wasteManagersByPromo = await api.getWasteManagersByPromoId(promotion.id);

            for (let i = 0; i < wasteManagersByPromo.length; i++) {
                const wasteManager = wasteManagersByPromo[i];
                const ppgcls = await api.getPpgcls(promotion.id, wasteManager.id);

                const valuablePpgcls = createValuablePpgcls(ppgcls);

                const contractsGroupedByTransferOperator = createContractsGroupedByTransferOperator({
                    ppgcls: valuablePpgcls,
                    promotion,
                    wasteManager
                });

                treatmentContractList.push(...contractsGroupedByTransferOperator);
            }

            dispatch({ type: actions.listFulfilled, payload: { treatmentContractList } });
        } catch (error) {
            console.log(error);
            dispatch({ type: actions.rejected, payload: { message: error.message } });
        }
    };
