import ListadoCostes from '../pages/Costes/ListadoCostes';
import NuevoCoste from '../pages/Costes/NuevoCoste';

export const COSTS_ROUTES = [
    {
        component: ListadoCostes,
        path: '/costs',
        requiredPermission: 'canView',
        section: 'costs'
    },
    {
        component: NuevoCoste,
        path: '/costs/create',
        requiredPermission: 'canCreate',
        section: 'costs'
    },
    {
        component: NuevoCoste,
        path: '/costs/:id/edit',
        requiredPermission: 'canCreate',
        section: 'costs'
    }
];
