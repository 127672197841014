import { useDateFormatter } from 'hooks/useDateFormatter';
import { AG_GRID_LOCALE_ES } from 'i18n/agGrid_ES';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'utils/helpers/general.helpers';
import { FilterModel, FilterModelContent } from '../types/agGridTypes';

interface useFiltersTranslationProps {
    i18nNamespace: string;
    customTranslateFn?: (key) => string;
}

export function useFiltersTranslation(props: useFiltersTranslationProps) {
    const [t] = useTranslation(props.i18nNamespace);
    const { format } = useDateFormatter();

    const getFiltersString = (rawFilters: FilterModel) => {
        if (!rawFilters || Object.keys(rawFilters)?.length === 0) return '';

        const filters = Object.keys(rawFilters).map((key) => getFilterTranslation(rawFilters[key], key));
        const filtersString = 'Filtros aplicados: ' + filters.join('\n');

        return filtersString;
    };

    const getFilterTranslation = (filterData: FilterModelContent, key: string) => {
        // multiple filters (conditions) for the same field
        if (filterData.conditions) {
            const operatorTrans = AG_GRID_LOCALE_ES[filterData.operator]; // AND, OR...
            const filtersArray = filterData.conditions.map((filter) => _getIndividualFilter(filter, key));

            const string = capitalize(filtersArray.join(` ${operatorTrans} `));
            return string;
        }

        // else normal, individual filter
        const string = _getIndividualFilter(filterData, key);
        return string;
    };

    const _getIndividualFilter = (filterModel: FilterModelContent, key: string) => {
        const filterType = filterModel.filterType; // text, date...
        const value = filterModel.filter; // selected value by user
        const operationType = filterModel.type; // equals, greaterThan...
        const operationTypeTrans = AG_GRID_LOCALE_ES[operationType]?.toLowerCase();

        const trans = props.customTranslateFn ? props.customTranslateFn : t;

        // CON LA OPCIÓN "EN BLANCO" O "NO EN BLANCO"
        if (operationType === 'notBlank' || operationType === 'blank') {
            const string = trans(key) + ' ' + operationTypeTrans;
            return string;
        }

        // BOOLEAN COLUMNS
        // It doesn't make any sense that AG Grid re-uses filterModel "type" like this for booleans...
        if (operationType === 'true' || operationType === 'false') {
            const string = trans(key) + ' ' + operationTypeTrans;
            return string;
        }

        // DATE COLUMNS
        if (filterType === 'date') {
            const formattedDateFrom = format(filterModel.dateFrom, 'DD/MM/YY');
            var dateText = formattedDateFrom;

            // BETWEEN TWO DATES
            if (operationType === 'inRange') {
                const formattedDateTo = format(filterModel.dateTo, 'DD/MM/YY');
                dateText = [formattedDateFrom, formattedDateTo].join(' y ');
            }

            const string = trans(key) + ' ' + operationTypeTrans + ' ' + dateText;
            return string;
        }

        // ELSE
        const string = trans(key) + ' ' + operationTypeTrans + ' ' + value;
        return string;
    };

    return { getFilterTranslation, getFiltersString };
}
