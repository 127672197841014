import { PropsWithChildren } from 'react';

import picture from 'assets/auth-background.jpg';
import './AuthLayout.scss';

const AuthLayout = (props: PropsWithChildren<{}>) => {
    return (
        <>
            <div className="AuthLayout">
                <div className="AuthLayout__picture" style={{ backgroundImage: `url(${picture})` }}></div>
                <div className="AuthLayout__panel">{props.children}</div>
            </div>
        </>
    );
};

export default AuthLayout;
