import Headline from 'components/BasicComponents/Headline/Headline';
import KPIs from 'features/KPIs';
import Alerts from 'features/alerts';
import './PromotionDetail.scss';
import PromotionInfo from './sections/PromotionInfo/PromotionInfo';
import ProtocolAndExecution from './sections/ProtocolAndExecution';
import ValorizaBadge from './sections/ValorizaBadge/ValorizaBadge';
import WasteByValorization from './sections/WasteByValorization';
import WasteSummary from './sections/WasteSummary';

const PromotionDetail = () => {
    return (
        <div className="PromotionDetail">
            <section className="PromotionDetail__promotionInfo">
                <PromotionInfo />
            </section>
            <section className="PromotionDetail__valorizaBadge">
                <ValorizaBadge />
            </section>

            <section className="PromotionDetail__kpis">
                <Headline className="PromotionDetail__headline">Objetivos del proyecto</Headline>
                <KPIs />
            </section>

            <div className="PromotionDetail__main">
                <section className="PromotionDetail__protocolAndExecution">
                    <Headline className="PromotionDetail__headline">Protocolo y ejecución (buenas prácticas)</Headline>
                    <ProtocolAndExecution />
                </section>

                <section className="PromotionDetail__waste">
                    <Headline className="PromotionDetail__headline">Desglose de residuos</Headline>
                    <WasteSummary />
                    <WasteByValorization />
                </section>
            </div>

            <aside className="PromotionDetail__aside">
                <Alerts />
            </aside>
        </div>
    );
};

export default PromotionDetail;
