import fetcher from '../../../lib/fetcher';

export const traceabilityById = async (id) => {
    const URL = `/api/tracing/container-traceability?id.equals=${id}`;
    try {
        const response = await fetcher.get(URL);

        return response?.data?.content;
    } catch (error) {
        console.log(error);
    }
};
