import { NotificationSettingsCriteria } from 'modules/notification-settings/domain/NotificationSettingsCriteria';
import { NotificationSettingsStore } from 'modules/notification-settings/domain/NotificationSettingsStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

export const loadNotificationSettings =
    (promotionIds: number[]): Thunk<NotificationSettingsStore> =>
    async (store, { api }) => {
        try {
            const newCriteria: NotificationSettingsCriteria = {
                promotionIds: promotionIds,
                type: 'waste-register'
            };

            store.set((draft) => {
                draft.criteria = newCriteria;

                draft.loading = 'pending';
                draft.error = null;
            });

            const wasteManagerNotificationSettings = await api.findByCriteria({
                ...newCriteria,
                stakeholderType: 'waste-manager'
            });
            const carrierNotificationSettings = await api.findByCriteria({
                ...newCriteria,
                stakeholderType: 'carrier'
            });

            store.set((draft) => {
                draft.criteria = newCriteria;

                draft.data.wasteManagers = wasteManagerNotificationSettings;
                draft.data.carriers = carrierNotificationSettings;

                draft.loading = 'succeeded';
                draft.error = null;
            });
        } catch (e) {
            const error = e as Error;
            console.error(error);

            store.set((state) => {
                state.error = error;
                state.loading = 'failed';
            });
        }
    };
