import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';

import WASTE_REGISTER_CONFIG from 'features/waste-register/waste-register.config';
import { notify } from 'utils/helpers/notifications';

const { PAGE_MODE_STORAGE_KEY } = WASTE_REGISTER_CONFIG;

export function useWasteRegisterNotifications() {
    const [t] = useTranslation('wasteRegister');
    const match = useRouteMatch();

    const created = (id: string) => {
        const mode = localStorage.getItem(PAGE_MODE_STORAGE_KEY);

        notify({
            msg: (
                <div className="WasteRegisterForm__toast">
                    <Link to={`${match.url}/${mode}/${id}`}>{t('form.save.notification.success')}</Link>
                </div>
            ),
            isSuccess: true,
            autoClose: 3000
        });
    };

    const removed = () => {
        notify({
            msg: <div className="WasteRegisterForm__toast">{t('form.remove.notification.success')}</div>,
            isSuccess: true,
            autoClose: 3000
        });
    };

    return { created, removed };
}
