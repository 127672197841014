import { getDocumentsByUsage } from 'features/documentation/api/getDocumentsByUsage';

export const getDocumentsByType = async (promotionId, usage, extraFilter = '') => {
    let docs = [];
    const filter = `?usage.equals=${usage}${extraFilter}`;
    const docsRequest = await getDocumentsByUsage(promotionId, filter);
    if (docsRequest?.data?.code == 0) {
        docs = docsRequest?.data?.content;
    }
    return docs;
};
