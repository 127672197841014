import { createBilleables } from 'modules/billeables/domain/Billeable/createBilleables';
import { BilleablesStore, BilleablesStoreEntities } from 'modules/billeables/domain/BilleablesStore';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { entitiesAdapter } from 'modules/shared/domain/utils/entitiesAdapter';

export const fetchBilleables =
    (criteria?: { promotionId?: number }): Thunk<BilleablesStore> =>
    async (store, { api, promotion }) => {
        store.dispatch('loadingSetted', { loading: store.state.loading === 'idle' ? 'pending' : 'updating' });

        try {
            const addons = await api.getAddons({ 'id.equals': criteria?.promotionId });
            const billeables = createBilleables({ addons, promotion: promotion.data });

            store.dispatch('fetchFulfilled', { billeables: billeables });
        } catch (e) {
            store.dispatch('errorSetted', { error: e as Error });
        }
    };

export const fetchReducers: Pick<Reducers<BilleablesStore>, 'fetchFulfilled'> = {
    fetchFulfilled(state, payload) {
        const entities = entitiesAdapter(payload.billeables, 'name') as BilleablesStoreEntities;

        return { error: null, loading: 'succeeded', entities, snapshot: entities };
    }
};
