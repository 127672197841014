import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../context/AuthProvider';
import Layout from '../../../pages/Layout/Layout';
import Buscador from '../../../components/BasicComponents/Buscador/Buscador';
import { TituloH1, TituloPromocion } from '../../../components/BasicComponents/Titulos/Titulos';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import InputFecha from '../../../components/BasicComponents/Inputs/InputFecha';
import SearchButton from '../../../components/BasicComponents/Buttons/SearchButton';
import SubmitButton from '../../../components/BasicComponents/Buttons/SubmitButton';
import DocumentType from '../../../components/BasicComponents/Select-Autocomplete/DocumentType';
import GoBack from '../../../components/BasicComponents/Buttons/GoBack';
import SortMenu from '../../../components/BasicComponents/Sort/SortMenu';
import { SORT } from '../constants/documentation.constants';
import { INITIAL_PAGE } from '../../../utils/constants/pagination.constants';
import { createFilterWithObject } from '../../../utils/helpers/general.helpers';
import { deleteDocument } from '../api/deleteDocument';
import { getDocuments } from '../api/getDocuments';
import Document from './Document';
import TreatmentContractList from './TreatmentContract/TreatmentContractList';
import { TREATMENT_CONTRACT_TYPE_ID } from './TreatmentContract/utils/treatmentContract.constants';

const DocumentationByTypePage = (props) => {
    const [t] = useTranslation();
    const { state } = useLocation();
    const history = useHistory();
    const INITIAL_STATE_VALUES = {
        fechaDesde: '',
        fechaHasta: ''
    };

    const [selectedDocType, setSelectedDocType] = useState();
    const [pagination, setPagination] = useState(INITIAL_PAGE);
    const [loading, setLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [values, setValues] = useState(INITIAL_STATE_VALUES);
    const [numberOfDocs, setNumberOfDocs] = useState(0);
    const [selectedOption, setSelectedOption] = useState();
    const [openMenu, setOpenMenu] = useState(false);

    const { fechaDesde, fechaHasta } = values;

    const createDocumentFilter = (formValues) => {
        const documentFilter = {
            fechaDesde: `&fechaCreacion.greaterThanOrEqual=${formValues.fechaDesde}`,
            fechaHasta: `&fechaCreacion.lessThanOrEqual=${formValues.fechaHasta}`
        };
        return documentFilter;
    };

    const getFilter = (page, filterValues, resetSortFilter) => {
        const sortBy =
            selectedOption === undefined || resetSortFilter
                ? '&sort=fechaCreacion,desc&sort=id,desc'
                : objSortFilter[selectedOption?.label];

        let filter = `?page=${page}&sort=fechaCreacion,desc&sort=id,desc&tipoId.equals=${selectedDocType?.id}`;
        filter += createFilterWithObject(filterValues, createDocumentFilter(filterValues));
        filter += sortBy;
        filter += selectedDocType?.section === 'wasteManagerDocuments' ? '&usage.equals=waste-management' : '';

        return filter;
    };

    const searchTypeDocument = async (page, filterValues, newSearch = true, resetSortFilter = false) => {
        setLoading(true);
        const filter = getFilter(page, filterValues, resetSortFilter);
        const docsRequest = await getDocuments(props.promocion?.id, filter);
        if (docsRequest?.data?.code == 0) {
            if (newSearch) {
                setDocuments(docsRequest.data.content.documents);
            } else {
                setDocuments([...documents, ...docsRequest.data.content.documents]);
            }
            setNumberOfDocs(docsRequest.headers['x-total-count']);
        }
        setLoading(false);
    };

    const handleLoadMore = () => {
        setPagination(pagination + 1);
        searchTypeDocument(pagination + 1, values, false);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setPagination(0);
        searchTypeDocument(INITIAL_PAGE, values, true);
    };

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    const deleteDocumentFunction = async (id) => {
        const deleteDocumentRequest = await deleteDocument(id);
        if (deleteDocumentRequest !== undefined && deleteDocumentRequest.status === 204) {
            const documentsFiltered = documents.filter((document) => document.id !== id);
            setNumberOfDocs(numberOfDocs - 1);
            setDocuments(documentsFiltered);
        }
    };

    const goBack = () => {
        history.push('/documents');
    };

    //SORT
    const objSortFilter = {
        ASC_DATE: '&sort=fechaCreacion,asc&sort=id,desc',
        DESC_DATE: '&sort=fechaCreacion,desc&sort=id,desc',
        ASC_FILENAME: '&sort=nombreFichero,asc&sort=id,desc',
        DESC_FILENAME: '&sort=nombreFichero,desc&sort=id,desc'
    };

    const onChangeRadio = (option) => {
        setSelectedOption(option);
    };

    const isChecked = (option) => {
        return selectedOption?.label === option?.label ? true : false;
    };

    const handleSortSubmit = async (e) => {
        e.preventDefault();
        if (selectedOption) {
            searchTypeDocument(INITIAL_PAGE, values, true);
            setOpenMenu(false);
        }
    };

    const deleteFilter = async () => {
        setSelectedOption();
        setPagination(0);
        searchTypeDocument(INITIAL_PAGE, values, true, true);
    };

    useEffect(() => {
        if (state?.selectedDocType) {
            setSelectedDocType(state?.selectedDocType);
            setNumberOfDocs(state?.selectedDocType?.size);
            state.selectedDocType = null;
        }
    }, []);

    useEffect(() => {
        if (props.promocion?.id !== undefined && selectedDocType?.id !== undefined) {
            setPagination(0);

            if (state?.values) {
                setValues(state?.values);
                searchTypeDocument(INITIAL_PAGE, state?.values, true);
            } else {
                searchTypeDocument(INITIAL_PAGE, values, true);
            }
        }
    }, [selectedDocType]);

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <TituloH1 titulo={t('title', { ns: 'documentation' })} />
            <Buscador titulo={t('finder', { ns: 'documentation' })}>
                <form className="documentation__form" onSubmit={handleFormSubmit}>
                    <InputFecha
                        label={t('date.dateFrom', { ns: 'common' })}
                        id="fechaDesde-trazabilidad"
                        name="fechaDesde"
                        value={fechaDesde}
                        onChange={handleInputChange}
                        aspaLimpiar
                        values={values}
                        setValues={setValues}
                        notLimit={true}
                    />
                    <InputFecha
                        label={t('date.dateTo', { ns: 'common' })}
                        id="fechaHasta-trazabilidad"
                        name="fechaHasta"
                        value={fechaHasta}
                        onChange={handleInputChange}
                        aspaLimpiar
                        values={values}
                        setValues={setValues}
                        notLimit={true}
                    />
                    <SearchButton text={t('search.search', { ns: 'common' })} />
                </form>
            </Buscador>

            {selectedDocType?.id !== undefined && (
                <>
                    <TituloPromocion titulo={t('search.results', { ns: 'common' })} />
                    <SortMenu
                        openMenu={openMenu}
                        setOpenMenu={setOpenMenu}
                        selectedOption={selectedOption}
                        deleteFilter={deleteFilter}
                    >
                        <form onSubmit={handleSortSubmit} className="sort__form">
                            {SORT.map((input) => (
                                <div key={input.label} className="sort__inputContainer">
                                    <input
                                        className="sort__input"
                                        type="radio"
                                        value={input.label}
                                        name={input.name}
                                        onChange={() => onChangeRadio(input)}
                                        checked={isChecked(input)}
                                        id={input.label}
                                    />
                                    <label className="sort__label" htmlFor={input.label}>
                                        {t(input.label, { ns: 'sort' })}
                                    </label>
                                </div>
                            ))}
                            <SubmitButton text={t('results', { ns: 'common' })} />
                        </form>
                    </SortMenu>
                    <div className="documentation__select">
                        <DocumentType changeDocType={setSelectedDocType} docType={selectedDocType} />
                        <h4>
                            {selectedDocType.name}{' '}
                            {!loading && selectedDocType.id !== TREATMENT_CONTRACT_TYPE_ID && '(' + numberOfDocs + ')'}
                        </h4>
                    </div>
                    {!loading && documents.length === 0 && (
                        <p className="documentation__empty">{t('noResults', { ns: 'common' })}</p>
                    )}
                    {documents.length > 0 && (
                        <InfiniteScroll
                            dataLength={documents.length}
                            next={() => handleLoadMore()}
                            hasMore={true}
                            scrollableTarget="main-layout"
                        >
                            {documents.map((document, i) => (
                                <Document
                                    document={document}
                                    documentType={selectedDocType}
                                    values={values}
                                    key={i}
                                    onClickDelete={deleteDocumentFunction}
                                />
                            ))}
                        </InfiniteScroll>
                    )}
                    {props.isCoCircular && selectedDocType.id === TREATMENT_CONTRACT_TYPE_ID && (
                        <TreatmentContractList promotion={props?.promocion} />
                    )}
                </>
            )}
            {loading && <Spinner />}
        </Layout>
    );
};
export default withAuth(DocumentationByTypePage);
