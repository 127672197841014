import Hotjar from '@hotjar/browser';

import { AnalyticsApi } from 'modules/analytics/domain/AnalyticsApi';

export const hotjarAnalyticsApi: AnalyticsApi = {
    init: async () => {
        const id = 2849652;
        const version = 6;
        // Initializing with `debug` option:
        Hotjar.init(id, version, { debug: false });
    },
    event: async (event, identification) => {
        const isCocircular = ['@cocircular.es', '@localhost'].some((domain) => identification?.email.includes(domain));
        const userType = isCocircular ? 'cocircular' : 'external';

        const metadata = identification ? `user:${userType}` : '';

        const data = `${event}__${metadata}`;
        Hotjar.event(data);
    },
    pageView: async ({ page, title }) => {
        // Hotjar automatically tracks page views
    },
    identify: async (identification) => {
        Hotjar.identify(identification.email, identification as any);
    }
};
