import { useEffect } from 'react';
import { useQuery } from './useQuery';

export default function useStateFromQueryParams<S extends string | null>(props: { key: string; initialState?: S }) {
    const { key, initialState } = props;
    const query = useQuery();

    const state = query.params[key];

    const setState = (value?: S) => {
        if (value) query.push({ [key]: value }, { mode: 'replace' });
        else query.remove([key]);
    };

    useEffect(() => {
        if (state === undefined) return;
        if (initialState) setState(initialState);
    }, [initialState]);

    return [state, setState] as [S | undefined, (value?: S) => void];
}
