import { ApiAddon } from '../../BilleablesApi/ApiBilleable';
import { SELLO_VALORIZA_ADDONS } from './SelloValorizaAddons';
import { SelloValorizaBilleable } from './SelloValorizaBilleable';
import { isSelloValorizaAddon } from './isSelloValorizaAddon';

type Sources = {
    addons: ApiAddon[];
    addon?: ApiAddon;
    score?: number | null;
    valorizationTarget?: number | null;
};

/** Creates a "Sello Valoriza" billeable item from API addons */
export function createSelloValorizaBilleable(source: Sources): SelloValorizaBilleable {
    const defaultAddon = source.addons.find((ad) => ad.name === SELLO_VALORIZA_ADDONS.FULL_BADGE.name);
    const addon = source.addon || defaultAddon;

    return {
        name: 'SELLO_VALORIZA',
        scope: 'PROMOTION',
        purchased: source.addon?.purchased || false,
        editable: true,
        config: {
            addon: addon ? { id: addon.id, name: addon.name } : null,
            score: source.score || null,
            valorizationTarget: source.valorizationTarget || null
        },
        options: {
            addon: source.addons.filter(isSelloValorizaAddon).map(createTypeOptions)
        },
        validations: {
            score: { min: '0', max: '5' },
            valorizationTarget: { min: '1', max: '100' }
        }
    };
}

const createTypeOptions = (apiBilleable: ApiAddon) => ({ value: apiBilleable.id, label: apiBilleable.name });
