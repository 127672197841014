import KPIs_EN from 'features/KPIs/i18n/KPIs_EN.json';
import KPIs_ES from 'features/KPIs/i18n/KPIs_ES.json';
import KPIsGoalSettings_EN from 'features/KPIsGoalSettings/i18n/KPIsGoalsSettings_EN.json';
import KPIsGoalSettings_ES from 'features/KPIsGoalSettings/i18n/KPIsGoalsSettings_ES.json';
import walle_EN from 'features/admin/walle/i18n/walle_EN.json';
import walle_ES from 'features/admin/walle/i18n/walle_ES.json';
import Auth_EN from 'features/auth/i18n/Auth_EN.json';
import Auth_ES from 'features/auth/i18n/Auth_ES.json';
import automaticReadingEN from 'features/automatic-reading/i18n/automaticReading_EN.json';
import automaticReadingES from 'features/automatic-reading/i18n/automaticReading_ES.json';
import faq_EN from 'features/faq/i18n/faq_en.json';
import faq_ES from 'features/faq/i18n/faq_es.json';
import globalSettings_EN from 'features/global-settings/i18n/globalSettings_EN.json';
import globalSettings_ES from 'features/global-settings/i18n/globalSettings_ES.json';
import home_EN from 'features/home/i18n/home_en.json';
import home_ES from 'features/home/i18n/home_es.json';
import tags_EN from 'features/tags/i18n/tags_EN.json';
import tags_ES from 'features/tags/i18n/tags_ES.json';
import traceabilities_EN from 'features/traceabilities/i18n/traceabilities_EN.json';
import traceabilities_ES from 'features/traceabilities/i18n/traceabilities_ES.json';
import wasteManagement_EN from 'features/waste-management/i18n/waste-management_EN.json';
import wasteManagement_ES from 'features/waste-management/i18n/waste-management_ES.json';
import wasteRegister_EN from 'features/waste-register/i18n/wasteRegister_EN.json';
import wasteRegister_ES from 'features/waste-register/i18n/wasteRegister_ES.json';
import NotificationSettings_EN from 'features/notification-settings/i18n/NotificationSettings_EN.json';
import NotificationSettings_ES from 'features/notification-settings/i18n/NotificationSettings_ES.json';
import Validations_ES from 'validations/i18n/Validations_ES.json';
import Validations_EN from 'validations/i18n/Validations_EN.json';
import comparative_ES from 'features/comparative/i18n/comparative_ES.json';
import comparative_EN from 'features/comparative/i18n/comparative_EN.json';
import onboarding_ES from 'features/onboarding/i18n/onboarding_ES.json';
import onboarding_EN from 'features/onboarding/i18n/onboarding_EN.json';
import helpCenter_ES from 'features/help-center/i18n/help-center_ES.json';
import helpCenter_EN from 'features/help-center/i18n/help-center_EN.json';
import support_ES from 'features/support/i18n/support_ES.json';
import support_EN from 'features/support/i18n/support_EN.json';

import i18n, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import associateTraceabilitiesEN from '../features/associate_documents_traceabilities/i18n/associate_documents_traceabilities_en.json';
import associateTraceabilitiesES from '../features/associate_documents_traceabilities/i18n/associate_documents_traceabilities_es.json';
import dashboard_EN from '../features/dashboard/i18n/dashboard_EN.json';
import dashboard_ES from '../features/dashboard/i18n/dashboard_ES.json';
import diExtraction_EN from '../features/di-extraction/i18n/diExtraction_en.json';
import diExtraction_ES from '../features/di-extraction/i18n/diExtraction_es.json';
import documentationEN from '../features/documentation/i18n/documentation_en.json';
import documentationES from '../features/documentation/i18n/documentation_es.json';
import impactEN from '../features/impact/i18n/impact_EN.json';
import impactES from '../features/impact/i18n/impact_ES.json';
import newPpgclsEN from '../features/new_ppgcl/i18n/new_ppgcls_en.json';
import newPpgclsES from '../features/new_ppgcl/i18n/new_ppgcls_es.json';
import reports_EN from '../features/reports/i18n/reports_EN.json';
import reports_ES from '../features/reports/i18n/reports_ES.json';
import reuseEN from '../features/reuse/i18n/reuse_EN.json';
import reuseES from '../features/reuse/i18n/reuse_ES.json';
import activitiesEN from './en/activities.json';
import commonEN from './en/common.json';
import costsEN from './en/costs.json';
import errorsEN from './en/errors.json';
import lerEN from './en/ler.json';
import newCodLersEN from './en/newCodLers.json';
import newPpgclEN from './en/newPpgcl.json';
import newPromotionEN from './en/newPromotion.json';
import newWasteManagerEN from './en/newWasteManager.json';
import ppgclEN from './en/ppgcl.json';
import profileEN from './en/profile.json';
import promotionEN from './en/promotion.json';
import sortEN from './en/sort.json';
import traceabilityEN from './en/traceability.json';
import usersEN from './en/users.json';
import visitEN from './en/visit.json';
import activitiesES from './es/activities.json';
import commonES from './es/common.json';
import costsES from './es/costs.json';
import errorsES from './es/errors.json';
import lerES from './es/ler.json';
import newCodLersES from './es/newCodLers.json';
import newPpgclES from './es/newPpgcl.json';
import newPromotionES from './es/newPromotion.json';
import newWasteManagerES from './es/newWasteManager.json';
import ppgclES from './es/ppgcl.json';
import profileES from './es/profile.json';
import promotionES from './es/promotion.json';
import sortES from './es/sort.json';
import traceabilityES from './es/traceability.json';
import usersES from './es/users.json';
import visitES from './es/visit.json';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        // Namspaces
        common: commonEN,
        errors: errorsEN,
        reports: reports_EN,
        activities: activitiesEN,
        visit: visitEN,
        promotion: promotionEN,
        costs: costsEN,
        traceability: traceabilityEN,
        newPromotion: newPromotionEN,
        users: usersEN,
        sort: sortEN,
        documentation: documentationEN,
        ler: lerEN,
        profile: profileEN,
        newWasteManager: newWasteManagerEN,
        impact: impactEN,
        ppgcl: ppgclEN,
        associateTraceabilities: associateTraceabilitiesEN,
        home: home_EN,
        newCodLers: newCodLersEN,
        newPpgcl: newPpgclEN,
        reuse: reuseEN,
        automaticReading: automaticReadingEN,
        newPpgcls: newPpgclsEN,
        dashboard: dashboard_EN,
        faq: faq_EN,
        diExtraction: diExtraction_EN,
        traceabilities: traceabilities_EN,
        walle: walle_EN,
        auth: Auth_EN,
        kpis: KPIs_EN,
        wasteManagement: wasteManagement_EN,
        globalSettings: globalSettings_EN,
        tags: tags_EN,
        KPIsGoalSettings: KPIsGoalSettings_EN,
        wasteRegister: wasteRegister_EN,
        NotificationSettings: NotificationSettings_EN,
        validations: Validations_EN,
        comparative: comparative_EN,
        onboarding: onboarding_EN,
        helpCenter: helpCenter_EN,
        support: support_EN
    },
    es: {
        common: commonES,
        errors: errorsES,
        reports: reports_ES,
        activities: activitiesES,
        visit: visitES,
        promotion: promotionES,
        costs: costsES,
        traceability: traceabilityES,
        newPromotion: newPromotionES,
        users: usersES,
        sort: sortES,
        documentation: documentationES,
        ler: lerES,
        profile: profileES,
        newWasteManager: newWasteManagerES,
        impact: impactES,
        ppgcl: ppgclES,
        associateTraceabilities: associateTraceabilitiesES,
        home: home_ES,
        newCodLers: newCodLersES,
        newPpgcl: newPpgclES,
        reuse: reuseES,
        automaticReading: automaticReadingES,
        newPpgcls: newPpgclsES,
        dashboard: dashboard_ES,
        faq: faq_ES,
        diExtraction: diExtraction_ES,
        traceabilities: traceabilities_ES,
        walle: walle_ES,
        auth: Auth_ES,
        kpis: KPIs_ES,
        wasteManagement: wasteManagement_ES,
        globalSettings: globalSettings_ES,
        tags: tags_ES,
        KPIsGoalSettings: KPIsGoalSettings_ES,
        wasteRegister: wasteRegister_ES,
        NotificationSettings: NotificationSettings_ES,
        validations: Validations_ES,
        comparative: comparative_ES,
        onboarding: onboarding_ES,
        helpCenter: helpCenter_ES,
        support: support_ES
    }
};

const applyCaseFormat = (value, options) => {
    const { format } = options;

    if (value === undefined || format === undefined) {
        return value;
    }

    switch (format) {
        case 'uppercase':
            return value?.toUpperCase();
        case 'lowercase':
            return value?.toLowerCase();
        case 'capitalize':
            return value?.charAt(0)?.toUpperCase() + value?.slice(1);
        default:
            return value;
    }
};

use(initReactI18next) // passes i18n down to react-i18next
    .use({
        // our custom plugin (postProcessor)
        type: 'postProcessor',
        name: 'caseConversor',
        // eslint-disable-next-line
        process: function (value, key, options, translator) {
            return applyCaseFormat(value, options);
        }
    })
    .init({
        resources,
        defaultNS: 'common',
        lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        postProcess: ['caseConversor'] // custom postProcess
    });

export default i18n;
