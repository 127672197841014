import { IconInfoCircle } from '@tabler/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Co2Generated } from 'assets/iconos/valorizadas/co2_generated.svg';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import { currencyFormatDE } from 'utils/helpers/general.helpers';

import './Impact.scss';
import Kpi from './ImpactKpi';
import PopoverCalculation from './PopoverInfoCalculation';
import ByLerBarGraph from './graphs/GraphByLerBar';

const ImpactCo2Generated = ({ co2KPI, co2generated, co2GeneratedbyLerGraphData }) => {
    const [t] = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const handleToggleModal = () => {
        setOpenModal(!openModal);
    };

    const getButtonIconAndTitle = (title) => {
        return (
            <>
                <IconInfoCircle size={18} stroke={1.5} />
                <span>{title}</span>
            </>
        );
    };

    const emptyData = Object.values(co2KPI).every((kpi) => kpi === '0');
    if (emptyData) return null;

    return (
        <SimpleCard
            title="CO₂ generado (t CO₂ eq)"
            className="impact_container"
            button
            buttonTitle={getButtonIconAndTitle(t('howIsItCalculated', { ns: 'impact' }))}
            buttonAction={() => handleToggleModal()}
        >
            <div className="impact__card">
                <div className="impact__kpisContainer">
                    <Kpi
                        isGenerated={true}
                        kpiValue={currencyFormatDE(co2generated?.real)}
                        IconKPI={Co2Generated}
                        type="generated"
                        testid="kpiCo2GeneratedReal"
                    />
                </div>
                <div className="Impact__containerGraphs">
                    <div>
                        {co2GeneratedbyLerGraphData?.length > 0 ? (
                            <ByLerBarGraph
                                onlyReal={true}
                                graphTitle={t('co2.generated.byLer', { ns: 'impact' })}
                                graphData={co2GeneratedbyLerGraphData}
                                legendTitle="T CO₂ Eq."
                            />
                        ) : null}
                    </div>
                </div>
            </div>
            <SimplePopover
                openModal={openModal}
                setOpenModal={setOpenModal}
                noClose={false}
                title={t('popoverTitle', { ns: 'impact' })}
                addFunctionOnClose={handleToggleModal}
            >
                <PopoverCalculation />
            </SimplePopover>
        </SimpleCard>
    );
};

export default ImpactCo2Generated;
