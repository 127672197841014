import { produce } from 'immer';

import { OrphanDocumentsStore } from 'modules/orphan-documents/domain/OrphanDocumentsStore';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

export const unselectOrphanDocuments =
    ({ selectedDocumentIds = [] as string[], unselectAll = false }): Thunk<OrphanDocumentsStore> =>
    async (store) => {
        store.dispatch('unselectMany', { selectedDocumentIds, unselectAll });
    };

export const unselectOrphanDocumentsReducers: Pick<Reducers<OrphanDocumentsStore>, 'unselectMany'> = {
    unselectMany: (state, { selectedDocumentIds, unselectAll }) =>
        produce(state, (draft) => {
            if (unselectAll) {
                draft.selectedDocuments = [];
            } else {
                draft.selectedDocuments = draft.selectedDocuments.filter(
                    (selectedDocument) => !selectedDocumentIds.includes(selectedDocument.extractionId)
                );
            }
        })
};
