import { Reducers, createStore } from 'modules/shared/domain/store/createStore';
import { AUTH_INITIAL_STATE } from '../domain/AuthConfig';
import { AuthStore } from '../domain/AuthStore';
import { login } from './auth/login';
import { logout } from './auth/logout';
import { loadAuth } from './load/loadAuth';
import { changePassword } from './reset-password/changePassword';
import { requestPassReset } from './reset-password/requestPassReset';
import { checkKeyValidity } from './reset-password/checkKeyValidity';

export const authStore = createStore({
    reducers: {} as Reducers<AuthStore>,
    initialState: AUTH_INITIAL_STATE,
    thunks: {
        login,
        logout,
        loadAuth,
        requestPassReset,
        checkKeyValidity,
        changePassword
    }
});
