import { httpNotificationSettingsApi } from 'modules/notification-settings/infrastructure/api/httpNotificationSettingsApi';
import { NotificationSettingsProvider } from 'modules/notification-settings/infrastructure/react/notificationSettingsContext';
import { useTranslation } from 'react-i18next';
import './NotificationSettings.scss';
import WasteRegisterNotifications from './components/WasteRegisterNotifications/WasteRegisterNotifications';

const WasteRegisterSettings = () => {
    const [t] = useTranslation('NotificationSettings');
    return (
        <div className="NotificationSettings">
            <header className="NotificationSettings__header">
                <h2>{t('title')}</h2>
            </header>
            <NotificationSettingsProvider api={httpNotificationSettingsApi}>
                <WasteRegisterNotifications />
            </NotificationSettingsProvider>
        </div>
    );
};

export default WasteRegisterSettings;
