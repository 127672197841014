import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import Tabs from 'components/BasicComponents/Tabs';
import PrivateRoute from 'components/routes/PrivateRoute';
import TraceabilitiesTabBadge from 'features/traceabilities/components/TraceabilitiesTabBadge';
import { TRACEABILITIES_VIEWS, traceabilitiesRouteRoot } from 'features/traceabilities/routes/TraceabilitiesRoutes';
import { PromotionWasteRegisterPage } from 'features/waste-register';
import { useAutomaticReadingPermissions } from 'modules/automatic-reading';
import { useDiExtraction } from 'modules/di-extraction';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import { useTraceabilities } from 'modules/traceabilities';
import Layout from 'pages/Layout/Layout';
import TraceabilitiesList from '../../components/TraceabilitiesList';
import TraceabilitiesReview from '../../components/TraceabilitiesReview';
import './TraceabilitiesPage.scss';

const TraceabilitiesPage = () => {
    const [{ promotion }] = usePromotion();
    const promotionId = promotion?.id;

    const [traceabilities] = useTraceabilities();
    const [extractions] = useDiExtraction();
    const [{ permissions }, { loadAutomaticReadingPermissions }] = useAutomaticReadingPermissions();
    const canAccessAutomaticReading = permissions?.canAccess.value && !permissions.isDisabled.value;

    const { url } = useRouteMatch();
    const [t] = useTranslation();
    const location = useLocation();

    const activeTab =
        (location.pathname.includes(TRACEABILITIES_VIEWS.automaticReading) && 1) ||
        (location.pathname.includes(TRACEABILITIES_VIEWS.wasteRegister) && 2) ||
        0; // Home tab as fallback

    // Update extractions counters
    useEffect(() => {
        if (!promotion?.id) return;

        loadAutomaticReadingPermissions();
    }, [promotionId]);

    const error = traceabilities.error || extractions.error;

    const pendingExtractions = extractions.counters.PENDING || 0;

    return (
        <Layout padding={0}>
            <div className="TraceabilitiesPage">
                <ErrorCard className="TraceabilitiesPage__error" error={Array.isArray(error) ? error[0] : error} />

                <section className="TraceabilitiesPage__tabs">
                    <Tabs
                        activeTab={activeTab}
                        testid="traceabilities-tabs"
                        tabs={[
                            // to keep consistency between the index and the active tab, always render all elements and hide them by styles
                            <Link className="TraceabilitiesPage__tab" to={url}>
                                {t('tabs.home', { ns: 'traceabilities' })}
                            </Link>,

                            <Link
                                style={{ display: canAccessAutomaticReading ? 'flex' : 'none' }}
                                className="TraceabilitiesPage__tab"
                                to={url + TRACEABILITIES_VIEWS.automaticReading}
                            >
                                {t('tabs.automaticReading', { ns: 'traceabilities' })}

                                <TraceabilitiesTabBadge
                                    state={
                                        (pendingExtractions && 'loading') || (!!extractions.total && 'warning') || null
                                    }
                                >
                                    {pendingExtractions || extractions.total || null}
                                </TraceabilitiesTabBadge>
                            </Link>,
                            <Link className="TraceabilitiesPage__tab" to={url + TRACEABILITIES_VIEWS.wasteRegister}>
                                {t('tabs.wasteRegister', { ns: 'traceabilities' })}
                            </Link>
                        ]}
                    />
                </section>

                <Switch>
                    <PrivateRoute
                        path={`${traceabilitiesRouteRoot}${TRACEABILITIES_VIEWS.automaticReading}`}
                        component={TraceabilitiesReview}
                    />
                    <PrivateRoute
                        path={`${traceabilitiesRouteRoot}${TRACEABILITIES_VIEWS.wasteRegister}`}
                        component={PromotionWasteRegisterPage}
                    />
                    <PrivateRoute component={TraceabilitiesList} />
                </Switch>
            </div>
        </Layout>
    );
};

export default TraceabilitiesPage;
