import { IconBuildingCommunity, IconBuildingSkyscraper } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import RoundedIcon from 'components/BasicComponents/Icon/RoundedIcon';
import { TituloH3 } from 'components/BasicComponents/Titulos/Titulos';

import './PromotionBilleables.scss';
import PromotionBilleablesList from './components/PromotionBilleablesList/PromotionBilleablesList';

export const PromotionBilleables = () => {
    const [t] = useTranslation();

    return (
        <>
            <header className="PromotionBilleables__header">
                <RoundedIcon icon={<IconBuildingSkyscraper />} />
                <TituloH3 titulo={t('productsAndServices.enterpriseServices', { ns: 'newPromotion' })} />
            </header>

            <section className="PromotionBilleables__section">
                <PromotionBilleablesList scope="ENTERPRISE" purchased />
            </section>

            <header className="PromotionBilleables__header">
                <RoundedIcon icon={<IconBuildingCommunity />} />
                <TituloH3 titulo={t('productsAndServices.promotionServices', { ns: 'newPromotion' })} />
            </header>

            <section className="PromotionBilleables__section">
                <PromotionBilleablesList scope="PROMOTION" purchased />
            </section>

            <section className="PromotionBilleables__section">
                <PromotionBilleablesList scope="PROMOTION" />
            </section>
        </>
    );
};
