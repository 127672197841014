import { IconDownload } from '@tabler/icons';
import { FilterModel } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import { AgGridReact } from 'ag-grid-react';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import DropdownList from 'components/BasicComponents/DropdownList';
import Spinner from 'components/BasicComponents/Spinner/Spinner';
import TraceabilityRequestDIAction from 'features/traceabilities/components/TraceabilityRequestDIAction/TraceabilityRequestDIAction';
import TableFilters from 'lib/ag-grid/components/TableFilters';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { usePromotion } from 'modules/promotion';
import {
    useWasteRegisterApi,
    useWasteRegisterStore
} from 'modules/waste-register/infrastructure/react/wasteRegisterContext';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useWasteRegisterGrid } from './hooks/useWasteRegisterGrid';
import { useWasteRegisterTableExport } from './hooks/useWasteRegisterTableExport';
import { useWasteRegisterTableHeight } from './hooks/useWasteRegisterTableHeight';
import './WasteRegisterTable.scss';

const WasteRegisterTable = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [{ promotion }] = usePromotion();
    const [, analytics] = useAnalyticsStore();

    const [{ updated }, { loadWasteRegisterTableInfo }] = useWasteRegisterStore();
    const { data, loading, refetch } = useWasteRegisterApi.findComputedByCriteria({
        promotionIds: [promotion?.id || NaN]
    });

    const [headers, setHeaders] = useState<any>([]);
    const [translationObject, setTranslationObject] = useState<any>([]);
    const [gridState, setGridState] = useState<any>();

    const gridRef = useRef<AgGridReact>(null);
    const container = useWasteRegisterTableHeight();
    const { options } = useWasteRegisterGrid(headers);

    const translate = (key) => {
        if (!translationObject) return key;
        return translationObject[key] || key;
    };

    const { exportWasteRegisterAsCsv, exportWasteRegisterAsExcel } = useWasteRegisterTableExport({
        gridRef,
        customTranslateFn: translate
    });

    const loadTableInfo = async () => {
        if (promotion === null) return;

        const data = await loadWasteRegisterTableInfo(promotion.id);
        if (!data) return;

        setTranslationObject(data.translationObject);

        // Don't display id/uuid column (but we need the data to redirect to its detail)
        const headersExcludingId = data.headers.filter((header) => header.field !== 'id');
        setHeaders(headersExcludingId);
    };

    useEffect(() => {
        loadTableInfo();
        refetch();
    }, [promotion, JSON.stringify(Object.keys(updated))]);

    return (
        <div className="WasteRegisterTable">
            <div className="ag-theme-quartz" ref={container.ref}>
                <header className="WasteRegisterTable__header" id="WasteRegisterTable__header">
                    <TableFilters
                        i18nNamespace=""
                        customTranslateFn={translate}
                        filtersObject={(gridState?.filter?.filterModel as FilterModel) || undefined}
                        updateFilters={(updatedFilters) => gridRef.current?.api?.setFilterModel(updatedFilters)}
                    />

                    <div className="WasteRegisterTable__actions">
                        <DropdownList
                            items={[
                                <li
                                    role="button"
                                    onClick={() => {
                                        analytics.event(AnalyticsEvents.WASTE_REGISTER_TABLE_EXPORT_EXCEL);
                                        exportWasteRegisterAsExcel();
                                    }}
                                >
                                    {t('excelFormat')}
                                </li>,
                                <li
                                    role="button"
                                    onClick={() => {
                                        analytics.event(AnalyticsEvents.WASTE_REGISTER_TABLE_EXPORT_CSV);
                                        exportWasteRegisterAsCsv();
                                    }}
                                >
                                    {t('csvFormat')}
                                </li>
                            ]}
                        >
                            <SmallButton titulo={t('exportData')} icon={<IconDownload />} />
                        </DropdownList>

                        <TraceabilityRequestDIAction color="purple" />
                    </div>
                </header>
                {/* REQUIRED: applying the Data Grid theme */}
                <AgGridReact
                    ref={gridRef}
                    rowData={data || []}
                    onStateUpdated={(g) => setGridState(g.state)}
                    loadingOverlayComponent={() => <Spinner />}
                    loading={loading === 'pending'}
                    onRowClicked={(event) => history.push(`./table/${event?.data?.id}`)}
                    // getRowId={(row) => row.data?.id?.toString()}
                    {...options}
                />
            </div>
        </div>
    );
};

export default WasteRegisterTable;
