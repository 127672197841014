import { useState } from 'react';

import { SelloValorizaBilleable, usePromotionBilleables } from 'modules/billeables';
import { createSelloValorizaBilleable } from 'modules/billeables/domain/Billeable/SelloValorizaBilleable/createSelloValorizaBilleable';

export function useSelloValorizaBilleableForm(props: { selloValoriza: SelloValorizaBilleable }) {
    const [, { updateBilleable }] = usePromotionBilleables();
    const { selloValoriza } = props;

    const [values, setValues] = useState({
        addonId: selloValoriza.config.addon?.id.toString(),
        score: selloValoriza.config.score,
        valorizationTarget: selloValoriza.config.valorizationTarget
    });

    return {
        values,
        update: (name: string, value: any) => {
            const changes = { ...values, [name]: value };
            setValues(changes);

            const option = selloValoriza.options.addon.find((o) => o.value === parseInt(changes.addonId || '0'));
            if (!option) return;

            updateBilleable(
                createSelloValorizaBilleable({
                    addons: [],
                    score: changes.score,
                    valorizationTarget: changes.valorizationTarget,
                    // addon: selectedAddon
                    addon: {
                        id: option.value,
                        name: option.label,
                        purchased: true,
                        scope: 'PROMOTION'
                    }
                })
            );
        }
    };
}
