import { Reducers, createStore } from 'modules/shared/domain/store/createStore';
import { OrphanDocumentsStore } from '../domain/OrphanDocumentsStore';
import {
    loadEmailsWithOrphanDocuments,
    loadEmailsWithOrphanDocumentsReducers
} from './load/loadEmailsWithOrphanDocuments';
import { selectOrphanDocuments, selectOrphanDocumentsReducers } from './selection/selectOrphanDocuments';
import { unselectOrphanDocuments, unselectOrphanDocumentsReducers } from './selection/unselectOrphanDocuments';
import { assignOrphanDocuments, assignOrphanDocumentsReducers } from './actions/assignOrphanDocuments';
import { discardOrphanDocuments, discardOrphanDocumentsReducers } from './actions/discardOrphanDocuments';

const reducers: Reducers<OrphanDocumentsStore> = {
    ...loadEmailsWithOrphanDocumentsReducers,
    ...selectOrphanDocumentsReducers,
    ...unselectOrphanDocumentsReducers,
    ...assignOrphanDocumentsReducers,
    ...discardOrphanDocumentsReducers
};

export const orphanDocumentsStore = createStore({
    reducers,
    thunks: {
        loadEmailsWithOrphanDocuments,
        selectOrphanDocuments,
        unselectOrphanDocuments,
        assignOrphanDocuments,
        discardOrphanDocuments
    },
    initialState: {
        data: [],
        total: 0,
        selectedDocuments: [],
        loading: 'idle',
        error: null,
        criteria: {}
    }
});
