import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import ActionConfirm from 'components/BasicComponents/ActionConfirm/ActionConfirm';
import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import PromotionAutocomplete from 'components/BasicComponents/Select-Autocomplete/PromotionAutocomplete';
import { IconFileStack } from 'icons';

import './WallePanel.scss';
import { IconFileSearch } from '@tabler/icons';
import { useOrphanDocuments } from 'modules/orphan-documents';

const MAX_PREVIEWS_ALLOWED = 4;

const INITIAL_FORM_STATE = {
    promocion: null
};

const WallePanel = () => {
    const [{ selectedDocuments }, { assignOrphanDocuments, discardOrphanDocuments }] = useOrphanDocuments();
    const [values, setValues] = useState(INITIAL_FORM_STATE) as any;

    const [t] = useTranslation('walle');

    const discardAll = () => {
        discardOrphanDocuments();
        setValues(INITIAL_FORM_STATE);
    };

    const assignAll = () => {
        if (values.promocion) {
            assignOrphanDocuments({ promotionId: values.promocion.id, promotionName: values.promocion.nombre });
            setValues(INITIAL_FORM_STATE);
        }
    };

    const noSelected = selectedDocuments.length === 0;
    const maxDocumentsReached = selectedDocuments.length > MAX_PREVIEWS_ALLOWED;
    // reverse array without mutating original object
    const reversedDocuments = selectedDocuments.map((item, i) => selectedDocuments[selectedDocuments.length - 1 - i]);

    const documentsToShow = maxDocumentsReached
        ? reversedDocuments.slice(0, MAX_PREVIEWS_ALLOWED - 1)
        : reversedDocuments;

    const differentEmails = [...new Set(selectedDocuments.map((doc) => doc.emailId))];

    return (
        <div className="WallePanel">
            <SimpleCard className="WallePanel__container">
                <header className="WallePanel__actions">
                    <ActionConfirm
                        title={t(`panel.discard`)}
                        message={t(`panel.discardConfirm`)}
                        onConfirm={() => discardAll()}
                    >
                        <BasicButton
                            type="button"
                            color="red"
                            ghost
                            text={t('panel.discard')}
                            // action={discardAll} // utility of this??
                            disabled={noSelected}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                    </ActionConfirm>
                    <div className="WallePanel__assign">
                        <PromotionAutocomplete
                            hiddenLabel
                            placeholder={t('panel.selectPromotion')}
                            values={values}
                            setValues={setValues}
                            className={`WallePanel__promotion ${noSelected ? 'WallePanel__promotion--disabled' : ''}`}
                            isClearable={true}
                        />
                        <BasicButton
                            type="button"
                            color="purple"
                            text={t('panel.assign')}
                            action={assignAll}
                            disabled={noSelected || !values.promocion}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                    </div>
                </header>
                {documentsToShow.length === 0 ? (
                    <div className="WallePanel__empty">
                        <IconFileSearch size={56} />
                        <span className="WallePanel__empty-title">{t('panel.emptyTitle')}</span>
                        <span className="WallePanel__empty-msg">{t('panel.emptyMsg')}</span>
                    </div>
                ) : (
                    <div className="WallePanel__documents">
                        {documentsToShow.map((doc) => (
                            <iframe
                                key={doc?.extractionId}
                                className={`WallePanel__preview WallePanel__preview--${documentsToShow.length}`}
                                src={doc?.url}
                            />
                        ))}
                        {maxDocumentsReached && (
                            <div className="WallePanel__preview WallePanel__preview--more">
                                <IconFileStack size={48} />
                                <span>
                                    {t('panel.nMore', { count: selectedDocuments.length - MAX_PREVIEWS_ALLOWED + 1 })}
                                </span>
                            </div>
                        )}
                    </div>
                )}
                {selectedDocuments.length > 0 && (
                    <footer className="WallePanel__resume">
                        <span>
                            {selectedDocuments.length}{' '}
                            {selectedDocuments.length > 1
                                ? t('panel.selectedDocuments.pl')
                                : t('panel.selectedDocuments.sg')}
                        </span>
                        {differentEmails.length > 1 && (
                            <span>
                                {' '}
                                {t('panel.from')} {differentEmails.length} {t('panel.selectedEmails')}
                            </span>
                        )}
                    </footer>
                )}
            </SimpleCard>
        </div>
    );
};

export default WallePanel;
