import { AnalyticsApi } from 'modules/analytics/domain/AnalyticsApi';
import { AnalyticsStore } from 'modules/analytics/domain/AnalyticsStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Store = AnalyticsStore; // Define Store here

export const pageView =
    (...params: Parameters<AnalyticsApi['pageView']>): Thunk<Store> =>
    async (store, { api, storage }) => {
        try {
            // Only sends the pageView if the store is initialized
            if (store.state.initialized) {
                await api.pageView(...params);
            }
        } catch (e) {
            const error = e as Error;

            store.set((state) => {
                state.error = error;
            });
        }
    };
