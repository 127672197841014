import { IconBackhoe, IconChevronDown, IconChevronRight, IconChevronUp } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import wasteManagerServices from '../../../api/wasteManager/wasteManager.services';
import SmallButton from '../../../components/BasicComponents/Buttons/Small/SmallButton';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import RoundedIcon from '../../../components/BasicComponents/Icon/RoundedIcon';
import GestoraPopover from '../../../components/BasicComponents/Popovers/GestoraPopover';
import { withAuth } from '../../../context/AuthProvider';
import './Gestoras.scss';

const Gestoras = (props) => {
    const [t] = useTranslation();
    const { width, breakpoint } = props;
    const { promocion } = props;
    const [gestoras, setGestoras] = useState([]);
    const [selectedGestora, setSelectedGestora] = useState(null);
    const [gestoraLoading, setGestoraLoading] = useState(null);
    const [closedDropdown, setClosedDropdown] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!promocion) return;
        getGestoras(promocion.id);
    }, [promocion]);

    useEffect(() => {
        if (width <= breakpoint) {
            setClosedDropdown(true);
        } else {
            setClosedDropdown(false);
        }
    }, [width]);

    const togglePopover = async (gestoraId) => {
        setGestoraLoading(gestoraId);
        const data = await wasteManagerServices.getWasteManagerById(gestoraId);
        setGestoraLoading(null);

        if (!data) return;

        setSelectedGestora(data.content);
    };

    const getGestoras = async (promotionId) => {
        const result = await wasteManagerServices.getWasteManagers({ promotionId });

        if (result?.content) setGestoras(result.content);
        if (result instanceof Error) setError(result);
    };

    const enterprises = Object.entries(Object.groupBy(gestoras, (wm) => wm.enterprise.id));

    return (
        <SimpleCard
            className="gestora"
            title={t('manager.pl', { ns: 'common' })}
            tooltip={t('manager.hired', { ns: 'common' })}
            allowScroll={true}
        >
            {width <= breakpoint && (
                <SmallButton
                    action={() => setClosedDropdown(!closedDropdown)}
                    titulo={closedDropdown ? 'Mostrar mis gestoras' : 'Ocultar mis gestoras'}
                    className="usuario__button"
                    icon={
                        closedDropdown ? (
                            <IconChevronDown size={18} stroke={1.4} />
                        ) : (
                            <IconChevronUp size={18} stroke={1.4} />
                        )
                    }
                    iconPosition="right"
                />
            )}

            <ErrorCard error={error} />

            {!closedDropdown && (
                <div className={width <= breakpoint && 'gestora__container'}>
                    {enterprises?.map(([enterpriseId, gestoras], i) => {
                        const enterprise = gestoras[0].enterprise;
                        return (
                            <div key={enterpriseId} className="gestora__enterprise-group">
                                <div className="gestora__enterprise-group-name">
                                    {enterprise.name} - {enterprise.cif}
                                </div>

                                {gestoras?.map((gestora, i) => (
                                    <div key={i} className="gestora__row">
                                        <div>
                                            <RoundedIcon icon={<IconBackhoe size={24} stroke={1.1} />} />
                                            <span>{gestora?.name}</span>
                                        </div>
                                        <SmallButton
                                            titulo={t('show.showMore', { ns: 'common' })}
                                            action={() => togglePopover(gestora.id)}
                                            iconPosition="right"
                                            icon={<IconChevronRight size={18} stroke={1.4} />}
                                            isLoading={gestora.id === gestoraLoading}
                                        />
                                    </div>
                                ))}
                            </div>
                        );
                    })}

                    {gestoras.length === 0 && !error && <p>{t('noWastemanagers', { ns: 'common' })}</p>}

                    {selectedGestora && (
                        <GestoraPopover
                            openModal={!!selectedGestora}
                            setOpenModal={() => setSelectedGestora(null)}
                            gestora={selectedGestora}
                        />
                    )}
                </div>
            )}
        </SimpleCard>
    );
};

export default withAuth(Gestoras);
