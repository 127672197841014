import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';

import SpinnerTiny from 'components/BasicComponents/SpinnerTiny';
import promotionServices from '../../../api/promotion/promotion.services';
import { customStyles, hasMore } from '../../../components/BasicComponents/Select-Autocomplete/helpers';
import { withAuth } from '../../../context/AuthProvider';

const PromotionMultiSelect = ({
    propName = 'promocion',
    required,
    className,
    values,
    setValues,
    isClearable = false,
    enterprise
}) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);

    const removeSelectedPromotions = (arrOptions) => {
        let options = arrOptions;
        for (let i = 0; i < values[propName].length; i++) {
            for (let j = 0; j < arrOptions.length; j++) {
                if (values[propName][i].id === arrOptions[j].id) {
                    options = options.filter((option) => option.id !== arrOptions[j].id);
                }
            }
        }
        return options;
    };

    const findPromotions = async (params) => {
        const { enterpriseId, ...restParams } = params;
        const entries = Object.entries(restParams).filter(([, v]) => ![null, undefined, ''].includes(v));
        const qs = new URLSearchParams(entries).toString();

        const promotions = await promotionServices
            .filterPromotion(`?${qs}`)
            // filters client-side promotions by relatedEnterprises enterpriseId because the API doesn't support it
            .then((promotions) => {
                if (!enterpriseId) return promotions;
                return promotions.filter((p) => p.relatedEnterprises.some((e) => e.enterpriseId === enterpriseId));
            });

        return promotions;
    };

    const getLoadOptions = async (search, loadedOptions, { page }) => {
        const promotions = await findPromotions({
            'nombre.contains': search,
            enterpriseId: enterprise?.id,
            page: 0,
            size: 999
        });

        const options = removeSelectedPromotions(promotions);
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = async (item) => {
        setValues({ ...values, [propName]: item });
    };

    const key = () => {
        return JSON.stringify(values);
    };

    const findAndSetEnterprisePromotions = async ({ enterpriseId }) => {
        selectOption([]);
        setLoading(true);
        try {
            const promotions = await findPromotions({ enterpriseId, page: 0, size: 999 });
            selectOption(promotions);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            selectOption([]);
        }
    };

    const selected = values?.[propName] || [];

    // automatically load promotions when enterprise changes
    useEffect(() => {
        if (!enterprise.id) {
            selectOption([]);
            return;
        }
        findAndSetEnterprisePromotions({ enterpriseId: enterprise.id });
    }, [enterprise?.id]);

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {t('promotion.upperCase', { ns: 'common' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                key={key()}
                loadingMessage={() => `Recuperando proyectos, puede tardar unos segundos...`}
                loadOptions={getLoadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.nombre}
                getOptionValue={(option) => option}
                value={values[propName]}
                styles={customStyles()}
                placeholder={
                    (loading && (
                        <div style={{ display: 'flex', gap: '0.8rem' }}>
                            <SpinnerTiny />
                            Recuperando proyectos de {enterprise.nombreComercial}, puede tardar unos segundos...
                        </div>
                    )) ||
                    (enterprise && selected.length === 0 && !loading && (
                        <div>No se han encontrado proyectos para {enterprise.nombreComercial}</div>
                    )) ||
                    ''
                }
                additional={{
                    page: 0
                }}
                isDisabled={loading}
                isClearable={isClearable}
                isMulti={true}
            />
        </div>
    );
};
export default withAuth(PromotionMultiSelect);
