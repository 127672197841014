import { useState, useEffect, useContext } from 'react';
import { withAuth } from '../../../context/AuthProvider';
import { getLersSelected, getPgclsAsCarrier, matchPgcls } from '../helpers/waste_manager.helpers';
import { NewPpgclContext } from './NewPpgclStepper';
import { Option } from './Option';
import './WasteManagerResult.scss';

const WasteManagerResult = ({
    wasteManager,
    checkboxHandleClick,
    openModal = false,
    wasteManagersChecked,
    completePpgcl,
    transferOperator
}) => {
    const { newPpgcls } = useContext(NewPpgclContext);
    const [showOptions, setShowOptions] = useState(false);
    const [options, setOptions] = useState([]);

    const checked = wasteManagersChecked?.find((wasteManagerChecked) => wasteManagerChecked.id === wasteManager.id);

    const checkedTransfer = () => {
        if (
            wasteManagersChecked?.some(
                (transfer) =>
                    transfer.id === completePpgcl?.id && transfer?.transferOperatorWasteManagerId === wasteManager.id
            )
        ) {
            return true;
        } else {
            return false;
        }
    };

    const onChange = () => {
        if (openModal) {
            checkboxHandleClick(wasteManager);
            setShowOptions(!showOptions);
        } else {
            checkboxHandleClick(wasteManager);
        }
    };

    const search = async () => {
        const filter = `&gestoraId.equals=${wasteManager.id}&codLER.in=${getLersSelected(newPpgcls)}`;
        const pgclsAsCarriers = await getPgclsAsCarrier(filter);
        if (pgclsAsCarriers.length > 0) {
            const filterOptions = matchPgcls(pgclsAsCarriers, newPpgcls);
            setOptions(filterOptions);
        }
    };
    useEffect(() => {
        if (showOptions) search();
    }, [showOptions]);

    useEffect(() => {
        if (checked && openModal) setShowOptions(true);
    }, []);

    return (
        <>
            <li className="wasteManagerResult">
                <input
                    type="checkbox"
                    onChange={onChange}
                    checked={transferOperator ? checkedTransfer() : checked}
                    className="checkbox wasteManagerResult__checkbox"
                />
                <div>
                    <h5>{wasteManager.empresa}</h5>
                    <p>
                        {wasteManager.nIMA} - {wasteManager.identificacionFiscal} -{' '}
                        {wasteManager.direccions[0]?.municipio.nombre}
                    </p>
                </div>
            </li>
            {showOptions &&
                options.length > 0 &&
                options.map((option) => <Option key={option.ppgcl.id} {...{ option, wasteManager }} />)}
        </>
    );
};

export default withAuth(WasteManagerResult);
