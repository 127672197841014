import { TraceabilitySaverExtended } from 'modules/traceabilities';
import { TraceabilitySaver } from '../Traceability/TraceabilitySaver';

export const getEmptyTraceabilityToEdit = (): TraceabilitySaverExtended => {
    const emptyTraceabilityToEdit: TraceabilitySaverExtended = {
        ...getEmptyTraceabilityForm(),
        extra: null,
        wasteRegisterId: null,
        di: null
    };

    return emptyTraceabilityToEdit;
};

export const getEmptyTraceabilityForm = (): TraceabilitySaver => {
    const emptyTraceability: TraceabilitySaver = {
        id: undefined,
        ppgcl: null,
        date: new Date().toISOString().split('T')[0],
        numDi: '',
        weight: null,
        cost: null,
        volume: null,
        vehiclePlate: null,
        incomplete: false,
        observations: ''
    };

    return emptyTraceability;
};
