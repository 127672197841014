import { useContext, useEffect, useState } from 'react';
import { IconAlertTriangle, IconCheck } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import { getPpgcl } from '../api/getPpgcl';
import SubmitButton from '../../../components/BasicComponents/Buttons/SubmitButton';
import { getTodayDate } from '../../../utils/helpers/dates.helpers';
import { createMassivePpgcls } from '../api/createMassivePpgcls';
import { PpgclContext } from '../../../components/ComplexComponents/WasteManagerDashboard/CardPpgclSettings';
import PpgclFinal from './PpgclFinal';
import { NewPpgclContext } from './NewPpgclStepper';
import './FinalStep.scss';

const FinalStep = ({ promocion }) => {
    const [ppgclsToCreate, setPpgclsToCreate] = useState([]);

    const {
        newPpgcls,
        setNewPpgcls,
        wasteManagersChecked,
        carriersChecked,
        finishForm,
        setWasteManagersChecked,
        setCarriersChecked
    } = useContext(NewPpgclContext);
    const { setAllPpgcl, allPpgcl } = useContext(PpgclContext);

    const filterNewPpgcls = newPpgcls.filter(
        (newPpgcl) => newPpgcl.wasteManagerProGesCodLerId && newPpgcl.carrierProGesCodLerId && newPpgcl.volumenUnitario
    );

    const checkIfExists = async () => {
        let ppgclsChecked = [];
        if (filterNewPpgcls.length > 0) {
            for (let i = 0; i < filterNewPpgcls.length; i++) {
                const filter = `?gestoraId.equals=${filterNewPpgcls[i].ppgcl.procesoGestora.gestora.id}&promocionId.in=${promocion.id}&carrierId.equals=${filterNewPpgcls[i].carrier.procesoGestora.gestora.id}&codLer.equals=${filterNewPpgcls[i].ppgcl.codLER.codigoLER}`;
                const checkIfExists = await getPpgcl(filter);
                checkIfExists.data.length === 0
                    ? (filterNewPpgcls[i] = { ...filterNewPpgcls[i], exists: false })
                    : (filterNewPpgcls[i] = { ...filterNewPpgcls[i], exists: true });
                ppgclsChecked.push(filterNewPpgcls[i]);
            }
        }
        setPpgclsToCreate(ppgclsChecked);
    };

    useEffect(() => {
        checkIfExists();
    }, []);

    const createNewPpgcl = async () => {
        let newPpgcls = [];
        const ppgclsNotExists = ppgclsToCreate.filter((ppgcl) => ppgcl.exists === false);
        if (ppgclsNotExists.length > 0) {
            for (let i = 0; i < ppgclsNotExists.length; i++) {
                delete ppgclsNotExists[i].ppgcl;
                delete ppgclsNotExists[i].carrier;
                delete ppgclsNotExists[i].exists;
                delete ppgclsNotExists[i].id;
                ppgclsNotExists[i].fechaPresupuesto = getTodayDate();
                ppgclsNotExists[i].promocionId = promocion.id;
                newPpgcls.push(ppgclsNotExists[i]);
            }
            const createNewsPpgcls = await createMassivePpgcls(newPpgcls);
            if (createNewsPpgcls.status === 201) {
                setAllPpgcl(allPpgcl.concat(createNewsPpgcls.data));
                setTimeout(() => {
                    finishForm();
                }, 2000);
            }
        }
    };

    const cleanPpgcls = (ppgclToDelete) => {
        const { gestora } = ppgclToDelete.ppgcl.procesoGestora;
        if (!wasteManagersChecked.some((wasteManagerChecked) => gestora.id === wasteManagerChecked.id)) {
            setWasteManagersChecked(
                wasteManagersChecked.filter((wasteManagerChecked) => wasteManagerChecked.id !== gestora.id)
            );
        }
        if (
            !carriersChecked.some(
                (carrierChecked) => ppgclToDelete.carrier.procesoGestora.gestora.id === carrierChecked.id
            )
        ) {
            setCarriersChecked(
                carriersChecked.filter(
                    (carrierChecked) => carrierChecked.id !== ppgclToDelete.carrier.procesoGestora.gestora.id
                )
            );
        }
    };

    const deleteOption = (ppgclToDelete) => {
        setPpgclsToCreate(ppgclsToCreate.filter((newPpgcl) => newPpgcl.id !== ppgclToDelete.id));
        setNewPpgcls(ppgclsToCreate.filter((newPpgcl) => newPpgcl.id !== ppgclToDelete.id));
        cleanPpgcls(ppgclToDelete);
    };

    const allPpgclsNotExists = ppgclsToCreate?.every((ppgcl) => ppgcl.exists === false);
    const allHasTransferOperator = ppgclsToCreate?.every((ppgcl) => ppgcl.transferOperatorTypeId !== undefined);
    const canCreate = allPpgclsNotExists && allHasTransferOperator;

    return (
        <>
            {ppgclsToCreate.length === 0 && (
                <p className="finalPpgcl__message">No se ha encontrado ningún flujo con la información completa</p>
            )}
            {ppgclsToCreate.filter((ppgcl) => ppgcl.exists === false).length > 0 && (
                <ul className="finalPpgcl__ul">
                    <p className="finalPpgcl__message finalPpgcl__messageCreate">
                        <IconCheck stroke={1.4} />
                        Se van a crear los siguientes flujos:
                    </p>
                    {ppgclsToCreate
                        .filter((ppgcl) => ppgcl.exists === false)
                        .map((ppgclNotExist) => (
                            <PpgclFinal key={ppgclNotExist.id} ppgcl={ppgclNotExist} deleteOption={deleteOption} />
                        ))}
                </ul>
            )}
            {ppgclsToCreate.filter((ppgcl) => ppgcl.exists === true).length > 0 && (
                <ul className="finalPpgcl__ul">
                    <p className="finalPpgcl__message finalPpgcl__messageExists">
                        <IconAlertTriangle stroke={1.4} />
                        Los siguientes flujos no se van a crear porque ya existen:
                    </p>
                    {ppgclsToCreate
                        .filter((ppgcl) => ppgcl.exists === true)
                        .map((ppglExists) => (
                            <PpgclFinal key={ppglExists.ppgcl.id} ppgcl={ppglExists} />
                        ))}
                </ul>
            )}
            {!allHasTransferOperator && (
                <ul className="finalPpgcl__ul">
                    <p className="finalPpgcl__message finalPpgcl__messageExists">
                        <IconAlertTriangle stroke={1.4} /> Hay flujos sin operador de traslado seleccionado.
                    </p>
                </ul>
            )}
            {ppgclsToCreate.length !== 0 && (
                <SubmitButton onClickButton={createNewPpgcl} text="Crear flujos" buttonDisabled={!canCreate} />
            )}
        </>
    );
};

export default withAuth(FinalStep);
