export const codigosLer = [
    {
        id: 2950,
        codLer: 170504,
        descripcion: 'Tierra y piedras',
        color: '#273648'
    },
    {
        id: 1900,
        codLer: 170201,
        descripcion: 'Madera',
        color: '#4d5a64'
    },
    {
        id: 2650,
        codLer: 170407,
        descripcion: 'Metales mezclados',
        color: '#79838b'
    },
    {
        id: 6201,
        codLer: 150101,
        descripcion: 'Envases de papel y cartón',
        color: '#90999e'
    },
    {
        id: 2000,
        codLer: 170203,
        descripcion: 'Plástico',
        color: '#a5adb1'
    },
    {
        id: 3550,
        codLer: 170802,
        descripcion: 'Yeso',
        color: '#bcc1c5'
    },
    {
        id: 1800,
        codLer: 170107,
        descripcion: 'Mezclas de hormigón, ladrillos, tejas y materiales cerámicos',
        color: '#d3d6d8'
    },
    {
        id: 0,
        codLer: 'Resto',
        descripcion: 'Resto',
        color: '#e9eaec'
    }
];
export const NO_DATA = 'No se han encontrado datos';
export const LOADING = 'Cargando datos...';
export const SOIL_WASTE = ['1705', '170503', '170504', '200202', '020401', '191302'];
export const lerNoDesglosado = 65900;
export const padding = [
    {
        length: 1,
        padding: 0.85
    },
    {
        length: 2,
        padding: 0.75
    },
    {
        length: 3,
        padding: 0.65
    },
    {
        length: 4,
        padding: 0.6
    },
    {
        length: 5,
        padding: 0.55
    },
    {
        length: 6,
        padding: 0.5
    },
    {
        length: 7,
        padding: 0.45
    },
    {
        length: 8,
        padding: 0.4
    },
    {
        length: 9,
        padding: 0.4
    },
    {
        length: 10,
        padding: 0.4
    },
    {
        length: 11,
        padding: 0.4
    },
    {
        length: 12,
        padding: 0.4
    },
    {
        length: 13,
        padding: 0.4
    },
    {
        length: 14,
        padding: 0.4
    },
    {
        length: 15,
        padding: 0.4
    },
    {
        length: 16,
        padding: 0.4
    },
    {
        length: 17,
        padding: 0.4
    },
    {
        length: 18,
        padding: 0.4
    },
    {
        length: 19,
        padding: 0.4
    },
    {
        length: 20,
        padding: 0.4
    }
];

export const transportistas = {
    id: 5651,
    descripcion: 'gestoraTransportista'
};

export const estadoGestora = [
    {
        id: 1,
        descripcion: 'migrado',
        color: 'rojo'
    },
    {
        id: 2,
        descripcion: 'descubierto',
        color: 'rojo'
    },
    {
        id: 3,
        descripcion: 'enProceso',
        color: 'amarillo'
    },
    {
        id: 4,
        descripcion: 'operativa',
        color: 'verde'
    },
    {
        id: 5,
        descripcion: 'baja',
        color: 'amarillo'
    },
    {
        descripcion: 'sinEstado',
        color: 'negro'
    }
];

export const tipoActividad = [
    {
        descripcion: 'resumenEjecutivo',
        id: 385751
    },
    {
        descripcion: 'conclusionesFinales',
        id: 365253
    }
];

export const COLORS_GRAPHICS = {
    Otros: '#D2E0FB',
    Hormigón: '#ADC4CE',
    'Fracciones minerales': '#B4BDFF',
    Madera: '#FFCF96',
    Metales: '#9AD0C2',
    'Papel / Cartón': '#B99470',
    Plástico: '#fff091',
    Cerámicos: '#D6C7AE',
    Yeso: '#DC8686',
    Aislamientos: '#EAD1DC',
    Mezclas: '#9E9FA5',
    Absorbentes: '#E14790',
    'Tierra y materiales naturales excavados': '#A64B2A',
    Bituminosos: '#9E6F21',
    'Envases contaminados': '#FF6D28',
    'Aerosoles/Envases a presión': '#CC3333',
    'Pintura y barniz': '#FDF675',
    Vidrio: '#739072',
    'Tierra, lodos o materiales excavados contaminados': '#8E3200',
    'RSU orgánico': '#b5ab9a',
    'RSU vidrio': '#9c9587',
    'RSU papel': '#b5a688',
    'RSU envases': '#a69980',
    'Otros peligrosos': '#F900BF',
    'RSU fracción resto': '#96896e',
    Aceites: '#982176',
    'Residuos y fibras vegetales': '#C3EDC0',
    'Toners de impresión': '#FFB84C',
    'Equipos eléctricos y electrónicos peligrosos': '#EB5F54',
    'Tubos Fluorescentes': '#F9ED30',
    'Paneles Solares Fotovoltaicos': '#9EB384'
};

export const DIRECTIONS_TYPE_ID = {
    PLANTA: 5701,
    FISCAL: 5301
};
