import { createFilterWithObject } from '../../../utils/helpers/general.helpers';
import { getCarriers } from '../api/getCarriers';
import { getPgclsFilterAsCarrier } from '../api/getPgclsFilterAsCarrier';
import { getPgclsFilterAsWasteManager } from '../api/getPgclsFilterAsWasteManager';
import { getTransferOperators } from '../api/getTransferOperators';
import { getWasteManagerPgcl } from '../api/getWasteManagerPgcl';

export const INITIAL_STATE_STAKEHOLDER_SEARCH = {
    name: '',
    nima: '',
    cif: '',
    province: ''
};

export const getPgcls = async (filter = '') => {
    const getWasteManagersPgcl = await getPgclsFilterAsWasteManager(filter);
    return getWasteManagersPgcl.data.content.valorizationProcess;
};

export const getWasteManagersByPgcl = async (filter = '') => {
    const getWasteManagersPgcl = await getWasteManagerPgcl(filter);
    return getWasteManagersPgcl.data;
};

export const getPgclsAsCarrier = async (filter = '') => {
    const getWasteManagersPgcl = await getPgclsFilterAsCarrier(filter);
    return getWasteManagersPgcl.data.content.valorizationProcess;
};

export const getTransferOperatorByPgcl = async (filter = '') => {
    const transferOperators = await getTransferOperators(filter);
    return transferOperators.data;
};

export const getCarriersByPgcl = async (filter = '') => {
    const getCarriersPpgcl = await getCarriers(filter);
    return getCarriersPpgcl.data;
};

export const getLersSelected = (newPpgcls) => {
    const lersSelected = newPpgcls.map((newLer) => newLer.ppgcl.codLER.codigoLER);
    return [...new Set(lersSelected)];
};

export const matchPgcls = (arr1, newPpgcls) => {
    let matchPgcls = [];
    for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < newPpgcls.length; j++) {
            if (arr1[i].codLER.id === newPpgcls[j].ppgcl.codLER.id) {
                matchPgcls.push(newPpgcls[j]);
            }
        }
    }
    return matchPgcls;
};

const _getObjFilterWasteManagers = (searchValues) => {
    return {
        name: `&empresa.contains=${searchValues.name}`,
        nima: `&nIMA.equals=${searchValues.nima}`,
        cif: `&identificacionFiscal.equals=${searchValues.cif}`,
        province: `&provinceId.equals=${searchValues.province.id}`
    };
};

export const getFilter = (searchValues) => {
    const addFilter = createFilterWithObject(searchValues, _getObjFilterWasteManagers(searchValues));
    return addFilter;
};

const _getObjFilterLers = (searchValues) => {
    return {
        lerType: `&lerType.equals=${searchValues.lerType.lerKind}`,
        codLER: `&codLERId.equals=${searchValues.codLER.id}`
    };
};

export const getFilterLer = (searchValues) => {
    const addFilter = createFilterWithObject(searchValues, _getObjFilterLers(searchValues));
    return addFilter;
};

const _getObjFilterTransferOperator = (searchValues) => {
    return {
        name: `&empresa.contains=${searchValues.name}`,
        nima: `&nIMA.equals=${searchValues.nima}`,
        cif: `&identificacionFiscal.equals=${searchValues.cif}`
    };
};
export const getFilterTransferOperator = (searchValues) => {
    const addFilter = createFilterWithObject(searchValues, _getObjFilterTransferOperator(searchValues));
    return addFilter;
};

export const codeTypeTransferOperator = {
    PROMOTION: 1,
    /** @deprecated - cocircular treated as another manager */
    // COCIRCULAR: 2,
    SAME_MANAGER: 3,
    ANOTHER_MANAGER: 4
};
export const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
};
