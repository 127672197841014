import fetcher from '../../../lib/fetcher';

export const getDocumentTypeById = async (id) => {
    const URL = `/api/document-type?id.equals=${id}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
    }
};
