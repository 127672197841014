import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';

import { legacyEnterpriseAdapter } from 'modules/enterprise/related-enterprise/domain/legacyEnterpriseAdapter';
import enterpriseServices from '../../../api/enterprise/enterprise.services';
import { withAuth } from '../../../context/AuthProvider';
import { customStyles, hasMore } from './helpers';

const EnterpriseAutocomplete = ({
    propName = 'promocion',
    required,
    className,
    values,
    setValues,
    isClearable = false,
    INITIAL_STATE = '',
    promotion
}) => {
    const [t] = useTranslation();

    const getLoadOptions = async (search, loadedOptions, { page }) => {
        let filter = `?page=${page}`;
        let options;
        if (promotion?.id) {
            const mainEnterprise = legacyEnterpriseAdapter(promotion?.relatedEnterprises);
            options = [mainEnterprise];
        } else if (search) {
            options = await enterpriseServices.filterEnterprise(`?nombreComercial.contains=${search}`);
        } else {
            options = await enterpriseServices.filterEnterprise(filter);
        }
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        if (item) {
            setValues({ ...values, [propName]: item });
        } else {
            setValues({ ...values, [propName]: INITIAL_STATE });
        }
    };

    const key = () => {
        return JSON.stringify(values);
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {t('enterprise', { ns: 'common' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                key={key()}
                loadOptions={getLoadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.nombreComercial}
                placeholder=""
                value={values[propName]}
                additional={{
                    page: 0
                }}
                filterOption={false}
                styles={customStyles()}
                isClearable={isClearable}
            />
        </div>
    );
};
export default withAuth(EnterpriseAutocomplete);
