import { PropsWithChildren, ReactElement, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';

import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { useAuth } from 'modules/auth';
import { usePromotion } from 'modules/promotion';
import { ROLES } from 'utils/constants/roles.constants';

function TraceabilityRequestDIAction(props: PropsWithChildren<{ color?: string }>) {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();
    const [{ authorities }] = useAuth();

    const noNima = !promotion?.nima;
    const hasPermissions = authorities.includes(ROLES.writer) || authorities.includes(ROLES.admin);

    const isDisabled = (noNima && { value: true, reason: 'promotionWithoutNima' }) ||
        (!hasPermissions && { value: true, reason: 'requestWriterPermissions' }) || { value: false, reason: null };

    const msg = t(`disableReasons.${isDisabled.reason}`, { ns: 'wasteRegister', defaultValue: null });
    const to = `/promotion/${promotion?.id}/traceabilities/waste-register`;

    return (
        <Tooltip msg={msg}>
            {props.children ? (
                cloneElement(props.children as ReactElement<any>, {
                    disabled: isDisabled.value,
                    to
                })
            ) : (
                <CreateButton
                    color={props.color}
                    text={t('registerDI.button', { ns: 'wasteRegister' })}
                    to={to}
                    state={{ openCreatePanel: true }}
                    icon={null as any}
                    disabled={isDisabled.value}
                />
            )}
        </Tooltip>
    );
}

export default TraceabilityRequestDIAction;
