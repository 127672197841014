import { RefObject } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useFiltersTranslation } from 'lib/ag-grid/hooks/useFiltersTranslation';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { usePromotion } from 'modules/promotion';
import { useExcelExport } from 'lib/ag-grid/hooks/useExcelExport';

interface UseWasteRegisterTableExport {
    gridRef: RefObject<AgGridReact>;
    customTranslateFn: (key) => string;
}

export function useWasteRegisterTableExport({ gridRef, customTranslateFn }: UseWasteRegisterTableExport) {
    const { format } = useDateFormatter();
    const [{ promotion }] = usePromotion();
    const { getFiltersString } = useFiltersTranslation({ i18nNamespace: 'wasteRegister', customTranslateFn });
    const { exportAsExcel } = useExcelExport();

    if (!gridRef?.current?.api) return { exportWasteRegisterAsCsv: () => {}, exportWasteRegisterAsExcel: () => {} };

    const currentTime = format(new Date(), 'YYYY/MM/DD_HH:mm');
    const fileName = 'Registros--' + promotion?.nombre + '--' + currentTime;

    // Filters
    const filtersString = getFiltersString(gridRef.current.api.getFilterModel());
    const filtersInOneLine = filtersString.replaceAll('\n', ', '); // Avoid rows overlap

    const prependString = `Fecha y hora de descarga: ${currentTime}. Promoción: ${promotion?.nombre}. ${filtersInOneLine}`;

    const exportWasteRegisterAsCsv = () => {
        gridRef.current?.api.exportDataAsCsv({
            allColumns: true,
            fileName,
            prependContent: prependString,
            processCellCallback: (params) => {
                return params.value;
            }
        });
    };

    const exportWasteRegisterAsExcel = async () => {
        exportAsExcel({ fileName, gridRef, prependString, worksheetName: 'Registros' });
    };

    return { exportWasteRegisterAsCsv, exportWasteRegisterAsExcel };
}
