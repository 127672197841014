import { PropsWithChildren } from 'react';

import { withAuth } from 'context/AuthProvider';

import { useAdminBootstrap } from './hooks/useAdminBootstrap';
import { useAuthBootstrap } from './hooks/useAuthBootstrap';
import { useAnalyticsBootstrap } from './hooks/useAnalyticsBootstrap';

// Used to initialize stores or perform data loading at application startup
const AppBootstrap = (props: PropsWithChildren<any>) => {
    // Loads auth data
    const { auth } = useAuthBootstrap();

    // Loads Admin specific data
    useAdminBootstrap(props);

    useAnalyticsBootstrap();

    if (auth.isLoading) return null;

    return <>{props.children}</>;
};

export default withAuth(AppBootstrap);
