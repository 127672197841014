import { Controller, FieldValues } from 'react-hook-form';

import TextArea from 'components/BasicComponents/Inputs/TextArea';
import { FormControlProps } from './shared/FormControlProps';
import { useErrorMessage } from './shared/useErrorMessage';

type TextareaProps = {
    label?: string;
    append?: React.ReactNode;
};

type TextareaFormControlProps<Values extends FieldValues> = TextareaProps &
    React.TextareaHTMLAttributes<HTMLTextAreaElement> &
    FormControlProps<Values>;

const TextareaFormControl = <Values extends FieldValues>(props: TextareaFormControlProps<Values>) => {
    const { control, name, label, append, ...attributes } = props;
    const { errorMsg } = useErrorMessage(props);

    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field }) => (
                <TextArea
                    {...attributes}
                    label={props.label}
                    inputContainer="input__textarea  input__container"
                    name={field.name}
                    value={field.value}
                    onChange={((value: string) => field.onChange(value)) as any}
                    validacion={!!errorMsg}
                    required={!!errorMsg}
                    errorMsg={errorMsg}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            )}
        />
    );
};

export default TextareaFormControl;
