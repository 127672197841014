import fetcher from 'lib/fetcher';

import { DocumentApi } from 'modules/document/domain/DocumentApi';

export const httpDocumentApi: DocumentApi = {
    async findDocumentById({ id, promotionId }) {
        if (!id || !promotionId) return null;
        const response = await fetcher.get(`/api/documents/promotion/${promotionId}?id.equals=${id}`);
        const document = response?.data?.content?.documents?.[0];
        if (!document) return null;

        return document;
    },

    async uploadDocument(payload) {
        const params = {
            date: new Date().toISOString().split('T')[0],
            docsArray: [{ bytes: payload.file.bytes, name: payload.file.name, mimeType: payload.file.type }],
            promotionId: payload.promotionId,
            type: payload.type
        };

        const { data } = await fetcher.post('/api/tracing/documents', params);

        const id: number | undefined = data?.content?.ids?.[0];

        if (payload.links?.traceabilityId) {
            await fetcher.put('/api/tracing/associate-traceability', {
                documentId: id,
                traceabilityIds: [payload.links.traceabilityId]
            });
        }

        return id ? { id } : undefined;
    },

    async removeDocument({ id }) {
        await fetcher.delete(`/api/tracing/documents/${id}`);
    }
};
