import { useEffect, useState } from 'react';

interface UseStateFromStorageProps<S extends any> {
    key: string;
    initialState?: S;
    /** @default localStorage */
    storage?: Storage;
}

export default function useStateFromStorage<S extends any>({
    key,
    initialState,
    storage = localStorage
}: UseStateFromStorageProps<S>) {
    const storageValue = storage.getItem(key) as S;
    const initialValue =
        (storageValue === null && initialState) ||
        (storageValue === 'true' && true) ||
        (storageValue !== 'false' && storageValue) ||
        (storageValue === 'false' && false);

    const [state, setState] = useState<S>(initialValue as S);

    useEffect(() => {
        updateStorage();
    }, [JSON.stringify(state)]);

    const setStateWithStorageSave = (value: S) => {
        setState(value);
    };

    const updateStorage = () => {
        if (state === null || state === undefined) {
            storage.removeItem(key);
            return;
        }

        if (typeof state === 'boolean') {
            storage.setItem(key, state ? 'true' : 'false');
            return;
        }

        storage.setItem(key, `${state}`);
    };

    return [state, setStateWithStorageSave] as [typeof state, typeof setStateWithStorageSave];
}
