import { actions } from '../actions';
import { getDocumentsByType } from '../../utils/getDocumentsByType';
import { createFilteredAndSortedDocumentTypes } from '../domain/createFilteredAndSortedDocumentTypes';
import { createDocumentTypesBySection } from '../domain/createDocumentTypesBySection';
import { createPermanentDocuments } from '../domain/createPermanentDocuments';
import { createFilteredBuildingDocuments } from '../domain/createFilteredBuildingDocuments';

export const loadDocumentTypes =
    ({ dispatch }) =>
    async (promotion) => {
        try {
            dispatch({ type: actions.pending });

            // A little faster way to do API calls, so each line doesn't await to the previous one. Promise.all allows parallel function calls
            let [buildingDocs, trackingDocs, wasteManagerDocs] = await Promise.all([
                getDocumentsByType(promotion.id, 'building'),
                getDocumentsByType(promotion.id, 'tracking'),
                getDocumentsByType(promotion.id, 'waste-management')
            ]);

            const promotionTypeId = promotion.promotionType.id;
            let filteredBuildingDocs = createFilteredBuildingDocuments(buildingDocs);
            let buildingDocsWithPermanentDocs = createPermanentDocuments(filteredBuildingDocs, promotionTypeId);

            buildingDocsWithPermanentDocs = createFilteredAndSortedDocumentTypes(buildingDocsWithPermanentDocs);
            trackingDocs = createFilteredAndSortedDocumentTypes(trackingDocs);
            wasteManagerDocs = createFilteredAndSortedDocumentTypes(wasteManagerDocs);

            const documentTypesBySection = createDocumentTypesBySection({
                buildingDocs: buildingDocsWithPermanentDocs,
                trackingDocs,
                wasteManagerDocs
            });

            dispatch({ type: actions.fulfilled, payload: { documentTypesBySection } });
        } catch (error) {
            console.log(error);
            dispatch({ type: actions.rejected, payload: { message: error.message } });
        }
    };
