import './GlobalSettings.scss';

// eslint-disable-next-line import/named
import { IconTag, IconUser, TablerIconProps } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import SettingsLayout from 'components/Layouts/SettingsLayout';
import PrivateRoute from 'components/routes/PrivateRoute';
import TagManager from 'features/tags/components/TagManager';
import Layout from 'pages/Layout/Layout';
import PromotionSettings from 'pages/PromotionSettings/PromotionSettings';
import './GlobalSettings.scss';
import { useAuth } from 'modules/auth';
import { hasPermission } from 'utils/helpers/roles.helpers';

const GlobalSettings = () => {
    const [t] = useTranslation('globalSettings');
    const root = useRouteMatch() as unknown as { url: string };
    const location = useLocation();
    const [account] = useAuth();

    const iconProps: TablerIconProps = {
        size: 20,
        stroke: 1.75
    };

    const routes = [
        {
            name: t('sections.users.name'),
            icon: <IconUser {...iconProps} />,
            to: `${root.url}/users`,
            requiredPermission: 'canView',
            section: 'users',
            // users management component
            detail: PromotionSettings
        },
        {
            name: t('sections.tags.name'),
            icon: <IconTag {...iconProps} />,
            to: `${root.url}/tags`,
            requiredPermission: 'canCreate',
            section: 'tags',
            // users management component
            detail: TagManager
        }
    ];

    const active = routes.find(({ to }) => location.pathname.startsWith(to))?.name;
    const visibleRoutes = routes.filter((route) =>
        hasPermission(route.section, route.requiredPermission, account.user)
    );

    return (
        <Layout>
            <SettingsLayout className="GlobalSettings" links={visibleRoutes} active={active}>
                <Switch>
                    {routes.map(({ to, detail, ...rest }) => (
                        <PrivateRoute key={to} path={to} component={detail} {...rest} />
                    ))}
                    <Redirect to={routes[0].to} />
                </Switch>
            </SettingsLayout>
        </Layout>
    );
};

export default GlobalSettings;
