import { nonUploadeableDocTypesIds } from 'features/documentation/components/store/domain/constants/documentation.constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { withAuth } from '../../../context/AuthProvider';
import { getAllTypeDocuments } from '../../../features/documentation/api/getAllTypeDocuments';
import { getTypeDocuments } from '../../../features/documentation/api/getTypeDocuments';
import { customStyles, hasMore } from './helpers';

const DocumentTypeSelectAutocomplete = (props) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState(false);
    const { setValues, value, values, required, className, limitedOptionsIds, propName = 'type' } = props;

    const loadOptionsTipoDocumento = async (search, loadedOptions, { page }) => {
        let docs = [];
        let filter = `?`;
        if (search !== undefined && search !== '') {
            filter += `tipoDesc.contains=${search}`;
        }

        if (limitedOptionsIds !== undefined) {
            const docsRequest = await getAllTypeDocuments(filter);
            if (docsRequest?.data !== undefined) {
                docs = docsRequest?.data?.filter((doc) => limitedOptionsIds.includes(doc.id));
            }
        } else {
            const docsRequest = await getTypeDocuments(props.promocion?.id, filter);
            if (docsRequest?.data?.code == 0) {
                docs = docsRequest?.data?.content;
                docs = docs.filter((doc) => !nonUploadeableDocTypesIds.includes(doc.id));
            }
        }

        return {
            options: docs,
            hasMore: hasMore(docs),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOptionDocumentType = (item) => {
        setValues({ ...values, [propName]: item });
    };

    useEffect(() => {
        emptyInput();
    }, [props.validacion]);

    useEffect(() => {
        emptyInput();
    }, [props.value]);

    const emptyInput = () => {
        if (props.validacion === true && required && value === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {t('type', { ns: 'documentation' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                key={JSON.stringify(value)} // refresh options when doctype changes (when a doc is created and we reset everything)
                loadOptions={loadOptionsTipoDocumento}
                onChange={selectOptionDocumentType}
                getOptionLabel={(option) => option.name || option.descripcion}
                getOptionValue={(option) => option}
                value={value}
                styles={customStyles(notComplete)}
                placeholder=""
                additional={{
                    page: 0
                }}
            />
        </div>
    );
};
export default withAuth(DocumentTypeSelectAutocomplete);
