/* eslint-disable react/display-name */
import React, { useContext } from 'react';

import { useAuth } from 'modules/auth';

const LegacyAuthContext = React.createContext();
const { Consumer, Provider } = LegacyAuthContext;

/**
 * @deprecated use usePromotion or useAuth hooks instead
 * */
const withAuth = (WrappedComponent) => {
    return (props) => {
        const legacyGlobals = useContext(LegacyAuthContext);
        const [auth] = useAuth();

        return <WrappedComponent {...auth} {...legacyGlobals} {...props} />;
    };
};

//TODO: (Brown to the future) Cambiar a función
class LegacyAuthProvider extends React.Component {
    constructor(props) {
        super(props);

        const INITIAL_STATE = {
            promocion: null
        };

        this.state = props.stateMock !== undefined ? props.stateMock : INITIAL_STATE;
    }

    changePromotion = (promocion) => {
        this.setState({ promocion });
        const promoData = JSON.stringify(promocion);
        sessionStorage.setItem('promocion', promoData);
    };

    removePromotion = () => {
        this.setState({ promocion: undefined });
        sessionStorage.removeItem('promocion');
    };

    componentDidMount = async () => {
        try {
            const promocion = JSON.parse(sessionStorage.getItem('promocion'));
            if (!promocion) return;

            this.setState({ promocion });
        } catch {}
    };

    render() {
        const { promocion } = this.state;
        const { changePromotion, removePromotion } = this;

        return (
            <Provider
                value={{
                    changePromotion,
                    removePromotion,
                    promocion
                }}
            >
                {this.props.children}
            </Provider>
        );
    }
}

export { LegacyAuthProvider as AuthProvider, Consumer, withAuth };

export default LegacyAuthProvider;
