import { IconPlus, IconX } from '@tabler/icons';
import { useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchButton from '../../../components/BasicComponents/Buttons/SearchButton';
import SuggestionCard from '../../../components/BasicComponents/Cards/SuggestionCard';
import InputNumber from '../../../components/BasicComponents/Inputs/InputNumber';
import InputText from '../../../components/BasicComponents/Inputs/InputText';
import ProvinceAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/ProvinceAutocomplete';
import { withAuth } from '../../../context/AuthProvider';
import {
    getCarriersByPgcl,
    getFilter,
    getLersSelected,
    getWasteManagersByPgcl,
    INITIAL_STATE_STAKEHOLDER_SEARCH
} from '../helpers/waste_manager.helpers';
import { NewPpgclContext } from './NewPpgclStepper';
import WasteManagerResult from './WasteManagerResult';
import './WasteManagerStep.scss';

const WasteManagerStep = ({ promocion, isCarrier }) => {
    const {
        wasteManagersChecked,
        setWasteManagersChecked,
        setLoading,
        newPpgcls,
        setNewPpgcls,
        carriersChecked,
        setCarriersChecked
    } = useContext(NewPpgclContext);
    const lers = getLersSelected(newPpgcls);
    const [wasteManagers, setWasteManagers] = useState([]);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState(INITIAL_STATE_STAKEHOLDER_SEARCH);
    const [lersSelected, setLersSelected] = useState(lers);
    const lersNotSelected = getLersSelected(newPpgcls).filter((ler) => !lersSelected.includes(ler));

    const handleLoadMore = async () => {
        setPage((prevPage) => prevPage + 1);
        const filter = `&page=${page + 1}` + getFilter(search);
        isCarrier ? await setStatusCarriers(filter, false) : await setStatusWasteManager(filter, false);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setPage(0);
        isCarrier
            ? setStatusCarriers(`&page=0` + getFilter(search))
            : setStatusWasteManager(`&page=0` + getFilter(search));
    };

    const setStatusCarriers = async (filter = '', newSearch = true) => {
        setLoading(true);
        filter += `&codLer.in=${lersSelected}`;
        const carriers = await getCarriersByPgcl(filter);
        newSearch ? setWasteManagers(carriers) && setPage(0) : setWasteManagers(wasteManagers.concat(carriers));
        setLoading(false);
    };

    const setStatusWasteManager = async (filter = '', newSearch = true) => {
        setLoading(true);
        const newWasteManagers = await getWasteManagersByPgcl(filter);
        newSearch
            ? setWasteManagers(newWasteManagers) && setPage(0)
            : setWasteManagers(wasteManagers.concat(newWasteManagers));
        setLoading(false);
    };
    const checkboxHandleClick = (wasteManager) => {
        if (wasteManagersChecked.some((waste) => waste.id === wasteManager.id)) {
            setWasteManagersChecked(wasteManagersChecked.filter((waste) => waste.id !== wasteManager.id));
            setNewPpgcls(newPpgcls.filter((ppgcl) => ppgcl.ppgcl.procesoGestora.gestora.id !== wasteManager.id));
        } else {
            setWasteManagersChecked(wasteManagersChecked.concat(wasteManager));
        }
    };
    const checkboxHandleClickCarrier = (carrier) => {
        if (carriersChecked.some((carrierChecked) => carrierChecked.id === carrier.id)) {
            setCarriersChecked(carriersChecked.filter((carrierChecked) => carrierChecked.id !== carrier.id));
        } else {
            setCarriersChecked(carriersChecked.concat(carrier));
        }
    };
    useEffect(() => {
        isCarrier && setLersSelected(getLersSelected(newPpgcls));
    }, []);
    useEffect(() => {
        isCarrier && setStatusCarriers(`&page=0` + getFilter(search));
    }, [lersSelected]);

    useEffect(() => {
        if (promocion?.id) {
            setSearch({ ...search, province: promocion.direccion.municipio.provincia });
            if (isCarrier) {
                if (newPpgcls) {
                    setStatusCarriers(
                        `&page=0` + getFilter({ ...search, province: promocion.direccion.municipio.provincia })
                    );
                }
            } else {
                setStatusWasteManager(
                    `&page=0` + getFilter({ ...search, province: promocion.direccion.municipio.provincia })
                );
            }
        }
    }, [promocion?.id, isCarrier]);

    const handleInputChange = ({ target }) => {
        setSearch({
            ...search,
            [target.name]: target.value
        });
    };

    return (
        <div>
            <SuggestionCard>
                {isCarrier
                    ? 'El rol transportista lleva a cabo el traslado de los residuos a los centros de valorización, es clave en la cadena de valor del residuo y su trazabilidad.'
                    : 'Las gestoras son empresas autorizadas para valorizar los residuos generados recuperando materias primas y residuos. De forma que puedan ser reutilizados o reintroducidos en el proceso productivo propio o en procesos de otras industrias.'}
            </SuggestionCard>
            <p className="verticalStepper__message">
                {isCarrier
                    ? 'Selecciona las empresas transportistas y los residuos que trasladarán:'
                    : 'Selecciona las gestoras que van a participar en este proyecto:'}
            </p>
            <form onSubmit={handleFormSubmit} className="wasteManagerStep__form">
                <InputText
                    label="Nombre"
                    name="name"
                    value={search?.name}
                    setValues={setSearch}
                    onChange={handleInputChange}
                />
                <ProvinceAutocomplete values={search} setValues={setSearch} isClearable={true} />
                <InputNumber
                    label="NIMA"
                    name="nima"
                    value={search?.nima}
                    setValues={setSearch}
                    onChange={handleInputChange}
                />
                <InputText
                    label="CIF"
                    name="cif"
                    value={search?.cif}
                    setValues={setSearch}
                    onChange={handleInputChange}
                />
                <SearchButton />
            </form>
            {isCarrier && (
                <div className="wasteManagerStep__buttonsContainer">
                    {lersSelected.map((ler) => (
                        <button
                            key={ler}
                            className="wasteManagerStep__buttonLer"
                            onClick={
                                lersSelected.length > 1
                                    ? () => setLersSelected(lersSelected.filter((lerSelect) => lerSelect !== ler))
                                    : null
                            }
                        >
                            {ler}
                            {lersSelected.length > 1 && <IconX size={18} />}
                        </button>
                    ))}
                    {lersNotSelected.map((lerNotSelected) => (
                        <button
                            className="wasteManagerStep__buttonLer wasteManagerStep__buttonLer--notselect"
                            key={lerNotSelected}
                            onClick={() => setLersSelected([...lersSelected, lerNotSelected])}
                        >
                            {lerNotSelected}
                            <IconPlus size={18} />
                        </button>
                    ))}
                </div>
            )}

            {!isCarrier && (
                <div className="wasteManagerStep__container">
                    {wasteManagers.length > 0 ? (
                        <InfiniteScroll
                            dataLength={wasteManagers.length}
                            next={() => handleLoadMore()}
                            hasMore={true}
                            height={450}
                        >
                            <ul>
                                {wasteManagers.map((wasteManager) => (
                                    <WasteManagerResult
                                        key={wasteManager.id}
                                        {...{
                                            wasteManager,
                                            wasteManagersChecked,
                                            checkboxHandleClick
                                        }}
                                    />
                                ))}
                            </ul>
                        </InfiniteScroll>
                    ) : (
                        <p className="verticalStepper__message">No existen resultados</p>
                    )}
                </div>
            )}
            {isCarrier && (
                <div className="wasteManagerStep__container">
                    {wasteManagers.length > 0 ? (
                        <InfiniteScroll
                            dataLength={wasteManagers.length}
                            next={() => handleLoadMore()}
                            hasMore={true}
                            height={450}
                        >
                            <ul>
                                {wasteManagers.map((carrier) => (
                                    <WasteManagerResult
                                        key={carrier.id}
                                        wasteManager={carrier}
                                        wasteManagersChecked={carriersChecked}
                                        checkboxHandleClick={checkboxHandleClickCarrier}
                                        openModal
                                    />
                                ))}
                            </ul>
                        </InfiniteScroll>
                    ) : (
                        <p className="verticalStepper__message">
                            {newPpgcls.length === 0 &&
                                'Para poder seleccionar los transportistas primero configura las gestoras y los residuos en el pasos previos'}
                            {wasteManagers.length === 0 && newPpgcls.length !== 0 && 'No existen resultados'}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default withAuth(WasteManagerStep);
