import { Trans, useTranslation } from 'react-i18next';

import KPIAlarm from 'features/KPIs/components/KPIAlarm';
import { KPIStatus } from 'modules/promotion-kpis';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import { MandatoryFlowAlarms } from '../hooks/useMandatoryFlowsAlarms';

const WasteManagementAlarm = (props: {
    alarms: MandatoryFlowAlarms;
    link: string;
    status: KPIStatus;
    promotionId: string;
}) => {
    const [t] = useTranslation('kpis');
    return (
        <>
            {props.alarms.map(({ goal, ...flow }) => {
                const goalValueTons =
                    (goal?.value.type === 'EGR' && flow.egrTons) || // Egr
                    (goal?.value.type === 'PGR' && flow.pgrTons) || // Pgr
                    0; // or 0
                if (goalValueTons === 0) {
                    return (
                        <KPIAlarm
                            status={props.status}
                            link={{
                                text: t(`kpis:mandatory-flows.alarm.no_EgrOrPgr.link`),
                                to: `/promotion/${props.promotionId}/settings/egr-pgr`
                            }}
                            text={
                                <Trans
                                    i18nKey={`kpis:mandatory-flows.alarm.no_EgrOrPgr.text`}
                                    values={{
                                        flowName: flow.name,
                                        wasteManagementType: goal?.value.type.toUpperCase()
                                    }}
                                    components={{ b: <strong /> }}
                                />
                            }
                        />
                    );
                }

                const accumulatedRealPercent =
                    goalValueTons > 0
                        ? `${currencyFormatDE(((flow.accumulatedRealTons || 0) / goalValueTons) * 100)}%`
                        : 'N/D';

                return (
                    <KPIAlarm
                        status={props.status}
                        link={{
                            text: t(`kpis:mandatory-flows.alarm.${props.status}.egrAndPgr.link`),
                            to: props.link
                        }}
                        text={
                            <Trans
                                i18nKey={`kpis:mandatory-flows.alarm.${props.status}.egrAndPgr.text`}
                                values={{
                                    goalValueTons: (goalValueTons && `${goalValueTons}t`) || 'N/D',
                                    flowName: flow.name,
                                    goalValuePercent: currencyFormatDE(goal?.value.value),
                                    wasteManagementType: goal?.value.type.toUpperCase(),
                                    accumulatedRealTons: flow.accumulatedRealTons,
                                    accumulatedRealPercent
                                }}
                                components={{ b: <strong /> }}
                            />
                        }
                    />
                );
            })}
        </>
    );
};

export default WasteManagementAlarm;
