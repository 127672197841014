import fetcher from '../../../lib/fetcher';

export const getDocumentsByUsage = async (promotionId, filter) => {
    // params 'usage' and 'promocionId' are required
    const URL = `api/documents/promotion/${promotionId}/usage${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        return error.response;
    }
};
