export class DomainException extends Error {
    cause: Cause;

    constructor(params: DomainException['cause']) {
        super();
        this.message = params.code;
        this.cause = params;
    }
}

interface Cause {
    code: string;
    description: string;
    content: any;
}

// interface Cause {
//     code: string;
//     description: string;
//     content: any;
// }

// interface Content {
//     errors: FieldError[];
// }

// interface FieldError {
//     field: string;
//     error: string;
// }
