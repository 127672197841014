import { z } from 'zod';
import Validations_ES from './i18n/Validations_ES.json';
import Validations_EN from './i18n/Validations_EN.json';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    const keys = new Set([...Object.keys(Validations_EN), ...Object.keys(Validations_ES)]);

    if (keys.has(issue.code)) return { message: `validations:${issue.code}` };

    return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);
