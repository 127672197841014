import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import promotionServices from '../../../api/promotion/promotion.services';
import { withAuth } from '../../../context/AuthProvider';
import { customStyles, hasMore } from './helpers';

const EstadosPromocionSelectAutocomplete = (props) => {
    const [t] = useTranslation();

    useEffect(() => {
        if (values?.promotionState === '') {
            setDefaultValue();
        }
    }, []);

    const setDefaultValue = async () => {
        const opcionesEstado = await promotionServices.promotionState();
        if (opcionesEstado !== undefined && promotionState === '') {
            setValues({ ...values, promotionState: opcionesEstado[0] });
        }
    };

    const loadOptionsEstadosPromocion = async (search, loadedOptions, { page }) => {
        const opcionesEstado = await promotionServices.promotionState();

        return {
            options: opcionesEstado,
            hasMore: hasMore(opcionesEstado),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOptionEstado = (item) => {
        setValues({ ...values, promotionState: item });
    };

    const { promotionState } = props.values;
    const { setValues, values, required, className } = props;

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {t('promotionPhase', { ns: 'visit' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={loadOptionsEstadosPromocion}
                onChange={selectOptionEstado}
                getOptionLabel={(option) => option.estado}
                getOptionValue={(option) => option}
                value={promotionState}
                styles={customStyles()}
                placeholder=""
                additional={{
                    page: 0
                }}
            />
        </div>
    );
};
export default withAuth(EstadosPromocionSelectAutocomplete);
