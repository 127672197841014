import { useEffect, useState } from 'react';

import { IconX } from '@tabler/icons';
import InputText from 'components/BasicComponents/Inputs/InputText';
import usePrevious from 'hooks/usePrevious';
import { debounce } from 'utils/helpers/async.helpers';
import { FilterProps } from '../FilterProps';
import './TextFilter.scss';

type Value = string | undefined | null;

interface TextFilterProps extends FilterProps {
    name: string;
    label: string;
    value?: Value;
    onChange?: (value: Value) => any;
    onClear?: () => any;
    isClearable: boolean;
}

const TextFilter = (props: TextFilterProps) => {
    const [value, _setValue] = useState(props.value);
    const prevValue = usePrevious(value);

    useEffect(() => {
        if (prevValue === undefined && value === undefined) return;

        changeValueWithDebounce();
    }, [JSON.stringify(value)]);

    const changeValueWithDebounce = async () => {
        if (!props.onChange) return;
        await debounce(props.onChange, 500)(value);
    };

    useEffect(() => {
        // prevents emitting onChange when the component mounts
        if (prevValue === undefined) return;
        // prevents emitting onChange when the value is the same
        if (JSON.stringify(props.value) === JSON.stringify(value)) return;

        if (props.value) _setValue(props.value);
    }, [JSON.stringify(props.value)]);

    const hasValue = value !== undefined && value !== null && value !== '';

    const modifiers = {
        hasValue: hasValue ? 'TextFilter--has-value' : '',
        isChip: props.variant === 'chip' ? 'TextFilter--is-chip' : ''
    };

    return (
        <div className={`TextFilter ${Object.values(modifiers).join(' ')}`}>
            <p className="TextFilter__label">{props.label}</p>
            <div className="TextFilter__fields">
                <InputText
                    id={props.name}
                    autocomplete="off"
                    label={props.label}
                    value={value || ''}
                    onChange={(e) => _setValue(e.target.value || '')}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
                {props.isClearable && value && (
                    <div className="TextFilter__clearInput" onClick={() => _setValue(null)}>
                        <IconX />
                    </div>
                )}
            </div>
        </div>
    );
};

export default TextFilter;
