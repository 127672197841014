import InfiniteScroll from 'react-infinite-scroll-component';

import EmptyState from 'components/BasicComponents/EmptyState';
import { useQuery } from 'hooks/useQuery';
import { useDiExtraction } from 'modules/di-extraction';
import AutomaticReadingPendingState from '../AutomaticReadingPendingState';
import './AutomaticReadingList.scss';
import AutomaticReadingHeader from './components/AutomaticReadingHeader';
import AutomaticReadingItem from './components/AutomaticReadingItem';

const pageSize = 20;

const AutomaticReadingList = () => {
    const query = useQuery();
    const [{ diExtractions, criteria, loading, counters }, diExtractionsActions] = useDiExtraction();
    const { fetchDiExtractions } = diExtractionsActions;

    const skeletons = new Array(pageSize).fill(null).map((_, i) => <AutomaticReadingItem key={i} />);
    const isLoading = loading === 'pending' || loading === 'updating';
    const modifiers = [isLoading ? 'AutomaticReadingList--is-loading' : ''];

    return (
        <section className={`AutomaticReadingList ${modifiers.join(' ')}`}>
            <AutomaticReadingPendingState />

            {/* Header */}
            <AutomaticReadingHeader />

            {/* Empty states */}
            {counters.totalApplicable === 0 && loading !== 'succeeded' && (
                <div className="AutomaticReadingList__list">{skeletons}</div>
            )}

            {loading === 'succeeded' && counters.totalApplicable === 0 && !counters.PENDING && (
                <EmptyState mode={Object.keys(query.params).length > 0 ? 'notFound' : 'happyEmptyState'} />
            )}

            {/* Table */}
            {counters.totalApplicable !== null && counters.totalApplicable > 0 && (
                <InfiniteScroll
                    dataLength={diExtractions.length}
                    next={() => fetchDiExtractions({ page: (criteria.page || 0) + 1 }, { mode: 'merge' })}
                    hasMore={counters.totalApplicable > diExtractions.length}
                    loader={skeletons}
                    scrollableTarget="main-layout"
                    className="AutomaticReadingList__list"
                >
                    {diExtractions.map((diExtraction) => (
                        <AutomaticReadingItem key={diExtraction.id} diExtraction={diExtraction} />
                    ))}
                </InfiniteScroll>
            )}
        </section>
    );
};

export default AutomaticReadingList;
