import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { IconEyeOff, IconFileCheck } from '@tabler/icons';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import { withAuth } from '../../context/AuthProvider';
import Layout from '../../pages/Layout/Layout';
import CreateButton from '../../components/BasicComponents/Buttons/CreateButton';
import { TituloH1, TituloPromocion } from '../../components/BasicComponents/Titulos/Titulos';
import activitiesServices from '../../api/activities/activities.services';
import Folder from '../../components/BasicComponents/Folder/Folder';
import Spinner from '../../components/BasicComponents/Spinner/Spinner';
import HasPermission from '../../components/AccessCheckers/HasPermission';

const Activities = (props) => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();

    const [loading, setLoading] = useState(false);
    const [activities, setActivities] = useState([]);

    const numberOfActivities = async (activity) => {
        const filter = `/count?promocionId.equals=${props.promocion.id}&tipoActividadId.equals=${activity.id}`;
        const numActivities = await activitiesServices.getActivities(filter);
        const objActivity = Object.assign(activity, { numActivities });
        return objActivity;
    };

    const getTypeOfActivities = async () => {
        setLoading(true);
        let activities = [];
        const allTypesOfActivities = await activitiesServices.getTypesActivities('');
        for (let i = 0; i < allTypesOfActivities?.length; i++) {
            const numberActivities = await numberOfActivities(allTypesOfActivities[i]);
            if (numberActivities !== undefined) {
                activities = activities.concat(numberActivities);
            }
        }
        setActivities(activities);
        setLoading(false);
    };

    useEffect(() => {
        if (props.promocion?.id) {
            getTypeOfActivities();
        }
    }, [props.promocion?.id]);

    const { isCoCircular } = props;
    return (
        <Layout>
            <div className="create-button">
                <TituloH1 titulo={t('title', { ns: 'activities' })} />
                <div className="create-button__container">
                    <HasPermission ifRole="canCreate" section="activities">
                        <CreateButton
                            text={t('new.newActivity', { ns: 'activities' })}
                            to={`/promotion/${promotion.id}/activities/create`}
                        />
                    </HasPermission>
                    <HasPermission ifRole="canComplete" section="siteChecking">
                        <CreateButton
                            text={t('new.newVisit', { ns: 'activities' })}
                            to={`/promotion/${promotion.id}/activities/create-visit`}
                        />
                    </HasPermission>
                </div>
            </div>

            <TituloPromocion titulo={t('groupActivity', { ns: 'activities' })} />
            <div className="documentation__folderContainer">
                {activities.length > 0 &&
                    activities.map((activity) => (
                        <HasPermission
                            ifRole="canView"
                            section={activity?.descripcionCorta === 'Visita Obra' ? 'siteChecking' : 'activities'}
                            key={activity.id}
                        >
                            <Link
                                to={{
                                    pathname: `/promotion/${promotion.id}/activities/type-list`,
                                    state: { typeId: activity.id }
                                }}
                            >
                                <Folder>
                                    <div className="documentation__eye">
                                        <p className="documentation__numberFiles">
                                            <IconFileCheck size={20} color={'#7F42F5'} />
                                            {activity.numActivities}
                                        </p>
                                        {isCoCircular && !activity.visibleExterno && (
                                            <div
                                                data-for={'Actividades no visibles para el cliente'}
                                                data-tip={t('notVisible', { ns: 'activities' })}
                                                data-offset="{'top': -10}"
                                            >
                                                <IconEyeOff color={'#7F42F5'} />
                                                <ReactTooltip
                                                    id="Actividades no visibles para el cliente"
                                                    delayHide={100}
                                                    effect="solid"
                                                    multiline={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <p className="documentation__descriptionFolder">{activity.descripcionCorta}</p>
                                </Folder>
                            </Link>
                        </HasPermission>
                    ))}
            </div>
            {loading && <Spinner />}
        </Layout>
    );
};
export default withAuth(Activities);
