import { httpLocationApi } from 'modules/location/infrastructure/api/httpLocationApi';
import { LocationProvider } from 'modules/location/infrastructure/react/LocationContext';
import PromotionDataForm, { PromotionDataFormProps } from './PromotionDataForm';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: PromotionDataFormProps) => (
    <LocationProvider api={httpLocationApi}>
        <PromotionDataForm {...props} />
    </LocationProvider>
);
