import HelpCenterPage from '../pages/HelpCenterPage';

export const helpCenterRoutesRoot = '/help';

export const HELP_CENTER_ROUTES = [
    {
        component: HelpCenterPage,
        path: helpCenterRoutesRoot,
        exact: false
    }
];
