type Config = {
    truncate?: boolean;
};

export const calcGrValue = (real: number, goal: number, config?: Config) => {
    if (goal === 0) return 0; // Avoid division by zero (0/0 = NaN

    const { truncate } = config || {};
    const percent = ((real || 0) / (goal || 1)) * 100;
    const value = truncate ? Math.floor(percent) : percent;

    const formated = Math.round(value * 100) / 100;

    if (value > 999) return 999;
    return formated;
};
