import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

import { ImpactCriteria, createImpact } from '../../domain/Impact';
import { ImpactStore } from '../../domain/ImpactStore';

export const fetchImpact =
    (criteria?: ImpactCriteria): Thunk<ImpactStore> =>
    async (store, { api }) => {
        try {
            store.dispatch('fetchPending', { criteria });

            const [avoided, generated] = await Promise.all([
                api.getAvoidedImpact({ ...(criteria?.common || {}), ...(criteria?.avoided || {}) }),
                api.getGeneratedImpact({ ...(criteria?.common || {}), ...(criteria?.generated || {}) })
            ]);
            const errors = [...avoided.errors, ...generated.errors];

            const impact = createImpact({ avoided, generated });

            if (errors.length > 0) {
                console.error(errors);
                store.dispatch('fetchPartiallyFulfilled', { impact, error: errors });
                return;
            }

            store.dispatch('fetchFulfilled', { impact });
        } catch (e: any) {
            console.error(e);
            store.dispatch('fetchError', { error: e.message });
        }
    };

export const fetchImpactReducers: Pick<
    Reducers<ImpactStore>,
    'fetchPending' | 'fetchFulfilled' | 'fetchError' | 'fetchPartiallyFulfilled'
> = {
    fetchPending(state, payload) {
        return { ...state, loading: 'pending', criteria: payload.criteria };
    },
    fetchFulfilled(state, payload) {
        return { ...state, loading: 'succeeded', impact: payload.impact };
    },
    fetchError(state, payload) {
        return { ...state, loading: 'failed', error: payload.error };
    },
    fetchPartiallyFulfilled(state, payload) {
        return { ...state, loading: 'warning', error: payload.error, impact: payload.impact };
    }
};
