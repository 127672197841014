import { ReactElement } from 'react';

interface GenericDataFieldProps {
    text: string;
    title?: string | undefined;
    icon?: ReactElement | null;
    unit?: string | null;
    testid?: string;
}
const GenericDataField = ({ text, title, icon, unit, testid }: GenericDataFieldProps) => {
    return (
        <p className="TraceabilitiesTable__genericDataField" data-testid={testid}>
            {!!text && icon}
            <span title={title}>
                {text}
                {!!unit && !!text && <>{' ' + unit}</>}
            </span>
        </p>
    );
};

export default GenericDataField;
