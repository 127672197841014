import { WasteManagementMetrics } from 'modules/waste-management';

export function mandatoryFlowsToChartAdapter(
    mandatoryFlows: WasteManagementMetrics,
    type: 'EGR' | 'PGR'
): Array<{ [key: string]: any }> {
    const data = mandatoryFlows.map((flow) => {
        const estimation = (type === 'EGR' && flow.egrTons) || (type === 'PGR' && flow.pgrTons) || 0;

        const real = flow.realTons;

        // corresponds to real tons that are under the estimation
        const filled = real >= estimation ? estimation : real;

        // corresponds to real tons that are over the estimation
        const overflow = real >= estimation ? real - estimation : 0;

        // corresponds to real tons needed to reach the estimation
        const deficit = real < estimation ? estimation - real : 0;

        return {
            name: flow.lerType.name,
            filled,
            overflow,
            deficit
        };
    });

    return data;
}
