import { forwardRef, PropsWithChildren, useEffect, useImperativeHandle, useRef } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';

import { JsonSchema } from 'modules/shared';
import DynamicFormSection from './components/DynamicFormSection';
import './DynamicForm.scss';
import { jsonSchemaResolver } from './resolver/jsonSchemaResolver';

type Values = Record<string, any>;

type DynamicFormState = { values: Values; errors: null } | { values: null; errors: FieldErrors<Values> };

type DynamicFormProps = PropsWithChildren<{
    schema?: JsonSchema | null;
    defaultValues?: Values | null;
    className?: string;
    onValid?: (data: Values) => void;
    onInvalid?: (errors: FieldErrors<Values>) => void;
    onChanges?: (data: Values) => void;
}>;

export type DynamicFormRef = {
    submit: () => Promise<DynamicFormState>;
};

const DynamicForm = forwardRef<DynamicFormRef, DynamicFormProps>((props, ref) => {
    const schema = props.schema || {};
    const $submit = useRef<HTMLButtonElement>(null);
    const $form = useRef<HTMLFormElement>(null);

    const form = useForm({
        resolver: jsonSchemaResolver(schema),
        defaultValues: props.defaultValues || undefined
    });

    const submit = async () => {
        await form.trigger(); // 1. Trigger form validation to update error state
        $form.current?.reportValidity(); // 2. Show native validation message if there are errors

        const result = await new Promise<DynamicFormState>((r) =>
            form.handleSubmit(
                (values) => r({ values, errors: null }),
                (errors) => r({ values: null, errors })
            )()
        );
        return result;
    };

    const values = form.watch();

    useEffect(() => {
        props.onChanges?.(values);
    }, [JSON.stringify(values)]);

    useEffect(() => {
        if (!props.defaultValues) return;
        if (form.formState.isDirty) return; // prevents default values from overriding user input

        form.reset(props.defaultValues);
    }, [props.defaultValues]);

    // Expose submit method to allow external form submission
    useImperativeHandle(ref, () => ({ submit }), []);

    if (Object.keys(schema).length === 0) return null;

    return (
        <FormProvider {...form}>
            <form
                ref={$form}
                className={`DynamicForm ${props.className || ''}`}
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <DynamicFormSection node={schema} name="root" />
                {props.children}

                {/* Hidden submit button to trigger form submission */}
                <button ref={$submit} type="submit" style={{ display: 'none' }} />
            </form>
        </FormProvider>
    );
});

export default DynamicForm;
