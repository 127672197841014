import { IconX } from '@tabler/icons';
import './BasicChip.scss';

const BasicChip = ({ text = 'Default chip', id, hasRemoveButton = true, removeChipFunction, className = '' }) => {
    return (
        <div type="button" className={'basicChip ' + (className && className)}>
            <span>{text}</span>
            {hasRemoveButton && (
                <button type="button" className="basicChip__removeButton" onClick={() => removeChipFunction(id)}>
                    <IconX size={16} color={'#7F42F5'} />
                </button>
            )}
        </div>
    );
};

export default BasicChip;
