import { useEffect } from 'react';

import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useQuery } from 'hooks/useQuery';
import {
    DiExtractionCriteria,
    DiExtrationStatus,
    approvableStatusList,
    rejectableStatusList,
    useDiExtraction
} from 'modules/di-extraction';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';

export const useLoadAfterFiltersChange = () => {
    const query = useQuery();
    const [{ promotion }] = usePromotion();
    const promotionId = promotion?.id;

    const [, { reloadDiExtractions }] = useDiExtraction();

    const defaultStatusFilter = [
        ...new Set([...approvableStatusList, ...rejectableStatusList, DiExtrationStatus.PENDING])
    ];

    const multiselectAdapter = (options: SelectOption[] | SelectOption) => {
        if (!options) return undefined;
        return Array.isArray(options) ? options?.map((o) => o.value) : [options.value];
    };

    const criteria: Partial<DiExtractionCriteria> = {
        promotionId: promotionId || NaN,
        sort: query.params?.sort?.value,
        requestDateFrom: query.params?.date?.from,
        requestDateTo: query.params?.date?.to,
        state: multiselectAdapter(query.params?.status) || defaultStatusFilter,
        origin: query.params?.origin?.value,
        email: query.params?.email,
        fileName: query.params?.fileName,
        withWarnings: query.params?.withWarnings?.value
    };

    useEffect(() => {
        if (criteria.promotionId === null) return;

        reloadDiExtractions(criteria);
    }, [JSON.stringify(criteria)]);
};
