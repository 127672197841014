import CircleIcono from '../Iconos/CircleIcono';
import { TituloMetric, TituloDate, TituloFraction } from '../Titulos/Titulos';

import './Metrics.scss';

const IconMetric = ({
    title,
    titleDate,
    titleFraction,
    subtitle,
    icon,
    unit,
    danger = false,
    warning = false,
    color
}) => {
    const getColor = () => {
        if (danger) {
            return 'red';
        } else if (warning) {
            return 'orange';
        } else if (color) {
            return color;
        } else {
            return 'green';
        }
    };

    return (
        <div className="metric__info">
            <CircleIcono color={getColor()}>{icon}</CircleIcono>
            <div className="metric__data">
                <p className="metric__subtitle">{subtitle}</p>
                {title && <TituloMetric titulo={title} unit={unit} />}
                {titleFraction && <TituloFraction titulo={titleFraction} unit={unit} />}
                {titleDate && <TituloDate titulo={titleDate} />}
            </div>
        </div>
    );
};

export default IconMetric;
