import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import promotionServices from '../../../api/promotion/promotion.services';
import { withAuth } from '../../../context/AuthProvider';
import { customStyles, hasMore } from './helpers';

const PromotionAutocomplete = ({
    propName = 'promocion',
    required,
    className,
    values,
    setValues,
    isClearable = false,
    INITIAL_STATE = '',
    enterprise,
    num,
    multiselect = false,
    arrMultiselect = [],
    addPromotion = null,
    hiddenLabel = false,
    placeholder = ''
}) => {
    const [t] = useTranslation();

    const createFilter = (page) => {
        let filter = `?page=${page}`;
        if (enterprise?.id) {
            filter = `?enterpriseId.equals=${enterprise.id}`;
        }
        if (num) {
            filter = `?num.equals=${values.num}`;
        }
        return filter;
    };

    const removeSelectedPromotions = (arrOptions) => {
        let options = arrOptions;
        for (let i = 0; i < values.length; i++) {
            for (let j = 0; j < arrOptions.length; j++) {
                if (values[i].id === arrOptions[j].id) {
                    options = options.filter((option) => option.id !== arrOptions[j].id);
                }
            }
        }
        return options;
    };

    const getLoadOptions = async (search, loadedOptions, { page }) => {
        let filter = createFilter(page);
        let options;
        if (search) {
            options = await promotionServices.filterPromotion(`?nombre.contains=${search}`);
        } else {
            options = await promotionServices.filterPromotion(filter);
        }

        if (multiselect) {
            options = removeSelectedPromotions(options);
        }
        return {
            options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        if (item) {
            setValues({ ...values, [propName]: item });
        } else {
            setValues({ ...values, [propName]: INITIAL_STATE });
        }
    };

    const selectOptionMultiselect = (item) => {
        if (item) {
            if (values.find((promotion) => promotion.id === item.id)) {
                setValues(values.filter((promotion) => promotion.id !== item.id));
            } else {
                setValues([...values, item]);
            }
            addPromotion !== null && addPromotion(item);
        } else {
            setValues(INITIAL_STATE);
        }
    };

    const key = () => {
        return JSON.stringify(values);
    };

    const label = t('promotion.upperCase', { ns: 'common' });

    return (
        <div className={className ? className : 'input__autocomplete'}>
            {!hiddenLabel && (
                <label className="input__label">
                    {label} {required ? '*' : ''}
                </label>
            )}
            <AsyncPaginate
                key={key()}
                loadOptions={getLoadOptions}
                onChange={multiselect ? selectOptionMultiselect : selectOption}
                getOptionLabel={(option) => option.nombre}
                placeholder={placeholder}
                value={multiselect ? arrMultiselect : values[propName]}
                additional={{
                    page: 0
                }}
                filterOption={false}
                styles={customStyles()}
                isClearable={isClearable}
                aria-label={label}
            />
        </div>
    );
};
export default withAuth(PromotionAutocomplete);
