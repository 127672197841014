import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import TextArea from 'components/BasicComponents/Inputs/TextArea';
import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { useAuth } from 'modules/auth';
import { useComparativeCache, useUpdateObservations } from 'modules/comparative';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObservationsInfo } from '../../hooks/useComparativeGrid';
import './ComparativeObservationsModal.scss';

interface ComparativeObservationsModalProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
    observationsInfo: ObservationsInfo | undefined;
    onRefresh: () => Promise<any>;
}

const ComparativeObservationsModal = (props: ComparativeObservationsModalProps) => {
    const TEXT_MAX_LENGTH = 280;

    const [t] = useTranslation('comparative');
    const { format } = useDateFormatter();
    const [{ isCoCircular }] = useAuth();

    const { mutate: updateObservations } = useUpdateObservations();
    const { invalidate } = useComparativeCache();

    const [saving, setSaving] = useState(false);
    const [text, setText] = useState(props.observationsInfo?.observations?.text || '');

    if (!props.observationsInfo) return null;

    const updateText = (event) => {
        setText(event.target.value);
    };

    const saveObservations = async () => {
        setSaving(true);

        const request = await updateObservations({ promotionId: props.observationsInfo?.project.id ?? '', text });

        if (request.data?.success) {
            await invalidate.mutate();
            await props.onRefresh();

            setTimeout(() => {
                props.setOpenModal(false);
            }, 500);
        }
        setSaving(false);
    };

    return (
        <SimplePopover
            openModal={props.openModal}
            setOpenModal={props.setOpenModal}
            title={'Valorización CoCircular'}
            className="ComparativeObservationsModal"
        >
            <section>
                <p>
                    <p>Proyecto: {props.observationsInfo.project.name}</p>
                    {props.observationsInfo.observations && (
                        <p>
                            Última actualización por {props.observationsInfo.observations.user} el{' '}
                            {format(props.observationsInfo.observations.date, 'DD/MM/YY')}
                        </p>
                    )}
                </p>
                <TextArea
                    label={`Comentarios (${text.length}/${TEXT_MAX_LENGTH})`}
                    inputContainer="input__textarea input__container"
                    name="observations"
                    value={text}
                    onChange={updateText}
                    maxLength={TEXT_MAX_LENGTH}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
                {isCoCircular && (
                    <BasicButton
                        type="button"
                        text={'Actualizar'}
                        isLoading={saving}
                        action={() => saveObservations()}
                        {...({} as any)}
                    />
                )}
            </section>
        </SimplePopover>
    );
};

export default ComparativeObservationsModal;
