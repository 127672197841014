import { useEffect, useState } from 'react';
import { useNodeDimensions } from './useNodeDimensions';

interface useBarChartProps {
    allData: Array<any>;
    idProp: string;
    resetObjects: Array<any>; // triggers useEffect
    barsPerWidth: Array<{ maxWidth: number; bars: number }>;
    mode: 'sliceFromLeftToRight' | 'sliceFromRightToLeft';
}

export function useBarChart({ allData, resetObjects, idProp, barsPerWidth, mode }: useBarChartProps) {
    const [slicedData, setSlicedData] = useState(allData);
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(false);
    const [page, setPage] = useState(0);

    const firstBarId = allData.at(0)?.[idProp];
    const lastBarId = allData.at(-1)?.[idProp];

    const { rect, ref } = useNodeDimensions();
    const chartWidth = rect?.width || 0;

    useEffect(() => {
        // reset pagination to avoid crashes when user resizes window
        setPage(0);
        updateBarsAndButtons(0);
    }, [...resetObjects, chartWidth]);

    const updateBarsAndButtons = (offset) => {
        if (allData.length > 0 && chartWidth > 0) {
            const slicedDataAux = calculateDisplayedBars(offset);
            showOrHideScrollButtons(slicedDataAux);
        }
    };

    const calculateDisplayedBars = (offset) => {
        const bars = barsPerWidth.find((entry) => chartWidth <= entry.maxWidth)?.bars;
        if (bars === undefined) return allData;

        let from, to;

        if (mode === 'sliceFromLeftToRight') {
            from = offset * bars || undefined;
            to = offset * bars + bars;
        } else {
            from = -(offset * bars + bars);
            to = -(offset * bars) || undefined; // .slice() having "0" as second param doesn't work idk why
        }

        const slicedDataAux = (allData.slice(from, to) as []) || [];

        setSlicedData(slicedDataAux);
        return slicedDataAux;
    };

    const showOrHideScrollButtons = (slicedDataAux) => {
        let showLeftBtn = true,
            showRightBtn = true;

        if (slicedDataAux.at(0)?.[idProp] == firstBarId) showLeftBtn = false;
        if (slicedDataAux.at(-1)?.[idProp] == lastBarId) showRightBtn = false;

        setShowLeftButton(showLeftBtn);
        setShowRightButton(showRightBtn);
    };

    const scrollLeft = () => {
        const newPage = mode === 'sliceFromLeftToRight' ? page - 1 : page + 1;
        setPage(newPage);
        updateBarsAndButtons(newPage);
    };

    const scrollRight = () => {
        const newPage = mode === 'sliceFromLeftToRight' ? page + 1 : page - 1;
        setPage(newPage);
        updateBarsAndButtons(newPage);
    };

    return { slicedData, ref, showLeftButton, showRightButton, scrollLeft, scrollRight };
}
