import { ApiRelatedEnterprises } from 'modules/shared';
import { findMainEnterprise } from './findMainEnterprise';

export const legacyEnterpriseAdapter = (relatedEnterprises?: ApiRelatedEnterprises[]): LegacyEnterprise | null => {
    const mainEnterprise = findMainEnterprise(relatedEnterprises);
    if (!mainEnterprise) return null;

    return {
        id: mainEnterprise.enterpriseId,
        tipoEmpresa: null,
        nombreFiscal: mainEnterprise.enterpriseName,
        nombreComercial: mainEnterprise.enterpriseName,
        cif: mainEnterprise.enterpriseCif
    };
};

export interface LegacyEnterprise {
    id: number;
    tipoEmpresa?: any;
    nombreFiscal: string;
    nombreComercial: string;
    cif: string;
    recicla?: number;
    economica?: number;
    peso?: number;
    valorizacion?: number;
    valorizacionCocircular?: number;
    monthlyFee?: any;
    impactoC02?: number;
}
