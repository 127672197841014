import { IconExchange, IconTruckDelivery, IconEdit } from '@tabler/icons';
import './FastAccessButton.scss';

const stroke = 1.5;

const FastAccessButton = ({ type, text, ...props }) => {
    const renderIcon = {
        settings: () => <IconExchange stroke={stroke} size={24} />,
        recycle: () => <IconTruckDelivery stroke={stroke} />,
        edit: () => <IconEdit stroke={stroke} />
    };

    return (
        <>
            <button className="fastAccessButtons" {...props}>
                {text ? text : null} {renderIcon[type]()}
            </button>
        </>
    );
};

export default FastAccessButton;
