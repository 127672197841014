/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from 'zod';

export const PromotionDataFormSchema = z
    .object({
        id: z.number().min(1, { message: 'validations:required' }),
        name: z.string().min(1, { message: 'validations:required' }).max(255),
        nima: z.string().min(10).max(10).optional(),
        surface: z.number().min(1, { message: 'validations:required' }),
        pem: z.number().min(1, { message: 'validations:required' }),
        address: z.string().min(1, { message: 'validations:required' }).max(255),
        postalCode: z
            .string()
            .min(5)
            .max(5)
            .refine((data) => parseInt(data) >= 1000 && parseInt(data) <= 52999, { message: 'validations:postalCode' }),
        town: z.object({ id: z.number(), name: z.string() }, { message: 'validations:required' }).required(),
        type: z.object({ id: z.number(), name: z.string() }, { message: 'validations:required' }).required(),
        numHouses: z.number().optional(),
        power: z.number().optional(),
        startDate: z.date(),
        endDate: z.date()
    })
    .refine((data) => data.startDate < data.endDate, {
        path: ['endDate'],
        message: 'promotion:validations.endDateAfterStartDate'
    });

export type PromotionDataFormSchema = z.infer<typeof PromotionDataFormSchema>;
