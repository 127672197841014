import { useEffect, useReducer } from 'react';

/**
 * Adapts a Thunk function to simple hook
 * @deprecated - use "createQueryHook" of "contextStoreAdapter" instead
 * */
export function thunkToHookAdapter<
    Arg extends any,
    Store extends any,
    Repositories extends { [key: string]: any },
    Data extends any
>(thunk: (...args: Arg[]) => (store: Store, repositories: Repositories) => Promise<Data>, repositories: Repositories) {
    return (...args: Arg[]) => {
        type State = {
            data: Data | null;
            isLoading: boolean;
            error: Error | null;
            args: Arg[];
        };

        type Actions =
            | { type: 'pending'; payload: { isLoading: boolean; args: Arg[] } }
            | { type: 'fulfilled'; payload: { data: Data; isLoading: boolean } }
            | { type: 'rejected'; payload: { error: Error; isLoading: boolean } };

        const initialState: State = {
            args: [],
            data: null,
            error: null,
            isLoading: false
        };

        const reducer = (state: State, action: Actions) => {
            return { ...state, ...action.payload };
        };

        const [state, dispatch] = useReducer(reducer, initialState);

        const effect = async (...callArgs: Arg[]) => {
            try {
                dispatch({ type: 'pending', payload: { isLoading: true, args } });

                const call = thunk(...callArgs);
                const data = await call(null as Store, repositories);

                dispatch({ type: 'fulfilled', payload: { data, isLoading: false } });
                return data;
            } catch (error: any) {
                dispatch({ type: 'rejected', payload: { error, isLoading: false } });
            }
        };

        useEffect(() => {
            effect(...args);
        }, [JSON.stringify(args)]);

        return [state, { revalidate: effect }] as [State, { revalidate: typeof effect }];
    };
}
