import { IconBuilding, IconGridDots, IconHash, IconMapPin } from '@tabler/icons';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { IconHammer } from 'icons/IconHammer';
import { IconHourglassLow } from 'icons/IconHourglassLow';
import { IconProgress } from 'icons/IconProgress';
import { IconRotateDot } from 'icons/IconRotateDot';
import { useAuth } from 'modules/auth';
import { PromotionPreview as IPromotionPreview } from 'modules/promotion/domain/promotion/PromotionPreview';
import { ReactElement } from 'react';
import { currencyFormatDE, getYearMonthDay } from 'utils/helpers/general.helpers';

interface AdditionalData {
    propName: keyof IPromotionPreview['additionalData'];
    icon: ReactElement;
    translationKey: string;
    extraText?: string;
    transformFunction?: (prop) => string;
    onlyForAdmin?: boolean;
}

// Custom hook as we need to use a custom hook inside
export function useAdditionalData(): { additionalData: Array<AdditionalData> } {
    const { fromNow } = useDateFormatter();
    const [{ authorities }] = useAuth();

    const allAdditionalData: Array<AdditionalData> = [
        // propName and translationKey separated just in case PromotionPreview interface changes
        {
            propName: 'latestUpdate',
            icon: <IconRotateDot />,
            translationKey: 'latestUpdate',
            transformFunction: (date) => 'Actualizado ' + fromNow(date)
        },
        {
            propName: 'mainEnterpriseName',
            icon: <IconBuilding />,
            translationKey: 'mainEnterpriseName'
        },
        {
            propName: 'province',
            icon: <IconMapPin />,
            translationKey: 'province'
        },
        {
            propName: 'progressPercentage',
            icon: <IconProgress />,
            translationKey: 'progressPercentage',
            extraText: '%'
        },
        {
            propName: 'endDate',
            icon: <IconHourglassLow />,
            translationKey: 'endDate',
            transformFunction: (date) => {
                const prefix = getYearMonthDay() >= date ? 'Finalizó ' : 'Finaliza ';
                return prefix + fromNow(date);
            }
        },
        {
            propName: 'surface',
            icon: <IconGridDots />,
            translationKey: 'surface',
            transformFunction: (number) => currencyFormatDE(number),
            extraText: 'm²'
        },
        {
            propName: 'type',
            icon: <IconHammer />,
            translationKey: 'type'
        },
        {
            propName: 'num',
            icon: <IconHash />,
            translationKey: 'num',
            onlyForAdmin: true
        }
    ];

    // DOMAIN, woops...
    const isAdminOrAudit = authorities.includes('ROLE_ADMIN') || authorities.includes('ROLE_WRITER_AUDIT');

    let additionalData = allAdditionalData;
    // exclude num for non admin/audit users
    if (!isAdminOrAudit) {
        additionalData = allAdditionalData.filter((data) => !data.onlyForAdmin);
    }

    return { additionalData };
}
