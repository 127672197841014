import { ApiAddon } from '../BilleablesApi/ApiBilleable';
import { BilleablesPromotionRepository } from '../BilleablesPromotionRepository/BilleablesPromotionRepository';
import { Billeable } from './Billeable';
import { createGenericBilleable } from './GenericBilleable/createGenericBilleable';
import { createSaaSBilleable } from './SaaSBilleable/createSaaSBilleable';
import { SELLO_VALORIZA_ADDONS } from './SelloValorizaBilleable/SelloValorizaAddons';
import { createSelloValorizaBilleable } from './SelloValorizaBilleable/createSelloValorizaBilleable';
import { isSelloValorizaAddon } from './SelloValorizaBilleable/isSelloValorizaAddon';
import { isSelloValorizaBilleable } from './SelloValorizaBilleable/isSelloValorizaBilleable';
import { isEnterpriseAddon } from './EnterpriseBilleable/isEnterpriseAddon';
import { TIER_ADDONS } from './SaaSBilleable/SaaSAddons';

type Sources = {
    addons: ApiAddon[];
    promotion: BilleablesPromotionRepository['data'];
};

export function createBilleables({ addons, promotion }: Sources): Billeable[] {
    const defaultBilleables: Billeable[] = [createSaaSBilleable({ purchased: !promotion.isDemo })];

    const billeables: Billeable[] = addons.reduce((billeables, addon) => {
        // Exclude tier addons
        if (isTierAddon(addon)) {
            return billeables;
        }

        // Only creates 1 Billeable item for all "*_BADGE" addons,
        if (isSelloValorizaAddon(addon) || addon.name === SELLO_VALORIZA_ADDONS.NO_BADGE.name) {
            if (billeables.some(isSelloValorizaBilleable)) return billeables;

            const selloValorizaBilleable = createSelloValorizaBilleable({
                addons,
                score: promotion?.selloValorizaScore,
                valorizationTarget: promotion?.selloValorizaValorizationTarget,
                // Takes purchased addon if existis, if not uses default
                addon: addons.find((_addon) => isSelloValorizaAddon(_addon) && _addon.purchased)
            });

            return [...billeables, selloValorizaBilleable];
        }

        // Makes all enterprise addon non-editables
        if (isEnterpriseAddon(addon)) {
            return [...billeables, createGenericBilleable({ addon, editable: false })];
        }

        const genericBilleable = createGenericBilleable({ addon, editable: true });

        return [...billeables, genericBilleable];
    }, defaultBilleables);

    return billeables;
}

const isTierAddon = (addon: ApiAddon) =>
    Object.values(TIER_ADDONS)
        .map((addon) => addon.name)
        .some((addonName) => addon.name === addonName);
