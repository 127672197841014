import { IconEye, IconEyeOff } from '@tabler/icons';
import { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from 'assets/logo/logo360.png';
import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import InputText from 'components/BasicComponents/Inputs/InputText';
import { useAuth } from 'modules/auth/infrastructure/react/authContext';
import './Login.scss';

const Login = () => {
    const [t] = useTranslation('auth');
    const [{ error }, { login }] = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPass, setShowPass] = useState(false);

    const send = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        setShowError(false);

        await login({ username: username.replaceAll(' ', ''), password });

        setIsLoading(false);
    };

    useEffect(() => {
        if (error) setShowError(true);
    }, [error]);

    useEffect(() => {
        setShowError(false);
    }, [username, password]);

    const modifiers = [showError ? 'AuthLayout--has-error' : ''];

    return (
        <div className={`Login ${modifiers.join(' ')}`}>
            <form className="AuthLayout__form" autoComplete="on" onSubmit={send}>
                <header className="AuthLayout__header">
                    <img src={logo} alt="" className="AuthLayout__logo" />
                </header>
                <div>
                    <InputText
                        id="username"
                        className="AuthLayout__input"
                        label={t('username')}
                        name="username"
                        type="text"
                        autoComplete="username"
                        disabled={isLoading}
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                </div>
                <div className="AuthLayout__password">
                    <InputText
                        id="password"
                        className="AuthLayout__input"
                        label={t('password')}
                        name="password"
                        type={showPass ? 'text' : 'password'}
                        autoComplete="password"
                        disabled={isLoading}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                    <button
                        type="button"
                        className="AuthLayout__password-eye-btn"
                        onClick={() => setShowPass(!showPass)}
                    >
                        {showPass ? <IconEyeOff /> : <IconEye />}
                    </button>
                </div>
                <div className="AuthLayout__error-msg">{t('loginErrorMsg')}</div>
                <footer className="AuthLayout__footer">
                    <BasicButton
                        color="purple"
                        type="submit"
                        isLoading={isLoading}
                        text={t(`login`)}
                        extraClasses="AuthLayout__button"
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                    <hr />
                    <Link
                        to={`/account/reset-password/request${username.includes('@') ? `?email=${username}` : ''}`}
                        className="AuthLayout__link"
                    >
                        {t(`lostPasswordOrUsername`)}
                    </Link>
                </footer>
            </form>
        </div>
    );
};

export default Login;
