import * as Sentry from '@sentry/react';
import { Logger } from 'modules/shared/domain/Logger';

export const logger: Logger = (() => {
    const log = [] as any[];
    return {
        log,
        async send(error: Error, extra = {}) {
            console.error(error);
            if (log.length > 1000) log.shift();

            const data = {
                level: extra.level || 'error',
                user: extra.user,
                extra: {
                    date: new Date().toISOString(),
                    error_stack: error.stack,
                    error_cause: error.cause,
                    error_message: error.message,
                    ...extra
                }
            };

            Sentry.captureException(error, data);
            log.push(data);
        }
    };
})();
