import WasteRegisterExportCsv from './components/WasteRegisterExportCsv';
import './WasteRegisterActions.scss';

const WasteRegisterActions = () => {
    return (
        <div className="WasteRegisterActions">
            <WasteRegisterExportCsv />
        </div>
    );
};

export default WasteRegisterActions;
