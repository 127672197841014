import ReactGA from 'react-ga4';

import { AnalyticsApi } from 'modules/analytics/domain/AnalyticsApi';

export const googleAnalyticsApi: AnalyticsApi = {
    init: async () => {
        ReactGA.initialize('G-Q19RT7WWLB');
    },
    event: async (event, identification) => {
        const [category] = event.split('_');
        const label = Object.entries(identification || {})
            .map(([key, value]) => `${key}:${value}`)
            .join(',');

        // Send a custom event
        ReactGA.event({
            category,
            action: event,
            label
            // value: 99, // optional, must be a number
            // nonInteraction: true, // optional, true/false
            // transport: 'xhr' // optional, beacon/xhr/image
        });
    },
    pageView: async ({ page, title }) => {
        ReactGA.send({ hitType: 'pageview', page, title });
    },
    identify: async (identification) => {
        const userId = identification.email.replaceAll('.', '_');

        ReactGA.set({ userId });
        ReactGA.gtag('set', 'user_properties', { ...identification });
    }
};
