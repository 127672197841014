import KPIs from 'features/KPIs';
import './KPIsPrintable.scss';

const KPIsPrintable = () => {
    return (
        <>
            <div className="KPIsPrintable">
                <KPIs />
            </div>
        </>
    );
};

export default KPIsPrintable;
