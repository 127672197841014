import { onboardingStore } from 'modules/onboarding/application/onboardingStore';
import { contextStoreAdapter } from 'modules/shared';

const OnboardingContext = contextStoreAdapter(onboardingStore)({
    name: 'OnboardingContext'
});

export const OnboardingProvider = OnboardingContext.Provider;
export const useOnboardingStore = OnboardingContext.useConsumer;

export const useResourcesState = OnboardingContext.createQueryHook(({ api }) => api.getResourcesState);
export const useUpdateResourceState = OnboardingContext.createMutationHook(({ api }) => api.updateResourceState);
