import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getTodayDate } from 'utils/helpers/dates.helpers';
import { withAuth } from '../../../context/AuthProvider';
import Layout from '../../../pages/Layout/Layout';
import GoBack from '../../../components/BasicComponents/Buttons/GoBack';
import SubmitButton from '../../../components/BasicComponents/Buttons/SubmitButton';
import { TituloH1 } from '../../../components/BasicComponents/Titulos/Titulos';
import Buscador from '../../../components/BasicComponents/Buscador/Buscador';
import InputFecha from '../../../components/BasicComponents/Inputs/InputFecha';
import MessageNotComplete from '../../../components/BasicComponents/Messages/MessageNotComplete';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import BasicModal from '../../../components/BasicComponents/Modales/BasicModal';
import { BIM_MODEL_ID, ASSOCIABLE_TYPES_IDS } from '../../../features/documentation/constants/documentation.constants';
import DocumentTypeSelectAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/DocumentTypeSelectAutocomplete';
import { allFieldsComplete } from '../../../utils/helpers/general.helpers';
import DragNDrop from '../../../components/BasicComponents/DragAndDrop/DragNDrop';
import { getDocumentById } from '../api/getDocumentById';
import { createDocument } from '../api/createDocument';
import { updateDocument } from '../api/updateDocument';
import { patchDocument } from '../api/patchDocument';
import { docMapperPOST } from '../mappers/docMapperPOST';
import { docMapperPUT } from '../mappers/docMapperPUT';
import { docMapperPATCH } from '../mappers/docMapperPATCH';
import { prepareDocsArray } from '../helpers/documentation.helpers';

const NewDocument = (props) => {
    const [t] = useTranslation();
    let history = useHistory();
    const STATE = props.location.state;
    const INITIAL_STATE_DOC = {
        id: null,
        name: '',
        remarks: '',
        url: '',
        date: getTodayDate(),
        type: ''
    };

    const [editMode, setEditMode] = useState(false);
    const [document, setDocument] = useState(INITIAL_STATE_DOC);
    const [files, setFiles] = useState([]);
    const [messageNotComplete, setMessageNotComplete] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [validation, setValidation] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [createdDocument, setCreatedDocument] = useState();

    useEffect(() => {
        if (props.match.params.id !== undefined && props.promocion?.id) {
            setEditMode(true);
            searchDocument(props.match.params.id);
        } else {
            setEditMode(false);
        }
        // eslint-disable-next-line no-restricted-globals
    }, [location, props.promocion]);

    const searchDocument = async (id) => {
        setIsLoading(true);
        const docRequest = await getDocumentById(props?.promocion?.id, id);
        if (docRequest?.data?.code == 0) {
            setDocData(docRequest?.data?.content?.documents[0]);
        }
        setIsLoading(false);
    };

    const setDocData = (doc) => {
        const { id, name, remarks, url, date, type } = doc;

        setDocument({ id, name, remarks, url, date, type });
        setFiles([{ id, name, remarks, url, date, type }]);
    };

    const handleInputChange = ({ target }) => {
        setDocument({
            ...document,
            [target.name]: target.value
        });
    };

    const isInvoiceOrCertificate = async (docRequest, docTypeId) => {
        const newDocId = docRequest?.data?.content?.ids[0];
        if (files.length === 1 && newDocId !== undefined && ASSOCIABLE_TYPES_IDS.includes(docTypeId)) {
            setOpenModal(true);
            const document = await getDocumentById(props?.promocion?.id, newDocId);
            setCreatedDocument(document.data?.content?.documents[0]);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (validateForm()) {
            const docRequest = await getDocRequest();
            if (docRequest?.data?.code == 0 || docRequest?.status == 204) {
                if (editMode) {
                    setTimeout(() => {
                        goDocumentacion();
                    }, 1000);
                } else {
                    setTimeout(async () => {
                        await isInvoiceOrCertificate(docRequest, document?.type?.id);
                        resetData();
                    }, 500);
                }
            }
        }

        setIsLoading(false);
    };

    const getDocRequest = () => {
        // new document -> post
        if (!editMode) {
            const docsArray = prepareDocsArray(files);
            const docData = docMapperPOST({
                promoId: props?.promocion?.id,
                docData: document,
                type: document.type,
                docsArray: docsArray
            });
            return createDocument(docData);

            // file itself changed -> put
        } else if (checkIfFileChanged()) {
            const docData = docMapperPUT({
                promoId: props?.promocion?.id,
                docData: document,
                type: document.type,
                file: files[0]
            });
            return updateDocument(docData);
            // only data changed -> patch
        } else {
            const docData = docMapperPATCH({
                promoId: props?.promocion?.id,
                docData: document,
                type: document.type,
                file: files[0]
            });
            return patchDocument(docData);
        }
    };

    const checkIfFileChanged = () => {
        return files?.[0]?.bytes !== undefined;
    };

    const resetData = () => {
        setDocument(INITIAL_STATE_DOC);
        setFiles([]);
    };

    const requiredFieldsEditDoc = {
        name: files[0]?.name || document?.name,
        date: document?.date,
        type: document?.type?.id
    };

    const validateForm = () => {
        if (allFieldsComplete(requiredFieldsEditDoc)) {
            setMessageNotComplete('');
            setValidation(false);
            return true;
        } else {
            setMessageNotComplete('Complete los campos requeridos');
            setValidation(true);
            return false;
        }
    };

    const goBack = () => {
        history.push(`/promotion/${STATE.promocion.id}`);
    };
    const goDocumentacion = () => {
        history.goBack();
    };

    const addTraceabilities = () => {
        history.push({
            pathname: `/documents/${createdDocument.id}/link-traceabilities`,
            state: { documento: createdDocument }
        });
    };

    return (
        <Layout>
            <GoBack goBack={STATE?.promocion ? goBack : goDocumentacion} />
            <TituloH1 titulo={t(editMode ? 'edit' : 'new', { ns: 'documentation' })} />
            <Buscador titulo={``} column={true}>
                {document?.type?.id === BIM_MODEL_ID && (
                    <p className="newDocument__BIM">
                        <span>{t('explanationBIM.base', { ns: 'documentation' })}</span>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://developer.mozilla.org/es/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types"
                        >
                            <span>{t('explanationBIM.validFormat', { ns: 'documentation' })}</span>
                        </a>

                        <span>{t('explanationBIM.sendThrough', { ns: 'documentation' })}</span>

                        <a
                            className="newWasteManagerRequest__mail"
                            href="mailto:soporte360@cocicrcular.es?subject=Solicitud modelo BIM"
                        >
                            <span>soporte360@cocircular.es</span>
                        </a>
                    </p>
                )}
                <form onSubmit={handleFormSubmit}>
                    <DocumentTypeSelectAutocomplete
                        values={document}
                        value={document.type}
                        setValues={setDocument}
                        validacion={validation}
                        required
                    />
                    <InputFecha
                        label={t('date.date', { ns: 'common' })}
                        id="fecha-nueva-trazabilidad"
                        name="date"
                        value={document.date}
                        onChange={handleInputChange}
                        validacion={validation}
                        notLimit={true}
                        required
                    />
                    <DragNDrop
                        label={t('add', { ns: 'documentation' })}
                        disabled={!document?.type}
                        maxFiles={document?.type && document?.type?.unique === true ? 1 : undefined}
                        setFiles={setFiles}
                        files={files}
                        validacion={validation}
                        required
                        urlProp="url"
                        fileNameProp="name"
                        fileProp="bytes"
                        contentTypeProp="type"
                        maxSizeMb={150}
                    />
                    {messageNotComplete && <MessageNotComplete message={t('complete', { ns: 'common' })} />}
                    <SubmitButton
                        text={t(editMode ? 'edit' : 'add', { ns: 'documentation' })}
                        buttonDisabled={isLoading ? true : false}
                    />
                </form>
            </Buscador>
            {isLoading && <Spinner />}
            {openModal && (
                <BasicModal
                    title="Asociar traslados"
                    subtitle={t('addTraceability', { ns: 'documentation' })}
                    actionText={t('add.addTraceability', { ns: 'reports' })}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    refuse={false}
                    actionFunction={addTraceabilities}
                />
            )}
        </Layout>
    );
};
export default withAuth(NewDocument);
