import { useEffect, useState } from 'react';

import { WasteManagement, wasteManagementApi } from 'modules/waste-management';

interface UseExternalCheckProps {
    data?: WasteManagement | null;
}

export const useExternalCheck = (props: UseExternalCheckProps) => {
    const [external, setExternal] = useState<boolean | undefined>();
    const { mutate: save } = wasteManagementApi.useSave();

    useEffect(() => {
        const allExternal = (props.data?.length || 0) > 0 && props.data?.every((entry) => entry.external);
        setExternal(allExternal);
    }, [props.data]);

    const updateAndSaveExternal = (value: boolean) => {
        if (!props.data) return;

        setExternal(value);
        save(
            props.data.map((entry) => ({
                ...entry,
                external: value
            }))
        );
    };

    return { external, updateAndSaveExternal };
};
