import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';

import { useAuth } from 'modules/auth';
import userServices from '../../../../api/user/user.services';
import { withAuth } from '../../../../context/AuthProvider';
import { customStyles } from '../../../BasicComponents/Select-Autocomplete/helpers';

const SearchUsers = ({ required, users, setUsers, className, value, onChange = () => {} }) => {
    const [{ user: account }] = useAuth();

    const isAdmin = account?.authorities?.includes('ROLE_ADMIN');

    const [t] = useTranslation();
    const hasMore = (options) => {
        return options.length === 20 ? true : false;
    };

    const loadOptions = async (search, loadedOptions, { page }) => {
        let options = [];

        const filter = search ? `?mail.contains=${search}` : `?page=${page}&activated.equals=true`;

        if (isAdmin) {
            // Finds users of all enterprises
            options = await userServices.getUser(filter);
        } else {
            // Finds users only of own enterprise
            options = await userServices.getUsersByEntepriseId(account.enterprise.id, filter);
        }

        return {
            options: options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };
    const handleOnChange = (item) => {
        setUsers?.(users.concat(item));
        onChange(item);
    };

    return (
        <div className={className || ''}>
            <label className="input__label">
                {t('searchByEmail', { ns: 'users' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={loadOptions}
                onChange={handleOnChange}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
                getOptionValue={(option) => option}
                placeholder=""
                value={value}
                additional={{
                    page: 0
                }}
                styles={customStyles()}
            />
        </div>
    );
};
export default withAuth(SearchUsers);
