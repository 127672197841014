import WasteManagementPlanner from 'features/waste-management/WasteManagementPlanner';
import './EgrAndPgr.scss';
import { WasteManagementEntryType } from 'modules/waste-management';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import { IconAlertCircle } from '@tabler/icons';
import { Trans } from 'react-i18next';

const EgrAndPgr = () => {
    return (
        <div className="EgrAndPgr">
            <AlertCard title="" icon={<IconAlertCircle />}>
                <Trans ns="wasteManagement" i18nKey="info.rcdMandatory" components={{ bold: <strong /> }} />
            </AlertCard>

            <WasteManagementPlanner type={WasteManagementEntryType.EGR} />
            <WasteManagementPlanner type={WasteManagementEntryType.PGR} />
        </div>
    );
};

export default EgrAndPgr;
