import PropTypes from 'prop-types';
import { useEffect } from 'react';
import './ResultadoCarga.scss';
import { IconCircleCheck, IconCircleX } from '@tabler/icons';

const ResultadoCarga = ({ text, success, setMessage }) => {
    useEffect(() => {
        setTimeout(() => {
            setMessage({});
        }, 3000);
    }, [text]);

    return (
        <div className="resultadoCarga">
            <div
                className={
                    success
                        ? 'resultadoCarga__containerIcon resultadoCarga__containerIconSuccess'
                        : 'resultadoCarga__containerIcon resultadoCarda__containerIconError'
                }
            >
                {success ? <IconCircleCheck color="#b3e1c1" size={40} /> : <IconCircleX color="#ff7070" size={40} />}
            </div>
            <p>{text}</p>
        </div>
    );
};

ResultadoCarga.propTypes = {
    success: PropTypes.bool.isRequired
};

export default ResultadoCarga;
