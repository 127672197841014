import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { TituloH2 } from '../../components/BasicComponents/Titulos/Titulos';
import logo from '../../assets/logo/logo360.png';
import notFound from '../../assets/imagenes/notFound.png';
import './NotFound.scss';
import BasicButton from '../../components/BasicComponents/Buttons/Base/BasicButton';

export const NotFound = () => {
    const [t] = useTranslation();

    return (
        <div className="notFound">
            <Link to="/">
                <img src={logo} alt="logo360" className="notFound__logo360" />
            </Link>
            <div className="notFound__container">
                <div>
                    <TituloH2 titulo={t('notFound', { ns: 'errors' })} />
                    <Link to="/">
                        <BasicButton
                            color="blue"
                            text={t('goHomeButton', { ns: 'errors' })}
                            type="link"
                            to="/"
                            action={() => this.onClick}
                        />
                    </Link>
                </div>
                <img src={notFound} alt="not found illustration" />
            </div>
        </div>
    );
};
