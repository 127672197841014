import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleCard from '../../BasicComponents/Cards/SimpleCard';
import RichText from '../../BasicComponents/RichText';
import { TituloH4 } from '../../BasicComponents/Titulos/Titulos';
import SubmitButton from '../../BasicComponents/Buttons/SubmitButton';
import { getYearMonthDay } from '../../../utils/helpers/general.helpers.js';
import ResultadoCarga from '../../BasicComponents/Messages/ResultadoCarga';
import activitiesServices from '../../../api/activities/activities.services';
import DragNDrop from '../../BasicComponents/DragAndDrop/DragNDrop';
import { withAuth } from '../../../context/AuthProvider';
import { getIncidences } from '../../../utils/helpers/siteChecking.helpers';

const Resumen = (props) => {
    const [t] = useTranslation();
    const { newAttachedImages, setNewAttachedImages } = props;

    const [observacionesData, setObservacionesData] = useState({ observaciones: '' });
    const [dataLoaded, setDataLoaded] = useState(false);
    const [resetObservacionesNota, setResetObservacionesNota] = useState(false);
    const [tipoActividadNota, setTipoActividadNota] = useState(null);
    const [observacionesNota, setObservacionesNota] = useState({
        observacionesNota: ''
    });
    const [message, setMessage] = useState({});

    useEffect(() => {
        setInitialObservacionesData();
        getTipoActividadNota();
    }, []);

    useEffect(() => {
        if (dataLoaded) {
            updateObservacionesData();
        }
    }, [observacionesData.observaciones]);

    const setInitialObservacionesData = () => {
        setDataLoaded(false);

        if (props?.actividad?.observaciones?.includes('|')) {
            // OUTDATED: antes se guardaban las incidencias en las observaciones.

            // Sacar las observaciones a mano de la suma de incidencias + observaciones
            // Se hace de esta forma para encontrar la primera vez que aparece '|' y con eso ya troceamos las observaciones
            // If observaciones is null, "i" will be undefined. in that case, we give "i" the value -1 too
            const i =
                props.actividad.observaciones?.indexOf('|') !== undefined
                    ? props.actividad.observaciones?.indexOf('|')
                    : -1;
            const newObservaciones = i !== -1 ? props.actividad.observaciones?.slice(i + 1) : '';

            setObservacionesData({ observaciones: newObservaciones });
        } else {
            setObservacionesData({ observaciones: props?.actividad?.observaciones });
        }

        setDataLoaded(true);
    };

    const updateObservacionesData = () => {
        props.setActividad((actividad) => ({
            ...actividad,
            observaciones: observacionesData?.observaciones
        }));
    };

    const getIncidencias = () => {
        const incidences = getIncidences(props.sectionTypes);
        const incidencesArray = incidences?.split('.');
        incidencesArray.pop(); // quitamos el último elemento porque está vacío al hacer lo del split
        return incidencesArray;
    };

    const getTipoActividadNota = async () => {
        const tipos = await activitiesServices.getTypesActivities('?descripcionCorta.contains=Nota interna');
        if (tipos.length > 0) {
            setTipoActividadNota(tipos[0]);
        }
    };

    const createNotaInterna = async (e) => {
        e.preventDefault();
        let actividadAux = {
            promocion: props?.promocion,
            tipoActividad: tipoActividadNota,
            fechaActividad: getYearMonthDay(),
            estadoActividad: 'ABIERTO',
            observaciones: observacionesNota?.observacionesNota,
            asunto: 'Nota interna sobre ' + props?.actividad?.asunto
        };

        const newActividad = await activitiesServices.createActivity(actividadAux);
        if (newActividad !== undefined) {
            setMessage({
                text: t('internalNote.success', { ns: 'visit' }),
                success: true
            });
            setObservacionesNota({ observacionesNota: '' });
            setResetObservacionesNota(true);
            setResetObservacionesNota(false);
        } else {
            setMessage({
                text: t('internalNote.error', { ns: 'visit' }),
                success: false
            });
        }
    };

    return (
        <SimpleCard title={props?.title} allowScroll={true}>
            <div className="visita__resumenSection">
                <TituloH4 titulo={t('incidences.generated', { ns: 'visit' })} />
                {observacionesData?.incidencias === '' ? (
                    <div>
                        <i>{t('incidences.noGenerated', { ns: 'visit' })}</i>
                    </div>
                ) : (
                    <ul>
                        {getIncidencias().map((incidencia, i) => (
                            <li key={i}>{incidencia}</li>
                        ))}
                    </ul>
                )}
            </div>
            <div className="visita__resumenSection">
                <TituloH4
                    titulo={`${t('comments', { ns: 'common' })} ${t('optional.sg', {
                        ns: 'common'
                    })}`}
                />
                <RichText
                    id="observacionesInput"
                    values={observacionesData}
                    setValues={setObservacionesData}
                    field="observaciones"
                    dataLoaded={dataLoaded}
                />
            </div>
            <div className="visita__resumenSection visita__resumenSection-small">
                <TituloH4 titulo={`${t('attach.morePhotos', { ns: 'visit' })} ${t('optional.sg', { ns: 'common' })}`} />
                <small>{t('notToUpload', { ns: 'visit' })}</small>
                <DragNDrop files={newAttachedImages} setFiles={setNewAttachedImages} />
            </div>
            {props.isCoCircular && (
                <div className="visita__resumenSection">
                    <TituloH4 titulo={t('internalNote.createActivity.title', { ns: 'visit' })} />
                    <form onSubmit={createNotaInterna}>
                        <RichText
                            id="observacionesNotaInput"
                            values={observacionesNota}
                            setValues={setObservacionesNota}
                            field="observacionesNota"
                            dataLoaded={true}
                            reset={resetObservacionesNota}
                        />
                        <SubmitButton text={t('internalNote.createActivity.button', { ns: 'visit' })} />
                    </form>
                </div>
            )}
            {message.text !== undefined && (
                <ResultadoCarga text={message?.text} success={message?.success} setMessage={setMessage} />
            )}
        </SimpleCard>
    );
};

export default withAuth(Resumen);
