import { IconEyeOff, IconReport, IconTrash } from '@tabler/icons';
import Status from 'components/BasicComponents/Status/Status';
import ConstructionSiteActions from 'features/activities/components/ConstructionSiteActions';
import SiteCheckingModeChip from 'features/activities/components/smallComponents/SiteCheckingModeChip';
import { useAuth } from 'modules/auth';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import promoProGesCodLersServices from '../../../api/promoProGesCodLers/promoProGesCodLers.services';
import { withAuth } from '../../../context/AuthProvider';
import { cambiarOrdenFecha } from '../../../utils/helpers/general.helpers';
import HasPermission from '../../AccessCheckers/HasPermission';
import IconButton from '../../BasicComponents/Buttons/Small/IconButton';
import SmallButton from '../../BasicComponents/Buttons/Small/SmallButton';
import SimpleCard from '../../BasicComponents/Cards/SimpleCard';
import RoundedIcon from '../../BasicComponents/Icon/RoundedIcon';
import ModalEliminar from '../../BasicComponents/Modales/ModalEliminar';
import ConstructionSiteDateModal from '../../BasicComponents/Popovers/ConstructionSiteDatePopover';
import SmallText from '../../BasicComponents/Text/SmallText';
import { TituloH3 } from '../../BasicComponents/Titulos/Titulos';
import './Activity.scss';

const Activity = ({ activity, descriptionType, promocion, values, deleteActivity, updateActivity, index }) => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();
    const [{ user }] = useAuth();

    const [openModal, setOpenModal] = useState(false);
    const [isWasteManager, setIsWasteManager] = useState(false);
    const [openDateModal, setOpenDateModal] = useState(false);

    const isSiteChecking = activity?.tipoActividad?.id === 360203;

    const status = {
        ABIERTO: { description: 'Abierto', color: 'orange' },
        PLANIFICADA: { description: 'Planificada', color: 'blue' },
        EN_PROGRESO: { description: 'En progreso', color: 'blue' },
        INCOMPLETA: { description: 'Incompleta', color: 'orange' },
        CERRADO: { description: 'Cerrado', color: 'green' },
        BORRADOR: { description: 'Borrador', color: 'blue' },
        default: { description: 'Sin estado', color: 'blue' }
    };

    const getStatus = (activityStatus) => {
        let statusActivity = status[activityStatus] || status['default'];
        return statusActivity;
    };

    const checkIfIsWasteManager = async (wasteManagerId) => {
        const ppgcl = await promoProGesCodLersServices.filterPromoProGesCodLers(
            `?promocionId.equals=${promocion?.id}&gestoraId.equals=${wasteManagerId}`
        );
        const isWasteManager = ppgcl.length > 0 ? true : false;
        setIsWasteManager(isWasteManager);
    };

    useEffect(() => {
        if (activity.gestora !== null) {
            checkIfIsWasteManager(activity.gestora?.id);
        }
    }, []);

    return (
        <div>
            <SimpleCard className="activity">
                <RoundedIcon big={true} icon={<IconReport />} />
                <div className="activity__containerRight">
                    <div className="activity__containerTitle">
                        <TituloH3 titulo={activity.asunto} />

                        <div className="activity__flex">
                            <HasPermission ifRole="canCreate" section="activities">
                                {!activity.tipoActividad?.visibleExterno && (
                                    <div
                                        data-for={'Actividad no visible para el cliente'}
                                        data-tip={t('notVisible', { ns: 'activities' })}
                                        className="activity__eye"
                                    >
                                        <IconEyeOff color={'#7F42F5'} />
                                        <ReactTooltip
                                            id="Actividad no visible para el cliente"
                                            delayHide={100}
                                            effect="solid"
                                            multiline={true}
                                        />
                                    </div>
                                )}
                            </HasPermission>
                            {isSiteChecking ? (
                                <ConstructionSiteActions
                                    activityId={activity.id}
                                    activityStatus={activity.estadoActividad}
                                    openModal={openModal}
                                    setOpenModal={setOpenModal}
                                    setOpenDateModal={setOpenDateModal}
                                />
                            ) : (
                                <>
                                    {/* ÑAPA PARA OCULTAR BOTONES DE LOS INFORMES DE ESTADO */}
                                    {(activity.estadoActividad !== 'BORRADOR' ||
                                        user.authorities.includes('ROLE_ADMIN')) && (
                                        <Link
                                            to={{
                                                pathname: `/promotion/${promotion.id}/activities/${activity.id}`
                                            }}
                                        >
                                            <SmallButton color="blue" titulo={t('viewDetail', { ns: 'common' })} />
                                        </Link>
                                    )}
                                    {/* ÑAPA PARA OCULTAR BOTONES DE LOS INFORMES DE ESTADO: QUE NO PUEDAS EDITAR/BORRARLOS SI NO ERES ADMIN */}
                                    {(activity.tipoActividad.id !== 385751 ||
                                        user.authorities.includes('ROLE_ADMIN')) && (
                                        <HasPermission ifRole="canCreate" section="activities">
                                            <Link
                                                to={{
                                                    pathname: `/promotion/${promotion.id}/activities/${activity.id}/edit`,
                                                    state: {
                                                        values: values,
                                                        tipeIdActivity: activity.tipoActividad.id
                                                    }
                                                }}
                                            >
                                                <SmallButton titulo={t('edit', { ns: 'common' })} />
                                            </Link>

                                            <IconButton icon={<IconTrash />} action={() => setOpenModal(!openModal)} />
                                        </HasPermission>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="activity__smallInfo">
                        <Status
                            text={getStatus(activity.estadoActividad)['description']}
                            status={getStatus(activity.estadoActividad)['color']}
                        />

                        <SmallText text={cambiarOrdenFecha(activity.fechaActividad)} />
                        <SmallText text={descriptionType} />
                        {isSiteChecking && <SiteCheckingModeChip onSite={activity.onSite} />}
                    </div>

                    {activity.gestora !== null && (
                        <p className="activity__p">
                            {isWasteManager ? t('manager.sg', { ns: 'common' }) : t('transporter', { ns: 'common' })}:{' '}
                            <span>{activity.gestora?.empresa}</span>
                        </p>
                    )}

                    <p className="activity__p">
                        {t('author', { ns: 'common' })}:{' '}
                        <span>
                            {activity.clienteUser?.user
                                ? `${activity.clienteUser.user.firstName} ${activity.clienteUser.user.lastName}`
                                : t('cocircularTech', { ns: 'common' })}
                        </span>
                    </p>

                    {openDateModal && (
                        <ConstructionSiteDateModal
                            activity={activity}
                            openModal={openDateModal}
                            setOpenModal={setOpenDateModal}
                            updateActivity={updateActivity}
                            index={index}
                        />
                    )}

                    {openModal && (
                        <ModalEliminar
                            openModal={openModal}
                            setOpenModal={setOpenModal}
                            title={t('deteleActivity', { ns: 'activities' })}
                            subtitle={`${t('question', { ns: 'common' })}${t('delete', {
                                ns: 'common'
                            })} ${descriptionType} "${activity.asunto}"?`}
                            deleteFunction={deleteActivity}
                            id={activity.id}
                        />
                    )}
                </div>
            </SimpleCard>
        </div>
    );
};
export default withAuth(Activity);
