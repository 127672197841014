import './Impact.scss';
import ImpactCo2Avoided from './ImpactCo2Avoided';
import ImpactCo2Generated from './ImpactCo2Generated';

const ImpactCo2 = ({
    co2TemporalGraphData,
    co2ByLerGraphData,
    co2KPI,
    co2generated,
    co2GeneratedbyLerGraphData,
    equivalencies,
    isCoCircular
}) => {
    return (
        <>
            <ImpactCo2Generated {...{ co2KPI, co2generated, co2GeneratedbyLerGraphData }} />
            <ImpactCo2Avoided {...{ co2TemporalGraphData, co2ByLerGraphData, co2KPI, equivalencies, isCoCircular }} />
        </>
    );
};

export default ImpactCo2;
