import fetcher from 'lib/fetcher';
import { EnterprisesApi } from '../../domain/EnterprisesApi';
import { Enterprise } from '../../domain/Enterprise';

export const httpEnterprisesApi: EnterprisesApi = {
    async getEnterprises(criteria) {
        const params = {
            'nombreComercial.contains': criteria.search,
            page: criteria.page || 0
        };

        const { data } = await fetcher.get('api/maintenance/enterprise', { params });

        // Adapts old maintenance api to new enterprise api
        const enterprises: Enterprise[] = data.map(({ id, nombreFiscal, cif }) => ({
            id,
            name: nombreFiscal,
            cif
        }));

        return enterprises;
    }
};
