import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconEdit, IconTrash } from '@tabler/icons';
import { withAuth } from '../../context/AuthProvider';
import RoundedCard from '../../components/BasicComponents/Cards/RoundedCard';
import Card from '../../components/BasicComponents/Cards/SimpleCard';
import SmallText from '../../components/BasicComponents/Text/SmallText';
import SmallButton from '../../components/BasicComponents/Buttons/Small/SmallButton';
import IconButton from '../../components/BasicComponents/Buttons/Small/IconButton';
import ModalEliminar from '../../components/BasicComponents/Modales/ModalEliminar';
import HasPermission from '../../components/AccessCheckers/HasPermission';
import { cambiarOrdenFecha } from '../../utils/helpers/general.helpers.js';

const CostResult = ({ cost, values, deleteFunction }) => {
    const [t] = useTranslation();
    const [openDeleteModal, setOpenDelelteModal] = useState(false);

    return (
        <Card className="card__costes">
            <div className="costes__info">
                <RoundedCard text={cost.coste} unit={'€'} />
                <div className="costes__text">
                    <div className="costes__smallText">
                        {cost.fecha !== null && <SmallText text={cambiarOrdenFecha(cost.fecha)} />}
                        {cost.codLerNumber !== null && <SmallText text={cost.codLerNumber} />}
                        {cost?.gestora !== undefined && cost?.gestora !== null && (
                            <SmallText text={cost?.gestora?.empresa} />
                        )}
                    </div>
                    <p>{cost.observaciones}</p>
                </div>
            </div>
            <HasPermission ifRole="canCreate" section="costs">
                <div className="costes__buttons">
                    <Link
                        to={{
                            pathname: `/costs/${cost.id}/edit`,
                            state: {
                                values: values
                            }
                        }}
                    >
                        <SmallButton titulo={t('edit', { ns: 'common' })} icon={<IconEdit size={20} />} />
                    </Link>
                    <IconButton icon={<IconTrash />} action={() => setOpenDelelteModal(!openDeleteModal)} />
                </div>
            </HasPermission>

            {openDeleteModal && (
                <ModalEliminar
                    title={t('deleteCosts', { ns: 'costs' })}
                    subtitle={`${t('delete', { ns: 'costs' })} '${cost.observaciones}'?`}
                    openModal={openDeleteModal}
                    setOpenModal={setOpenDelelteModal}
                    deleteFunction={deleteFunction}
                    id={cost.id}
                />
            )}
        </Card>
    );
};
export default withAuth(CostResult);
