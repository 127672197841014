import fetcher from '../../../lib/fetcher';

export const getDocumentById = async (promotionId, id) => {
    const URL = `/api/documents/promotion/${promotionId}?id.equals=${id}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
