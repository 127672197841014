import { Thunk } from 'modules/shared/domain/store/createStore';

import { WasteRegisterExtended } from 'modules/waste-register/domain/WasteRegisterExtended';
import { WasteRegisterStore } from 'modules/waste-register/domain/WasteRegisterStore';

type Store = WasteRegisterStore; // Define Store here

export const saveWasteRegister =
    (
        wasteRegister: WasteRegisterExtended
    ): Thunk<Store, { data: WasteRegisterExtended; error: null } | { data: null; error: Error }> =>
    async (store, { api }) => {
        try {
            store.set((draft) => {
                draft.updating[wasteRegister.id] = wasteRegister;
                draft.loading = 'updating';

                draft.updated = {};
                draft.invalid = {};
                draft.error = null;
            });
            const data = await api.save(wasteRegister);

            store.set((draft) => {
                delete draft.updating[wasteRegister.id];
                delete draft.invalid[wasteRegister.id];
                draft.loading = 'succeeded';

                draft.updated[wasteRegister.id] = wasteRegister;
                draft.data.byId[wasteRegister.id] = wasteRegister;
                draft.total += 1;
            });
            return { data, error: null };
        } catch (e) {
            const error = e as Error;

            store.set((draft) => {
                delete draft.updating[wasteRegister.id];
                draft.loading = 'failed';

                draft.invalid[wasteRegister.id] = error;
            });

            return { data: null, error };
        }
    };
