import { useEffect } from 'react';

import { useAuth } from 'modules/auth';

export function useAuthBootstrap() {
    const [auth, { loadAuth }] = useAuth();

    useEffect(() => {
        loadAuth();
    }, []);

    return { auth };
}
