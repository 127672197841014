import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IconInfoCircle } from '@tabler/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withAuth } from '../../../context/AuthProvider';
import Layout from '../../../pages/Layout/Layout';
import { TituloH1 } from '../../../components/BasicComponents/Titulos/Titulos';
import CreateButton from '../../../components/BasicComponents/Buttons/CreateButton';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import AlertCard from '../../../components/BasicComponents/Cards/AlertCard';
import { getDownloads } from '../api/getDownloads';
import { deleteDownload } from '../api/deleteDownload';
import './Documentation.scss';
import GoBack from '../../../components/BasicComponents/Buttons/GoBack';
import DocDownloadRequest from './DocDownloadRequest';

const DownloadListPage = (props) => {
    const [t] = useTranslation();
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState(0);
    const [downloads, setDownloads] = useState([]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (props.promocion?.id) {
                getAllDownloads(0);
            }
        }
        return () => {
            mounted = false;
        };
    }, [props.promocion?.id]);

    const getAllDownloads = async (page = 0) => {
        setLoading(true);
        const filter = `&page=${page}`;
        const downloadsRequest = await getDownloads({ promotionId: props?.promocion?.id }, filter);

        if (downloadsRequest?.data?.code == 0) {
            setDownloads(downloadsRequest?.data?.content);
            setPagination(pagination + 1);
        }

        setLoading(false);
    };

    const handleLoadMore = () => {
        getAllDownloads(pagination);
    };

    const checkIfDownloadPending = () => {
        return downloads?.some((download) => download?.status === 'PENDING');
    };

    const cancelDownloadRequest = async (id) => {
        const downloadsRequest = await deleteDownload(id);

        if (downloadsRequest?.status == 204) {
            const updatedDownloads = downloads?.filter((res) => res.id !== id);
            setDownloads(updatedDownloads);
        }
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <div className="create-button">
                <TituloH1 titulo={t('downloadDocs', { ns: 'documentation' })} />
                <CreateButton text={t('selectDocuments', { ns: 'documentation' })} to="/documents/download/create" />
            </div>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {checkIfDownloadPending() && (
                        <AlertCard
                            title={t('preparingDownload.title', { ns: 'documentation' })}
                            icon={<IconInfoCircle size={24} />}
                        >
                            <p>{t('preparingDownload.description', { ns: 'documentation' })}</p>
                        </AlertCard>
                    )}
                    <div className="downloadListPage__results">
                        {downloads?.length > 0 ? (
                            <InfiniteScroll
                                dataLength={downloads.length}
                                next={() => handleLoadMore()}
                                hasMore={true}
                                scrollableTarget="main-layout"
                            >
                                {downloads?.map((download, i) => (
                                    <DocDownloadRequest
                                        key={i}
                                        download={download}
                                        deleteFunction={cancelDownloadRequest}
                                    />
                                ))}
                            </InfiniteScroll>
                        ) : (
                            <p>{t('noDownloads', { ns: 'documentation' })}</p>
                        )}
                    </div>
                </>
            )}
        </Layout>
    );
};
export default withAuth(DownloadListPage);
