import DashboardPage from '../components/DashboardPage';

export const GLOBAL_DASHBOARD_ROUTES = [
    {
        component: DashboardPage,
        path: '/dashboard',
        requiredPermission: 'canView',
        section: 'dashboard'
    }
];

export const DASHBOARD_ROUTES = [
    {
        component: DashboardPage,
        path: '/promotion/:promotionId/dashboard',
        requiredPermission: 'canView',
        section: 'dashboard'
    }
];
