import { useTranslation } from 'react-i18next';

import SubmitButton from 'components/BasicComponents/Buttons/SubmitButton';
import MessageNotComplete from 'components/BasicComponents/Messages/MessageNotComplete';
import { useRelatedEnterprisesData } from 'modules/enterprise/related-enterprise/infraestructure/store/relatedEnterpriseStore';
import { ApiRelatedEnterprises } from 'modules/shared/domain/ApiPromotion';
import { withAuth } from '../../../../context/AuthProvider';
import SimpleCard from '../../../BasicComponents/Cards/SimpleCard';
import { TituloH2 } from '../../../BasicComponents/Titulos/Titulos';
import NewRelatedEnterpriseForm from './NewRelatedEnterpriseForm';
import RelatedEnterprise from './RelatedEnterprise';

const RelatedEnterprisesStep = ({ submitting, createPromotionForm, messageNotComplete }) => {
    const [t] = useTranslation();
    const [state] = useRelatedEnterprisesData();
    const { relatedEnterprises, loading } = state;
    const buttonDisabled = submitting || loading === 'saving';

    const fakeRelatedEnterprise: ApiRelatedEnterprises = {
        id: '',
        enterpriseId: 0,
        enterpriseName: '',
        enterpriseCif: '',
        client: true,
        enterpriseRole: '',
        mainEnterprise: false
    };

    return (
        <SimpleCard className="relatedEnterprisesStep">
            <TituloH2 titulo={'Empresas relacionadas'} />

            <div className="relatedEnterprisesStep__list" data-testid="relatedEnterprisesList">
                {loading === 'pending' ? (
                    [...Array(3)].map((e, i) => (
                        <RelatedEnterprise
                            key={i}
                            relatedEnterprise={fakeRelatedEnterprise}
                            classNames="relatedEnterprise--is-skeleton"
                        />
                    ))
                ) : (
                    <>
                        {relatedEnterprises.length > 0 ? (
                            <form onSubmit={createPromotionForm}>
                                {relatedEnterprises.map((relatedEnterprise) => (
                                    <RelatedEnterprise
                                        key={relatedEnterprise.id}
                                        relatedEnterprise={relatedEnterprise}
                                    />
                                ))}
                                <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={buttonDisabled} />
                            </form>
                        ) : (
                            <i>Todavía no hay empresas relacionadas para este proyecto</i>
                        )}
                    </>
                )}
            </div>
            {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
            <NewRelatedEnterpriseForm />
        </SimpleCard>
    );
};

export default withAuth(RelatedEnterprisesStep);
