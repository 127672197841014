import fetcher from 'lib/fetcher';
import { NotificationSetting } from 'modules/notification-settings/domain/NotificationSetting';
import { NotificationSettingsApi } from 'modules/notification-settings/domain/NotificationSettingsApi';
import { StakeholderType } from 'modules/notification-settings/domain/Stakeholder';

export const httpNotificationSettingsApi: NotificationSettingsApi = {
    async findByCriteria(criteria) {
        const params = {
            'promotionId.in': criteria.promotionIds?.join(','),
            'stakeholderType.equals': mapStakeholderTypeToApi(criteria.stakeholderType),
            'type.in': criteria.type === 'waste-register' ? 'WASTE_REGISTER' : '',
            // load all at once
            size: 999,
            page: 0
        };

        const { data } = await fetcher.get('/api/notification-settings', { params });

        const modifiedData = data?.content?.map((notificationSetting) => {
            const modifiedNotificationSetting: NotificationSetting = {
                ...notificationSetting,
                type: criteria.type,
                data: {
                    ...notificationSetting.data,
                    stakeholderType: mapStakeholderTypeToFront(notificationSetting.data.stakeholderType)
                }
            };
            return modifiedNotificationSetting;
        });

        return modifiedData;
    },

    // stakeholders that appear in ppgcls (ask fran)
    async getStakeholders(criteria) {
        const params = {
            'promotionId.in': criteria.promotionIds?.join(','),
            'stakeholderName.contains': criteria.name,
            'stakeholderType.equals': mapStakeholderTypeToApi(criteria.type),
            page: criteria.page
        };

        const { data } = await fetcher.get('/api/notification-settings/stakeholders', { params });
        return data?.content || [];
    },

    async getStakeholderDefaultEmail(stakeholderId) {
        const { data } = await fetcher.get(`/api/waste-manager/${stakeholderId}`);
        return data?.mail || '';
    },

    async save(newNotificationSetting, promotionId) {
        const apiNotificationSetting = {
            ...newNotificationSetting,
            promotionId: promotionId,
            type: mapTypeToApi(newNotificationSetting.type),
            data: {
                stakeholderId: newNotificationSetting.data.stakeholderId,
                stakeholderType: mapStakeholderTypeToApi(newNotificationSetting.data.stakeholderType)
            }
        };

        const response = await fetcher.put(`/api/notification-settings`, apiNotificationSetting);
        return response.data;
    },

    async delete(id) {
        console.log('DELETED NOTIFICATION SETTING: ' + id);
        const response = await fetcher.delete(`/api/notification-settings/${id}`);
        console.log(response);

        return response.data;
    }
};

const mapStakeholderTypeToApi = (stakeholderType: StakeholderType | undefined) => {
    if (stakeholderType === 'waste-manager') return 'WASTE_MANAGER';
    if (stakeholderType === 'carrier') return 'CARRIER';
    return undefined;
};

const mapStakeholderTypeToFront = (stakeholderType: 'WASTE_MANAGER' | 'CARRIER') => {
    if (stakeholderType === 'WASTE_MANAGER') return 'waste-manager';
    if (stakeholderType === 'CARRIER') return 'carrier';
    return '';
};

const mapTypeToApi = (type: NotificationSetting['type']) => {
    if (type === 'waste-register') return 'WASTE_REGISTER';
    return '';
};
