import EditProfile from '../pages/Profile/EditProfile';
import MyProfile from '../pages/Profile/MyProfile';

export const PROFILE_ROUTES = [
    {
        component: MyProfile,
        path: '/profile',
        requiredPermission: 'canView',
        section: 'profile'
    },
    {
        component: EditProfile,
        path: '/profile/edit',
        requiredPermission: 'canCreate',
        section: 'profile'
    }
];
