import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';

import { DEFAULT_OPTIONS_AUTOCOMPLETE } from 'components/BasicComponents/Select-Autocomplete/autocomplete.helpers';

import usePrevious from 'hooks/usePrevious';
import { FilterProps } from '../FilterProps';
import './SelectFilter.scss';
import { selectFilterAsyncPaginateStyles } from './selectFilterAsyncPaginateStyles';

export type SelectOption = { value: any; label: string | ReactElement; [key: string]: any };

export type SelectAsyncOptions = (
    search: string,
    loadedOptions: SelectOption[],
    additional?: { page: number }
) => Promise<{ options: SelectOption[]; hasMore?: boolean; additional?: { page: number } }>;

interface SelectFilterProps extends FilterProps {
    label: string;
    name: string;
    value?: any;
    onChange?: (option?: SelectOption) => any;
    onClear?: () => any;
    options?: SelectOption[];
    loadOptions?: SelectAsyncOptions;
    isMultiple?: boolean;
    disabled?: boolean;
}

const SelectFilter = (props: SelectFilterProps) => {
    const [t] = useTranslation();
    const [value, setValue] = useState();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const prevValue = usePrevious(value);

    useEffect(() => {
        // prevents emitting when value not changed
        if (JSON.stringify(prevValue) === JSON.stringify(value)) return;

        if (value === undefined) return;
        if (props.onChange) props.onChange(value);
    }, [JSON.stringify(value)]);

    useEffect(() => {
        if (props.value === undefined) return;
        if (props.value) setValue(props.value);
    }, [JSON.stringify(props.value)]);

    const modifiers = {
        hasValue: !!value ? 'SelectFilter--has-value' : '',
        isChip: props.variant === 'chip' ? 'SelectFilter--is-chip' : '',
        disabled: props.disabled ? 'SelectFilter--disabled' : ''
    };

    const staticOptions = (search: string) => {
        const options = (props.options || []).filter((option) => {
            if (typeof option.label !== 'string') return undefined;

            return option.label.toLowerCase().includes(search.toLowerCase());
        });

        return { options };
    };

    return (
        <div className={`SelectFilter ${Object.values(modifiers).join(' ')}`}>
            <label className="input__label" id={props.name}>
                {props.label}
            </label>

            <div className="SelectFilter__select">
                <AsyncPaginate
                    {...DEFAULT_OPTIONS_AUTOCOMPLETE}
                    aria-labelledby={props.name}
                    placeholder={t('select.selectAnOption')}
                    defaultOptions={true}
                    value={value}
                    onChange={setValue}
                    styles={selectFilterAsyncPaginateStyles()}
                    isDisabled={props.disabled}
                    menuIsOpen={menuIsOpen}
                    onMenuClose={() => setMenuIsOpen(false)}
                    onMenuOpen={() => setMenuIsOpen(true)}
                    loadOptions={props.loadOptions || staticOptions}
                    isMulti={props.isMultiple}
                    isClearable={!props.isMultiple}
                    debounceTimeout={400}
                />
            </div>
        </div>
    );
};

export default SelectFilter;
