import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { entitiesAdapter } from 'modules/shared/domain/utils/entitiesAdapter';
import { TraceabilitiesCriteria } from 'modules/traceabilities/domain/TraceabilitiesCriteria';

import { TraceabilitiesStore } from '../../domain/TraceabilitiesStore';

export const loadTraceabilities =
    (
        newCriteria: TraceabilitiesCriteria,
        config?: {
            mode?: TraceabilitiesStore['actions']['loadTraceabilitiesPending']['payload']['mode'];
        }
    ): Thunk<TraceabilitiesStore> =>
    async (store, { api, logger }) => {
        const { mode = 'replace' } = config || {};

        const currentCriteria = store.state.criteria;
        const criteria = mode === 'merge' ? { ...currentCriteria, ...newCriteria } : newCriteria;

        store.dispatch('loadTraceabilitiesPending', { criteria, mode });

        try {
            const { traceabilities, total } = await api.getTraceabilities(criteria);

            store.dispatch('loadTraceabilitiesFulfilled', { traceabilities, total, mode });
        } catch (e) {
            const error = e as Error;

            logger.send(error);
            store.dispatch('loadTraceabilitiesRejected', { error });
        }
    };

export const loadTraceabilitiesReducers: Pick<
    Reducers<TraceabilitiesStore>,
    'loadTraceabilitiesPending' | 'loadTraceabilitiesFulfilled' | 'loadTraceabilitiesRejected'
> = {
    loadTraceabilitiesPending: (state, payload) =>
        produce(state, (draft) => {
            draft.loading.list = payload.mode === 'replace' ? 'pending' : 'updating';
            draft.criteria = payload.criteria;
        }),
    loadTraceabilitiesFulfilled: (state, { traceabilities, total, mode }) =>
        produce(state, (draft) => {
            const newTraceabilities = entitiesAdapter(traceabilities);
            const newIds = newTraceabilities.allIds;

            draft.data.allIds = mode === 'merge' ? [...state.data.allIds, ...newIds] : newIds;
            draft.data.byId = { ...state.data.byId, ...newTraceabilities.byId };
            draft.total = total;
            draft.loading.list = 'succeeded';
        }),
    loadTraceabilitiesRejected: (state, { error }) =>
        produce(state, (draft) => {
            draft.error = error;
            draft.loading.list = 'failed';
        })
};
