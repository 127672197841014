import './SmallMetricCard.scss';

const SmallMetricCard = ({ title, subtitle, unit, icon, className = '' }) => {
    return (
        <div className={`smallMetricCard ${className}`}>
            {icon}
            <div>
                <p className="smallMetricCard__title">
                    {title} {unit && <span>{unit}</span>}
                </p>
                <p className="smallMetricCard__subtitle">{subtitle}</p>
            </div>
        </div>
    );
};

export default SmallMetricCard;
