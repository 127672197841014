import { logger } from 'logger';

import { httpTraceabilitiesApi, TraceabilitiesProvider } from 'modules/traceabilities';
import TraceabilitiesListKPIsPrintable from './TraceabilitiesListKPIsPrintable';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return (
        <TraceabilitiesProvider api={httpTraceabilitiesApi} logger={logger}>
            <TraceabilitiesListKPIsPrintable />
        </TraceabilitiesProvider>
    );
};
