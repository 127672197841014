import { useTranslation } from 'react-i18next';

import KPITile from 'features/KPIs/components/KPITile';
import { Widget } from 'features/KPIs/types/Widget';
import { KPIStatus, useMixSummary } from 'modules/promotion-kpis';

import KPIThermometer from '../../components/KPIThermometer';
import MixDetail from './components/MixDetail';

const useMixWidget: Widget = (props) => {
    const [t] = useTranslation('kpis');

    const { data, error } = useMixSummary(props);

    const recoverable = data?.recoverable;
    const status = KPIStatus[recoverable?.status || 'INDETERMINATE'];

    return {
        status,
        Tile: () => {
            return (
                <KPITile
                    label={t('mix.label')}
                    unit={t('mix.unit')}
                    value={recoverable?.value}
                    hasError={!!error}
                    status={status}
                >
                    <KPIThermometer
                        maxValue={100}
                        value={recoverable?.value}
                        thresholds={recoverable?.goals.map((goal) => ({
                            ...goal,
                            type: 'MAX' as const,
                            value: goal.value.value
                        }))}
                        status={status}
                    />
                </KPITile>
            );
        },
        Detail: () => {
            if (!data) return null;

            return (
                <MixDetail summary={data} promotionId={props.promotionId} promotionProgress={props.promotionProgress} />
            );
        }
    };
};

export default useMixWidget;
