import { useEffect } from 'react';

import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';
import { useQuery } from 'hooks/useQuery';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import { TraceabilitiesCriteria, useTraceabilities } from 'modules/traceabilities';

export function useLoadTraceabilitiesFromUrl() {
    const query = useQuery();
    const [{ promotion }] = usePromotion();
    const [, { loadTraceabilities }] = useTraceabilities();

    const urlFilters: TraceabilitiesCriteria = {
        promotionId: promotion?.id || NaN,
        ...queryParamsToCriteriaAdapter(query.params)
    };

    useEffect(() => {
        loadTraceabilities(urlFilters);
    }, [JSON.stringify(urlFilters)]);
}

const queryParamsToCriteriaAdapter = (query: { [key: string]: any }): Partial<TraceabilitiesCriteria> => ({
    status: ['COMPLETED', 'WARNING'],
    dateFrom: query.date?.from,
    dateTo: query.date?.to,
    lerCodes: multiselectAdapter(query.lerCodes),
    lerTypes: multiselectAdapter(query.lerTypes),
    wasteManagers: multiselectAdapter(query.wasteManagers),
    carriers: multiselectAdapter(query.carriers),
    valorization: query.valorization?.value,
    sort: query.sort?.value,
    updatedBy: query.updatedBy,
    excludeLands: query.excludeLands,
    ...(query.di ? { certified: query.di.value === 'certified' } : {})
});

const multiselectAdapter = (options: SelectOption[] | SelectOption) => {
    if (!options) return undefined;
    return Array.isArray(options) ? options?.map((o) => o.value) : [options.value];
};
