import { IconEye } from '@tabler/icons';

const ActionMenuDocumentShow = (props) => {
    return (
        <li {...props} role="button">
            <IconEye stroke={1.25} size={20} /> Mostrar
        </li>
    );
};

export default ActionMenuDocumentShow;
