import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { RelatedEnterprisesStore } from '../../domain/RelatedEnterprisesStore';

export const removeRelatedEnterprise =
    (relatedEnterpriseId: string): Thunk<RelatedEnterprisesStore> =>
    async (store) => {
        store.dispatch('removeStaged', { relatedEnterpriseId });
    };

export const removeRelatedEnterpriseReducers: Pick<Reducers<RelatedEnterprisesStore>, 'removeStaged'> = {
    removeStaged: (state, payload) => {
        return produce(state, (draft) => {
            draft.relatedEnterprises = draft.relatedEnterprises.filter(
                (relatedEnterprise) => relatedEnterprise.id !== payload.relatedEnterpriseId
            );
        });
    }
};
