import { Thunk } from 'modules/shared/domain/store/createStore';
import { createAutomaticReadingPermissions } from '../../domain/Permissions/createAutomaticReadingPermissions';
import { AutomaticReadingPermissionsStore } from '../../domain/AutomaticReadingPermissionsStore';

export const loadAutomaticReadingPermissions =
    (): Thunk<AutomaticReadingPermissionsStore> => async (store, repositories) => {
        store.dispatch('loadAutomaticReadingPermissionsPending', null);
        try {
            const promotion = await repositories.promotion.getCurrent();

            const { canAccess } = await repositories.api.getTracingDiExtractionAccess({
                promotionId: promotion.id.toString()
            });
            const permissions = createAutomaticReadingPermissions({ canAccess, nima: promotion.nima });

            store.dispatch('loadAutomaticReadingPermissionsFulfilled', { permissions });
        } catch (error) {
            store.dispatch('loadAutomaticReadingPermissionsRejected', { error: error as Error });
        }
    };
