import { useEffect, useState } from 'react';

import { Carrier } from 'modules/carrier';
import { Ler } from 'modules/ler';
import { WasteFlow, wasteFlowHooks } from 'modules/waste-flow';
import { WasteManager } from 'modules/waste-manager';

/** Keeps state and finds a waste flow that matches with given filters */
export function useWasteFlowSelector(props: {
    promotionId: number;
    flow?: WasteFlow | null;
    ler?: Ler;
    manager?: WasteManager;
    carrier?: Carrier | null;
}) {
    const { ler, manager, carrier } = props;
    const [flow, setFlow] = useState<WasteFlow | undefined | null>(props.flow);

    const shouldFindFlow = ler && manager && carrier;

    const flows = wasteFlowHooks.useWasteFlows({
        promotionId: props.promotionId,
        ler: { code: ler?.code },
        manager: { name: manager?.name },
        carrier: { name: carrier?.name },
        size: shouldFindFlow
            ? 999 // Because we need to filter by carrier in the frontend
            : 0
    });

    const foundFlow = flows?.data?.length === 1 ? flows.data[0] : undefined;

    // Update the flow when the found flow changes
    useEffect(() => {
        if (foundFlow) setFlow(foundFlow);
    }, [foundFlow]);

    return { flow, setFlow, loading: flows.loading } as const;
}
