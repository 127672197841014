import { Widget } from 'features/KPIs/types/Widget';
import useMandatoryFlowsWidget from 'features/KPIs/widgets/useMandatoryFlowsWidget';
import useMixWidget from 'features/KPIs/widgets/useMixWidget';
import useValorizationWidget from 'features/KPIs/widgets/useValorizationWidget';
import { usePromotion } from 'modules/promotion';
import { PromotionKPIs } from 'modules/promotion-kpis';
import './KPIDetailPrintable.scss';

export interface KPIDetailPrintableProps {
    kpi: PromotionKPIs;
}

const KPIDetailPrintable = (props: KPIDetailPrintableProps) => {
    const [{ promotion }] = usePromotion();

    const params = {
        promotionId: promotion?.id.toString() || '',
        promotionProgress: promotion?.progress || 0
    };

    const widgets: { [key: string]: ReturnType<Widget> } = {
        valorization: useValorizationWidget(params),
        mix: useMixWidget(params),
        'mandatory-flows': useMandatoryFlowsWidget(params)
    };

    const widget: ReturnType<Widget> | undefined = widgets[props.kpi as keyof typeof widgets];

    if (!widget) return null;

    return (
        <>
            <div className="KPIDetailPrintable">
                <div className="KPIDetailPrintable__tile">{widget.Tile?.() || null}</div>
                <div className="KPIDetailPrintable__detail">{widget.Detail?.() || null}</div>
            </div>
        </>
    );
};

export default KPIDetailPrintable;
