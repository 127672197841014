import { customStyles } from 'components/BasicComponents/Select-Autocomplete/helpers';

const baseStyles = customStyles();

export const selectFilterAsyncPaginateStyles = () => {
    return {
        ...baseStyles,
        container: (styles) => ({
            ...styles,
            borderRadius: '10px'
        }),
        control: (styles) => ({
            ...styles,
            background: 'rgb(247, 247, 248)',
            borderRadius: '10px',
            padding: '2px 3px',
            boxShadow: 'none',
            fontSize: '1.3rem',
            lineHeight: '1',
            fontWeight: '500',
            minHeight: '4rem',
            border: 'none',
            margin: '0'
        }),
        placeholder: (styles) => ({
            ...styles,
            color: '#b4b8c1', // $tooltip-grey,
            position: 'static',
            transform: 'none',
            marginLeft: '12px'
        }),
        singleValue: (styles) => ({
            ...styles,
            color: 'hsl(260, 90%, 54%)', //$purple
            fontSize: '1.3rem', // 1.3rem
            background: 'hsl(240, 100%, 95%)', //$light-purple
            padding: '9.5px 8px',
            borderRadius: '8px',
            margin: '2px 0',
            position: 'static',
            transform: 'none',
            maxWidth: `calc(100% - 6px)`
        }),
        valueContainer: (styles, options) => {
            return {
                ...styles,
                padding: 0,
                gap: options.isMulti ? '0.4rem' : '0',
                minHeight: '32px',
                flexWrap: options.isMulti ? 'wrap' : 'nowrap'
            };
        },
        indicatorsContainer: (styles, options) => ({
            ...styles,
            // display: options.isMulti ? 'none' : styles.display,
            // indicatorContainer
            '& > *:nth-child(1)': {
                padding: '8px 8px 8px 0px',
                transform: 'scale(0.85)',
                cursor: 'pointer'
            }
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            display: 'none'
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            display: 'none'
        }),
        multiValue: (styles) => ({
            ...styles,
            fontSize: '1.3rem',
            background: 'hsl(240, 100%, 95%)', //$light-purple
            padding: '6.5px 8px',
            borderRadius: '8px',
            margin: '0',
            maxWidth: '329px',
            // Text
            '& > *:nth-child(1)': {
                color: 'hsl(260, 90%, 54%) !important', //$purple
                fontSize: 'inherit !important'
            },
            // Close icon
            '& > *:nth-child(2)': {
                transform: 'scale(1.2)',
                color: 'hsl(260, 90%, 54%) !important', //$purple
                opacity: '0.35',
                cursor: 'pointer',
                background: 'none !important'
            }
        }),
        input: (styles) => ({
            ...styles
        }),
        menu: (styles) => ({
            ...styles,
            minWidth: '17rem'
        })
    };
};
