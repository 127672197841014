import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { withAuth } from '../../../context/AuthProvider';
import { activityStatus, constructionSiteStatus } from '../../../utils/constants/activities.constants';
import { customStyles, hasMore } from './helpers';

const ActivityStatusSelectAutocomplete = ({ isConstructionSite = false, ...props }) => {
    const [t] = useTranslation();
    const [defaultValue, setDefaultValue] = useState({ value: '', label: '' });

    const { estadoActividad } = props.values;
    const { setValues, values, required, className } = props;

    useEffect(() => {
        getActivityStatus();
    }, [estadoActividad]);
    const filterContructionSiteStatus = () => {
        return constructionSiteStatus;
    };
    const getActivityStatus = () => {
        const arrayStatus = isConstructionSite ? filterContructionSiteStatus() : activityStatus;
        const status = arrayStatus?.find((status) => status.value === estadoActividad);
        setDefaultValue(status);
    };

    const loadOptionsActivityStatus = async ({ page }) => {
        const status = isConstructionSite ? filterContructionSiteStatus() : activityStatus;
        return {
            options: status,
            hasMore: hasMore(status),
            additional: {
                page: page + 1
            }
        };
    };

    const activityStatusChanged = (item) => {
        setValues({ ...values, estadoActividad: item?.value });
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {t('status.label', { ns: 'activities' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={loadOptionsActivityStatus}
                onChange={activityStatusChanged}
                getOptionLabel={(option) => (option?.label ? t(`status.${option?.label}`, { ns: 'activities' }) : '')}
                getOptionValue={(option) => option?.value}
                value={defaultValue}
                styles={customStyles()}
                placeholder=""
                additional={{
                    page: 0
                }}
                isClearable={true}
            />
        </div>
    );
};
export default withAuth(ActivityStatusSelectAutocomplete);
