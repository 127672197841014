import { IconX } from '@tabler/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import userServices from '../../../../api/user/user.services';
import { withAuth } from '../../../../context/AuthProvider';
import CreateButton from '../../../BasicComponents/Buttons/CreateButton';
import SubmitButton from '../../../BasicComponents/Buttons/SubmitButton';
import SimpleCard from '../../../BasicComponents/Cards/SimpleCard';
import MessageNotComplete from '../../../BasicComponents/Messages/MessageNotComplete';
import SimplePopover from '../../../BasicComponents/Popovers/SimplePopover';
import { TituloH2 } from '../../../BasicComponents/Titulos/Titulos';
import NewUserForm from './NewUserForm';
import SearchUsers from './SearchUsers';
import './Users.scss';

const Users = ({ promotion, setPromotion, createPromotionForm, submitting, messageNotComplete, users, setUsers }) => {
    const [t] = useTranslation();

    const [toggleFormUser, setToggleFormUser] = useState(false);

    const deleteUser = async (user) => {
        const idsPromotions = user.promotionIds.filter((ids) => ids !== promotion.id);
        const userWithoutPromotionId = { ...user, promotionIds: idsPromotions };
        await userServices.editUser(userWithoutPromotionId);
        setUsers(users.filter((users) => users.id !== userWithoutPromotionId.id));
    };

    return (
        <SimpleCard>
            <div className="users__buttons">
                <TituloH2 titulo={t('usersPromotion', { ns: 'newPromotion' })} />
                <CreateButton
                    text={t('createUser', { ns: 'newPromotion' })}
                    type="button"
                    action={() => setToggleFormUser(!toggleFormUser)}
                />
            </div>
            <form onSubmit={createPromotionForm} className="users__form">
                <SearchUsers required users={users} setUsers={setUsers} />
                {users &&
                    users.length > 0 &&
                    users.map((user) => (
                        <p className="users__usersSelected" key={user.id}>
                            {user.firstName} {user.lastName} - {user.email} - {user.roleType.descripcion}
                            <IconX size={15} onClick={() => deleteUser(user)} />
                        </p>
                    ))}

                {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
                <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={submitting} />
            </form>
            {toggleFormUser && (
                <SimplePopover
                    openModal={toggleFormUser}
                    setOpenModal={setToggleFormUser}
                    title={t('newUser', { ns: 'users' })}
                >
                    <NewUserForm
                        promotion={promotion}
                        setPromotion={setPromotion}
                        setToggleFormNewUser={setToggleFormUser}
                        {...{ users, setUsers }}
                    />
                </SimplePopover>
            )}
        </SimpleCard>
    );
};
export default withAuth(Users);
