export const loadingState = {
    IDLE: 'idle',
    PENDING: 'pending',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed'
};

export const initialState = {
    loading: loadingState.IDLE,
    documentTypesBySection: {
        cocircularDocuments: { type: 'unique', documentTypes: [] },
        sealDocuments: { type: 'unique', documentTypes: [] },
        traceabilityDocuments: { type: 'folder', documentTypes: [] },
        wasteManagerDocuments: { type: 'folder', documentTypes: [] },
        projectDocuments: { type: 'unique', documentTypes: [] }
    },
    error: null
};
