import { IconTruckDelivery } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

import RoundedIcon from 'components/BasicComponents/Icon/RoundedIcon';
import InputFecha from 'components/BasicComponents/Inputs/InputFecha';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import { TituloH3 } from 'components/BasicComponents/Titulos/Titulos';
import { withAuth } from 'context/AuthProvider';
import fromEntities from 'lib/fromEntities';
import { usePromotionBilleables } from 'modules/billeables';

const OthersForm = ({ promotion, handleInputChange, siteVisitNum, validation, setPromotion }) => {
    const [t] = useTranslation();
    const [{ entities }] = usePromotionBilleables();
    const billeables = fromEntities(entities);

    const isNewPromotionMode = promotion?.id === undefined;
    const isNumVisitRequired = billeables.some((bill) => bill.name === 'SELLO_VALORIZA' && bill.purchased);

    const numVisitLabel = t('labels.visitsNumber', { ns: 'newPromotion' });
    const numVisitOptional = !isNumVisitRequired ? ` ${t('optional.sg', { ns: 'common' })}` : '';

    return (
        <>
            {isNewPromotionMode && (
                <>
                    <div className="ProductsAndServices__form-title">
                        <RoundedIcon icon={<IconTruckDelivery />} />
                        <TituloH3 titulo={t('labels.otherAddons', { ns: 'newPromotion' })} />
                    </div>
                    <div className="ProductsAndServices__container ProductsAndServices__visits">
                        <InputNumber
                            name="siteVisitNum"
                            label={numVisitLabel + numVisitOptional}
                            value={siteVisitNum}
                            onChange={handleInputChange}
                            validacion={validation}
                            formatNumber={false}
                            required={isNumVisitRequired}
                            htmlRequired={isNumVisitRequired}
                            min={isNumVisitRequired ? 1 : 0}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                        <InputFecha
                            name="startDateVisits"
                            label={'Generar visitas a partir de'}
                            value={promotion.startDateVisits}
                            onChange={handleInputChange}
                            validacion={validation}
                            minDate={promotion.startDateCocircular}
                            maxDate={promotion.fechaFin}
                            required={siteVisitNum}
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default withAuth(OthersForm);
