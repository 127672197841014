export const createDocumentTypesBySection = ({ buildingDocs, trackingDocs, wasteManagerDocs }) => {
    const documentTypesBySection = {
        cocircularDocuments: { documentTypes: buildingDocs.filter(isCoCircularDocument) },
        projectDocuments: { documentTypes: buildingDocs.filter(isProjectDocument) },
        wasteManagerDocuments: { documentTypes: wasteManagerDocs },
        traceabilityDocuments: { documentTypes: trackingDocs },
        sealDocuments: { documentTypes: buildingDocs.filter(isSealDocument) }
    };

    return documentTypesBySection;
};

const isCoCircularDocument = (document) => COCIRCULAR_DOC_TYPES.includes(document.id);
const isSealDocument = (document) => SEAL_DOC_TYPES.includes(document.id) || EVALUATION_DOC_TYPE === document.id;
const isProjectDocument = (document) => !isCoCircularDocument(document) && !isSealDocument(document);

// sello valoriza, sello recicla
const SEAL_DOC_TYPES = [676651, 676652];
// protocolo, guia de buenas practicas, carteleria, certificado de formación
const COCIRCULAR_DOC_TYPES = [417751, 442551, 394501, 479701];
// tipo de documento "proyecto" pero que es una carpeta
const EVALUATION_DOC_TYPE = 732551;
