import { useTranslation } from 'react-i18next';
import { IconChevronLeft, IconChevronRight, IconDeviceFloppy } from '@tabler/icons';
import SaveButton from '../Buttons/SaveButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import './Steppers.scss';

const Stepper = ({
    steps,
    currentStep,
    setCurrentStep,
    setFormIsEnding,
    children,
    setIsSaving,
    draftButton = true,
    finalButton = true
}) => {
    const [t] = useTranslation();

    const changeStep = (step) => {
        setCurrentStep(step);
    };

    const saveAndFinish = () => {
        setFormIsEnding(true);
        saveInfo();
    };

    const saveInfo = () => {
        setIsSaving(true);
    };

    return (
        <div>
            <div className="stepper__stepper">
                {steps.map((step) => (
                    <div
                        key={step?.id}
                        onClick={() => changeStep(step?.id)}
                        className={
                            currentStep === step?.id ? 'stepper__option stepper__option-active' : 'stepper__option'
                        }
                    >
                        <button>{step?.id + 1}</button>
                        <span>{step?.title}</span>
                    </div>
                ))}
            </div>

            {children}

            <div className="stepper__buttons">
                <div className="stepper__button1">
                    {currentStep !== 0 && (
                        <SecondaryButton
                            type="button"
                            titulo={t('previous', { ns: 'visit' })}
                            icon={<IconChevronLeft size={24} stroke={1.1} />}
                            action={() => changeStep(currentStep - 1)}
                        />
                    )}
                </div>
                {draftButton && (
                    <div className="stepper__button2">
                        <SaveButton
                            type="button"
                            text={t('save', { ns: 'visit' })}
                            icon={<IconDeviceFloppy size={24} stroke={1.5} />}
                            iconPosition="left"
                            action={() => saveInfo()}
                        />
                    </div>
                )}

                <div className="stepper__button3">
                    {currentStep !== steps?.length - 1 && (
                        <SecondaryButton
                            type="button"
                            titulo={t('next', { ns: 'visit' })}
                            icon={<IconChevronRight size={24} stroke={1.1} />}
                            iconPosition="right"
                            action={() => changeStep(currentStep + 1)}
                        />
                    )}

                    {currentStep !== steps?.length - 1 && finalButton && (
                        <SecondaryButton
                            type="button"
                            titulo={t('finish.button', { ns: 'visit' })}
                            action={() => saveAndFinish()}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Stepper;
