import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconArchive } from '@tabler/icons';
import visitRecordServices from 'api/siteChecking/visitRecord.services';
import {
    bestPracticesRNP,
    bestPracticesRP,
    bestPracticesRSU,
    dangerousContainerData,
    notDangerousContainerData,
    rsuContainerData
} from 'utils/constants/siteCheking.constants';
import { getIncidences } from 'utils/helpers/siteChecking.helpers';
import { openFileByURL } from 'utils/helpers/files.helpers';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import RoundedIcon from 'components/BasicComponents/Icon/RoundedIcon';
import { TituloH3, TituloH4 } from 'components/BasicComponents/Titulos/Titulos';
import BestPractices from 'components/ComplexComponents/SiteChecking/BestPractices';
import FileButton from 'components/BasicComponents/Buttons/FileButton';
import VisitRecordFieldResult from './VisitRecordFieldResult';
import './ActivitySiteCheckingSection.scss';

interface ActivitySiteCheckingSectionProps {
    activityId: number;
}

const ActivitySiteCheckingSection = ({ activityId }: ActivitySiteCheckingSectionProps) => {
    const [t] = useTranslation();
    const [incidences, setIncidences] = useState([]);
    const [openRows, setOpenRows] = useState([] as any);

    const [visitRecordsData, setVisitRecordsData] = useState([
        {
            id: 0,
            title: 'evaluation.rsu',
            containers: [],
            dangerous: false,
            rsu: true,
            bestPracticesText: bestPracticesRSU
        },
        {
            id: 1,
            title: 'evaluation.nonDangerous',
            containers: [],
            dangerous: false,
            rsu: false,
            bestPracticesText: bestPracticesRNP
        },
        {
            id: 2,
            title: 'evaluation.dangerous',
            containers: [],
            dangerous: true,
            rsu: false,
            bestPracticesText: bestPracticesRP
        }
    ]);

    useEffect(() => {
        loadVisitRecordsData();
    }, []);

    const loadVisitRecordsData = async () => {
        const visitRecordsRequest = await visitRecordServices.getVisitRecordsByActivityId(activityId);

        // really neccesary order visit records by ler kind (especially for incidences)
        const sortedVisitRecords = visitRecordsRequest?.content?.response?.sort((a, b) =>
            a?.lerType?.lerKind?.localeCompare(b?.lerType?.lerKind)
        );

        if (!sortedVisitRecords) return;

        separateVisitRecordsData(sortedVisitRecords);
        getInitialIncidences(sortedVisitRecords);
    };

    const separateVisitRecordsData = (visitRecords) => {
        let visitRecordsDataAux = [...visitRecordsData];

        visitRecordsDataAux[0].containers = visitRecords?.filter((visitRecord) => isRsu(visitRecord));
        visitRecordsDataAux[1].containers = visitRecords?.filter((visitRecord) => isValuableMaterial(visitRecord));
        visitRecordsDataAux[2].containers = visitRecords?.filter((visitRecord) => isDangerous(visitRecord));

        setVisitRecordsData(visitRecordsDataAux);
    };

    const isRsu = (vr) => vr?.existContainer && vr?.lerType?.rsu;
    const isValuableMaterial = (vr) => vr?.existContainer && !vr?.lerType?.rsu && !vr?.lerType?.dangerous;
    const isDangerous = (vr) => vr?.existContainer && !vr?.lerType?.rsu && vr?.lerType?.dangerous;

    const getInitialIncidences = (allVisitRecords) => {
        // We need an specific structure in order to get incidences
        const visitRecordsStructured = getVisitRecordsBySections(allVisitRecords);
        const incidences = getIncidences(visitRecordsStructured);
        const incidencesArray = incidences?.split('.');
        incidencesArray.pop(); // remove last element because its empty
        setIncidences(incidencesArray as any);
    };

    // Ugly ass code to adapt one structure to another... This is old and works, so I didn't want to change it
    const getVisitRecordsBySections = (containers) => {
        let sectionTypesAux = [] as any;

        if (containers?.length > 0) {
            let lastLerType = containers[0];
            let visitRecordsArray = [] as any;

            for (let i = 0; i < containers.length; i++) {
                if (containers[i]?.lerType?.id === lastLerType?.lerType?.id) {
                    visitRecordsArray.push(containers[i]);
                } else {
                    sectionTypesAux.push({
                        sectionName: lastLerType?.lerType?.lerKind,
                        containers: visitRecordsArray
                    });
                    visitRecordsArray = [];
                    lastLerType = containers[i];
                    i--;
                }
            }
            // Push last visit record
            sectionTypesAux.push({
                sectionName: lastLerType?.lerType?.lerKind,
                containers: visitRecordsArray
            });
        }
        return sectionTypesAux;
    };

    const getContainerData = (dangerous, rsu) => {
        if (rsu) return rsuContainerData;
        if (!rsu && dangerous) return dangerousContainerData;
        return notDangerousContainerData;
    };

    const translatedBestPractices = (bestPracticesArray) => {
        return bestPracticesArray.map((text) => t(text, { ns: 'visit' }));
    };

    const isRowOpen = (rowId) => {
        const isOpen = openRows.includes(rowId);
        return isOpen;
    };

    const hideOrOpenRow = (rowId) => {
        let openRowsAux = [...openRows];

        if (isRowOpen(rowId)) {
            openRowsAux = openRows.filter((currentRowId) => currentRowId !== rowId);
        } else {
            openRowsAux.push(rowId);
        }
        setOpenRows(openRowsAux);
    };

    return (
        <SimpleCard>
            <section className="ActivitySiteCheckingSection">
                <header className="ActivityDetail__cardTitle">
                    <RoundedIcon icon={<IconArchive />} />
                    <TituloH3 titulo={'Detalles de la visita a obra'} />
                </header>
                <div className="ActivitySiteCheckingSection__incidences">
                    <TituloH4 titulo={t('incidences.constructionSiteVisit', { ns: 'visit' })} />
                    {incidences?.length === 0 ? (
                        <i>{t('incidences.noGenerated', { ns: 'visit' })}</i>
                    ) : (
                        <ul>
                            {incidences?.map((incidence, i) => (
                                <li key={i}>{incidence}</li>
                            ))}
                        </ul>
                    )}
                </div>
                <div>
                    {visitRecordsData?.map((visitRecordSection, i) => (
                        <section className="ActivitySiteCheckingSection__wasteSection" key={i}>
                            <TituloH4 titulo={t(visitRecordSection?.title, { ns: 'visit' })} />
                            {visitRecordSection?.containers?.length > 0 ? (
                                <div className="ActivitySiteCheckingSection__tableWrapper">
                                    <table className="ActivitySiteCheckingSection__table">
                                        <tbody>
                                            {/* Workaround: Table headers inside tbody instead thead because print does not render well inside thead :( */}
                                            <tr className="ActivitySiteCheckingSection__desktopHeaders ActivitySiteCheckingSection__row">
                                                <th>{t('flowType', { ns: 'visit' })}</th>
                                                {getContainerData(
                                                    visitRecordSection?.dangerous,
                                                    visitRecordSection?.rsu
                                                ).map((data, j) => (
                                                    <th key={i + j + 'l'}>{t(data.label, { ns: 'visit' })}</th>
                                                ))}
                                                <th>{t('attach.attached', { ns: 'visit' })}</th>
                                            </tr>

                                            {visitRecordSection?.containers?.map((container: any, j) => (
                                                <>
                                                    <tr className="ActivitySiteCheckingSection__row">
                                                        <td
                                                            className="ActivitySiteCheckingSection__lerType"
                                                            title={container?.lerType?.lerKind}
                                                        >
                                                            {container?.lerType?.lerKind}
                                                        </td>
                                                        {getContainerData(
                                                            visitRecordSection?.dangerous,
                                                            visitRecordSection?.rsu
                                                        ).map((data, i) => (
                                                            <td key={i + j + 'c'}>
                                                                <span className="ActivitySiteCheckingSection__mobileHeaders">
                                                                    {t(data.label, { ns: 'visit' })}
                                                                </span>
                                                                <VisitRecordFieldResult
                                                                    isDangerous={visitRecordSection?.dangerous}
                                                                    field={data.field}
                                                                    value={container[data.field]}
                                                                />
                                                            </td>
                                                        ))}
                                                        <td>
                                                            {container?.documents?.length > 0 ? (
                                                                <FileButton
                                                                    opened={isRowOpen(container.id)}
                                                                    action={() => hideOrOpenRow(container.id)}
                                                                />
                                                            ) : (
                                                                <>-</>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    {container?.documents?.length > 0 && (
                                                        <tr>
                                                            <td colSpan={20}>
                                                                {container?.documents?.map((img) => (
                                                                    <img
                                                                        key={'img' + i + j}
                                                                        src={img?.preSignedUrl}
                                                                        alt={img?.title}
                                                                        onClick={() => openFileByURL(img?.preSignedUrl)}
                                                                        className={
                                                                            isRowOpen(container.id)
                                                                                ? 'ActivitySiteCheckingSection__image'
                                                                                : 'ActivitySiteCheckingSection__image ActivitySiteCheckingSection__image-hidden'
                                                                        }
                                                                    />
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <i>{t('container.noRecord', { ns: 'visit' })}</i>
                            )}
                            <BestPractices
                                arrayTexts={translatedBestPractices(visitRecordSection?.bestPracticesText)}
                            />
                        </section>
                    ))}
                </div>
            </section>
        </SimpleCard>
    );
};

export default ActivitySiteCheckingSection;
