import { produce } from 'immer';
import fromEntities from 'lib/fromEntities';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

import { BilleablesStore } from '../../domain/BilleablesStore';

export const saveBilleables =
    (criteria: { promotionId: number }): Thunk<BilleablesStore> =>
    async (store, { api, promotion }) => {
        store.dispatch('loadingSetted', { loading: 'saving' });

        try {
            api.savePromotionAddons({
                promotionId: criteria.promotionId,
                billeables: fromEntities(store.state.entities)
            });

            store.dispatch('savedStaged', null);
        } catch (e) {
            store.dispatch('errorSetted', { error: e as Error });
        }
    };

export const saveReducers: Pick<Reducers<BilleablesStore>, 'savedStaged'> = {
    savedStaged: (state) => {
        return produce(state, (draft) => {
            draft.loading = 'succeeded';
        });
    }
};
