import { Thunk } from 'modules/shared/domain/store/createStore';
import { AnalyticsStore } from '../../domain/AnalyticsStore';

type Store = AnalyticsStore; // Define Store here

export const init =
    (): Thunk<Store> =>
    async (store, { api }) => {
        try {
            const shouldInitialize = window.location.hostname === '360advisor.net';

            store.set((state) => {
                state.initialized = shouldInitialize;
            });

            if (shouldInitialize) await api.init();
        } catch (e) {
            const error = e as Error;
            store.set((state) => {
                state.error = error;
            });
        }
    };
