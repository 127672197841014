import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Children, PropsWithChildren, ReactElement, cloneElement, useCallback, useEffect, useState } from 'react';
import { IconAdjustmentsHorizontal, IconSearch, IconTrash } from '@tabler/icons';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import SidePanel from 'components/BasicComponents/SidePanel/SidePanel';
import { useQuery } from 'hooks/useQuery';

import './FilterBar.scss';
import { FilterProps } from '../FilterProps';

type Values = { [key: string]: any } | null;

interface FilterBarProps {
    className?: string;
    onChange?: (values: Values) => void;
    onFilterChange?: (name: string, value: any) => void;
}

const FilterBar = (props: PropsWithChildren<FilterBarProps>) => {
    const [values, setValues] = useState<Values>({});
    const [showFilters, setShowFilters] = useState(false);
    const history = useHistory();
    const [t] = useTranslation();
    const query = useQuery();

    useEffect(() => {
        if (!values || showFilters) return;
        if (Object.keys(values).length === 0) return;

        query.push(values || {}, { mode: values === null ? 'replace' : 'merge' });

        if (props.onChange) props.onChange(values);
    }, [JSON.stringify(values), showFilters]);

    useEffect(() => {
        setValues(query.params);
    }, [JSON.stringify(query.params)]);

    const onChangeInterceptor = useCallback(
        (value: any, child: ReactElement<FilterProps>) => {
            const { name } = child.props;
            const newValues = { ...values, [name]: value };

            // Because AsyncPaginate returns undefined in some weird cases
            // we need to prevent it from setting the value.
            // Null is a valid value for filter to reset to default.
            if (value === undefined) return;

            setValues(newValues);
            child.props.onChange?.(value || null);

            const prevValue = values && values[name];
            if (JSON.stringify(prevValue) !== JSON.stringify(value)) props.onFilterChange?.(name, value);
        },
        [values]
    );

    const clearAll = () => {
        /** We use "null" instead of "{}"" so that the useEffect can discern
         * the "cleaned" state (in which case it must perform a "replace")
         * from the default empty state (in which case it must perform a "merge"). */
        setValues(null);
        history.push({ search: null });

        setShowFilters(false);
    };

    const filters = Children.toArray(props.children) as ReactElement<FilterProps>[];
    const isEmpty = !filters.some((filter) => (values || {})[filter.props.name]);

    const modifiers = {
        parent: props.className || '',
        isEmpty: isEmpty ? 'FilterBar--is-empty' : ''
    };

    return (
        <>
            <div className={`FilterBar ${Object.values(modifiers).join(' ')}`}>
                {filters
                    .filter((filter) => (values || {})[filter.props.name] !== undefined)
                    .map((filter) => (
                        <div
                            key={filter.props.name}
                            className={`FilterBar__filter ${
                                (values || {})[filter.props.name]
                                    ? 'FilterBar__filter--visible'
                                    : 'FilterBar__filter--hidden'
                            }`}
                        >
                            {cloneElement(filter, {
                                onChange: (value: any) => {
                                    if (showFilters) return; // Prevents emitting onChange a second time from filter bar when filters panel is open
                                    onChangeInterceptor(value, filter);
                                },
                                value: (values || {})[filter.props.name],
                                variant: 'chip'
                            })}
                        </div>
                    ))}

                <div className="FilterBar__buttonContainer">
                    <SmallButton
                        action={() => setShowFilters(!showFilters)}
                        className="FilterBar__button"
                        titulo={t(isEmpty ? 'filters.filtersEmptyButtonText' : 'filters.withValueButtonText')}
                        icon={<IconAdjustmentsHorizontal />}
                        iconPosition="left"
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                </div>
            </div>
            <SidePanel
                testid="filters-modal"
                title={t('filters.modalTitle')}
                openModal={showFilters}
                setOpenModal={setShowFilters}
            >
                <div className="FilterBar__modalFilters">
                    {filters.map((filter) =>
                        cloneElement(filter, {
                            onChange: (value: any) => {
                                onChangeInterceptor(value, filter);
                            },
                            value: (values || {})[filter.props.name],
                            variant: 'control'
                        })
                    )}
                    <footer className="FilterBar__modalFiltersFooter">
                        <SmallButton
                            action={clearAll}
                            titulo={t('filters.clearAll')}
                            icon={<IconTrash />}
                            iconPosition="left"
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                        <SmallButton
                            color="purple"
                            action={() => setShowFilters(!showFilters)}
                            titulo={t('filters.search')}
                            icon={<IconSearch />}
                            iconPosition="left"
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                    </footer>
                </div>
            </SidePanel>
        </>
    );
};

export default FilterBar;
