import { AutomaticReadingPermissions } from './AutomaticReadingPermissions';

export const createAutomaticReadingPermissions = (sources: {
    canAccess: { value: boolean };
    nima: string | null;
}): AutomaticReadingPermissions => {
    const hasNima = !!sources.nima;

    const disabledRules = !hasNima && { value: true, reason: 'promotionWithoutNima' };

    return {
        canAccess: sources.canAccess,
        isDisabled: disabledRules || { value: false, reason: null }
    };
};
