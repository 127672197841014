import { useState } from 'react';

import { usePromotion } from 'modules/promotion';
import NewCodLersRequest from '../../components/ComplexComponents/PromotionSettings/NewCodLersRequest';
import NewWasteManagerRequest from '../../components/ComplexComponents/PromotionSettings/NewWasteManagerRequest';
import CardPpgclSettings from '../../components/ComplexComponents/WasteManagerDashboard/CardPpgclSettings';
import './DetailPromotion.scss';

const DetailPromotion = () => {
    const [{ promotion }] = usePromotion();

    const [openCodLerPopover, setOpenCodLerPopover] = useState(false);
    const [openWasteManagerPopover, setOpenWasteManagerPopover] = useState(false);

    return (
        <>
            <CardPpgclSettings />

            {openWasteManagerPopover && (
                <NewWasteManagerRequest
                    openModal={openWasteManagerPopover}
                    setOpenModal={setOpenWasteManagerPopover}
                    promotionName={promotion?.nombre}
                />
            )}

            {openCodLerPopover && (
                <NewCodLersRequest openModal={openCodLerPopover} setOpenModal={setOpenCodLerPopover} />
            )}
        </>
    );
};

export default DetailPromotion;
