import IconMetric from '../Metrics/IconMetric';
import './Cards.scss';

const MetricCard = ({ title, subtitle, icon, unit, danger, warning }) => {
    return (
        <div className="card">
            <IconMetric {...{ title, subtitle, icon, unit, danger, warning }} />
        </div>
    );
};

export default MetricCard;
