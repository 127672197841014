import { Reducers, createStore } from 'modules/shared';
import { NotificationSettingsStore } from '../domain/NotificationSettingsStore';
import { deleteNotificationSetting } from './delete/deleteNotificationSetting';
import { saveNotificationSetting } from './save/saveNotificationSetting';
import { loadNotificationSettings } from './load/loadNotificationSettings';

export const notificationSettingsStore = createStore({
    reducers: {} as Reducers<NotificationSettingsStore>,
    initialState: {
        data: {
            carriers: [],
            wasteManagers: []
        },
        updatingId: null,
        invalid: {},
        loading: 'idle',
        error: null,
        criteria: {
            page: 0
        }
    },
    thunks: {
        loadNotificationSettings,
        saveNotificationSetting,
        deleteNotificationSetting
    }
});
