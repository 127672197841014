import { AnalyticsEvents } from 'modules/analytics/domain/AnalyticsEvents';
import { AnalyticsStore } from 'modules/analytics/domain/AnalyticsStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Store = AnalyticsStore; // Define Store here

export const event =
    (event: AnalyticsEvents): Thunk<Store> =>
    async (store, { api, storage }) => {
        try {
            const { identification } = store.state;

            // Only sends the event if the store is initialized
            if (store.state.initialized) {
                await api.event(event, identification);
            }
        } catch (e) {
            const error = e as Error;

            store.set((state) => {
                state.error = error;
            });
        }
    };
