import html2canvas from 'html2canvas';
import { logger } from 'logger';

export async function renderElementAsFile(el: HTMLElement, filename: string): Promise<File | undefined> {
    return new Promise(async (resolve) => {
        try {
            const canvas = await html2canvas(document.body);

            canvas.toBlob((blob) => {
                if (!blob) return logger.send(new Error('blob'), { blob });

                const file = new File([blob], filename, { type: blob.type });
                resolve(file);
            });
        } catch (e) {
            const error = e as Error;
            logger.send(error);
            resolve(undefined);
            return;
        }
    });
}
