export const getValorizationType = ({ valorizationCode, isFinalValorizator }) => {
    if (valorizationCode.includes('D')) return 'dumping';

    if (valorizationCode.includes('R13')) return 'stored';

    if (valorizationCode.includes('R14')) return 'stored';

    if (valorizationCode.includes('RU')) return 'reuse';

    if (valorizationCode === 'R1') return 'energy';

    if (isFinalValorizator) return 'finalMaterial';

    if (valorizationCode.includes('R')) return 'nonFinalMaterial';

    return ''; // if somehow it has T01, O01...
};
