import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import LerSelectorOption from 'components/BasicComponents/Select/CustomOptions/LerSelectorOption';
import { useWasteManagementStore } from 'modules/waste-management';

export const useLoadLerOptions = (debounceDelay: number = 500) => {
    const [, , { api }] = useWasteManagementStore();

    const [loadLerOptions] = useAsyncOptions({
        request: ({ search, page }) => api.getLers({ search, page }),
        adapter: (ler, search) => ({
            value: ler.id,
            data: {
                lerId: ler.id,
                codLer: ler.code,
                lerType: ler.type.name
            },
            label: (
                <LerSelectorOption
                    search={search}
                    code={ler.code}
                    lerType={ler.type.name}
                    description={ler.descripcion}
                />
            )
        })
    });

    return { loadLerOptions };
};
