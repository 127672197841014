import { produce } from 'immer';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { ApiRelatedEnterprises } from 'modules/shared/domain/ApiPromotion';

import { RelatedEnterprisesStore } from '../../domain/RelatedEnterprisesStore';
import { createNewRelatedEnterprise } from '../../domain/createNewRelatedEnterprise';

export const addRelatedEnterprise =
    (relatedEnterprise: ApiRelatedEnterprises): Thunk<RelatedEnterprisesStore> =>
    async (store) => {
        try {
            const newRelatedEnterprise = createNewRelatedEnterprise(relatedEnterprise, store.state.relatedEnterprises);

            store.dispatch('addStaged', { relatedEnterprise: newRelatedEnterprise });
        } catch (e: any) {
            console.error(e);
            store.dispatch('errorSetted', { error: e.message });
        }
    };

export const addRelatedEnterpriseReducers: Pick<Reducers<RelatedEnterprisesStore>, 'addStaged'> = {
    addStaged: (state, payload) => {
        return produce(state, (draft) => {
            draft.relatedEnterprises.push(payload.relatedEnterprise);
        });
    }
};
