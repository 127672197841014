import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import SidePanel from 'components/BasicComponents/SidePanel';
import WasteRegisterForm from '../WasteRegisterForm';
import './WasteRegisterEditPage.scss';

type WasteRegisterEditPageProps = {
    promotionId: number;
    goBackTo: string;
};

type WasteRegisterEditPageRoute = {
    registerId?: string;
};

const WasteRegisterEditPage = (props: WasteRegisterEditPageProps) => {
    const { params, url } = useRouteMatch<WasteRegisterEditPageRoute>();
    const history = useHistory();
    const { state } = useLocation();
    const [t] = useTranslation('wasteRegister');
    const [open, setOpen] = useState(false);

    const mode = params.registerId ? 'edit' : 'create';

    const close = () => {
        setOpen(false);
        const [base] = url.split(props.goBackTo);
        history.push(base + props.goBackTo);
    };

    useEffect(() => {
        if (!state?.openCreatePanel) return;

        setOpen(state?.openCreatePanel);
        // Reset state so we can open sidepanel by clicking on the button again.
        // Doing it this way to avoid rerender component
        window.history.replaceState({}, '');
    }, [state]);

    return (
        <SidePanel
            title={t('registerDI.panelTitle')}
            variant="full"
            openModal={
                // open the panel when creating a new register if the state is open
                (mode === 'create' && open) ||
                // always open the panel when editing a register
                (mode === 'edit' && true)
            }
            setOpenModal={close}
        >
            <WasteRegisterForm id={params.registerId} promotionId={props.promotionId} onSave={close} onRemove={close} />
        </SidePanel>
    );
};

export default WasteRegisterEditPage;
