import { IconCircle, IconEdit, IconLink, IconTrash, IconUnlink } from '@tabler/icons';
import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { useTranslation } from 'react-i18next';
import userServices from '../../../api/user/user.services';
import { withAuth } from '../../../context/AuthProvider';
import IconButton from '../../BasicComponents/Buttons/Small/IconButton';
import './User.scss';

const User = ({
    userPromotion,
    promotion,
    user,
    openModalDeleteUser,
    openEditPopover,
    editUserOptions = false,
    disassociateUser = false,
    associateUser = false,
    setUsers,
    users,
    setNoUsersPromotion,
    noUsersPromotion
}) => {
    const [t] = useTranslation();
    const unlinkUser = async (user) => {
        const promotionIds = user.promotionIds.filter((promo) => promo !== promotion.id);
        user = { ...user, promotionIds };
        await userServices.editUser(user);
        setUsers(users.filter((userPromotion) => userPromotion.id !== user.id));
        setNoUsersPromotion([...noUsersPromotion, user]);
    };

    const linkUser = async (user) => {
        const promotionIds = [...user.promotionIds, promotion.id];
        user = { ...user, promotionIds };
        await userServices.editUser(user);
        setNoUsersPromotion(noUsersPromotion.filter((noUser) => noUser.id !== user.id));
        setUsers([...users, user]);
    };

    return (
        <li className="user ">
            <div className="user__info">
                <div className="profile__iconUser">
                    <p>
                        {userPromotion.firstName?.charAt(0)?.toUpperCase()}
                        {userPromotion.lastName?.charAt(0)?.toUpperCase()}
                    </p>
                </div>
                <div>
                    <div className="profile__userName">
                        {!userPromotion.activated && (
                            <Tooltip msg="Usuario desactivado">
                                <IconCircle fill="#ff5757" strokeWidth={0} />
                            </Tooltip>
                        )}
                        {userPromotion.firstName} {userPromotion.lastName}{' '}
                    </div>
                    <span className="usuario__desc">
                        {userPromotion?.roleType?.descripcion}
                        {userPromotion.enterprise ? ` - ${userPromotion.enterprise.name}` : ''}
                    </span>
                    <div className="user__stakeHolderContainer">
                        {userPromotion?.gestoraName && (
                            <p>
                                <span>Gestora:</span> {userPromotion.gestoraName}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="user__containerButtons">
                {disassociateUser && (
                    <ActionConfirm
                        title={t(`removeUserFromPromotion.title`, { ns: 'users' })}
                        message={t(`removeUserFromPromotion.message`, { ns: 'users' })}
                        confirmText={t(`removeUserFromPromotion.confirmText`, {
                            ns: 'users'
                        })}
                        onConfirm={() => unlinkUser(userPromotion)}
                    >
                        <IconButton icon={<IconUnlink />} text={t('removeUser', { ns: 'users' })} />
                    </ActionConfirm>
                )}
                {associateUser && (
                    <IconButton
                        icon={<IconLink />}
                        text={t('addUser', { ns: 'users' })}
                        action={() => linkUser(userPromotion)}
                    />
                )}
                {editUserOptions && (
                    <>
                        {user.id !== userPromotion.id && (
                            <IconButton
                                icon={<IconTrash color="#ff5757" />}
                                action={() => openModalDeleteUser(userPromotion)}
                            />
                        )}
                        <IconButton
                            icon={<IconEdit />}
                            text={t('edit', { ns: 'common' })}
                            action={() => openEditPopover(userPromotion)}
                        />
                    </>
                )}
            </div>
        </li>
    );
};
export default withAuth(User);
