import { TagColors } from './TagColors';

export type TagId = string;
export type TagProyectId = number;

export interface Tag {
    id: TagId;
    name: string;
    color: TagColors;
    projects: Array<{
        id: TagProyectId;
        name: string;
    }>;
}

export const createTag = (data: Partial<Tag> & { id: Tag['id'] }) => {
    const randomColorIndex = Math.floor(Math.random() * Object.keys(TagColors).length);
    return {
        id: data.id,
        name: data.name || '',
        color: data.color || Object.values(TagColors)[randomColorIndex],
        projects: data.projects || []
    };
};

export const dissasociateProjectFromTag = (tag: Tag, projectId: TagProyectId) => {
    return {
        ...tag,
        projects: tag.projects.filter((project) => project.id !== projectId)
    };
};
