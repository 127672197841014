import fetcher from '../../../lib/fetcher';
const _userMessage = {
    success: {
        text: 'message.disassociate.success',
        ns: 'associateTraceabilities'
    },
    error: {
        text: 'message.disassociate.error',
        ns: 'associateTraceabilities'
    }
};
export const disassociateTraceability = async (document) => {
    const URL = '/api/tracing/disassociate-traceability';
    try {
        const response = await fetcher.put(URL, document, { message: _userMessage });
        return response;
    } catch (error) {
        console.log(error);
    }
};
