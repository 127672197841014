import { DiExtractionStore } from '../../domain/DiExtractionStore';
import { IDiExtraction } from '../../domain/IDiExtraction';

export type DiExtractionUpdates = { [id: IDiExtraction['id']]: Partial<IDiExtraction> };

export const startUpdates =
    (extractionIds: string[] = []) =>
    (draft: DiExtractionStore['state']) => {
        draft.error = null;
        draft.loading = 'updating';
        draft.updating = [...draft.updating, ...extractionIds];
    };

export const fulfillUpdates =
    (updates: DiExtractionUpdates = {}) =>
    (draft: DiExtractionStore['state']) => {
        const updatedIds = Object.keys(updates);

        draft.error = null;
        draft.loading = 'succeeded';
        draft.updated = [...draft.updated, ...updatedIds];

        draft.updating = draft.updating.filter((id) => updatedIds.includes(id));

        draft.diExtractions = draft.diExtractions.map((diExtraction) => {
            const changes = updates[diExtraction.id] || {};
            return { ...diExtraction, ...changes };
        });
    };

export const rejectUpdates =
    (extractionIds: string[] = [], error: Error) =>
    (draft: DiExtractionStore['state']) => {
        draft.updating = draft.updating.filter((id) => extractionIds.includes(id));
        draft.error = error;
    };
