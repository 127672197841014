import { promotionKPIsStore } from 'modules/promotion-kpis/application/promotionKPIsStore';
import { contextStoreAdapter } from 'modules/shared';

const Ct = contextStoreAdapter(promotionKPIsStore)({ name: 'PromotionKPIsContext' });

export const PromotionKPIsProvider = Ct.Provider;
export const usePromotionKPIs = Ct.useConsumer;

export const useValorizationSummary = Ct.createQueryHook(({ api }) => api.getValorizationSummary.bind(api));
export const useValorizationByScope = Ct.createQueryHook(({ api }) => api.getValorizationByScope.bind(api));
export const useMixSummary = Ct.createQueryHook(({ api }) => api.getMixSummary.bind(api));
export const useMixByScope = Ct.createQueryHook(({ api }) => api.getMixByScope.bind(api));
export const useMandatoryFlowsSummary = Ct.createQueryHook(({ api }) => api.getMandatoryFlowsSummary.bind(api));
export const useMandatoryFlowsDetails = Ct.createQueryHook(({ api }) => api.getMandatoryFlowsDetails.bind(api));
export const useTreatmentContractsSummary = Ct.createQueryHook(({ api }) => api.getTreatmentContractsSummary.bind(api));
export const useTraceabilityUpdateSummary = Ct.createQueryHook(({ api }) => api.getTraceabilityUpdateSummary.bind(api));

export const useGoals = Ct.createQueryHook(({ api }) => api.getGoals.bind(api));
export const useGoalSave = Ct.createMutationHook(({ api }) => api.saveGoal.bind(api));
export const useGoalRemove = Ct.createMutationHook(({ api }) => api.removeGoal.bind(api));
