import { usePromotion } from 'modules/promotion';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { notify } from 'utils/helpers/notifications';

export function useAutomaticReadingNotifications() {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();

    const created = (traceabilityId: number) => {
        notify({
            msg: (
                <div className="diExtraction__toast">
                    {t('actions.approveOne.success', { ns: 'automaticReading' })}
                    <Link
                        data-testid="diExtraction__toast-link"
                        to={`/promotion/${promotion?.id}/traceabilities/traceability/${traceabilityId}`}
                    >{`#${traceabilityId}`}</Link>
                </div>
            ),
            isSuccess: true,
            autoClose: 10 * 1000
        });
    };

    return { created };
}
