import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { PromotionStore } from '../../domain/PromotionStore';
import { PromotionCriteria } from '../../domain/promotion/PromotionCriteria';
import { createPromotion } from '../../domain/promotion/createPromotion';

export const loadPromotion =
    (id: PromotionCriteria['id']): Thunk<PromotionStore> =>
    async (store, { api }) => {
        try {
            store.dispatch('loadPending', null);

            const data = await api.getPromotionById(id);
            const promotion = createPromotion(data);

            store.dispatch('loadFulfilled', { promotion });
        } catch (error: any) {
            store.dispatch('loadRejected', { error });
        }
    };

export const loadPromotionReducers: Pick<Reducers<PromotionStore>, 'loadFulfilled'> = {
    loadFulfilled: (state, payload) => {
        return { ...state, loading: 'succeeded', promotion: payload.promotion };
    }
};
