export const IconSparkles = () => (
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M25.3333 28C26.3058 28 27.2384 28.3863 27.9261 29.0739C28.6137 29.7616 29 30.6942 29 31.6667C29 30.6942 29.3863 29.7616 30.0739 29.0739C30.7616 28.3863 31.6942 28 32.6667 28C31.6942 28 30.7616 27.6137 30.0739 26.9261C29.3863 26.2384 29 25.3058 29 24.3333C29 25.3058 28.6137 26.2384 27.9261 26.9261C27.2384 27.6137 26.3058 28 25.3333 28ZM25.3333 5.99999C26.3058 5.99999 27.2384 6.3863 27.9261 7.07394C28.6137 7.76157 29 8.6942 29 9.66666C29 8.6942 29.3863 7.76157 30.0739 7.07394C30.7616 6.3863 31.6942 5.99999 32.6667 5.99999C31.6942 5.99999 30.7616 5.61369 30.0739 4.92605C29.3863 4.23842 29 3.30579 29 2.33333C29 3.30579 28.6137 4.23842 27.9261 4.92605C27.2384 5.61369 26.3058 5.99999 25.3333 5.99999ZM12.5 28C12.5 25.0826 13.6589 22.2847 15.7218 20.2218C17.7847 18.1589 20.5826 17 23.5 17C20.5826 17 17.7847 15.8411 15.7218 13.7782C13.6589 11.7153 12.5 8.91738 12.5 5.99999C12.5 8.91738 11.3411 11.7153 9.27817 13.7782C7.21527 15.8411 4.41738 17 1.5 17C4.41738 17 7.21527 18.1589 9.27817 20.2218C11.3411 22.2847 12.5 25.0826 12.5 28Z"
            stroke="url(#paint0_linear_5541_4651)"
            strokeWidth="2.75"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="paint0_linear_5541_4651"
                x1="34.5"
                y1="0.999998"
                x2="14"
                y2="33"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#6720F3" />
                <stop offset="0.301782" stopColor="#C25D93" />
                <stop offset="1" stopColor="#FFAC2F" />
            </linearGradient>
        </defs>
    </svg>
);
