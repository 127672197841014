import { FormEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import BasicButton from 'components/BasicComponents/Buttons/Base/BasicButton';
import InputText from 'components/BasicComponents/Inputs/InputText';
import { useQuery } from 'hooks/useQuery';
import { useAuth } from 'modules/auth/infrastructure/react/authContext';
import './ResetPasswordRequest.scss';

const ResetPasswordRequest = () => {
    const query = useQuery();
    const [t] = useTranslation('auth');
    const [{ error }, { requestPassReset }] = useAuth();
    const [dirty, setDirty] = useState(false);
    const [email, setEmail] = useState<string>(query.params.email || '');
    const [isLoading, setIsLoading] = useState(false);

    const [sended, setSended] = useState(false);

    const send = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setDirty(false);
        setIsLoading(true);
        const response = await requestPassReset({ email: email.replaceAll(' ', '') });
        setIsLoading(false);
        setSended(response.sended);
    };

    useEffect(() => setDirty(true), [email]);

    const modifiers = [!dirty && error ? 'AuthLayout--has-error' : ''];

    return (
        <div className={`ResetPasswordRequest ${modifiers.join(' ')}`}>
            {sended ? (
                <>
                    <header className="AuthLayout__header">
                        <h1 className="AuthLayout__title">{t('sendedTitle')}</h1>
                        <p className="AuthLayout__text">
                            <Trans
                                i18nKey="sendedText"
                                ns="auth"
                                values={{ email }}
                                components={{ bold: <strong />, italic: <em />, paragraph: <p /> }}
                            />
                        </p>
                    </header>
                </>
            ) : (
                <>
                    <header className="AuthLayout__header">
                        <h1 className="AuthLayout__title">{t('resetPasswordTitle')}</h1>
                        <p className="AuthLayout__text">{t('resetPasswordRequestSubtitle')}</p>
                    </header>
                    <form className="AuthLayout__form" autoComplete="on" onSubmit={send}>
                        <div>
                            <InputText
                                className="AuthLayout__input"
                                label={t('email')}
                                placeholder="ej: usuario@empresa.com"
                                name="email"
                                type="text"
                                autoComplete="email"
                                disabled={isLoading}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                // Just to ignore optional fields
                                {...({} as any)}
                            />
                        </div>
                        <div data-testid="auth__error" className="AuthLayout__error-msg">
                            {t((error as Error)?.message || '-')}
                        </div>
                        <footer className="AuthLayout__footer">
                            <BasicButton
                                color="purple"
                                type="submit"
                                isLoading={isLoading}
                                text={t(`sendEmail`)}
                                extraClasses="AuthLayout__button"
                                // Just to ignore optional fields
                                {...({} as any)}
                            />
                        </footer>
                    </form>
                </>
            )}
            <footer className="AuthLayout__footer">
                <hr />
                <Link to={`/account/help`} className="AuthLayout__link">
                    {t(`needHelpMsg`)}
                </Link>
                <Link to={`/account/login`} className="AuthLayout__link">
                    {t(`goBackLogin`)}
                </Link>
            </footer>
        </div>
    );
};

export default ResetPasswordRequest;
