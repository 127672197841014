import { PromotionKPIdGoalExportPayload } from 'modules/promotion-kpis/domain/goals/PromotionKPIdGoalExportPayload';
import { PromotionKPIsStore } from 'modules/promotion-kpis/domain/PromotionKPIsStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Store = PromotionKPIsStore; // Define Store here

export const restoreGoals =
    (params: { promotionId: number; payload: PromotionKPIdGoalExportPayload[] }): Thunk<Store> =>
    async (store, { api }) => {
        try {
            const { promotionId, payload } = params;

            // 1. Retrive all current goals
            const currentGoals = await api.getAllGoals({ promotionId });

            // 2. Remove all current goals
            await Promise.all(
                currentGoals.map((goal) => api.removeGoal({ goalId: goal.uuid, promotionId, kpi: goal.kpi }))
            );

            // 3. Save all goals from the payload
            await Promise.all(
                payload.map(({ kpi, ...goal }) =>
                    api.saveGoal({ goal: { ...goal, uuid: crypto.randomUUID() }, kpi, promotionId })
                )
            );
        } catch (e) {
            const error = e as Error;
            console.error(error);
        }
    };
