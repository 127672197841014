import { IconExternalLink } from '@tabler/icons';

import { Traceability, useTraceabilities } from 'modules/traceabilities';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import { useTranslation } from 'react-i18next';
import './TraceabilityDetailPreview.scss';

interface TraceabilityDetailPreviewProps {
    traceability?: Traceability | null;
}

const TraceabilityDetailPreview = ({ traceability }: TraceabilityDetailPreviewProps) => {
    const [traceabilities] = useTraceabilities();
    const [t] = useTranslation();
    const { url } = traceability?.di || {};

    if (traceabilities.loading.detail !== 'succeeded') {
        return <div className="TraceabilityDetailPreview"></div>;
    }

    return (
        <div className="TraceabilityDetailPreview">
            {url && <iframe src={url || ''}></iframe>}
            {!url && t('noDiAvailable', { ns: 'traceabilities' })}

            {url && (
                <div className="TraceabilityDetailPreview__openDi">
                    <SmallButton
                        type="external-link"
                        titulo={t('showDi', { ns: 'traceabilities' })}
                        icon={<IconExternalLink />}
                        iconPosition="left"
                        to={url}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                </div>
            )}
        </div>
    );
};

export default TraceabilityDetailPreview;
