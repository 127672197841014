import { IconCheck, IconX } from '@tabler/icons';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionConfirm from 'components/BasicComponents/ActionConfirm';
import IconButton from 'components/BasicComponents/Buttons/Small/IconButton';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import LerSelectorOption from 'components/BasicComponents/Select/CustomOptions/LerSelectorOption';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import LerAlert from 'components/ComplexComponents/LerAlert';
import { useAuth } from 'modules/auth';
import {
    WasteManagementEntry,
    WasteManagementEntryType,
    wasteManagementApi,
    wasteManagementEntryValidation
} from 'modules/waste-management';
import { debounce } from 'utils/helpers/async.helpers';
import { hasPermission } from 'utils/helpers/roles.helpers';
import './WMPlannerItem.scss';
import { useLoadLerOptions } from './useLoadLerOptions';

interface WMPlannerItemProps {
    entry?: Partial<WasteManagementEntry>;
    onCreated?: () => void;
    onDeleted?: () => void;
}

const WMPlannerItem = (props: WMPlannerItemProps) => {
    const [t] = useTranslation();
    const lerFieldRef = useRef(null);
    const [account] = useAuth();
    const canEdit = account.user
        ? hasPermission('egrandpgr', 'canCreate', account.user, props.entry?.promotionId)
        : true;

    const remove = wasteManagementApi.useDelete();
    const create = wasteManagementApi.useCreate();
    const save = wasteManagementApi.useSave();
    const { loadLerOptions } = useLoadLerOptions();

    const [entry, setEntry] = useState(props.entry);
    const validation = wasteManagementEntryValidation(entry);

    const updateAndSave = async (values: Partial<WasteManagementEntry>) => {
        const updatedEntryData = { ...(entry || {}), ...values };
        setEntry(updatedEntryData);

        if (!validation.valid) return;
        if (!updatedEntryData?.id) return;

        const { error } = await debounce(save.mutate)([updatedEntryData as WasteManagementEntry]);
        if (error) setEntry(props.entry);
    };

    const submitToCreate = async () => {
        const { id, ...data } = entry as WasteManagementEntry;

        await create.mutate({
            ...data,
            external: props.entry?.external || false,
            lerOrientative: props.entry?.lerOrientative || false,
            promotionId: props.entry?.promotionId || NaN,
            type: props.entry?.type || WasteManagementEntryType.EGR
        });

        setEntry(undefined);
        props.onCreated?.();
        (lerFieldRef.current as any | null)?.select.focus();
    };

    const modifiers = [
        remove.loading === 'pending' || create.loading === 'pending' ? 'WMPlannerItem--updating' : '',
        remove.loading === 'failed' || save.loading === 'failed' || create.loading === 'failed'
            ? 'WMPlannerItem--mutation-error'
            : '',
        !props.entry?.id ? 'WMPlannerItem--new' : ''
    ];

    return (
        <tr
            className={`WMPlannerItem ${modifiers.join(' ')}`}
            data-testid={!props.entry?.id ? 'WMPlannerItem--new' : ''}
        >
            <td>
                <div className="WMPlannerItem__ler">
                    <SelectAutocomplete
                        ref={lerFieldRef}
                        defaultOptions={!entry}
                        name="codLer"
                        onChange={(v) => {
                            const data = (v as any)?.data;
                            if (!data) return;
                            updateAndSave({ lerId: data?.lerId, codLer: data?.codLer, lerType: data?.lerType });
                        }}
                        value={
                            entry?.lerId
                                ? {
                                      value: entry.lerId,
                                      label: (
                                          <LerSelectorOption code={entry.codLer || ''} lerType={entry.lerType || ''} />
                                      )
                                  }
                                : undefined
                        }
                        className="WMPlannerItem__selector"
                        label={t('ler.code')}
                        disabled={!canEdit}
                        menuPosition="fixed"
                        loadOptions={loadLerOptions}
                    />
                    <LerAlert code={entry?.codLer} />
                </div>
            </td>
            <td>
                <InputNumber
                    id="weight"
                    name="weight"
                    onChange={({ target }) => updateAndSave({ weight: target.value })}
                    label={t('weight.upperCase')}
                    value={entry?.weight || ''}
                    showThousandSeparator={false}
                    maximumFractionDigits={3}
                    min="1"
                    align="right"
                    disabled={!canEdit}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </td>
            <td>
                <InputNumber
                    id="volume"
                    name="volume"
                    onChange={({ target }) => updateAndSave({ volume: target.value })}
                    label={t('volume.upperCase')}
                    value={entry?.volume || ''}
                    showThousandSeparator={false}
                    maximumFractionDigits={3}
                    min="1"
                    align="right"
                    disabled={!canEdit}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </td>
            <td>
                <InputNumber
                    id="budget"
                    name="budget"
                    onChange={({ target }) => updateAndSave({ budget: target.value })}
                    label={t('cost.upperCase')}
                    value={entry?.budget || ''}
                    showThousandSeparator={false}
                    maximumFractionDigits={3}
                    min="1"
                    align="right"
                    disabled={!canEdit}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </td>
            <td>
                <div className="WasteManagementPlanner__actions">
                    {props.entry?.id && (
                        <ActionConfirm
                            title={t(`operations.REMOVE.name`, { ns: 'wasteManagement' })}
                            message={t(`operations.REMOVE.confirmationMessage`, { ns: 'wasteManagement' })}
                            onConfirm={async () => {
                                await remove.mutate({ id: props.entry?.id || NaN });
                                props.onDeleted?.();
                            }}
                        >
                            <IconButton
                                ariaLabel={t('delete', { ns: 'operations.REMOVE.name' })}
                                tabIndex={-1}
                                titulo="delete"
                                isLoading={remove.loading === 'pending'}
                                icon={<IconX size={16} />}
                                disabled={!canEdit}
                                // Just to ignore optional fields
                                {...({} as any)}
                            />
                        </ActionConfirm>
                    )}
                    {!props.entry?.id && (
                        <Tooltip
                            msg={t(`validation.${validation.field}`, { ns: 'wasteManagement', defaultValue: null })}
                        >
                            <IconButton
                                ariaLabel={t('operations.CREATE.name', { ns: 'wasteManagement' })}
                                isLoading={create.loading === 'pending'}
                                action={submitToCreate}
                                disabled={!canEdit || !validation.valid || create.loading === 'pending'}
                                titulo={t('operations.CREATE.name', { ns: 'wasteManagement' })}
                                icon={<IconCheck size={16} />}
                                // Just to ignore optional fields
                                {...({} as any)}
                            />
                        </Tooltip>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default WMPlannerItem;
