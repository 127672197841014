import AssociateTraceabilitiesPage from '../components/AssociateTraceabilitiesPage';

export const ASSOCIATE_TRACEABILITIES_ROUTE = [
    {
        component: AssociateTraceabilitiesPage,
        path: '/documents/:id/link-traceabilities',
        requiredPermission: 'canView',
        section: 'documentation'
    }
];
