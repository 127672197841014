import { useTranslation } from 'react-i18next';
import SaveButton from '../Buttons/SaveButton';
import SecondaryButton from '../Buttons/SecondaryButton';
import SimplePopover from '../Popovers/SimplePopover';
import './ModalEliminar.scss';

const BasicModal = ({
    children,
    title = '¿Eliminar objeto?',
    subtitle = '',
    actionText,
    cancelText,
    actionFunction,
    openModal,
    setOpenModal,
    refuse = true,
    disabledButton = false,
    onCloseModal,
    textCenter,
    testid = null,
    actions = null
}) => {
    const [t] = useTranslation();
    cancelText = cancelText ? cancelText : t('cancel', { ns: 'common' });

    const closeModal = () => {
        onCloseModal && onCloseModal();
        setOpenModal(false);
    };

    const triggerActionAndClose = () => {
        if (disabledButton) return;

        actionFunction();
        closeModal();
    };

    const classNameButton = () => {
        let className = 'button';
        if (refuse) {
            className += ' button__refuse';
        }
        if (disabledButton) {
            className += 'button__refuse button__disabled';
        }
        return className;
    };

    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            addFunctionOnClose={onCloseModal}
            title={title}
            className="simplePopover deleteModal"
        >
            <div data-testid={testid}>
                {subtitle !== '' && <p>{subtitle}</p>}
                <div className={`modal__content ${textCenter && 'modal__content--text-center'}`}>{children}</div>
                <div className="modal__buttonsContainer">
                    <SecondaryButton type="button" action={() => closeModal()} titulo={cancelText} />
                    {actions && (
                        <div onClick={() => closeModal()} style={{ display: 'contents' }}>
                            {actions}
                        </div>
                    )}
                    {refuse && !actions && (
                        <button
                            disabled={disabledButton}
                            className={classNameButton()}
                            onClick={() => triggerActionAndClose()}
                        >
                            {actionText}
                        </button>
                    )}
                    {!refuse && !actions && (
                        <SaveButton
                            disabled={disabledButton}
                            extraClasses="button__purple"
                            type="button"
                            text={actionText}
                            action={() => triggerActionAndClose()}
                        />
                    )}
                </div>
            </div>
        </SimplePopover>
    );
};

export default BasicModal;
