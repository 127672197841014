import { useTranslation } from 'react-i18next';

import KPITile from 'features/KPIs/components/KPITile';
import { Widget } from 'features/KPIs/types/Widget';
import { calcKPIStatusFromGoal, KPIStatus, useMandatoryFlowsSummary } from 'modules/promotion-kpis';
import KPIThermometer from '../../components/KPIThermometer';
import MandatoryFlowsDetails from './components/MandatoryFlowsDetails';

const useMandatoryFlowsWidget: Widget = (props) => {
    const [t] = useTranslation('kpis');
    const { data, loading, error } = useMandatoryFlowsSummary(props);

    const total = data?.lerTypes.length || null;
    const value = total ? data?.lerTypes.filter((lerType) => lerType.met).length : null;
    const status = KPIStatus[data?.status || 'INDETERMINATE'];

    return {
        status,
        Tile: () => (
            <KPITile
                label={t('mandatory-flows.label')}
                unit={''}
                value={loading === 'succeeded' ? value : undefined}
                total={loading === 'succeeded' ? total : undefined}
                hasError={!!error}
                status={status}
            >
                <KPIThermometer
                    type="discrete"
                    value={value}
                    maxValue={total || 0}
                    status={data?.lerTypes.map((flow) => calcKPIStatusFromGoal({ goal: flow }))}
                />
            </KPITile>
        ),
        Detail: () => {
            return <MandatoryFlowsDetails promotionId={props.promotionId} />;
        }
    };
};

export default useMandatoryFlowsWidget;
