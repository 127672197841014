import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import HasPermission from '../../../components/AccessCheckers/HasPermission';
import SubmitButton from '../../../components/BasicComponents/Buttons/SubmitButton';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import { withAuth } from '../../../context/AuthProvider';
import { differenceArrays, removeDuplicates } from '../../../utils/helpers/general.helpers';
import { disassociateTraceability } from '../api/disassociateTraceability';
import { createObjectDisassociate, searchTraceabilitiesDocument } from '../helpers/associateTraceabilities.helpers';
import { AssociateTracabilitiesContext } from './AssociateTraceabilitiesPage';
import ResultAssociateTraceability from './ResultAssociateTraceability';

const TraceabilitiesWithDocument = () => {
    const [t] = useTranslation();
    const INITIAL_PAGE = 0;
    const {
        traceabilitiesDocument,
        setTraceabilitiesDocument,
        numberTraceabilitiesDocument,
        setNumberTraceabilitiesDocument,
        traceabilitiesWithoutDocument,
        setTraceabilitiesWithoutDocument,
        DOCUMENT,
        associatedTraceabilities,
        setAssociatedTraceabilities
    } = useContext(AssociateTracabilitiesContext);

    const [traceabilitiesToDisassociate, setTraceabilitiesToDisassociate] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [page, setPage] = useState(INITIAL_PAGE);
    const [loading, setLoading] = useState(false);

    const handleClick = (traceability) => {
        const findIdx = associatedTraceabilities.indexOf(traceability);
        if (findIdx > -1) {
            const filteredData = associatedTraceabilities.filter((item) => item !== traceability);
            setAssociatedTraceabilities(filteredData);
            setTraceabilitiesToDisassociate([...traceabilitiesToDisassociate, traceability]);
        } else {
            const filteredData = traceabilitiesToDisassociate.filter((item) => item !== traceability);
            setAssociatedTraceabilities([...associatedTraceabilities, traceability]);
            setTraceabilitiesToDisassociate(filteredData);
        }
    };

    const disasociateTraceabilities = async () => {
        setLoading(true);
        setSubmitting(true);
        if (traceabilitiesToDisassociate.length > 0) {
            const documentToEdit = createObjectDisassociate(DOCUMENT, traceabilitiesToDisassociate);
            const disasociate = await disassociateTraceability(documentToEdit);
            if (disasociate !== undefined) {
                setTraceabilitiesDocument(differenceArrays(traceabilitiesDocument, traceabilitiesToDisassociate));
                setNumberTraceabilitiesDocument(numberTraceabilitiesDocument - traceabilitiesToDisassociate.length);
                setTraceabilitiesWithoutDocument(traceabilitiesWithoutDocument.concat(traceabilitiesToDisassociate));
                setPage(INITIAL_PAGE);
                setTraceabilitiesToDisassociate([]);
            }
        }
        setLoading(false);
        setSubmitting(false);
    };

    const handleLoadMore = async () => {
        setPage(page + 1);
        const nextTraceabilities = await searchTraceabilitiesDocument(DOCUMENT, page + 1);
        setTraceabilitiesDocument(removeDuplicates(traceabilitiesDocument.concat(nextTraceabilities)));
    };

    return (
        <SimpleCard
            title={`${t('associateTraceability', { ns: 'associateTraceabilities' })} (${numberTraceabilitiesDocument})`}
            id="scrollableCard"
            allowScroll={true}
        >
            {traceabilitiesDocument.length > 0 && (
                <>
                    <InfiniteScroll
                        dataLength={traceabilitiesDocument.length}
                        next={() => handleLoadMore()}
                        hasMore={true}
                        height={500}
                        scrollableTarget="scrollableCard"
                    >
                        {traceabilitiesDocument.map((traceability) => (
                            <ResultAssociateTraceability
                                traceability={traceability}
                                selectedCheckboxes={traceabilitiesToDisassociate}
                                key={traceability.id}
                                handleClick={handleClick}
                            />
                        ))}
                    </InfiniteScroll>
                </>
            )}
            {traceabilitiesDocument.length === 0 && (
                <p>{t('notTraceabilitiesAssociated', { ns: 'associateTraceabilities' })}</p>
            )}
            <HasPermission ifRole="canCreate" section="traceability">
                {traceabilitiesDocument.length > 0 && (
                    <SubmitButton
                        text={`${t('disassociateTraceability', { ns: 'associateTraceabilities' })}: ${
                            traceabilitiesToDisassociate.length
                        }`}
                        onClickButton={disasociateTraceabilities}
                        buttonDisabled={submitting}
                    />
                )}
            </HasPermission>
            {loading && <Spinner />}
        </SimpleCard>
    );
};
export default withAuth(TraceabilitiesWithDocument);
