import { useState, useEffect } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';

import { customStyles } from 'components/BasicComponents/Select-Autocomplete/helpers';
import { ENTERPRISE_ROLES } from 'modules/enterprise/related-enterprise/domain/constants/enterpriseRoles';
import './EnterpriseAutocomplete.scss';

const EnterpriseRoleAutocomplete = ({ values, setValues, propName = '', ...props }) => {
    const [notComplete, setNotComplete] = useState(false);

    const key = () => {
        return JSON.stringify(values);
    };

    const hasMore = (options) => {
        return options.length === 20 ? true : false;
    };

    const loadOptions = async (search, loadedOptions, { page }) => {
        let options = ENTERPRISE_ROLES;
        if (search) {
            options = options.filter((role) => role.label.toLowerCase().includes(search.toLowerCase()));
        }
        return {
            options: options,
            hasMore: hasMore(options),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOption = (item) => {
        setValues({ ...values, [propName]: item });
    };

    const emptyInput = () => {
        if (props.required && !props.isFormValid && values[propName] === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    useEffect(() => {
        emptyInput();
    }, [props.isFormValid, values]);

    return (
        <div className={props.className || ''}>
            <label className="input__label" id="enterpriseRoleAutocomplete">
                Rol en el proyecto {props.required ? '*' : ''}
            </label>
            <AsyncPaginate
                aria-labelledby="enterpriseRoleAutocomplete"
                key={key()}
                loadOptions={loadOptions}
                onChange={selectOption}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option}
                placeholder=""
                value={values[propName]}
                additional={{
                    page: 0
                }}
                styles={customStyles(notComplete)}
            />
        </div>
    );
};
export default EnterpriseRoleAutocomplete;
