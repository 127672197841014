import { ApiAddon } from '../../BilleablesApi/ApiBilleable';
import { GenericBilleable } from './GenericBilleable';

type Source = {
    addon: ApiAddon;
    editable: boolean;
};

export function createGenericBilleable({ addon, editable = true }: Source): GenericBilleable {
    return {
        name: addon.name,
        scope: addon.scope,
        editable: editable,
        purchased: addon.purchased || false,
        config: {
            addon: { id: addon.id, name: addon.name }
        }
    };
}
