import { TraceabilitiesSummaryTotals } from 'modules/traceabilities/domain/TraceabilitiesSummary';

export const sumaryTotalsAdapter = (props: { data: TraceabilitiesSummaryTotals }): TraceabilitiesSummaryTotals => {
    const labelsOverrides = {
        RU0: 'Reutilización'
    };

    return props.data.map((item) => ({
        ...item,
        label: labelsOverrides[item.label] || item.label
    }));
};
