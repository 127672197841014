import { useReducer } from 'react';

import createReducer from 'lib/createReducer';

import { reducers } from './reducers';
import { initialState } from './state';
import { loadTreatmentContractList } from './thunks/loadTreatmentContractList';

export * from './state';

export function useTreatmentContractStore() {
    const [state, dispatch] = useReducer(createReducer(reducers), initialState);

    const store = {
        loadTreatmentContractList: loadTreatmentContractList({ dispatch })
    };

    return [state, store];
}
