import { DiExtractionCriteria } from 'modules/di-extraction/domain/DiExtractionCriteria';
import { DiExtractionStore } from 'modules/di-extraction/domain/DiExtractionStore';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

interface Config {
    mode?: DiExtractionStore['actions']['fetchPending']['payload']['mode'];
}

export const fetchDiExtractions =
    (newCriteria: DiExtractionCriteria, config?: Config): Thunk<DiExtractionStore> =>
    async (store, { api }) => {
        const { mode = 'replace' } = config || {};
        try {
            const criteria = mode === 'merge' ? { ...store.state.criteria, ...newCriteria } : newCriteria;

            store.dispatch('fetchPending', { criteria, mode });

            const { results, withWarnings } = await api.getDiExtractions(criteria);

            store.dispatch('fetchFulfilled', { diExtractions: results, mode, withWarnings });
        } catch (e) {
            const error = e as Error;
            if (error.message === 'canceled') return;

            store.dispatch('errorSetted', { error });
        }
    };

export const fetchReducers: Pick<Reducers<DiExtractionStore>, 'fetchFulfilled' | 'fetchPending'> = {
    fetchPending(state, payload) {
        return {
            ...state,
            criteria: payload.criteria,
            diExtractions: payload.mode === 'replace' ? [] : state.diExtractions,
            updated: payload.mode === 'merge' ? state.updated : [],
            loading: payload.mode === 'merge' ? 'updating' : 'pending'
        };
    },
    fetchFulfilled(state, { diExtractions, mode, withWarnings }) {
        return {
            ...state,
            loading: 'succeeded',
            error: null,
            withWarnings,
            diExtractions: mode === 'merge' ? [...state.diExtractions, ...diExtractions] : diExtractions
        };
    }
};
