import { useEffect } from 'react';

import { useOrphanDocuments } from 'modules/orphan-documents';
import { hasPermission } from 'utils/helpers/roles.helpers';

export function useAdminBootstrap(props: { authorities: string[]; promocion: any }) {
    const [, { loadEmailsWithOrphanDocuments }] = useOrphanDocuments();

    const isAdmin = hasPermission('admin', 'canView', props, props.promocion);

    useEffect(() => {
        if (isAdmin) loadEmailsWithOrphanDocuments(); // required for walle feature
    }, [isAdmin]);
}
