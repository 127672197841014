import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import activitiesServices from '../../../api/activities/activities.services';
import { withAuth } from '../../../context/AuthProvider';
import { customStyles, hasMore } from './helpers';

const TipoActividadSelectAutocomplete = (props) => {
    const [t] = useTranslation();
    const [notComplete, setNotComplete] = useState(false);

    const addEmptyValue = (options) => {
        let emptyOption = { descripcionCorta: '(Ninguno)' };
        if (options.length > 0) {
            const optionsModificated = [emptyOption].concat(options);
            return optionsModificated;
        }
    };

    const loadOptionsTipoActividad = async (search, loadedOptions, { page }) => {
        let opcionesTipoDeActividad;
        if (!search) {
            opcionesTipoDeActividad = await activitiesServices.getTypesActivities('');
            opcionesTipoDeActividad = addEmptyValue(opcionesTipoDeActividad);
        } else {
            opcionesTipoDeActividad = await activitiesServices.getTypesActivities(
                `?descripcionCorta.contains=${search}`
            );
        }
        return {
            options: opcionesTipoDeActividad,
            hasMore: hasMore(opcionesTipoDeActividad),
            additional: {
                page: page + 1
            }
        };
    };

    const selectOptionTipoActividad = (item) => {
        if (item.descripcionCorta === '(Ninguno)') {
            setValues({ ...values, tipoActividad: { descripcionCorta: '' } });
        } else {
            setValues({ ...values, tipoActividad: item });
        }
    };

    useEffect(() => {
        inputVacio();
    }, [props.validacion]);

    const inputVacio = () => {
        if (props.validacion === true && required && tipoActividad === '') {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };
    const { tipoActividad } = props.values;
    const { setValues, values, required, className } = props;

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <label className="input__label">
                {t('activityType', { ns: 'activities' })} {required ? '*' : ''}
            </label>
            <AsyncPaginate
                loadOptions={loadOptionsTipoActividad}
                onChange={selectOptionTipoActividad}
                getOptionLabel={(option) => option.descripcionCorta}
                getOptionValue={(option) => option}
                value={tipoActividad}
                styles={customStyles(notComplete)}
                placeholder=""
                additional={{
                    page: 0
                }}
            />
        </div>
    );
};
export default withAuth(TipoActividadSelectAutocomplete);
