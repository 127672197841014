import { Reducers, createStore } from 'modules/shared/domain/store/createStore';
import { PromotionKPIsStore } from '../domain/PromotionKPIsStore';
import { downloadGoals } from './goals/export/downloadGoals';
import { restoreGoals } from './goals/export/restoreGoals';

export const promotionKPIsStore = createStore({
    reducers: {} as Reducers<PromotionKPIsStore>,
    initialState: {},
    thunks: {
        downloadGoals,
        restoreGoals
    }
});
