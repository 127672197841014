import { createWastePoster } from './createWastePoster';

export const createPosters = ({ lerTypes }) => {
    const wastePosters = lerTypes.filter(isWaste).filter(hasLers).map(createWastePoster).sort(sortByOrder);

    const posters = [...wastePosters];

    return posters;
};

// PRIVATE

const isOtherWastes = (lerType) => lerType.id === 0;
const isDangerousOtherWastes = (lerType) => lerType.id === 604901;
const isWaste = (lerType) => !(isOtherWastes(lerType) || isDangerousOtherWastes(lerType));
const hasLers = (lerType) => lerType.codLers.length >= 1;
const sortByOrder = (a, b) => (a.order > b.order ? 1 : -1);
