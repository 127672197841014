import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import './Buttons.scss';

const FileButton = ({ widthFull = false, opened = false, action }) => {
    const [t] = useTranslation();
    return (
        <button
            type="button"
            className={`buttonAux button__small button__purple ${widthFull ? 'button-full' : ''}`}
            onClick={action}
        >
            {opened ? <IconChevronUp stroke={1.5} size={18} /> : <IconChevronDown stroke={1.5} size={18} />}
            <span className="button__label">
                {opened ? t('hidePhotos', { ns: 'common' }) : t('viewPhotos', { ns: 'common' })}
            </span>
        </button>
    );
};

export default FileButton;
