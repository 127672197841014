import { PromotionKPIsGoalScope } from './PromotionKPIsGoalScope';

export interface PromotionKPIsGoal {
    uuid: string;
    scope: PromotionKPIsGoalScope;
    progress: number;
    severity: GoalSeverityLevels | null;
    value: {
        type: string | null;
        unit: string | null;
        value: number;
    };
}

/** Evaluated goal */
export type GoalMet<G extends any> = Omit<G, 'scope'> & { met: boolean | null };

/** Goal with met information */
export type PromotionKPIsGoalMet = Omit<PromotionKPIsGoal, 'scope'> & { met?: boolean | null };

export enum GoalSeverityLevels {
    Critical = 1,
    Warning = 2
}
