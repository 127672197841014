import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { TraceabilitiesSummaryCriteria } from 'modules/traceabilities/domain/TraceabilitiesSummary';

import { TraceabilitiesStore } from '../../domain/TraceabilitiesStore';

export const loadTraceabilitiesSummaryByDate =
    (params: Omit<TraceabilitiesSummaryCriteria, 'filters'>): Thunk<TraceabilitiesStore> =>
    async (store, { api, logger }) => {
        const filters = store.state.criteria || {};
        if (!filters.promotionId) return;

        try {
            store.dispatch('loadSummaryByDatePending', null);
            const summary = await api.getTraceabilitiesSummaryByDate({ ...params, filters });

            store.dispatch('loadSummaryByDateFulfilled', { summary });
        } catch (e) {
            const error = e as Error;

            logger.send(error);
            store.dispatch('loadSummaryByDateRejected', { error });
        }
    };

export const loadTraceabilitiesSummaryByDateReducers: Pick<
    Reducers<TraceabilitiesStore>,
    'loadSummaryByDatePending' | 'loadSummaryByDateFulfilled' | 'loadSummaryByDateRejected'
> = {
    loadSummaryByDatePending: (state) =>
        produce(state, (draft) => {
            draft.loading.summary = 'pending';
        }),
    loadSummaryByDateFulfilled: (state, { summary }) =>
        produce(state, (draft) => {
            draft.summaryByDate = summary;
            draft.loading.summary = 'succeeded';
        }),
    loadSummaryByDateRejected: (state, { error }) =>
        produce(state, (draft) => {
            draft.error = error;
            draft.loading.summary = 'failed';
        })
};
