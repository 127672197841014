import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import usePrevious from 'hooks/usePrevious';
import InputFecha from '../../Inputs/InputFecha';
import { FilterProps } from '../FilterProps';
import './DateRangeFilter.scss';

type Value = { from: string; to: string } | null;

interface DateRangeFilterProps extends FilterProps {
    name: string;
    label: string;
    value?: Value;
    onChange?: (value: Value) => any;
    onClear?: () => any;
}

const DateRangeFilter = (props: DateRangeFilterProps) => {
    const [t] = useTranslation();
    const [value, _setValue] = useState(props.value || null);
    const prevValue = usePrevious(value);

    const setValue = (value: Value) => {
        const hasValue = value?.from || value?.to;
        _setValue(hasValue ? value : null);
    };

    useEffect(() => {
        // prevents emitting onChange when the component mounts
        if (prevValue === undefined) return;

        if (props.onChange) props.onChange(value || null);
    }, [JSON.stringify(value)]);

    useEffect(() => {
        // prevents emitting onChange when the component mounts
        if (prevValue === undefined) return;
        // prevents emitting onChange when the value is the same
        if (JSON.stringify(props.value) === JSON.stringify(value)) return;

        if (props.value) setValue(props.value);
    }, [JSON.stringify(props.value)]);

    const hasValue = value?.from || value?.to;

    const modifiers = {
        hasValue: hasValue ? 'DateRangeFilter--has-value' : '',
        isChip: props.variant === 'chip' ? 'DateRangeFilter--is-chip' : ''
    };

    return (
        <div className={`DateRangeFilter ${Object.values(modifiers).join(' ')}`}>
            <p className="DateRangeFilter__label">{props.label}</p>
            <div className="DateRangeFilter__fields">
                <InputFecha
                    id={props.name + 'from'}
                    label={`${props.label} ${t('date.from', { ns: 'common' })}`}
                    value={value?.from || ''}
                    onChange={(e) => setValue({ from: e.target.value, to: value?.to || '' })}
                    notLimit
                    // Just to ignore optional fields
                    {...({} as any)}
                />
                <span className="DateRangeFilter__separator">-</span>
                <InputFecha
                    id={props.name + 'to'}
                    label={`${props.label} ${t('date.to', { ns: 'common' })}`}
                    value={value?.to || ''}
                    onChange={(e) => setValue({ to: e.target.value, from: value?.from || '' })}
                    notLimit
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </div>
        </div>
    );
};

export default DateRangeFilter;
