import HomePage from '../pages/HomePage';

export const HOME_VIEWS = {
    list: '/list',
    analysis: '/analysis',
    configuration: '/configuration'
};

export const HOME_ROUTES = [
    {
        component: HomePage,
        path: '/home',
        requiredPermission: 'canView',
        section: 'enterprise',
        exact: false
    }
];
