import { produce } from 'immer';
import { DiExtractionStore } from 'modules/di-extraction/domain/DiExtractionStore';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

export const fetchNextDiExtraction =
    (): Thunk<DiExtractionStore> =>
    async (store, { api }) => {
        try {
            store.dispatch('fetchNextPending', {});

            const currentCriteria = store.state.criteria;
            const dataLength = store.state.diExtractions.length;

            const { results, withWarnings } = await api.getDiExtractions({
                ...currentCriteria,
                size: 1,
                page: dataLength
            });

            if (results.length > 1) throw new Error('fetchNextDiExtraction: Only one result allowed');

            if (results.length === 0) return;

            store.dispatch('fetchNextFulfilled', { diExtraction: results[0], withWarnings });
        } catch (e) {
            store.dispatch('errorSetted', { error: e as Error });
        }
    };

export const fetchNextReducers: Pick<Reducers<DiExtractionStore>, 'fetchNextPending' | 'fetchNextFulfilled'> = {
    fetchNextPending: (state, payload) =>
        produce(state, (draft) => {
            draft.loading = 'updating';
        }),
    fetchNextFulfilled: (state, payload) =>
        produce(state, (draft) => {
            draft.diExtractions.push(payload.diExtraction);
            draft.withWarnings = payload.withWarnings;
        })
};
