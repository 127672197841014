export const docMapperPUT = ({ promoId, docData, type, file }) => {
    const doc = {
        promotionId: promoId,
        document: {
            id: docData?.id,
            name: file?.name || docData?.name,
            remarks: docData?.remarks || '',
            date: docData?.date,
            type: { id: type?.id, name: type?.name }
        },
        content: {
            bytes: file?.bytes,
            type: file?.type
        }
    };

    return doc;
};
