export const docMapperPOST = ({ promoId, docData, type, docsArray }) => {
    const doc = {
        promotionId: promoId,
        type: { id: type?.id, name: type?.name },
        date: docData?.date,
        docsArray: docsArray
    };

    return doc;
};
