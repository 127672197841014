import { documentStore } from 'modules/document/application/documentStore';
import { contextStoreAdapter } from 'modules/shared';

const Ctx = contextStoreAdapter(documentStore)({ name: 'DocumentStore' });

export const DocumentProvider = Ctx.Provider;

export const useDocuments = {
    findById: Ctx.createQueryHook(({ api }) => api.findDocumentById.bind(api)),
    upload: Ctx.createMutationHook(({ api }) => api.uploadDocument.bind(api)),
    remove: Ctx.createMutationHook(({ api }) => api.removeDocument.bind(api))
};
