import { BarItem, ResponsiveBar } from '@nivo/bar';
import { TituloH2 } from '../../../../components/BasicComponents/Titulos/Titulos';
import '../Impact.scss';
import CustomStackedBar from './CustomStackedBar';
import options, { labelOptions } from './options';

const ByLerGraphData = ({ graphTitle, graphTitleSuffix, graphData, legendTitle, onlyReal = false, stackedBars }) => {
    return (
        <div className="Impact__graph">
            <div className="Impact__graphTitle">
                <TituloH2 titulo={graphTitle} /> <span className="Impact__graphTitleSuffix">{graphTitleSuffix}</span>
            </div>
            <div className="Impact__lineGraph">
                <ResponsiveBar
                    colors={options.colorsBarChart}
                    margin={options.margin}
                    data={graphData}
                    groupMode="grouped"
                    barComponent={(barProps) => {
                        if (barProps.data.id !== 'Potential') return <BarItem {...barProps} />;

                        return <CustomStackedBar barProps={barProps} />;
                    }}
                    keys={onlyReal ? ['Real'] : ['Real', 'Potential', 'Estándar']}
                    indexBy="typeLer"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={options.axisBottom}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        legend: legendTitle,
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    animation={false}
                    enableLabel={false}
                    borderRadius={3}
                />
            </div>
            {!onlyReal && (
                <div className="Impact__containerLegends">
                    <p>
                        <span className={`impact__legend impact__legend--${labelOptions['real'].id}`}></span>
                        {labelOptions['real'].label}
                    </p>
                    <p>
                        <span className={`impact__legend impact__legend--${labelOptions['potential']?.id}`}></span>
                        {labelOptions['potential']?.label}
                    </p>
                    <p>
                        <span className={`impact__legend impact__legend--${labelOptions['standard'].id}`}></span>
                        {labelOptions['standard'].label}
                    </p>
                    <p>
                        <span className={`impact__legend impact__legend--${labelOptions['mix'].id}`}></span>
                        {labelOptions['mix'].label}
                    </p>
                </div>
            )}
        </div>
    );
};

export default ByLerGraphData;
