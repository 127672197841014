export const COLUMNS_ORDER = [
    {
        position: 0,
        column: 'metadata'
    },
    {
        position: 1,
        column: 'lerInfo'
    },
    {
        position: 2,
        column: 'wasteManagerAndCarrier'
    },
    {
        position: 3,
        column: 'valorizationInfo'
    },
    {
        position: 4,
        column: 'weight'
    },
    {
        position: 5,
        column: 'volume'
    },
    {
        position: 6,
        column: 'cost'
    },
    {
        position: 7,
        column: 'di'
    },
    {
        position: 8,
        column: 'actions'
    }
];
