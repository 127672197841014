export enum TagColors {
    crimson = 'crimson',
    mediumblue = 'mediumblue',
    green = 'green',
    darkorange = 'darkorange',
    dodgerblue = 'dodgerblue',
    teal = 'teal',
    sienna = 'sienna',
    fuchsia = 'fuchsia',
    chocolate = 'chocolate',
    dimgrey = 'dimgrey'
}
