import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import EmptyState from 'components/BasicComponents/EmptyState';
import { Alert, useAlerts } from 'modules/alerts';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { usePromotion } from 'modules/promotion';
import { useState } from 'react';
import AlertDetailPopover from './AlertDetailPopover';
import './Alerts.scss';
import { ALERT_STYLE } from './constants/alerts.constants';

const Alerts = () => {
    const [{ promotion }] = usePromotion();
    const [selectedAlert, setSelectedAlert] = useState<Alert | null>(null);
    const [, analytics] = useAnalyticsStore();

    if (!promotion) return <></>;

    // eslint-disable-next-line
    const { data: alerts } = useAlerts({ promotionId: promotion?.id });

    const closeModal = () => {
        setSelectedAlert(null);
    };

    return (
        <SimpleCard className="Alerts">
            <h3 className="Alerts__title">Alertas</h3>
            {alerts?.length === 0 && <EmptyState mode="happyEmptyState" subject="alertas" />}
            {alerts && alerts?.length > 0 && (
                <div className="Alerts__container">
                    {alerts.map((alert) => (
                        <p
                            onClick={() => {
                                setSelectedAlert(alert);
                                analytics.event(AnalyticsEvents.PROMO_ALERT_CLICKED);
                            }}
                            className={`Alerts__alert Alerts__alert-${ALERT_STYLE[alert.level].color}`}
                        >
                            {ALERT_STYLE[alert.level].icon}
                            <span>{alert.title}</span>
                        </p>
                    ))}
                </div>
            )}
            {selectedAlert && (
                <AlertDetailPopover
                    alert={selectedAlert}
                    openModal={selectedAlert !== null}
                    setOpenModal={closeModal}
                    promotionId={promotion?.id}
                />
            )}
        </SimpleCard>
    );
};

export default Alerts;
