import { Trans } from 'react-i18next';

import HasPermission from 'components/AccessCheckers/HasPermission';
import { useDiExtraction } from 'modules/di-extraction';
import AutomaticReadingMassiveApprove from '../AutomaticReadingMassiveApprove';
import AutomaticReadingMassiveReject from '../AutomaticReadingMassiveReject/AutomaticReadingMassiveReject';
import AutomaticReadingMassiveRetry from '../AutomaticReadingMassiveRetry';
import './AutomaticReadingHeader.scss';

interface AutomaticReadingHeaderProps {}

const AutomaticReadingList = (props: AutomaticReadingHeaderProps) => {
    const [{ counters, withWarnings }] = useDiExtraction();

    if (counters.totalApplicable === 0) return null;

    return (
        <header className="AutomaticReadingHeader">
            <div data-testid="AutomaticReadingHeader__items" className="AutomaticReadingHeader__items">
                <Trans
                    i18nKey="foundDIs"
                    ns="automaticReading"
                    values={{ items: counters.totalApplicable, withWarnings }}
                    components={{ b: <strong /> }}
                />
            </div>

            {/* Actions */}
            <div className="AutomaticReadingHeader__actions">
                <HasPermission ifRole="canView" section="automaticReadingRetry">
                    <AutomaticReadingMassiveRetry />
                </HasPermission>
                <AutomaticReadingMassiveReject />
                <AutomaticReadingMassiveApprove />
            </div>
        </header>
    );
};

export default AutomaticReadingList;
