import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

import './ColorSelectorOption.scss';

interface ColorSelectorOptionProps {
    color: string;
}

const ColorSelectorOption = (props: ColorSelectorOptionProps) => {
    const [t] = useTranslation('common');
    const style = { '--_color': props.color } as CSSProperties;

    return (
        <>
            <div style={style} className="ColorSelectorOption" data-testid="ColorSelectorOption">
                <div className="ColorSelectorOption__circle" />
                <div className="ColorSelectorOption__name">{t(`colors.${props.color}`)}</div>
            </div>
        </>
    );
};

export default ColorSelectorOption;
