import { DiExtrationStatus } from '../IDiExtraction';

// At pending review tab, we don't want to show: APPROVED, REJECTED
// In addition, front does not use: NOT_VALID, DOCUMENT_UPLOAD_ERROR, PROJECT_NOT_FOUND
export const applicableStatusList = [
    DiExtrationStatus.PENDING,
    DiExtrationStatus.PENDING_REVIEW,
    DiExtrationStatus.VALIDATION_ERROR,
    DiExtrationStatus.DOCUMENT_UPLOAD_ERROR,
    DiExtrationStatus.NOT_VALID,
    DiExtrationStatus.ALREADY_PROCESSED
];
