import fetcher from 'lib/fetcher';

import { WasteManager } from '../../domain/WasteManagers/WasteManager';
import { WasteManagersCriteria } from '../../domain/WasteManagers/WasteManagersCriteria';
import { WasteManagersApi } from '../../domain/WasteManagersApi';

export const httpWasteManagersApi: WasteManagersApi = {
    async getWasteManagers(criteria) {
        const noTerminatedWasteManagersFilter = { 'estadoId.notEquals': 5604 }; // Estado BAJA
        const newCriteria = { ...criteria, noTerminatedWasteManagersFilter };

        const { data: wasteManagers } = await fetcher('api/waste-manager', { params: newCriteria });

        const wasteManagersWithPpgcls = await Promise.all(
            wasteManagers.map((wasteManager) => getWasteManagerWithPpgcl(wasteManager, criteria))
        );

        return wasteManagersWithPpgcls;
    }
};

async function getWasteManagerWithPpgcl(wasteManager: WasteManager, criteria: WasteManagersCriteria) {
    const params = {
        'gestoraId.equals': wasteManager.id,
        'valorizationCode.equals': criteria['valuationCode.equals'],
        'codLER.in': criteria['codLer.in']
    };

    const { data } = await fetcher('api/pro-ges-cod-lers', { params });

    const ppgcls = data.content.valorizationProcess;
    const valorizationProcess = ppgcls[0];

    return {
        ...wasteManager,
        procesoGestora: valorizationProcess?.procesoGestora,
        ppgcls: ppgcls
    };
}
