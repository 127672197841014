import { IconAlertCircle } from '@tabler/icons';

import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import Navbar from 'components/BasicComponents/Navbar/Navbar';
import TopBar from 'components/BasicComponents/TopBar/TopBar';
import { withAuth } from 'context/AuthProvider';
import LogoutHandler from 'lib/LogoutHandler';
import ErrorHandler from 'lib/ToastHandler';

import { usePromotion } from 'modules/promotion';
import { Trans } from 'react-i18next';
import './Layout.scss';
import { Link } from 'react-router-dom';

const Layout = ({
    children,
    promocion,
    alerts = null,
    navBar = true,
    breadcrumbs = [],
    padding = undefined,
    ...rest
}) => {
    const [{ promotion }] = usePromotion();
    const { isLoggedin } = rest;

    return (
        <div className="layout">
            {navBar && isLoggedin && <Navbar />}
            <div className="layout__content">
                {isLoggedin && <TopBar showOnMobile={!navBar} showLogo={!navBar} breadcrumbs={breadcrumbs} />}

                {promocion && !promocion.nima && (
                    <AlertCard
                        className="card__alert-warning card__alert--borderless card__alert--center"
                        icon={<IconAlertCircle />}
                    >
                        <Trans
                            i18nKey="common:warnings.promotionWithoutNima"
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            components={[<Link to={`/promotion/${promotion?.id}/settings/project`} />]}
                        />
                    </AlertCard>
                )}

                {alerts}

                <main className="layout__main" id="main-layout" style={{ padding }}>
                    {children}
                    <ErrorHandler />
                    {isLoggedin && <LogoutHandler />}
                </main>
            </div>
        </div>
    );
};

export default withAuth(Layout);
