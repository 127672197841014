import { Redirect } from 'react-router-dom';

export const OTHERS_ROUTES = [
    // Just for keep it compatible with old home links
    {
        component: () => <Redirect to="/home/list" />,
        path: '/organization',
        requiredPermission: 'canView',
        section: 'enterprise',
        withoutPromotion: true
    }
];
