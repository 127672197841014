import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { TraceabilitiesSummaryCriteria } from 'modules/traceabilities/domain/TraceabilitiesSummary';

import { TraceabilitiesStore } from '../../domain/TraceabilitiesStore';

export const loadTraceabilitiesSummaryTotals =
    (params: Omit<TraceabilitiesSummaryCriteria, 'filters' | 'unit'>): Thunk<TraceabilitiesStore> =>
    async (store, { api, logger }) => {
        const filters = store.state.criteria || {};
        if (!filters.promotionId) return;
        try {
            store.dispatch('loadSummaryTotalsPending', null);
            const summary = await api.getTraceabilitiesSummaryTotals({ ...params, filters });

            store.dispatch('loadSummaryTotalsFulfilled', { summary });
        } catch (e) {
            const error = e as Error;

            logger.send(error);
            store.dispatch('loadSummaryTotalsRejected', { error });
        }
    };

export const loadTraceabilitiesSummaryTotalsReducers: Pick<
    Reducers<TraceabilitiesStore>,
    'loadSummaryTotalsPending' | 'loadSummaryTotalsFulfilled' | 'loadSummaryTotalsRejected'
> = {
    loadSummaryTotalsPending: (state) =>
        produce(state, (draft) => {
            draft.loading.summary = 'pending';
        }),
    loadSummaryTotalsFulfilled: (state, { summary }) =>
        produce(state, (draft) => {
            draft.summaryTotals = summary;
            draft.loading.summary = 'succeeded';
        }),
    loadSummaryTotalsRejected: (state, { error }) =>
        produce(state, (draft) => {
            draft.error = error;
            draft.loading.summary = 'failed';
        })
};
