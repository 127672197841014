import { GridState } from 'ag-grid-community';
import useStateFromStorage from 'hooks/useStateFromStorage';
import { useEffect, useMemo, useState } from 'react';

const KEY = 'Comparative';

export function useComparativeState() {
    const [shouldPersist, setShouldPersist] = useStateFromStorage({
        key: `${KEY}__shouldPersist`,
        initialState: false
    });

    const storage: Storage = shouldPersist ? localStorage : sessionStorage;

    const restore = () => JSON.parse(storage.getItem(KEY) || '{}') as GridState;
    const mutate = (value: Object) => storage.setItem(KEY, JSON.stringify(value));

    // In order to avoid ag grid re-rendering, memoize the initial state from storage instead of use state
    const initialState = useMemo(() => restore(), []);

    const [state, setState] = useState<GridState>(restore());

    const save = (gridState: GridState) => {
        // Only pick the properties we want to persist
        const subset: Array<keyof GridState> = [
            'filter',
            'sort',
            'columnOrder',
            'columnPinning',
            'columnGroup',
            'columnSizing',
            'columnVisibility',
            'scroll'
        ];

        const state = subset.reduce((acc, key) => {
            acc[key] = gridState[key];
            return acc;
        }, {});

        mutate(state);
        setState(state);
    };

    // Updates the state when storage changes
    useEffect(() => {
        mutate(state);
    }, [shouldPersist]);

    return { state, save, shouldPersist, setShouldPersist, initialState };
}
