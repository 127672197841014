/* 
This interface should be moved to a domain folder in the future.

However, at the time this file was made, there was no intention in spending time with a full 
activities refactor. So that's why there is no clean architecture in this feature yet.
*/

export interface Activity {
    id: number;
    subject: string;
    author?: any; // user name and surname
    observations: string;
    date: string;
    plannedDate?: any;
    type: string;
    typeId: number;
    status: 'ABIERTO' | 'PLANIFICADA' | 'EN_PROGRESO' | 'BORRADOR' | 'INCOMPLETA' | 'CERRADO';
    wasteManagerName?: string;
    visitRecords?: any; // another name ?
    closureNotification: boolean; // not sure what this means
    visibleByClients: boolean;
    onSite: boolean | null;
}

export const activityAdapter = (oldActivityData): Activity => {
    const activity: Activity = {
        id: oldActivityData.id,
        subject: oldActivityData.asunto,
        author: oldActivityData.clienteUser?.nombreWeb || null,
        observations: oldActivityData.observaciones || '',
        date: oldActivityData.fechaActividad,
        plannedDate: oldActivityData.fechaPrevistaActividad || null,
        type: oldActivityData.tipoActividad.descripcionCorta,
        typeId: oldActivityData.tipoActividad.id,
        visibleByClients: oldActivityData.tipoActividad.visibleExterno,
        status: oldActivityData.estadoActividad,
        wasteManagerName: oldActivityData.gestora?.empresa || null,
        visitRecords: oldActivityData.visitRecords || null,
        closureNotification: oldActivityData.closureNotification,
        onSite: oldActivityData.onSite
    };
    return activity;
};
