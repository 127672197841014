import { useEffect } from 'react';

import { DiExtrationStatus, IDiExtraction, useDiExtraction } from 'modules/di-extraction';

export function useReloadExtractionsWhilePending() {
    const [{ diExtractions, criteria, counters }, { reloadDiExtractions }] = useDiExtraction();
    const shouldPolling = (counters.PENDING || 0) > 0 || diExtractions.some(isPending);

    useEffect(() => {
        if (!criteria.promotionId) return;
        if (!shouldPolling) return;

        const polling = setInterval(() => {
            if (document.hidden) return;
            reloadDiExtractions(undefined, { silent: true });
        }, 10_000);

        if (!shouldPolling) clearInterval(polling);
        return () => clearInterval(polling);
    }, [shouldPolling, JSON.stringify(criteria)]);
}

const isPending = (diExt: IDiExtraction) => diExt.state === DiExtrationStatus.PENDING;
