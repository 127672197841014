import { IconAlertCircle } from '@tabler/icons';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import ResiduoPeligroso from 'assets/imagenes/residuo-peligroso.jpg';
import logoCoCircular from 'assets/logo-blue.png';
import { legacyEnterpriseAdapter } from 'modules/enterprise/related-enterprise/domain/legacyEnterpriseAdapter';
import './WasteSignage.scss';
import { imagesByLerType } from './assets/imagesByLerType';
import { calcDescriptionFontSize } from './utils/calcDescriptionFontSize';
import { calcTitleFontSize } from './utils/calcTitleFontSize';

const WasteSignage = forwardRef((props, ref) => {
    const { promocion, posters } = props;
    const [t] = useTranslation();

    const mainEnterprise = legacyEnterpriseAdapter(promocion?.relatedEnterprises);

    return (
        <div ref={ref} data-testid="wasteSignagePosters" className="WasteSignage__posters">
            {posters.map((poster) => (
                <div data-testid="wasteSignagePoster" className="WasteSignage__poster" key={poster.id + poster.title}>
                    <div className="WasteSignage__dataContainer">
                        <div className="WasteSignage__mainDataContainer">
                            <img className="WasteSignage__logo" src={logoCoCircular} alt="logo-cocircular" />
                            <p className="WasteSignage__uppertext">Depositar exclusivamente</p>
                            <p
                                data-testid="wasteSignagePosterTitle"
                                className="WasteSignage__title"
                                style={{
                                    fontSize: calcTitleFontSize(poster.title),
                                    color: poster.isRestrictedUsage ? '#e84420' : null
                                }}
                            >
                                {poster.title}
                            </p>

                            {poster.isRestrictedUsage && (
                                <p
                                    data-testid="wasteSignagePosterRestrictedUsage"
                                    className="WasteSignage__restrictedUsage"
                                >
                                    {t('restrictedUsage', { ns: 'documentation' })} <IconAlertCircle />
                                </p>
                            )}

                            {poster.lers.length > 0 && (
                                <div data-testid="wasteSignagePosterDesc" className="WasteSignage__desc">
                                    <p className="WasteSignage__descLabel">LER:</p>
                                    <div style={{ fontSize: calcDescriptionFontSize(poster.lers.join('')) }}>
                                        {poster.lers.map((ler, index) => (
                                            <span key={ler}>
                                                {index > 0 && <span className="WasteSignage__spacer">|</span>}
                                                <span className="WasteSignage__descValue">{ler}</span>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="WasteSignage__metaDataContainer">
                            {poster.isDangerous && (
                                <div className="WasteSignage__metaData">
                                    <p className="WasteSignage__metaDataLabel">Fecha inicio almacenamiento: </p>
                                    <p className="WasteSignage__metaDataValue">&nbsp;</p>
                                </div>
                            )}

                            <div className="WasteSignage__metaData">
                                <p className="WasteSignage__metaDataLabel">Nima: </p>
                                <p className="WasteSignage__metaDataValue">{promocion.nima}</p>
                            </div>

                            {promocion && (
                                <div className="WasteSignage__metaData">
                                    <p className="WasteSignage__metaDataLabel">Proyecto: </p>
                                    <p className="WasteSignage__metaDataValue">{promocion.nombre}</p>
                                </div>
                            )}

                            {mainEnterprise && (
                                <div className="WasteSignage__metaData">
                                    <p className="WasteSignage__metaDataLabel">Empresa: </p>
                                    <p className="WasteSignage__metaDataValue">
                                        {mainEnterprise?.nombreComercial} / {mainEnterprise?.cif}
                                    </p>
                                </div>
                            )}

                            {promocion.direccion && (
                                <div className="WasteSignage__metaData">
                                    <p className="WasteSignage__metaDataLabel">Localización: </p>
                                    <p className="WasteSignage__metaDataValue">{promocion.direccion.direccion}</p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div
                        className="WasteSignage__imageContainer"
                        style={{
                            backgroundImage: 'url(' + imagesByLerType[poster.id] + ')'
                        }}
                    >
                        <div className="WasteSignage__triangle"></div>
                        {poster.isDangerous && <img className="WasteSignage__dangerousImg" src={ResiduoPeligroso} />}
                    </div>
                </div>
            ))}
        </div>
    );
});

WasteSignage.displayName = 'WasteSignage';

export default WasteSignage;
