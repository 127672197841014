import { useTranslation } from 'react-i18next';

import { useReloadAfterUpdate } from 'features/automatic-reading/hooks/useReloadAfterUpdate';
import TraceabilitiesPageHeader from 'features/traceabilities/components/TraceabilitiesPageHeader/TraceabilitiesPageHeader';
import { useLoadAfterFiltersChange } from '../../hooks/useLoadAfterFiltersChange';
import { useReloadExtractionsWhilePending } from '../../hooks/useReloadExtractionsWhilePending';
import AutomaticReadingFilters from '../AutomaticReadingFilters/AutomaticReadingFilters';
import AutomaticReadingList from '../AutomaticReadingList/AutomaticReadingList';
import './AutomaticReadingPage.scss';

const AutomaticReadingPage = () => {
    const { t } = useTranslation('automaticReading');
    useReloadExtractionsWhilePending();
    useLoadAfterFiltersChange();
    useReloadAfterUpdate();

    return (
        <div className="AutomaticReadingPage">
            <TraceabilitiesPageHeader title={t('diReading')} />
            <AutomaticReadingFilters />
            <AutomaticReadingList />
        </div>
    );
};

export default AutomaticReadingPage;
