import MultiProvider from 'lib/MultiProvider';
import { httpEnterprisesApi } from 'modules/enterprise/enterprises/infrastructure/api/httpEnterprisesApi';
import { EnterprisesProvider } from 'modules/enterprise/enterprises/infrastructure/react/enterprisesContext';
import { PromotionProvider } from 'modules/promotion';
import { httpPromotionApi } from 'modules/promotion/infrastructure/api/httpPromotionApi';
import { TagsProvider, httpTagsApi } from 'modules/tags';
import TagManager from './TagManager';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <MultiProvider
        providers={[
            <TagsProvider api={httpTagsApi} />,
            <EnterprisesProvider api={httpEnterprisesApi} /> /** Required for TagManagerEnterpriseSelector */,
            <PromotionProvider api={httpPromotionApi} /> /** Required for PromotionSelectAutocomplete */
        ]}
    >
        <TagManager />
    </MultiProvider>
);
