import Gestoras from 'components/ComplexComponents/Promotion/Gestoras';

const WasteManagers = () => {
    return (
        <>
            <Gestoras />
        </>
    );
};

export default WasteManagers;
