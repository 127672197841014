import { Redirect } from 'react-router-dom';

import { ACTIVITIES_ROUTES } from 'features/activities/routes/ActivitiesRoutes';
import { DASHBOARD_ROUTES } from 'features/dashboard/routes/DashboardRouter';
import ImpactPage from 'features/impact/components/ImpactPage';
import { TRACEABILITIES_ROUTES } from 'features/traceabilities/routes/TraceabilitiesRoutes';
import Expedient from '../pages/Expedient/Expedient';
import Promotion from '../pages/Promotion';
import Settings from '../pages/Settings';

export const promotionRouteRoot = '/promotion/:promotionId';

export const INTERNAL_PROMOTION_ROUTES = [
    ...TRACEABILITIES_ROUTES,
    ...ACTIVITIES_ROUTES,
    ...DASHBOARD_ROUTES,
    {
        component: ImpactPage,
        path: promotionRouteRoot + '/impact',
        requiredPermission: 'canView',
        section: 'impact',
        exact: false
    },
    {
        component: Settings,
        path: promotionRouteRoot + '/settings',
        requiredPermission: 'canView',
        section: 'promotion'
    },
    {
        component: Expedient,
        path: promotionRouteRoot,
        requiredPermission: 'canView',
        section: 'promotion'
    }
];

export const PROMOTION_ROUTES = [
    {
        component: Promotion,
        path: promotionRouteRoot,
        requiredPermission: 'canView',
        section: 'promotion',
        exact: false
    },

    // simply to maintain compatibility with the old routes.
    // should be deleted as soon as all the links that point here are changed
    {
        component: () => <Redirect to="/home" />,
        path: '/promotion',
        requiredPermission: 'canView',
        section: 'promotion'
    }
];
