import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../context/AuthProvider';
import Layout from '../../../pages/Layout/Layout';
import GoBack from '../../../components/BasicComponents/Buttons/GoBack';
import SubmitButton from '../../../components/BasicComponents/Buttons/SubmitButton';
import { TituloH1 } from '../../../components/BasicComponents/Titulos/Titulos';
import Buscador from '../../../components/BasicComponents/Buscador/Buscador';
import MessageNotComplete from '../../../components/BasicComponents/Messages/MessageNotComplete';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import DocumentTypeSelectAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/DocumentTypeSelectAutocomplete';
import { allFieldsComplete } from '../../../utils/helpers/general.helpers';
import { VALID_DOWNLOAD_IDS } from '../constants/documentation.constants';
import { createDownload } from '../api/createDownload';
import { getDocuments } from '../api/getDocuments';

const NewDownloadRequestPage = (props) => {
    const [t] = useTranslation();
    let history = useHistory();

    const INITIAL_STATE = {
        id: uuid(),
        documentType: '',
        promotionId: props?.promocion?.id
    };

    const [downloadData, setDownloadData] = useState(INITIAL_STATE);
    const [isLoading, setIsLoading] = useState(false);
    const [validation, setValidation] = useState(false);
    const [messageNotComplete, setMessageNotComplete] = useState('');
    const [numExistingDocs, setNumExistingDocs] = useState(null);

    useEffect(() => {
        if (props?.promocion?.id !== undefined) {
            setDownloadData({ ...downloadData, promotionId: props?.promocion?.id });
        }
    }, [props?.promocion]);

    useEffect(() => {
        if (props?.promocion?.id !== undefined && downloadData?.documentType?.id !== undefined) {
            getNumDocuments();
        }
    }, [downloadData?.documentType?.id, props?.promocion?.id]);

    const getNumDocuments = async () => {
        let filter = `?tipoId.equals=${downloadData?.documentType?.id}`;
        filter += downloadData?.documentType?.wasteManagement ? '&usage.equals=waste-management' : '';

        const numDocsAux = await getDocuments(props.promocion?.id, filter);

        if (numDocsAux?.data?.code == 0) {
            setNumExistingDocs(numDocsAux?.headers['x-total-count']);
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (validateForm()) {
            const downloadDataMapped = mapperPutDownload();
            const docRequest = await createDownload(downloadDataMapped);

            if (docRequest?.data?.code == 0 || docRequest?.status == 204) {
                setDownloadData(INITIAL_STATE);
            }
        }

        setIsLoading(false);
    };

    const mapperPutDownload = () => {
        const downloadDataMapped = { ...downloadData, documentTypeId: downloadData?.documentType?.id };
        delete downloadDataMapped.documentType;
        return downloadDataMapped;
    };

    const requiredFields = {
        id: downloadData?.id,
        promotionId: downloadData?.promotionId,
        documentType: downloadData?.documentType?.id
    };

    const validateForm = () => {
        if (allFieldsComplete(requiredFields)) {
            setMessageNotComplete('');
            setValidation(false);
            return true;
        } else {
            setMessageNotComplete('Complete los campos requeridos');
            setValidation(true);
            return false;
        }
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <TituloH1 titulo={t('selectDocuments', { ns: 'documentation' })} />
            <Buscador titulo={``} column={true}>
                <form onSubmit={handleFormSubmit}>
                    <DocumentTypeSelectAutocomplete
                        values={downloadData}
                        value={downloadData.documentType}
                        setValues={setDownloadData}
                        validacion={validation}
                        limitedOptionsIds={VALID_DOWNLOAD_IDS}
                        propName="documentType"
                        required
                    />
                    {messageNotComplete && <MessageNotComplete message={t('complete', { ns: 'common' })} />}
                    {numExistingDocs !== null && (
                        <>
                            {numExistingDocs > 0 ? (
                                <span>
                                    {t('numDocsToDownload', { ns: 'documentation' })}: {numExistingDocs}
                                </span>
                            ) : (
                                <MessageNotComplete message={t('noExistingDocs', { ns: 'documentation' })} />
                            )}
                        </>
                    )}
                    <SubmitButton
                        text={t('requestNewDownload', { ns: 'documentation' })}
                        buttonDisabled={isLoading || numExistingDocs === null || numExistingDocs <= 0}
                    />
                </form>
            </Buscador>
            {isLoading && <Spinner />}
        </Layout>
    );
};
export default withAuth(NewDownloadRequestPage);
