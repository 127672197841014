/**
 * Creates a based on reducers object
 * @link https://react.dev/learn/extracting-state-logic-into-a-reducer#step-2-write-a-reducer-function
 * @example
 * createReducer({
 * 'tasks/pending': (state) => ({ ...state, loading: 'pending' }),
 * 'tasks/fulfilled': (state, payload) => ({ ...state, tasks: payload.tasks }),
 * });
 *  */
export default function createReducer(reducers = {}) {
    return (state, action) => {
        const reducer = reducers[action.type];

        if (!reducer) {
            throw new Error(`[createReducer] no reducer function provided for ${action.type}`);
        }

        const newState = reducers[action.type](state, action.payload);

        return newState;
    };
}
