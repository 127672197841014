export type WasteManagement = Array<WasteManagementEntry>;

export interface WasteManagementEntry {
    id: number;
    lerId: number;
    promotionId: number;
    promotionName: string;
    lerType: string;
    codLer: string;
    budget?: number;
    volume?: number;
    weight: number;
    external: boolean;
    lerOrientative: boolean;
    type: WasteManagementEntryType;
}

export enum WasteManagementEntryType {
    PGR = 'PGR',
    EGR = 'EGR'
}

export interface WasteManagementLer {
    id: number;
    code: string;
    descripcion: string;
    type: {
        id: number;
        name: string;
    };
}

export const wasteManagementEntryValidation = (entry?: Partial<WasteManagementEntry>) => {
    if (!entry?.weight) return { valid: false, field: 'weight' };
    if (!entry?.lerId) return { valid: false, field: 'lerId' };

    return { valid: true };
};

type WasteManagementEntryCreator = Partial<Omit<WasteManagementEntry, 'lerType' | 'codLer'>>;

export function wasteManagementEntryCreatorAdapter(entry: Partial<WasteManagementEntry>): WasteManagementEntryCreator {
    return {
        id: entry.id,
        promotionId: entry.promotionId,
        promotionName: entry.promotionName,
        budget: entry.budget,
        volume: entry.volume,
        weight: entry.weight,
        external: entry.external,
        lerOrientative: entry.lerOrientative,
        type: entry.type,
        lerId: entry.lerId
    };
}
