import { useState, useEffect } from 'react';
import userServices from '../api/user/user.services';

export const useUsers = (promotionId) => {
    const [data, setData] = useState([] as any[]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        if (promotionId) {
            userServices
                .getUsersByPromoId(promotionId)
                .then((result) => {
                    setData(result);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setData([]);
                    setLoading(false);
                });
        }
    }, [promotionId]);

    return [data, setData, loading] as [typeof data, typeof setData, typeof loading];
};
