import { Controller, FieldValues } from 'react-hook-form';

import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import InputText from 'components/BasicComponents/Inputs/InputText';
import { FormControlProps } from './shared/FormControlProps';
import { useErrorMessage } from './shared/useErrorMessage';

type InputProps = {
    label?: string;
    append?: React.ReactNode;
};

type InputFormControlProps<Values extends FieldValues> = InputProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    FormControlProps<Values>;

const InputFormControl = <Values extends FieldValues>(props: InputFormControlProps<Values>) => {
    const { control, name, label, append, ...inputAttributes } = props;
    const isNumeric = ['tel', 'number'].includes(props.type || '');

    const { errorMsg } = useErrorMessage(props);

    return (
        <Controller
            control={props.control}
            name={props.name}
            render={({ field }) =>
                isNumeric ? (
                    <InputNumber
                        {...inputAttributes}
                        name={field.name}
                        label={props.label}
                        value={field.value}
                        onChange={({ target }) => {
                            const value = parseInt(target.value);
                            field.onChange(isNaN(value) ? undefined : value);
                        }}
                        validacion={!!errorMsg}
                        required={!!errorMsg}
                        errorMsg={errorMsg}
                        onBlur={field.onBlur as any}
                        inputContainer={props.className || ''}
                        extraElement={append}
                        // Just to ignore optional fields
                        {...({} as any)}
                    />
                ) : (
                    <InputText
                        {...inputAttributes}
                        name={field.name}
                        label={props.label}
                        value={field.value}
                        onChange={field.onChange}
                        validacion={!!errorMsg}
                        required={!!errorMsg}
                        errorMsg={errorMsg}
                        onBlur={field.onBlur as any}
                        inputContainer={props.className}
                        extraElement={append}
                    />
                )
            }
        />
    );
};

export default InputFormControl;
