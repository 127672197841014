import { associateTraceabilities } from '../../associate_documents_traceabilities/api/associateTraceability';
import { createDocument } from '../../documentation/api/createDocument';
import { getDocumentTypeById } from '../../documentation/api/getDocumentTypeById';
import { REUSE_CERTIFICATE_TYPE_ID } from '../../documentation/constants/documentation.constants';
import { prepareDocsArray } from '../../documentation/helpers/documentation.helpers';
import { docMapperPOST } from '../../documentation/mappers/docMapperPOST';

const _mapperPostReuseCertificate = async ({ files, values, promoId }) => {
    let docReuse = null;
    const docReuseTypeRequest = await getDocumentTypeById(REUSE_CERTIFICATE_TYPE_ID);
    const docReuseType = docReuseTypeRequest?.data?.[0];

    if (docReuseType !== undefined) {
        const docsArray = prepareDocsArray(files);
        docReuse = docMapperPOST({
            promoId,
            docData: values,
            type: { id: docReuseType?.id, name: docReuseType?.descripcion },
            docsArray: docsArray
        });
    }
    return docReuse;
};

const _mapperDocTraceability = (idTraceability, idDocument) => {
    return {
        documentId: idDocument,
        traceabilityIds: [idTraceability]
    };
};

export const newReuseCertificate = async ({ files, values, traceabilityId, promoId }) => {
    const reuseDoc = await _mapperPostReuseCertificate({ files, values, promoId });
    const reuseDocRequest = await createDocument(reuseDoc);

    if (reuseDocRequest?.data?.code == 0 || reuseDocRequest?.status == 204) {
        const objAssociate = _mapperDocTraceability(traceabilityId, reuseDocRequest?.data?.content?.ids[0]);
        await associateTraceabilities(objAssociate);
    }

    return reuseDocRequest;
};
