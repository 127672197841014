import { IconUpload } from '@tabler/icons';
import { useEffect, useState } from 'react';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import DragNDrop from 'components/BasicComponents/DragAndDrop/DragNDrop';
import CircularProgress from 'components/BasicComponents/Progress/CircularProgress/CircularProgress';
import { usePreventNavigation } from 'hooks/usePreventNavigation';
import { useDiExtraction } from 'modules/di-extraction';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import { WasteFlow } from 'modules/waste-flow';
import { retry } from 'utils/helpers/async.helpers';
import AutomaticReadingPreselector from '../AutomaticReadingPreselector';
import './AutomaticReadingUpload.scss';

type AutomaticReadingUploadProps = {
    onUploadStart?: () => void;
    onUploadEnd?: () => void;
};

const AutomaticReadingUpload = (props: AutomaticReadingUploadProps) => {
    const [{ promotion }] = usePromotion();
    const [, { createDiExtractions, reloadDiExtractions }] = useDiExtraction();
    const [error, setError] = useState<Error>();
    const [errorReport, setErrorReport] = useState({ uploaded: 0, total: 0 });

    const [files, setFiles] = useState<File[]>([]);
    const [filesToUpload, setFilesToUpload] = useState(0);
    const [wasteFlow, setWasteFlow] = useState<WasteFlow>();

    const uploadProgress = Math.floor(((files.length - filesToUpload) * 100) / files.length);
    const promotionId = promotion?.id;

    // Prevents navigation when there are files to upload
    usePreventNavigation(filesToUpload > 0);

    // Syncs the uploading files count with the files state
    useEffect(() => {
        setFilesToUpload(files.length);
    }, [files]);

    // Upload files until there are no more files to upload
    useEffect(() => {
        const uploadEnded = files.length > 0 && filesToUpload <= 0;
        if (uploadEnded) {
            // Clean files after upload
            setFilesToUpload(0);
            setFiles([]);
            setWasteFlow(undefined);
        }

        uploadFilesUntilEmpty();
    }, [filesToUpload]);

    const calcFilesPerUpload = () => {
        const maxUploadSize = 200; // Max upload size allowed by API;
        const defaultLimit = 50; // Default limit of files per upload;

        const maxSizePerFile = maxUploadSize / defaultLimit;
        const exampleFileMb = files[0]?.size / (1024 * 1024);

        if (exampleFileMb > maxUploadSize) throw new Error('Max');

        const filesPerUpload =
            exampleFileMb > maxSizePerFile ? Math.floor(maxUploadSize / exampleFileMb) : defaultLimit;

        return filesPerUpload;
    };

    const uploadFilesUntilEmpty = async () => {
        if (!promotionId) return;
        const limit = calcFilesPerUpload();
        const offset = files.length - filesToUpload;

        const newDiExtractions = files.slice(offset, offset + limit);
        if (newDiExtractions.length <= 0) return;

        const newFilesToUpload = filesToUpload - newDiExtractions.length;

        if (filesToUpload === files.length) props.onUploadStart?.();

        const extra = {
            wasteFlowId: wasteFlow?.id
        };

        setError(undefined);
        await retry(createDiExtractions)(promotionId, newDiExtractions, extra).catch((error) => {
            setError(error as Error);
            setFilesToUpload(0);
            setErrorReport({ uploaded: offset, total: files.length });
        });

        setFilesToUpload(newFilesToUpload);
        reloadDiExtractions({ promotionId }, { silent: true });

        if (newFilesToUpload <= 0) props.onUploadEnd?.();
    };

    return (
        <div className="AutomaticReadingUpload">
            <ErrorCard
                className="AutomaticReadingUpload__error"
                error={error}
                tx={{ ns: 'automaticReading', ...errorReport }}
            />

            <AutomaticReadingPreselector onWasteFlowSelected={setWasteFlow} />

            <DragNDrop
                label={' '}
                className="AutomaticReadingUpload__dragAndDrop"
                files={files}
                setFiles={setFiles}
                fileProp="bytes"
                fileNameProp="fileName"
                /** Because AWS Textract has a 5GB limit */
                maxSizeMb={4}
                showFilelist={false}
                format="file"
                accept={['application/pdf', 'text/xml']}
                {...({} as any)}
            >
                {files.length === 0 && (
                    <>
                        <IconUpload />
                        <p className="AutomaticReadingUpload__title">Elige DIs o arrástralos aquí</p>
                        <p className="AutomaticReadingUpload__subtitle">Los DIs deben tener formato PDF o XML (E3L)</p>
                    </>
                )}
                {files.length > 0 && (
                    <>
                        <div className="AutomaticReadingUpload__progress">
                            <CircularProgress progress={uploadProgress} />
                        </div>
                        <p className="AutomaticReadingUpload__title">Subiendo {files.length} documentos</p>
                        <p className="AutomaticReadingUpload__subtitle">
                            No cierres esta ventana hasta que todos los documentos se hayan subido.
                        </p>
                    </>
                )}
            </DragNDrop>
        </div>
    );
};

export default AutomaticReadingUpload;
