export type PromotionStatus = 'inProgress' | 'finished';

export interface PromotionPreview {
    id: number;
    name: string;
    favorite: boolean; // store this in the future at bbdd
    tags: Array<{
        id: string;
        name: string;
        color: string;
    }>; // typify this
    status: PromotionStatus;
    additionalData: {
        latestUpdate: string;
        province: string;
        mainEnterpriseName: string;
        endDate: string;
        type: string;
        surface: number;
        num: number;
        progressPercentage: number;
    };
}

export const fakePromotionPreview: PromotionPreview = {
    id: 0,
    name: ' ',
    favorite: false,
    tags: [],
    status: 'inProgress',
    additionalData: {
        latestUpdate: ' ',
        province: ' ',
        mainEnterpriseName: ' ',
        endDate: ' ',
        type: ' ',
        surface: 0,
        num: 0,
        progressPercentage: 0
    }
};
