import { AsyncPaginate } from 'react-select-async-paginate';
import activitiesServices from '../../../api/activities/activities.services';
import { withAuth } from '../../../context/AuthProvider';
import { iconSelect } from './helpers';
import './DocumentType.scss';

const ActivityTypeSelectAutocomplete = ({ values, setValues, className }) => {
    const { tipo } = values;

    const getAllTypesOfActivities = async () => {
        const allTypesOfActivities = await activitiesServices.getTypesActivities('');
        return allTypesOfActivities;
    };

    const loadOptionsActivityType = async (search) => {
        let options = await getAllTypesOfActivities();
        if (search) {
            options = options.filter((option) => option.descripcionCorta.toLowerCase().includes(search.toLowerCase()));
        }
        return {
            options: options
        };
    };

    const selectOptionTipoDocumento = (item) => {
        setValues(item.id);
    };

    return (
        <div className={className ? className : 'input__autocomplete'}>
            <AsyncPaginate
                loadOptions={loadOptionsActivityType}
                onChange={selectOptionTipoDocumento}
                getOptionLabel={(option) => option.descripcionCorta}
                getOptionValue={(option) => option}
                value={tipo}
                styles={iconSelect()}
                placeholder=""
                additional={{
                    page: 0
                }}
                isClearable={false}
                className="documentType"
                isSearchable={false}
            />
        </div>
    );
};
export default withAuth(ActivityTypeSelectAutocomplete);
