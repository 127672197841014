// eslint-disable-next-line import/named
import { BarSvgProps, ResponsiveBar } from '@nivo/bar';
import { ReactNode } from 'react';

import { getBarColor } from 'utils/helpers/colors.helpers';
import './KPIBarChart.scss';

interface KPIBarChartProps extends Omit<BarSvgProps, 'data'> {
    unit?: string;
    data?: Array<{ [key: string]: any }>;
    indexBy?: string;
    legend?: Array<{
        icon?: ReactNode;
        label: string;
    }>;
}

const KPIBarChart = (props: KPIBarChartProps) => {
    const {
        data = [
            { value: 0, name: ' ' },
            { value: 100, name: ' ' }
        ],
        indexBy = 'name',
        ...overrides
    } = props;

    const modifiers = [
        // makes the chart clickable
        props.onClick ? 'KPIBarChart--clickable' : ''
    ];

    return (
        <>
            <div className={`KPIBarChart ${modifiers.join(' ')}`}>
                <div className="KPIBarChart__scroll">
                    <div className="KPIBarChart__chart" style={{ minWidth: `${data.length * 6}rem` }}>
                        <ResponsiveBar
                            data={data}
                            indexBy={indexBy}
                            keys={Object.keys(data[0] || {}).filter((key) => key !== indexBy)}
                            margin={{ top: 32, right: 16, bottom: 40, left: 60 }}
                            valueScale={{ type: 'symlog', constant: 1000 }}
                            indexScale={{ type: 'band', round: true }}
                            colors={(params) => {
                                const color = getBarColor(params.data.name);
                                return color || '#273749';
                            }}
                            enableLabel={false}
                            axisTop={null}
                            axisRight={null}
                            axisLeft={{
                                tickValues: 5,
                                tickSize: 0,
                                tickPadding: 12,
                                format: (value) => `${value}${props.unit || ''}`
                            }}
                            defs={[
                                {
                                    id: 'lines',
                                    background: 'inherit',
                                    type: 'patternLines',
                                    color: 'rgba(0, 0, 0, 0.1)',
                                    rotation: -45,
                                    lineWidth: 5,
                                    spacing: 7
                                },
                                {
                                    id: 'lines-filled',
                                    type: 'patternLines',
                                    background: 'transparent',
                                    color: 'rgba(0, 0, 0, 0.1)',
                                    rotation: -45,
                                    lineWidth: 5,
                                    spacing: 7
                                }
                            ]}
                            padding={0.15}
                            theme={{
                                grid: {
                                    line: {
                                        stroke: 'rgba(209, 216, 225, 0.25)',
                                        strokeWidth: 2,
                                        strokeDasharray: '6 6'
                                    }
                                },
                                axis: {
                                    ticks: {
                                        line: {
                                            stroke: 'rgba(209, 216, 225, 0.50)',
                                            strokeWidth: 2
                                        },
                                        text: {
                                            fill: '#273749',
                                            fontSize: 10,
                                            fontFamily: 'Be Vietnam Pro'
                                        }
                                    }
                                }
                            }}
                            {...overrides}
                        />
                    </div>
                </div>
                <div className="KPIBarChart__legend">
                    {props.legend?.map((item, index) => (
                        <div key={index} className="KPIBarChart__legend-item">
                            {item.icon}
                            <span className="KPIBarChart__legend-text">{item.label}</span>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default KPIBarChart;
