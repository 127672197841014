import { useTranslation } from 'react-i18next';

import KPIThermometer from 'features/KPIs/components/KPIThermometer';
import KPITile from 'features/KPIs/components/KPITile';
import { Widget } from 'features/KPIs/types/Widget';
import { KPIStatus, PromotionKPIs, useTreatmentContractsSummary } from 'modules/promotion-kpis';
import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import KPIAlarm from 'features/KPIs/components/KPIAlarm';

const key = PromotionKPIs.treatmentContracts;

const useTreatmentContractsWidget: Widget = (props) => {
    const [t] = useTranslation('kpis');
    const { data, error } = useTreatmentContractsSummary(props);

    const value = data?.flowsWithContract;
    const total = data?.totalFlows;
    const left = (total || 0) - (value || 0);
    const status = (!total && KPIStatus.INDETERMINATE) || KPIStatus[data?.status || 'INDETERMINATE'];

    return {
        status: status,
        Tile: () => {
            return (
                <KPITile
                    label={t(`${key}.label`)}
                    unit={t(`${key}.unit`)}
                    value={total === 0 ? null : value}
                    total={total || null}
                    hasError={!!error}
                    status={status}
                >
                    <KPIThermometer
                        type="discrete"
                        value={value}
                        maxValue={total || 0}
                        status={Array.from({ length: total || 0 }, (_, i) =>
                            i >= (value || 0) ? status : KPIStatus.OK
                        )}
                    />
                </KPITile>
            );
        },
        Detail: () => {
            return (
                <>
                    <ErrorCard error={error} />
                    {!error && (
                        <KPIAlarm
                            status={status}
                            title={t(`${key}.alarm.${status}.title`, { count: left, defaultValue: null })}
                            text={t(`${key}.alarm.${status}.subtitle`, { count: left, defaultValue: null })}
                            link={{
                                text: t(`${key}.alarm.${status}.link`),
                                to: t(`${key}.alarm.${status}.to`, {
                                    promotionId: props.promotionId,
                                    mandatoryFlows: PromotionKPIs.mandatoryFlows
                                })
                            }}
                        />
                    )}
                </>
            );
        }
    };
};

export default useTreatmentContractsWidget;
