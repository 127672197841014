import WasteManagerDashboard from '../pages/WasteManagerDashboard/WasteManagerDashboard';

export const WASTE_MANAGER_ROUTES = [
    {
        component: WasteManagerDashboard,
        path: '/waste-manager/dashboard',
        requiredPermission: 'canView',
        section: 'wasteConfiguration'
    }
];
