import { useEffect, useState } from 'react';
import uuid from 'react-uuid';

import { usePromotion } from 'modules/promotion';
import { PromotionKPIsGoal, useGoalSave } from 'modules/promotion-kpis';
import { debounce } from 'utils/helpers/async.helpers';
import { KPIGoalProps } from '../KPIGoal';

/** Handles goal form */
export function useGoalForm(props: KPIGoalProps) {
    const { mutate, loading } = useGoalSave();
    const [{ promotion }] = usePromotion();
    const [goal, setGoal] = useState(props.goal || createEmptyGoal());

    const mode: 'edit' | 'create' = !!props.goal ? 'edit' : 'create';

    const update = (data: Partial<PromotionKPIsGoal>) => {
        const updatedGoal = { ...goal, ...data } as PromotionKPIsGoal;
        setGoal(updatedGoal);

        // Deferred save when goal updates in edit mode
        const haveChanges = JSON.stringify(goal) !== JSON.stringify(updatedGoal);
        if (mode === 'edit' && haveChanges) debounce(save)(updatedGoal);
    };

    const save = async (goal: PromotionKPIsGoal) => {
        const { loading } = await mutate({ goal, kpi: props.kpi, promotionId: promotion?.id || NaN });

        if (loading === 'succeeded' && mode === 'create') setGoal(createEmptyGoal());
        props.onSave?.(loading);
    };

    // Updates goal form state when goal prop changes
    useEffect(() => {
        if (props.goal) setGoal(props.goal);
    }, [props.goal]);

    return { goal, mode, saving: loading, update, save };
}

function createEmptyGoal() {
    return {
        uuid: uuid(),
        scope: null,
        progress: null,
        severity: null,
        value: null
    };
}
