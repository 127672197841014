import { useTranslation } from 'react-i18next';
import { Link, Redirect, Switch, useLocation } from 'react-router-dom';

import Tabs from 'components/BasicComponents/Tabs';
import PrivateRoute from 'components/routes/PrivateRoute';
import FAQ from 'features/faq/components/FAQ';
import Layout from 'pages/Layout/Layout';
import Guides from '../../components/Guides/Guides';
import './HelpCenterPage.scss';

const HelpCenterPage = () => {
    const { pathname } = useLocation();
    const [t] = useTranslation();
    /**
     * As the route has route parameters, it is necessary to distinguish between the root
     * route against which it is typed (root) versus the current base route for the link (current).
     */
    const root = `/help`;
    const current = root;

    const routes = [
        {
            page: 'onboarding:guide:short',
            path: `/guides`,
            component: Guides,
            exact: false
        },
        {
            page: 'faq:faq',
            path: `/faq`,
            component: FAQ,
            exact: false
        }
    ];

    return (
        <Layout>
            <div className="HelpCenterPage">
                <Tabs
                    className="HelpCenterPage__tabs"
                    activeTab={routes.findIndex((route) => pathname.includes(route.path))}
                    tabs={routes.map((route) => (
                        <Link to={current + route.path}>{t(route.page)}</Link>
                    ))}
                />

                <Switch>
                    {routes
                        .filter((route) => route.component)
                        .map(({ path, ...route }) => (
                            <PrivateRoute path={root + path} {...route} />
                        ))}
                    <Redirect to={current + routes[0].path} />
                </Switch>
            </div>
        </Layout>
    );
};

export default HelpCenterPage;
