import { useTranslation } from 'react-i18next';

import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { usePromotion } from 'modules/promotion';
import './PromotionData.scss';
import PromotionDataForm from './components/PromotionDataForm';

const PromotionData = () => {
    const [t] = useTranslation('promotion');
    const [{ promotion }] = usePromotion();

    return (
        <SimpleCard
            className="PromotionData"
            title={t(`settings.sections.project`)}
            subtitle={t(`projectSettings.description`)}
        >
            <PromotionDataForm promotion={promotion} />
        </SimpleCard>
    );
};

export default PromotionData;
