import { COLORS_GRAPHICS } from 'utils/constants/general.constants';
import { removeAccentedCharacters } from './general.helpers';

export const getBarColor = (typeLer) => {
    const assignedColor = COLORS_GRAPHICS[typeLer];
    return assignedColor || generatedHSL(typeLer);
};

export const getTreatmentBarColor = (valorizationCode) => {
    if (valorizationCode === 'R1') return '#f5e69a'; // energy
    if (valorizationCode === 'VMNE') return '#b8885a'; // lands
    if (valorizationCode.includes('R13') || valorizationCode.includes('R14')) return '#f7cf97'; // stored
    if (valorizationCode.includes('RU')) return '#94dcf2'; // reuse

    // if Rx or Dx
    const valorizationNumber = parseInt(valorizationCode.slice(1));

    if (valorizationCode.includes('R')) {
        const color = getIncrementalColor({
            variableNumber: -valorizationNumber, // negative number!! move to the left
            initualHue: 158, // green
            hueOffset: 4,
            saturationValue: 45 + valorizationNumber * 1.25,
            luminosityValue: 55 + valorizationNumber * 2
        });
        return color;
    }

    if (valorizationCode.includes('D')) {
        const color = getIncrementalColor({
            variableNumber: valorizationNumber,
            initualHue: 340, // red
            hueOffset: 2, // less offset, to avoid getting orange-brownish color
            saturationValue: 70 - valorizationNumber * 1.25,
            luminosityValue: 80 - valorizationNumber * 2
        });
        return color;
    }

    return '#a8a8a8'; // avoid pure black
};

const getIncrementalColor = ({ variableNumber, initualHue, hueOffset, saturationValue, luminosityValue }) => {
    const hue = initualHue + variableNumber * hueOffset;
    const color = `hsl(${hue}deg ${saturationValue}% ${luminosityValue}%)`;
    return color;
};

export function generatedHSL(typeLer) {
    // Cleans typeLer from similar start letters and spaces
    let seed = typeLer.replaceAll(' ', '');
    seed = removeAccentedCharacters(seed);
    // Generates a number based on seed between 0 to 360
    const hue = parseInt(seed, 36) % 360;
    const color = `hsl(${hue}deg 75% 85%)`;
    return color;
}

// param example: 170107 (Fracciones minerales)
export const getLerCodeColor = (lerData) => {
    // do not remove the optional chaining (?.) as it can occour delays where the lerData param is only a ler type
    const lerType = lerData.split('(')[1]?.replace(')', '');

    const baseColor = COLORS_GRAPHICS[lerType];
    return baseColor;
};
