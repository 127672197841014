import { IconInfoCircle } from '@tabler/icons';
import ReactTooltip from 'react-tooltip';
import './Tooltips.scss';

const IconTooltip = ({ msg, icon = undefined, className = '', size = 24 }) => {
    if (icon === undefined) {
        icon = <IconInfoCircle stroke={2} size={size} />;
    }
    return (
        <span className={`icon__tooltip ${className}`} data-for={msg} data-tip={msg} data-offset="{'top': -10}">
            {icon}
            <ReactTooltip id={msg} delayHide={100} effect="solid" multiline={true} />
        </span>
    );
};

export default IconTooltip;
