import { WasteManagersApi } from '../../domain/WasteManagersApi';
import { createRecomendedTransferOperatorsCriteria } from '../../domain/TransferOperators/createRecomendedTransferOperatorsCriteria';

type Filters = { lers: string };

export const loadRecomendedTransferOperators =
    (filters: Filters) =>
    async (store: null, { api }: { api: WasteManagersApi }) => {
        const criteria = createRecomendedTransferOperatorsCriteria(filters);
        const transferOperators = await api.getWasteManagers(criteria);

        return transferOperators;
    };
