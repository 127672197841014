import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconTrash } from '@tabler/icons';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';
import HasPermission from 'components/AccessCheckers/HasPermission';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import IconButton from 'components/BasicComponents/Buttons/Small/IconButton';
import { useAuth } from 'modules/auth';

interface ConstructionSiteActionsProps {
    activityStatus: string;
    activityId: number;
    openModal: boolean;
    setOpenModal: any;
    setOpenDateModal: any;
}

const ConstructionSiteActions = ({
    activityStatus,
    activityId,
    openModal,
    setOpenModal,
    setOpenDateModal
}: ConstructionSiteActionsProps) => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();
    const [{ isCoCircular }] = useAuth();

    return (
        <>
            {/* ugly 'if statement' but it works */}
            {activityStatus === 'CERRADO' ? (
                <Link
                    to={{
                        pathname: `/promotion/${promotion?.id}/activities/${activityId}`
                    }}
                >
                    <SmallButton color="blue" titulo={t('viewDetail', { ns: 'common' })} />
                </Link>
            ) : (
                <HasPermission ifRole="canComplete" section="siteChecking">
                    <Link
                        to={{
                            pathname: `/promotion/${promotion?.id}/activities/${activityId}`
                        }}
                    >
                        <SmallButton color="blue" titulo={t('viewDetail', { ns: 'common' })} />
                    </Link>
                </HasPermission>
            )}
            <HasPermission ifRole="canComplete" section="siteChecking">
                {(activityStatus === 'PLANIFICADA' || activityStatus === 'BORRADOR') && (
                    <SmallButton titulo={t('replan.action', { ns: 'visit' })} action={() => setOpenDateModal(true)} />
                )}
                <Link
                    to={{
                        pathname: `/promotion/${promotion?.id}/activities/${activityId}/edit-visit`
                    }}
                >
                    <SmallButton titulo={t('edit', { ns: 'common' })} />
                </Link>
                {/* just in case... only cocircular can delete construction site activities */}
                {isCoCircular && (
                    <IconButton icon={<IconTrash />} action={() => setOpenModal(!openModal)} {...({} as any)} />
                )}
            </HasPermission>
        </>
    );
};

export default ConstructionSiteActions;
