import AppRouter from './routers/AppRouter';
import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.scss';

const App = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    return <AppRouter />;
};

export default App;
