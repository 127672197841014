import { Reducers, createStore } from 'modules/shared/domain/store/createStore';

import { ImpactStore } from '../domain/ImpactStore';

import { fetchImpact, fetchImpactReducers } from './fetch/fetchImpact';

const reducers: Reducers<ImpactStore> = {
    ...fetchImpactReducers
};

export const impactStore = createStore({
    initialState: {
        impact: {
            impactCo2: {
                // Generated
                co2GeneratedbyLerGraphData: [],
                co2GeneratedTemporalGraphData: [],
                co2generated: {
                    potential: '0',
                    real: '0',
                    standard: '0'
                },

                // Avoided?
                co2ByLerGraphData: [],
                co2TemporalGraphData: [],
                co2KPI: {
                    potential: '0',
                    real: '0',
                    standard: '0',
                    potentialUnseparatedWastes: '0'
                }
            },
            equivalencies: {
                trees: 0,
                meters: 0,
                trips: {
                    numTripsMAD: 0
                }
            },
            impactLandUse: {
                landUseByLerGraphData: [],
                landUseTemporalGraphData: [],
                landUseKPI: {
                    potential: '0',
                    real: '0',
                    standard: '0',
                    potentialUnseparatedWastes: '0'
                }
            }
        },
        loading: 'idle',
        error: null,
        criteria: null
    },
    reducers,
    thunks: {
        fetchImpact
    }
});
