import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';
import { RelatedEnterprisesStore } from '../../domain/RelatedEnterprisesStore';

export const changeRelatedEnterprise =
    (relatedEnterpriseId: string): Thunk<RelatedEnterprisesStore> =>
    async (store) => {
        store.dispatch('changeRelatedEnterpriseStaged', { relatedEnterpriseId });
    };

export const changeRelatedEnterpriseReducers: Pick<
    Reducers<RelatedEnterprisesStore>,
    'changeRelatedEnterpriseStaged'
> = {
    changeRelatedEnterpriseStaged: (state, payload) => {
        return produce(state, (draft) => {
            const currentMainEnterprise = draft.relatedEnterprises.find(
                (relatedEnterprise) => relatedEnterprise.mainEnterprise
            );

            const newMainEnterprise = draft.relatedEnterprises.find(
                (relatedEnterprise) => relatedEnterprise.id === payload.relatedEnterpriseId
            );

            if (currentMainEnterprise) currentMainEnterprise.mainEnterprise = false;
            if (newMainEnterprise) newMainEnterprise.mainEnterprise = true;
        });
    }
};
