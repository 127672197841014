import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withAuth } from '../../context/AuthProvider';
import Layout from '../Layout/Layout';
import SimpleCard from '../../components/BasicComponents/Cards/SimpleCard';
import { TituloH1 } from '../../components/BasicComponents/Titulos/Titulos';
import SmallText from '../../components/BasicComponents/Text/SmallText';
import SmallButton from '../../components/BasicComponents/Buttons/Small/SmallButton';
import './MyProfile.scss';

const MyProfile = (props) => {
    const [t] = useTranslation();
    const { user } = props;

    return (
        <Layout>
            <TituloH1 titulo={`${t('my.upperCase', { ns: 'common' })} ${t('profile.lowerCase', { ns: 'common' })}`} />
            <SimpleCard className="profile">
                <div className="profile__personalInfo">
                    <div className="profile__iconUser">
                        <p>
                            {' '}
                            {user?.firstName?.charAt(0)?.toUpperCase()}
                            {user?.lastName?.charAt(0)?.toUpperCase()}
                        </p>
                    </div>
                    <div className="profile__info">
                        <p className="profile__name">
                            {user?.firstName} {user?.lastName}
                        </p>
                        <SmallText text={user?.roleType?.descripcion} />
                    </div>
                </div>
                <Link to="/profile/edit" className="profile__link">
                    <SmallButton
                        titulo={`${t('edit', { ns: 'common' })} ${t('profile.lowerCase', { ns: 'common' })}`}
                    />
                </Link>
            </SimpleCard>
        </Layout>
    );
};
export default withAuth(MyProfile);
