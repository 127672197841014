import { useReducer } from 'react';

import createReducer from 'lib/createReducer';

import { reducers } from './reducers';
import { initialState } from './state';
import { loadPosters } from './thunks/loadPosters';

export * from './state';

export function useWastePostersStore() {
    const [state, dispatch] = useReducer(createReducer(reducers), initialState);

    const store = {
        loadPosters: loadPosters({ dispatch })
    };

    return [state, store];
}
