import { Trans, useTranslation } from 'react-i18next';

import SegmentedControl from 'components/BasicComponents/SegmentedControl/SegmentedControl';
import OnboardingResourceItem from 'features/onboarding/components/OnboardingResourceItem';
import useStateFromStorage from 'hooks/useStateFromStorage';
import { useAuth } from 'modules/auth';
import { ONBOARDING_PATHS, ROLETYPE_TO_PATH, useResourcesState, useUpdateResourceState } from 'modules/onboarding';
import './Onboarding.scss';
import { OnboardingResourceStates } from 'modules/onboarding/domain/paths/resources/OnboardingResourceStates';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';

const paths = Object.keys(ONBOARDING_PATHS);
const key = 'Onboarding--path';

const Onboarding = () => {
    const [t] = useTranslation('onboarding');
    const [, analytics] = useAnalyticsStore();
    const { mutate: updateResourceState } = useUpdateResourceState();
    const resources = useResourcesState();

    const [{ user }] = useAuth();
    const role = ROLETYPE_TO_PATH[user?.roleType?.id?.toString() || ''];
    const [path, setPath] = useStateFromStorage({ key, initialState: role?.path || 'construction' });

    const { topics } = ONBOARDING_PATHS[path];

    return (
        <div className="Onboarding">
            <header className="Onboarding__header">
                <SegmentedControl
                    onChange={(id) => setPath(id)}
                    value={path}
                    options={paths.map((path) => ({ id: path, label: t(`paths.${path}`) }))}
                />
            </header>

            <section className="Onboarding__topics">
                {topics.map((topic, index) => (
                    <div className="Onboarding__topic">
                        <header className="Onboarding__topicHeader">
                            <p className="Onboarding__topicText">
                                <Trans
                                    i18nKey={`onboarding:topic.${path}.${topic.id}.text`}
                                    components={{ b: <strong /> }}
                                />
                            </p>
                        </header>

                        <div className="Onboarding__topicResources">
                            {topic.resources
                                .filter((r) => !!r.video)
                                .map((resource) => (
                                    <OnboardingResourceItem
                                        key={resource.id}
                                        data={resource}
                                        state={resources.data?.[resource.id]}
                                        variant={topic.id === 'generic' ? 'big' : undefined}
                                        className={'Onboarding__topicResource'}
                                        onClick={async () => {
                                            // sends an event to the analytics store
                                            const event = `${AnalyticsEvents.ONBOARDING_VIDEO_CLICKED}_${resource.id}`;
                                            analytics.event(event as AnalyticsEvents);

                                            // updates the resource state to SEEN
                                            const { SEEN } = OnboardingResourceStates;
                                            await updateResourceState({ id: resource.id, state: SEEN });

                                            // refetches the resources
                                            resources.refetch();
                                        }}
                                    />
                                ))}
                        </div>
                    </div>
                ))}
            </section>
        </div>
    );
};

export default Onboarding;
