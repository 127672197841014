import { useTranslation } from 'react-i18next';

export function useSignageDocInfo() {
    const [t] = useTranslation();
    const title = t('signage', { ns: 'documentation' });

    const signageWasteDocument = {
        id: 0,
        name: t('wasteSignage', { ns: 'documentation' }),
        date: new Date()
    };

    const documents = [signageWasteDocument];

    return {
        name: title,
        size: documents.length,
        documents
    };
}
