import Modal from 'react-modal';
import { IconCircleX } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import { TituloH2 } from '../Titulos/Titulos';
import './Popovers.scss';

const SimplePopover = ({
    openModal,
    setOpenModal,
    title = '',
    children,
    noClose = false,
    allowScroll = true,
    className = '',
    addFunctionOnClose
}) => {
    const closeModal = () => {
        setOpenModal(false);
        addFunctionOnClose && addFunctionOnClose();
    };

    const customStyles = {
        overlay: {
            zIndex: 100,
            backgroundColor: 'rgba(136, 136, 136, 0.75)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        content: {
            overflow: 'unset',
            overflowY: allowScroll ? 'auto' : 'unset'
        }
    };

    return (
        <Modal
            isOpen={openModal}
            onRequestClose={noClose ? null : closeModal}
            style={customStyles}
            ariaHideApp={false}
            className={'simplePopover ' + className}
        >
            <div className="popover__title">
                <TituloH2 titulo={title} />
                {!noClose && (
                    <IconCircleX
                        data-testid="Popover__close"
                        role="button"
                        size={28}
                        stroke={1.1}
                        onClick={() => closeModal()}
                    />
                )}
            </div>
            {children}
        </Modal>
    );
};
export default withAuth(SimplePopover);
