import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';

import { DEFAULT_OPTIONS_AUTOCOMPLETE } from 'components/BasicComponents/Select-Autocomplete/autocomplete.helpers';
import { SelectAsyncOptions, SelectOption } from '../Filter/SelectFilter/SelectFilter';
import { selectFilterAsyncPaginateStyles } from '../Filter/SelectFilter/selectFilterAsyncPaginateStyles';
import './Select.scss';

export interface SelectAutocompleteProps {
    label?: string;
    name?: string;
    value?: SelectOption | SelectOption[];
    onChange?: (option?: SelectAutocompleteProps['value']) => any;
    onBlur?: () => any;
    onInputChange?: (value: string) => any;
    onMenuOpen?: () => any;
    loadOptions?: SelectAsyncOptions;
    className?: string;
    defaultOptions?: boolean;
    isClearable?: boolean;
    dataTestId?: string;
    placeholder?: string;
    loadOptionsOnMenuOpen?: boolean;
    alwaysOpen?: boolean;
    noOptionsMessage?: string;
    extraElement?: any;
    disabled?: boolean;
    menuPosition?: 'fixed';
    style?: React.CSSProperties;
    id?: string;
    errorMsg?: string;
}

const SelectAutocomplete = forwardRef((props: SelectAutocompleteProps, ref) => {
    const { isClearable = true, errorMsg = '' } = props;
    const [t] = useTranslation();
    const [value, setValue] = useState(props.value);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const selectRef = useRef<HTMLSelectElement>(null);

    const setValueAndEmitChange = (newValue: typeof value) => {
        setValue(newValue);
        props.onChange?.(newValue);
    };

    const propsValue = Array.isArray(props.value) ? props.value.map((o) => o.value).join() : props.value?.value;

    // Syncs internal value when props.value changes
    useEffect(() => {
        setValue(props.value);
    }, [propsValue]);

    useEffect(() => {
        const select = selectRef.current;
        if (!select) return;

        if (errorMsg) {
            select.setCustomValidity(errorMsg);
        } else select.setCustomValidity('');
    }, [errorMsg]);

    const modifiers = [errorMsg ? 'input__input--notComplete' : '', props.disabled ? 'Select--disabled' : ''];

    return (
        <div className={`Select Select__wasteFlow ${props.className || ''} ${modifiers.join('')}`} style={props.style}>
            <label className="input__label" id={props.name}>
                {props.label}
            </label>

            <select
                tabIndex={-1}
                className="Select__nativeEl"
                ref={selectRef}
                value={Array.isArray(props.value) ? undefined : props.value?.value}
            />

            <div className="SelectFilter__select" data-testid={props.dataTestId || 'SelectAutocomplete'}>
                <AsyncPaginate
                    id={props.id}
                    selectRef={ref}
                    {...DEFAULT_OPTIONS_AUTOCOMPLETE}
                    noOptionsMessage={
                        props.noOptionsMessage
                            ? () => props.noOptionsMessage
                            : DEFAULT_OPTIONS_AUTOCOMPLETE.noOptionsMessage
                    }
                    aria-labelledby={props.name}
                    placeholder={props.placeholder || t('select.selectAnOption')}
                    defaultOptions={props.defaultOptions}
                    value={value === undefined ? null : value}
                    onChange={setValueAndEmitChange}
                    onBlur={props.onBlur}
                    styles={selectFilterAsyncPaginateStyles()}
                    menuPosition={props.menuPosition}
                    menuIsOpen={props.alwaysOpen || menuIsOpen}
                    onMenuClose={() => setMenuIsOpen(false)}
                    onMenuOpen={() => {
                        props.onMenuOpen?.();
                        setMenuIsOpen(true);
                    }}
                    className={modifiers.join(' ')}
                    loadOptionsOnMenuOpen={props.loadOptionsOnMenuOpen}
                    loadOptions={props.loadOptions}
                    isClearable={isClearable}
                    onInputChange={props.onInputChange}
                    debounceTimeout={400}
                    isDisabled={props.disabled}
                    isMulti={Array.isArray(value)}
                />
                {props.extraElement && <div className="Select__extraElement">{props.extraElement}</div>}
            </div>
        </div>
    );
});

export default SelectAutocomplete;
