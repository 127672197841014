import { DomainException } from 'modules/shared';

export class InvalidApproveDataException extends DomainException {}

export class CantUploadFilesException extends DomainException {
    constructor() {
        super({
            code: 'upload-error.error',
            description: 'upload-error.description',
            content: null
        });
    }
}
