import { useTranslation } from 'react-i18next';

import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import { useEnterprisesStore } from 'modules/enterprise/enterprises/infrastructure/react/enterprisesContext';
import './EnterpriseSelectAutocomplete.scss';
import { Enterprise } from 'modules/enterprise/enterprises/domain/Enterprise';
import { SelectOption } from 'components/BasicComponents/Filter/SelectFilter/SelectFilter';

interface EnterpriseSelectAutocompleteProps {
    className?: string;
    value?: Enterprise;
    onChange?: (option: Enterprise) => void;
}

const EnterpriseSelectAutocomplete = (props: EnterpriseSelectAutocompleteProps) => {
    const [, , { api }] = useEnterprisesStore();
    const [t] = useTranslation('tags');

    const [loadOptions] = useAsyncOptions({
        request: ({ search, page }) => api.getEnterprises({ search, page }),
        adapter: (enterprise) => ({
            value: enterprise.id,
            label: enterprise.name,
            data: enterprise
        })
    });

    return (
        <div className="EnterpriseSelectAutocomplete">
            <SelectAutocomplete
                className={props.className}
                value={props.value ? { value: props.value.id, label: props.value.name } : undefined}
                onChange={(option) => {
                    if (!option) return;
                    props.onChange?.({
                        id: (option as SelectOption).value,
                        name: (option as SelectOption).label as string,
                        cif: (option as any).data?.cif
                    });
                }}
                label={t('manager.enterprise')}
                loadOptions={loadOptions}
                isClearable={false}
            />
        </div>
    );
};

export default EnterpriseSelectAutocomplete;
