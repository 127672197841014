import { IconBulb } from '@tabler/icons';
import AlertCard from './AlertCard';
import './Cards.scss';

const SuggestionCard = ({ children, title = '', withIcon = true, className = '' }) => {
    return (
        <AlertCard
            title={title}
            icon={withIcon && <IconBulb size={24} />}
            className={`card__alert-suggestion ${className}`}
        >
            {children}
        </AlertCard>
    );
};

export default SuggestionCard;
