import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import { NotificationSetting } from 'modules/notification-settings/domain/NotificationSetting';
import { useNotificationSettings } from 'modules/notification-settings/infrastructure/react/notificationSettingsContext';

export const useLoadEmails = (stakeholderId: NotificationSetting['data']['stakeholderId']) => {
    const [, , { api }] = useNotificationSettings();

    const [loadEmails] = useAsyncOptions({
        request: async () =>
            // convert email into array
            api.getStakeholderDefaultEmail(stakeholderId).then((email) => [email]),
        adapter: (email) => ({ value: email, label: email }),
        removePagination: true
    });

    return { loadEmails };
};
