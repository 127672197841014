import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useRouteMatch } from 'react-router-dom';

import ErrorCard from 'components/BasicComponents/Cards/ErrorCard';
import EmptyState from 'components/BasicComponents/EmptyState';
import TraceabilitiesPageHeader from 'features/traceabilities/components/TraceabilitiesPageHeader/TraceabilitiesPageHeader';
import { useLoadWasteRegisters } from 'features/waste-register/hooks/useLoadWasteRegisters';
import { useDateFormatter } from 'hooks/useDateFormatter';
import fromEntities from 'lib/fromEntities';
import { WasteRegister } from 'modules/waste-register/domain/WasteRegister';
import { useWasteRegisterStore } from 'modules/waste-register/infrastructure/react/wasteRegisterContext';
import WasteRegisterActions from '../WasteRegisterActions';
import './WasteRegisterList.scss';
import WasteRegisterListItem from './components/WasteRegisterListItem';

export type WasteRegisterListProps = {
    promotionIds: number[];
};

const WasteRegisterList = (props: WasteRegisterListProps) => {
    const { t } = useTranslation('wasteRegister');
    const { url } = useRouteMatch();
    const history = useHistory();
    const [{ total, criteria, data, loading, error }, { loadMoreWasteRegisters }] = useWasteRegisterStore();
    const wasteRegisters = fromEntities(data);
    const { format } = useDateFormatter();

    const skeletons = new Array(criteria.size).fill(null).map((_, i) => <WasteRegisterListItem key={i} />);
    const list = groupHeadersAdapter(wasteRegisters, 'movementDate');

    useLoadWasteRegisters({ promotionIds: props.promotionIds });

    const modifiers = [
        loading === 'pending' ? 'WasteRegisterList--pending' : '',
        loading === 'failed' && list.length === 0 ? 'WasteRegisterList--failed' : ''
    ].join(' ');

    return (
        <div className={`WasteRegisterList ${modifiers}`}>
            <ErrorCard className="WasteRegisterList__error" error={error as Error} />

            <TraceabilitiesPageHeader title={t('title')} showTraceabilityAction={false} />

            {list.length === 0 && loading === 'succeeded' && (
                <EmptyState testid="WasteRegisterList__empty" mode="emptyResults" subject="registros de residuos" />
            )}

            {list.length > 0 && <WasteRegisterActions />}

            {list.length === 0 && ['failed', 'pending'].includes(loading) && (
                <div data-testid="WasteRegisterList__list">{skeletons}</div>
            )}

            <div data-testid="WasteRegisterList__list">
                <InfiniteScroll
                    className="WasteRegisterList__list"
                    scrollableTarget="main-layout"
                    dataLength={wasteRegisters.length}
                    hasMore={total > wasteRegisters.length}
                    loader={skeletons}
                    next={() => {
                        loadMoreWasteRegisters({ page: (criteria.page || 0) + 1 });
                    }}
                >
                    {list.map((item, index) => {
                        if ('type' in item && item.type === 'header')
                            return (
                                <header key={index} className="WasteRegisterList__group-header">
                                    {format(item.group, 'DD MMM YYYY')}
                                </header>
                            );

                        const wr = item as WasteRegister;
                        return (
                            <WasteRegisterListItem
                                key={wr.id}
                                wasteRegister={wr}
                                onClickEdit={(wr) => {
                                    history.push(`${url}/${wr.id}`);
                                }}
                            />
                        );
                    })}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default WasteRegisterList;

function groupHeadersAdapter<E extends { [key: string]: any }>(data: E[], sortKey: keyof E) {
    return data.reduce((acc, item, index) => {
        const prevItem = data[index - 1];

        const prevSortKey = prevItem?.[sortKey];
        const currentSortKey = item[sortKey];

        if (!prevSortKey || prevSortKey !== currentSortKey) {
            return [...acc, { type: 'header' as const, group: currentSortKey, sortKey }, item];
        }

        return [...acc, item];
    }, [] as (E | { type: 'header'; group: any })[]);
}
