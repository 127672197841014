import { NEW_EDIT_TRACEABILITY_ROUTE } from 'features/new_edit_traceability/routes/NewEditTraceabilityRoute';
import Traceabilities from '../index';
import TraceabilitiesPage from '../pages/TraceabilitiesPage/TraceabilitiesPage';
import TraceabilityDetailPage from '../pages/TraceabilityDetailPage';
import TraceabilityEditPage from '../pages/TraceabilityEditPage';

export const traceabilitiesRouteRoot = '/promotion/:promotionId/traceabilities';

const withRoot = (routes: any[], root: string) =>
    routes.map((route) => ({
        ...route,
        path: root + route.path
    }));

export const TRACEABILITIES_VIEWS = {
    automaticReading: '/review',
    wasteRegister: '/waste-register'
};

export const INTERNAL_TRACEABILITIES_ROUTES = [
    ...withRoot(NEW_EDIT_TRACEABILITY_ROUTE, '/promotion/:promotionId'),
    {
        component: TraceabilityEditPage,
        path: traceabilitiesRouteRoot + '/traceability/new',
        requiredPermission: 'canCreate',
        section: 'traceability',
        exact: true
    },
    {
        component: TraceabilityEditPage,
        path: traceabilitiesRouteRoot + '/traceability/:traceabilityId/edit',
        requiredPermission: 'canCreate',
        section: 'traceability',
        exact: true
    },
    {
        component: TraceabilityDetailPage,
        path: traceabilitiesRouteRoot + '/traceability/:traceabilityId',
        exact: true
    },
    {
        component: TraceabilitiesPage,
        path: traceabilitiesRouteRoot
    }
];

export const TRACEABILITIES_ROUTES = [
    {
        component: Traceabilities,
        path: traceabilitiesRouteRoot,
        requiredPermission: 'canView',
        section: 'traceability',
        exact: false
    }
];
