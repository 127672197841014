import fetcher from '../../../lib/fetcher';

export const getDocuments = async (promotionId, filter) => {
    const URL = `/api/documents/promotion/${promotionId}${filter}`;
    try {
        const response = await fetcher.get(URL);
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
