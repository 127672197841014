import NewReusePage from '../components/NewReusePage';

export const REUSE_ROUTES = [
    {
        component: NewReusePage,
        path: '/traceabilities/reuse/create',
        requiredPermission: 'canCreate',
        section: 'traceability'
    },
    {
        component: NewReusePage,
        path: '/traceabilities/reuse/:id/edit',
        requiredPermission: 'canCreate',
        section: 'traceability'
    }
];
