import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { withAuth } from 'context/AuthProvider';
import { useRecomendedTransferOperators } from 'modules/transfer-operator/infrastructure/react/useRecomendedTransferOperators';
import { codeTypeTransferOperator, getKeyByValue } from '../helpers/waste_manager.helpers';
import { NewPpgclContext } from './NewPpgclStepper';
import { SearcherTransferOperator } from './SearcherTransferOperator';

const TransferOperatorOption = ({ completePpgcl, promocion }) => {
    const [t] = useTranslation();
    const newPpgclState = useContext(NewPpgclContext) as any;
    const { newPpgcls, setNewPpgcls, setTransferOperatorChecked, transferOperatorChecked } = newPpgclState;
    const [transferOperatorSelected, setTransferOperatorSelected] = useState(null as any);
    const [costData, setCostData] = useState({ unit: '', ton: '' });
    const [values, setValues] = useState({ cost: '', volumenUnitario: '' } as any);
    const [showOptionsTransferOperator, setShowOptionsTransferOperator] = useState(false);
    const [{ data: recomendedTransferOperators }] = useRecomendedTransferOperators({
        lers: completePpgcl.ppgcl.codLER.codigoLER
    });
    const promotion = promocion;

    const findIndex = () => {
        return newPpgcls.findIndex((newPpgcl) => newPpgcl.id === completePpgcl.id);
    };
    const deleteTransferOperator = () => {
        setTransferOperatorChecked(
            transferOperatorChecked.filter((transferOperatorChecked) => transferOperatorChecked.id !== completePpgcl.id)
        );
    };

    const selectTransferOperator = (type) => {
        setShowOptionsTransferOperator(false);
        const index = findIndex();

        // Prev transfer operator cleanup
        delete newPpgcls[index].transferOperatorProGesCodLerId;
        delete newPpgcls[index].transferOperator;
        deleteTransferOperator();

        const recommendedTransferOperatorSelected = recomendedTransferOperators?.find((trOp) => trOp.nIMA === type);
        if (recommendedTransferOperatorSelected) {
            const ppgcl = recommendedTransferOperatorSelected.ppgcls[0];

            newPpgcls[index] = {
                ...newPpgcls[index],
                transferOperatorProGesCodLerId: ppgcl.id,
                transferOperator: ppgcl,
                transferOperatorTypeId: codeTypeTransferOperator.ANOTHER_MANAGER
            };
        }

        const codeTypeIdSelected = codeTypeTransferOperator[type];
        if (codeTypeIdSelected) {
            newPpgcls[index] = {
                ...newPpgcls[index],
                transferOperatorTypeId: codeTypeIdSelected
            };
        }

        setTransferOperatorSelected(type);
        setNewPpgcls(newPpgcls);
    };

    useEffect(() => {
        if (!recomendedTransferOperators) return;
        const currentTransferOperator = completePpgcl?.transferOperator?.procesoGestora?.gestora;

        const isRecommended = recomendedTransferOperators?.some((r) => r.id === currentTransferOperator?.id);
        if (isRecommended) {
            setTransferOperatorSelected(currentTransferOperator.nIMA);
            return;
        }

        const isAnother = completePpgcl?.transferOperatorTypeId === codeTypeTransferOperator.ANOTHER_MANAGER;
        if (isAnother) {
            setShowOptionsTransferOperator(true);
            return;
        }

        // For rest options (promotion, waste manager...)
        setTransferOperatorSelected(getKeyByValue(codeTypeTransferOperator, completePpgcl?.transferOperatorTypeId));
    }, [recomendedTransferOperators]);

    useEffect(() => {
        if (!completePpgcl) return;

        setValues({
            ...values,
            volumenUnitario: completePpgcl?.volumenUnitario ?? '',
            cost: completePpgcl?.cost === '' ? null : completePpgcl?.cost
        });
        setCostData({
            unit: completePpgcl?.cost?.unit === '' ? null : completePpgcl?.cost?.unit,
            ton: completePpgcl?.cost?.ton === '' ? null : completePpgcl?.cost?.ton
        });
    }, [completePpgcl]);

    useEffect(() => {
        updateNewPpgcls();
    }, [values]);

    const updateNewPpgcls = () => {
        const index = findIndex();
        newPpgcls[index] = {
            ...newPpgcls[index],
            ...values
        };
        setNewPpgcls(newPpgcls);
    };

    const onChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    const onCostChange = ({ target }) => {
        const updatedCost = { ...costData, [target.name]: target.value };
        setCostData(updatedCost);
        setValues({
            ...values,
            cost: {
                unit: updatedCost?.unit,
                ton: updatedCost?.ton
            }
        });
    };

    const onClickOther = () => {
        setTransferOperatorSelected(null);
        setShowOptionsTransferOperator(true);
    };

    const noNima = !promocion.nima;
    const promotionOptionDisableReasons =
        (noNima && t('disableReasons.promotionWithoutNimaTransferOp', { ns: 'newPpgcl' })) || null;

    return (
        <li key={completePpgcl.ppgcl.id} className="transferOperator">
            <p className="transferOperator__info">
                <span>{completePpgcl.ppgcl.codLER.codigoLER} </span> - {completePpgcl.ppgcl.codLER.descripcion}
            </p>
            <p className="transferOperator__info">
                Gestora: <span>{completePpgcl.ppgcl.procesoGestora.gestora.empresa}</span>
            </p>
            <p className="transferOperator__info">
                Transportista: <span>{completePpgcl.carrier.procesoGestora.gestora.empresa}</span>
            </p>

            <div className="transferOperator__buttonsContainer">
                {recomendedTransferOperators?.map((transferOperator) => (
                    <SmallButton
                        action={() => selectTransferOperator(transferOperator.nIMA)}
                        titulo={transferOperator.empresa}
                        className={transferOperatorSelected === transferOperator.nIMA ? 'transferOperator__active' : ''}
                    />
                ))}
                <Tooltip msg={promotionOptionDisableReasons}>
                    <SmallButton
                        action={() => selectTransferOperator('PROMOTION')}
                        titulo={promocion.nombre}
                        className={transferOperatorSelected === 'PROMOTION' ? 'transferOperator__active' : ''}
                        disabled={!promocion.nima}
                    />
                </Tooltip>
                <SmallButton
                    action={() => selectTransferOperator('SAME_MANAGER')}
                    titulo={completePpgcl.ppgcl.procesoGestora.gestora.empresa}
                    className={transferOperatorSelected === 'SAME_MANAGER' ? 'transferOperator__active' : ''}
                />
                <SmallButton
                    titulo="Otro"
                    action={onClickOther}
                    className={showOptionsTransferOperator ? 'transferOperator__active' : ''}
                />
            </div>

            {showOptionsTransferOperator && <SearcherTransferOperator {...{ completePpgcl }} />}
            <div className="transferOperator__inputsContainer">
                {promotion?.permissions.costs && (
                    <>
                        <InputNumber
                            label="Coste unitario (€)"
                            onChange={onCostChange}
                            value={costData.unit}
                            name="unit"
                            required
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                        <InputNumber
                            label="Coste por tonelada (€)"
                            onChange={onCostChange}
                            value={costData.ton}
                            name="ton"
                            required
                            // Just to ignore optional fields
                            {...({} as any)}
                        />
                    </>
                )}
                <InputNumber
                    label="Volumen del contenedor por defecto (m³)"
                    {...{ onChange }}
                    value={values.volumenUnitario}
                    name="volumenUnitario"
                    required
                    validacion={values.volumenUnitario.length === 0 ? true : false}
                    // Just to ignore optional fields
                    {...({} as any)}
                />
            </div>
        </li>
    );
};

export default withAuth(TransferOperatorOption);
