import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import './ComparativeExplanation.scss';
import { useTranslation } from 'react-i18next';

interface ComparativeExplanationProps {
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
}

const ComparativeExplanation = (props: ComparativeExplanationProps) => {
    const [t] = useTranslation('comparative');
    return (
        <SimplePopover
            openModal={props.openModal}
            setOpenModal={props.setOpenModal}
            title="Cómo se calcula"
            className="ComparativeExplanation"
        >
            <section>
                <p>Para evaluar un proyecto, analizamos y puntuamos tres bloques:</p>

                <p>
                    <b>Protocolo (cómo está enfocada la gestión de residuos). Puntuación entre 0 y 10:</b>
                </p>
                <ul>
                    <li>
                        <span>Zonas.</span> Deben existir zonas y contenedores diferenciados de cada grupo de residuos:
                        valorizables, peligrosos y RSU.
                    </li>
                    <li>
                        <span>Cartelería.</span> La señalización debe estar presente y adecuada a cada grupo de
                        residuos: valorizables, peligrosos y RSU.
                    </li>
                    <li>
                        <span>Contratos de tratamiento.</span> Deben estar disponibles en la plataforma y relativos a
                        cada grupo de residuos: valorizables, peligrosos y RSU (* en ciertos casos).
                    </li>
                    <li>
                        <span>Existe PGR.</span> Debe haber un Plan de Gestión de Residuos en vigor en la plataforma.
                    </li>
                </ul>

                <p>
                    <b>Ejecución (cómo se lleva a cabo en la práctica). Puntuación entre 0 y 10:</b>
                </p>
                <li>
                    Para cada grupo de residuos se evalúa la <span>pureza</span> de los contenedores identificados en
                    las 2 últimas evaluaciones.
                </li>
                <li>
                    En el caso de residuos <span>peligrosos</span>, la zona debe estar también protegida e
                    impermeabilizada.
                </li>

                <p>
                    <b>Objetivos (métricas clave de la gestión de residuos):</b>
                </p>
                <li>
                    <span>Valorización.</span> Compara el % de valorización obtenido con los umbrales definidos en la
                    configuración de proyecto.
                </li>
                <li>
                    <span>Mezcla.</span> Compara el % de mezcla con los umbrales definidos en la configuración de
                    proyecto.
                </li>
                <li>
                    <span>Flujos a segregar.</span> Evalúa que existan los flujos de residuos necesarios configurados
                    según avance del proyecto.
                </li>
                <li>
                    <span>Contratos de tratamiento.</span> Deben existir y estar vinculados a los códigos LERs y
                    gestoras definidos en el proyecto según avance del mismo (antes de registrar un traslado).
                </li>
                <li>
                    <span>Actualización trazabilidad (días).</span> Se mide el tiempo transcurrido desde la última
                    validación de traslados (DI).
                </li>
                <i>
                    Para ver más detalle sobre los objetivos, entra en cada proyecto y selecciona Configuración {'> '}
                    Objetivos y KPIs.
                </i>
            </section>
        </SimplePopover>
    );
};

export default ComparativeExplanation;
