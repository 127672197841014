import { useTranslation } from 'react-i18next';
import { IconAlarm } from '@tabler/icons';
import { withAuth } from '../../../context/AuthProvider';
import SmallMetricCard from '../../../components/BasicComponents/Cards/SmallMetricCard';
import SmallText from '../../../components/BasicComponents/Text/SmallText';
import HasPermission from '../../../components/AccessCheckers/HasPermission';
import { TituloH3 } from '../../../components/BasicComponents/Titulos/Titulos';
import { cambiarOrdenFecha, currencyFormatDE } from '../../../utils/helpers/general.helpers';
import './ResultsAssociateTraceabilities.scss';

const ResultAssociateTraceability = ({ traceability, selectedCheckboxes, handleClick }) => {
    const [t] = useTranslation();
    const { valorizacion } = traceability?.promoProGesCodeLer?.wasteManagerProGesCodLer?.procesoGestora || null;
    return (
        <div className="resultAssociate">
            <div className="resultAssociate__info">
                <HasPermission ifRole="canCreate" section="traceability">
                    <input
                        type="checkbox"
                        value={traceability}
                        onChange={() => handleClick(traceability)}
                        checked={selectedCheckboxes.find((associateTraceability) =>
                            associateTraceability.id === traceability.id ? true : false
                        )}
                        selected={selectedCheckboxes.includes(traceability.id)}
                        name={traceability.id}
                        className="toggle resultAssociate__toggle "
                    />
                </HasPermission>
                {traceability.traceabilityStatus === 'WARNING' && (
                    <p className="resultAssociate__warning">
                        <IconAlarm /> Sin DI o DI incompleto
                    </p>
                )}
                <TituloH3
                    titulo={`${t('tr', { ns: 'traceability' })}${traceability.id} - ${
                        traceability.promoProGesCodeLer.wasteManagerProGesCodLer.codLER.codigoLER
                    }`}
                />
                {traceability?.fechaMovimiento && <SmallText text={cambiarOrdenFecha(traceability.fechaMovimiento)} />}
            </div>

            <div className="resultAssociate__infoManager">
                <p className="resultAssociate__info">
                    <span>{t('manager.sg', { ns: 'common' })}: </span>
                    {traceability.promoProGesCodeLer.wasteManagerProGesCodLer.procesoGestora.gestora.empresa}
                </p>

                {traceability.promoProGesCodeLer.carrierProGesCodLer?.procesoGestora.gestora.empresa && (
                    <p className="resultAssociate__info resultAssociate__transportist">
                        <span>{t('transporter', { ns: 'common' })}: </span>
                        {traceability.promoProGesCodeLer.carrierProGesCodLer?.procesoGestora.gestora.empresa}
                    </p>
                )}
            </div>
            <div className="resultAssociate__containerCards">
                <SmallMetricCard
                    title={currencyFormatDE(traceability.volumenGestionado)}
                    subtitle={t('volume.upperCase', { ns: 'traceability' })}
                    unit="m³"
                />
                <SmallMetricCard
                    title={currencyFormatDE(traceability.pesoGestionado)}
                    subtitle={t('managed.lowerCase', { ns: 'traceability' })}
                    unit="tn"
                />
                <SmallMetricCard
                    title={currencyFormatDE(traceability.porcentajeValorizacion)}
                    subtitle={t('valuation.val', { ns: 'traceability' })}
                    unit="%"
                />
                <SmallMetricCard
                    title={traceability.costeLER === 0 ? '-' : currencyFormatDE(traceability.costeLER)}
                    subtitle={t('cost.lowerCase', { ns: 'costs' })}
                    unit="€"
                />
                <SmallMetricCard
                    title={valorizacion.codigoValorizacion}
                    subtitle={t('treatment.lowerCase', { ns: 'traceability' })}
                />
            </div>
        </div>
    );
};

export default withAuth(ResultAssociateTraceability);
