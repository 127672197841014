import { ResponsiveLine } from '@nivo/line';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Card from 'components/BasicComponents/Cards/SimpleCard';
import { useDateFormatter } from 'hooks/useDateFormatter';
import { TraceabilityStat, useTraceabilities } from 'modules/traceabilities';
import {
    MAX_MIXTURES_PERCENTAGE,
    MIN_VALORIZATION_PERCENTAGE
} from 'modules/traceabilities/domain/utils/traceabilities.constants';
import { currencyFormatDE } from 'utils/helpers/general.helpers';

import { usePromotion } from 'modules/promotion';
import { DATA_EMPTY, KPI_CONFIG, KpiColors } from './TraceabilitiesListKPIs.config';
import './TraceabilitiesListKPIs.scss';

interface TraceabilitiesListKPIsProps {
    hideCost?: boolean;
}

const TraceabilitiesListKPIs = (props: TraceabilitiesListKPIsProps) => {
    const [traceabilities, { loadTraceabilitiesStats }] = useTraceabilities();
    const { format } = useDateFormatter();
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();

    // In order to prevent innecesary updates,
    // extracts page and sort from criteria to only update when filters change
    const { page, sort, status, ...filters } = traceabilities.criteria || {};

    useEffect(() => {
        if (!filters.promotionId) return;
        loadTraceabilitiesStats();
    }, [JSON.stringify(filters), JSON.stringify(traceabilities.removed)]);

    const isPercentageKpi = (kpiName: keyof Partial<TraceabilityStat>) => {
        return kpiName === 'averageValorizationPercent' || kpiName === 'averageMixPercent';
    };

    const getMarkerPercentage = (kpiName: keyof Partial<TraceabilityStat>) => {
        if (kpiName === 'averageValorizationPercent') return MIN_VALORIZATION_PERCENTAGE;
        return MAX_MIXTURES_PERCENTAGE;
    };

    const lastEntry = traceabilities.stats.at(-1);
    const isEmpty = traceabilities.stats.length <= 1; // Because a chart without at least 2 points has no sense

    const modifiers = [[traceabilities.loading.stats === 'pending' ? 'TraceabilitiesListKPIs--is-loading' : '']];

    const showCost = promotion?.permissions.costs && !props.hideCost;

    return (
        <section className={`TraceabilitiesListKPIs ${modifiers.join(' ')}`}>
            {KPI_CONFIG.map((kpi) => {
                if (kpi.name === 'accumulatedCostPerTraceability' && !showCost) return <></>;

                const lastValue = (lastEntry || {})[kpi.name];

                const color = (isEmpty && KpiColors.empty) || kpi.calculateColor(lastValue) || KpiColors.neutral;
                const noData =
                    traceabilities.loading.stats === 'succeeded' &&
                    (!lastEntry || (kpi.name === 'averageValorizationPercent' && lastValue === null));

                return (
                    <Card className="TraceabilitiesListKPIs__item" dataTestid={kpi.name as any} id={kpi.name as any}>
                        <header className="TraceabilitiesListKPIs__header">
                            <p className="TraceabilitiesListKPIs__label">
                                {t(`kpis.${kpi.name}`, { ns: 'traceabilities' })}
                            </p>
                            <p className="TraceabilitiesListKPIs__value">
                                {lastEntry && !noData && `${currencyFormatDE(lastEntry[kpi.name])}${kpi.unit}`}
                                {noData && t('noData', { ns: 'traceabilities' })}
                            </p>
                        </header>

                        <div className="TraceabilitiesListKPIs__chart">
                            <ResponsiveLine
                                data={
                                    isEmpty
                                        ? DATA_EMPTY
                                        : [
                                              {
                                                  id: kpi.name,
                                                  data: traceabilities.stats.map((stat: TraceabilityStat) => ({
                                                      x: stat.date,
                                                      y: stat[kpi.name]
                                                  }))
                                              }
                                          ]
                                }
                                markers={
                                    isPercentageKpi(kpi.name) && !isEmpty
                                        ? [
                                              {
                                                  axis: 'y',
                                                  value: getMarkerPercentage(kpi.name),
                                                  lineStyle: {
                                                      stroke: KpiColors.neutral,
                                                      strokeWidth: 2,
                                                      strokeDasharray: '2 7'
                                                  },
                                                  legend: getMarkerPercentage(kpi.name) + '%',
                                                  textStyle: {
                                                      fill: '#ababab',
                                                      fontSize: 11
                                                  }
                                              }
                                          ]
                                        : []
                                }
                                yScale={{
                                    type: 'linear',
                                    min: isPercentageKpi(kpi.name) ? 0 : 'auto',
                                    max: isPercentageKpi(kpi.name) ? 100 : 'auto'
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={null}
                                axisLeft={null}
                                pointSize={1}
                                curve="cardinal"
                                margin={{ top: 5, bottom: 10 }}
                                lineWidth={2}
                                isInteractive={true}
                                enableCrosshair={false}
                                colors={() => color}
                                enableArea={true}
                                areaOpacity={0.3}
                                useMesh={true}
                                tooltip={(point) =>
                                    isEmpty ? (
                                        <div className="TraceabilitiesListKPIs__tooltip">
                                            <div className="TraceabilitiesListKPIs__tooltipValue">
                                                {t('kpis.nodata', { ns: 'traceabilities' })}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="TraceabilitiesListKPIs__tooltip">
                                            <div className="TraceabilitiesListKPIs__tooltipValue">
                                                {currencyFormatDE(point.point.data.y || 0)}
                                                {kpi.unit}
                                            </div>
                                            <div className="TraceabilitiesListKPIs__tooltipLabel">
                                                {format(point.point.data.x as string, 'MMMM YYYY')}
                                            </div>
                                        </div>
                                    )
                                }
                                legends={[]}
                                enableGridX={false}
                                enableGridY={false}
                                defs={[
                                    {
                                        id: `${kpi.name}-gradient`,
                                        type: 'linearGradient',
                                        colors: [
                                            {
                                                offset: 0,
                                                color: color
                                            },
                                            {
                                                offset: 80,
                                                color: `${color}00`
                                            }
                                        ]
                                    }
                                ]}
                                fill={[{ match: '*', id: `${kpi.name}-gradient` }]}
                            />
                        </div>
                    </Card>
                );
            })}
        </section>
    );
};

export default TraceabilitiesListKPIs;
