import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconInfoCircle } from '@tabler/icons';

import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';

import './ImpactKpi.scss';

const Kpi = ({ IconKPI, type, kpiValue, testid = null, children = null }) => {
    const [t] = useTranslation();
    const [isModalOpen, setOpenModal] = useState();

    const options = {
        real: {
            color: 'purple-real',
            subtitle: 'metrics.real'
        },
        potential: {
            color: 'green-potential',
            subtitle: 'explanation.potential'
        },
        potentialUnseparatedWastes: {
            color: 'green-potential',
            subtitle: 'explanation.potentialUnseparatedWastes'
        },
        standard: {
            color: 'purple-standard',
            subtitle: 'metrics.standard'
        },
        generated: {
            color: 'purple-real',
            subtitle: 'generatedReal'
        }
    };

    if (!kpiValue || kpiValue === '0') return null;

    const label = t(`${options[type]?.subtitle}`, { ns: 'impact' });

    const MoreInfoButton = () =>
        children ? (
            <IconInfoCircle
                className="impactKpi__moreInfoButton"
                role="button"
                size="16"
                onClick={() => setOpenModal(true)}
            />
        ) : null;

    return (
        <div data-testid={testid} className={`impactKpi impactKpi--${options[type]?.color}`}>
            <div>
                <span className="impactKpi__label">
                    {label} <MoreInfoButton />
                </span>
                <p className="impactKpi__value">{kpiValue}</p>
            </div>

            {children && (
                <SimplePopover
                    openModal={isModalOpen}
                    setOpenModal={setOpenModal}
                    noClose={false}
                    title={label}
                    addFunctionOnClose={() => setOpenModal(false)}
                >
                    {children}
                </SimplePopover>
            )}
        </div>
    );
};

export default Kpi;
