import { contextStoreAdapter } from 'modules/shared/infraestructure/adapters/react/contextStoreAdapter';

import { automaticReadingPermissionsStore } from '../../application/automaticReadingPermissionsStore';

// Creator
const createAutomaticReadingPermissionsContext = contextStoreAdapter(automaticReadingPermissionsStore);

// Main
const automaticReadingPermissionsContext = createAutomaticReadingPermissionsContext({
    name: 'AutomaticReadingPermissionsStore'
});
export const useAutomaticReadingPermissions = automaticReadingPermissionsContext.useConsumer;
export const AutomaticReadingPermissionsProvider = automaticReadingPermissionsContext.Provider;
