import BilleableBox from '../BilleableBox/BilleableBox';

interface PromotionBilleablesListSkeletonsProps {
    count?: number;
}

const PromotionBilleablesListSkeletons = (props: PromotionBilleablesListSkeletonsProps) => {
    const skeletons = new Array(props.count).fill(null);

    return (
        <>
            {skeletons.map((_, index) => (
                <BilleableBox key={index} />
            ))}
        </>
    );
};

export default PromotionBilleablesListSkeletons;
