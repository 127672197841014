import { IconArrowRight } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import RoundedIcon from 'components/BasicComponents/Icon/RoundedIcon';
import { TituloH2 } from 'components/BasicComponents/Titulos/Titulos';
import { FAQ_BASE_URL, FAQ_SECTIONS } from '../constans/faqSections';
import './FAQ.scss';

const FAQPage = () => {
    const history = useHistory();
    const [t] = useTranslation('faq');
    const { faqId } = useParams<{ faqId: string }>();
    const [selectedGuideId, setSelectedGuideId] = useState(faqId || 'd0d02f86-7ea0-4920-94ac-6a0dac969ca4');

    const getSelectedGuideUrl = () => {
        const url = FAQ_BASE_URL + selectedGuideId;
        return url;
    };

    useEffect(() => {
        if (!selectedGuideId) return;
        history.push(`/help/faq/${selectedGuideId}`);
    }, [selectedGuideId]);

    return (
        <div className="faq">
            <div className="faq__container">
                <SimpleCard className="faq__menu">
                    {FAQ_SECTIONS.map((section) => (
                        <div key={section.id} className="faq__section">
                            <div className="faq__title">
                                <RoundedIcon icon={section.icon} />
                                <TituloH2 titulo={t(section.titleKey)} />
                            </div>
                            {section.guides.map((guide) => (
                                <div
                                    key={guide.id}
                                    className={
                                        selectedGuideId === guide.id
                                            ? 'faq__option faq__option-selected'
                                            : 'faq__option'
                                    }
                                    onClick={() => setSelectedGuideId(guide.id)}
                                >
                                    <p>{guide.title}</p>
                                    <IconArrowRight stroke={1.25} />
                                </div>
                            ))}
                        </div>
                    ))}
                </SimpleCard>
                <SimpleCard className="faq__iframe">
                    <iframe src={getSelectedGuideUrl()} width="800" height="640" allowFullScreen />
                </SimpleCard>
            </div>
        </div>
    );
};
export default FAQPage;
