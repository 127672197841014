export const formattedDate = (start, end) => {
    const startDate = new Date(start);
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();

    const endDate = new Date(end);
    const endMonth = endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();

    return `${startMonth}/${startYear} - ${endMonth}/${endYear}`;
};

export const getTodayDate = () => {
    let today: Date | string = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    return today;
};

/** Obtiene todos los meses comprendidos entre dos fechas */
export const getMonthsBetween = ({ firstDate, lastDate }: { firstDate: string; lastDate: string }): string[] => {
    const fromDate = new Date(firstDate);
    const toDate = new Date(lastDate);

    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth();
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months: string[] = [];

    for (let year = fromYear; year <= toYear; year++) {
        let monthNum = year === fromYear ? fromMonth : 0;
        const monthLimit = year === toYear ? toMonth : 11;

        for (; monthNum <= monthLimit; monthNum++) {
            let month = monthNum + 1;
            months.push(`${year}-${month < 10 ? `0${month}` : month}-01`);
        }
    }
    return months;
};
