import { IconAlertCircle, IconBuildingFactory, IconLicense, IconRecycle, IconTruck } from '@tabler/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import RoundedIcon from 'components/BasicComponents/Icon/RoundedIcon';
import { TituloH2, TituloH3 } from 'components/BasicComponents/Titulos/Titulos';
import { usePromotion } from 'modules/promotion';
import { useTraceabilities } from 'modules/traceabilities';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import './TraceabilityDetail.scss';
import TraceabilityDetailFollowing from './components/TraceabilityDetailFollowing';
import TraceabilityDetailPreview from './components/TraceabilityDetailPreview';

interface TraceabilityDetailProps {
    traceabilityId: string;
}

const dateFormat: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
const timeFormat: Intl.DateTimeFormatOptions = { ...dateFormat, hour: '2-digit', minute: '2-digit' };
const stakeholders: Array<'transferOperator' | 'carrier' | 'wasteManager'> = [
    'transferOperator',
    'carrier',
    'wasteManager'
];
const stakeholderIcons = {
    wasteManager: <IconBuildingFactory />,
    carrier: <IconTruck />,
    transferOperator: <IconLicense />
};

const TraceabilityDetail = (props: TraceabilityDetailProps) => {
    const { traceabilityId } = props;
    const [{ promotion }] = usePromotion();
    const [t, { language }] = useTranslation();
    const [traceabilities, { loadOneTraceability }] = useTraceabilities();

    const traceability = traceabilities.data.byId[traceabilityId];

    useEffect(() => {
        if (!promotion?.id) return;
        loadOneTraceability(promotion?.id, traceabilityId);
    }, [traceabilityId, promotion]);

    const isLoaded = !!traceability;
    const hasError = traceabilities.loading.detail === 'failed';
    const fallback = isLoaded ? '-' : null;

    const value = (val: any, unit?: string) => (val ? val + (unit || '') : fallback);
    const date = (date?: string, format?: Intl.DateTimeFormatOptions) =>
        date ? new Date(date).toLocaleDateString(language, format) : fallback;
    const label = (lab: string) => (isLoaded ? t(`detail.${lab}`, { ns: 'traceabilities' }) : null);

    const modifiers = {
        hasError: hasError ? 'TraceabilityDetail--has-error' : '',
        isLoading: traceabilities.loading.detail === 'pending' && !isLoaded ? 'TraceabilityDetail--is-loading' : ''
    };

    return (
        <div className={`TraceabilityDetail ${modifiers.isLoading} ${modifiers.hasError}`}>
            {traceabilities.error && (
                <AlertCard
                    title={(traceabilities.error as Error)?.message}
                    className="card__alert-error TraceabilityDetail__errorMsg"
                    icon={<IconAlertCircle />}
                    testId="TraceabilityDetail__errorMsg"
                >
                    <code>{JSON.stringify((traceabilities.error as Error).stack, null, 2)}</code>
                </AlertCard>
            )}

            <SimpleCard className="TraceabilityDetail__main">
                <TraceabilityDetailPreview traceability={traceability} />

                <div className="TraceabilityDetail__info">
                    <div className="TraceabilityDetail__data">
                        <dl>
                            <dt>{label('date')}</dt>
                            <dd>{date(traceability?.date)}</dd>
                        </dl>
                        <dl>
                            <dt>{label('status')}</dt>
                            <dd
                                className={`TraceabilityDetail__status TraceabilityDetail__status--${traceability?.status}`}
                            >
                                {t(`status.${traceability?.status}`, { ns: 'traceabilities', defaultValue: null })}
                            </dd>
                        </dl>
                        <dl>
                            <dt>{label('diNum')}</dt>
                            <dd>{value(traceability?.di.num)}</dd>
                        </dl>

                        <div className="TraceabilityDetail__break"></div>

                        <dl>
                            <dt>{label('creationDate')}</dt>
                            <dd>{date(traceability?.creationDate, timeFormat)}</dd>
                        </dl>
                        <dl>
                            <dt>{label('createdBy')}</dt>
                            <dd>{value(traceability?.createdBy)}</dd>
                        </dl>

                        <div className="TraceabilityDetail__break"></div>

                        <dl>
                            <dt>{label('lastUpdate')}</dt>
                            <dd>{date(traceability?.updateDate, timeFormat)}</dd>
                        </dl>
                        <dl>
                            <dt>{label('updatedBy')}</dt>
                            <dd>{value(traceability?.updatedBy)}</dd>
                        </dl>

                        <div className="TraceabilityDetail__break"></div>

                        <dl>
                            <dt>{label('observations')}</dt>
                            <dd>{value(traceability?.observations)}</dd>
                        </dl>
                    </div>
                </div>
            </SimpleCard>

            <SimpleCard className="TraceabilityDetail__section">
                <div className="TraceabilityDetail__cardTitle">
                    <RoundedIcon icon={<IconRecycle />} />
                    <TituloH3 titulo={t('card.waste', { ns: 'traceabilities' })} />
                </div>
                <div className="TraceabilityDetail__data">
                    <dl>
                        <dt>{label('lerCode')}</dt>
                        <dd>{value(traceability?.ler.code)}</dd>
                    </dl>
                    <dl>
                        <dt>{label('lerKind')}</dt>
                        <dd>{value(traceability?.ler.kind)}</dd>
                    </dl>
                    <dl>
                        <dt>{label('valorizationCode')}</dt>
                        <dd>{value(traceability?.process.valorizationCode)}</dd>
                    </dl>
                    <dl>
                        <dt>{label('valorizationPercent')}</dt>
                        <dd>{value(traceability?.valorizationPercent, '%')}</dd>
                    </dl>
                    <dl>
                        <dt>{label('weight')}</dt>
                        <dd>{value(currencyFormatDE(traceability?.weight), 'tn')}</dd>
                    </dl>
                    <dl>
                        <dt>{label('volume')}</dt>
                        <dd>{value(traceability?.volume, 'm³')}</dd>
                    </dl>
                    <div className="TraceabilityDetail__break"></div>
                    <dl>
                        <dt>{label('lerDesc')}</dt>
                        <dd>{value(traceability?.ler.desc)}</dd>
                    </dl>
                    <dl>
                        <dt>{label('processDesc')}</dt>
                        <dd>{value(traceability?.process.description)}</dd>
                    </dl>
                </div>
            </SimpleCard>

            <SimpleCard className="TraceabilityDetail__section">
                <TraceabilityDetailFollowing traceabilityId={props.traceabilityId} />
            </SimpleCard>

            <section className="TraceabilityDetail__section">
                <TituloH2 titulo={t('section.stakeholders', { ns: 'traceabilities' })} />
                <div className="TraceabilityDetail__stakeholders">
                    {stakeholders.map((stakeholder) => (
                        <SimpleCard key={stakeholder}>
                            <div className="TraceabilityDetail__cardTitle">
                                <RoundedIcon icon={stakeholderIcons[stakeholder]} />
                                <TituloH3 titulo={t(`card.${stakeholder}`, { ns: 'traceabilities' })} />
                            </div>
                            <div className="TraceabilityDetail__data TraceabilityDetail__data--one-field-per-row">
                                <dl>
                                    <dt>{label('stakeholder.name')}</dt>
                                    <dd>{value((traceability || {})[stakeholder]?.name)}</dd>
                                </dl>
                                <dl>
                                    <dt>{label('stakeholder.nif')}</dt>
                                    <dd>{value((traceability || {})[stakeholder]?.nif)}</dd>
                                </dl>
                                <dl>
                                    <dt>{label('stakeholder.nima')}</dt>
                                    <dd>{value((traceability || {})[stakeholder]?.nima)}</dd>
                                </dl>
                                <dl>
                                    <dt>
                                        {label(
                                            (traceability?.[stakeholder]?.addresses || [])?.length > 1
                                                ? 'stakeholder.addresses'
                                                : 'stakeholder.address'
                                        )}
                                    </dt>
                                    <dd>
                                        {(traceability?.[stakeholder]?.addresses || [])?.length > 0
                                            ? (traceability || {})[stakeholder]?.addresses.map((address) => (
                                                  <p key={address.address}>
                                                      {address.address} {address.postalCode} {address.city.name}{' '}
                                                      {address.province.name}
                                                  </p>
                                              ))
                                            : fallback}
                                    </dd>
                                </dl>
                                <dl>
                                    <dt>{label('stakeholder.email')}</dt>
                                    <dd>{value((traceability || {})[stakeholder]?.email)}</dd>
                                </dl>
                                <dl>
                                    <dt>{label('stakeholder.phone')}</dt>
                                    <dd>{value((traceability || {})[stakeholder]?.phone)}</dd>
                                </dl>
                                {stakeholder === 'carrier' && traceability?.carrier.vehiclePlate && (
                                    <dl>
                                        <dt>{label('vehiclePlate')}</dt>
                                        <dd>{value(traceability?.carrier.vehiclePlate)}</dd>
                                    </dl>
                                )}
                            </div>
                        </SimpleCard>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default TraceabilityDetail;
