export const omitFalsyValues = (obj: { [key: string]: any }) => {
    const filteredEntries = Object.entries(obj).filter(
        ([key, value]) => value !== null && value !== '' && value !== undefined
    );
    return Object.fromEntries(filteredEntries);
};

export const deepEqual = (a?: Object, b?: Object) => {
    return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function deepMerge(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                deepMerge(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return deepMerge(target, ...sources);
}
