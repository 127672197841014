import fetcher from 'lib/fetcher';
import { WasteByValorizationApi } from '../../domain/WasteByValorizationApi';

export const httpWasteByValorizationApi: WasteByValorizationApi = {
    getWasteByValorization: async ({ promotionId }) => {
        const { data } = await fetcher.get(`/api/tracing/valuation/metrics`, { params: { promotionId } });

        const wasteByValorization = data?.content?.map((entry) => ({ ...entry, id: entry.lerType.id }));

        return wasteByValorization || [];
    }
};
