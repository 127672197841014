import { useEffect } from 'react';

import TraceabilitiesSummary from 'features/traceabilities/components/TraceabilitiesList/components/TraceabilitiesSummary';
import {
    TraceabilitiesSummaryMode,
    TraceabilitiesSummaryUnits
} from 'modules/traceabilities/domain/TraceabilitiesSummary';
import { TraceabilitiesSummaryProps } from 'features/traceabilities/components/TraceabilitiesList/components/TraceabilitiesSummary/TraceabilitiesSummary';
import { usePromotion } from 'modules/promotion';
import { TraceabilitiesCriteria, useTraceabilities } from 'modules/traceabilities';
import './TraceabilitiesSummaryPrintable.scss';

export type TraceabilitiesSummaryPrintableProps = TraceabilitiesSummaryProps & { criteria?: TraceabilitiesCriteria };

const TraceabilitiesSummaryPrintable = (props: TraceabilitiesSummaryPrintableProps) => {
    const [{ promotion }] = usePromotion();
    const [, { loadTraceabilities }] = useTraceabilities();

    useEffect(() => {
        loadTraceabilities({ promotionId: promotion?.id, ...(props.criteria || {}) });
    }, []);

    return (
        <>
            <div className="TraceabilitiesSummaryPrintable">
                <TraceabilitiesSummary
                    limited={false}
                    hideCost={true} // To prevent the cost column from being printed from any user
                    unit={TraceabilitiesSummaryUnits.tons}
                    mode={TraceabilitiesSummaryMode.totals}
                    {...props}
                />
            </div>
        </>
    );
};

export default TraceabilitiesSummaryPrintable;
