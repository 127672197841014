import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useAuth } from 'modules/auth';
import { EnterpriseId } from 'modules/enterprise/enterprises';
import { PromotionId } from 'modules/promotion';
import { Tag, tagsHooks } from 'modules/tags';
import { useEffect, useRef, useState } from 'react';
import { hasPermission } from 'utils/helpers/roles.helpers';
import TagBadge from '../TagBadge';
import AddTags from './AddTags';
import { AddTagsRef } from './AddTags/AddTags';
import './Tags.scss';

export type TagButProjects = Omit<Tag, 'projects'>;

interface TagsProps {
    tags: TagButProjects[];
    enterpriseId: EnterpriseId;
    promotionId: PromotionId;
}

const Tags = (props: TagsProps) => {
    const [account] = useAuth();
    const [, analytics] = useAnalyticsStore();
    const detachFromPromotion = tagsHooks.useDetachFromProject();
    const [tags, setTags] = useState<TagButProjects[]>(props.tags);
    const addTagsRef = useRef<AddTagsRef>(null);
    const canEditOrCreate = hasPermission('tags', 'canCreate', account.user);

    // Sync tags with props.tags
    useEffect(() => setTags(props.tags), [props.tags]);

    const detach = async (tag: TagButProjects) => {
        const { loading } = await detachFromPromotion.mutate({ projectId: props.promotionId, tagId: tag.id });

        if (loading === 'succeeded') {
            setTags((prev) => prev.filter((t) => t.id !== tag.id));
            analytics.event(AnalyticsEvents.HOME_TAG_DETACHED);
        }

        addTagsRef.current?.setOpen(false);
    };

    return (
        <div className="Tags" onClick={(e) => (canEditOrCreate ? e.stopPropagation() : null)}>
            {canEditOrCreate && (
                <AddTags
                    ref={addTagsRef}
                    enterpriseId={props.enterpriseId}
                    promotionId={props.promotionId}
                    onAdd={(tag) => setTags((prev) => [...prev, tag])}
                    onAddFailed={(tag) => setTags((prev) => prev.filter((t) => t.id !== tag.id))}
                />
            )}

            {tags.map((tag) => (
                <TagBadge
                    name={tag.name}
                    color={tag.color}
                    onRemove={canEditOrCreate ? () => detach(tag) : undefined}
                />
            ))}
        </div>
    );
};

export default Tags;
