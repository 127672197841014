import fetcher from 'lib/fetcher';

import { Ler } from '../../domain/Ler';
import { LerApi } from '../../domain/LerApi';

export const httpLerApi: LerApi = {
    async findLerByCriteria(criteria) {
        const { promotionId } = criteria || {};

        if (promotionId !== undefined) {
            const { data } = await fetcher.get(`/api/lers/project/${promotionId}`);
            const ler: Ler[] = data.content.map((item) => ({
                id: item.id,
                code: item.code,
                type: item.lerKind
            }));

            return ler;
        }

        return [];
    }
};
