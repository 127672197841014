import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import userServices from '../../../api/user/user.services';
import { withAuth } from '../../../context/AuthProvider';
import SimpleCard from '../../BasicComponents/Cards/SimpleCard';
import InputText from '../../BasicComponents/Inputs/InputText';
import InputEmail from '../../BasicComponents/Inputs/InputEmail';
import { TituloH3 } from '../../BasicComponents/Titulos/Titulos';
import SubmitButton from '../../BasicComponents/Buttons/SubmitButton';
import Spinner from '../../BasicComponents/Spinner/Spinner';
import ResultadoCarga from '../../BasicComponents/Messages/ResultadoCarga';
import MessageNotComplete from '../../BasicComponents/Messages/MessageNotComplete';
import RoleAutocomplete from '../../ComplexComponents/NewPromotion/Step3/RoleAutocomplete';
import LanguageSelect from '../../ComplexComponents/NewPromotion/Step3/LanguageSelect';

const PersonalInformationForm = (props) => {
    const [t] = useTranslation();
    const { i18n } = useTranslation();

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneOne: '',
        phoneTwo: '',
        langKey: '',
        activated: true
    });

    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [messageNotComplete, setMessageNotComplete] = useState();

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };
    const handleFormSubmit = async (e) => {
        /** TODO REPLACE with editUser thunk */
        e.preventDefault();
        setSubmitting(true);
        setLoading(true);
        if (validationForm()) {
            const updatedUser = await userServices.editUser(values);
            if (updatedUser !== undefined) {
                i18n.changeLanguage(values.langKey);
                localStorage.setItem('lang', values.langKey?.toLowerCase());

                /**
                 * PROVISIONAL WORKAROUND
                 * To force the AuthProvider to update, we temporarily reload the page.
                 * The final approach should be to create a Thunk for auth module for this entire part.
                 */
                window.location.reload();

                setMessage({ success: true, text: t('success.update', { ns: 'profile' }) });
            } else {
                setMessage({ success: false, text: t('profile.update', { ns: 'errors' }) });
            }
        }
        setSubmitting(false);
        setLoading(false);
        /** / TODO REPLACE with editUser thunk */
    };

    const validationForm = () => {
        if (
            values?.email === null ||
            values?.firstName === null ||
            values?.lastName === null ||
            values?.phoneOne === null ||
            values?.email === '' ||
            values?.firstName === '' ||
            values?.lastName === '' ||
            values?.phoneOne === ''
        ) {
            setMessageNotComplete(t('complete', { ns: 'common' }));
            return false;
        } else {
            setMessageNotComplete('');
            return true;
        }
    };
    useEffect(() => {
        setValues(props?.user);
    }, [props?.user]);

    return (
        <SimpleCard className="editProfile__card">
            <TituloH3 titulo={t('personalData', { ns: 'profile' })} />
            <form onSubmit={handleFormSubmit}>
                <InputEmail
                    label={t('email', { ns: 'profile' })}
                    onChange={handleInputChange}
                    name="email"
                    value={values?.email}
                    id="email"
                    required
                />
                <InputText
                    label={t('name', { ns: 'profile' })}
                    onChange={handleInputChange}
                    name="firstName"
                    value={values?.firstName}
                    id="firstName"
                    required
                />
                <InputText
                    label={t('lastName', { ns: 'profile' })}
                    onChange={handleInputChange}
                    name="lastName"
                    value={values?.lastName}
                    id="lastName"
                    required
                />
                <RoleAutocomplete
                    values={values}
                    setValues={setValues}
                    // validacion={validation}
                    required
                />
                <InputText
                    label={t('telf', { ns: 'profile' })}
                    onChange={handleInputChange}
                    name="phoneOne"
                    value={values?.phoneOne}
                    id="phoneOne"
                    required
                />
                <InputText
                    label={t('otherTelf', { ns: 'profile' })}
                    onChange={handleInputChange}
                    name="phoneTwo"
                    value={values?.phoneTwo}
                    id="phoneTwo"
                />

                {props.isCoCircular && (
                    <span>
                        <LanguageSelect values={values} setValues={setValues} onChange={handleInputChange} />
                    </span>
                )}
                <SubmitButton text={t('updateData', { ns: 'profile' })} buttonDisabled={submitting} />
            </form>
            {message?.text && (
                <ResultadoCarga setMessage={setMessage} text={message?.text} success={message?.success} />
            )}
            {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
            {loading && <Spinner />}
        </SimpleCard>
    );
};
export default withAuth(PersonalInformationForm);
