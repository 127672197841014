import fetcher from 'lib/fetcher';
import { ImpactApi } from 'modules/impact/domain/ImpactApi';

const url = (resource: string) => `/api/impact/${resource}`;

export const httpImpactApi: ImpactApi = {
    async getGeneratedImpact(params) {
        const results = await Promise.allSettled([
            fetcher.get(url('generated/co2-by-ler'), { params }).then(({ data }) => data.content),
            fetcher.get(url('generated/land-by-ler'), { params }).then(({ data }) => data.content),
            fetcher.get(url('generated/co2-by-date'), { params }).then(({ data }) => data.content.scenarios),
            fetcher.get(url('generated/land-by-date'), { params }).then(({ data }) => data.content.scenarios)
        ]);

        const [co2ByLer, landByLer, co2ByDate, landByDate] = results;

        return {
            errors: getErrors(results),

            co2ByLer: getValue(co2ByLer),
            landByLer: getValue(landByLer),
            co2ByDate: getValue(co2ByDate),
            landByDate: getValue(landByDate)
        };
    },

    async getAvoidedImpact(params) {
        const results = await Promise.allSettled([
            fetcher.get(url('avoided/co2-by-ler'), { params }).then(({ data }) => data.content),
            fetcher.get(url('avoided/land-by-ler'), { params }).then(({ data }) => data.content),
            fetcher.get(url('avoided/co2-by-date'), { params }).then(({ data }) => data.content.scenarios),
            fetcher.get(url('avoided/land-by-date'), { params }).then(({ data }) => data.content.scenarios),
            fetcher.get(url('avoided/total/co2-equivalences'), { params }).then(({ data }) => data.content),
            fetcher.get(url('avoided/total/land-equivalences'), { params }).then(({ data }) => data.content)
        ]);

        const [co2ByLer, landByLer, co2ByDate, landByDate, co2Total, landTotal] = results;

        return {
            errors: getErrors(results),

            co2ByLer: getValue(co2ByLer),
            landByLer: getValue(landByLer),
            co2ByDate: getValue(co2ByDate),
            landByDate: getValue(landByDate),
            co2Total: getValue(co2Total),
            landTotal: getValue(landTotal)
        };
    }
};

const getValue = (result: PromiseSettledResult<any>, fallback?: any) =>
    result.status === 'fulfilled' ? result.value : fallback;

const getErrors = (results: PromiseSettledResult<any>[]) =>
    results.filter((result) => result.status === 'rejected').map((result: any) => result.reason) || [];
