import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

export function useDateFormatter() {
    const [, { language }] = useTranslation();

    dayjs.locale(language);

    const capitalizeString = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const fromNow = (date?: string | Date, capitalize = false) => {
        if (!date) return '';
        const fromNow = dayjs(date).fromNow();
        return capitalize ? capitalizeString(fromNow) : fromNow;
    };

    const format = (date?: string | Date, format?: string) => {
        if (!date) return '';
        return dayjs(date).format(format || 'DD MMM YYYY, HH:mm');
    };

    return { fromNow, format };
}
