import { IconTag, IconX } from '@tabler/icons';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';

import { useAsyncOptions } from 'components/BasicComponents/Filter/SelectFilter/hooks/useAsyncOptions';
import SelectAutocomplete from 'components/BasicComponents/Select/SelectAutocomplete';
import Tooltip from 'components/BasicComponents/Tooltips/Tooltip';
import { useKey } from 'hooks/useKey';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { PromotionId } from 'modules/promotion';
import { TagEnterpriseId, createTag, tagCreatorAdapter, tagsHooks, useTagsStore } from 'modules/tags';
import TagBadge from '../../TagBadge';
import { TagButProjects } from '../Tags';
import './AddTags.scss';

interface AddTagsProps {
    enterpriseId: TagEnterpriseId;
    promotionId: PromotionId;
    onAdd?: (tag: TagButProjects) => any;
    onAddFailed?: (tag: TagButProjects) => any;
}

export type AddTagsRef = {
    setOpen: (isOpen: boolean) => Promise<void>;
};

const AddTags = forwardRef<AddTagsRef, AddTagsProps>((props: AddTagsProps, ref) => {
    const [, , { api }] = useTagsStore();
    const [, analytics] = useAnalyticsStore();
    const [t] = useTranslation('tags');
    const [selectIsOpen, setSelectIsOpen] = useState(false);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const selectRef = useRef<any>(null);

    const save = tagsHooks.useSave();
    const attachToProject = tagsHooks.useAttachToProject();

    const [loadOptions] = useAsyncOptions({
        request: async ({ search, page }) => {
            if ((page || 0) > 0) return [];
            return (
                api
                    .getTags({ enterpriseId: props.enterpriseId })
                    // Exclude already added tags
                    .then((tags) => tags.filter((tag) => !tag.projects.find((p) => p.id === props.promotionId)))
                    // Search
                    .then((tags) =>
                        search ? tags.filter((tag) => tag.name?.toLowerCase().includes(search?.toLowerCase())) : tags
                    )
            );
        },
        adapter: (tag) => ({
            value: tag.id,
            label: (
                <div className="AddTags__option">
                    <TagBadge color={tag.color} name={tag.name} />
                </div>
            ),
            tag
        })
    });

    const attach = async (tag: TagButProjects) => {
        setSelectIsOpen(false);
        props.onAdd?.(tag);

        const { loading } = await attachToProject.mutate({ projectId: props.promotionId, tagId: tag.id });

        if (loading === 'succeeded') analytics.event(AnalyticsEvents.HOME_TAG_ATTACHED);
        if (loading === 'failed') props.onAddFailed?.(tag);
    };

    const createNewTag = useCallback(async () => {
        if (!search) return;
        const tag = createTag({ id: uuid(), name: search });

        const tagCreator = tagCreatorAdapter(tag, props.enterpriseId);
        tagCreator.projectIds?.push(props.promotionId);

        props.onAdd?.(tag);
        const { loading } = await save.mutate(tagCreator);

        if (loading === 'succeeded') analytics.event(AnalyticsEvents.HOME_TAG_CREATED);
        if (loading === 'failed') props.onAddFailed?.(tag);

        setSelectIsOpen(false);
    }, [search]);

    useImperativeHandle(
        ref,
        () => ({
            setOpen: async (isOpen) => setSelectIsOpen(isOpen)
        }),
        []
    );

    useKey('Enter', createNewTag);

    useKey('Escape', () => setSelectIsOpen(false));

    useEffect(() => {
        if (selectIsOpen) selectRef.current?.select.focus();
    }, [selectIsOpen]);

    return (
        <>
            <div className="AddTags">
                <Tooltip msg={t('tags.addTags')}>
                    <button
                        className="AddTags__add"
                        aria-label={t('tags.addTags')}
                        onClick={() => setSelectIsOpen(!selectIsOpen)}
                    >
                        {selectIsOpen ? <IconX size={14} /> : <IconTag size={14} />}
                    </button>
                </Tooltip>

                {selectIsOpen && (
                    <SelectAutocomplete
                        ref={selectRef}
                        className="AddTags__select"
                        loadOptions={loadOptions}
                        onInputChange={(value) => setSearch(value)}
                        alwaysOpen
                        noOptionsMessage={t('tags.createTag')}
                        onChange={(option) => option && attach((option as TagOption).tag)}
                        placeholder={t('tags.searchTag')}
                    />
                )}
            </div>
        </>
    );
});

type TagOption = {
    value: string;
    label: string;
    tag: TagButProjects;
};

export default AddTags;
