// used when only info changed (file itself remains the same)
export const docMapperPATCH = ({ promoId, docData, type }) => {
    const doc = {
        promotionId: promoId,
        document: {
            id: docData?.id,
            name: docData?.name,
            remarks: docData?.remarks || '',
            date: docData?.date,
            type: { id: type?.id, name: type?.name }
        }
    };
    return doc;
};
