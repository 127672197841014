import { removeAccentedCharacters } from 'utils/helpers/general.helpers';
import './LerSelectorOption.scss';

interface LerSelectorOptionProps {
    code: string;
    lerType: string;
    description?: string;
    search?: string;
}

const LerSelectorOption = ({ code, lerType, description, search }: LerSelectorOptionProps) => {
    const highlightSearch = (string: string) => {
        if (!search || search === '') return string;

        const cleanedString = removeAccentedCharacters(string);
        const cleanedSearch = removeAccentedCharacters(search);

        var match = new RegExp(cleanedSearch, 'i').exec(cleanedString);

        if (!match) return string;

        const i = match.index;

        const firstPart = string.substring(0, i);
        const middlePart = string.substring(i, i + search.length);
        const lastPart = string.substring(firstPart.length + middlePart.length);

        const highlightedText = firstPart + `<mark>${middlePart}</mark>` + lastPart;

        return <span dangerouslySetInnerHTML={{ __html: highlightedText }} />;
    };

    return (
        <div className="LerSelectorOption" data-testid="LerSelectorOption">
            <p className="LerSelectorOption__ler">
                {highlightSearch(code)} <span className="LerSelectorOption__lerType">{highlightSearch(lerType)}</span>
            </p>
            <p className="LerSelectorOption__description">
                <span title={description}>{highlightSearch(description || '')}</span>
            </p>
        </div>
    );
};

export default LerSelectorOption;
