import ChangePasswordForm from '../../components/ChangePasswordForm';

const ResetPasswordChange = () => {
    return (
        <ChangePasswordForm
            title="resetPasswordTitle"
            subtitle="resetPasswordChangeSubtitle"
            sendBtnTxt="changePassword"
        />
    );
};

export default ResetPasswordChange;
