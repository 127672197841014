import { WasteRegister } from '../WasteRegister';

export const csvFieldsAdapter = (wr: WasteRegister) => [
    ['Fecha de movimiento', wr.movementDate],
    ['Tipo de residuo', wr.flow.ler.type],
    ['Código de residuo', wr.flow.ler.code],
    ['Volumen', wr.volume],
    ['Destino', wr.flow.manager.name],
    ['Transportista', wr.flow.carrier?.name || '-']
];
