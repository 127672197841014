import { Trans, useTranslation } from 'react-i18next';

import SettingsExporter from 'components/ComplexComponents/SettingsExporter';
import { usePromotion } from 'modules/promotion';
import { usePromotionKPIs } from 'modules/promotion-kpis';
import './KPIGoalsExport.scss';

interface KPIGoalsExportProps {}

const KPIGoalsExport = (props: KPIGoalsExportProps) => {
    const [t] = useTranslation('KPIsGoalSettings');
    const [{ promotion }] = usePromotion();
    const [, { downloadGoals, restoreGoals: uploadGoals }] = usePromotionKPIs();

    const promotionId = promotion?.id;

    if (promotionId === undefined) return null;

    return (
        <div className="KPIGoalsExport">
            <SettingsExporter
                onDownload={() => downloadGoals({ promotionId })}
                uploadMsg={<Trans i18nKey="KPIsGoalSettings:exporter.uploadMsg" components={{ p: <p /> }} />}
                defaultFileName={t('exporter.downoadFileName', { promotion: promotion?.nombre })}
                onUpload={async (payload) => {
                    await uploadGoals({ promotionId, payload });
                    window.location.reload();
                }}
            />
        </div>
    );
};

export default KPIGoalsExport;
