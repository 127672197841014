import { DeepRequired, FieldErrorsImpl, Resolver } from 'react-hook-form';
import _set from 'lodash.set';
import _get from 'lodash.get';
import { JsonSchema } from 'modules/shared';

export function jsonSchemaResolver<Values extends Record<string, any>>(schema: JsonSchema): Resolver<Values, any> {
    // resolver:
    return async (values, context, options) => {
        const errors = getErrors(schema, values);

        return {
            errors,
            values: Object.values(errors).length ? {} : values
        };
    };
}

export type ControlConfig = JsonSchema & { isRequired?: boolean; path: string };

function getErrors(schema: JsonSchema, values: Record<string, any>): Partial<FieldErrorsImpl<DeepRequired<any>>> {
    const errors: Partial<FieldErrorsImpl<DeepRequired<any>>> = {};

    // Validate a given node and its children deeply
    const validateTree = (control: ControlConfig) => {
        const { path } = control;

        // Prevent empty nodes
        if (!control || Object.values(control).length === 0) return;

        // If the node has properties, call extract recursively on each property
        if (control.properties) {
            Object.entries(control.properties).forEach((child) => {
                const [childName, childNode] = child as [string, JsonSchema];
                const childPath = (path ? `${path}.` : '') + childName;
                const isRequired = (control.required || []).includes(childName);

                validateTree({ ...childNode, path: childPath, isRequired });
            });

            return;
        }

        const value = _get(values, path);
        const error = validateControl(value, control);
        if (!error) return;

        _set(errors, path, error);
    };

    validateTree({ ...schema, isRequired: false, path: '' });
    return errors;
}

/** Validates a single control */
function validateControl(value: any, control: ControlConfig): FieldErrorsImpl<any>[string] | undefined {
    // Sort is importante here, because for UX reasons, we want to show first more general errors
    // E.g. if a field is required and has a wrong type, we want to show the required error first

    if (control.isRequired) {
        if (value === undefined || value === null || value === '') {
            return { type: 'required', message: 'required' };
        }
    }

    if (control.type === 'number') {
        if (isNaN(value)) {
            return { type: 'type', message: 'not_integer' };
        }
    }

    if (control.oneOf) {
        if (!control.oneOf.map((s: JsonSchema) => s.const).includes(value)) {
            return { type: 'value', message: 'invalid_value' };
        }
    }
}
