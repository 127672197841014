import { withAuth } from 'context/AuthProvider';
import MultiProvider from 'lib/MultiProvider';
import { logger } from 'logger';
import { AutomaticReadingPermissionsProvider, httpAutomaticReadingPermissionsApi } from 'modules/automatic-reading';
import { DiExtractionProvider, httpDiExtractionApi } from 'modules/di-extraction';
import { TraceabilitiesProvider, httpTraceabilitiesApi } from 'modules/traceabilities';
import Traceabilities from './Traceabilities';

/** Traceabilities container component, just for use as DI container and feature route root */
export default withAuth((props) => {
    if (!props.promocion || !props.user) return null;

    return (
        <MultiProvider
            providers={[
                <AutomaticReadingPermissionsProvider
                    api={httpAutomaticReadingPermissionsApi}
                    promotion={{ getCurrent: async () => props.promocion }}
                />,
                <TraceabilitiesProvider api={httpTraceabilitiesApi} logger={logger} />,
                <DiExtractionProvider api={httpDiExtractionApi} />
                // <WasteRegisterProvider api={httpWasteRegisterApi} />
            ]}
        >
            <Traceabilities />
        </MultiProvider>
    );
});
