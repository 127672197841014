import { AuthStore } from 'modules/auth/domain/AuthStore';
import { validateEmail } from 'modules/auth/domain/validations/validateEmail';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Store = AuthStore;

export const requestPassReset =
    (params: { email: string }): Thunk<Store, { sended: boolean }> =>
    async (store, { api }) => {
        const { email } = params;
        try {
            store.set((draft) => {
                draft.error = null;
            });

            validateEmail(email);

            await api.requestPassReset({ email });

            return { sended: true };
        } catch (error) {
            store.set((draft) => {
                draft.error = error as Error;
            });

            return { sended: false };
        }
    };
