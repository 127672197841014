import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import GoBack from 'components/BasicComponents/Buttons/GoBack';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import Layout from 'pages/Layout/Layout';
import TraceabilityForm from '../../components/TraceabilityForm';
import './TraceabilityEditPage.scss';

const TraceabilityEditPage = () => {
    const [t] = useTranslation('traceabilities');
    const history = useHistory();
    const { traceabilityId } = useParams<{ traceabilityId: string }>();

    const mode = traceabilityId === undefined ? 'create' : 'edit';

    return (
        <div className="TraceabilityEditPage__layoutWrapper">
            <Layout>
                <div className={`TraceabilityEditPage`}>
                    <header className="TraceabilityDetailPage__header">
                        <div>
                            <GoBack goBack={() => history.goBack()} />
                            <TituloH1
                                titulo={mode === 'create' ? 'Nueva trazabilidad' : `${t('edit')} #${traceabilityId}`}
                            />
                        </div>
                    </header>

                    <SimpleCard>
                        <TraceabilityForm traceabilityId={traceabilityId} />
                    </SimpleCard>
                </div>
            </Layout>
        </div>
    );
};

export default TraceabilityEditPage;
