import { useAuth } from 'modules/auth';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export function useCheckKey() {
    const route = useParams<{ key: string }>();
    const [, { checkKeyValidity }] = useAuth();
    const [isCheckingKey, setIsCheckingKey] = useState(true);
    const [isKeyValid, setIsKeyValid] = useState(false);

    const checkKey = async () => {
        const checkKeyValidityResult = await checkKeyValidity({ key: route.key });
        setIsCheckingKey(false);
        setIsKeyValid(checkKeyValidityResult.valid);
    };

    useEffect(() => {
        checkKey();
    }, []);

    return { isCheckingKey, isKeyValid };
}
