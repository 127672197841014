import { produce } from 'immer';
import { EmailWithOrphanDocumentsCriteria } from 'modules/orphan-documents/domain/EmailWithOrphanDocumentsCriteria';

import { OrphanDocumentsStore } from 'modules/orphan-documents/domain/OrphanDocumentsStore';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

type Config = {
    mode?: OrphanDocumentsStore['actions']['loadAllPending']['payload']['mode'];
};

export const loadEmailsWithOrphanDocuments =
    (criteria?: EmailWithOrphanDocumentsCriteria, config?: Config): Thunk<OrphanDocumentsStore> =>
    async (store, { api, logger }) => {
        const { mode = 'replace' } = config || {};

        try {
            store.dispatch('loadAllPending', { mode });
            const { emails, total } = await api.getEmailsWithOrphanDocuments({ page: criteria?.page || 0 });
            store.dispatch('loadAllFulfilled', { emails, mode, total });
        } catch (e) {
            const error = e as Error;

            logger.send(error);
            store.dispatch('loadAllRejected', { error });
        }
    };

export const loadEmailsWithOrphanDocumentsReducers: Pick<
    Reducers<OrphanDocumentsStore>,
    'loadAllFulfilled' | 'loadAllPending' | 'loadAllRejected'
> = {
    loadAllPending: (state, payload) =>
        produce(state, (draft) => {
            draft.loading = payload.mode === 'replace' ? 'pending' : 'updating';
        }),
    loadAllFulfilled: (state, { emails, mode, total }) =>
        // TODO: mirar si necesito pedir un id al back
        produce(state, (draft) => {
            draft.data = mode === 'merge' ? [...state.data, ...emails] : emails;
            draft.total = total;
            draft.loading = 'succeeded';
            draft.criteria = { ...draft.criteria, page: mode === 'merge' ? (draft.criteria?.page || 0) + 1 : 0 };
        }),
    loadAllRejected: (state, { error }) =>
        produce(state, (draft) => {
            draft.error = error;
            draft.loading = 'failed';
        })
};
