import { DiExtractionWarningFields, IDiExtraction } from 'modules/di-extraction';
import DiExtractionWarning from './DiExtractionWarning';

interface useDiExtractionWarningsProps {
    diExtraction: IDiExtraction;
}

export function useDiExtractionWarnings({ diExtraction }: useDiExtractionWarningsProps) {
    const getWarnings = (field: DiExtractionWarningFields, value: any) => {
        // Don't display warnings if the user modified the initial value
        if (diExtraction.result[field] != value) return null;

        const warnings = diExtraction.warnings.filter((warning) => warning.fields.includes(field));
        if (warnings.length === 0) return null;

        const lowWarnings = warnings.filter((w) => w.severityLevel === 'LOW');
        const highWarnings = warnings.filter((w) => w.severityLevel === 'HIGH');

        return (
            <div className="diExtraction__warnings">
                {lowWarnings.length > 0 && (
                    <DiExtractionWarning type="LOW" messages={lowWarnings.map((w) => w.message)} />
                )}
                {highWarnings.length > 0 && (
                    <DiExtractionWarning type="HIGH" messages={highWarnings.map((w) => w.message)} />
                )}
            </div>
        );
    };

    return { getWarnings };
}
