import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import wasteManagerServices from '../../../api/wasteManager/wasteManager.services';
import ppgclServices from '../../../api/promoProGesCodLers/promoProGesCodLers.services';
import { withAuth } from '../../../context/AuthProvider';
import { TituloH3 } from '../Titulos/Titulos';
import SimplePopover from './SimplePopover';

const GestoraPopover = ({ openModal, setOpenModal, noClose, promocion, gestora, isCoCircular }) => {
    const [t] = useTranslation();
    const [contactosGestora, setContactosGestora] = useState([]);
    const [promoProGesCodLers, setPromoProGesCodLers] = useState([]);
    // eslint-disable-next-line
    const props = [
        // { label: 'ID fiscal', prop: 'identificacionFiscal'},
        { label: 'NIMA', prop: 'nima', visibleForExternal: true },
        { label: t('enterprise'), prop: 'enterprise.name', visibleForExternal: true },
        { label: t('cif'), prop: 'enterprise.cif', visibleForExternal: true },
        // { label: 'enterpriseCif', prop: 'enterprise.cif', visibleForExternal: true },
        { label: 'E-mail', prop: 'email', visibleForExternal: true },
        { label: t('phoneOne', { ns: 'users' }), prop: 'phone', visibleForExternal: true }
    ];

    useEffect(() => {
        getContactosGestora();
        getPromoProGesCodLer();
    }, [promocion, gestora]);

    const getContactosGestora = async () => {
        const results = await wasteManagerServices.getWasteManagerContact(`?gestoraId.equals=${gestora.id}`);

        if (results !== undefined) {
            setContactosGestora(results);
        } else {
            setContactosGestora([]);
        }
    };

    const getPromoProGesCodLer = async () => {
        const results = await ppgclServices.filterPromoProGesCodLers(
            `?promocionId.equals=${promocion.id}&gestoraId.equals=${gestora.id}`
        );
        if (results !== undefined) {
            setPromoProGesCodLers(results);
        } else {
            setPromoProGesCodLers([]);
        }
    };

    const getNestedData = (prop) => {
        let finalData = '';

        if (prop.includes('.')) {
            const nestedProps = prop.split('.');
            let dataAux = { ...gestora };

            nestedProps.forEach((nestedProp) => {
                if (dataAux !== null) {
                    dataAux = dataAux[nestedProp];
                }
            });
            finalData = dataAux;
        } else {
            finalData = gestora[prop];
        }

        return finalData;
    };

    const truncateString = (string) => {
        let result = '-';
        if (string !== undefined && string !== null && string !== '') {
            if (string.length < 40) {
                result = string;
            } else {
                result = string.slice(0, 40) + ' [...]';
            }
        }
        return result;
    };

    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            noClose={noClose}
            title={t('managementData', { ns: 'traceability' })}
        >
            <div className="popover__container-gestora">
                <TituloH3 titulo={gestora.empresa} />
                <div className="popover__data">
                    <div>
                        {props.map(
                            (prop, i) =>
                                (prop.visibleForExternal || isCoCircular) && (
                                    <div key={i}>
                                        <div className="data__row">
                                            <label className="data__label">{prop.label}</label>
                                            <p className="data__value">
                                                {getNestedData(prop.prop) === null ? '-' : getNestedData(prop.prop)}
                                            </p>
                                        </div>
                                    </div>
                                )
                        )}
                    </div>
                    {contactosGestora.length > 0 && (
                        <div className="data__contacts">
                            <label className="data__label">{t('contact.pl', { ns: 'common' })}</label>
                            <div>
                                {contactosGestora.map((contacto, i) => (
                                    <div key={i} className="data__contact">
                                        <div className="data__row">
                                            <label className="data__label">
                                                {contacto?.nombre !== null ? contacto?.nombre : ''}
                                                {contacto?.notas !== null ? ', ' + contacto?.notas : ''}
                                            </label>
                                        </div>
                                        <div className="data__row">
                                            <span>{contacto?.telefono !== null ? contacto?.telefono : ''}</span>
                                            <span>{contacto?.mail !== null ? ', ' + contacto?.mail : ''}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                <br />
                <TituloH3 titulo={t('managersLerCode', { ns: 'traceability' })} />
                <div className="ppgcl__container">
                    {promoProGesCodLers.map((ppgcl, i) => (
                        <div key={i} className="ppgcl__result">
                            <TituloH3
                                titulo={
                                    ppgcl?.wasteManagerProGesCodLer?.codLER?.codigoLER +
                                    ' (' +
                                    ppgcl?.wasteManagerProGesCodLer?.codLER?.lerType?.lerKind +
                                    ')'
                                }
                            />
                            <div className="ppgcl__data">
                                <div>
                                    <div className="data__row">
                                        <label className="data__label">Coste unitario / por tonelada (€)</label>
                                        <p className="data__value">
                                            {ppgcl?.cost?.unit !== undefined && ppgcl?.cost?.unit !== null && (
                                                <span>{ppgcl?.cost?.unit} / </span>
                                            )}
                                            {ppgcl?.cost?.ton !== undefined && ppgcl?.cost?.ton !== null && (
                                                <span>{ppgcl?.cost?.ton}€</span>
                                            )}
                                        </p>
                                    </div>
                                    <div className="data__row">
                                        <label className="data__label">
                                            {t('valuation.upperCase', { ns: 'traceability' })} (%)
                                        </label>
                                        <p className="data__value">
                                            {ppgcl?.wasteManagerProGesCodLer?.actualValorizationPercentage}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="data__row">
                                        <label className="data__label">{t('ler.description', { ns: 'common' })}</label>
                                        <p>{truncateString(ppgcl?.wasteManagerProGesCodLer?.codLER?.descripcion)}</p>
                                    </div>
                                    <div className="data__row">
                                        <label className="data__label">
                                            {t('process.upperCase', { ns: 'common' })}
                                        </label>
                                        <p>
                                            {
                                                ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.valorizacion
                                                    ?.codigoValorizacion
                                            }{' '}
                                            -{' '}
                                            {truncateString(
                                                ppgcl?.wasteManagerProGesCodLer?.procesoGestora?.valorizacion
                                                    ?.descripcion
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </SimplePopover>
    );
};
export default withAuth(GestoraPopover);
