import WasteSummary from 'features/promotion/pages/PromotionDetail/sections/WasteSummary';
import './WasteSummaryPrintable.scss';

const WasteSummaryPrintable = () => {
    return (
        <>
            <div className="WasteSummaryPrintable">
                <WasteSummary />
            </div>
        </>
    );
};

export default WasteSummaryPrintable;
