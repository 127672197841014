import { produce } from 'immer';

import { OrphanDocumentsStore } from 'modules/orphan-documents/domain/OrphanDocumentsStore';
import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

interface AssignOrphanDocumentsProps {
    promotionId: number;
    promotionName: string;
}

export const assignOrphanDocuments =
    ({ promotionId, promotionName }: AssignOrphanDocumentsProps): Thunk<OrphanDocumentsStore> =>
    async (store, { api, logger }) => {
        store.dispatch('triggerAssignAction', { loading: 'assigning' });
        try {
            const selectedDocumentsIds = store.state.selectedDocuments.map((doc) => doc.extractionId);
            await api.assignOrphanDocuments({ selectedDocumentsIds, promotionId });
            store.dispatch('assignMany', { promotionName });
        } catch (e) {
            const error = e as Error;
            logger.send(error);
            store.dispatch('assignManyRejected', { error });
        }
    };

export const assignOrphanDocumentsReducers: Pick<
    Reducers<OrphanDocumentsStore>,
    'assignMany' | 'assignManyRejected' | 'triggerAssignAction'
> = {
    assignMany: (state, { promotionName }) =>
        produce(state, (draft) => {
            const selectedDocumentIds = state.selectedDocuments.map((doc) => doc.extractionId);
            // loop each email, find the selected docs and change its promotionName
            draft.data = draft.data.map((email) => ({
                ...email,
                documents: email.documents.map((doc) =>
                    selectedDocumentIds.includes(doc.extractionId)
                        ? {
                              ...doc,
                              promotionName: promotionName,
                              discarded: false
                          }
                        : { ...doc }
                )
            }));

            draft.selectedDocuments = [];
            draft.loading = 'succeeded';
        }),
    assignManyRejected: (state, { error }) =>
        produce(state, (draft) => {
            draft.error = error;
            draft.loading = 'failed';
        }),
    triggerAssignAction: (state, payload) => ({ ...state, loading: payload.loading })
};
