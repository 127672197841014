import { GoalSeverityLevels, PromotionKPIsGoalMet } from './goals/PromotionKPIsGoal';

export enum KPIStatus {
    OK = 'success',
    WARNING = 'warning',
    FAILED = 'danger',
    INDETERMINATE = 'indeterminate',
    NOT_APPLICABLE = 'not_applicable'
}

/** Determines the status of the KPI based on whether it meets the goal and severity level. */
export function calcKPIStatusFromGoal(props: {
    goal?: Pick<PromotionKPIsGoalMet, 'met' | 'severity'> | null;
    data?: { value: any };
}): KPIStatus | null {
    const { goal, data } = props;
    const { severity } = goal || {};

    let met = goal?.met;

    if (data) {
        // Override API: If the data is null or undefined, the goal is not met
        met = data.value === null || data.value === undefined ? false : met;
    }

    if (!goal) return KPIStatus.INDETERMINATE;

    const status =
        // If the goal is met, always return success
        (met === true && KPIStatus.OK) ||
        // If the goal is not met, return the corresponding status
        (met === false && severity === GoalSeverityLevels.Critical && KPIStatus.FAILED) ||
        (met === false && severity === GoalSeverityLevels.Warning && KPIStatus.WARNING) ||
        // If the goal is not evaluable and there is no info about data to evaluate, return not applicable
        ((met === null || met === undefined) && KPIStatus.NOT_APPLICABLE) ||
        // Indetermate as fallback
        KPIStatus.INDETERMINATE;

    return status;
}
