export const dangerousContainerData = [
    { field: 'containerSize', label: 'canister.sizeShorter' },
    { field: 'signage', label: 'signage' },
    { field: 'purity', label: 'purity.upperCase' },
    { field: 'rainProtection', label: 'rainProtection' },
    { field: 'waterproofTray', label: 'waterproofTray' },
    { field: 'leakProof', label: 'leakProof' },
    { field: 'collectionDate', label: 'collectionDate', type: 'date' }
];

export const notDangerousContainerData = [
    { field: 'containerSize', label: 'container.sizeShorter' },
    { field: 'signage', label: 'signage' },
    { field: 'containerFillPercentage', label: 'filled' },
    { field: 'orderAndAccessibility', label: 'orderAndAccess' },
    { field: 'purity', label: 'purity.upperCase' }
];

export const rsuContainerData = [
    { field: 'signage', label: 'signage' },
    { field: 'orderAndAccessibility', label: 'orderAndAccess' },
    { field: 'purity', label: 'purity.waste' }
];

export const INCIDENCES_LIST = [
    { id: 'signage-false', text: 'cartelería incorrecta (revisar existencia y/o su contenido)' },
    { id: 'orderAndAccessibility-false', text: 'orden y accesibilidad incorrecto (acondicionar y liberar perímetro)' },
    { id: 'rainProtection-false', text: 'protección de la lluvia incorrecta (facilitar techado)' },
    {
        id: 'waterproofTray-false',
        text: 'bandeja impermeable incorrecta (colocar base estanca para evitar filtraciones)'
    },
    { id: 'purity-50', text: '50% de pureza (mezclado, separar correctamente)' },
    { id: 'purity-25', text: '25% de pureza (muy mezclado, separar correctamente)' },
    { id: 'containerFillPercentage-100', text: '100% de llenado (retirar urgentemente)' }
];

export const bestPracticesRNP = ['bestPractices.rnpZone', 'bestPractices.rnpContainers', 'bestPractices.rnpDi'];

export const bestPracticesRP = ['bestPractices.rpZone', 'bestPractices.rpProtection', 'bestPractices.rpPickUp'];

export const bestPracticesRSU = ['bestPractices.rsuZone', 'bestPractices.rsuEmptyContainer'];
