import TextFilter from 'components/BasicComponents/Filter/TextFilter';
import { useAuth } from 'modules/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { hasPermission } from 'utils/helpers/roles.helpers';
import promotionServices from '../../api/promotion/promotion.services';
import userServices from '../../api/user/user.services';
import SimpleCard from '../../components/BasicComponents/Cards/SimpleCard';
import ModalEliminar from '../../components/BasicComponents/Modales/ModalEliminar';
import SimplePopover from '../../components/BasicComponents/Popovers/SimplePopover';
import NewUserForm from '../../components/ComplexComponents/NewPromotion/Step3/NewUserForm';
import User from '../../components/ComplexComponents/PromotionSettings/User';
import { withAuth } from '../../context/AuthProvider';
import './PromotionSettings.scss';

const PromotionSettings = (props) => {
    const [t] = useTranslation();
    const [{ user: account, isCoCircular }] = useAuth();

    const [myPromotions, setMyPromotions] = useState([]);
    const [toggleNewUser, setToggleNewUser] = useState(false);
    const [toggleDeleteUser, setToggleDeleteUser] = useState(false);
    const [users, setUsers] = useState([]);
    const [userToEditOrCreate, setUserToEditOrCreate] = useState();
    const [userToDelete, setUserToDelete] = useState();

    const [showUsersFromAllEnterprises, setShowUsersFromAllEnterprises] = useState(false);
    const [showDeactivatedUsers, setShowDeactivatedUsers] = useState(false);
    const [mailFilter, setMailFilter] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // eslint-disable-next-line
    const [message, setMessage] = useState();

    const canEdit = hasPermission('users', 'canCreate', account);

    const getPromotions = async () => {
        const promotions = await promotionServices.getUserPromotions(props.user.id);
        setMyPromotions(promotions.data);
        return promotions;
    };

    const openModalDeleteUser = async (user) => {
        setToggleDeleteUser(!toggleDeleteUser);
        setUserToDelete(user);
    };

    const getUsersPromotions = async () => {
        setIsLoading(true);

        let usersAux = [];
        let filtersArray = [];

        if (mailFilter) filtersArray.push(`mail.contains=${mailFilter}`);
        if (!showDeactivatedUsers) filtersArray.push('activated.equals=true');

        const filter = '?' + filtersArray.join('&');

        if (showUsersFromAllEnterprises) {
            usersAux = await userServices.getUser(filter);
        } else {
            usersAux = await userServices.getUsersByEntepriseId(account.enterprise.id, filter + '&size=999');
        }
        setUsers(usersAux);
        setIsLoading(false);
    };

    const getInformation = async () => {
        const promotions = await getPromotions();
        await getUsersPromotions(promotions.data);
    };

    const openEditPopover = async (user) => {
        const getUser = await userServices.getUser(`?id.equals=${user.id}`);
        setUserToEditOrCreate(getUser[0]);
        setToggleNewUser(!toggleNewUser);
    };

    const filterArrWithoutUserDeleted = (user) => {
        setUsers(users.filter((userArr) => userArr.id !== user.id));
    };

    const deleteUser = async () => {
        const userDelete = { ...userToDelete, activated: false };
        const deleteUser = await userServices.editUser(userDelete);
        createMessageDeleteUser(deleteUser);
        filterArrWithoutUserDeleted(deleteUser.data.content);
    };

    const createMessageDeleteUser = (user) => {
        if (user !== undefined && user.status === 200) {
            setMessage({ text: 'Usuario eliminado con éxito', success: true });
        } else {
            setMessage({ text: 'Error al eliminar el usuario', success: false });
        }
    };

    useEffect(() => {
        getInformation();
    }, [showUsersFromAllEnterprises, showDeactivatedUsers, mailFilter]);

    const sortedUsers = users.sort((a, b) =>
        a.firstName.replaceAll(' ', '').localeCompare(b.firstName.replaceAll(' ', ''))
    );

    return (
        <>
            <SimpleCard
                title="Gestión de usuarios"
                subtitle="Estos usuarios son usuarios vinculados a tu organización:"
                button={canEdit}
                buttonTitle="Nuevo usuario"
                buttonAction={() => setToggleNewUser(!toggleNewUser)}
                className="promotionSettings"
            >
                {isCoCircular && (
                    <div className="promotionSettings__moreFilters">
                        <p>Filtros para CoCircular</p>
                        <div className="promotionSettings__checkbox">
                            <input
                                id="showAllUsers"
                                type="checkbox"
                                checked={showUsersFromAllEnterprises}
                                onChange={() => setShowUsersFromAllEnterprises(!showUsersFromAllEnterprises)}
                            />
                            <label htmlFor="showAllUsers">Ver los usuarios de todas las empresas</label>
                        </div>

                        <div className="promotionSettings__checkbox">
                            <input
                                id="showDeactivatedUsers"
                                type="checkbox"
                                checked={showDeactivatedUsers}
                                onChange={() => setShowDeactivatedUsers(!showDeactivatedUsers)}
                            />
                            <label htmlFor="showDeactivatedUsers">Ver los usuarios desactivados</label>
                        </div>
                    </div>
                )}

                <TextFilter
                    name="mail"
                    label="Email contiene"
                    isClearable={true}
                    value={mailFilter}
                    onChange={(string) => setMailFilter(string)}
                />

                {!isLoading && users?.length > 0 && (
                    <>
                        <ul className="promotionSettings__ul">
                            {sortedUsers.map((user) => (
                                <User
                                    key={user.id}
                                    userPromotion={user}
                                    promotion={props.promocion}
                                    editUserOptions={canEdit}
                                    {...{ setUsers, users, openEditPopover, openModalDeleteUser }}
                                />
                            ))}
                        </ul>
                    </>
                )}
            </SimpleCard>

            {toggleNewUser && (
                <SimplePopover
                    openModal={toggleNewUser}
                    setOpenModal={setToggleNewUser}
                    title={t('newUser', { ns: 'users' })}
                    className="simplePopover newPromotion__popover"
                    addFunctionOnClose={() => setUserToEditOrCreate()}
                >
                    <NewUserForm
                        setToggleFormNewUser={setToggleNewUser}
                        {...{ userToEditOrCreate, setUsers, users, myPromotions }}
                        promotion={props.promocion}
                        associateToPromotion={true}
                    />
                </SimplePopover>
            )}

            {toggleDeleteUser && (
                <ModalEliminar
                    title="Eliminar usuario"
                    subtitle={`¿Está seguro que quiere eliminar el usuario ${userToDelete.firstName} ${userToDelete.lastName}? `}
                    openModal={toggleDeleteUser}
                    setOpenModal={setToggleDeleteUser}
                    actionText="Eliminar usuario"
                    deleteFunction={deleteUser}
                />
            )}
        </>
    );
};

export default withAuth(PromotionSettings);
