import { useState, useEffect, createRef } from 'react';

const InputText = ({
    label = '',
    id = '',
    placeholder = '',
    name,
    value,
    onChange,
    inputContainer = '',
    className = '',
    onlyRead = false,
    required = false,
    validacion = false,
    type = 'text',
    autoComplete = '',
    disabled = false,
    extraElement,
    onBlur = undefined,
    errorMsg = '',
    ...inputAttributes
}) => {
    const [notComplete, setNotComplete] = useState(false);
    const ref = createRef();

    const inputVacio = () => {
        if (
            validacion === true &&
            required &&
            // eslint-disable-next-line prettier/prettier
            (!!errorMsg ||
                value === undefined ||
                value === null ||
                (typeof value === 'string' && value.trim().length === 0))
        ) {
            setNotComplete(true);
        } else {
            setNotComplete(false);
        }
    };

    const classInput = () => {
        let inputClass = 'input__input';
        if (className) {
            inputClass += ` ${className}`;
        }
        if (disabled) {
            inputClass += ` input__input--disabled`;
        }
        return inputClass;
    };

    useEffect(() => {
        inputVacio();
    }, [notComplete, validacion, value]);

    useEffect(() => {
        if (errorMsg) {
            ref.current.setCustomValidity(errorMsg);
            // ref.current.reportValidity();
        } else ref.current.setCustomValidity('');
    }, [errorMsg, ref]);

    return (
        <div className={`${inputContainer || ''} input__container`}>
            <label className="input__label" htmlFor={id}>
                {label} {required && !label.endsWith('*') ? '*' : ''}
            </label>
            <div className={`input__wrapper ${notComplete ? 'input__input--notComplete' : ''}`}>
                <input
                    id={id}
                    ref={ref}
                    type={type}
                    name={name}
                    aria-label={label}
                    value={value}
                    onChange={onChange}
                    className={classInput()}
                    placeholder={placeholder}
                    readOnly={onlyRead}
                    disabled={disabled}
                    autoComplete={autoComplete}
                    required={required}
                    onBlur={onBlur}
                    {...inputAttributes}
                />
                {extraElement}
            </div>
        </div>
    );
};
export default InputText;
