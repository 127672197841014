import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import logoCoCircular from 'assets/logo-blue.png';
import logo360 from 'assets/logo/logo360.png';
import { TituloH2, TituloH3 } from 'components/BasicComponents/Titulos/Titulos';
import { legacyEnterpriseAdapter } from 'modules/enterprise/related-enterprise/domain/legacyEnterpriseAdapter';
import { cambiarOrdenFecha } from 'utils/helpers/general.helpers';
import './TreatmentContractPrint.scss';

const TreatmentContractPrint = forwardRef(({ content }, ref) => {
    const [t] = useTranslation();

    const getCurrentTimePhrase = () => {
        const today = new Date();
        const currentDate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        const time = today.getHours() + ':' + today.getMinutes();

        const text = `Contrato de tratamiento generado desde la web 360 Advisor el ${currentDate} a las ${time}h`;
        return text;
    };

    const mainEnterprise = legacyEnterpriseAdapter(content.promotion?.relatedEnterprises);

    return (
        <>
            {content !== null && (
                <article ref={ref} className="treatmentContract">
                    <TituloH2 titulo={'Contrato de tratamiento de residuos'} />
                    <small>
                        <i>
                            RD 553/2020 de 02 de junio, que regula el traslado de residuos en el interior del estado
                            español
                        </i>
                    </small>
                    {t('', { ns: 'common' })}
                    <div>
                        <section>
                            <TituloH3 titulo="1. OPERADOR DEL TRASLADO" />
                            <p>Nombre o razón social: {content.transferOperator.name}</p>
                            <p>Tipo de operador del traslado: Agente de residuos</p>
                            <p>CIF: {content.transferOperator.cif}</p>
                            <p>NIMA: {content.transferOperator.nima}</p>
                        </section>
                        <section>
                            <TituloH3 titulo="2. ORIGEN DEL TRASLADO" />
                            <p>
                                Nombre o razón social: {mainEnterprise?.nombreComercial} - {content.promotion.nombre}
                            </p>
                            <p>Dirección: {content.promotion.direccion.direccion}</p>
                            <p>CIF: {mainEnterprise?.cif}</p>
                            <p>NIMA: {content.promotion.nima}</p>
                        </section>
                        <section>
                            <TituloH3 titulo="3. DESTINATARIO / INSTALACIÓN DESTINO DEL TRASLADO" />
                            <p>Nombre o razón social: {content.wasteManager.empresa}</p>
                            <p>CIF: {content.wasteManager.identificacionFiscal}</p>
                            <p>NIMA: {content.wasteManager.nIMA}</p>
                        </section>
                        <section>
                            <TituloH3 titulo="4. CARACTERÍSTICAS DE LOS RESIDUOS QUE SE TRASLADAN" />
                            <p>
                                Los residuos deberán presentarse segregados conforme al listado de residuos inferior.
                                Debe evitarse mezclar residuos de distintas clases, pues puede ser razón para el rechazo
                                de los mismos por parte del destinatario o en su caso causa de recargo por trabajos de
                                segregación.
                            </p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Código LER</th>
                                        <th>Tratamiento</th>
                                        <th>Periodicidad</th>
                                        <th>Validez desde</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {content.ppgcls.map((ppgcl) => (
                                        <tr key={ppgcl.id}>
                                            <td>
                                                {ppgcl.wasteManagerProGesCodLer.codLER.codigoLER} (
                                                {ppgcl.wasteManagerProGesCodLer.codLER.lerType.lerKind})
                                            </td>
                                            <td>
                                                {
                                                    ppgcl.wasteManagerProGesCodLer.procesoGestora.valorizacion
                                                        .codigoValorizacion
                                                }
                                            </td>
                                            <td>Mensual</td>
                                            <td>{cambiarOrdenFecha(ppgcl.fechaPresupuesto)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </section>
                        <section>
                            <TituloH3 titulo="5. CONDICIONES DE ACEPTACIÓN DEL RESIDUO" />
                            <ul>
                                <li>
                                    En caso de aceptación del residuo, el gestor de destino se compromete a la
                                    valorización o eliminación de los residuos, según la operación de tratamiento
                                    indicada.
                                </li>
                                <li>
                                    El gestor de la instalación de destino dispondrá como máximo de un plazo de treinta
                                    (30) días desde la entrega de los residuos para remitir al operador el documento de
                                    identificación completo con la fecha de aceptación o rechazo del residuo.
                                </li>
                                <li>
                                    El contrato debe continuar siendo efectivo una vez entregado el residuo, durante los
                                    treinta (30) días siguientes que el destinatario tiene para verificar la admisión.
                                </li>
                            </ul>
                        </section>
                        <section>
                            <TituloH3 titulo="6. OBLIGACIONES EN CASO DE RECHAZO DEL RESIDUO" />
                            <ul>
                                <li>
                                    En caso de rechazo el operador procederá a hacerse cargo de los residuos, eligiendo
                                    la siguiente opción según artículo 7.1 del Real Decreto:
                                    <p>
                                        ☐ Devolver el residuo al lugar de origen acompañado del documento de
                                        identificación con la indicación de la devolución del residuo.
                                    </p>
                                    <p>
                                        ☐ Enviar los residuos a otra instalación de tratamiento. Este traslado deberá ir
                                        acompañado de un nuevo documento de identificación. El operador de este nuevo
                                        traslado será el operador del traslado inicial.
                                    </p>
                                </li>
                            </ul>
                        </section>
                        <section className="treatmentContract__sign">
                            <div>
                                <TituloH3 titulo="PRODUCTOR DEL TRASLADO" />
                                <p>Nombre o razón social: {mainEnterprise?.nombreComercial}</p>
                                <p>CIF: {mainEnterprise?.cif}</p>
                            </div>
                            <div>
                                <TituloH3 titulo="DESTINATARIO DEL TRASLADO" />
                                <p>Firma autorizada y sello</p>
                                <div className="treatmentContract__signSquare"></div>
                            </div>
                        </section>
                        <section className="treatmentContract__logos">
                            <p>
                                <i>{getCurrentTimePhrase()}</i>
                            </p>

                            <div>
                                <img alt="Logo 360 Advisor" src={logo360} />
                                <img alt="Logo CoCircular" src={logoCoCircular} />
                            </div>
                        </section>
                    </div>
                </article>
            )}
        </>
    );
});

TreatmentContractPrint.displayName = 'TreatmentContractPrint';

export default TreatmentContractPrint;
