import { ApiRelatedEnterprises } from 'modules/shared/domain/ApiPromotion';

export const createRelatedEnterprisesList = (relatedEnterprises: ApiRelatedEnterprises[]) => {
    // order by main enterprise and then by client
    let newRelatedEnterprises = relatedEnterprises.sort(
        (a, b) => Number(b.mainEnterprise) - Number(a.mainEnterprise) || Number(b.client) - Number(a.client)
    );

    return newRelatedEnterprises;
};
