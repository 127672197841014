import { useTranslation } from 'react-i18next';
import SuggestionCard from '../../BasicComponents/Cards/SuggestionCard';
import '../../../pages/Visitas/Visitas.scss';

const BestPractices = ({ arrayTexts = [] }) => {
    const [t] = useTranslation();

    return (
        <SuggestionCard title={t('bestPractices.title', { ns: 'visit' })} allowScroll={true}>
            <ul>
                {arrayTexts?.map((text, i) => (
                    <li key={i}>{text}</li>
                ))}
            </ul>
        </SuggestionCard>
    );
};

export default BestPractices;
