import fetcher from '../../../lib/fetcher';

const message = {
    success: {
        text: 'downloadRequest.put.success',
        ns: 'documentation'
    },
    error: {
        text: 'downloadRequest.put.error',
        ns: 'documentation'
    }
};

export const createDownload = async (download) => {
    const URL = `/api/tracing/documents/compression`;
    try {
        const response = await fetcher.put(URL, download, { message });
        return response;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
