import { IconChevronRight } from '@tabler/icons';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import SimpleCard from 'components/BasicComponents/Cards/SimpleCard';
import ProgressBar from 'components/BasicComponents/Progress/ProgressBar/ProgressBar';
import { usePromotion } from 'modules/promotion';
import { useEffect, useState } from 'react';

import SimplePopover from 'components/BasicComponents/Popovers/SimplePopover';
import TagBadge from 'features/tags/components/TagBadge';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import './PromotionInfo.scss';
import PromotionInfoModal from './PromotionInfoModal';
import WalleMailAddress from './WalleMailAddress';

const PromotionInfo = () => {
    const [{ promotion, promotionPreviews }, { loadPromotionPreviews }] = usePromotion();
    const [openModal, setOpenModal] = useState(false);
    const [, analytics] = useAnalyticsStore();

    const tags = promotionPreviews?.[0]?.tags || [];

    const loadTags = async () => {
        await loadPromotionPreviews({ id: promotion?.id });
    };

    useEffect(() => {
        if (!promotion) return;
        loadTags();
    }, [promotion?.id]);

    return (
        <SimpleCard className="PromotionInfo">
            <h1>{promotion?.nombre}</h1>

            <div className="PromotionInfo__addressAndMail">
                <p className="PromotionInfo__address">
                    {promotion?.direccion.direccion}, {promotion?.direccion.municipio.nombre} (
                    {promotion?.direccion.municipio.provincia.nombre})
                </p>
                <WalleMailAddress />
            </div>

            <div className="PromotionInfo__bottomData">
                <div className="PromotionInfo__progressAndTags">
                    <div className="PromotionInfo__progressBar">
                        <ProgressBar displayPercentageText={true} percentage={promotion?.progress || 0} />
                    </div>
                    {tags?.map((tag) => (
                        <TagBadge name={tag.name} color={tag.color as any} />
                    ))}
                </div>
                <SmallButton
                    action={() => {
                        setOpenModal(true);
                        analytics.event(AnalyticsEvents.PROMO_VIEW_MORE_CLICKED);
                    }}
                    titulo={'Ver más'}
                    icon={<IconChevronRight />}
                    iconPosition="right"
                    className="PromotionInfo__viewMore"
                    {...({} as any)}
                />
            </div>

            <SimplePopover
                openModal={openModal}
                setOpenModal={setOpenModal}
                title={promotion?.nombre}
                className="PromotionInfoModal"
            >
                <PromotionInfoModal />
            </SimplePopover>
        </SimpleCard>
    );
};

export default PromotionInfo;
