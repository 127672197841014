import { IconInnerShadowTopRight } from 'icons';
import './IconButton.scss';

const IconButton = ({
    type = 'button',
    icon = <></>,
    isLoading = false,
    action = () => {},
    text = '',
    disabled = false,
    ariaLabel = '',
    ...attributes
}) => {
    return (
        <button
            type={type}
            className={
                disabled
                    ? 'buttonAux button__small button__white button__icon button__icon--disabled'
                    : 'buttonAux button__small button__white button__icon'
            }
            onClick={action}
            disabled={disabled}
            aria-label={ariaLabel}
            {...attributes}
        >
            {isLoading ? (
                <div className="button__spinner">
                    <IconInnerShadowTopRight />
                </div>
            ) : (
                icon
            )}
            {text && <span>{text}</span>}
        </button>
    );
};
export default IconButton;
