import { IconAlertOctagon, IconPrinter } from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import logo from 'assets/cocircular.png';
import SmallButton from 'components/BasicComponents/Buttons/Small/SmallButton';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import CircularProgress from 'components/BasicComponents/Progress/CircularProgress/CircularProgress';
import { FetcherStatus } from 'lib/fetcher';
import { usePromotion } from 'modules/promotion';
import { PromotionKPIs } from 'modules/promotion-kpis';
import { TraceabilitiesSummaryMeasures } from 'modules/traceabilities/domain/TraceabilitiesSummary';
import ImpactPrintable from './components/ImpactPrintable';
import KPIDetailPrintable from './components/KPIDetailPrintable';
import KPIsPrintable from './components/KPIsPrintable';
import PromotionInfoModalPrintable from './components/PromotionInfoModalPrintable';
import PromotionInfoPrintable from './components/PromotionInfoPrintable';
import TraceabilitiesListKPIsPrintable from './components/TraceabilitiesListKPIsPrintable';
import TraceabilitiesSummaryPrintable from './components/TraceabilitiesSummaryPrintable';
import WasteByValorizationChartPrintable from './components/WasteByValorizationChartPrintable';
import WasteSummaryPrintable from './components/WasteSummaryPrintable';
import './Report.scss';

const date = new Date().toLocaleDateString();

const Report = () => {
    const printRef = useRef<HTMLDivElement>(null);
    const [{ promotion }] = usePromotion();
    const [progress, setProgress] = useState(0);
    const [t] = useTranslation('reports');

    const print = useReactToPrint({
        content: () => printRef.current as any,
        documentTitle: `${promotion?.nombre || 'Informe'} - ${date}`,
        pageStyle: () => `
            @media print {
                @page {
                    margin: 0cm;
                    size: 210mm 297mm;
                }
                html {
                    background-color: #FFFFFF;
                    margin: 0mm;
                    -webkit-print-color-adjust:exact !important;
                    print-color-adjust:exact !important;
                }
            }
        `
    });

    // Keep track of the number of requests and resolved requests
    useEffect(() => {
        const subscription = FetcherStatus.subscribe(({ requests }) => {
            const items = [...requests.values()];
            const all = items.length;
            const resolved = items.filter(({ status: s }) => s === 'fulfilled' || s === 'rejected').length;

            setProgress(Math.ceil((resolved / all) * 100));
        });
        return () => subscription.unsubscribe();
    }, []);

    const address = promotion?.direccion;
    const isBrowserCompatible = !/^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return (
        <div className="Report">
            {!isBrowserCompatible && (
                <AlertCard
                    className="Report__info card__alert-error"
                    title={t('infoDownloadReportTitle')}
                    icon={<IconAlertOctagon />}
                >
                    Lo sentimos, la descarga del informe no está soportada en <strong>Safari</strong>. Por favor, usa
                    otro navegador.
                </AlertCard>
            )}

            {isBrowserCompatible && (
                <AlertCard className="Report__info" title={t('infoDownloadReportTitle')} icon={null}>
                    <p>
                        <Trans
                            i18nKey="reports:infoDownloadReport"
                            components={{
                                b: <strong />
                            }}
                        />
                    </p>

                    <div className="Report__infoContent">
                        {progress < 100 && (
                            <div className="Report__progressContainer">
                                <div className="Report__progress">
                                    <CircularProgress progress={progress} />
                                </div>
                                <div>Generando informe....</div>
                            </div>
                        )}

                        {progress >= 100 && (
                            <SmallButton
                                titulo={t('downloadReport')}
                                icon={<IconPrinter />}
                                action={() => print()}
                                type="button"
                                color="blue"
                                disabled={progress < 100}
                            />
                        )}
                    </div>
                </AlertCard>
            )}

            <div ref={printRef} className="Report__document">
                {/* Header, for all pages but cover */}
                <header className="Report__header">
                    <div>
                        <p>
                            <strong>{promotion?.nombre}</strong>
                        </p>
                        <p>
                            <small>
                                {address?.direccion} {address?.postalCode} {address?.municipio.nombre}
                            </small>
                        </p>
                    </div>
                    <img className="Report__headerLogo" src={logo} alt="Logo" />
                </header>

                {/* Cover */}
                <div className="Report__cover">
                    <img className="Report__coverLogo" src={logo} alt="Logo" />
                    <div className="Report__coverText">
                        <h1 className="Report__coverTitle">Gestión de residuos e impacto medioambiental</h1>
                        <p>{date}</p>
                    </div>
                    <div className="Report__coverInfo">
                        <PromotionInfoPrintable />
                    </div>
                </div>

                {/* Summary */}
                <div className="Report__page">
                    <div className="Report__sectionHeader">
                        <h2>1. Información general</h2>
                        <p>Avance temporal y datos del proyecto</p>
                    </div>
                    <PromotionInfoModalPrintable />

                    <div className="Report__sectionHeader">
                        <h2>2. Resumen y objetivos</h2>
                        <p>Resumen de métricas globales y estado actual del cumplimiento de los objetivos</p>
                    </div>
                    <KPIsPrintable />
                    <WasteSummaryPrintable />
                </div>

                {/* CodLer and LerType metrics */}
                <div className="Report__page">
                    <div className="Report__break-avoid">
                        <div className="Report__sectionHeader">
                            <h2>3. Métricas de gestión y valorización por residuo</h2>
                            <p>Datos asociados a la gestión de cada residuo</p>
                        </div>
                        <TraceabilitiesListKPIsPrintable />
                    </div>

                    <div className="Report__break-avoid">
                        <div className="Report__sectionHeader">
                            <h3>3.1 Métricas de tratamiento por cada código LER</h3>
                        </div>
                        <TraceabilitiesSummaryPrintable measure={TraceabilitiesSummaryMeasures.codLer} />
                    </div>

                    <div className="Report__break-avoid">
                        <div className="Report__sectionHeader">
                            <h3>3.2 Métricas de tratamiento para Tierra y materiales naturales excavados</h3>
                        </div>
                        <TraceabilitiesSummaryPrintable
                            measure={TraceabilitiesSummaryMeasures.treatment}
                            // only Tierras y materiales naturales excavados
                            criteria={{ lerTypes: ['12'] }}
                        />
                    </div>

                    <div className="Report__break-avoid">
                        <div className="Report__sectionHeader">
                            <h3>3.3 Toneladas de residuos por tipo de valorización</h3>
                        </div>
                        <WasteByValorizationChartPrintable />
                    </div>
                </div>

                {/* Waste managers metrics */}
                <div className="Report__page">
                    <div className="Report__break-avoid">
                        <div className="Report__sectionHeader">
                            <h2>4. Metricas de gestión por gestora</h2>
                            <p>Datos asociados a la gestión de cada gestora</p>
                        </div>
                        <TraceabilitiesSummaryPrintable measure={TraceabilitiesSummaryMeasures.wasteManager} />
                    </div>
                </div>

                {/* Impact metrics */}
                <div className="Report__page">
                    <div className="Report__break-avoid">
                        <div className="Report__sectionHeader">
                            <h2>5. Metricas de impacto medioambiental</h2>
                            <p>Distribución de CO₂ generado y evitado por residuo</p>
                        </div>
                        <ImpactPrintable />
                    </div>
                </div>

                {/* Impact metrics */}
                <div className="Report__page">
                    <div className="Report__sectionHeader">
                        <h2>6. Anexo I: Detalle de cumplimiento de objetivos</h2>
                        <p></p>
                    </div>
                    <div>
                        {Object.values(PromotionKPIs).map((kpi) => (
                            <div className="Report__break-avoid">
                                <KPIDetailPrintable key={kpi} kpi={kpi} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Report;
