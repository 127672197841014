import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { cambiarOrdenFecha } from 'utils/helpers/general.helpers';

export function useWasteSignagePrint({ docInfo }) {
    const printTargetRef = useRef();
    const documentTitle = `${cambiarOrdenFecha(docInfo.documents[0].date)} - ${docInfo.documents[0].name}`;

    const handlePrint = useReactToPrint({
        documentTitle,
        content: () => printTargetRef.current,
        pageStyle: () => `
            @media print {
                @page {
                    size: 297mm 210mm;
                    margin: 0mm;
                }
                html {
                    background-color: #FFFFFF; 
                    margin: 0mm;
                    -webkit-print-color-adjust:exact !important;
                    print-color-adjust:exact !important;
                }
            }
        `
    });

    return {
        printTargetRef,
        handlePrint
    };
}
