import { produce } from 'immer';
import { Loading } from 'modules/shared/domain/loading';
import { BaseFunction, BaseStore, Reducers, State, Thunk } from 'modules/shared/domain/store/createStore';
import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState
} from 'react';

export function contextStoreAdapter<
    Store extends BaseStore,
    ThunkAction extends string,
    ThunkFn extends BaseFunction<Thunk<Store>>,
    ThunkFns extends { [TA in ThunkAction]: ThunkFn },
    Dispatch extends <Type extends keyof Store['actions']>(
        type: Type,
        payload: Store['actions'][Type]['payload']
    ) => void
>(store: { initialState: Store['state']; reducers: Reducers<Store>; thunks: ThunkFns }) {
    return ({ name }) => {
        type Thunks = {
            [Thunk in keyof ThunkFns]: (
                ...args: Parameters<ThunkFns[Thunk]>
            ) => ReturnType<ReturnType<ThunkFns[Thunk]>>;
        } & { reset: () => Promise<any> };

        type Value = [State<Store>, Thunks, Store['repositories']];
        let alreadyWarned = false;

        const Context = createContext([store.initialState, {}, {}] as Value);
        Context.displayName = name;

        const Provider = ({ children, ...repositories }: PropsWithChildren<Store['repositories']>) => {
            const reducer = (
                state: State<Store>,
                action: {
                    type: Parameters<Dispatch>[0];
                    payload: Parameters<Dispatch>[1];
                }
            ) => {
                if (action.type === 'reset') return store.initialState;
                if (action.type === 'set') return action.payload as State<Store>;
                return store.reducers[action.type](state, action.payload);
            };

            const [state, _dispatch] = useReducer(reducer, store.initialState);

            const dispatch = ((type, payload) => {
                _dispatch({ type, payload });
            }) as Dispatch;

            const set = (payload: State<Store> | ((draft: State<Store>) => void)): Store['state'] => {
                const _payload = typeof payload === 'function' ? produce(state, payload) : payload;
                dispatch('set', _payload);
                return _payload;
            };

            const reset = async () => {
                dispatch('reset', {});
            };

            const thunks = Object.keys(store.thunks).reduce(
                (thunks, action) => {
                    const thunkAction = action as ThunkAction;

                    const thunk = (...args: []) => {
                        return store.thunks[action as ThunkAction].apply(null, args)(
                            { dispatch, set, state },
                            repositories
                        );
                    };

                    return {
                        ...thunks,
                        [thunkAction]: thunk
                    };
                },
                { reset }
            );

            const value = useMemo(() => {
                return [state, thunks, repositories] as Value;
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [state, repositories]);

            return <Context.Provider value={value}>{children}</Context.Provider>;
        };

        const useConsumer = () => {
            const value = useContext(Context);

            if (value === null && !alreadyWarned) {
                console.log(
                    `%c [contextStoreAdapter] No Provider for ${name} context`,
                    'background: #FFFF99; color: black'
                );
                alreadyWarned = true;
            }

            return value || {};
        };

        /**
         * Creates a Query Hook from a method in a repository that allows a query
         * to be made directly without the need to store the data in the store.
         */
        function createQueryHook<QueryFn extends (...params: any[]) => any>(
            selector: (repositories: Store['repositories']) => QueryFn
        ) {
            type QueryParams = Parameters<QueryFn>;
            type Data = Awaited<ReturnType<QueryFn>>;

            const useQuery = (...queryParams: QueryParams) => {
                const [, , repositories] = useContext(Context);
                const queryFn = useCallback(selector(repositories), [repositories]);

                const [state, setState] = useState<{ data: Data | null; loading: Loading; error: Error | null }>({
                    data: null,
                    loading: 'idle',
                    error: null
                });

                const fetchData = async (...params: QueryParams) => {
                    setState({ ...state, loading: 'pending' });
                    try {
                        const data = await queryFn(...params);
                        setState({ data, loading: 'succeeded', error: null });
                        return data as ReturnType<QueryFn>;
                    } catch (error) {
                        console.error(error);
                        setState({ data: null, loading: 'failed', error: error as Error });
                    }
                };

                useEffect(() => {
                    fetchData(...queryParams);
                }, [JSON.stringify(queryParams)]);

                const refetch = async (...params: QueryParams | undefined[]) => {
                    const refetchParams = (params.length > 0 ? params : queryParams) as Parameters<QueryFn>;
                    const res = await fetchData(...refetchParams);
                    return res;
                };

                return { ...state, refetch };
            };

            return useQuery;
        }

        /**
         * Creates a Query Hook from a method in a repository that allows a mutation
         * to be made directly without the need to store the data in the store.
         */
        function createMutationHook<MutationFn extends (...params: any[]) => any>(
            selector: (repositories: Store['repositories']) => MutationFn
        ) {
            type Body = Parameters<MutationFn>;
            type Data = Awaited<ReturnType<MutationFn>>;

            const useMutation = () => {
                const [, , repositories] = useContext(Context);
                const mutationFn = useCallback(selector(repositories), [selector, repositories]);

                const [state, setState] = useState<{ data: Data | null; loading: Loading; error: Error | null }>({
                    data: null,
                    loading: 'idle',
                    error: null
                });

                const mutate = async (...body: Body) => {
                    setState({ ...state, loading: 'pending' });
                    try {
                        const data = await mutationFn(...body);

                        const result = { data: data as Data, loading: 'succeeded' as Loading, error: null };
                        setState(result);
                        return result;
                    } catch (error) {
                        const result = { data: null, loading: 'failed' as Loading, error: error as Error };
                        setState(result);
                        return result;
                    }
                };

                return { mutate, ...state };
            };

            return useMutation;
        }

        return { Provider, useConsumer, createQueryHook, createMutationHook };
    };
}
