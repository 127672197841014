import ImpactPage from '../components/ImpactPage';

export const IMPACT_ROUTES = [
    {
        component: ImpactPage,
        path: '/impact',
        requiredPermission: 'canView',
        section: 'impact',
        withoutPromotion: true
    }
];
