import { produce } from 'immer';

import { Reducers, Thunk } from 'modules/shared/domain/store/createStore';

import { TraceabilitiesStore } from '../../domain/TraceabilitiesStore';

export const loadTraceabilitiesStats =
    (): Thunk<TraceabilitiesStore> =>
    async (store, { api, logger }) => {
        const criteria = store.state.criteria || {};
        if (!criteria.promotionId) return;

        store.dispatch('loadTraceabilitiesStatsPending', null);

        try {
            const { stats } = await api.getTraceabilitiesStats(criteria);
            store.dispatch('loadTraceabilitiesStatsFulfilled', { stats });
        } catch (e) {
            const error = e as Error;

            logger.send(error);
            store.dispatch('loadTraceabilitiesStatsRejected', { error });
        }
    };

export const loadTraceabilitiesStatsReducers: Pick<
    Reducers<TraceabilitiesStore>,
    'loadTraceabilitiesStatsPending' | 'loadTraceabilitiesStatsFulfilled' | 'loadTraceabilitiesStatsRejected'
> = {
    loadTraceabilitiesStatsPending: (state, payload) =>
        produce(state, (draft) => {
            draft.loading.stats = 'pending';
        }),
    loadTraceabilitiesStatsFulfilled: (state, { stats }) =>
        produce(state, (draft) => {
            draft.stats = stats;
            draft.loading.stats = 'succeeded';
        }),
    loadTraceabilitiesStatsRejected: (state, { error }) =>
        produce(state, (draft) => {
            draft.stats = [];
            draft.loading.stats = 'failed';
            draft.error = error;
        })
};
