import fetcher from 'lib/fetcher';
import { WasteSummaryApi } from '../../domain/WasteSummaryApi';

export const httpWasteSummaryApi: WasteSummaryApi = {
    getWasteSummary: async ({ promotionId }) => {
        const { data } = await fetcher.get(`/api/impact/metrics/summary`, {
            params: { 'promocion.equals': promotionId }
        });
        return data.content;
    }
};
