import { IconCheck } from '@tabler/icons';
import BasicButton from './Base/BasicButton';
import './Buttons.scss';

const SaveButton = ({
    type = 'submit',
    text = 'Save',
    disabled = false,
    action = () => {},
    to = undefined,
    state = undefined,
    color = 'blue',
    extraClasses = '',
    isLoading = false,
    ...props
}) => {
    return (
        <BasicButton
            color={color}
            type={type}
            text={text}
            testid={props.testid}
            action={action}
            to={to}
            state={state}
            disabled={disabled}
            extraClasses={extraClasses}
            isLoading={isLoading}
            icon={props.icon ?? <IconCheck stroke={2.1} size={18} color="white" />}
        />
    );
};
export default SaveButton;
