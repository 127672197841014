import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import wasteManagerServices from '../../../api/wasteManager/wasteManager.services';
import { withAuth } from '../../../context/AuthProvider';
import SubmitButton from '../../BasicComponents/Buttons/SubmitButton';
import BasicChip from '../../BasicComponents/Chips/BasicChip';
import MessageNotComplete from '../../BasicComponents/Messages/MessageNotComplete';
import ResultadoCarga from '../../BasicComponents/Messages/ResultadoCarga';
import SimplePopover from '../../BasicComponents/Popovers/SimplePopover';
import CodLerAutocomplete from '../../BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import SmallText from '../../BasicComponents/Text/SmallText';
import { TituloH4 } from '../../BasicComponents/Titulos/Titulos';
import Spinner from '../../BasicComponents/Spinner/Spinner';
import WasteManagerAutocomplete from '../../BasicComponents/Select-Autocomplete/WasteManagerAutocomplete';
import './NewWasteManagerRequest.scss';

const NewCodLersRequest = ({ openModal, setOpenModal }) => {
    const INITIAL_STATE = {
        gestora: null,
        lers: []
    };
    const [t] = useTranslation();
    const [codLers, setCodLers] = useState([]);
    const [selectedCodLer, setSelectedCodLer] = useState({ codLer: '' });
    const [codLerRequestValues, setCodLerRequestValues] = useState(INITIAL_STATE);

    const [validation, setValidation] = useState(false);
    const [message, setMessage] = useState();
    const [messageNotComplete, setMessageNotComplete] = useState();
    const [loading, setLoading] = useState(false);

    const validateForm = () => {
        if (codLerRequestValues?.gestora === null) {
            setMessageNotComplete(
                'Debes elegir una gestora. Si no existe la que necesitas, puedes solicitar que se de de alta desde el otro formulario.'
            );
            setValidation(false);
            return false;
        }

        if (codLers?.length === 0) {
            setMessageNotComplete('Debes seleccionar al menos 1 código LER.');
            setValidation(false);
            return false;
        }

        setMessageNotComplete('');
        setValidation(true);
        return true;
    };

    const resetData = () => {
        setSelectedCodLer({ codLer: '' });
        setCodLerRequestValues(INITIAL_STATE);
    };

    const createMessage = (newValorizationRequest) => {
        if (
            newValorizationRequest !== undefined &&
            (newValorizationRequest?.status === 200 || newValorizationRequest?.status === 204)
        ) {
            setMessage({ text: t('message.success', { ns: 'newCodLers' }), success: true });
            setTimeout(() => {
                resetData();
                setOpenModal(false);
            }, 3000);
        } else {
            setMessage({ text: t('message.error', { ns: 'newCodLers' }), success: false });
        }
    };

    useEffect(() => {
        addSelectedCodLer();
    }, [selectedCodLer]);

    useEffect(() => {
        setCodLerRequestValues({
            ...codLerRequestValues,
            lers: codLers
        });
    }, [codLers]);

    const addSelectedCodLer = () => {
        if (selectedCodLer?.codLer !== '') {
            const newCodLer = selectedCodLer?.codLer?.codigoLER;

            if (!codLers.includes(newCodLer)) {
                setCodLers([...codLers, newCodLer]);
            }
        }
    };

    const removeCodLer = (removedCodLer) => {
        const updatedCodLers = codLers?.filter((ler) => ler !== removedCodLer);
        setCodLers(updatedCodLers);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validateForm()) {
            const newValorizationRequest = await wasteManagerServices.requestNewValorizationProcess(
                codLerRequestValues
            );
            createMessage(newValorizationRequest);
        }

        setLoading(false);
    };

    return (
        <SimplePopover
            openModal={openModal}
            setOpenModal={setOpenModal}
            title={t('longTitle', { ns: 'newCodLers' })}
            className="simplePopover popover__big"
            allowScroll={false}
            addFunctionOnClose={() => resetData()}
        >
            <form onSubmit={handleSubmit} className="promotionSettings__formEditPromotion">
                <WasteManagerAutocomplete
                    values={codLerRequestValues}
                    setValues={setCodLerRequestValues}
                    validacion={validation}
                    required
                />

                <div className="newWasteManagerRequest__codLers">
                    <TituloH4 titulo={t('titleLers', { ns: 'newWasteManager' })} />

                    <CodLerAutocomplete
                        searchFromPPGCL={false}
                        values={selectedCodLer}
                        setValues={setSelectedCodLer}
                        label={t('codLerSearcher', { ns: 'newWasteManager' })}
                    />

                    <SmallText text={t('selectedCodLers', { ns: 'newWasteManager' })} />

                    <div className="newWasteManagerRequest__selectedCodLers">
                        {codLers?.map((codLer, i) => (
                            <BasicChip key={i} id={codLer} text={codLer} removeChipFunction={removeCodLer} />
                        ))}
                    </div>
                </div>

                {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
                <SubmitButton text={t('sendRequest', { ns: 'newWasteManager' })} />
                {message?.text && (
                    <ResultadoCarga text={message?.text} success={message?.success} setMessage={setMessage} />
                )}
            </form>
            {loading && <Spinner />}
        </SimplePopover>
    );
};
export default withAuth(NewCodLersRequest);
