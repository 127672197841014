import { PropsWithChildren } from 'react';
import { currencyFormatDE } from 'utils/helpers/general.helpers';
import './SemiCircularIndicator.scss';

export interface SemiCircularIndicatorProps {
    value?: number | null;
    maxValue: number; // 10 if its a score, 100 if its a percentage...
    isPercentage: boolean;
}

const SemiCircularIndicator = (props: PropsWithChildren<SemiCircularIndicatorProps>) => {
    const progressValue = ((props.value || 0) * 180) / props.maxValue;
    const progressStyle = `conic-gradient(var(--SemiCircularIndicator-progress) ${progressValue}deg, var(--SemiCircularIndicator-circle) 0deg)`;

    return (
        <>
            <div className="SemiCircularIndicator">
                <div
                    className="SemiCircularIndicator__progress"
                    style={{
                        background: progressStyle,
                        rotate: '-90deg'
                    }}
                >
                    <svg viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="44" />
                    </svg>
                </div>
                <p className="SemiCircularIndicator__percent">
                    {props.value !== undefined && props.value !== null ? currencyFormatDE(props.value) : 'N/D'}
                    {props.isPercentage && '%'}
                </p>
            </div>
            {props.children && <div className="SemiCircularIndicator__text">{props.children}</div>}
        </>
    );
};

export default SemiCircularIndicator;
