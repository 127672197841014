import { FormProvider } from 'react-hook-form';

import { IconAlertTriangle } from '@tabler/icons';
import CreateButton from 'components/BasicComponents/Buttons/CreateButton';
import AlertCard from 'components/BasicComponents/Cards/AlertCard';
import InputFormControl from 'components/FormControls/InputFormControl';
import { Promotion } from 'modules/promotion';
import { Trans, useTranslation } from 'react-i18next';
import './PromotionDataForm.scss';
import PromotionDataAddressForm from './components/PromotionDataAddressForm';
import PromotionDataInfoForm from './components/PromotionDataInfoForm';
import { usePromotionDataCanEdit } from './hooks/usePromotionDataCanEdit';
import { usePromotionDataForm } from './hooks/usePromotionDataForm';

export type PromotionDataFormProps = {
    promotion?: Promotion | null;
};

const PromotionDataForm = (props: PromotionDataFormProps) => {
    const [t] = useTranslation('promotion');
    const { form, save } = usePromotionDataForm(props);
    const { canEdit, allowedEnterprise } = usePromotionDataCanEdit();

    return (
        <FormProvider {...form}>
            <form className="PromotionDataForm__form" onSubmit={save}>
                {!canEdit && (
                    <AlertCard className="card__alert-warning" icon={<IconAlertTriangle />}>
                        <Trans
                            i18nKey="promotion:cantEditWarning"
                            components={{ b: <strong /> }}
                            values={{ enterprise: allowedEnterprise?.enterpriseName }}
                        />
                    </AlertCard>
                )}

                <div className="PromotionDataForm__row">
                    <InputFormControl
                        name="name"
                        control={form.control}
                        label={t('name')}
                        className="PromotionDataForm__control --full --large"
                    />
                    <InputFormControl
                        name="nima"
                        control={form.control}
                        label={t('nima')}
                        className="PromotionDataForm__control"
                    />
                </div>

                <PromotionDataAddressForm />
                <PromotionDataInfoForm />

                <div className="PromotionDataForm__row --align-right">
                    <CreateButton
                        type="submit"
                        text={t('common:save')}
                        icon={null}
                        isLoading={form.formState.isSubmitting}
                        disabled={!canEdit}
                    />
                </div>
            </form>
        </FormProvider>
    );
};

export default PromotionDataForm;
