import { AnalyticsIdentification } from 'modules/analytics/domain/AnalyticsIdentification';
import { AnalyticsStore } from 'modules/analytics/domain/AnalyticsStore';
import { Thunk } from 'modules/shared/domain/store/createStore';

type Store = AnalyticsStore; // Define Store here

export const indentify =
    (identification: AnalyticsIdentification): Thunk<Store> =>
    async (store, { api, storage }) => {
        try {
            // Only sends the event if the store is initialized
            if (store.state.initialized) {
                await api.identify(identification);
            }

            // But always upates the store
            const newState = store.set((state) => {
                state.identification = identification;
            });

            // And syncs the state with the storage
            await storage.sync(newState);
        } catch (e) {
            const error = e as Error;
            store.set((state) => {
                state.error = error;
            });
        }
    };
