export const loadingState = {
    IDLE: 'idle',
    PENDING: 'pending',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed'
};

export const initialState = {
    loading: loadingState.IDLE,
    treatmentContractList: [],
    treatmentContractDetail: null,
    error: null
};
