import { Redirect, Switch } from 'react-router-dom';

import PrivateRoute from 'components/routes/PrivateRoute';
import { INTERNAL_TRACEABILITIES_ROUTES, traceabilitiesRouteRoot } from './routes/TraceabilitiesRoutes';
import { useLoadExtractionCounters } from './hooks/useLoadExtractionCounters';

const Traceabilities = () => {
    useLoadExtractionCounters();

    return (
        <>
            <Switch>
                {INTERNAL_TRACEABILITIES_ROUTES.map((route: any) => (
                    <PrivateRoute {...route} key={route.path} />
                ))}
                <Redirect to={traceabilitiesRouteRoot} />
            </Switch>
        </>
    );
};

export default Traceabilities;
