import ReportImpact from 'features/reports/components/Report/components/ImpactPrintable/ReportImpact';
import './ImpactPrintable.scss';

const ImpactPrintable = () => {
    return (
        <>
            <div className="ImpactPrintable">
                <ReportImpact />
            </div>
        </>
    );
};

export default ImpactPrintable;
