import { IconInnerShadowTopRight } from 'icons';
import './SpinnerTiny.scss';

interface SpinnerTinyProps extends React.HTMLAttributes<HTMLDivElement> {}

const SpinnerTiny = ({ className, ...props }: SpinnerTinyProps) => {
    return (
        <div className={`SpinnerTiny ${className}`} {...props}>
            <IconInnerShadowTopRight />
        </div>
    );
};

export default SpinnerTiny;
